import { TbChartHistogram } from "react-icons/tb";
import { Button } from "../../../shared-ui/frontend/button";
import * as BottomControlBar from "../../../shared-ui/time-series-2/secondary-variable-view/bottom-control-bar";
import { useGetUseVariabilityDrawerStoreNotRequired } from "../../variability-view/variability-drawer";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { DateSelector } from "./chart-range/date-selector";
import { ComponentProps, memo, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import {
  Acknowledgements,
  useAcknowledgementsQuery,
  useAriaClustersQuery,
  useClustersQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import {
  FaBell,
  FaChartLine,
  FaCheck,
  FaComment,
  FaEllipsisH,
  FaExclamationTriangle,
  FaLayerGroup,
  FaSearchPlus,
} from "react-icons/fa";
import { useClusterDrawerStoreNotRequired } from "../../clusters/cluster-drawer/use-cluster-drawer-store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared-ui/frontend/dropdown-menu";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { YYYY_MM_DD } from "../../../lib/validators";
import { iife } from "../../../lib/utils";
import { Check, X } from "lucide-react";
import {
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "../../../shared-ui/frontend/hover-card";
import { useMutation } from "@tanstack/react-query";
import {
  postAcknowledgement,
  PostAckSchema,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import moment from "moment";
import { defaultRangeDaysForSlopingTrends } from "../../../constants/dateState";
import { atom, useAtom, useAtomValue, useSetAtom, useStore } from "jotai";
import {
  Atoms,
  isVariableTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { useCallback } from "use-memo-one";
import { minLen1 } from "../../../shared-ui/lib/utils";
import { useOperatingModesQuery } from "../../om/manager/queries";
import {
  useIsFullscreen,
  useToggleFullscreen,
} from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { Badge } from "../../../shared-ui/frontend/badge";
import { useCorrelatedTagsDrawerStoreNotRequired } from "../../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { IoSparklesSharp } from "react-icons/io5";
import { useChartRenderOptions } from "./generic-secondary-variable-view";
import { minutesToMilliseconds } from "date-fns";
import { ClusterScoreBadge } from "../../aria/cluster-score-badge";

const showBottomControlsAtom = atom((get) => {
  const brushState = get(Atoms.brushStoreAtom);
  const showNotifsAtom = get(Atoms.showNotificationsAtomAtom);

  const notCurrentlyBrushing = !brushState;
  const notCurrentlyShowingNotifications =
    !showNotifsAtom || !get(showNotifsAtom);

  const showBottomControls =
    notCurrentlyBrushing && notCurrentlyShowingNotifications;

  return showBottomControls;
});

const BottomControls = memo(function BottomControls() {
  const isOperatingLimitsChart = useAtomValue(Atoms.chartVariantAtom) === "ol";
  const isInstantCalc = useAtomValue(Atoms.chartVariantAtom) === "ic";
  const show = useAtomValue(showBottomControlsAtom);
  const d = useTzAwareDateStateDomain();
  if (!show) return null;

  return (
    <BottomControlBar.Container>
      <BottomControlBar.VariableSelectOverlayButton />
      <BottomControlBar.GlobalClampsButton />
      <BottomControlBar.EyeButton />
      <ClusterDrawerButton />
      <CorrelatedTagsButton />
      {!isOperatingLimitsChart && !isInstantCalc && <AckButton />}
      <BottomControlBar.StoreInspect />
      <DateSelector className="mx-auto" />
      {!isInstantCalc && <CreateCommentButton />}
      {!isOperatingLimitsChart && !isInstantCalc && (
        <SlopingTrendsButton size={BottomControlBar.BUTTON_SIZE} />
      )}
      {!isInstantCalc && <NotificationButton />}
      {!isInstantCalc && (
        <VariabilityButton size={BottomControlBar.BUTTON_SIZE} />
      )}
      <BottomControlBar.ResetButton domain={d} />
      <MasksButton />
    </BottomControlBar.Container>
  );
});

const toggleModeAtom = atom(null, (get, set, s: string) => {
  const copy = new Set(get(Atoms.excludedModesAtom));
  copy.delete(s) || copy.add(s);
  set(Atoms.excludedModesAtom, copy);
});

function MasksButton() {
  const isAnOperatingLimitsChart =
    useAtomValue(Atoms.chartVariantAtom) === "ol";
  const [modesThatAreOff, setExcludedModes] = useAtom(Atoms.excludedModesAtom);
  const toggleMode = useSetAtom(toggleModeAtom);
  const [showLimitLines, setShowLimitLines] = useAtom(Atoms.showLimitLinesAtom);

  const inSingleVariableView = useAtomValue(Atoms.singleVariableViewAtom);

  const shouldShowXAxisDiscontinuityButton =
    useAtomValue(Atoms.xAxisContinuityAtom) !== undefined &&
    inSingleVariableView;

  const [isModeTransparency, setIsModeTransparency] = useAtom(
    Atoms.isModeTransparencyAtom
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size={BottomControlBar.BUTTON_SIZE} variant={"ghost"}>
          <FaEllipsisH className="size-3.5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-2 flex flex-col gap-1.5" align="end">
        {/* when it's undefined, it hasn't been initialized yet  */}
        {isModeTransparency !== undefined && (
          <MaskToggleButton
            onClick={() => setIsModeTransparency(!isModeTransparency)}
            active={isModeTransparency}
          >
            Mode Transparency
          </MaskToggleButton>
        )}
        {shouldShowXAxisDiscontinuityButton && <XAxisDiscontinuityToggle />}
        {isAnOperatingLimitsChart && (
          <MaskToggleButton
            onClick={() => setShowLimitLines((s) => !s)}
            active={showLimitLines}
          >
            Toggle Limits
          </MaskToggleButton>
        )}
        <div className="w-full h-[0.5px] bg-xslate-8 my-1" />
        <MaskToggleButton
          onClick={() => setExcludedModes(new Set())}
          active={modesThatAreOff.size === 0}
          disabled={modesThatAreOff.size === 0}
        >
          View All
        </MaskToggleButton>
        <MaskToggleButton
          onClick={() => toggleMode("Remainder")}
          active={!modesThatAreOff.has("Remainder")}
        >
          Remainder
        </MaskToggleButton>
        <MaskToggleButton
          onClick={() => toggleMode("0".repeat(24))}
          active={!modesThatAreOff.has("000000000000000000000000")}
        >
          Shutdown
        </MaskToggleButton>
        <_ModesMaskButtonsForVariableId />
      </PopoverContent>
    </Popover>
  );
}

function _ModesMaskButtonsForVariableId() {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const modesQuery = useOperatingModesQuery();
  const modesThatAreOff = useAtomValue(Atoms.excludedModesAtom);
  const toggleMode = useSetAtom(toggleModeAtom);

  const modes = modesQuery.data;

  const myModes = useMemo(() => {
    if (!modes) return undefined;
    if (!isVariableTrendLine(primaryTrendLine)) return undefined;

    return modes.filter((x) =>
      x.bindingVariableIdsSet.includes(primaryTrendLine.bv.slice(24))
    );
  }, [modes, primaryTrendLine]);
  if (!myModes?.length) return undefined;

  return (
    <>
      {myModes.map(({ _id, name }) => {
        const off = modesThatAreOff.has(_id);
        const active = !off;
        return (
          <MaskToggleButton active={active} onClick={() => toggleMode(_id)}>
            {name}
          </MaskToggleButton>
        );
      })}
    </>
  );
}

function MaskToggleButton({
  active,
  onClick,
  children,
  disabled,
}: Pick<ComponentProps<typeof Button>, "onClick" | "children" | "disabled"> & {
  active: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "justify-start w-full flex-nowrap animate-in slide-in-from-top-3"
      )}
      size={"xs"}
      variant={active ? "default" : "destructive"}
    >
      {active ? (
        <Check className="h-4 w-4 mr-1" />
      ) : (
        <X className="h-4 w-4 text-xred-10 mr-1" />
      )}
      {children}
    </Button>
  );
}

const xAxisContinuityAtomRequired = atom((get) => {
  const out = get(Atoms.xAxisContinuityAtom);
  if (!out) throw new Error("Don't use when not set");

  return out;
});

function XAxisDiscontinuityToggle() {
  const continuousAtom = useAtomValue(xAxisContinuityAtomRequired);
  const [isContinuous, setIsContinuous] = useAtom(continuousAtom);

  return (
    <MaskToggleButton
      onClick={() => setIsContinuous((x) => !x)}
      active={isContinuous}
    >
      X-Axis Continuity
    </MaskToggleButton>
  );
}

function ClusterDrawerButton() {
  const s = useClusterDrawerStoreNotRequired();
  const clustersQuery = useClustersQuery();
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  if (!useAtomValue(Atoms.singleVariableViewAtom)) return null;
  if (!s) return null;

  if (!isVariableTrendLine(primaryTrendLine)) return null;

  const myClusters = clustersQuery.data?.filter((x) =>
    x.variables.includes(primaryTrendLine.bv.slice(24))
  );

  if (!myClusters?.length) return null;

  return (
    <Tooltip>
      <TooltipContent>Clusters</TooltipContent>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <Button size={BottomControlBar.BUTTON_SIZE} variant={"ghost"}>
              <FaLayerGroup className="size-3" />
            </Button>
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {myClusters.map((x) => {
            return (
              <DropdownMenuItem
                key={x._id}
                onSelect={() => s.getState().setClusterId(x._id)}
                className="flex flex-row justify-between"
              >
                <div className="line-clamp-1 flex flex-row">
                  {x.type === "dynamic" && (
                    <BsFillLightningChargeFill className="size-3 relative top-1" />
                  )}
                  <span className="max-w-80 truncate">{x.name}</span>
                </div>
                <div className="flex flex-row gap-1">
                  <Badge variant={"secondary"} className="relative shrink-0">
                    {x.variables.length} tags
                  </Badge>
                  <ClusterScoreBadge score={x.score} />
                </div>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  );
}

function CorrelatedTagsButton() {
  const s = useCorrelatedTagsDrawerStoreNotRequired();
  const clustersQuery = useAriaClustersQuery({
    filterType: "static",
  });
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  const allTags = useVariablesArrayQuery().data;

  if (!useAtomValue(Atoms.singleVariableViewAtom)) return null;
  if (!s) return null;

  if (!isVariableTrendLine(primaryTrendLine)) return null;

  const myClusters = clustersQuery.data?.filter((x) =>
    x.variables.includes(primaryTrendLine.bv.slice(24))
  );

  if (!myClusters?.length) return null;

  const all = [primaryTrendLine.bv.slice(24)];
  const excellent: string[] = [];
  const veryGood: string[] = [];
  const good: string[] = [];
  const fair: string[] = [];
  const poor: string[] = [];
  myClusters
    .sort((a, b) => (b.score || 0) - (a.score || 0))
    .forEach((c) => {
      const score = c.score || 0;
      const newVars = c.variables.filter((v) => !all.includes(v));
      if (score >= 95) {
        excellent.push(...newVars);
      } else if (score >= 90) {
        veryGood.push(...newVars);
      } else if (score >= 70) {
        good.push(...newVars);
      } else if (score >= 40) {
        fair.push(...newVars);
      } else {
        poor.push(...newVars);
      }
      all.push(...newVars);
    });
  const correlatedTags = {
    Excellent: excellent,
    "Very Good": veryGood,
    Good: good,
    Fair: fair,
    Poor: poor,
  };
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          size={BottomControlBar.BUTTON_SIZE}
          variant={"ghost"}
          onClick={() => {
            s.getState().setTags(correlatedTags);
            s.getState().setTagName(
              allTags?.find((x) => x._id === primaryTrendLine.bv.slice(24))
                ?.trimmedName
            );
          }}
        >
          <IoSparklesSharp className="size-3.5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Correlated Tags</TooltipContent>
    </Tooltip>
  );
}

function VariabilityButton({
  className,
  size,
}: PropsWithCn & Pick<ComponentProps<typeof Button>, "size">) {
  const selectedVariables = useAtomValue(Atoms.onlyVariableTrendLinesAtom);

  const [start, end] = useTzAwareDateStateDomain();

  const useVariabilityStore = useGetUseVariabilityDrawerStoreNotRequired();

  if (!useVariabilityStore || !selectedVariables.length) return;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={size ?? "icon"}
          onClick={() => {
            useVariabilityStore.getState().openDrawer({
              variables: iife(() => {
                const out = selectedVariables.map(({ bv, color }) => ({
                  color,
                  _id: bv.slice(24),
                }));

                if (!minLen1(out)) throw new Error("impossible");
                return out;
              }),
              defaultEnd: end,
              defaultStart: start,
            });
          }}
        >
          <TbChartHistogram
            // fontSize="1.2em"
            className="size-4 [&>path:first-child]:stroke-none"
            /* TODO: there might be a better way of handling this, but from
             * what I can see most other react-icons don't share this problem.
             * The icon wants to inherit stroke color, which is great, but it
             * also uses `stroke:none` internally, which gets overridden by
             * CSS. Not sure if it's just a bug or if there's meant to be a
             * more complicated wrapper used.*/
          />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Variability</TooltipContent>
    </Tooltip>
  );
}

export function useExitSlopingTrends() {
  const [start, end] = useTzAwareDateStateDomain();
  const setSlopingTrends = useSetAtom(Atoms.setSlopingTrendsAtom);
  return useCallback(() => {
    setSlopingTrends({
      on: false,
      newChartDomain: [start, end],
    });
  }, [start, end, setSlopingTrends]);
}

export function useEnterSlopingTrends() {
  const [, end] = useAtomValue(Atoms.getDomainAtom);
  const setSlopingTrends = useSetAtom(Atoms.setSlopingTrendsAtom);
  const zone = useTimezone();

  return useCallback(() => {
    const newEnd = DateTime.fromMillis(end, { zone })
      .endOf("day")
      .startOf("minute");

    const start = newEnd
      .minus({
        days: Math.max(defaultRangeDaysForSlopingTrends - 1, 0),
      })
      .startOf("day");

    setSlopingTrends({
      on: true,
      newChartDomain: [start.toMillis(), newEnd.toMillis()],
    });
  }, [end, setSlopingTrends, zone]);
}

function SlopingTrendsButton({
  className,
  size,
}: PropsWithCn & Pick<ComponentProps<typeof Button>, "size">) {
  const stEnabled = useAtomValue(Atoms.slopingTrendsAtom);
  const show = useAtomValue(Atoms.singleVariableViewAtom);
  const enterSlopingTrends = useEnterSlopingTrends();
  const exitSlopingTrends = useExitSlopingTrends();

  if (!show) return null;
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={stEnabled ? "default" : "ghost"}
          size={size ?? "icon"}
          onClick={() => {
            const newEnabled = !stEnabled;
            if (newEnabled) {
              enterSlopingTrends();
              return;
            }

            exitSlopingTrends();
          }}
        >
          <FaChartLine className="size-3.5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Sloping Trends</TooltipContent>
    </Tooltip>
  );
}

function NotificationButton({ className }: PropsWithCn) {
  const a = useAtomValue(Atoms.showNotificationsAtomAtom);
  if (!a) return null;
  return <_NotificationButton className={cn(className)} />;
}

function _NotificationButton({ className }: PropsWithCn) {
  const a = useAtomValue(Atoms.showNotificationsAtomAtom);
  if (!a) throw new Error("impossible");
  const setShow = useSetAtom(a);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={BottomControlBar.BUTTON_SIZE}
          onClick={() => setShow(true)}
        >
          <FaBell className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Notifications</TooltipContent>
    </Tooltip>
  );
}

function CreateCommentButton({ className }: PropsWithCn) {
  const setBrushStore = useSetAtom(Atoms.brushStoreAtom);
  const jot = useStore();
  const toggleFullscreen = useToggleFullscreen();
  const isFullscreen = useIsFullscreen()(jot);

  const { hideCommentPills } = useChartRenderOptions();

  const openFullscreenOnCommentPillClick = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom
  );

  const setSelectedCommentId = useSetAtom(Atoms.selectedCommentIdAtom);

  if (hideCommentPills) return null;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(className)}
          variant={"ghost"}
          size={BottomControlBar.BUTTON_SIZE}
          onClick={() => {
            // remove any selected comments if we're creating a new one
            setSelectedCommentId(undefined);
            setBrushStore({
              range: undefined,
              mode: "comment-create",
            });

            if (
              openFullscreenOnCommentPillClick &&
              toggleFullscreen &&
              !isFullscreen
            )
              toggleFullscreen(jot);
          }}
        >
          <FaComment className="size-3" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Create Comment</TooltipContent>
    </Tooltip>
  );
}

const domainEndAtom = atom((g) => g(Atoms.getDomainAtom)[1]);

function AckButton() {
  const primary = useAtomValue(Atoms.primaryBatchVariableAtom);

  const zone = useTimezone();

  const end = YYYY_MM_DD.parse(
    DateTime.fromMillis(useAtomValue(domainEndAtom), { zone }).toFormat(
      "yyyy-LL-dd"
    )
  );

  const invalidate = Acknowledgements.list.useInvalidate();

  const primaryIsVariable = isVariableTrendLine(primary);

  const mut = useMutation({
    mutationFn: ({
      unacknowledgment,
      type,
    }: { unacknowledgment: boolean } & Required<
      Pick<PostAckSchema, "type">
    >) => {
      if (!primaryIsVariable)
        throw new Error("This button should not be shown. Bug");
      return postAcknowledgement(b, {
        variable: primary.bv.slice(24),
        date: end,
        unacknowledgment,
        type,
      });
    },
    onSuccess: () => {
      invalidate();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });
  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);
  const acksQuery = useAcknowledgementsQuery(
    {
      variableId: primaryIsVariable ? primary.bv.slice(24) : "dummy-not-used",
      start: end,
      end,
    },
    {
      enabled: canRequestData && primaryIsVariable,
      staleTime: minutesToMilliseconds(5),
    }
  );
  const [hoverListOpen, setHoverListOpen] = useState(false);
  const b = useBaseUrlExperimental();

  const single = useAtomValue(Atoms.singleVariableViewAtom);

  if (acksQuery.isLoading || acksQuery.isError)
    return (
      <Button size={BottomControlBar.BUTTON_SIZE} variant={"ghost"} disabled>
        <Check className="size-4" />
      </Button>
    );

  const mostRecentMaybe = acksQuery.data[0];

  const isUnacked = !mostRecentMaybe || mostRecentMaybe.unacknowledgment;

  if (!single || !primaryIsVariable) return;

  return (
    <Tooltip>
      <TooltipContent>Acknowledge</TooltipContent>
      <HoverCardRoot
        open={!isUnacked && hoverListOpen}
        onOpenChange={(open) => {
          if (isUnacked) {
            setHoverListOpen(false);
            return;
          }
          setHoverListOpen(open);
        }}
        openDelay={400}
      >
        <Popover
          // sync popover and hover card to allow them to be open at the same time
          onOpenChange={(open) => {
            if (open) setHoverListOpen(open);
          }}
        >
          <HoverCardTrigger asChild>
            <PopoverTrigger asChild>
              <TooltipTrigger asChild>
                {iife(() => {
                  if (isUnacked)
                    return (
                      <Button
                        size={BottomControlBar.BUTTON_SIZE}
                        variant={"ghost"}
                      >
                        <Check className="size-4" />
                      </Button>
                    );

                  const Icon = iife(() => {
                    switch (mostRecentMaybe.type) {
                      case "issue":
                        return FaExclamationTriangle;
                      case "monitoring":
                        return FaSearchPlus;
                      default:
                        return Check;
                    }
                  });

                  return (
                    <Button
                      size={"xxs"}
                      variant={iife(() => {
                        if (isUnacked) return "ghost";
                        if (mostRecentMaybe.type === "issue")
                          return "destructive";
                        return "outline";
                      })}
                      className={cn(
                        mostRecentMaybe.type === "normal" &&
                          "text-indigo-500 focus:text-indigo-600 focus:bg-indigo-100 bg-indigo-100 border-indigo-500"
                      )}
                    >
                      <Icon className="size-3.5" />
                      {!isUnacked && (
                        <span className="ml-1">
                          {mostRecentMaybe.author.first[0]}.{" "}
                          {mostRecentMaybe.author.last}
                        </span>
                      )}
                    </Button>
                  );
                })}
              </TooltipTrigger>
            </PopoverTrigger>
          </HoverCardTrigger>
          <PopoverContent className="p-1">
            <div className="flex flex-col">
              <Button
                onClick={
                  mut.isLoading
                    ? undefined
                    : () => {
                        mut.mutate({
                          unacknowledgment: isUnacked
                            ? false
                            : mostRecentMaybe.type === "issue",
                          type: "issue",
                        });
                      }
                }
                className={cn(
                  "text-red-500 focus:text-red-600 hover:bg-red-100 py-2 px-4",
                  !isUnacked && mostRecentMaybe.type === "issue" && "bg-red-100"
                )}
                variant={"ghost"}
              >
                <FaExclamationTriangle className="size-4 mr-3" />
                Needs Attention
              </Button>
              <Button
                onClick={
                  mut.isLoading
                    ? undefined
                    : () => {
                        mut.mutate({
                          unacknowledgment: isUnacked
                            ? false
                            : mostRecentMaybe.type === "normal",
                          type: "normal",
                        });
                      }
                }
                className={cn(
                  "text-indigo-500 focus:text-indigo-600 px-4 py-2 focus:bg-indigo-100",
                  !isUnacked &&
                    mostRecentMaybe.type !== "issue" &&
                    "bg-indigo-100"
                )}
                variant={"ghost"}
              >
                <Check className="size-4 mr-3" />
                Acknowledged
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        <HoverCardContent className="w-72 max-h-[500px] overflow-scroll p-0">
          <span className="block pl-2 text-[0.9rem] py-1 text-gray-600">
            Acknowledgments:
          </span>
          <ul>
            {acksQuery.data.map((ack) => (
              <li
                key={ack._id}
                className="border-bordgrey2 border-b pl-4 py-1 first-of-type:border-t text-xs"
              >
                <div className="flex items-center">
                  <div className="flex-1">
                    <div className="text-gray-600">
                      {!ack.unacknowledgment ? (
                        !ack.type || ack.type === "normal" ? (
                          <FaCheck className="inline mr-1" />
                        ) : (
                          <FaExclamationTriangle className="inline mr-1" />
                        )
                      ) : null}
                      {ack.unacknowledgment
                        ? `Unacked by ${ack.author.first} ${ack.author.last}`
                        : `Acked by ${ack.author.first} ${ack.author.last}`}
                    </div>
                    <div className="text-gray-400">
                      {moment(ack.created_at).format("LLL")}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </HoverCardContent>
      </HoverCardRoot>
    </Tooltip>
  );
}

export { BottomControls };
