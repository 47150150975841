import React, { useState } from "react";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import useHasEditPermission from "../../../zustand/useHasEditPermission";

import Button from "../../common/Button/Button";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import {
  useGroupsQuery,
  useInvalidateShutdownRulesQuery,
  useShutdownRulesQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { ShutdownCard } from "./shutdown-card";
import { ShutdownRuleForm } from "./shutdown-form";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useMutation } from "@tanstack/react-query";
import { createShutdownRule } from "../../../frameworks/fetcher/api-routes-experimental";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

const PAGE_NAME = "Shutdown Rules";

function useCreateShutdownRuleMutation(closeForm: () => void) {
  const invalidateListQuery = useInvalidateShutdownRulesQuery();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: (payload: Parameters<typeof createShutdownRule>[1]) => {
      return createShutdownRule(baseUrl, payload);
    },
    onSuccess: (sdr) => {
      closeForm();
      invalidateListQuery();
      addSuccessToast(`${sdr.name} created`);
    },
    onError: (e) => addUnknownErrorToast(e),
  });
}

export function ShutdownRulesManager() {
  const [createMode, setCreateMode] = useState(false);
  const createMut = useCreateShutdownRuleMutation(() => setCreateMode(false));

  usePermissionBasedDocumentTitleForSettingsPage();
  const hasEditPermission = useHasEditPermission();
  const shutdownsQuery = useShutdownRulesQuery();
  const groupsQuery = useGroupsQuery();
  const variablesQuery = useVariablesArrayQuery();

  const anyQueryLoading =
    shutdownsQuery.isLoading ||
    groupsQuery.isLoading ||
    variablesQuery.isLoading;
  const anyQueryError =
    shutdownsQuery.isError || groupsQuery.isError || variablesQuery.isError;

  function download() {
    if (!groupsQuery.data || !shutdownsQuery.data || !variablesQuery.data)
      return;

    const data = shutdownsQuery.data?.map((r) => {
      return {
        "RULE NAME": r.name,
        'RULE DESCRIPTION"': r.description,
        EXPRESSION: r.expression,
        GROUPS: r.bindingGroupIds
          .map((gid) => {
            const g = groupsQuery.data.find((g) => g._id === gid);
            if (!g) throw new Error("group not found " + gid);
            return g.name;
          })
          .join(","),
        TAGS: r.bindingVariableIds
          .map((vid) => {
            const v = variablesQuery.data.find((v) => v._id === vid);
            if (!v) throw new Error("tag not found " + vid);
            return v.trimmedName;
          })
          .join(","),
      };
    });

    downloadXLSX(data, "dra_shutdown_rules.xlsx");
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditPermission && (
            <CreateNewEntityButton onClick={() => setCreateMode(true)} />
          )}
        </div>
        <div>
          {shutdownsQuery.data && (
            <span className="mr-5 italic text-[1.25rem] text-titlegrey">
              Displaying {shutdownsQuery.data.length} {PAGE_NAME}
            </span>
          )}
          <Button
            disabled={anyQueryError || anyQueryLoading}
            icon="download"
            iconClasses="mr-1"
            onClick={download}
            className="btn-ghost"
          >
            download
          </Button>
        </div>
      </div>
      {createMode && (
        <ShutdownRuleForm
          close={() => setCreateMode(false)}
          disabled={createMut.isLoading}
          onSubmit={(payload) => {
            createMut.mutate(payload);
          }}
        />
      )}

      <div className="drop-shadow-md my-5">
        {shutdownsQuery.data && shutdownsQuery.data.length > 0 ? (
          shutdownsQuery.data.map((rule, idx) => {
            return <ShutdownCard key={rule._id} rule={rule} />;
          })
        ) : (
          <div className="flex text-textgrey justify-center text-[1.5rem] mt-5">
            No {PAGE_NAME.toLowerCase()} to show
          </div>
        )}
      </div>
    </>
  );
}
