import { cn } from "../../../lib/utils";

export function RuleFreqDisplay({
  frequency,
  rule,
  className,
}: {
  rule: string;
  frequency: string;
  className?: string;
}) {
  return (
    <div className={cn("mx-6", className)}>
      <div>
        <span className="text-[1rem] tracking-tight text-zinc-500 font-semibold">
          Rule:
        </span>
        <span className="rounded bg-[#e6f0f3] px-1 text-[0.85em] ml-2 text-notifblue font-mono">
          {rule}
        </span>
      </div>
      <div>
        <span className="text-[1rem] tracking-tight text-zinc-500 font-semibold">
          Frequency:
        </span>
        <span className="ml-2 tracking-tight text-[1rem] text-zinc-800">
          {frequency}
        </span>
      </div>
    </div>
  );
}
