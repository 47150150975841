import * as R from "remeda";
import { HistRectPadding } from "../draw/drawHistogram";
import { ChartDimensions, SvgSelector } from "../types";
import { HistogramClass, TooltipLineClass } from "./chartUtils";
import * as d3 from "d3";

/**
 * move tooltip line on hover and execute callback functions
 * @param svg - SVG being hovered over
 * @param dimensions - Dimensions of the SVG Container div
 * @param hover - optional callback functions to execute
 */
export const drawHoverLineAndApplyHoverCallbacks = (
  svgSelector: SvgSelector,
  { width, margin }: ChartDimensions,
  hover?: {
    onHover: (svgX: number, windowX: number) => void;
    reset: () => void;
  }
) => {
  // make tooltip line if doesn't exist
  const tooltipLine = svgSelector.selectAll(`.${TooltipLineClass}`);

  /**
   * draw tooltip line on hover and execute callback functions
   * @param event - mouse event
   */
  function handleHover(event: MouseEvent) {
    const x = d3.pointer(event)[0]; // get x position relative to svg dimensions

    // if within margins then move tooltip line
    if (x >= margin && x <= width - margin) {
      // show and move tooltip line
      tooltipLine.attr("transform", `translate(${x}, 0)`);
      tooltipLine.style("opacity", 1);
    }

    hover?.onHover(x, event.clientX); // pass in mouse coordinate
  }

  svgSelector.on("mousemove", handleHover);

  // on mouse leave, hide tooltip and reset hover functions
  svgSelector.on("mouseleave", function () {
    tooltipLine.style("opacity", 0); // hide the hover line
    hover?.reset();
  });
};

/**
 * Get the variable that should be highlighted based on histogram rect height
 * @param x - x-coordinate of mouse
 * @returns - element that should be highlighted
 */
export function getHoverElementInHistogram(x: number, chart: SvgSelector) {
  const graphRects = chart
    ? chart.selectAll(`.${HistogramClass}`).selectChildren("rect")
    : d3.selectAll(`.${HistogramClass}`).selectChildren("rect");

  // get data from rects
  const rectData = graphRects.nodes().filter((d: d3.BaseType) => {
    const xPos: string | null = (d as Element).getAttribute("x");
    const width: string | null = (d as Element).getAttribute("width");
    return (
      xPos && width && x >= +xPos && x <= +xPos + (+width + HistRectPadding)
    );
  });

  /* highlighted element goes last to render in front
    go in reverse order to make sure highlighted variable does not change
    if it has the same height as another rect on another graph */
  const highestYValue = R.pipe(
    rectData,
    R.reverse(),
    R.firstBy([(d) => getRectHeight(d), "desc"])
  );

  return highestYValue ? (highestYValue as Element).parentElement : null;
}

/**
 * Helper function to get height of rect
 * @param rect - SVG rect element
 * @returns - height of rect as a number
 */
function getRectHeight(rect: d3.BaseType) {
  const yValue = (rect as Element).getAttribute("height");
  if (yValue) return +yValue;
  throw Error("Could not find height of rect");
}
