import { Alert } from "@mantine/core";
import React from "react";
import Input from "../../../common/Input/Input";
import { type Image as ImageType } from "../types/BuilderTypes";
import {
  CODENAME_INPUT_PLACEHOLDER,
  FILENAME_INPUT_PLACEHOLDER,
} from "./constants";
import type EditorProps from "./EditorProps";
import MarginInputs from "./MarginInputs";
import { validateCodename, validateFilename } from "./validators";

function Image({ content, editContent }: EditorProps<ImageType>) {
  const err =
    validateCodename(content.codename) || validateFilename(content.filename);

  const errComponent = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  return (
    <div className="flex flex-col gap-2">
      {errComponent}
      <MarginInputs editContent={editContent} content={content} />
      <div className="form-control">
        <label className="input-group input-group-sm flex">
          <span>Filename</span>
          <Input
            className="grow"
            placeholder={FILENAME_INPUT_PLACEHOLDER}
            bordered
            size="sm"
            required
            value={content.filename}
            onChange={(e) => {
              editContent((c) => {
                c.filename = e.target.value;
              });
            }}
          />
        </label>
      </div>

      <div className="form-control">
        <label className="input-group input-group-sm flex">
          <span>Codename</span>
          <Input
            placeholder={CODENAME_INPUT_PLACEHOLDER}
            className="grow"
            bordered
            size="sm"
            required
            value={content.codename}
            onChange={(e) => {
              editContent((c) => {
                c.codename = e.target.value;
              });
            }}
          />
        </label>
      </div>
    </div>
  );
}

export default Image;
