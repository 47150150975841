import React from "react";
import "./Error.scss";

function PageError(props) {
  const classes = props.classes || {};
  const message =
    props.message || "An error has occured. Please refresh the page.";

  return (
    <div className={`Error ${classes.Error || ""}`}>
      {/* This is wrapped because of some font-awesome CSS decisions.
       * Font-awesome aggressively inherits font-size from the parent element.
       * It resists setting font-size directly on the icon. */}
      <div className={`Error__icon ${classes.Error__icon || ""}`}>
        <i className={`fa fa-exclamation-triangle`} aria-hidden="true"></i>
      </div>
      <div className={`Error__label ${classes.Error__label || ""}`}>
        {message}
      </div>
    </div>
  );
}

export default PageError;
