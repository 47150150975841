import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { create } from "zustand";
import { variableSchema } from "../../../lib/api-validators";

type QueryBy =
  | "All attributes"
  | "Tag ID"
  | "description"
  | "expression"
  | "groups associated"
  | "units of measurement";

type TagsManagerState = {
  createMode: boolean;
  setCreateMode: (createMode: boolean) => void;
  sort: PropertyKey;
  setSort: (sort: string) => void;
  filter: PropertyKey;
  setFilter: (filter: string) => void;
  query: string;
  setQuery: (query: string) => void;

  queryBy: QueryBy;
  setQueryBy: (queryBy: QueryBy) => void;

  enabledFilters: Record<PropertyKey, (t: variableSchema) => boolean>;
  enabledSorts: Record<
    PropertyKey,
    (a: variableSchema, b: variableSchema) => number
  >;

  isStaleTagsPage: boolean;
};

type InitialDataForStore = {
  initialSort: TagsManagerState["sort"];
  initialFilter: TagsManagerState["filter"];
  enabledFilters: TagsManagerState["enabledFilters"];
  enabledSorts: TagsManagerState["enabledSorts"];
  isStaleTagsPage: boolean;
};

function createUseTagsManagerStore({
  initialFilter,
  initialSort,
  enabledFilters,
  enabledSorts,
  isStaleTagsPage,
}: InitialDataForStore) {
  return create<TagsManagerState>((set) => ({
    createMode: false,
    setCreateMode: (createMode) => set({ createMode }),
    sort: initialSort,
    setSort: (sort) => set({ sort }),
    filter: initialFilter,
    setFilter: (filter) => set({ filter }),
    query: "",
    setQuery: (query) => set({ query }),
    queryBy: "All attributes",
    setQueryBy: (queryBy) => set({ queryBy }),
    enabledFilters,
    enabledSorts,
    isStaleTagsPage,
  }));
}

type UseTagsManagerStore = ReturnType<typeof createUseTagsManagerStore>;

const TagsManagerStoreContext = createContext<UseTagsManagerStore | undefined>(
  undefined
);

function useGetUseTagsManagerStore() {
  const useStore = useContext(TagsManagerStoreContext);
  if (useStore === undefined) {
    throw new Error(
      "useGetUseTagsManagerStore must be used within a UsersManagerStoreProvider"
    );
  }
  return useStore;
}

function UseTagsManagerStoreProvider<
  T extends Record<string, (t: variableSchema) => boolean>,
  S extends Record<string, (a: variableSchema, b: variableSchema) => number>,
>({
  children,
  ...rest
}: PropsWithChildren<{
  enabledFilters: T;
  initialFilter: keyof T;
  enabledSorts: S;
  initialSort: keyof S;
  isStaleTagsPage: boolean;
}>) {
  const [s] = useState(() => createUseTagsManagerStore(rest));

  return (
    <TagsManagerStoreContext.Provider value={s}>
      {children}
    </TagsManagerStoreContext.Provider>
  );
}

export { useGetUseTagsManagerStore, UseTagsManagerStoreProvider, type QueryBy };
