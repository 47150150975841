import { SettingsSidebar } from "../nav/settings-sidebar";
import MainLayout from "./MainLayout";
import { Outlet } from "react-router-dom";

function ManagerLayoutOutlet() {
  return (
    <MainLayout showDateNav={false} managerPage={true} disableAnimation>
      <div className="grid grid-cols-12 gap-2">
        {/* nav bar  */}
        <SettingsSidebar />

        {/* the rest of the page, right side  */}
        <div className="col-span-12 lg:col-span-10 px-4 2xl:px-[10rem] animate-in slide-in-from-bottom-7 duration-300">
          <Outlet />
        </div>
      </div>
    </MainLayout>
  );
}

export { ManagerLayoutOutlet };
