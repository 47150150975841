import { cva, type VariantProps } from "class-variance-authority";

export const toastVariant = cva(
  "animate-in slide-in-from-top-7 rounded-md border px-4 py-3 w-[50vw]",
  {
    variants: {
      variant: {
        success: "border-xgreen-8 bg-xgreen-3 text-xgreen-11",
        primary: "border-xindigo-8 bg-xindigo-3 text-xindigo-11",
        danger: "border-xred-8 bg-xred-3 text-xred-11",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
);

export type ToastVariantOptions = VariantProps<typeof toastVariant>;
