import * as R from "remeda";
import { RidgelineResponse } from "../../../types/api/RidgelineResponse";
import {
  ChartDimensions,
  Domain,
  DrawDataAtLeast1,
  Scale,
  SingleDrawData,
  VariabilityGroup,
} from "../types";
import { scaleLinear } from "d3";
import { OperatingLimit } from "../../../lib/api-schema/operating-limit";
import { CapabilityStats } from "../capability-store";

export const TooltipLineClass = "tooltip-line";
export const USLLine = "usl-line";
export const LSLLine = "lsl-line";
export const TargetLine = "target-line";
export const HistogramClass = "histogram";
export const DensityClass = "density";
export const BoxPlotClass = "boxplot";

/**
 * Formatting data for the charts by combining variable with data from query
 * @param data - query data
 * @param groups - all groups in variability chart
 * @returns - array of formatted data
 */
export function setUpData(
  data: RidgelineResponse[],
  groups: [VariabilityGroup, ...VariabilityGroup[]],
  capabilityData: CapabilityStats[]
): SingleDrawData[] | undefined {
  const withColor = data.map((x) => {
    if (!x.ref) throw new Error("We need ref to keep track of colors");

    // get variable that matches data piece
    const variable = groups
      .flatMap((b) => b.variables)
      .find((v) => v.id === x.ref);

    if (!variable || !variable.color) return undefined; // race condition of the query updating, but not the groups state yet. this is fine. just draw what we can because another render is coming very soon.

    const capabilityStats = capabilityData.find((stat) => stat.ref === x.ref);
    return {
      ...x,
      ...variable,
      usl: capabilityStats?.usl,
      lsl: capabilityStats?.lsl,
      target: capabilityStats?.target,
    };
  });
  const withoutUndefineds = R.filter(withColor, R.isTruthy);

  if (withoutUndefineds.length === 0) return;

  return withoutUndefineds;
}

/**
 * Sorting data by putting the selected or hovered variable last to render in front
 * @param data - data to draw
 * @returns - array of possibly sorted data
 */
export function sortData(data: DrawDataAtLeast1) {
  return data.sort((a, b) => {
    if ((a.selected && !b.selected) || (a.hovered && !b.hovered)) return 1;
    if ((!a.selected && b.selected) || (!a.hovered && b.hovered)) return -1;
    return 0;
  });
}

/**
 * Create a X-axis scale from all variable domain values
 * @param xValues - domain x values
 * @param ChartDimensions - destructured chart dimensions
 * @param limits - limits for variable
 * @returns - returns a d3 Scale for x-axis
 */
export function getConsolidatedXScale(
  xValues: [number, number][],
  { width, margin }: ChartDimensions,
  limits?: Pick<OperatingLimit, "value">[] | undefined
): Scale {
  const minStart = Math.min(...xValues.map((d) => d[0]));
  const maxEnd = Math.max(...xValues.map((d) => d[1]));

  const out: [number, number] = [minStart, maxEnd];

  if (limits !== undefined && limits.length > 0) {
    /* We expect limits to be sorted.
     * TODO Should we? I mean, they are sorted, but it's not like
     * we couldn't handle the edge case. */
    out[0] = Math.min(out[0], R.last(limits)!.value);
    out[1] = Math.max(out[1], R.first(limits)!.value);
  }

  if (isNaN(out[0]) || isNaN(out[1])) {
    console.log(minStart, maxEnd);
    throw new Error("Could not consolidate X Domain");
  }

  return createLinearScale(out, [margin, width - margin]);
}

/**
 * Create a scale for the charts
 * @param values - values for domain
 * @param range - values for range
 * @returns - linear scale for charts
 */
export function createLinearScale(
  values: [number, number],
  range: [number, number]
): Scale {
  return scaleLinear().domain(values).range(range);
}

/**
 * Extract domain values from variable data
 * @param data - All variables to draw
 * @returns - Array of domains for each variable
 */
export function getDomainsFromData(
  data: SingleDrawData[]
): (Domain | undefined)[] {
  return R.map(data, (singleDrawData) => {
    if (!singleDrawData.values.length) {
      return undefined;
    }
    const lowX = R.first(singleDrawData.values)!.x0;
    const highX = R.last(singleDrawData.values)!.x1;

    const { lsl, usl, target } = singleDrawData;

    const lowCapabilityValue =
      lsl !== undefined && target !== undefined
        ? Math.min(lsl, target)
        : lsl !== undefined
          ? lsl
          : target !== undefined
            ? target
            : lowX;
    const highCapabilityValue =
      usl !== undefined && target !== undefined
        ? Math.max(usl, target)
        : usl !== undefined
          ? usl
          : target !== undefined
            ? target
            : highX;

    const domains: Domain = {
      id: singleDrawData.id,
      xDomain: [
        lowCapabilityValue !== undefined
          ? Math.min(lowCapabilityValue, lowX)
          : lowX,
        highCapabilityValue !== undefined
          ? Math.max(highCapabilityValue, highX)
          : highX,
      ],
      yDomain: [0, Math.max(...singleDrawData.values.map((v) => v.value))],
      yDensity: [
        0,
        Math.max(...singleDrawData.densityData.map((tuple) => tuple[1])),
      ],
    };

    // setting default domain values
    if (domains.xDomain[0] === 0 && domains.xDomain[1] === 0) {
      domains.xDomain = [-0.1, 0.1];
    }

    if (domains.yDomain[1] === 0) {
      domains.yDomain = [0, 1];
    }

    if (domains.yDensity && domains.yDensity[1] === 0) {
      domains.yDensity = [0, 1];
    }

    return domains;
  });
}
