import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import * as R from "remeda";
import { BaseUrl, useBaseUrlExperimental } from "../zustand/useBaseUrl";
import {
  APIRouteParamsWithoutBaseUrl,
  getComment_TODO_VALIDATE,
  getExceedanceGroupCounts,
  getFaultTrees,
  getGroups,
  getLimitsForVariable,
  getOperatingLimitProbabilities,
  getProbabilityOrExceedanceVariables,
  getRisks,
  getShutdownRules,
  getSpecialtyReports,
  getTeams,
  getUsers,
  getVariables,
  setWatchlist,
  getSlopes,
  saveExpression,
  getSavedExpressions,
  getInstantCalculation,
  getTimeseries,
  getAcknowledgements,
  getLabels,
  GetCommentsListOpts,
  getCommentsList,
  getSlopeById,
  getAriaClusters,
  getClusterDriftScore,
  toggleAria,
  saveCluster,
  deleteCluster,
  getAriaTimeseries,
  getAnomaly,
  deleteSavedExpression,
  getVariablesSlopes,
  getFreshAnomalies,
  getFolders,
  getCommentedVariables,
  getAcknowledgedVariables,
  getSectionForUnit,
  getAnomalySummariesForDay,
} from "../frameworks/fetcher/api-routes-experimental";
import {
  anomalySchema,
  groupSchema,
  variableSchema,
} from "../lib/api-validators";
import { clusterSchema } from "../lib/api-schema/cluster";
import { sortedLevels } from "../lib/api-schema/operating-limit";
import {
  addToast,
  addUnknownErrorToast,
} from "../components/toast/use-toast-store";
import {
  minutesToMilliseconds,
  secondsToMilliseconds,
  hoursToMilliseconds,
} from "date-fns";
import { YYYY_MM_DD } from "../lib/validators";
import { type DRATimeseries } from "../frameworks/fetcher/utils";
import { SavedExpressionVariable } from "../components/instant-calculator/types";
import { OVERALL_GROUP_NAME } from "../lib/api-schema/misc";

export type InferQueryResultData<T> =
  T extends UseQueryResult<infer R> ? R : never;

/**
 * getVariables can look different depending on what incldue options you pass it.
 */
const getRawVariables = (unitName: BaseUrl, filter?: string[]) =>
  getVariables(unitName).then((variables) => {
    return variableSchema
      .array()
      .parse(variables)
      .filter((v) => {
        if (filter) {
          return filter.includes(v._id);
        }
        return true;
      });
  });

export function useSpecialtyReportsQuery(b: BaseUrl) {
  return useQuery({
    queryFn: () => getSpecialtyReports(b),
    queryKey: [b, "specialtyReports"],
    refetchOnWindowFocus: false,
  });
}

export function useLabelsQuery() {
  return useQuery({
    queryKey: ["labels"],
    queryFn: getLabels,
    staleTime: Infinity,
  });
}

function useGetVariableQueriesKeys() {
  const unitName = useBaseUrlExperimental();

  return {
    array: ["variables", unitName],
    mappedById: ["variablesMappedById", unitName],
  } as const;
}

export function useInvalidateVariablesQuery() {
  const cli = useQueryClient();
  const keys = useGetVariableQueriesKeys();

  return () => {
    cli.invalidateQueries(keys.array);
    cli.invalidateQueries(keys.mappedById);
  };
}

export type BaseQueryOptions = Pick<
  UseQueryOptions,
  "staleTime" | "enabled" | "keepPreviousData" | "cacheTime"
> & { refetchOnMount?: boolean };

export function useAnomalousVariablesQuery(
  payload: {
    varIds?: string[];
    groupShortId?: string;
  },
  dateWithoutTime: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [
      baseUrl,
      "anomalous-variables",
      dateWithoutTime,
      payload.varIds?.slice().sort(),
      payload.groupShortId,
    ],
    queryFn: async () => {
      const data = await getAnomaly(baseUrl, payload, dateWithoutTime);
      return anomalySchema.array().parse(data);
    },
    ...opts,
  });
}

export function useFreshAnomaliesQuery(
  payload: Partial<{
    deg1array: string[];
    deg2array: string[];
    deg3array: string[];
  }> & {
    date: YYYY_MM_DD;
  },
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  const allVariables = (payload.deg1array ?? [])
    .concat(payload.deg2array ?? [], payload.deg3array ?? [])
    .sort();

  return useQuery({
    queryKey: [baseUrl, "fresh-anomalies", payload.date, allVariables],
    queryFn: async () => {
      const out = await getFreshAnomalies(baseUrl, {
        date: payload.date,
        deg1array: (payload.deg1array ?? []).slice().sort(),
        deg2array: (payload.deg2array ?? []).slice().sort(),
        deg3array: (payload.deg3array ?? []).slice().sort(),
      });

      return out;
    },
    ...opts,
  });
}

export function useSlopesForGroupQuery({
  date,
  groupId,
  opts,
}: {
  date: YYYY_MM_DD;
  groupId: string;
  opts?: BaseQueryOptions;
}) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [baseUrl, "group-slopes", groupId, date],
    queryFn: () =>
      getVariablesSlopes(baseUrl, {
        date,
        groupId,
      }),
    ...opts,
  });
}

export function useWatchlistMutation() {
  const baseUrl = useBaseUrlExperimental();
  const onSuccess = useInvalidateVariablesQuery();
  return useMutation({
    mutationFn: async (payload: { _id: string; watchlist: boolean }) => {
      return setWatchlist(baseUrl, payload._id, payload.watchlist);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      onSuccess();
    },
  });
}

export function usePublishedFaultTreesQuery() {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-trees", "published-only", baseUrl],
    queryFn: () =>
      getFaultTrees(baseUrl).then((trees) => {
        return trees.filter((t) => t.published);
      }),
    refetchOnWindowFocus: false,
  });
}

export function useOperatingLimitsProbabilityQuery(
  payload: Parameters<typeof getOperatingLimitProbabilities>[1],
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["operating-limits-probabilities", b, payload],
    queryFn: () => {
      return getOperatingLimitProbabilities(b, payload);
    },
    refetchOnWindowFocus: false,
    ...opts,
  });
}

export function useVariablesArrayQuery() {
  const unitName = useBaseUrlExperimental();
  const key = useGetVariableQueriesKeys().array;

  return useQuery({
    queryKey: key,
    queryFn: () => getRawVariables(unitName),
  });
}

export function useVariablesMappedByIdQuery() {
  const unitName = useBaseUrlExperimental();
  const key = useGetVariableQueriesKeys().mappedById;

  return useQuery({
    queryKey: key,
    queryFn: () =>
      getRawVariables(unitName).then((variables) =>
        Object.fromEntries(variables.map((v) => [v._id, v]))
      ),
  });
}

export function useGroupsQueryKey() {
  const baseUrlSlash = useBaseUrlExperimental();
  return ["groups", baseUrlSlash] as const;
}

export function useInvalidateGroupsQuery() {
  const cli = useQueryClient();
  const key = useGroupsQueryKey();
  return () => {
    cli.invalidateQueries(key);
  };
}

export function useGroupsQuery() {
  const key = useGroupsQueryKey();

  return useQuery({
    queryKey: key,
    queryFn: async () => {
      const groups = await getGroups(key[1]);
      const groups_1 = groupSchema.array().parse(groups);
      return groups_1.filter((g) => !g.deleted);
    },
  });
}

export function useGroupsMappedByIdQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["groupsMap", baseUrlSlash],
    queryFn: async () => {
      const groups = await getGroups(baseUrlSlash);
      const parsed = groupSchema.array().parse(groups);
      return R.pipe(
        parsed,
        R.filter((g) => !g.deleted),
        R.mapToObj((g) => {
          const { deleted, ...rest } = g;
          if (deleted) throw new Error("shouldn't be deleted");
          return [g._id, { ...rest, deleted: false as const }];
        })
      );
    },
  });
}

export const Comments = {
  list: {
    useKey: (b: BaseUrl) => {
      return [b, "comments-list"] as const;
    },
    useQuery: function (
      opts: GetCommentsListOpts,
      queryOpts?: BaseQueryOptions
    ) {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: [...this.useKey(b), opts],
        queryFn: () => getCommentsList(b, opts),
        refetchOnWindowFocus: false,
        ...queryOpts,
      });
    },
    useInvalidate: function () {
      const key = this.useKey(useBaseUrlExperimental());
      const cli = useQueryClient();
      return () => {
        cli.invalidateQueries(key);
      };
    },
  },
  single: {
    _baseKey: "singleComment",
    _getKey: function (b: BaseUrl, _id: string | undefined) {
      return [this._baseKey, b ?? "dummy", _id] as const;
    },
    useQuery: function (_id: string | undefined) {
      const b = useBaseUrlExperimental();

      return useQuery({
        queryKey: this._getKey(b, _id),
        queryFn: () =>
          getComment_TODO_VALIDATE(
            b,
            _id ?? "ensure you make use of enabled flag in useQuery"
          ),
        enabled: !!_id,
      });
    },
    useInvalidate: function () {
      const cli = useQueryClient();
      return () => cli.invalidateQueries([this._baseKey]);
    },
  },
  commentedVariables: {
    _baseKey: "commentedVariables",
    _getKey: function (b: BaseUrl, start: number, end: number) {
      return [this._baseKey, b, start, end] as const;
    },
    useQuery: function (start: number, end: number) {
      const b = useBaseUrlExperimental();

      return useQuery({
        queryKey: this._getKey(b, start, end),
        queryFn: () => getCommentedVariables(b, start, end),
      });
    },
    useInvalidate: function () {
      const cli = useQueryClient();
      return () => cli.invalidateQueries([this._baseKey]);
    },
  },
};

export function useClustersQuery() {
  // todo naming
  return useAriaClustersQuery();
  // const baseUrlSlash = useBaseUrlExperimental();
  // return useQuery({
  //   queryKey: ["clusters", baseUrlSlash],
  //   queryFn: async () => {
  //     const clusters = await getClusters(baseUrlSlash);
  //     return clusterSchema.array().parse(clusters);
  //   },
  // });
}

function useGetUsersQueryKey() {
  const baseUrlSlash = useBaseUrlExperimental();
  return ["users", baseUrlSlash] as const;
}

function useInvalidateUsersQuery() {
  const key = useGetUsersQueryKey();
  const cli = useQueryClient();
  return () => cli.invalidateQueries(key);
}

export function useUsersQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: useGetUsersQueryKey(),
    queryFn: () => getUsers(baseUrlSlash),
  });
}

export function useTeamsQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["teams", baseUrlSlash],
    queryFn: () => getTeams(baseUrlSlash),
  });
}

export function useOperatingLimitsQuery(
  variableId: string,
  opts?: BaseQueryOptions
) {
  const baseUrlSlash = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["operatingLimits", baseUrlSlash, variableId],
    queryFn: async () => {
      const limits = await getLimitsForVariable(baseUrlSlash, variableId);

      const out = limits
        .map(({ data, ...rest }) => ({
          ...rest,
          data: data.map(({ end, start, ...rest2 }) => ({
            ...rest2,
            end: end ? new Date(end).getTime() : null, // all this just to convert to Date
            start: new Date(start).getTime(), // all this just to convert to Date
          })),
        }))
        .sort((a, b) => {
          // I want them to be in the order of sortedLevels
          const aLevelIndex = sortedLevels.indexOf(a.level);
          const bLevelIndex = sortedLevels.indexOf(b.level);

          if (aLevelIndex === -1 || bLevelIndex === -1) {
            throw new Error("Unrecognized level");
          }

          return aLevelIndex - bLevelIndex;
        });

      return out;
    },
    staleTime: minutesToMilliseconds(2),
    ...opts,
  });
}

export function useOperatingLimitsProbabilityOrExceedanceVariablesQuery(
  enabled: boolean,
  ...args: APIRouteParamsWithoutBaseUrl<
    typeof getProbabilityOrExceedanceVariables
  >
) {
  const unitName = useBaseUrlExperimental();
  const [query] = args;
  return useQuery({
    queryKey: ["ol-probability-or-exceedance-variables", unitName, query],
    queryFn: () => getProbabilityOrExceedanceVariables(unitName, query),
    enabled,
    refetchOnWindowFocus: false,
    staleTime: minutesToMilliseconds(5),
    keepPreviousData: true, // we're using this data to render d3 chart, so while a new request fires off, keep the old data so the chart doesn't flicker (draws empty data then draws new data when it's avaialble)
  });
}

export function useExceedanceGroupCountsQuery(
  enabled: boolean,
  ...args: APIRouteParamsWithoutBaseUrl<typeof getExceedanceGroupCounts>
) {
  const unitName = useBaseUrlExperimental();
  const [query] = args;
  return useQuery({
    queryKey: ["exceedance-group-counts", unitName, query],
    queryFn: () => getExceedanceGroupCounts(unitName, query),
    enabled,
    keepPreviousData: true, // we're using this data to render d3 chart, so while a new request fires off, keep the old data so the chart doesn't flicker (draws empty data then draws new data when it's avaialble)
  });
}

function getShutdownRulesQueryKey(unitName: BaseUrl) {
  return ["shutdown-rules", unitName] as const;
}

export function useInvalidateShutdownRulesQuery() {
  const cli = useQueryClient();
  const unitName = useBaseUrlExperimental();
  const key = getShutdownRulesQueryKey(unitName);

  return () => {
    cli.invalidateQueries(key);
  };
}

export function useShutdownRulesQuery() {
  const unitName = useBaseUrlExperimental();
  const queryKey = getShutdownRulesQueryKey(unitName);

  return useQuery({
    queryKey: queryKey,
    queryFn: () => getShutdownRules(unitName),
  });
}

export function useAriaQuery(
  variables: string[],
  cluster: string,
  start: string,
  end: string,
  enabled: boolean
) {
  const unitName = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["aria", unitName, start, end, variables.slice().sort(), cluster],
    queryFn: () => {
      return getAriaTimeseries(unitName, variables, cluster, start, end);
    },
    enabled,
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
    keepPreviousData: true,
  });
}

export function useAnomalySummariesForDay(
  variables: string[],
  start: YYYY_MM_DD,
  end: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const unitName = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["anomaly-summaries", unitName, start, end, variables.sort()],
    queryFn: () => {
      return getAnomalySummariesForDay(unitName, {
        varIds: variables,
        start,
        end,
      });
    },
    ...opts,
  });
}

function usePersonalFoldersQueryKey() {
  const baseUrl = useBaseUrlExperimental();
  return [baseUrl, "personal-folders"] as const;
}

export const PersonalFoldersQuery = {
  useQuery: usePersonalFoldersQuery,
  useKey: usePersonalFoldersQueryKey,
  useInvalidate: () => {
    const key = usePersonalFoldersQueryKey();
    const cli = useQueryClient();

    return () => {
      cli.invalidateQueries(key);
    };
  },
};

export function usePersonalFoldersQuery(opts?: BaseQueryOptions) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: usePersonalFoldersQueryKey(),
    queryFn: () => getFolders(baseUrl),
    ...opts,
  });
}

export function useClusterDriftScoreQuery(start: YYYY_MM_DD, end: YYYY_MM_DD) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["cluster-drift-score", baseUrl, start, end],
    queryFn: () => {
      return getClusterDriftScore(baseUrl, { start, end });
    },
  });
}

export function useAriaClustersQuery(
  opts: {
    at?: Date;
    filterType?: clusterSchema["type"];
    enabled?: clusterSchema["aria_enabled"];
  } = {}
) {
  const baseUrl = useBaseUrlExperimental();
  const { at, filterType, enabled } = opts;
  return useQuery({
    queryKey: ["aria-clusters", baseUrl, at?.getTime(), filterType, enabled],
    queryFn: () => {
      return getAriaClusters(
        baseUrl,
        at?.getTime() ?? undefined,
        filterType,
        enabled
      );
    },
  });
}

export function useToggleAria() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (clusterId: string) => {
      return toggleAria(baseUrl, clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useClusterMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (payload: {
      clusterId?: string;
      cluster: Partial<clusterSchema>;
    }) => {
      return saveCluster(baseUrl, payload.cluster, payload.clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useDeleteClusterMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (clusterId: string) => {
      return deleteCluster(baseUrl, clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useInstantCalculatorQuery(
  expressions: { expression: string; id: string }[],
  start: number,
  end: number,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [
      "instant-calculator",
      baseUrl,
      expressions.map((x) => `${x.id}${x.expression}`).sort(),
      start,
      end,
    ],
    queryFn: async () => {
      const data = await getInstantCalculation(
        baseUrl,
        expressions,
        start,
        end
      );
      return data;
    },
    refetchOnWindowFocus: false,
    ...opts,
  });
}

export function useSlopes(
  variableId: string,
  start: YYYY_MM_DD,
  end: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["slopes", baseUrl, variableId, start, end],
    queryFn: async () => {
      return await getSlopes(baseUrl, variableId, start, end);
    },
    ...opts,
  });
}

export function useSlopeQuery(slopeId: string, enabled?: boolean) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["slope", b, slopeId],
    queryFn: () => getSlopeById(b, slopeId),
    enabled,
    staleTime: minutesToMilliseconds(20),
    refetchOnMount: false,
  });
}

export function useSaveExpressionMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (payload: {
      _id?: string;
      name: string;
      variables: SavedExpressionVariable[];
    }) => {
      return saveExpression(
        baseUrl,
        payload.name,
        payload.variables,
        payload._id
      );
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["saved-expressions", baseUrl]);
    },
  });
}

export function useDeleteSavedExpressionMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (_id: string) => {
      await deleteSavedExpression(baseUrl, _id);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["saved-expressions", baseUrl]);
      addToast({
        title: "Expression deleted",
        variant: "success",
      });
    },
  });
}

export function useSavedExpressionsQuery() {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["saved-expressions", baseUrl],
    queryFn: async () => {
      const data = await getSavedExpressions(baseUrl);
      return data;
    },
  });
}

export enum TrendChartQueryType {
  Zoomed,
  Regular,
}

const minuteInMs = minutesToMilliseconds(1);
const oneDayMs = hoursToMilliseconds(24);
const hourlyThreshold = oneDayMs * 6;
const dailyThreshold = oneDayMs * 181;
// filter changepoints based on range of request
const resolution = (start: number, end: number): 1 | 60 | 1440 => {
  const range = end - start;
  return range >= dailyThreshold - minuteInMs
    ? 1440
    : range >= hourlyThreshold - minuteInMs
      ? 60
      : 1;
};

export function useSectionsQuery(opts?: BaseQueryOptions) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["sections", b],
    queryFn: () => getSectionForUnit(b),
    // enabled: false, // flag because this feature isn't ready yet
    ...opts,
  });
}

export function useInvalidateSectionsQuery() {
  const cli = useQueryClient();
  const key = ["sections", useBaseUrlExperimental()];

  return () => {
    cli.invalidateQueries(key);
  };
}

function useTrendChartQuery(
  {
    end,
    start,
    variables,
    idk,
  }: {
    variables: string[];
    start: number;
    end: number;
    idk:
      | {
          type: TrendChartQueryType.Regular;
        }
      | {
          type: TrendChartQueryType.Zoomed;
          originalStart: number;
          originalEnd: number;
        };
  },
  opts?: BaseQueryOptions
) {
  const qc = useQueryClient();
  const b = useBaseUrlExperimental();

  const getTrendChartQueryKey = (start: number, end: number) => {
    const r = resolution(start, end);
    return [
      "trend-chart-query",
      b,
      variables.slice().sort(),
      r,
      start,
      end,
    ] as const;
  };

  const queryKey = getTrendChartQueryKey(start, end);

  return useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ signal }): Promise<DRATimeseries> => {
      /**
       * This was an optimization made to request less data if you're zooming
       * and the result of the zoom is a subset of what you already have in the
       * cache. I am commenting it out because it conflicts with auto-updating
       * that we wanna do every 1 minute, but we can revisit this later.
       */
      // for (const [key, data] of qc.getQueriesData<DRATimeseries>([
      //   queryKey[0],
      //   queryKey[1],
      //   queryKey[2],
      //   queryKey[3],
      // ])) {
      //   if (!data) continue;

      //   const thisDataStart = key[4];
      //   const thisDataEnd = key[5];

      //   if (
      //     typeof thisDataStart !== "number" ||
      //     typeof thisDataEnd !== "number"
      //   )
      //     continue;

      //   /**
      //    * If this data has a range that fully encompasses the range
      //    * we're looking for, then we can use this data. This is an
      //    * optimization to avoid making a new request.
      //    */
      //   const shouldUseThisData = thisDataStart <= start && thisDataEnd >= end;

      //   if (shouldUseThisData) return data;
      // }

      const { data: _data, changepoints: _changepoints } = await getTimeseries(
        b,
        {
          end: queryKey[5],
          start: queryKey[4],
          variables: queryKey[2],
          resolution: queryKey[3],
        },
        signal
      );

      const variablesIds = Object.keys(_data);

      const out: DRATimeseries = [];

      /**
       * Merge timeseries data with changepoints
       */
      for (const vid of variablesIds) {
        const tsPointsForVid = _data[vid];
        const changepoints = _changepoints[vid];

        if (!tsPointsForVid || !changepoints) {
          throw new Error("Missing data or changepoints");
        }

        // keep tracking of the left changepoint
        // increment it when we reach the right changepoint
        let leftChangepointIdx = 0;

        /**
         * Using a while loop because I don't want to i++
         * on every iteration,
         */
        let i = 0;
        while (i < tsPointsForVid.length) {
          const lCp = changepoints[leftChangepointIdx];
          const rCp = changepoints[leftChangepointIdx + 1];

          if (!lCp) throw new Error("no left changepoint");

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const dataPoint = tsPointsForVid[i]!;
          const pointTime = dataPoint.timestamp;

          if (rCp) {
            // if the right changepoint exists, then the left isn't the last one
            const pointIsBeforeRightCp = pointTime < rCp.timestamp;

            if (pointIsBeforeRightCp) {
              // this point still has the same DA as previous
              out.push({
                da: lCp.da,
                mode: lCp.mode,
                timestamp: dataPoint.timestamp,
                value: dataPoint.value,
                variable: vid,
              });
              i++;
            } else {
              // we've reached or passed the right changepoint

              // check if we need to interpolate
              const shouldInterpolate = pointTime > rCp.timestamp;

              if (shouldInterpolate) {
                const prevPoint = tsPointsForVid[i - 1];
                if (prevPoint) {
                  // todo is this first point necessary?
                  // it seems to work without this first push
                  out.push({
                    da: lCp.da,
                    mode: lCp.mode,
                    timestamp: rCp.timestamp,
                    value: rCp.value,
                    variable: vid,
                  });
                  out.push({
                    da: rCp.da,
                    mode: rCp.mode,
                    timestamp: rCp.timestamp,
                    value: rCp.value,
                    variable: vid,
                  });
                }
              }

              // make the right changepoint the new left one
              leftChangepointIdx++;
              // dont handle the point, we'll handle it in the next iteration
            }
          } else {
            // no more changepoints to the right, we're on the last one
            // simply push all the points
            out.push({
              da: lCp.da,
              mode: lCp.mode,
              timestamp: dataPoint.timestamp,
              value: dataPoint.value,
              variable: vid,
            });
            i++;
          }
        }
      }

      if (!idk) return out;
      if (idk.type !== TrendChartQueryType.Zoomed) return out;

      const originalData = qc.getQueryData<DRATimeseries>(
        getTrendChartQueryKey(idk.originalStart, idk.originalEnd)
      );

      if (!originalData) return out;

      for (const nonDownsampled of originalData) {
        const notWithinZoomedRange =
          nonDownsampled.timestamp < start || nonDownsampled.timestamp > end;

        if (notWithinZoomedRange) {
          out.push(nonDownsampled);
        }
      }

      return out;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: secondsToMilliseconds(30),
    refetchInterval: minutesToMilliseconds(1),
    ...opts,
  });
}

function useAcknowledgementsQuery(
  {
    end,
    start,
    variableId,
  }: {
    variableId: string;
    end: YYYY_MM_DD;
    start: YYYY_MM_DD;
  },
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["acks", b, variableId, end, start],
    queryFn: () =>
      getAcknowledgements(b, {
        end,
        start,
        varId: variableId,
      }),
    ...opts,
  });
}

const Acknowledgements = {
  list: {
    useQuery: ({
      end,
      start,
      variableId,
    }: {
      variableId: string;
      end: YYYY_MM_DD;
      start: YYYY_MM_DD;
    }) => {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: ["acks", b, variableId, end, start],
        queryFn: () =>
          getAcknowledgements(b, {
            end,
            start,
            varId: variableId,
          }),
      });
    },
    useInvalidate: () => {
      const cli = useQueryClient();
      return () => cli.invalidateQueries(["acks"]);
    },
  },
  acknowledgedVariables: {
    useQuery: (start: number, end: number) => {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: ["acknowledged-variables", b, start, end],
        queryFn: () => getAcknowledgedVariables(b, start, end),
      });
    },
    useInvalidate: () => {
      const cli = useQueryClient();
      return () => cli.invalidateQueries(["acknowledged-variables"]);
    },
  },
};

export {
  useInvalidateUsersQuery,
  useTrendChartQuery,
  useAcknowledgementsQuery,
  Acknowledgements,
};
