import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
  createOperatingMode,
  editOperatingMode,
  generateMessageFromError,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { addToast } from "../../toast/use-toast-store";

type Arg = Parameters<typeof createOperatingMode>[1];
type Return = Awaited<ReturnType<typeof createOperatingMode>>;

export function useCreateOperatingModeMutation(
  opts?: Omit<UseMutationOptions<Return, unknown, Arg, unknown>, "mutationFn">
) {
  const baseUrlSlash = useBaseUrlExperimental();

  const { onSuccess, onError, ...rest } = opts ?? {};

  return useMutation({
    mutationFn: (payload: Arg) => {
      return createOperatingMode(baseUrlSlash, payload);
    },
    onSuccess: (newOperatingMode, v, c) => {
      addToast({
        title: `Operating Mode: ${newOperatingMode.name} created`,
        variant: "success",
      });
      onSuccess?.(newOperatingMode, v, c);
    },
    onError: (err, v, c) => {
      addToast({
        title: "Error",
        description: generateMessageFromError(err),
        variant: "danger",
      });
      onError?.(err, v, c);
    },
    ...rest,
  });
}

export function useEditOperatingModeMutation() {
  const baseUrlSlash = useBaseUrlExperimental();

  return useMutation({
    mutationFn: (
      payload: { _id: string } & Parameters<typeof editOperatingMode>[2]
    ) => {
      const { _id, ...rest } = payload;
      return editOperatingMode(baseUrlSlash, _id, rest);
    },
    onSuccess: (newOperatingMode) => {
      addToast({
        title: `Operating Mode: ${newOperatingMode.name} updated`,
        variant: "success",
      });
    },
    onError: (err) => {
      addToast({
        title: "Error",
        description: generateMessageFromError(err),
        variant: "danger",
      });
    },
  });
}
