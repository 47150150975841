import { z } from "zod";

export const historianSchema = z.object({
  type: z
    .string({
      required_error: "Historian type is required",
      invalid_type_error: "Historian type must be a string",
    })
    .min(1),
  key: z
    .string({
      required_error: "Historian key is required",
      invalid_type_error: "Historian key must be a string",
    })
    .min(1),
});

export type historianSchema = z.infer<typeof historianSchema>;
