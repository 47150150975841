import ErrorScreen from "../common/error-screen";

export function NoAccountYetPage() {
  return (
    <ErrorScreen
      visible={true}
      message="Login failed. Ensure an admin has added you as a user."
    />
  );
}

export function UnknownErrorPage() {
  return (
    <ErrorScreen
      visible={true}
      message="Login failed due to an unknown reason. Please contact Near Miss Management."
    />
  );
}
