import { useEffect, useRef, useState } from "react";
import { EditableSingleProps } from "./types";
import { cn } from "../../../lib/utils";
import DateInput from "./DateInput";
import { DateTime } from "luxon";

type DateFormat = "yyyy/MM/dd HH:mm" | "yyyy/MM/dd";

export default function EditableDateTimeSingle({
  date,
  className,
  options,
  onChange: onChangeHandler,
  close,
}: EditableSingleProps) {
  const dtFormat: DateFormat = options.showTime
    ? "yyyy/MM/dd HH:mm"
    : "yyyy/MM/dd";

  const inputRef = useRef<HTMLInputElement>(null);
  const [input] = useState(DateTime.fromMillis(date).toFormat(dtFormat));

  // focus on input on init
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div
      className={cn(
        className,
        "px-3 flex flex-row items-center justify-evenly"
      )}
      id="editable-date-selector"
    >
      <DateInput
        showTime={options.showTime}
        date={input}
        onChange={(d) => {
          onChangeHandler(new Date(d).getTime());
        }}
        onSubmit={close}
        ref={inputRef}
      />
    </div>
  );
}
