import * as math from "mathjs";

export default function validateExpression(
  expression: string,
  symbols: string[],
  functions: {
    [functionName: string]: {
      argTypes: string[];
      description: string;
      // todo we should make sure all functions have an example and signature
      example?: string;
      signature?: string;
    };
  },
  resolvesToBoolean = false
): boolean {
  const node: math.MathNode = math.parse(expression);

  // Helper function to validate a single node
  function validateNode(node: math.MathNode): boolean {
    if (node instanceof math.ConstantNode && typeof node.value === "string") {
      if (symbols.includes(node.value)) {
        return true;
      } else {
        throw new Error(`Tag ${node.value} does not exist.`);
      }
    } else if (node instanceof math.FunctionNode) {
      const fn = functions[node.fn.name];
      if (!fn) throw new Error(`Function ${node.fn.name} is not supported.`);
      const functionArgs = fn.argTypes;
      // Check if the function supports a variable number of arguments
      const lastArgType = functionArgs[functionArgs.length - 1] || "";
      const isVarArgs = lastArgType.endsWith("...");
      const minArgsLength = isVarArgs
        ? functionArgs.length - 1
        : functionArgs.length;

      if (node.args.length < minArgsLength) {
        throw new Error(
          `Function ${node.fn.name} expects at least ${minArgsLength} arguments.`
        );
      }
      // Validate each argument based on the expected type
      return node.args.every((arg: math.MathNode, index: number) => {
        // If the function supports a variable number of arguments, use the last type for all subsequent arguments
        const argTypeIndex =
          isVarArgs && index >= minArgsLength ? minArgsLength - 1 : index;
        const expectedTypes = (fn.argTypes[argTypeIndex] || "")
          .replace("...", "")
          .split("|");
        return expectedTypes.some((expectedType) => {
          switch (expectedType) {
            case "expression":
              return validateExpression(arg.toString(), symbols, functions);
            case "symbol":
            case "tag":
              // mathjs toString will wrap in quotes
              const s = arg.toString();
              if (symbols.includes(s.substring(1, s.length - 1))) {
                return true;
              } else {
                throw new Error(`Tag ${s} does not exist.`);
              }
            case "number":
              if (parseFloat(arg.toString()).toString() === arg.toString()) {
                return true;
              } else {
                throw new Error(`Argument ${arg} is not a number.`);
              }
            case "date":
              const d = arg.toString();
              if (!isNaN(Date.parse(d.substring(1, d.length - 1)))) {
                return true;
              } else {
                throw new Error(`Argument ${arg} is not a date.`);
              }
            default:
              throw new Error(
                `Argument type ${expectedType} is not supported.`
              );
          }
        });
      });
    } else if (node instanceof math.OperatorNode) {
      return node.args ? node.args.every(validateNode) : true;
    } else if (node instanceof math.ParenthesisNode) {
      return validateNode(node.content);
    } else if (node instanceof math.ConstantNode) {
      if (typeof node.value === "number") return true;
      if (node.value === null) return true;
      if (typeof node.value === "string") {
        if (symbols.includes(node.value)) return true;
        else throw new Error(`Tag ${node.value.toString()} does not exist.`);
      }
    }
    throw new Error(`Unknown reference: ${node.toString()}`);
  }

  const isValid = validateNode(node);
  if (isValid && resolvesToBoolean) {
    // // Define a mock context for symbols and functions
    // const mockContext = symbols.reduce(
    //   (acc, symbol) => {
    //     acc[symbol] = true; // Mock symbols as true, assuming they can be part of boolean expressions
    //     return acc;
    //   },
    //   {} as Record<string, unknown>
    // );
    // // Mock functions to return booleans or mock values based on argument types
    const mockContext: Record<string, unknown> = {};
    Object.keys(functions).forEach((funcName) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mockContext[funcName] = (...args: any[]) => true; // Simplification: assume all custom functions return boolean
    });
    const modifiedExpression =
      // replace all symbols with 1
      replaceSubstrings(expression, symbols);

    const result = math.evaluate(
      // also replace binary OR/AND operator which doesn't seem to be handled right
      replaceSubstrings(modifiedExpression, ["|", "&"], ">"),
      mockContext
    );
    if (typeof result === "boolean") {
      return true;
    } else {
      throw new Error(
        `Expression does not resolve to a boolean value (only true/false)`
      );
    }
  } else {
    return isValid;
  }
}

function replaceSubstrings(
  str: string,
  substrings: string[],
  replacement = "1"
) {
  substrings.forEach((substring) => {
    let index = str.indexOf(substring);
    while (index !== -1) {
      str =
        str.slice(0, index) + replacement + str.slice(index + substring.length);
      index = str.indexOf(substring, index + 1);
    }
  });
  return str;
}
