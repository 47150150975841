import { useForm } from "react-hook-form";
import DaisyButton from "../../common/Button/Button";
import moment from "moment";
import { Tooltip } from "@mantine/core";
import { UseMutationResult } from "@tanstack/react-query";
import Notification, {
  NotificationType,
  NotificationScheduleType,
  NotificationScheduleLabels,
  NotificationCriteria,
  PatternNotificationFormData,
  PatternNotificationParameters,
  PatternNotificationCriteriaLabels,
} from "../../../types/api/Notification";
import { useGetUseDateSelectorsStore } from "../../charts/DTC/useDateSelectorStore";
import { cn } from "../../../lib/utils";
import Select from "../../common/Select/Select";
import Input from "../../common/Input/Input";
import { InputLabel } from "./input-label";
import { addToast, addUnknownErrorToast } from "../../toast/use-toast-store";
import { useAtomValue, useSetAtom } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useStore } from "zustand";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { DateTime } from "luxon";
import { Button } from "../../../shared-ui/frontend/button";

function formatDate(date: Date) {
  return moment.utc(date).format("MMM D, hh:mm A");
}

function roundUp(inputDate: Date): Date {
  const date = moment.utc(inputDate);
  if (date.seconds() || date.milliseconds()) {
    return date.startOf("minute").add(1, "minute").toDate(); // strips out seconds and milliseconds, then adds a minute
  } else {
    return date.toDate(); // already rounded
  }
}

function roundDown(inputDate: Date): Date {
  const date = moment.utc(inputDate);
  return date.startOf("minute").toDate(); // strips out seconds and milliseconds
}

const defaultCriteria: Pick<
  PatternNotificationFormData,
  "criteria"
>["criteria"] = "IDENTICAL_MATCH";

export default function PatternNotificationForm({
  defaultData = {
    schedule: NotificationScheduleType.ONCE,
    criteria: defaultCriteria,
    parameters: {
      start: new Date().toISOString(),
      end: new Date().toISOString(),
    },
  },
  variableId,
  mutation,
  className,
  close,
}: {
  variableId: string;
  defaultData: {
    schedule: NotificationScheduleType;

    parameters?: PatternNotificationParameters;
  } & Pick<PatternNotificationFormData, "criteria">;
  className?: string;
  /* We don't care about the types of any other parts of the mutation response, we're never going to reference them. */
  mutation: UseMutationResult<Notification>;
  close: () => void;
}) {
  const { mutate, isLoading } = mutation;

  const useDateSelectorsStore = useGetUseDateSelectorsStore();
  const dateSelectors = useDateSelectorsStore();

  const dateString = dateSelectors.data
    ? `${formatDate(roundUp(dateSelectors.data.start))} — ${formatDate(
        roundDown(dateSelectors.data.end)
      )}`
    : "Select a date range";

  const getDefaultValues = () => {
    /* This should never be triggered but having the guarantee helps keep
        Typescript happy and makes sure that it definitely will never happen. */

    const criteriaLabel =
      PatternNotificationCriteriaLabels[
        NotificationCriteria[defaultData.criteria]
      ];

    if (criteriaLabel === undefined) throw new Error("Invalid label");

    return {
      ...defaultData,
      schedule: defaultData.schedule,
      criteria: defaultData.criteria,
      threshold: defaultData.parameters?.threshold,
    };
  };

  const { register, handleSubmit, watch } =
    useForm<PatternNotificationFormData>({
      defaultValues: getDefaultValues(),
    });

  const criteria = watch("criteria");
  const submit = handleSubmit(async (data: PatternNotificationFormData) => {
    const start = dateSelectors?.data?.start;
    const end = dateSelectors?.data?.end;

    // if start or end is undefined, this pattern will not work if saved.
    if (!start || !end) {
      throw new Error("Date range for pattern matching notification invalid");
    }

    let roundedUpStart = moment.utc(roundUp(start)).toDate();

    // strip out seconds. This is the same as rounding down to the nearest minute.
    let roundedDownEnd = moment.utc(roundDown(end)).toDate();

    const roundedRange = {
      start: roundedUpStart,
      end: roundedDownEnd,
    };

    const payload = {
      schedule: { type: data.schedule },
      parameters: {
        threshold: data.threshold,
        ...dateSelectors.data,
        ...roundedRange,
      },
      type: NotificationType.PATTERN,
      variableId: variableId,
      value: NotificationCriteria[data.criteria],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutate(payload as any, {
      onError: (error) => {
        addUnknownErrorToast(error);
      },
      onSuccess: close,
    });
  });

  return (
    <form
      className={cn(
        "w-full animate-in slide-in-from-bottom-4 fade-in-20 p-3",
        className
      )}
      onSubmit={(event) => {
        event.preventDefault();
        submit(event);
      }}
    >
      <InputLabel className="flex items-center">
        <span className="inline mr-3">Pattern Time Range</span>
        <Tooltip withArrow label="Drag the date selectors in the chart to edit">
          <i className="fa fa-info-circle" />
        </Tooltip>
      </InputLabel>
      <Input size="sm" className="w-full" disabled value={dateString} />

      <div className="flex items-center gap-2">
        <div className="grow">
          <InputLabel>
            <span>Pattern Matching Rule</span>
          </InputLabel>
          <Select
            size="sm"
            bordered
            className="w-full"
            {...register("criteria", { required: true, disabled: isLoading })}
            options={(
              [
                "IDENTICAL_MATCH",
                "PROBABLE_MATCH",
                "EXCEEDS_THRESHOLD",
              ] as (typeof defaultCriteria)[]
            ).map((criteria) => {
              const label =
                PatternNotificationCriteriaLabels[
                  NotificationCriteria[criteria]
                ];
              if (label === undefined) throw new Error("Invalid label");
              return {
                value: criteria, // the string of the enum
                label,
              };
            })}
          />
        </div>

        {criteria === "EXCEEDS_THRESHOLD" && (
          <span className="w-[12em] flex mt-[1.75em] items-center">
            <Input
              bordered
              size="sm"
              className="inline-block ml-2 w-full focus:outline-offset-0 focus:outline-[#0071bc]"
              {...register("threshold", {
                required: true,
                minLength: 1,
                min: 0,
                max: 100,
                valueAsNumber: true,
              })}
            />
            <span className="mx-2 text-[1rem] font-bold">%</span>
          </span>
        )}
      </div>

      <InputLabel>
        <span>Notification Frequency</span>
      </InputLabel>
      <Select
        {...register("schedule", { required: true, disabled: isLoading })}
        size="sm"
        bordered
        className="w-full"
        options={Object.values(NotificationScheduleType).map((notifType) => {
          return {
            value: notifType,
            label: NotificationScheduleLabels[notifType],
          };
        })}
      />

      <div className="flex justify-between mt-6">
        <DaisyButton
          type="reset"
          size="sm"
          className="btn-outline btn-error"
          disabled={isLoading}
          onClick={() => {
            close();
          }}
        >
          Cancel
        </DaisyButton>
        <DaisyButton
          type="submit"
          disabled={isLoading}
          icon={isLoading ? "spinner pulse fw" : ""}
          size="sm"
          className="btn-primary"
        >
          Save
        </DaisyButton>
      </div>
    </form>
  );
}

//  {
//   schedule: NotificationScheduleType.ONCE,
//   criteria: defaultCriteria,
//   parameters: {
//     start: new Date(),
//     end: new Date(),
//   },
// }

export function PatternNotificationForm2({
  defaultData,
  variableId,
  mutation,
  className,
  close,
}: {
  variableId: string;
  defaultData: {
    schedule: NotificationScheduleType;
    parameters?: PatternNotificationParameters;
  } & Pick<PatternNotificationFormData, "criteria">;
  className?: string;
  /* We don't care about the types of any other parts of the mutation response, we're never going to reference them. */
  mutation: UseMutationResult<Notification>;
  close: () => void;
}) {
  const onClose = useSetAtom(Atoms.resetBrushAtom);

  const brushState = useAtomValue(Atoms.brushStoreAtom);

  if (!brushState)
    throw new Error(
      "Should not be rendering PatternNotificationForm2 without brushState"
    );
  const { mutate, isLoading } = mutation;

  const range = useStore(brushState.brushStore, (s) => {
    const r = s.unorderedRange;
    if (r[1] === undefined)
      throw new Error("This is only possible for comments");

    return r as [number, number];
  })
    .slice()
    .sort((a, b) => a - b) as [number, number];

  const tz = useTimezone();
  const fmt = (t: number) =>
    DateTime.fromMillis(t, { zone: tz }).toFormat("LLL d yyyy, h:mm a");

  const dateString = `${fmt(range[0])} — ${fmt(range[1])}`;

  const getDefaultValues = () => {
    /* This should never be triggered but having the guarantee helps keep
        Typescript happy and makes sure that it definitely will never happen. */

    const criteriaLabel =
      PatternNotificationCriteriaLabels[
        NotificationCriteria[defaultData.criteria]
      ];

    if (criteriaLabel === undefined) throw new Error("Invalid label");

    return {
      ...defaultData,
      schedule: defaultData.schedule,
      criteria: defaultData.criteria,
      threshold: defaultData.parameters?.threshold,
    };
  };

  const { register, handleSubmit, watch } =
    useForm<PatternNotificationFormData>({
      defaultValues: getDefaultValues(),
    });

  const criteria = watch("criteria");
  const submit = handleSubmit(async (data: PatternNotificationFormData) => {
    const start = DateTime.fromMillis(range[0], { zone: tz })
      .setZone("utc", { keepLocalTime: true })
      .toJSDate();
    const end = DateTime.fromMillis(range[1], { zone: tz })
      .setZone("utc", { keepLocalTime: true })
      .toJSDate();

    let roundedUpStart = moment.utc(roundUp(start)).toDate();

    // strip out seconds. This is the same as rounding down to the nearest minute.
    let roundedDownEnd = moment.utc(roundDown(end)).toDate();

    const roundedRange = {
      start: roundedUpStart,
      end: roundedDownEnd,
    };

    const payload = {
      schedule: { type: data.schedule },
      parameters: {
        threshold: data.threshold,
        ...roundedRange,
      },
      type: NotificationType.PATTERN,
      variableId: variableId,
      value: NotificationCriteria[data.criteria],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutate(payload as any, {
      onError: (error) => {
        addUnknownErrorToast(error);
      },
      onSuccess: onClose,
    });
  });

  return (
    <form
      className={cn(
        "w-full animate-in slide-in-from-bottom-4 fade-in-20 p-3",
        className
      )}
      onSubmit={(event) => {
        event.preventDefault();
        submit(event);
      }}
    >
      <InputLabel className="flex items-center">
        <span className="inline mr-3">Pattern Time Range</span>
        <Tooltip withArrow label="Drag the date selectors in the chart to edit">
          <i className="fa fa-info-circle" />
        </Tooltip>
      </InputLabel>
      <Input size="sm" className="w-full" disabled value={dateString} />

      <div className="flex items-center gap-2">
        <div className="grow">
          <InputLabel>
            <span>Pattern Matching Rule</span>
          </InputLabel>
          <Select
            size="sm"
            bordered
            className="w-full"
            {...register("criteria", { required: true, disabled: isLoading })}
            options={(
              [
                "IDENTICAL_MATCH",
                "PROBABLE_MATCH",
                "EXCEEDS_THRESHOLD",
              ] as (typeof defaultCriteria)[]
            ).map((criteria) => {
              const label =
                PatternNotificationCriteriaLabels[
                  NotificationCriteria[criteria]
                ];
              if (label === undefined) throw new Error("Invalid label");
              return {
                value: criteria, // the string of the enum
                label,
              };
            })}
          />
        </div>

        {criteria === "EXCEEDS_THRESHOLD" && (
          <span className="w-[12em] flex mt-[1.75em] items-center">
            <Input
              bordered
              size="sm"
              className="inline-block ml-2 w-full focus:outline-offset-0 focus:outline-[#0071bc]"
              {...register("threshold", {
                required: true,
                minLength: 1,
                min: 0,
                max: 100,
                valueAsNumber: true,
              })}
            />
            <span className="mx-2 text-[1rem] font-bold">%</span>
          </span>
        )}
      </div>

      <InputLabel>
        <span>Notification Frequency</span>
      </InputLabel>
      <Select
        {...register("schedule", { required: true, disabled: isLoading })}
        size="sm"
        bordered
        className="w-full"
        options={Object.values(NotificationScheduleType).map((notifType) => {
          return {
            value: notifType,
            label: NotificationScheduleLabels[notifType],
          };
        })}
      />

      <div className="flex justify-between mt-6">
        <Button
          type="reset"
          size="sm"
          variant={"destructive"}
          disabled={isLoading}
          onClick={() => {
            onClose();
            close();
          }}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={isLoading} size="sm">
          Save
        </Button>
      </div>
    </form>
  );
}
