import classNames from "classnames";
import React from "react";
import DateStateNav from "../dateSelector/date-state-nav";
import Nav from "../nav/Nav";

type Props = {
  children?: React.ReactNode;
  showDateNav?: boolean;
  leftmostDateStateNavChildren?: React.ReactNode;
  dateStateNavChildren?: React.ReactNode;
  allowRangeCalendar?: boolean; // we only allow this on the operating limits page for now
  managerPage?: boolean;
  goBackToDRAButton?: boolean;
  helpPage?: boolean;
  disableAnimation?: boolean;
};

const MainLayout = ({
  children,
  showDateNav,
  leftmostDateStateNavChildren,
  dateStateNavChildren,
  allowRangeCalendar,
  managerPage,
  goBackToDRAButton,
  helpPage = false,
  disableAnimation,
}: Props) => {
  const secondNavBar =
    !!showDateNav || !!dateStateNavChildren ? (
      <DateStateNav
        allowRangeCalendar={!!allowRangeCalendar}
        leftmostChildren={leftmostDateStateNavChildren}
      >
        {dateStateNavChildren}
      </DateStateNav>
    ) : null;

  return (
    <>
      <Nav
        managerPage={!!managerPage}
        goBackToDRAButton={goBackToDRAButton}
        helpPage={helpPage}
      />
      <div
        id="interactible-container"
        className={classNames(
          "relative MIN_H_SCREEN",
          !disableAnimation && "animate-in slide-in-from-bottom-7 duration-300"
        )}
      >
        {secondNavBar}
        {children}
      </div>
    </>
  );
};

export default MainLayout;
