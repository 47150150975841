import * as React from "react";
import { Input } from "../../../../shared-ui/frontend/input";
import { Label } from "../../../../shared-ui/frontend/label";
import { Button } from "../../../ui/button";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../../shared-ui/frontend/tooltip";
import { ScrollArea } from "../../../ui/scroll-area";
import { useVariablesArrayQuery } from "../../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import { cn, iife } from "../../../../lib/utils";
import { Minus, Plus, X } from "lucide-react";

type SwapType = "Included" | "Excluded";

const getIcon = (type: SwapType) => {
  switch (type) {
    case "Excluded":
      return Plus;
    case "Included":
      return Minus;
    default:
      const _: never = type;
      throw new Error("impossible");
  }
};

export function TagsSwapList({
  type,
  disabled,
  inThisList,
  onClick,
}: {
  type: SwapType;
  disabled?: boolean;
  inThisList: string[];
  onClick: (id: string[]) => void;
}) {
  const [search, setSearch] = React.useState("");
  const trimmedSearch = search.trim().toLowerCase();

  const variables = useVariablesArrayQuery().data;
  const allTags = useMemo(() => {
    return variables?.filter(
      (v) => v.type === "Tag" && inThisList.includes(v._id)
    );
  }, [variables, inThisList]);

  const filteredTags = useMemo(() => {
    return allTags?.filter((v) => {
      return (
        v.name.toLowerCase().includes(trimmedSearch) ||
        v.description?.toLowerCase().includes(trimmedSearch)
      );
    });
  }, [trimmedSearch, allTags]);

  const allButtonProps = (): React.PropsWithChildren<
    Required<Pick<React.ComponentProps<typeof Button>, "variant">>
  > => {
    switch (type) {
      case "Excluded":
        return {
          variant: "secondary",
          children: "Add All",
        };
      case "Included":
        return {
          variant: "destructive",
          children: "Remove All",
        };
      default:
        const _: never = type;
        throw new Error("impossible");
    }
  };

  const Icon = getIcon(type);

  if (!filteredTags) return;

  return (
    <div className="col-span-1 inline-flex flex-col gap-2">
      <div className="flex items-end">
        <Label className="text-xslate-11">{type}</Label>
        <Button
          disabled={disabled}
          className="ml-auto"
          size={"xxs"}
          type="button"
          onClick={() => {
            onClick(filteredTags.map((v) => v._id));
          }}
          {...allButtonProps()}
        />
      </div>
      <div className="relative">
        {/* search input  */}
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by Tag ID or description"
        />
        {trimmedSearch !== "" && (
          // a button to clear the search input
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="absolute top-1/2 right-1.5 -translate-y-1/2 text-xslate-11"
                size={"icon-sm"}
                variant={"ghost"}
                type="button"
                onClick={() => setSearch("")}
              >
                <X className="size-3.5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Clear search</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <ScrollArea className="h-[50svh] rounded-md border border-xslate-7 flex flex-col bg-white">
        <ul className={cn("relative flex flex-1 flex-col pl-2 pr-3 grow")}>
          {filteredTags.map((v) => {
            return (
              <TagButton
                icon={<Icon className="size-3" />}
                key={v._id}
                disabled={disabled}
                onClick={() => {
                  onClick([v._id]);
                }}
              >
                {iife(() => {
                  if (v.description.length > 0) {
                    return `${v.trimmedName} | ${v.description}`;
                  }
                  return v.trimmedName;
                })}
              </TagButton>
            );
          })}
        </ul>
      </ScrollArea>
    </div>
  );
}

function TagButton({
  icon,
  children,
  className,
  ...rest
}: { icon: React.ReactNode } & Pick<
  React.ComponentProps<"button">,
  "children" | "className" | "onClick" | "disabled"
>) {
  return (
    <li className={cn("first-of-type:mt-2 last-of-type:mb-2")}>
      <button
        type="button"
        className={cn(
          "text-left flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-sm transition-all",
          "focus:scale-[97%]",
          "cursor-pointer hover:bg-xslate-2 hover:pl-3 hover:text-xindigo-9",
          className
        )}
        {...rest}
      >
        {icon}
        <span className="tracking-tight text-sm">{children}</span>
      </button>
    </li>
  );
}
