export function ExpressionRow({ expression }: { expression: string }) {
  return <GenericRow label="Expression" value={expression} />;
}

export function GenericRow({ label, value }: { label: string; value: string }) {
  return (
    <div className="py-[0.4rem]">
      <span className="italic text-textgrey">{label}</span>
      <div className="pl-[1.3rem]">
        <span className="text-expblue text-[1.3rem] mono break-words">
          {value}
        </span>
      </div>
    </div>
  );
}

export default ExpressionRow;
