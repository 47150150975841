import moment from "moment-timezone";
import _ from "lodash";
import { format } from "mathjs";

export const CONTEXT_TYPES = {
  FaultTreeNode: "FaultTreeNode",
  OperatingLimit: "OperatingLimit",
} as const;

export const MODE_VIEWS = {
  remainder: "Remainder",
  uncalculated: "No Data",
  shutdown: "Shutdown",
  view_all: "View All" /* The same as no selection */,
  exclude_dates: "Exclude Dates",
} as const;

/* Used for fetching data from the API (not a user-facing option) */
export const ZOOM_LEVELS = [1, 7, 14, 30, 60, 120, 180];
export const DOMAINS = {
  normal: [
    { value: 7, label: "7d" },
    { value: 14, label: "14d" },
    { value: 30, label: "30d" },
    { value: 60, label: "60d" },
    { value: 120, label: "120d" },
    { value: 180, label: "180d", default: true },
    { value: 365, label: "1y" },
  ],
  large: [
    { value: 60, label: "60d" },
    /* Default value when we switch domain sets */
    { value: 180, label: "180d", default: true },
    { value: 365, label: "1 year" },
    { value: 730, label: "2 years" },
    { value: 3600, label: "All" },
  ],
};

export const UNIQUE_COLORS = {
  0: "#0595D0",
  1: "#ff1493",
  2: "#ff9900",
  3: "#9900FF",
  4: "#37b000",
  5: "#04ffff",
  6: "#ff78f7",
  7: "#afb000",
  8: "#cb32ff",
  9: "#37ff64",
  10: "#FF6868",
  11: "#ffcb32",
};

export const NUMBER_FORMATTERS = {
  // @ts-ignore
  base(value, precision = 6) {
    return format(value, {
      notation: "auto",
      precision: precision,
    }).toUpperCase();
  },
  // @ts-ignore
  hover: (number, unitsOfMeasurement) => {
    return `${NUMBER_FORMATTERS.base(number)} ${unitsOfMeasurement ?? ""}`;
  },
};

/* Dates in trends are returned in local time as UTC? It's super heckin weird,
 * but for now, roll with it. */
export const DATE_FORMATTERS = {
  // @ts-ignore
  hover: (time, options) => DATE_FORMATTERS.long(time, options),
  // @ts-ignore
  axis: (time, options) => DATE_FORMATTERS.short(time, options),
  // @ts-ignore
  selector: (time, options) => {
    options = options || {};
    let range = options.dateRange;
    let date = moment.utc(time);

    const dayStart = moment.utc(time).startOf("day").valueOf();
    const dayEnd = moment.utc(time).endOf("day").valueOf();
    if (date.valueOf() === dayStart || date.valueOf() === dayEnd) {
      return range && range > moment.duration(1, "years").valueOf()
        ? date.format("MMM D, YYYY")
        : date.format("MMM D");
    }

    /* Default behavior if range isn't specified. */
    if (!range || range > moment.duration(1, "years").valueOf()) {
      return date.format("MMM D, YYYY");
    }

    if (range >= moment.duration(7, "days").valueOf()) {
      return date.format("MMM D");
    }

    return date.format("MMM D, hh:mm A");
  },

  /* Fully written out date (still takes range into account when possible) */
  // @ts-ignore
  long: (time, options) => {
    options = options || {};
    let range = options.dateRange;
    let date = moment.utc(time);

    /* Default behavior if range isn't specified. */
    if (!range || range > moment.duration(1, "years").valueOf()) {
      return date.format("MMM D, YYYY");
    }

    if (range >= moment.duration(7, "days").valueOf()) {
      return date.format("MMM D, hA");
    }

    return date.format("MMM D, hh:mm A");
  },

  /* Short format (used for axis primarily) */
  // @ts-ignore
  short: (time, options) => {
    options = options || {};
    let range = options.dateRange;
    let date = moment.utc(time);

    if (date.minutes()) {
      return date.format("hh:mm");
    }

    if (date.hours()) {
      return date.format("hh A");
    }

    if (!range || range > moment.duration(1, "years").valueOf()) {
      return date.format("M/D/YY");
    }

    return date.format("M/D");
  },
};
