import { forwardRef, type ComponentProps } from "react";
import { cn } from "./cn";
import { Button } from "./button";

export const Form = forwardRef<HTMLFormElement, ComponentProps<"form">>(
  ({ className, ...rest }, ref) => {
    return (
      <form
        ref={ref}
        className={cn(
          "animate-in p-4 flex flex-col gap-2 slide-in-from-top-10",
          className
        )}
        {...rest}
      />
    );
  }
);

export function MultiSelectIconToggle(
  props: Omit<
    React.ComponentProps<typeof Button>,
    "variant" | "type" | "size"
  > & {
    shown: boolean;
  }
) {
  const { shown, ...rest } = props;
  return (
    <Button
      className={cn(!shown && "text-xslate-11")}
      type="button"
      variant={shown ? "default" : "ghost"}
      size={"icon"}
      {...rest}
    />
  );
}

export function CloseButton({
  children,
  ...props
}: Omit<React.ComponentProps<typeof Button>, "variant" | "type" | "size">) {
  return (
    <Button type="button" variant={"destructive"} size={"sm"} {...props}>
      {children ?? "Cancel"}
    </Button>
  );
}
