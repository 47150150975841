"use client";

import { ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";
import Select, {
  type MultiValueRemoveProps,
  type ClearIndicatorProps,
} from "react-select";

type MyOption = { label: string; value: string; isFixed?: boolean };

type Omitted = keyof Pick<
  React.ComponentPropsWithoutRef<typeof Select>,
  | "unstyled"
  | "isMulti"
  | "components"
  | "classNames"
  | "options"
  | "inputValue"
  | "onInputChange"
>;

function MultiSelect<T extends MyOption>(
  props: {
    options: T[];
    forwardedRef?: React.Ref<HTMLElement>;
  } & Omit<React.ComponentPropsWithoutRef<typeof Select<T, true>>, Omitted> & {
      noPortal?: true;
    }
) {
  /**
   * This is a hack to prevent the select component from rendering
   * during SSR. It seems like react-select has some issues with
   * SSR and errors are shown during development without this.
   */
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);

  const [inputValue, setInputValue] = useState("");

  const { closeMenuOnSelect, noPortal, styles, ...rest } = props;

  return (
    rendered && (
      <Select<T, true>
        menuPortalTarget={noPortal ? undefined : document.body} // very important for z-index related issues !!!!!!!!!!
        inputValue={inputValue}
        menuPlacement="top"
        onInputChange={(value, action) => {
          /**
           * When the user selects an option, we want to keep the search value
           * the same. This combinations of options allows that.
           */
          switch (action.action) {
            case "input-blur":
              break;
            case "input-change":
              setInputValue(value);
              break;
            case "menu-close":
              setInputValue("");
              break;
            case "set-value":
              break;
            default:
              const _: never = action.action;
              throw new Error(`Unhandled action`);
          }
        }}
        unstyled
        isMulti
        components={{
          DropdownIndicator,
          ClearIndicator: ClearIndicator<T>,
          MultiValueRemove: RemoveValueX,
        }}
        styles={{
          ...styles,
          menu: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        classNames={{
          container: () => props.className ?? "",
          control: () => {
            return "rounded-md border border-xslate-7 px-3 min-h-10 hover:border-xslate-8 hover:cursor-pointer text-sm bg-white dark:bg-xslate-1";
          },
          indicatorsContainer: () => "fill-xslate-11",
          dropdownIndicator: () => {
            return "fill-xslate-11";
          },
          menu: () =>
            "bg-xslate-1 shadow-md border border-xslate-7 rounded-md animate-in slide-in-from-top-3 fade-in-80 p-1",
          option: () =>
            "!text-sm py-1.5 rounded-sm pr-2 hover:bg-xslate-4 hover:text-xslate-12 pl-8",
          multiValue: () =>
            "bg-xslate-4 !text-xs rounded-md px-3 py-0.5 items-center animate-in slide-in-from-top-1",
          valueContainer: () => "gap-1.5 py-1",
          placeholder: () => "text-xslate-9",
        }}
        closeMenuOnSelect={closeMenuOnSelect ?? false}
        {...rest}
      />
    )
  );
}

function ClearIndicator<T extends MyOption>(
  props: ClearIndicatorProps<T, true>
) {
  return (
    <div {...props.innerProps}>
      <X className="ml-2 size-3.5 h-3.5 w-3.5 text-xslate-9" />
    </div>
  );
}

function DropdownIndicator() {
  return <ChevronDown className="ml-2 size-4 opacity-50" />;
}

function RemoveValueX<T extends MyOption>(
  props: MultiValueRemoveProps<T, true>
) {
  if (props.data.isFixed) {
    return null;
  }

  return (
    <div {...props.innerProps}>
      <X className="ml-2 size-3.5 h-3.5 w-3.5 text-xslate-9 hover:text-xred-9" />
    </div>
  );
}

export { MultiSelect };
