import { z } from "zod";
import flags from "../../lib/flags";
import { operatingLimitSchema } from "../../lib/api-schema/operating-limit";

export const limitTypeSchema = z.enum(["high", "low"]);

export type LimitType = z.infer<typeof limitTypeSchema>;

export const limitLevelNumSchema = z.custom<1 | 2 | 3 | 4 | 5>((v) => {
  return z.number().int().min(1).max(5).safeParse(v).success;
});

export type LimitLevelNum = z.infer<typeof limitLevelNumSchema>;

export type LimitLevel = `${LimitType} ${LimitLevelNum}`;

type HighLowTuple<T extends LimitLevelNum> = readonly [`high ${T}`, `low ${T}`];

type Limit_TODO = Pick<operatingLimitSchema, "level" | "type">;

export const QUERY_MODE_LABELS = {
  exceedance: "Exceedances",
  highProb: "Pred. Prob. > 75% (7d)",
  medProb: "Pred. Prob. > 50% (7d)",

  highProbNoExceedances: "Pred. Prob. > 75% (7d), No Exceedances",
  medProbNoExceedances: "Pred. Prob. > 50% (7d), No Exceedances",

  /**
   * These options no longer needed, but keeping them here just in case
   */
  // increaseProb: "Significant Increase in Pred. Prob. (7d)",
  // increaseProbNoExceedances:
  // "Significant Increase in Pred. Prob. (7d), No Exceedances",
} as const satisfies Record<string, string>;
export type QueryMode = keyof typeof QUERY_MODE_LABELS;

export const HIGH_LIMIT_LEVLELS_ORDERED = [
  "high 5",
  "high 4",
  "high 3",
  "high 2",
  "high 1",
] as const satisfies readonly LimitLevel[];

export const LOW_LIMIT_LEVLELS_ORDERED = [
  "low 1",
  "low 2",
  "low 3",
  "low 4",
  "low 5",
] as const satisfies readonly LimitLevel[];

export const ALL_LIMIT_LEVELS_ORDERED = [
  ...HIGH_LIMIT_LEVLELS_ORDERED,
  ...LOW_LIMIT_LEVLELS_ORDERED,
] as const satisfies readonly LimitLevel[];

export const limitLevelSchema = z.enum(ALL_LIMIT_LEVELS_ORDERED);
export type limitLevelSchema = z.infer<typeof limitLevelSchema>;

export const LEVEL_MAGNITUDES: { [Num in LimitLevelNum]: HighLowTuple<Num> } = {
  1: ["high 1", "low 1"],
  2: ["high 2", "low 2"],
  3: ["high 3", "low 3"],
  4: ["high 4", "low 4"],
  5: ["high 5", "low 5"],
};

export type View =
  | "exceedance_counts"
  | "trend_chart"
  | "frequency"
  | "extremum"
  | "time"
  | "insights"
  | "probability";

export const VIEW_LABELS = {
  trend_chart: "Trend Chart",
  frequency: "Frequency",
  extremum: (limit) => (limit.type === "high" ? "Maximum" : "Minimum"),
  time: "Time",
  insights: "Insights",
  probability: "Predictive Probability",
  exceedance_counts: "Exceedance Counts",
} as const satisfies Record<View, string | ((limit: Limit_TODO) => string)>;

type Unit = "unit" | "time" | "decimal" | "%" | "";
type Insight =
  | "probabilityToCrossNextLimit"
  | "totalNumberOfExceedances"
  | "averageDailyFrequencyOfExceedances"
  | "highestValuePerExceedance"
  | "maximumRecoveryTime"
  | "averageRecoveryTime"
  | "averageAccelerationTime"
  | "averageInterarrivalTime";

export const INSIGHTS_LABELS = {
  probabilityToCrossNextLimit: (limit) =>
    `% of ${limit.level.toUpperCase()} exceedances that crossed the next limit`,
  totalNumberOfExceedances: "Total number of exceedances",
  averageDailyFrequencyOfExceedances: "Average daily frequency of exceedances",
  highestValuePerExceedance: (limit) =>
    limit.type === "high"
      ? "Highest value per exceedance"
      : "Lowest value per exceedance",
  maximumRecoveryTime: "Maximum recovery time",
  averageRecoveryTime: "Average recovery time",
  averageAccelerationTime: "Average acceleration time",
  averageInterarrivalTime: "Average inter-arrival time",
} as const satisfies Record<Insight, ((limit: Limit_TODO) => string) | string>;

/* Detail icons for insights */
export const INSIGHT_DETAILS = {
  averageAccelerationTime:
    "Time between start of exceedances for consecutive limits",
  averageInterarrivalTime: "Time between start of consecutive exceedances",
  averageRecoveryTime: "Time between start and end of an exceedance",
} as const satisfies Partial<Record<Insight, string>>;

export const INSIGHT_UNITS = {
  probabilityToCrossNextLimit: "%",
  totalNumberOfExceedances: "",
  averageDailyFrequencyOfExceedances: "decimal",
  highestValuePerExceedance: "unit",
  maximumRecoveryTime: "time",
  averageRecoveryTime: "time",
  averageAccelerationTime: "time",
  averageInterarrivalTime: "time",
} as const satisfies Record<Insight, Unit>;

export const DEFAULT_VIEW = "frequency" satisfies View;
export const VIEW_ORDER = [
  "frequency",
  "time",
  "extremum",
  "probability",
  "insights",
] as const satisfies readonly View[];

export const INSIGHT_ORDER = [
  "probabilityToCrossNextLimit",
  "totalNumberOfExceedances",
  "averageDailyFrequencyOfExceedances",
  "highestValuePerExceedance",
  "maximumRecoveryTime",
  "averageRecoveryTime",
  "averageAccelerationTime",
  "averageInterarrivalTime",
] as const satisfies readonly Insight[];

export const LIMIT_STATES = flags([
  // 'new', /* If the limit is newly created and not yet saved. @TODO unused, think about using? */
  "editing" /* If the limit is in edit-limit. */,
  "saving" /* If a save is currently happening. */,
  "save_completed" /* Did a save finish? */,
]);

export const LEVEL_TYPES = [
  "High",
  "Low",
] as const satisfies readonly Capitalize<LimitType>[];
