import React, { PropsWithChildren, useMemo, useState } from "react";
import { create } from "zustand";
import {
  MODE_VIEWS,
  UNIQUE_COLORS,
} from "../charts/constants/dynamicTrendChartConstants";
import { v4 } from "uuid";
import { produce } from "immer";
import "./variability-drawer.scss";
import * as R from "remeda";
import { VariabilityHeader } from "./header/header";
import { VariabilityChart } from "./variability-chart";

import { differenceInDays } from "date-fns";
import { VariabilityTooltip } from "./tooltip/tooltip";
import {
  ClickableVariable,
  VariabilityDrawerStore,
  VariabilityGroup,
} from "./types";
import WhiskerBoxPlot from "./WhiskerBoxPlot";
import { cn } from "../../lib/utils";
import { Drawer, DrawerContent } from "../../shared-ui/frontend/drawer";
import { minLen1 } from "../../shared-ui/lib/utils";
import { DateTime } from "luxon";
import { LONG } from "../../shared-ui/lib/luxon-format-tokens";
/**
 * Must be used in a component tree where VariabilityDrawerStoreProvider
 * is above it. Up to you how you want to do that.
 */

export const TooltipSelector = "tooltip-line";

const initialContainerDims = {
  height: 300,
  width: 1200,
  margin: 20,
};

function VariabilityDrawer() {
  const useStore = useGetUseVariabilityDrawerStore();
  const hasBuckets = useStore((s) => !!s.groups);
  const svgContainerRatio = useStore((s) => s.svgContainerRatio);
  const showBoxPlot = useStore((s) => s.showBoxPlot);

  const svgContainerClasses =
    "rounded-lg shadow-sm border border-xslate-5 bg-white relative px-4 py-2";

  const aspect = {
    width: svgContainerRatio
      ? svgContainerRatio.width
      : initialContainerDims.width,
    height: svgContainerRatio
      ? showBoxPlot
        ? (svgContainerRatio.height - initialContainerDims.margin) / 2
        : svgContainerRatio.height - initialContainerDims.margin
      : initialContainerDims.height - initialContainerDims.margin,
  };

  return (
    <Drawer open={hasBuckets} dismissible={false}>
      <DrawerContent className="overflow-visible h-[98vh] bg-xslate-3">
        {hasBuckets && (
          <div className="overflow-visible">
            <VariabilityHeader />

            <VariabilityTooltip />
            <VariabilityChart
              aspect={aspect}
              className={cn(svgContainerClasses)}
              initialDims={initialContainerDims}
            />
            {showBoxPlot && (
              <WhiskerBoxPlot
                aspect={aspect}
                className={cn(svgContainerClasses)}
                initialDims={initialContainerDims}
              />
            )}
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
}

const getDefaultExcludedModesMap =
  (): ClickableVariable["excludedModesMap"] => ({
    [MODE_VIEWS.shutdown]: true,
  });

function assertMinLen1<T>(arr: T[]) {
  if (arr.length === 0) throw new Error("empty array");
  return arr as [T, ...T[]];
}

export type OpenDrawerParameters = Parameters<
  VariabilityDrawerStore["openDrawer"]
>[0];

function createVariabilityDrawerStore(opts?: {
  isForOFPage?: boolean;
  initiallyOpen?: OpenDrawerParameters;
}) {
  return create<VariabilityDrawerStore>()((set, get) => {
    const getRemainingColors = (gs: VariabilityGroup[]) => {
      const usedColors = gs.flatMap((g) => g.variables.map((v) => v.color));

      const remainingColors = R.difference.multiset(
        Object.values(UNIQUE_COLORS),
        usedColors
      );
      return remainingColors;
    };

    const getOpenDrawerState = ({
      defaultEnd,
      defaultStart,
      variables,
    }: OpenDrawerParameters): VariabilityDrawerStore["groups"] => {
      // put each initial variable into its own group
      const groups = variables.map(({ _id, color }): VariabilityGroup => {
        return {
          start: defaultStart,
          end: defaultEnd,
          variables: [
            {
              _id,
              id: v4(),
              color,
              selected: false,
              hovered: false,
              excludedModesMap: getDefaultExcludedModesMap(), // Shutdown will start off toggled off
            },
          ],
          id: v4(),
        };
      });

      if (!minLen1(groups)) throw new Error("unexpected");
      return groups;
    };

    const defaults: Pick<
      VariabilityDrawerStore,
      | "showLineGraphInHistogram"
      | "showBarsInHistogram"
      | "showBoxPlot"
      | "view"
      | "showAddVariablesOverlay"
      | "groups"
      | "showLimitLines"
      | "svgContainerRatio"
    > = {
      svgContainerRatio: undefined,
      showLineGraphInHistogram: true,
      showBarsInHistogram: true,
      showBoxPlot: true,
      view: "absolute",
      showAddVariablesOverlay: false,
      groups: opts?.initiallyOpen
        ? getOpenDrawerState(opts.initiallyOpen)
        : undefined,
      showLimitLines: !!opts?.isForOFPage,
    };

    /**
     * Any chart on the page can open the drawer, so we'll use some defaults from the chart's
     * state to initialize the drawer's state. Technically, you can pass this whatever you want,
     * but it makes sense to open the drawer with the same variable that the chart is showing.
     */
    const openDrawer: VariabilityDrawerStore["openDrawer"] = (opts) => {
      const groups = getOpenDrawerState(opts);

      set({
        ...defaults,
        groups,
      });
    };

    return {
      ...defaults,
      openDrawer,
      copyVariableIntoNewGroupWithPreviousDateRange(clickableId, tz) {
        const curr = get().groups;
        if (!curr) throw new Error("unexpected");

        const [firstColor] = getRemainingColors(curr);

        if (firstColor === undefined)
          return new Error(
            `This view only supports up to ${
              Object.keys(UNIQUE_COLORS).length
            } variables`
          );

        const group = curr.find((g) =>
          g.variables.some((v) => v.id === clickableId)
        );
        if (!group) throw new Error("unexpected");
        const variable = group.variables.find((v) => v.id === clickableId);
        if (!variable) throw new Error("unexpected");

        const diffDays = Math.abs(differenceInDays(group.end, group.start));

        const currStart = DateTime.fromMillis(group.start, { zone: tz });
        const currEnd = DateTime.fromMillis(group.end, { zone: tz });

        const newEnd = currStart
          .minus({ days: 1 })
          .endOf("day")
          .startOf("minute"); // 11:59 of the previous day

        const newStart = newEnd.minus({ days: diffDays }).startOf("day");

        const newStartMs = newStart.toMillis();
        const newEndMs = newEnd.toMillis();

        const shouldAddIntoOldGroup = curr.find(
          (g) => g.start === newStartMs && g.end === newEndMs // same domain
        );

        const newGroups = produce(curr, (draft) => {
          if (shouldAddIntoOldGroup) {
            const group = draft.find((g) => g.id === shouldAddIntoOldGroup.id);
            if (!group) throw new Error("impossible");

            group.variables.push({
              _id: variable._id,
              color: firstColor,
              excludedModesMap: getDefaultExcludedModesMap(),
              id: v4(),
              selected: false,
              hovered: false,
            });
            return;
          }

          draft.push({
            end: newEndMs,
            id: v4(),
            start: newStartMs,
            variables: [
              {
                _id: variable._id,
                color: firstColor,
                excludedModesMap: getDefaultExcludedModesMap(),
                id: v4(),
                selected: false,
                hovered: false,
              },
            ],
          });
        });

        set({ groups: newGroups });
      },
      updateRange(groupId, [start, end]) {
        const curr = get().groups;
        if (!curr) throw new Error("unexpected");

        const groupOfInterest = curr.find((g) => g.id === groupId);

        if (!groupOfInterest) throw new Error("unexpected");

        const groupWithSameDates = curr.find(
          (g) => g.start === start && g.end === end
        );

        if (groupWithSameDates) {
          // there is another group with the same dates, merge them
          const groupsWithoutTheOnesMerging = curr.filter(
            (g) => g.id !== groupOfInterest.id && g.id !== groupWithSameDates.id
          );

          const merged = produce(groupWithSameDates, (draft) => {
            draft.variables = [
              ...draft.variables,
              ...groupOfInterest.variables,
            ]; // there could be duplicate variables
          });

          // ensure >=1 variable
          const [first, ...rest] = merged.variables;
          if (!first) throw new Error("impossible");

          // set the new groups with 2 being merged
          set({
            groups: assertMinLen1([...groupsWithoutTheOnesMerging, merged]),
          });
          return;
        }

        set((curr) => {
          if (!curr.groups) throw new Error("unexpected");
          return {
            groups: produce(curr.groups, (draft) => {
              const group = draft.find((g) => g.id === groupId);
              if (!group) throw new Error("unexpected");
              group.start = start;
              group.end = end;

              draft.sort((a, b) => {
                const end = b.end - a.end; // most recent ends go on the right
                if (end === 0) return b.start - a.start; // most recent starts go on the left
                return end;
              });
            }),
          };
        });
      },
      removeGroup(groupId) {
        const curr = get().groups;
        if (!curr) throw new Error("unexpected");
        const newGroups = assertMinLen1(curr.filter((g) => g.id !== groupId));

        set((curr) => ({
          groups: newGroups,
          // if removal caused only 1 unique variable in all groups, then show limit lines, else keep the old setting
          showLimitLines: opts?.isForOFPage || curr.showLimitLines,
          view:
            newGroups.flatMap((g) => g.variables).length === 1
              ? "absolute"
              : curr.view,
        }));
      },
      toggleHoverVariable(variableId) {
        set((curr) => {
          const groups = curr.groups;

          if (!groups)
            throw new Error(
              "Groups not found when toggling highlight variable"
            );

          const isSelectedVariable = groups.find((g) =>
            g.variables.find((v) => v.selected)
          );

          if (isSelectedVariable === undefined) {
            return {
              groups: assertMinLen1(
                groups.map((g) => {
                  return {
                    ...g,
                    variables: assertMinLen1(
                      g.variables.map((v) => {
                        return {
                          ...v,
                          hovered: v.id === variableId,
                        };
                      })
                    ),
                  };
                })
              ),
            };
          }
          return { groups };
        });
      },
      toggleHighlightVariable(variableId) {
        set((curr) => {
          const groups = curr.groups;

          if (!groups)
            throw new Error(
              "Groups not found when toggling highlight variable"
            );

          return {
            groups: assertMinLen1(
              groups.map((g) => {
                return {
                  ...g,
                  variables: assertMinLen1(
                    g.variables.map((v) => {
                      return {
                        ...v,
                        selected: v.id === variableId ? !v.selected : false,
                      };
                    })
                  ),
                };
              })
            ),
          };
        });
      },
      removeVariable(clickableId) {
        const curr = get().groups;

        if (!curr) throw new Error("unexpected");
        const newGroups = produce(curr, (groupsDraft) => {
          const group = groupsDraft.find((g) =>
            g.variables.some((v) => v.id === clickableId)
          );
          if (!group) throw new Error("unexpected");

          const newVariables = group.variables.filter(
            (v) => v.id !== clickableId
          );

          const [first, ...rest] = newVariables;

          if (!first) throw new Error("impossible");

          group.variables = [first, ...rest];
        });

        set((curr) => {
          return {
            groups: newGroups,
            showLimitLines: opts?.isForOFPage || curr.showLimitLines,
            view:
              newGroups.flatMap((g) => g.variables).length === 1
                ? "absolute"
                : curr.view,
          };
        });
      },

      close: () => set(defaults),
      updateModesMapForSingleBucket(clickableId, excludedModesMap) {
        set((curr) => ({
          groups: produce(curr.groups, (draft) => {
            if (!draft) throw new Error("unexpected");

            const group = draft.find((g) =>
              g.variables.some((v) => v.id === clickableId)
            );
            if (!group) throw new Error("unexpected");
            const variable = group.variables.find((v) => v.id === clickableId);
            if (!variable) throw new Error("unexpected");

            variable.excludedModesMap = excludedModesMap;
          }),
        }));
      },
      addVariables: (variablesToAdd, domain) => {
        if (variablesToAdd.length === 0) return;

        const groups = get().groups;
        if (!groups) throw new Error("unexpected");
        const shouldAddIntoOldGroup = groups.find(
          (g) => g.start === domain[0] && g.end === domain[1] // same domain
        );

        const remainingColors = getRemainingColors(groups);

        if (variablesToAdd.length > remainingColors.length)
          return new Error(
            `This view only supports up to ${
              Object.keys(UNIQUE_COLORS).length
            } variables`
          );

        const clickables: ClickableVariable[] = variablesToAdd.map((_id, i) => {
          const color = remainingColors.at(i);

          if (!color) throw new Error("impossible"); // we checked above

          return {
            id: v4(),
            _id,
            selected: false,
            hovered: false,
            color,
            excludedModesMap: getDefaultExcludedModesMap(),
          };
        });

        const buildNewGroups = (): typeof groups => {
          if (shouldAddIntoOldGroup)
            return produce(groups, (draft) => {
              const group = draft.find(
                (g) => g.start === domain[0] && g.end === domain[1]
              );
              if (!group) throw new Error("impossible");

              group.variables.push(...clickables); //  add to existing group
            });

          // create a new group
          return produce(groups, (draft) => {
            draft.push({
              end: domain[1],
              start: domain[0],
              id: v4(),
              variables: assertMinLen1(clickables),
            });

            draft.sort((a, b) => {
              const end = b.end - a.end; // most recent ends go on the right
              if (end === 0) return b.start - a.start; // most recent starts go on the left
              return end;
            });
          });
        };

        const newGroups = buildNewGroups();

        set((curr) => ({
          groups: newGroups,
          showLimitLines: opts?.isForOFPage || curr.showLimitLines,
        }));
      },
    };
  });
}

type UseVariabilityDrawerStore = ReturnType<
  typeof createVariabilityDrawerStore
>;

const UseVariableDrawerContext =
  React.createContext<UseVariabilityDrawerStore | null>(null);

function VariabilityDrawerStoreProvider({
  children,
  init,
}: PropsWithChildren<{
  init?: Parameters<typeof createVariabilityDrawerStore>[0];
}>) {
  const [store] = useState(() => createVariabilityDrawerStore(init));

  return (
    <UseVariableDrawerContext.Provider
      value={store satisfies NonNullable<UseVariabilityDrawerStore>}
    >
      {children}
    </UseVariableDrawerContext.Provider>
  );
}

function useGetUseVariabilityDrawerStore() {
  const store = React.useContext(UseVariableDrawerContext);

  if (!store) {
    throw new Error(
      "useGetUseVariabilityDrawerStore must be used within a VariabilityDrawerStoreProvider"
    );
  }

  return store;
}

function useGetUseVariabilityDrawerStoreNotRequired() {
  const store = React.useContext(UseVariableDrawerContext);

  return store;
}

function useMin1Groups() {
  const useStore = useGetUseVariabilityDrawerStore();
  const buckets = useStore((s) => s.groups);
  if (!buckets) throw new Error("unexpected");
  return buckets;
}

export {
  VariabilityDrawer,
  VariabilityDrawerStoreProvider,
  useGetUseVariabilityDrawerStore,
  useGetUseVariabilityDrawerStoreNotRequired,
  useMin1Groups,
};
