import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { matchSorter } from "match-sorter";
import _ from "lodash";
import InputOld from "./InputOld";

const debounce = (cb, delay = 300) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export default function useSearch(items, options, returnComponents = false) {
  // in general, we expect items to be a state in the parent component that uses this hook
  const FILTER_SHOW_ALL = "All";
  const SORT_NONE = "NONE";
  const [matches, setMatches] = useState(items || []);

  const qRef = useRef();

  const [filter, setFilter] = useState(
    options?.filter
      ? options.filter.includeAll
        ? FILTER_SHOW_ALL
        : Object.keys(options.filter.options)[0]
      : null
  );

  const [queryByName, setQueryByName] = useState(
    options?.search ? Object.keys(options.search.options)[0] : null
  );

  const [query, setQuery] = useState("");

  const [sortName, setSortName] = useState(
    options?.sort
      ? options.sort.defaultNone
        ? SORT_NONE
        : Object.keys(options.sort.options)[0]
      : null
  );

  const oldItems = useRef(items || []);

  useEffect(
    () => setMatches(applyQuery(applyFilter(items || []))),
    [filter, queryByName, query, sortName]
  );

  // when items change, we need to re-apply the filter
  useEffect(() => {
    const out = applyQuery(applyFilter(items || []));
    if (!_.isEqual(items, oldItems.current)) {
      setMatches(out);
      oldItems.current = out;
    }
  }, [items]);

  const setQueryDebounced = useCallback(
    options.search ? debounce(setQuery) : () => {},
    []
  );

  const applySort = (arr) => {
    if (sortName && sortName !== SORT_NONE) {
      arr.sort(options.sort.options[sortName]);
    }
    return arr;
  };

  const applyFilter = (arr) => {
    return filter
      ? arr.filter(
          filter === FILTER_SHOW_ALL
            ? () => true
            : options.filter.options[filter]
        )
      : [...arr];
  };

  const applyQuery = (arr) => {
    const match_sorter_options = options.search.options[queryByName];
    const no_matches_for_empty_string = options.emptyStringMatchesNone;

    // filter first
    // only apply query if not falsy
    const results =
      query == null || query.trim() === ""
        ? no_matches_for_empty_string
          ? []
          : arr
        : matchSorter(arr || [], query, {
            ...match_sorter_options,
            threshold: matchSorter.rankings.CONTAINS, // no fuzzy searching! (which is the default) see matchSort.rankings for other options
          });

    applySort(results);

    return results;
  };

  const out = {
    matches,
  };

  if (filter) {
    const filterNames = options.filter.includeAll
      ? [FILTER_SHOW_ALL, ...Object.keys(options.filter.options)]
      : Object.keys(options.filter.options);

    if (returnComponents) {
      out.filter = (
        <InputOld
          type="select"
          classes={options.filter.classes}
          action={setFilter}
          value={filter}
          options={filterNames.map((name) => ({
            label: filter.prefix ? "Filter: " : "" + name,
            value: name,
          }))}
          label={options.filter.label}
        />
      );
    } else {
      out.filter = {
        filterNames,
        filterName: filter,
        updateFilterName: (name) => setFilter(name),
      };
    }
  }

  if (options?.search) {
    if (returnComponents) {
      const searchBys = Object.keys(options.search.options);
      out.search = (
        <InputOld
          refId={qRef}
          style={
            searchBys.length > 1
              ? {
                  marginRight: "0",
                  borderBottomRightRadius: "0",
                  borderTopRightRadius: "0",
                }
              : {}
          }
          hint={options.search.hint && `Search ${queryByName}`}
          type="text"
          action={setQueryDebounced}
          classes={options.search.classes ?? {}}
          label={options.search.label ? options.search.label : undefined}
        />
      );
    } else {
      // if search options are provided in props, return relevant stuff
      out.search = {
        query: query,
        updateQuery: setQuery, // wait .2 seconds for no keystrokes, then trigger a search
        queryBy: queryByName,
        updateQueryBy: setQueryByName,
        queryByNames: Object.keys(options.search.options),
      };
    }
  }

  if (sortName != null) {
    const sortNames = options.sort.defaultNone
      ? ["", ...Object.keys(options.sort.options)]
      : Object.keys(options.sort.options);

    if (returnComponents) {
      out.sort = (
        <InputOld
          type="select"
          classes={{ Input: "w-auto md:mr-2" }}
          action={setSortName}
          value={sortName}
          options={sortNames.map((name) => ({
            label: "Sort: " + name,
            value: name,
          }))}
        />
      );
    } else {
      out.sort = {
        sortNames,
        sortName,
        updateSortName: setSortName,
      };
    }
  }
  return out;
}
