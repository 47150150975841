import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const QUERY_KEY = "selectednode";

export function useSelectedNodeQuery() {
  const [searchParams, setSp] = useSearchParams();

  const nodeId = searchParams.get(QUERY_KEY);

  const removeSelectedNodeQuery = () => {
    setSp((curr) => {
      const out = new URLSearchParams(curr);
      out.delete(QUERY_KEY);
      return out;
    });
  };

  const setSelectedNodeQuery = (nodeId: string) => {
    setSp((curr) => {
      const out = new URLSearchParams(curr);
      out.set(QUERY_KEY, nodeId);
      return out;
    });
  };

  return {
    selectedNodeId: z.string().nullable().parse(nodeId) ?? undefined,
    removeSelectedNodeQuery,
    setSelectedNodeQuery,
  };
}
