import React, { Component, ComponentProps } from "react";
import Button from "../Button/Button";
import { Badge } from "../../ui/badge";
import { cn } from "../../../lib/utils";

/**
 * button prop is used to add a button to the end of the list to "Show More"
 * perhaps, or anything else you want to do with the list of items
 *
 * button is the object of props passed to a Button component, with 1 caveat: label must be the children
 */
function PillRow({
  label,
  items,
  keyPrefix,
  className,
  ulClassName,
  button,
}: {
  button?: ComponentProps<typeof Button> & {
    label: string;
  };
  className?: string;
  ulClassName?: string;
  keyPrefix?: string;
  items: string[];
  label?: string;
}) {
  const { label: btnLabel, ...rest } = button ?? {};

  const btnComponent = button ? (
    <li className="inline-block mt-2">
      <Button {...rest}>{btnLabel}</Button>
    </li>
  ) : null;

  return (
    <div className={cn("py-[0.4rem]", className)}>
      {label && <span className="italic text-textgrey">{label}</span>}
      <ul
        className={cn(
          "mt-0 lg:text-[0.9rem] flex gap-1 flex-wrap",
          ulClassName
        )}
      >
        {items.map((label) => (
          <li className="mt-2" key={`${keyPrefix ? keyPrefix + label : label}`}>
            <Badge variant={"outline"}>{label}</Badge>
          </li>
        ))}
        {btnComponent}
      </ul>
    </div>
  );
}

export default React.memo(PillRow);
