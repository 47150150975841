import * as d3 from "d3";
import { OperatingLimit } from "../../../lib/api-schema/operating-limit";
import { ChartDimensions, Scale, SvgSelector } from "../types";
import { TooltipLineClass } from "./chartUtils";

// values for fill and stroke opacity properties
export const DEFAULT_STROKE_OPACITY = 1;
export const DEFAULT_FILL_OPACITY = 0.4;
export const HIST_DEFAULT_FILL_OPACITY = 1;
export const LIGHT_STROKE_OPACITY = 0.1;
export const LIGHT_FILL_OPACITY = 0.1;

/**
 * Drawing the x-axis on the chart
 * @param scale - scale to draw
 * @param svgSelector - svg selector of chart to draw scale on
 * @param ChartDimensions - destructured chart dimensions
 * @param highlight - make scale visible if variable is highlighted or by default
 * @param identifier - reference for variable the scale is associated with
 * @returns - selection of scale to reference in chart code
 */

export function drawXScale(
  svgSelector: SvgSelector,
  opts: {
    scale: Scale;
    dims: ChartDimensions;
    highlight?: boolean;
    identifier?: string;
    axesFontScale?: number;
  }
) {
  return svgSelector
    .append("g")
    .attr("transform", `translate(0, ${opts.dims.height - opts.dims.margin})`)
    .attr("class", "x-axis")
    .attr(
      "id",
      opts.identifier ? `x-axis-${opts.identifier}` : "x-axis-consolidated"
    )
    .attr("visibility", opts.highlight ? "visible" : "hidden")
    .call(d3.axisBottom(opts.scale))
    .selectAll(".tick")
    .selectAll("text")
    .style("text-anchor", "middle")
    .style("font-size", 30 * (opts.axesFontScale ?? 0.4));
}

/**
 * Draw limit lines on chart
 * @param svgSelector - svg selector of chart to draw limit lines on
 * @param limits - limits to draw
 * @param xScale - scale to draw limit lines on
 * @param ChartDimensions - dimensions of chart
 */
export function drawLimitLines(
  svgSelector: SvgSelector,
  limits: Pick<OperatingLimit, "value">[],
  xScale: Scale,
  { height, margin }: ChartDimensions
) {
  svgSelector
    .append("g")
    .attr("transform", "translate(0, 0)")
    .selectAll("line")
    .exit()
    .data(limits)
    .enter()
    .append("line")
    .attr("width", 3)
    .attr("vector-effect", "non-scaling-stroke")
    .attr("class", "limits")
    .attr("x1", (d) => {
      return xScale(d.value);
    })
    .attr("x2", (d) => {
      return xScale(d.value);
    })
    .attr("y1", height - margin)
    .attr("y2", margin)
    .attr("stroke", "grey")
    .attr("style", "stroke-dasharray: 7px");
}

export function drawCapabilityLine(
  svgSelector: SvgSelector,
  { height, margin }: ChartDimensions,
  value: number,
  className: string,
  color: string,
  xScale: Scale
) {
  svgSelector
    .append("line")
    .attr("class", className)
    .attr("x1", xScale(value))
    .attr("y1", margin)
    .attr("x2", xScale(value))
    .attr("y2", height - margin)
    .attr("stroke", color)
    .style("pointer-events", "none");
}

/**
 * Create tooltip line for a chart
 * @param svgSelector - svg selector of chart
 * @param ChartDimensions - dimensions of chart
 */
export function createTooltipLine(
  svgSelector: SvgSelector,
  { height, margin }: ChartDimensions
) {
  svgSelector
    .append("line")
    .attr("class", TooltipLineClass)
    .attr("x1", 0)
    .attr("y1", margin)
    .attr("x2", 0)
    .attr("y2", height - margin)
    .attr("stroke", "blue")
    .style("stroke-dasharray", "2")
    .style("pointer-events", "none")
    .style("opacity", 0);
}
