import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import { OVERALL_GROUP_NAME } from "../../lib/api-schema/misc";
import { useGroupsQuery } from "../../hooks/tanstack-query";

export function useSelectedGroup() {
  const [searchParams] = useSearchParams();
  const groupShort = searchParams.get(DRAParamsMap.g)?.trim();
  const groupShortIdMaybe = groupShort ? parseInt(groupShort) : undefined;
  const groupShortId =
    groupShortIdMaybe !== undefined && !isNaN(groupShortIdMaybe)
      ? groupShortIdMaybe
      : undefined;

  const groupsQuery = useGroupsQuery();
  return (
    groupsQuery.data?.find((g) => g.short_id === groupShortId) ??
    groupsQuery.data?.find((g) => g.name === OVERALL_GROUP_NAME) ??
    groupsQuery.data?.[0]
  );
}
