import { type GlobalRange } from "../svv-store/use-svv-store";

const PRECISION = 3;

function getInitialClampStoreInputValueForBv(
  globalRange: GlobalRange | undefined,
  bvOrExpressionId: string,
  idx: 0 | 1
): string {
  return globalRange?.perBv[bvOrExpressionId]?.[idx].toFixed(PRECISION) ?? "";
}

export { getInitialClampStoreInputValueForBv, PRECISION };
