import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import pages from "./pages";

function HelpHome() {
  useDocumentTitle("Help > DRA");

  return (
    <MainLayout goBackToDRAButton helpPage>
      <div className="w-full flex MIN_H_SCREEN justify-center items-center flex-col gap-8">
        <h2 className="text-[3rem]">DRA Help Center</h2>
        <div className="grid grid-cols-2 w-[55%] h-1/2 gap-4">
          {pages.map((p) => (
            <Link
              key={p.url}
              to={"/help/" + p.url}
              className="flex flex-col items-center justify-center border-[3px] border-bordgrey bg-base-100 rounded-md gap-2 p-4 hover:border-btnblue min-h-[23vh] hover:cursor-pointer hover:bg-[#e0e8eb]"
              style={{ borderTopColor: p.color }}
            >
              <div className="text-[2rem]">{p.sectiontitle}</div>
              <div className="text-[1.25rem] w-full text-center text-zinc-400">
                {p.subtitle}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </MainLayout>
  );
}

export default HelpHome;
