import { Duration } from "luxon";

export function parseDuration(durationStr: string) {
  // This regex covers "h", "hr", "hrs" for hours and "m", "min", "mins" for minutes
  const regex = /(\d+)\s*(h|hr|hrs|m|min|mins)?/i;
  const matches = durationStr.match(regex);

  let hours = 0;
  let minutes = 0;

  if (matches) {
    const matches1 = matches[1];
    const matches2 = matches[2];
    if (matches2) {
      const unit = matches2.toLowerCase();
      if (unit.startsWith("h") && matches1) {
        hours = parseInt(matches1, 10);
      } else if (unit.startsWith("m") && matches1) {
        minutes = parseInt(matches1, 10);
      }
    } else {
      // If no unit is specified, assume the number is hours
      hours = parseInt(matches1 || "0", 10);
    }
  }

  return Duration.fromObject({ hours, minutes });
}
