type Userlike = {
  first: string;
  last: string;
  _id: string;
};

function getCapitalInitials<T extends Userlike>(user: T) {
  return `${user.first[0] ?? ""}${user.last[0] ?? ""}`.toUpperCase();
}

function getFullName<T extends Pick<Userlike, "first" | "last">>(user: T) {
  return `${user.first} ${user.last}`;
}

export { getCapitalInitials, getFullName, type Userlike };
