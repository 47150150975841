import { ComponentProps } from "react";
import { useDateState } from "../../zustand/useDateState";
import { LinkWithQuery } from "./LinkWithQuery2";
import { endOfDay, isAfter, subDays } from "date-fns";

export function useGetLinkWithQueryPropsForRealtime() {
  const DS = useDateState();

  const getLinkProps = (
    unitIsRealTime: boolean
  ): Pick<ComponentProps<typeof LinkWithQuery>, "pick" | "set"> => {
    if (
      !unitIsRealTime &&
      (DS.axisRangeTo.isToday ||
        isAfter(DS.axisRangeTo.local, endOfDay(new Date())))
    ) {
      // then we need to pass a different link to link with query
      const {
        dateParts: { d, m, y },
      } = DS.util.getAllDateRelatedData(subDays(new Date(), 1));

      return {
        pick: {
          z: true,
          cd: true,
        },
        set: {
          d,
          mo: m,
          y,
        },
      };
    }

    return {
      pick: {
        d: true,
        mo: true,
        y: true,
        z: true,
        cd: true,
      },
      set: undefined,
    };
  };

  return getLinkProps;
}
