import _ from "lodash";
import {
  type Paragraph,
  type List,
  Content,
  type Image,
  type PDF,
  type SubSection,
  type Video,
} from "./types/BuilderTypes";

export function paragraphTemplate(): Paragraph {
  return {
    type: "paragraph",
    content: "Type your paragraph here",
    id: _.uniqueId("content-"),
  };
}

export function listTemplate(): List {
  return {
    type: "list",
    bullets: false,
    content: [listContentTemplate(), listContentTemplate()],
    id: _.uniqueId("content-"),
  };
}

export function pdfTemplate(): PDF {
  return {
    type: "pdf",
    filename: "",
    displayname: "",
    codename: "",
    id: _.uniqueId("content-"),
  };
}

export function imageTemplate(): Image {
  return {
    type: "image",
    filename: "",
    codename: "",
    id: _.uniqueId("content-"),
  };
}

export function videoTemplate(): Video {
  return {
    type: "video",
    filename: "",
    codename: "",
    captionedcodename: "",
    captionedfilename: "",
    id: _.uniqueId("content-"),
  };
}

export function listContentTemplate() {
  return {
    value: "",
    id: _.uniqueId("content-list-"),
    marginLeft: "0",
  };
}

export function subsectionTemplate(): SubSection {
  return {
    id: _.uniqueId("subsection-"),
    title: "Subsection Example",
    content: [paragraphTemplate(), listTemplate()],
  };
}
