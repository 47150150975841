import { startOfDay } from "date-fns";
import useCurrentUnitObject from "../components/common/hooks/useCurrentUnitObject";
import { UTCStringToLocalDateObject } from "./dateUtil";

export function useProcStartDate(): Date {
  const unit = useCurrentUnitObject();
  // if no proc is defined, use this
  const defaultStartDate = startOfDay(new Date(2020, 0, 1)); // January 1, 2020

  if (!unit) {
    return defaultStartDate;
  }

  const { procStartDate } = unit;
  return !procStartDate
    ? defaultStartDate
    : startOfDay(UTCStringToLocalDateObject(procStartDate));
}
