import React, { useState, useRef, useMemo, useCallback } from "react";
import PetalChart from "../../charts/PetalChart";
import Footer from "../../nav/Footer";
import useResizeObserver from "../../common/useResizeObserver";
import debounce from "../../common/debounce";
import PetalLegend from "../petal-legend";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import { ellipsify } from "../../utils/stylable";
import useConfigStore from "../../../zustand/config/useConfigStore";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import PetalChartSkeleton from "../../skeletons/PetalChartSkeleton";
import MainLayout from "../../layouts/MainLayout";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { usePlantOverviewQuery } from "./use-init-pov";
import { useGetLinkWithQueryPropsForRealtime } from "../../nav/use-get-link-with-query-props-for-realtime";
import { motion } from "framer-motion";

function PlantOverview() {
  const authed = useAuthStore((s) => s.authed);
  const Config = useConfigStore((s) => s.config);
  const unitsAndTheirOverallGroups = usePlantOverviewQuery();
  const [petalChartDimensions, setPetalChartDimensions] = useState([0, 0]);

  useDocumentTitle(
    Config?.facilityName ? Config.facilityName + " > DRA" : "DRA"
  );

  // the next 3 consts are used to resize petal charts when we resize
  const firstPetalRef = useRef();
  const debouncedSetPetalDims = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      debounce((elt: any) => {
        const { clientWidth } = elt;
        setPetalChartDimensions([clientWidth, clientWidth / 2]);
      }, 200),
    []
  );
  useResizeObserver(firstPetalRef, debouncedSetPetalDims);

  const getLinkWithQueryProps = useGetLinkWithQueryPropsForRealtime();

  // this is used to set the initial petal chart size, after its container renders
  // useCallback is necessary so that react calls it once
  // passing the arrow function to ref instead will cause it to be called infinitely
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const measuredRef = useCallback((elt: any) => {
    if (!elt) {
      return;
    }
    firstPetalRef.current = elt;
    const { clientWidth } = elt;
    setPetalChartDimensions([clientWidth, clientWidth / 2]);
  }, []);

  const Nav = useNavigate();
  if (!authed) {
    Nav("/login", { replace: true });
  }

  return (
    <MainLayout showDateNav={true}>
      <div className="flex flex-col SIDEBAR-DOUBLE">
        {/* title and legend row  */}
        <div className="flex justify-between py-2 items-start mb-1 px-4">
          <span className="tracking-tight text-2xl font-medium">
            {Config?.facilityName ? Config.facilityName : "Plant Overview"}
          </span>
          <PetalLegend bordered />
        </div>
        {/* the page content begins here  */}
        <div className="px-4 mb-auto animate-in slide-in-from-bottom-7">
          <div className="grid grid-cols-12 auto-rows-max gap-8 mt-4">
            {unitsAndTheirOverallGroups.map((groupMaybe, i) => {
              if (!groupMaybe) return <PetalChartSkeleton key={i.toString()} />;

              return (
                <motion.div
                  initial={{ opacity: 0.3, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  key={groupMaybe.overallGroup._id}
                  className="2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 group/container"
                >
                  <div
                    className="card max-w-xl bg-white dark:bg-xslate-3 rounded-md border group-hover/container:border-xslate-7 border-xslate-6 group-hover/container:shadow-xl shadow-sm"
                    ref={i === 0 ? measuredRef : undefined}
                  >
                    <LinkWithQuery
                      className="card-body pb-2 pt-2 px-2 hover:cursor-pointer hover:bg-zinc-200 group/petaltitle"
                      {...getLinkWithQueryProps(groupMaybe.unitData.realTime)}
                      to={`/${groupMaybe.unitData.name.trim()}/ov`}
                      relative="path"
                    >
                      <div className="leading-none font flex items-center gap-2">
                        <div className="badge badge-md text-[0.7rem]">
                          {ellipsify(groupMaybe.unitData.shortName, 6)}
                        </div>
                        <div className="text-[0.9rem]">
                          {ellipsify(groupMaybe.unitData.displayName || "", 25)}
                        </div>
                        <div className="invisible group-hover/petaltitle:visible ml-auto">
                          <FaArrowRight />
                        </div>
                      </div>
                    </LinkWithQuery>
                    <PetalChart
                      className={"bg-white dark:bg-xslate-2"}
                      scaleText={undefined}
                      style={{ slim: true }}
                      groupId={groupMaybe.overallGroup._id}
                      width={petalChartDimensions[0]}
                      height={petalChartDimensions[1]}
                      unitName={groupMaybe.unitData.name}
                    />
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </MainLayout>
  );
}

export default PlantOverview;
