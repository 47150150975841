import { DateTime } from "luxon";
import { DtOptions } from "./types";
import { EARLIEST_DATE } from "../../common/date-state-calendar/constants";

function validateDay(year: number, month: number, day: number) {
  if (month === 2) {
    if (year % 4 === 0) return day >= 1 && day <= 29;
    return day >= 1 && day <= 28;
  } else if (month === 4 || month === 6 || month === 9 || month === 11) {
    return day >= 1 && day <= 30;
  }
  return day >= 1 && day <= 31;
}

/** function to validate the input */
function validateInput(str: string, showTime: DtOptions["showTime"]) {
  const [date, time] = str.split(" ");
  if (!date) return false;

  const [year, month, day] = date.split("/");
  if (!year || !month || !day) return false;

  const [hour, min] = time?.split(":") || [undefined, undefined];

  // validate year
  if (+year > DateTime.now().year || +year < EARLIEST_DATE.getFullYear())
    return false;

  // validate month
  if (+month < 1 || +month > 12) return false;

  // validate day based on month and year
  if (!validateDay(+year, +month, +day)) return false;

  if (!showTime) return true;

  // validate hour and minute if present
  if (hour === undefined || min === undefined) return false;
  if (+hour < 0 || +hour > 23) return false;
  if (+min < 0 || +min > 59) return false;

  return true;
}

export { validateInput };
