import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useConfigStore from "../../zustand/config/useConfigStore";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { POST } from "../../frameworks/fetcher/fetcher";
import { useRedirectSearchParam } from "./NavigateToLogin";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../toast/use-toast-store";
import { Button } from "../../shared-ui/frontend/button";
import { Input } from "../../shared-ui/frontend/input";

/**
 * redirects to the plant overview page
 * if the user is authenticated.
 */
export function LoginForm({
  setPass,
  setSubmitting,
  submitting,
}: {
  setPass?: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
}) {
  const redirectURL = useRedirectSearchParam();
  const authed = useAuthStore((s) => s.authed);
  const Config = useConfigStore((s) => s.config);
  const activeDirectoryEnabled = !!Config?.activeDirectoryEnabled;

  const [state, setState] = useState({
    user: setPass
      ? new URLSearchParams(window.location.search).get("email") || ""
      : "",
    pwd: "",
    error: "",
  });

  const [forgotMode, setForgotMode] = useState(false);

  async function login() {
    if (!state.user || !state.pwd) {
      return;
    }
    return useAuthStore
      .getState()
      .authenticate(state.user, state.pwd)
      .catch((e) => addUnknownErrorToast(e));
  }

  async function sendPasswordReset() {
    return POST(`/password-tokens`, {
      body: {
        email: state.user,
      },
    })
      .then(() => {
        setState((old) => ({ ...old }));
        addSuccessToast(
          `Successfully sent password reset email to ${state.user}`
        );
      })
      .catch((err) => addUnknownErrorToast(err));
  }

  if (authed) {
    /**
     * This will get hit after they successfully login
     * using the form below. authed will be updated to true
     * and then we will redirect them to the plant overview page
     * (or if redirect is present in the url, we will redirect them there)
     */
    return <Navigate replace to={redirectURL ?? "/"} />;
  }

  function handleOnSubmit() {
    setSubmitting(true);
    const fn = forgotMode ? sendPasswordReset : login;
    fn().finally(() => setSubmitting(false));
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmit();
      }}
    >
      <Input
        disabled={submitting}
        placeholder={
          activeDirectoryEnabled
            ? `${
                Config.customerMeta?.companyName || "Company"
              } ID (e.g. mohdali.abdullah)`
            : "Email"
        }
        required
        minLength={1}
        value={state.user}
        onChange={(e) => setState((old) => ({ ...old, user: e.target.value }))}
        className="w-full mt-8"
      />
      {!forgotMode && (
        <Input
          disabled={submitting}
          placeholder="Password"
          value={state.pwd}
          onChange={(e) => setState((old) => ({ ...old, pwd: e.target.value }))}
          type="password"
          required
          pattern={Config?.passwordPolicy?.regex}
          title={Config?.passwordPolicy?.message}
          className="w-full mt-2"
        />
      )}
      {activeDirectoryEnabled ? null : (
        <div className="flex justify-end">
          <Button
            type="button"
            variant={"link"}
            size={"xxs"}
            disabled={submitting}
            onClick={(e) => {
              e.preventDefault();
              setForgotMode(!forgotMode);
            }}
            className="px-0 py-5"
          >
            {forgotMode ? "Return to login" : "Reset Password"}
          </Button>
        </div>
      )}
      <div className="flex justify-center mt-8">
        <Button disabled={submitting} type="submit" className="w-full">
          {forgotMode ? "Reset Password" : "Log In"}
        </Button>
      </div>
    </form>
  );
}
