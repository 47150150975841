import { forwardRef } from "react";
import { type groupSchema } from "../../lib/api-validators";
import PetalChart from "../charts/PetalChart";
import PetalLegend from "../ov/petal-legend";
import PetalChartSkeleton from "../skeletons/PetalChartSkeleton";
import { useBaseUrlExperimental } from "../../zustand/useBaseUrl";

export const DRIChartsTopOfPage = forwardRef<
  HTMLDivElement,
  { group: groupSchema | undefined; petalChartDimensions: [number, number] }
>(({ group, petalChartDimensions }, ref) => {
  const unitName = useBaseUrlExperimental();

  return (
    <div
      className="w-1/2 bg-white border border-zinc-300 rounded-md"
      ref={ref}
      /**
       * (el) receives the div DOM element after it is rendered so we can know
       * about its size to subsequently decide what the petal chart dimensions
       * should be
       *
       * see https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
       */
    >
      <div className="relative card h-full">
        <div className="absolute top-10 left-0">
          <PetalLegend col />
        </div>
        <div className="card-title bg-base-100 py-1 px-2">
          Dynamic Risk Index
        </div>
        {group ? (
          <PetalChart
            className={undefined}
            scaleText={0.0016}
            groupId={group._id}
            width={petalChartDimensions[0]}
            height={petalChartDimensions[1]}
            style={{ svg: { margin: "auto auto 0 auto", overflow: "visible" } }} // top right bottom left
            unitName={unitName.replace("/", "")}
          />
        ) : (
          <PetalChartSkeleton />
        )}
      </div>
    </div>
  );
});

DRIChartsTopOfPage.displayName = "DRITopHalfPage";
