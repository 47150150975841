"use client";

import * as React from "react";
import { Drawer, DrawerContent } from "../../frontend/drawer";
import { type useStore } from "jotai";
import { PropsWithCn } from "../../frontend/cn";

type JotaiStore = ReturnType<typeof useStore>;

type StoresForFullscreenContext = {
  jotaiStore: JotaiStore;
};

const FullscreenContext = React.createContext<
  | {
      stores: StoresForFullscreenContext | undefined;
      setFullscreenStore: (s: StoresForFullscreenContext | undefined) => void;
    }
  | undefined
>(undefined);

function FullscreenProvider({ children }: React.PropsWithChildren) {
  const [stores, setFullscreenStore] =
    React.useState<StoresForFullscreenContext>();

  return (
    <FullscreenContext.Provider
      value={{
        stores,
        /**
         * You would think that you can actually just do:
         *
         * setFullscreenStore: setFullscreenStore
         *
         * But this reason why this doesn't work is because "s"
         * is a function. And so React will actually translate
         *
         * setFullscreenStore(s) into setFullscreenStore(s())
         *
         * Think about when you can have
         *
         * const [n, setN] = useState(0)
         *
         * and you do setN(n + 1) vs setN(() => n + 1).
         * React will translate setN(() => n + 1) into setN((() => n + 1)(n))
         *
         * In this case, it's fine because n is a number.
         *
         * But in the case of s, it's a function. So you need
         * to be explicit about it, so react doesn't think you're
         * using a function update.
         */
        setFullscreenStore: (s) => setFullscreenStore(() => s),
      }}
    >
      {children}
    </FullscreenContext.Provider>
  );
}

function useFullscreenContext() {
  /**
   * Intentionally allowing this to return undefined
   */
  return React.useContext(FullscreenContext);
}

function useIsFullscreen() {
  const ctx = useFullscreenContext();

  return (store: JotaiStore) => {
    if (!ctx) return false;
    const stores = ctx.stores;
    if (!stores) return false;
    return stores.jotaiStore === store;
  };
}

function useToggleFullscreen() {
  const fullscreenCtx = useFullscreenContext();
  const isFullscreen = useIsFullscreen();

  if (!fullscreenCtx) return;

  return (store: JotaiStore) => {
    const iAmFullscreen = isFullscreen(store);
    if (iAmFullscreen) {
      fullscreenCtx.setFullscreenStore(undefined);
    } else {
      fullscreenCtx.setFullscreenStore({
        jotaiStore: store,
      });
    }
  };
}

function FullscreenDrawer({
  children,
  className,
}: {
  children: (store: JotaiStore) => React.ReactNode;
} & PropsWithCn) {
  const ctx = useFullscreenContext();

  useRerenderComponentWhenWindowResized(ctx !== undefined, 50);

  if (!ctx) return null;

  const stores = ctx.stores;

  return (
    <Drawer
      dismissible={false}
      open={stores !== undefined}
      onOpenChange={(isOpen) => {
        if (!isOpen) ctx.setFullscreenStore(undefined);
      }}
    >
      <DrawerContent className={className}>
        {stores !== undefined && children(stores.jotaiStore)}
      </DrawerContent>
    </Drawer>
  );
}

function useRerenderComponentWhenWindowResized(active: boolean, ms: number) {
  /**
   * use this state to cause the
   * component to re-render when the window is
   * resized. This is because the chart won't
   * look right if it doesn't re-render.
   */
  const [, setDummy] = React.useState(0);

  React.useEffect(() => {
    if (!active) return;

    let timer = undefined as undefined | ReturnType<typeof setTimeout>;

    const cb = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setDummy((d) => d + 1);
      }, ms);
    };
    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
      clearTimeout(timer);
    };
  }, [active, ms]);
}

export {
  FullscreenProvider,
  useFullscreenContext,
  FullscreenDrawer,
  useIsFullscreen,
  useToggleFullscreen,
};
