import classNames from "classnames";
import React, { useRef, useCallback } from "react";
import Input from "./Input/Input";
import Select from "./Select/Select";

/**
 * Text and select inputs that interface with the useSearch hook
 *
 * This is one implementaton that is used for some of the manager pages.
 * Note that this implementation is completely independent of the hook,
 * as it has a flexible API.
 */

const debounce = (cb, delay = 280) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => cb(...args), delay);
  };
};

function SearchBar({ filter, search, sort, children }) {
  const qRef = useRef();
  // we want to keep the same debounce closure, specifically the timeout variable inside it
  const setQueryDebounced = useCallback(debounce(search.updateQuery), []);

  const searchInput = (
    <Input
      ref={qRef}
      placeholder={search.hint && `Search ${search.queryBy}`}
      type="text"
      size="sm"
      onInput={(e) => setQueryDebounced(e.target.value)}
      className={search.queryByNames.length > 1 ? "w-1/2" : ""}
      label={search.label ? search.label : undefined}
    />
  );

  return (
    <div className="gap-2 w-full items-center flex flex-col md:flex-row md:items-center md:justify-between mt-2">
      {search && (
        <div
          className={classNames("w-full md:w-auto", {
            "input-group": search.queryByNames.length > 1, // input group with only 1 element inside will make the styling weird, ensure we actually have 2 (or more) components
          })}
        >
          <span className="bg-neutral text-neutral-content text-[0.9rem]">
            Search
          </span>
          {searchInput}
          {search.queryByNames.length > 1 ? (
            <Select
              // autoSize
              className={"w-[155px] select-bordered"}
              // style={{
              //   marginLeft: "0",
              //   borderLeft: "none",
              //   lineHeight: "2.1em",
              //   height: "2.1em",
              //   borderBottomLeftRadius: "0",
              //   borderTopLeftRadius: "0",
              // }}
              onInput={(t) => {
                search.updateQueryBy(t.target.value);
                search.updateQuery(""); // reset the query in the useSearch logic
                qRef.current.value = ""; // reset the input display
              }}
              value={search.queryBy}
              options={search.queryByNames.map((name) => ({
                label: name,
                value: name,
              }))}
            />
          ) : null}
        </div>
      )}
      <div className="gap-2 max-w-min flex flex-row justify-between">
        {sort && (
          <div className="input-group md:mr-2 max-w-min">
            <span className="bg-neutral text-neutral-content text-[0.9rem]">
              Sort
            </span>
            <Select
              className={"select-bordered max-w-[155px]"}
              onInput={(e) => sort.updateSortName(e.target.value)}
              value={sort.sortName}
              options={sort.sortNames.map((name) => ({
                label: name,
                value: name,
              }))}
            />
          </div>
        )}
        {filter && (
          <div className="input-group  max-w-min">
            <span className="bg-neutral text-neutral-content text-[0.9rem]">
              Filter
            </span>
            <Select
              className={"w-auto max-w-[155px] select-bordered"}
              onInput={(e) => filter.updateFilterName(e.target.value)}
              value={filter.filterName}
              options={filter.filterNames.map((name) => ({
                label: filter.prefix ? "Filter: " : "" + name,
                value: name,
              }))}
              label={filter.label ? filter.label : undefined}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default SearchBar;
