import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "./cn";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-xslate-4 text-xslate-12 hover:bg-xslate-6",
        outline: "text-foreground border-xslate-7",
        success: "border-xgreen-8 bg-xgreen-3 text-xgreen-11 hover:bg-xgreen-3",
        primary:
          "border-xindigo-8 bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4",
        danger: "border-xred-8 bg-xred-3 text-xred-11 hover:bg-xred-4",
        violet:
          "border-xviolet-8 bg-xviolet-3 text-xviolet-11 hover:bg-xviolet-4",
        amber: "border-xamber-8 bg-xamber-3 text-xamber-11 hover:bg-xamber-4",
        orange:
          "border-xorange-8 bg-xorange-3 text-xorange-11 hover:bg-xorange-4",
        aria: "border-aria-8 bg-aria-3 text-aria-11 hover:bg-aria-4",
        blue: "border-xblue-8 bg-xblue-3 text-xblue-11 hover:bg-xblue-4",
      },
      size: {
        xs: "px-2 py-0 text-[0.6rem] leading-3",
        sm: "px-2.5 py-0.5 text-xs",
        default: "px-2.5 py-0.5 text-xs",
        md: "px-3 py-1 text-sm",
        lg: "px-4 py-1.5 text-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(function Badge(
  { variant, size, className, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
});

export { Badge, badgeVariants };
