import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useConfigStore from "../../zustand/config/useConfigStore";
import { PATCH } from "../../frameworks/fetcher/fetcher";
import { LoadingOverlay } from "@mantine/core";
import Button from "../common/Button/Button";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { routerPaths } from "../../constants/routerPaths";
import Input from "../common/Input/Input";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../toast/use-toast-store";
import { FooterLogo } from "../nmm-branding/footer-logo";
import { DynamicRiskAnalyzerLogo } from "../nmm-branding/dynamic-risk-analyzer-logo";

/**
 * redirects to the plant overview page
 * if the user is authenticated.
 */
function ResetPass() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authed = useAuthStore((s) => s.authed);
  const logout = useAuthStore((s) => s.unAuthenticate);
  const Config = useConfigStore((s) => s.config)!;
  const activeDirectoryEnabled = !!Config?.activeDirectoryEnabled;

  const [state, setState] = useState({
    user: new URLSearchParams(window.location.search).get("email") || "",
    pwd: "",
    pwd2: "",
    error: "",
  });

  const [submitting, setSubmitting] = useState(false);

  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/" + routerPaths.login);
    }
  }, [token, navigate]);

  async function setPassword() {
    if (!token) return;

    return PATCH(`/users/set-password`, {
      body: {
        token,
        password: state.pwd,
      },
    })
      .then(() => {
        addSuccessToast(`Successfully set password for ${state.user}`);
        setTimeout(() => {
          navigate("/");
        }, 1500);
      })
      .catch((err) => addUnknownErrorToast(err));
  }

  if (authed) {
    logout();
  }

  return (
    <div className="flex justify-center h-screen pt-[25vh]">
      <LoadingOverlay color="dark" visible={submitting} />
      <div className="mx-4 sm:mx-auto w-full sm:w-4/5 md:w-3/5 lg:w-1/ xl:w-1/3 2xl:w-1/4">
        <FooterLogo className="w-1/2 mx-auto" />
        <DynamicRiskAnalyzerLogo className="w-full my-8" />
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (state.pwd !== state.pwd2) {
              return addToast({
                title: "Passwords do not match",
                variant: "danger",
              });
            }
            setSubmitting(true);
            setPassword().finally(() => setSubmitting(false));
          }}
        >
          <Input
            placeholder="Password"
            size="md"
            value={state.pwd}
            onChange={(e) =>
              setState((old) => ({ ...old, pwd: e.target.value }))
            }
            type="password"
            required
            pattern={Config.passwordPolicy?.regex}
            title={Config.passwordPolicy?.message}
            className="w-full mt-2"
          />
          <Input
            placeholder="Confirm password"
            size="md"
            value={state.pwd2}
            onChange={(e) =>
              setState((old) => ({ ...old, pwd2: e.target.value }))
            }
            type="password"
            required
            pattern={Config.passwordPolicy?.regex}
            title={Config.passwordPolicy?.message}
            className="w-full mt-2"
          />
          {activeDirectoryEnabled ? null : (
            <div className="flex justify-end">
              <Link to={"/" + routerPaths.login}>
                <Button className="btn-link text-[0.7rem] p-0 normal-case">
                  Return to login
                </Button>
              </Link>
            </div>
          )}

          <div className="flex justify-center mt-8">
            <Button type="submit" className="btn-primary w-full btn-outline">
              Set password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPass;
