import classNames from "classnames";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../common/Button/Button";
import HelpPages from "../pages";

export default function SideBar() {
  const loc = useLocation();

  return (
    <div className="flex flex-col mt-2 pb-4">
      <Link to="..">
        <Button
          className="normal-case btn-ghost mb-2 max-w-max"
          icon="chevron-left"
        >
          DRA Help Center
        </Button>
      </Link>
      {HelpPages.map((page) => {
        const active = loc.pathname.endsWith(page.url);

        const header = (
          <div
            key={page.sectiontitle}
            className={classNames("mb px-1 text-[13px] font-semibold mb-1", {
              "text-primary": active,
              "text-slate-400": !active,
            })}
          >
            {page.sectiontitle}
          </div>
        );

        const subLinks = page.subsections.map((s, idx, arr) => {
          const theNextEltIsAHeader = idx === arr.length - 1;
          return (
            <Link
              key={page.url + s.title}
              to={"../" + page.url + ("#" + encodeURIComponent(s.title))}
            >
              <div
                key={page.sectiontitle + s.title}
                className={classNames(
                  "rounded-md p-1.5 px-3 flex items-center gap-3 group text-[14px] hover:bg-zinc-200 hover:cursor-pointer hover:text-indigo-800 text-neutral",
                  {
                    "mb-3": theNextEltIsAHeader,
                    "mb-[2px]": !theNextEltIsAHeader,
                  }
                )}
              >
                {s.title}
              </div>
            </Link>
          );
        });

        return (
          <div key={page.url} className="px-4">
            {header}
            {subLinks}
          </div>
        );
      })}
    </div>
  );
}
