import * as d3 from "d3";
import { DateTime } from "luxon";
import { getDomainFromScale } from "./utils";

class DayHoverDrawer {
  private GREY = "#fafafa";
  private hoverTime = undefined as number | undefined;
  private timezone: string;
  private canvas: HTMLCanvasElement;
  private trendChartHeight: number;
  private ctx: CanvasRenderingContext2D;
  private padding: {
    top: number;
    left: number;
  };

  constructor({
    canvas,
    timezone,
    trendChartHeight,
    padding,
  }: {
    timezone: string;
    canvas: HTMLCanvasElement;
    trendChartHeight: number;
    padding: {
      top: number;
      left: number;
    };
  }) {
    this.padding = padding;
    this.timezone = timezone;
    this.canvas = canvas;
    this.trendChartHeight = trendChartHeight;
    const ctx = this.canvas.getContext("2d");

    if (!ctx) throw new Error("cannot get 2d context");

    this.ctx = ctx;
  }

  setHoverTime(hoverTime: number): DayHoverDrawer {
    this.hoverTime = hoverTime;
    return this;
  }

  draw(xS: d3.ScaleTime<number, number, never>) {
    const zoomedWindow = getDomainFromScale(xS);
    if (this.hoverTime === undefined)
      throw new Error("cannot draw before setting hoverTime");

    const s = DateTime.fromMillis(this.hoverTime, {
      zone: this.timezone,
    }).startOf("day");
    const end = s.endOf("day").toMillis();
    const start = s.toMillis();

    const ctx = this.ctx;

    const x0 = xS(Math.max(start, zoomedWindow[0]));
    const x1 = xS(Math.min(zoomedWindow[1], end));
    const width = x1 - x0;

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.fillStyle = this.GREY;
    ctx.fillRect(
      x0 + this.padding.left,
      this.padding.top,
      width,
      this.trendChartHeight
    );
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}

export { DayHoverDrawer };
