import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

function createClusterDrawerStore() {
  return createStore<{
    clusterId: string | undefined;
    setClusterId: (clusterId: string | undefined) => void;
  }>((set) => ({
    clusterId: undefined,
    setClusterId: (clusterId) => set({ clusterId }),
  }));
}

type ClusterDrawerStore = ReturnType<typeof createClusterDrawerStore>;

const ClusterDrawerStoreContext = createContext<ClusterDrawerStore | undefined>(
  undefined
);

function ClusterDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createClusterDrawerStore);
  return (
    <ClusterDrawerStoreContext.Provider value={store}>
      {children}
    </ClusterDrawerStoreContext.Provider>
  );
}

function useClusterDrawerStoreNotRequired() {
  return useContext(ClusterDrawerStoreContext);
}

function useClusterDrawerStore() {
  const store = useContext(ClusterDrawerStoreContext);

  if (!store) {
    throw new Error(
      "useClusterStore must be used within a ClusterStoreProvider"
    );
  }

  return store;
}

export {
  ClusterDrawerStoreProvider,
  useClusterDrawerStore,
  useClusterDrawerStoreNotRequired,
};
