import getColors from "./colors";

export const withRgbString = (colors: ReturnType<typeof getColors>) =>
  colors.map((o) => {
    const { r, g, b } = o;
    return {
      ...o,
      rgb: `rgb(${r}, ${g}, ${b})`,
    };
  });
