import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import useAuthStore from "../../zustand/auth/useAuthStore";

function UserPermissionRoute({
  rolenum,
  children,
}: PropsWithChildren<{ rolenum: number }>) {
  const user = useAuthStore((s) => s.user);

  return user && user.numericRole >= rolenum ? (
    children
  ) : (
    <Navigate replace to="/" />
  );
}

export default UserPermissionRoute;
