import React, { memo, useEffect } from "react";
import "./StatusSeries.scss";
import { PropsWithCn } from "../../../types/component.types";
import { cn } from "../../../../lib/utils";
import { getOperatingLimitStatusSeries } from "../../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../../zustand/useBaseUrl";
import { useQuery } from "@tanstack/react-query";
import { operatingLimitSchema } from "../../../../lib/api-schema/operating-limit";
import { YYYY_MM_DD } from "../../../../lib/validators";
import { BaseQueryOptions } from "../../../../hooks/tanstack-query";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minutesToMilliseconds } from "date-fns";

type Status = "Active" | "Inactive";

const statusStyles: {
  [S in Status]: { color: `#${string}`; stroke: `#${string}` };
} = {
  Active: {
    color: "#0071bc",
    stroke: "#0071bc",
  },
  Inactive: {
    color: "#d9d9d9",
    stroke: "#d9d9d9",
  },
};

type DrawStyle = "rounded" | "square";

const SQUARE_PADDING = 3;
const STYLES: {
  [D in DrawStyle]: (
    ctx: CanvasRenderingContext2D,
    start: number,
    width: number,
    height: number
  ) => void;
} = {
  /* Traditional "pill-shaped" days */
  rounded: function (ctx, start, width, height) {
    const end = start + width - 2 * STROKE_WIDTH;

    /* On condensed charts, make sure the radius isn't wider than the tick itself. */
    const radius = Math.min(Math.ceil(height / 2), Math.ceil(width / 2));
    const paddingTop = 4;
    const bottom = height - paddingTop;

    ctx.moveTo(start + radius, paddingTop);
    ctx.lineTo(end - radius, paddingTop);
    ctx.quadraticCurveTo(end, paddingTop, end, radius + paddingTop);
    ctx.lineTo(end, bottom - radius);

    ctx.quadraticCurveTo(end, bottom, end - radius, bottom);
    ctx.lineTo(start + radius, bottom);
    ctx.quadraticCurveTo(start, bottom, start, bottom - radius);
    ctx.lineTo(start, radius + paddingTop);
    ctx.quadraticCurveTo(start, paddingTop, start + radius, paddingTop);
  },

  /* (Almost) ridgid blocks with padding. */
  square: function (ctx, start, width, height) {
    const splitSquarePadding = Math.floor(SQUARE_PADDING / 2);

    const end = start + width - 2 * STROKE_WIDTH - splitSquarePadding;
    start = start + splitSquarePadding;

    const radius = 2; /* Extremely narrow radius */
    const paddingTop = 4;
    const bottom = height - paddingTop;

    ctx.moveTo(start + radius, paddingTop);
    ctx.lineTo(end - radius, paddingTop);
    ctx.quadraticCurveTo(end, paddingTop, end, radius + paddingTop);
    ctx.lineTo(end, bottom - radius);

    ctx.quadraticCurveTo(end, bottom, end - radius, bottom);
    ctx.lineTo(start + radius, bottom);
    ctx.quadraticCurveTo(start, bottom, start, bottom - radius);
    ctx.lineTo(start, radius + paddingTop);
    ctx.quadraticCurveTo(start, paddingTop, start + radius, paddingTop);
  },
};

const STROKE_WIDTH = 1;

function useOperatingLimitsStatusSeriesQuery(
  payload: Parameters<typeof getOperatingLimitStatusSeries>[1],
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["operatingLimitsStatusSeries", baseUrl, payload],
    queryFn: () => {
      return getOperatingLimitStatusSeries(baseUrl, payload);
    },
    refetchOnWindowFocus: false,
    ...opts,
  });
}

export const StatusSeries2 = memo(function StatusSeries2({
  className,
  drawStyle,
  limit,
  end,
  start,
}: {
  drawStyle: DrawStyle;
  limit: Pick<operatingLimitSchema, "_id">;
  start: YYYY_MM_DD;
  end: YYYY_MM_DD;
} & PropsWithCn) {
  const inView = useAtomValue(Atoms.chartInViewportAtom);

  const statusSeriesQuery = useOperatingLimitsStatusSeriesQuery(
    {
      id: limit._id,
      start,
      end,
    },
    {
      enabled: inView,
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(10),
    }
  );

  const seriesCanvasRef = React.useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = seriesCanvasRef.current;
    const statusSeries = statusSeriesQuery.data?.data.map((datum) =>
      datum.value === 0 ? "Inactive" : "Active"
    );

    if (!statusSeries || !canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return; // canvas not supported?

    const renderCanvas = () => {
      /* On HDPI machines (like Macbooks), using the actual pixel value doesn't
       * give the display enough breathing room to scale correctly. You end up
       * with fuzzy borders. By giving it a 2x resolution, we make sure there are
       * enough pixels for the machine to comfortably do whatever resizing it
       * needs. And it saves us a little bit of intermitent blurring if the
       * browser window resizes upwards. */
      const bounds = canvas.getBoundingClientRect();
      const width = Math.ceil(bounds.width * 2);
      const height = Math.ceil(bounds.height * 2);

      /* A bit of padding helps avoid rounding cutoffs/clipping */
      canvas.width = width + 8;
      canvas.height = height + 8;

      const statuses: {
        [S in Status]: {
          start: number;
          width: number;
        }[];
      } = {
        Active: [],
        Inactive: [],
      };

      const elementWidth = width / statusSeries.length;
      statusSeries.forEach((status, index) => {
        const startX = elementWidth * index;

        /* Rounding to the nearest integer is important to avoid
         * anti-aliasing (fuzzy borders) */
        const tick = {
          start: Math.ceil(startX),
          width: Math.ceil(elementWidth),
        };

        const arr = statuses[status] ?? [];
        arr.push(tick);
        statuses[status] = arr;
      });

      Object.entries(statuses).forEach(([status, entries]) => {
        let statusStyle = statusStyles[status as Status] || {};
        let stroke = statusStyle.stroke;
        let fill = statusStyle.color;

        ctx.strokeStyle = stroke;
        ctx.fillStyle = fill;

        ctx.beginPath();
        for (const entry of entries) {
          STYLES[drawStyle](ctx, entry.start, entry.width, height);
        }
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      });

      // no longer used
      // const selectedIndex = this.props.selectedIndex;
      // _.each(statuses, (status, key) => {
      //   ctx.strokeStyle = ACTIVE_STROKE;
      //   ctx.lineWidth = ACTIVE_STROKE_WIDTH;

      //   _.each(status, (entry) => {
      //     if (entry.key !== selectedIndex) {
      //       return;
      //     }

      //     ctx.beginPath();
      //     STYLES[drawStyle](ctx, entry.start, entry.width, height);
      //     ctx.closePath();

      //     ctx.stroke();
      //   });
      // });
    };

    renderCanvas();
  }, [statusSeriesQuery.data, drawStyle]);

  return (
    <div
      className={cn(
        `StatusSeries--wrapper StatusSeries OperatingLimitsDetails__StatusSeries__chart`,
        className
      )}
    >
      <canvas
        className={`StatusSeries__chart OperatingLimitsDetails__StatusSeries__plot`}
        height="20"
        width="1000"
        ref={seriesCanvasRef}
      >
        HTML5 Canvas is not supported. If you can see this message, your browser
        is (probably) unsupported by DRA.
      </canvas>
    </div>
  );
});
