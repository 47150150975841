import { type KeyboardEvent, forwardRef, useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import { validateInput } from "./utils";

const DateInput = forwardRef<
  HTMLInputElement,
  {
    date: string;

    onChange: (d: string) => void;
    onSubmit: () => void;
    showTime: boolean;
  }
>(({ date, onChange, onSubmit, showTime }, ref) => {
  /**
   * When the date string changes from an external source, update
   * our local "editing state" to match it. This must be done
   * because:
   *
   * 1. the input needs a controlled value
   * 2. most of the time, the edit will be invalid until it isn't
   *
   */
  const [input, setInput] = useState(date);
  const [isInputValid, setIsInputValid] = useState(true);

  useEffect(() => {
    setInput(date);
  }, [date]);

  const baseStyle =
    "m-0 p-0 h-full box-content bg-transparent text-center border-none w-[16ch]";
  const focusedStyle = "focus-visible:outline-none";

  function handleKeyDown(e: KeyboardEvent) {
    const allowedKeys = [
      "Tab",
      "Backspace",
      "Delete",
      "Del",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      ":",
      "/",
      " ", // allow space button to be entered, useful for time
    ];

    // Check if the pressed key is not a number
    if (!/^\d$/.test(e.key) && allowedKeys.indexOf(e.key) === -1) {
      // Prevent the default behavior for non-number keys
      e.preventDefault();
      return;
    }

    // if key is enter, setDt to trigger dt useEffect and unmount
    if (e.key === "Enter") {
      onSubmit(); // tell consumer we "submitted"
    }
  }

  return (
    <input
      className={cn(
        baseStyle,
        focusedStyle,
        !isInputValid && "ring ring-xred-8"
      )}
      style={{
        borderRadius: "4px", // override input-group last child styling
      }}
      type="text"
      value={input}
      onKeyDown={handleKeyDown}
      onClick={(e) => e.preventDefault()}
      onChange={(e) => {
        const newValue = e.target.value;
        setInput(newValue);

        const isValid = validateInput(newValue, showTime);

        setIsInputValid(isValid);
        isValid && onChange(newValue);
      }}
      // placeholder={"todo"}
      ref={ref}
    />
  );
});

export default DateInput;
