import classNames from "classnames";
import React, { forwardRef } from "react";

type BaseInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputProps = {
  bordered?: boolean;
  size: "xs" | "sm" | "md" | "lg";
} & Omit<BaseInputProps, "size">; // remove size for our custom behavior

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ bordered = false, size = "md", className, ...rest }, ref) => {
    const inputClasses = classNames(className, "input", `input-${size}`, {
      "input-bordered": bordered,
    });
    return <input ref={ref} {...rest} className={inputClasses} />;
  }
);

Input.displayName = "Input";

export default Input;
