import * as R from "remeda";
import {
  getAcknowledgements,
  getActiveFTNodeIds,
  getAnomaly,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { anomalySchema, variableSchema } from "../../../lib/api-validators";
import moment from "moment";
import { z } from "zod";
import { acknowledgementSchema } from "../../../lib/api-schema/acknowledgment";
import { useQuery } from "@tanstack/react-query";
import { useVariablesMappedByIdQuery } from "../../../hooks/tanstack-query";
import { useGetUseAckManStore } from "./store/ack-man-store";
import useMaxDate from "../../common/hooks/useMaxDate";
import { YYYY_MM_DD } from "../../../lib/validators";
import { AnomalyLevelEnum } from "../../../types/api/Anomaly";

export function useAckManagerAnomaliesQuery() {
  const useAckManStore = useGetUseAckManStore();
  const maxDate = useMaxDate();
  const selectedDate = useAckManStore((s) => s.date) ?? maxDate;
  const selectedGroupId = useAckManStore((s) => s.selectedGroupId);
  const baseUrlSlash = useBaseUrlExperimental();

  const variablesMapQuery = useVariablesMappedByIdQuery();

  const variablesMap = variablesMapQuery.data;

  const formattedDate = YYYY_MM_DD.parse(
    moment(selectedDate).format("YYYY-MM-DD")
  );

  const query = useQuery({
    queryKey: [
      "acknowledgement-manager",
      baseUrlSlash,
      selectedGroupId,
      formattedDate,
    ],
    enabled: !!selectedGroupId && !!variablesMap,
    queryFn: async () => {
      if (!variablesMap) throw new Error("query shouldn't be running");

      const getAcksForActiveFtNodes = async () => {
        const activeNodeIdsToFtNameMap = await getActiveFTNodeIds(
          baseUrlSlash,
          formattedDate
        ); // a map whose keys are active node ids and values are names of fts each node belongs to

        const activeAndPublishedNodeIds = Object.keys(activeNodeIdsToFtNameMap);

        const acksForFtNodes =
          activeAndPublishedNodeIds.length > 0
            ? await getAcknowledgements(baseUrlSlash, {
                end: formattedDate,
                start: formattedDate,
                varIds: activeAndPublishedNodeIds,
              })
            : [];

        // no ack exists for these ids
        const noAckDocIds = activeAndPublishedNodeIds.filter(
          (id) => !acksForFtNodes.find((ack) => ack.variable === id)
        );
        return { acks: acksForFtNodes, noAckDocIds, activeNodeIdsToFtNameMap };
      };

      const getPrefixes = (
        ack: acknowledgementSchema | undefined,
        variable: variableSchema
      ) => {
        let prefixes = variable.trimmedName + " " + variable.description;

        if (ack) {
          prefixes += " " + ack.author.first + " " + ack.author.last;
          prefixes +=
            " " + moment(ack.created_at).format("dddd MMMM DD YYYY hh:mm:ss");
        }

        return prefixes;
      };

      type ForRegularTag = {
        type: "forTags";
        level: AnomalyLevelEnum;
        isFresh: boolean;
        stripes: boolean;
      };

      type ForFtNode = {
        type: "forFtNodes";
        level: undefined;
        isFresh: undefined;
        stripes: undefined;
      };

      type FormattedAck = (ForFtNode | ForRegularTag) & {
        _id: string;
        variable_name: string;
        variable_description: string;
        variable_id: string;

        prefixes: ReturnType<typeof getPrefixes>;
        searchString: ReturnType<typeof getPrefixes>;
        date: Date | undefined;
        author: string | undefined;
        acknowledged: boolean;
      };

      /**
       * the # of docs we get back is <= the # of active ft node ids,
       * but we want to show all active ft node ids, so we create
       * "dummy" objects below with the remainder `noAckDocIds`
       */
      const {
        acks: acksOrUnackDocsForFtNodes,
        noAckDocIds,
        activeNodeIdsToFtNameMap,
      } = await getAcksForActiveFtNodes();

      /**
       * We could get multiple ack docs for the same ft node,
       * so we group by variable id and take the latest ack
       */
      const onlyLatestAckDocs = R.pipe(
        acksOrUnackDocsForFtNodes,
        R.groupBy((ack) => ack.variable),
        R.values,
        R.map((acksForSameFtNode) => {
          const sortedDesc = acksForSameFtNode.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          return sortedDesc[0]; // get the latest ack
        })
      );

      const found = onlyLatestAckDocs.map((ack) => {
        const variable = variablesMap[ack.variable];

        if (!variable) throw new Error("variable not found");

        const prefix = getPrefixes(ack, variable);

        const ftName = activeNodeIdsToFtNameMap[ack.variable];

        if (!ftName) throw new Error("impossible");

        const out: FormattedAck = {
          level: undefined,
          _id: ack._id,
          stripes: undefined,
          acknowledged: !ack.unacknowledgment,
          variable_name: variable.trimmedName,
          variable_description: ftName,
          variable_id: variable._id, // the node id
          isFresh: undefined,
          author: ack.author.first + " " + ack.author.last,
          date: new Date(ack.created_at),
          prefixes: prefix,
          searchString: prefix.trim().toLowerCase(),
          type: "forFtNodes",
        };
        return out;
      });

      const notFound = noAckDocIds.map((id) => {
        const variable = variablesMap[id];

        if (!variable) throw new Error("variable not found");

        const ftName = activeNodeIdsToFtNameMap[variable._id];

        if (!ftName) throw new Error("impossible");

        const prefix = getPrefixes(undefined, variable);

        const out: FormattedAck = {
          level: undefined,
          _id: id,
          stripes: undefined,
          acknowledged: false,
          variable_name: variable.trimmedName,
          variable_description: ftName,
          variable_id: variable._id,
          isFresh: undefined,
          author: undefined,
          date: undefined,
          prefixes: prefix,
          searchString: prefix.trim().toLowerCase(),
          type: "forFtNodes",
        };
        return out;
      });

      const activeFtNodeFormattedAckDocs = [...found, ...notFound];

      const anomalyAndAcksForRegularTags = await getAnomaly(
        baseUrlSlash,
        { groupId: selectedGroupId },
        moment(selectedDate),
        {
          include: "acknowledgment",
          returnIsFresh: "true",
        }
      ).then((anoms) => {
        return anomalySchema
          .extend({
            isFresh: z.boolean(),
            included: z.object({
              acknowledgment: acknowledgementSchema.nullable(),
            }),
          })
          .array()
          .parse(anoms);
      });

      const regularTagsFormattedAckDocs = anomalyAndAcksForRegularTags
        .filter((anomDayRaw) => anomDayRaw.level > 0 && anomDayRaw.level < 4)
        .map((anomDayRaw) => {
          const variable = variablesMap[anomDayRaw.variableId];

          if (!variable) throw new Error("variable not found");

          const ackOrUnackObject =
            anomDayRaw.included.acknowledgment ?? undefined;

          const prefix = getPrefixes(ackOrUnackObject, variable);

          const out: FormattedAck = {
            _id: anomDayRaw._id,
            stripes: anomDayRaw.shutdownMilliseconds > 0,
            level: anomDayRaw.level,
            acknowledged: ackOrUnackObject
              ? !ackOrUnackObject.unacknowledgment
              : false,

            variable_name: variable.trimmedName,
            variable_description: variable.description,
            variable_id: variable._id,
            isFresh: anomDayRaw.isFresh,
            author:
              ackOrUnackObject &&
              ackOrUnackObject.author.first +
                " " +
                ackOrUnackObject.author.last,
            date: ackOrUnackObject && new Date(ackOrUnackObject.created_at),
            prefixes: prefix,
            searchString: prefix.trim().toLowerCase(),
            type: "forTags",
          };
          return out;
        });

      return [...activeFtNodeFormattedAckDocs, ...regularTagsFormattedAckDocs];
    },
  });

  return query;
}
