import {
  getFaultTreeNode,
  getFaultTreeNodeResolutionDate,
  getFaultTreeNodeStatus,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useDateState } from "../../../zustand/useDateState";
import {
  ExtendedFtNode,
  useGetUseFaultTreeDetailsStore,
} from "./ft-details-store";
import { useSelectedNodeQuery } from "./use-selected-node-query";

export function useGetTreeNodeData() {
  const $ds = useDateState();
  const baseUrl = useBaseUrlExperimental();
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();

  async function getTreeNodeData(treeNodeId: string): Promise<ExtendedFtNode> {
    const statusPromise = getFaultTreeNodeStatus(
      baseUrl,
      treeNodeId,
      $ds.axisRangeTo.dateString
    );

    const resolutionDatePromise = getFaultTreeNodeResolutionDate(
      baseUrl,
      treeNodeId
    );

    const ftNode =
      useFaultTreeDetailsStore
        .getState()
        .tree?.nodes.find((n) => n._id === treeNodeId) ??
      (await getFaultTreeNode(baseUrl, treeNodeId)); // save a request if we already have the node

    const resolutionDate = await resolutionDatePromise;
    const status = await statusPromise;

    const combined = Object.assign({}, ftNode, status, { resolutionDate });
    return combined;
  }

  return getTreeNodeData;
}

export function useGetOnTreeNodeClick() {
  const { setSelectedNodeQuery } = useSelectedNodeQuery();
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();

  const getTreeNodeData = useGetTreeNodeData();

  async function onTreeNodeClick(nodeId: string) {
    setSelectedNodeQuery(nodeId);
    const extendedNode = await getTreeNodeData(nodeId);
    useFaultTreeDetailsStore
      .getState()
      .setLastProcessed(extendedNode.resolutionDate ?? undefined);
    useFaultTreeDetailsStore.getState().setSelectedNode(extendedNode);
  }

  return onTreeNodeClick;
}
