import { cn } from "../../../lib/utils";
import { useDateState } from "../../../zustand/useDateState";
import Button from "../../common/Button/Button";
import { PropsWithCn } from "../../types/component.types";
import { RANGES } from "../../../constants/dateState";
import { differenceInDays, startOfDay } from "date-fns";
import moment from "moment";
import "./antd-styles.scss";
import { CustomRangePopover } from "./custom-range-button";

export const useTimeParams = () => {
  const ds = useDateState();
  const startAndEndTuple = ds.getTimeParams();
  return getTimeParamsAsDatesSync(startAndEndTuple[0], startAndEndTuple[1]);
};

function getTimeParamsAsDatesSync(start: string, end: string): [Date, Date] {
  const startSplit = start.split(":").map(Number);
  const endSplit = end.split(":").map(Number);

  if (!startSplit || !endSplit) throw new Error("unexpected format");

  const [s_h, s_m] = startSplit;
  const [e_h, e_m] = endSplit;

  if (
    s_h === undefined ||
    s_m === undefined ||
    e_h === undefined ||
    e_m === undefined
  )
    throw new Error("unexpected format");

  // the days dont' matter I just need it as a Date object with the correct time for the
  // time picker component Ankur wanted to use
  const resetSecondsAndMS = (h: number, m: number) =>
    moment().millisecond(0).second(0).hour(h).minute(m).toDate();

  const s_date = resetSecondsAndMS(s_h, s_m);
  const e_date = resetSecondsAndMS(e_h, e_m);

  return [s_date, e_date];
}

export function GlobalCustomRangeButton({ className }: PropsWithCn) {
  const ds = useDateState();
  const customNumDays = ds.customNumDays;
  const isInCustomMode = ds.axisRangeIndex === RANGES.length;
  const active = isInCustomMode;

  const axisFromDate = ds.axisRangeFrom.local;
  const axisToDate = ds.axisRangeTo.local;

  const [startTime, endTime] = useTimeParams();

  const startWithTime = new Date(axisFromDate);
  startWithTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);

  const endWithTime = new Date(axisToDate);
  endWithTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

  const customButtonLabel = () => {
    // Just show custom if it's not in custom mode
    if (!isInCustomMode) return "Custom";

    const startTimeIsDefault =
      startWithTime.getHours() === 0 && startWithTime.getMinutes() === 0;
    const startTimeIsCustom = !startTimeIsDefault;
    const endTimeIsDefault =
      endWithTime.getHours() === 23 && endWithTime.getMinutes() === 59;
    const endTimeIsCustom = !endTimeIsDefault;
    const timeIsCustom = startTimeIsCustom || endTimeIsCustom;

    // only show the range with decimals if it's not the default time
    if (timeIsCustom) {
      const range = moment(endWithTime).diff(
        moment(startWithTime),
        "days",
        true
      );
      return `Custom (${Math.round(range * 10) / 10}d)`;
    }

    // show the number of days (int) if the time is default
    return `Custom (${customNumDays}d)`;
  };

  return (
    <CustomRangePopover
      calendarPosition="bottom"
      onChange={({ end: endDate, start: startDate }) => {
        const diff =
          Math.abs(
            // I only want to consider the days, not the time, so reset to start of day
            differenceInDays(startOfDay(endDate), startOfDay(startDate))
          ) + 1;

        ds.multiSet({
          end: endDate,
          time: {
            start: moment(startDate).format("HH:mm"),
            end: moment(endDate).format("HH:mm"),
          },
          rangeIdx: RANGES.length,
          customNumDays: diff,
        });
      }}
      value={{
        start: startWithTime,
        end: endWithTime,
      }}
    >
      <Button
        className={cn(
          "normal-case font-normal",
          {
            "bg-base-100 text-neutral hover:bg-neutral hover:text-neutral-content":
              !active,
            "bg-neutral text-neutral-content": active,
          },
          className
        )}
      >
        {customButtonLabel()}
      </Button>
    </CustomRangePopover>
  );
}

export { getTimeParamsAsDatesSync };
