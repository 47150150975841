import { z } from "zod";
import { MongoIdUpdatedAtCreatedAt } from "./common";

export const teamSchema = MongoIdUpdatedAtCreatedAt.extend({
  name: z.string(),
  unit: z.string(),
  users: z.array(z.string()),
});

export type TeamType = z.infer<typeof teamSchema>;
