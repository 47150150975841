/**
 * I am tired of JS dates, and just want some simplicity
 */

import moment from "moment";
import { z } from "zod";

/**
 * This function takes in a UTC string (usually returned by the API)
 * and returns a Date object with that same exact date at 12AM local time.
 *
 * For example 2020-01-01T00:00:00.000Z would really be December 31st 2019 at some
 * time due to timezone differences, but this function would return January 1st 2020 at 12 AM
 * as a Date object
 *
 * NOTE: the parameter must be formatted like '2020-01-01T00:00:00.000Z'
 */
export function UTCStringToLocalDateObject(isoString: string) {
  const [year, month, day] = z
    .string()
    .parse(isoString.split("T")[0])
    .split("-");
  // technically day is gonna look something like 01T00:00:00.000Z, but parseInt still works correctly
  // that is, parseInt('01T00:00:00.000Z') === 1
  return new Date(
    z.coerce.number().parse(year),
    z.coerce.number().parse(month) - 1,
    z.coerce.number().parse(day)
  );
}

export function applyTimeOntoUtcDateObj(
  asUtc: moment.Moment,
  timeParamDateObjOnlyTimeMatters: Date
): number {
  asUtc.hours(timeParamDateObjOnlyTimeMatters.getHours());
  asUtc.minutes(timeParamDateObjOnlyTimeMatters.getMinutes());
  asUtc.seconds(0);
  asUtc.milliseconds(0);
  return asUtc.valueOf();
}
