import { useNotificationPostMutation } from "../tanstack/useNotificationsQuery";
import { NotificationScheduleType } from "../../../types/api/Notification";
import CustomNotificationForm from "./CustomNotificationForm";
import { ComponentProps } from "react";

export default function CustomNotificationCreateForm(
  passAlong: Pick<
    ComponentProps<typeof CustomNotificationForm>,
    "close" | "className"
  >
) {
  const mutation = useNotificationPostMutation();

  return (
    <CustomNotificationForm
      mutation={mutation}
      defaultData={{
        schedule: {
          type: NotificationScheduleType.ONCE,
        },
      }}
      {...passAlong}
    />
  );
}

export function CustomNotificationCreateForm2(
  passAlong: Pick<
    ComponentProps<typeof CustomNotificationForm>,
    "close" | "className"
  >
) {
  const mutation = useNotificationPostMutation();

  return (
    <CustomNotificationForm
      mutation={mutation}
      defaultData={{
        schedule: {
          type: NotificationScheduleType.ONCE,
        },
      }}
      {...passAlong}
    />
  );
}
