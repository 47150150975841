import { type List as ListType } from "../builder/types/BuilderTypes";
import {
  boldAndItalicizeHTMLify,
  generateStyleObjectFromMarginOptions,
} from "../utils";

/**
 * Default is numbered, pass in bullets=true for a bulleted list
 */
export default function List({
  content,
  bullets,
  marginTop,
  marginBottom,
}: ListType) {
  return (
    <ul
      className="w-full m-0 px-2 list-none"
      style={generateStyleObjectFromMarginOptions(marginTop, marginBottom)}
    >
      {content.map(({ value, customIndex, marginLeft }, idx) => {
        const formatted = boldAndItalicizeHTMLify(value);
        const innerHtml = `${
          bullets ? "•" : `${customIndex ? customIndex : idx + 1}.`
        } ${formatted}`;

        return (
          <li
            key={formatted}
            style={{
              marginLeft: (marginLeft ?? "0") + "rem",
              marginBottom: "10px",
            }}
          >
            {value === formatted ? (
              <span>{innerHtml}</span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: innerHtml }} />
            )}
          </li>
        );
      })}
    </ul>
  );
}
