import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shared-ui/frontend/dropdown-menu";
import { Sun, Moon, Laptop, LucideIcon } from "lucide-react";
import { Button } from "../../shared-ui/frontend/button";
import { cn } from "../../lib/utils";
import { useTheme } from "../../hooks/use-theme";

function ThemeToggle({ className }: { className?: string }) {
  const [, setTheme] = useTheme();

  if (false)
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className={cn(className, "aspect-square w-9 px-0")}
          >
            <Sun className="size-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="size-4 absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setTheme("light")}>
            <Sun className="size-4 mr-2" />
            <span>Light</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("dark")}>
            <Moon className="size-4 mr-2" />
            <span>Dark</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("system")}>
            <Laptop className="size-4 mr-2" />
            <span>System</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
}

function ThemeToggle2({ className }: { className?: string }) {
  const [selectedTheme, setTheme] = useTheme();

  const btns: {
    theme: typeof selectedTheme;
    icon: LucideIcon;
    onClick: () => void;
  }[] = [
    { theme: "light", icon: Sun, onClick: () => setTheme("light") },
    { theme: "dark", icon: Moon, onClick: () => setTheme("dark") },
    { theme: "system", icon: Laptop, onClick: () => setTheme("system") },
  ];

  if (false)
    return (
      <div
        className={cn(
          "border border-xslate-4 inline-flex rounded-full max-w-max bg-white dark:bg-xslate-2 p-1 gap-0.5",
          className
        )}
      >
        {btns.map(({ icon: Icon, onClick, theme }) => (
          <Button
            key={theme}
            onClick={onClick}
            variant={"ghost"}
            size={"icon"}
            className={cn(
              "rounded-full",
              theme === selectedTheme && "bg-xslate-3"
            )}
          >
            <Icon className="size-4" />
          </Button>
        ))}
      </div>
    );
}

export { ThemeToggle, ThemeToggle2 };
