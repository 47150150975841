import { Menu, Tooltip } from "@mantine/core";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Button from "../common/Button/Button";
import moment from "moment";
import classNames from "classnames";
import {
  acknowledgementSchema,
  acknowledgementType,
} from "../../lib/api-schema/acknowledgment";

export function AckTypeDropdown({
  acks,
  acknowledge: postAck,
  className,
}: {
  acks: acknowledgementSchema[];
  className: "dropdown-bottom" | "dropdown-top";
  acknowledge: (t: Extract<acknowledgementType, "normal" | "issue">) => void;
}) {
  // ensure sorted
  const sortedAcks = [...acks].sort((a, b) => {
    return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
  });
  const mostRecentAck = sortedAcks[0];
  return (
    <div className={classNames("dropdown dropdown-hover", className)}>
      <Menu
        shadow="md"
        position={"top-end"}
        width={200}
        classNames={{
          dropdown: "border-2 border-bordgrey2 shadow-xl rounded-lg",
        }}
      >
        <Menu.Target>
          <Tooltip
            label={
              mostRecentAck && mostRecentAck.unacknowledgment === false
                ? "Acknowledged"
                : "Acknowledge"
            }
            position={className === "dropdown-bottom" ? "right" : "bottom"}
            withArrow
          >
            <Button
              icon={
                mostRecentAck && mostRecentAck.unacknowledgment === false
                  ? mostRecentAck.type === "issue"
                    ? "exclamation-triangle"
                    : mostRecentAck.type === "monitoring"
                    ? "search-plus"
                    : "check"
                  : "check"
              }
              iconClasses={`w-4 h-4 ${
                mostRecentAck && mostRecentAck.unacknowledgment === false
                  ? mostRecentAck.type === "issue"
                    ? "text-red-500"
                    : mostRecentAck.type === "monitoring"
                    ? "text-yellow-500"
                    : "text-primary"
                  : ""
              }`}
              tabIndex={0}
              className={`btn-ghost ${
                mostRecentAck && mostRecentAck.unacknowledgment === false
                  ? mostRecentAck.type === "issue"
                    ? "border-red-500"
                    : "border-primary"
                  : ""
              }`}
            >
              {mostRecentAck && mostRecentAck.unacknowledgment === false ? (
                <span className="text-xs normal-case">
                  {mostRecentAck.author.first[0]}. {mostRecentAck.author.last}
                </span>
              ) : (
                ""
              )}
            </Button>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            color="red"
            className={
              mostRecentAck &&
              mostRecentAck.type === "issue" &&
              mostRecentAck.unacknowledgment === false
                ? "bg-red-500 text-white hover:text-red-500"
                : ""
            }
            onClick={() => postAck("issue")}
            icon={<i className={"fa fa-exclamation-triangle"} />}
          >
            Needs Attention
          </Menu.Item>
          <Menu.Item
            color="rgb(55, 48, 163)"
            className={
              mostRecentAck &&
              mostRecentAck.type === "normal" &&
              mostRecentAck.unacknowledgment === false
                ? "bg-primary text-white hover:text-primary"
                : ""
            }
            onClick={() => postAck("normal")}
            icon={<i className={"fa fa-check"} />}
          >
            Acknowledged
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {sortedAcks.length > 0 && (
        <ul
          tabIndex={0}
          className="dropdown-content rounded-md p-0 text-[0.8rem] shadow-lg bg-base-100 w-72 border border-bordgrey2 max-h-[500px] overflow-y-scroll"
        >
          <span className="block pl-2 text-[0.9rem] py-1 text-gray-600">
            Acknowledgments:
          </span>
          {sortedAcks.map((ack) => (
            <li
              key={ack._id}
              className="border-bordgrey2 border-b pl-4 py-1 first-of-type:border-t"
            >
              <div className="flex items-center">
                <div className="flex-1">
                  <div className="text-gray-600">
                    {!ack.unacknowledgment ? (
                      !ack.type || ack.type === "normal" ? (
                        <FaCheck className="inline mr-1" />
                      ) : (
                        <FaExclamationTriangle className="inline mr-1" />
                      )
                    ) : null}
                    {ack.unacknowledgment
                      ? `Unacked by ${ack.author.first} ${ack.author.last}`
                      : `Acked by ${ack.author.first} ${ack.author.last}`}
                  </div>
                  <div className="text-gray-400">
                    {moment(ack.created_at).format("LLL")}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
