import * as R from "remeda";
import { OperatingLimit } from "../../../lib/api-schema/operating-limit";
import {
  createTooltipLine,
  drawCapabilityLine,
  drawLimitLines,
  drawXScale,
} from "../utils/drawUtils";
import {
  ChartDimensions,
  DashboardOptions,
  DrawDataAtLeast1,
  SingleDrawData,
  SvgSelector,
} from "../types";
import { drawHistogram } from "./drawHistogram";
import { drawDensityChart } from "./drawDensityChart";
import { BarData, DensityData } from "../../../types/api/RidgelineResponse";
import {
  LSLLine,
  TargetLine,
  USLLine,
  createLinearScale,
  getConsolidatedXScale,
  getDomainsFromData,
  sortData,
} from "../utils/chartUtils";
import { drawHoverLineAndApplyHoverCallbacks } from "../utils/hoverUtils";

export function drawVariabilityChart({
  svgSelector,
  dimensions,
  data,
  limits,
  options,
  hoverFns,
  axesFontScale,
}: {
  svgSelector: SvgSelector;
  dimensions: ChartDimensions;
  data: DrawDataAtLeast1;
  limits: Pick<OperatingLimit, "value">[] | undefined;
  options: DashboardOptions;
  hoverFns: {
    onHover: (svgX: number, windowX: number) => void;
    reset: () => void;
  };
  axesFontScale?: number;
}) {
  const highlightedVariable = data.find((v) =>
    v.selected ? true : v.hovered ? true : false
  );

  const sortedData: DrawDataAtLeast1 = sortData(data);

  // add mouse over and mouse leave listeners and functions here
  svgSelector.selectAll("*").remove(); // clear previous when redrawing

  svgSelector
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("preserveAspectRatio", "none");

  drawBuckets(
    svgSelector,
    dimensions,
    sortedData,
    options,
    limits,
    highlightedVariable,
    axesFontScale
  );

  createTooltipLine(svgSelector, dimensions);
  drawHoverLineAndApplyHoverCallbacks(svgSelector, dimensions, hoverFns);
}

// Draw all data groups
const drawBuckets = (
  svgSelector: SvgSelector,
  dimensions: ChartDimensions,
  data: DrawDataAtLeast1,
  options: { showBars: boolean; showLines: boolean; relative: boolean },
  limits?: Pick<OperatingLimit, "value">[],
  highlightedVariable?: SingleDrawData,
  axesFontScale?: number
) => {
  // get consolidated domain for all data
  const domains = getDomainsFromData(data);
  const allXDomains = R.filter(
    domains.map((d) => d?.xDomain),
    R.isTruthy
  );

  // make consolidated x scale for all x domains
  const consolidatedXScale = getConsolidatedXScale(
    allXDomains,
    dimensions,
    limits
  );

  // make y scale as percentages to map histogram with density chart
  const yScale = createLinearScale(
    [0, 100],
    [dimensions.height - dimensions.margin, dimensions.margin]
  );

  // if not relative, draw consolidated x scale
  if (!options.relative) {
    drawXScale(svgSelector, {
      scale: consolidatedXScale,
      dims: dimensions,
      highlight: true,
      axesFontScale,
    });
  }

  // for each variable, draw histogram and density chart
  data.forEach((variable, index) => {
    if (!variable.ref) throw new Error("Need ref for variable for color");

    // if domain for variable exists, draw charts
    const domain = domains[index];
    if (!domain) return;

    const highlight: boolean = highlightedVariable
      ? variable.ref === highlightedVariable.ref
      : true;

    // add domain endpoints to density data chart to have endpoints on ends of scales
    variable.densityData = [
      [domain.xDomain[0], 0],
      ...variable.densityData,
      [domain.xDomain[1], 0],
    ];

    // if in absolute view, draw charts with consolidated x scale
    if (!options.relative) {
      if (limits)
        drawLimitLines(svgSelector, limits, consolidatedXScale, dimensions);
      if (options.showLines)
        drawDensityChart(
          svgSelector,
          normalizeVariableValuesForDensity(
            variable.densityData,
            domain.yDensity[1]
          ),
          variable.ref,
          variable.color,
          consolidatedXScale,
          yScale,
          highlight
        );
      if (variable.usl !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.usl,
          USLLine,
          variable.color,
          consolidatedXScale
        );
      if (variable.lsl !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.lsl,
          LSLLine,
          variable.color,
          consolidatedXScale
        );
      if (variable.target !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.target,
          TargetLine,
          variable.color,
          consolidatedXScale
        );
      // draw histogram regardless of options to calculate which group is highest in hover callback
      drawHistogram(
        svgSelector,
        normalizeVariableValuesForHistogram(variable.values, domain.yDomain[1]),
        variable.ref,
        variable.color,
        consolidatedXScale,
        yScale,
        highlight,
        dimensions,
        options
      );
    }
    // else draw charts in relative view
    else {
      // in relative view, make scale for charts
      const myXScale = createLinearScale(domain.xDomain, [0, dimensions.width]);

      if (highlight && highlightedVariable !== undefined) {
        const drawnXScale = drawXScale(svgSelector, {
          scale: myXScale,
          dims: dimensions,
          highlight,
          identifier: variable.id,
          axesFontScale,
        });
        drawnXScale.style("fill", variable.color);

        if (limits) drawLimitLines(svgSelector, limits, myXScale, dimensions);
        if (variable.usl !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.usl,
            USLLine,
            variable.color,
            myXScale
          );
        if (variable.lsl !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.lsl,
            LSLLine,
            variable.color,
            myXScale
          );
        if (variable.target !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.target,
            TargetLine,
            variable.color,
            myXScale
          );
      }

      // draw density chart
      if (options.showLines)
        drawDensityChart(
          svgSelector,
          normalizeVariableValuesForDensity(
            variable.densityData,
            domain.yDensity[1]
          ),
          variable.ref,
          variable.color,
          myXScale,
          yScale,
          highlight
        );
      // draw histogram
      drawHistogram(
        svgSelector,
        normalizeVariableValuesForHistogram(variable.values, domain.yDomain[1]),
        variable.ref,
        variable.color,
        myXScale,
        yScale,
        highlight,
        dimensions,
        options
      );
    }
  });
};

function normalizeVariableValuesForHistogram(
  values: BarData[],
  normalizationFactor: number
): BarData[] {
  const normalizedValues = values.map((v) => ({
    ...v,
    value: (v.value / normalizationFactor) * 100,
  }));
  return normalizedValues;
}

function normalizeVariableValuesForDensity(
  values: DensityData[],
  normalizationFactor: number
): DensityData[] {
  const normalizedValues = values.map(
    (v) => [v[0], (v[1] / normalizationFactor) * 100] as DensityData
  );
  return normalizedValues;
}
