import React, { useRef, useState } from "react";
import ExpressionBuildingInput from "../../shared-ui/expression-building-input/ExpressionBuildingInput";
import Button from "../common/Button/Button";
import { parseExprRef } from "../../shared-ui/expression-building-input/utils";
import validateExpression from "../../shared-ui/expression-building-input/validate";
import { useVariablesArrayQuery } from "../../hooks/tanstack-query";
import { FUNCTIONS } from "../../shared-ui/expression-building-input/constants";
import TimeSeriesDrawer from "../time-series/Drawer";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import { cn } from "../../lib/utils";

export function InstantCalculator({
  onInput,
  booleanExpression,
  defaultValue,
  defaultInputRef,
  className,
}: {
  onInput?: (expr: string) => void | undefined;
  booleanExpression?: boolean;
  defaultValue?: string;
  defaultInputRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}) {
  const [expression, setExpression] = useState<string>(defaultValue || "");
  const [useDumbInput, setUseDumbInput] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isValidExpression, setIsValidExpression] = useState(true);
  const [validationError, setValidationError] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const dumbRef = useRef<HTMLInputElement>(null);
  const inputRef = defaultInputRef || ref;
  const allVariables = useVariablesArrayQuery();
  const options =
    allVariables.data
      ?.filter((v) => v.type === "Tag")
      .map((v) => ({
        label: v.trimmedName,
        value: v.name,
        description: v.description,
      })) || [];
  const symbols = allVariables.data?.map((o) => o.name) || [];
  const currentUnit = useCurrentUnitObject();
  const isRT = currentUnit && currentUnit.addons?.["realTime"];

  return (
    <>
      <TimeSeriesDrawer
        open={drawerOpen}
        onOpenChange={setDrawerOpen}
        variables={[]}
        coloredLines={[]}
        expression={expression}
        expressionChange={(expr) => {
          // expression building input will only overwrite contents if there's nothing there
          inputRef.current!.innerHTML = "";
          setExpression(expr);
          onInput && onInput(expr);
        }}
        width={1500}
        height={500}
      />
      <div className="flex flex-row">
        {useDumbInput ? (
          <input
            ref={dumbRef}
            type="text"
            defaultValue={expression}
            onInput={(e) => {
              const expr = e.currentTarget.value;
              if (onInput) {
                onInput(expr);
              }
              if (expr === "") {
                setExpression("");
              }
            }}
            className={cn(
              "rounded-lg border-xslate-7 h-[50px] border w-full px-4",
              isRT ? "rounded-r-none border-r-0" : "",
              isValidExpression ? "" : "border-red-500 border-r",
              className
            )}
            placeholder={
              booleanExpression
                ? "Type a boolean (true/false) expression with Tag IDs in double quotes"
                : undefined
            }
          />
        ) : (
          <ExpressionBuildingInput
            inputRef={inputRef}
            options={options}
            defaultValue={expression}
            onInput={(e) => {
              const html = e.currentTarget.innerHTML;
              if (onInput) {
                const expr = parseExprRef(html);
                onInput(expr);
              }
              if (html === "") {
                setExpression("");
              }
            }}
            className={cn(
              "rounded-lg border-xslate-7",
              isRT ? "rounded-r-none border-r-0" : "",
              isValidExpression ? "" : "border-red-500 border-r",
              className
            )}
            placeholder={
              booleanExpression
                ? "Type a boolean (true/false) expression with Tag IDs in double quotes"
                : undefined
            }
          />
        )}
        {isRT && (
          <Button
            className="rounded-lg rounded-l-none btn-outline normal-case h-auto"
            onClick={() => {
              const expr = useDumbInput
                ? dumbRef.current?.value || ""
                : parseExprRef(inputRef.current?.innerHTML || "");
              try {
                if (expr === "") {
                  setIsValidExpression(true);
                  setDrawerOpen(true);
                } else if (
                  validateExpression(
                    expr,
                    symbols,
                    FUNCTIONS,
                    booleanExpression || false
                  )
                ) {
                  setIsValidExpression(true);
                  setExpression(expr);
                  setDrawerOpen(true);
                }
              } catch (e) {
                if (e instanceof Error) {
                  setIsValidExpression(false);
                  setDrawerOpen(false);
                  setTimeout(() => setIsValidExpression(true), 4000);
                  setValidationError(e.message);
                }
              }
            }}
            icon="calculator"
          ></Button>
        )}
      </div>
      {!isValidExpression && (
        <div className="bg-red-500 text-white p-2 -mt-2 w-fit">
          {validationError}
        </div>
      )}
      <div className="w-full flex flex-row-reverse">
        <Button
          size="xs"
          type="button"
          icon="exchange"
          onClick={() => {
            setExpression(
              useDumbInput
                ? dumbRef.current?.value || ""
                : parseExprRef(inputRef.current?.innerHTML || "")
            );
            setUseDumbInput(!useDumbInput);
          }}
          className="w-fit right-0 normal-case btn-link"
        >
          {useDumbInput
            ? "Switch to Smart Expression Builder"
            : "Switch to Classic Expression Builder"}
        </Button>
      </div>
    </>
  );
}
