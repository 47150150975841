import { Alert } from "@mantine/core";
import React from "react";
import Input from "../../../common/Input/Input";
import { type Video as VideoType } from "../types/BuilderTypes";
import {
  CODENAME_INPUT_PLACEHOLDER,
  FILENAME_INPUT_PLACEHOLDER,
} from "./constants";
import type EditorProps from "./EditorProps";
import MarginInputs from "./MarginInputs";
import { validateCodename, validateFilename } from "./validators";

function Video({ content, editContent }: EditorProps<VideoType>) {
  const err =
    validateFilename(content.filename) ||
    validateCodename(content.codename) ||
    validateCodename(content.captionedcodename) ||
    validateFilename(content.captionedfilename);

  const errComp = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  return (
    <div className="flex flex-col gap-2">
      {errComp}
      <MarginInputs editContent={editContent} content={content} />
      <div className="form-control col-span-1">
        <label className="input-group input-group-sm flex">
          <span>Filename</span>
          <Input
            placeholder={FILENAME_INPUT_PLACEHOLDER}
            className="grow"
            bordered
            size="sm"
            required
            value={content.filename}
            onChange={(e) => {
              editContent((c) => {
                c.filename = e.target.value;
              });
            }}
          />
        </label>
      </div>
      <div className="form-control col-span-1">
        <label className="input-group input-group-sm flex">
          <span>Codename</span>
          <Input
            className="grow"
            bordered
            size="sm"
            placeholder={CODENAME_INPUT_PLACEHOLDER}
            required
            value={content.codename}
            onChange={(e) => {
              editContent((c) => {
                c.codename = e.target.value;
              });
            }}
          />
        </label>
      </div>
      <div className="form-control col-span-1">
        <label className="input-group input-group-sm flex">
          <span>Captioned Filename</span>
          <Input
            placeholder={FILENAME_INPUT_PLACEHOLDER}
            className="grow"
            bordered
            size="sm"
            required
            value={content.captionedfilename}
            onChange={(e) => {
              editContent((c) => {
                c.captionedfilename = e.target.value;
              });
            }}
          />
        </label>
      </div>
      <div className="form-control col-span-1">
        <label className="input-group input-group-sm flex">
          <span>Captioned Codename</span>
          <Input
            className="grow"
            bordered
            size="sm"
            required
            placeholder={CODENAME_INPUT_PLACEHOLDER}
            value={content.captionedcodename}
            onChange={(e) => {
              editContent((c) => {
                c.captionedcodename = e.target.value;
              });
            }}
          />
        </label>
      </div>
    </div>
  );
}

export default Video;
