export enum VariableTypeNumber {
  Tag,
  ShutdownRule,
  FaultTreeNode,
  OperatingMode,
  Notification,
}

export enum VariableTypeString {
  Tag = "Tag",
  ShutdownRule = "ShutdownRule",
  FaultTreeNode = "FaultTreeNode",
  OperatingMode = "OperatingMode",
  Notification = "Notification",
}

export enum ShutdownRuleCategory {
  Shutdown,
  Slowdown,
  EquipmentShutdown,
  Calibration,
  Transition,
}

export interface VariableIdTypePair {
  _id: string;
  type: VariableTypeString;
}

export enum FaultTreeNodeExpressionConnectorType {
  Or = "|",
  And = "&",
  None = "",
}

export default interface Variable {
  _id: string;
  unit: string;
  short_id: number;
  created_at?: Date;
  updated_at?: Date;
  sd_rule_modified_at?: Date;
  om_rule_modified_at?: Date;
  name: string;
  nameWithDescription: string;
  trimmedName?: string;
  description: string;
  type: VariableTypeString;
  groups: string[];
  folders: string[];
  families: string[];
  high_side: boolean;
  low_side: boolean;
  historian?: string /* String key representing alternate historian to use. See the unit in `config.yaml`. */;
  units_of_measurement: string | null;
  sensor_flatline: boolean;
  expression: string | null;
  watchlist: boolean;
  hidden: boolean;
  sd_rule_ids: string[];
  sd_groups: string[];
  sd_vars: string[];
  sd_vars_set?: string[];
  sd_rule_category: ShutdownRuleCategory | null;
  sd_buffer: number;
  om_rule_ids: string[];
  om_groups: string[];
  om_vars: string[];
  om_vars_set?: string[];
  st_enabled?: boolean;
  ft_node_expression_variable_ids?: VariableIdTypePair[];
  ft_node_recommendation?: string;
  ft_node_parent_id?: string | null;
  ft_node_fault_tree_id?: string | null;
  ft_node_tag_expression?: string | null;
  ft_node_node_expression?: string | null;
  ft_node_expression_connector?: FaultTreeNodeExpressionConnectorType | null;
  ft_node_should_be_processed?: boolean | null;
  proc?: { processSlopes: boolean };
  hide_positive_slopes?: boolean;
  hide_negative_slopes?: boolean;
  deleted: boolean;
  deletedIndex?: number | null;
  operating_limits?: {}[];
  hasNotification?: boolean;
} // eslint-disable-line
