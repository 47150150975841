import { z } from "zod";
import { MongoId } from "../common";

/**
 * See https://zod.dev/?id=recursive-types for more info on recursive types.
 */
const baseFaultTreeChartSchema = MongoId.extend({
  name: z.string(),
  status: z.number().int(),
  value: z.string(),
});

export type faultTreeChartSchema = z.infer<typeof baseFaultTreeChartSchema> & {
  children: faultTreeChartSchema[];
};

export const faultTreeChartSchema: z.ZodType<faultTreeChartSchema> =
  baseFaultTreeChartSchema.extend({
    children: z.lazy(() => faultTreeChartSchema.array()),
  });
