import { createOperatingMode } from "../../../frameworks/fetcher/api-routes-experimental";
import * as ManagerComponents from "../../manager-components";
import * as React from "react";
import { PropsWithCn } from "../../types/component.types";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import validateExpression from "../../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../../shared-ui/expression-building-input/constants";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { Label } from "../../../shared-ui/frontend/label";

type Payload = Parameters<typeof createOperatingMode>[1];

export function OperatingModeForm({
  onSubmit,
  disabled,
  close,
  defaultValues,
  className,
}: PropsWithCn<{
  onSubmit: (p: Payload) => void;
  disabled: boolean;
  close: () => void;
  defaultValues?: Payload;
}>) {
  const [formState, setFormState] = React.useState<Payload>({
    bindingGroupIds: defaultValues?.bindingGroupIds ?? [],
    description: defaultValues?.description ?? "",
    bindingVariableIds: defaultValues?.bindingVariableIds ?? [],
    expression: defaultValues?.expression ?? "",
    name: defaultValues?.name ?? "",
    om_calc: defaultValues?.om_calc ?? true,
    clusters: defaultValues?.clusters ?? [],
  });

  const allVariables = useVariablesArrayQuery();
  const symbols = allVariables.data?.map((o) => o.name) || [];

  const onFormSubmit: React.ComponentProps<"form">["onSubmit"] = (e) => {
    e.preventDefault();
    try {
      validateExpression(formState.expression, symbols, FUNCTIONS, true);
      onSubmit(formState);
    } catch (e) {
      addUnknownErrorToast(e);
    }
  };

  return (
    <ManagerComponents.FormContainer
      onSubmit={onFormSubmit}
      className={className}
    >
      <ManagerComponents.InputWithLabel
        id="id"
        label="ID"
        className="w-full"
        required
        minLength={1}
        value={formState.name}
        disabled={disabled}
        onChange={(e) =>
          setFormState((old) => ({ ...old, name: e.target.value }))
        }
      />
      <ManagerComponents.InputWithLabel
        id="description"
        label="Description"
        className="w-full"
        required
        minLength={1}
        value={formState.description}
        disabled={disabled}
        onChange={(e) =>
          setFormState((old) => ({ ...old, description: e.target.value }))
        }
      />
      <Label>Expression</Label>
      <InstantCalculator
        onInput={(expr) => {
          setFormState((old) => ({ ...old, expression: expr }));
        }}
        booleanExpression={true}
        defaultValue={formState.expression}
      />

      <ManagerComponents.GroupMultiSelect
        disabled={disabled}
        value={formState.bindingGroupIds}
        onChange={(groupIds) =>
          setFormState((old) => ({ ...old, bindingGroupIds: groupIds }))
        }
      />
      <ManagerComponents.TagsMultiSelect
        disabled={disabled}
        value={formState.bindingVariableIds}
        noPortal
        onChange={(vids) =>
          setFormState((old) => ({ ...old, bindingVariableIds: vids }))
        }
      />

      <ManagerComponents.ClusterMultiSelect
        disabled={disabled}
        value={formState.clusters}
        onChange={(clusterIds) =>
          setFormState((old) => ({ ...old, clusters: clusterIds }))
        }
      />

      <ManagerComponents.TogglerWithLabel
        disabled={disabled}
        className="m-3"
        label="Anomaly Calculation"
        checked={formState.om_calc}
        onChange={(e) =>
          setFormState((old) => ({ ...old, om_calc: e.target.checked }))
        }
      />
      <ManagerComponents.CancelSaveFooter
        className="mt-3"
        close={{
          disabled,
          onClick: close,
        }}
        submit={{
          disabled,
        }}
      />
    </ManagerComponents.FormContainer>
  );
}
