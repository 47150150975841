import { useSearchParams } from "react-router-dom";
import _ from "lodash";

function useSearchParamsEnhanced() {
  const [searchParams, setSp] = useSearchParams();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setSearchParams(obj: any, overwrite = false, replace = false) {
    if (!obj) {
      throw new Error("Object argument expected");
    }

    if (overwrite) {
      setSp(obj, { replace });
      return;
    }

    setSp(
      (existing) => {
        _.forEach(obj, (v, k) => existing.set(k, v));
        return existing;
      },
      { replace }
    );
  }

  const out: [URLSearchParams, typeof setSearchParams] = [
    searchParams,
    setSearchParams,
  ];

  return out;
}

export default useSearchParamsEnhanced;
