import React, { useState } from "react";
import moment from "moment-timezone";
import "./FaultTreeOverview.scss";
import FaultTreeLegend from "./FaultTreeLegend";
import Footer from "../../nav/Footer";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import MainLayout from "../../layouts/MainLayout";
import { LoadingOverlay } from "@mantine/core";
import { FaultTreeOverviewCard2 } from "./FaultTreeOverviewCard2";
import { usePublishedFaultTreesQuery } from "../../../hooks/tanstack-query";

export function FaultTreeOverview2() {
  useDocumentTitle("Fault Trees > DRA");
  const [lastProcessed, setLastProcessed] = useState<string>();
  const faultTreesQuery = usePublishedFaultTreesQuery();
  const { isLoading, isError } = faultTreesQuery;

  const date = lastProcessed
    ? moment(lastProcessed).format("MMMM Do YYYY, h:mm a")
    : null;

  return (
    <MainLayout showDateNav={true}>
      <LoadingOverlay overlayBlur={2} visible={!isError && isLoading} />

      <div className="bg-bggrey flex flex-col MIN_H_SCREEN2">
        {/* <MainLoader match={!init} /> */}
        <div>
          <div className="flex justify-between py-2 mb-1 px-2">
            <div className="page-header prose pl-1">
              <h2>Fault Trees Overview</h2>
            </div>
            <div className="rounded-md p-1 bg-base-100 px-2 pt-[0.35rem]">
              <FaultTreeLegend />
            </div>
          </div>
          {!isError &&
            !isLoading &&
            (faultTreesQuery.data.length > 0 ? (
              <ul className="list-none px-2 mb-12">
                {faultTreesQuery.data.map((t) => (
                  <li key={t._id} className="mb-2 group">
                    <FaultTreeOverviewCard2
                      tree={t}
                      setLastProcessed={setLastProcessed}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <h3 className="text-center text-[1.5rem] pt-12 h-[60vh]">
                No published fault trees to display.
              </h3>
            ))}

          {!isError && !isLoading && date && (
            <div className="FaultTreeOverview__procDate">
              {faultTreesQuery.data.length} Fault Trees &nbsp;&middot;&nbsp;
              Last Updated: {date}
            </div>
          )}
        </div>
        <Footer className="!mt-auto" />
      </div>
    </MainLayout>
  );
}
