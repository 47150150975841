import { z } from "zod";
import { create } from "zustand";
import { GET, POST } from "../../frameworks/fetcher/fetcher";
import { produce } from "immer";
import { UserRole } from "./types/UserRole";
import { hoursToMilliseconds } from "date-fns";
import { initLabelStore } from "../../types/use-label-store";

const JWTClaim = z.object({
  first: z.string(),
  last: z.string(),
  id: z.string(),
  email: z.string(),
  role: UserRole,
  units: z.array(z.string()),
});

type JWTClaim = z.infer<typeof JWTClaim>;

type User = Omit<JWTClaim, "id" | "exp"> & {
  _id: string;
  hasEditPermission: boolean;
  numericRole: number;
};

type AuthStore = {
  initialized: boolean;
  authed: boolean;
  user?: User;
  // refresh: () => Promise<void>;
  logoutTimer: number | undefined;
  unAuthenticate: () => Promise<void>;
  removeToken: () => Promise<void>; // server side logout
  authenticate: (email: string, password: string) => Promise<void>;
};

const UNAUTHED_DEFAULT_STATE: Pick<
  AuthStore,
  "initialized" | "authed" | "user" | "logoutTimer"
> = {
  initialized: true,
  authed: false,
  user: undefined,
  logoutTimer: undefined,
};

const ROLES: {
  [TRole in UserRole]: {
    type: TRole;
    value: number;
    hasEditPermission: boolean;
  };
} = {
  Admin: { type: "Admin", value: 2, hasEditPermission: true },
  Root: { type: "Root", value: 3, hasEditPermission: true },
  User: { type: "User", value: 1, hasEditPermission: false },
};

function getUserFromJWT(payload: JWTClaim) {
  const { units, role, first, last, email } = payload;
  const roleObject = ROLES[role];
  const hasEditPermission = roleObject.hasEditPermission;

  return {
    first,
    last,
    email,
    units,
    role,
    _id: payload.id,
    hasEditPermission,
    numericRole: roleObject ? roleObject.value : 0,
  };
}

const useAuthStore = create<AuthStore>()((set, get) => {
  async function handleLoggedIn(claim: JWTClaim, expiresAt: Date) {
    initLabelStore(); // this functino is called whenever a user is logged in so I thought it'd be a good time to do things that need to happen when they log in

    const timerToLogout = expiresAt.getTime() - Date.now();

    if (timerToLogout < 0) {
      set(UNAUTHED_DEFAULT_STATE);
      return;
    }

    try {
      const user = getUserFromJWT(claim);

      set(
        produce((state) => {
          state.exp = Date.now() + hoursToMilliseconds(72);
          state.initialized = true;
          state.authed = true;
          state.user = user;
          state.logoutTimer = setTimeout(() => {
            get().unAuthenticate();
          }, timerToLogout) as unknown as number;
        })
      );
    } catch {
      set(UNAUTHED_DEFAULT_STATE);
      throw new Error("Could not log in");
    }
  }

  const init = async () => {
    const checkLoggedInResult = await GET("/authenticate/check");

    const { session } = z
      .object({
        session: JWTClaim.extend({
          exp: z.number().int(),
        }).nullable(),
      })
      .parse(checkLoggedInResult.body);
    if (session) {
      handleLoggedIn(session, new Date(session.exp * 1000));
      return;
    }
    return set(UNAUTHED_DEFAULT_STATE);
  };

  void init();

  const removeToken = async () => {
    await GET(`/authenticate/logout`);
  };

  return {
    logoutTimer: undefined,
    initialized: false,
    authed: false,
    user: undefined,
    removeToken,
    unAuthenticate: async () => {
      await removeToken();
      const timer = get().logoutTimer;
      timer !== undefined && clearTimeout(timer);
      set(UNAUTHED_DEFAULT_STATE);
    },
    authenticate: async (email, password) => {
      const data = await POST(`/authenticate`, { body: { email, password } });

      const { expiresAt, user } = z
        .object({
          user: JWTClaim,
          expiresAt: z.string().datetime(),
        })
        .parse(data.body);

      handleLoggedIn(user, new Date(expiresAt));
    },
  };
});

export function useUserRequired() {
  const user = useAuthStore((s) => s.user);

  if (!user) throw new Error("only call where logged in");
  return user;
}

export default useAuthStore;
