import * as d3 from "d3-legacy";
import _ from "lodash";

/* Convert a normal d3 chart into a <sparkle>responsive</sparkle> chart. Some of
 * the tranforms here may seem weird or hacky, but d3 considers the outputed
 * classes/dom of its axes to be part of its API, so it's less hacky than it
 * initially seems. However, I assume we'll need to make some adjustments here
 * over time as we figure out which CSS properties are and aren't safe to mess
 * around with. */
export default function (x, y, xAxisEl, yAxisEl, plotEl, helpers) {
  helpers = helpers || {};

  return function (chart) {
    /* For now, just convert X and Y axis. We'll see about the rest later. */

    const xAxis = (() => {
      if (_.isString(xAxisEl)) {
        return chart.select(xAxisEl);
      }
      if (xAxisEl instanceof d3.selection) {
        return xAxisEl;
      }
      return d3.select(xAxisEl);
    })();

    const yAxis = (() => {
      if (_.isString(yAxisEl)) {
        return chart.select(yAxisEl);
      }
      if (yAxisEl instanceof d3.selection) {
        return yAxisEl;
      }
      return d3.select(yAxisEl);
    })();

    const plot = (() => {
      if (_.isString(plotEl)) {
        return chart.select(plotEl);
      }
      if (plotEl instanceof d3.selection) {
        return plotEl;
      }
      return d3.select(plotEl);
    })();

    /* SVG overflow isn't supported anywhere that I can see, because all life is
     * misery. And SVG has no concept of margins, even though we really need
     * margins. How do we deal with this #$@&%x bullcrap, you ask? We have to
     * get a little bit creative with percentages -- giving ourselves enough
     * extra room that the responsive axis will practically always have padding,
     * but a fixed amount that's easy to remove in CSS. Importantly, /we/ need
     * to handle removing it in CSS for the caller, because otherwise this tool
     * isn't particularly valuable or easy to use.
     *
     * The above task is made _significantly_ easier by the fact that our x/y
     * axis and chart are all separate SVGs that can be moved around freely.
     *
     * So we're not handling /everything/ here, but we are handling most things.
     * You just need to position the X/Y axis at the top/left or bottom/right of
     * the chart and mess with padding a bit.
     */
    xAxis.attr("width", "120%").style("width", "120%").style("left", "-10%");

    yAxis.attr("height", "120%").style("height", "120%").style("top", "-10%");

    /* Remove domains and re-draw.
     * TODO: detect other properties and port them as well? */
    let xStroke = xAxis.select(".domain").attr("stroke");
    xAxis.select(".domain").remove();
    xAxis
      .append("line")
      .attr("class", "domain")
      .attr("stroke", xStroke || "black")
      .attr("x1", `${(10 / 120) * 100}%`)
      .attr("x2", `${(110 / 120) * 100}%`);

    let yStroke = yAxis.select(".domain").attr("stroke");
    yAxis.select(".domain").remove();
    yAxis
      .append("line")
      .attr("class", "domain")
      .attr("stroke", yStroke || "black")
      .attr("y1", `${(10 / 120) * 100}%`)
      .attr("y2", `${(110 / 120) * 100}%`);

    /* If we _can_ use an existing element and skip the domain crud, then do so. */
    // const xAxisElement = xAxis.node();
    // if (xAxisElement && xAxisElement. {
    // }

    xAxis.selectAll(".tick").each((d, i, nodes) => {
      let node = nodes[i];
      let selection = d3.select(node);

      /* Offset, accounting for the bullcrap padding above. */
      let range = x.range();
      let length = Math.abs(range[1] - range[0]);
      let domain = x.domain();

      let paddedLength = length + 0.2 * length;
      let padding = paddedLength - length;
      let fixedOffset = (0.5 * padding) / paddedLength;

      let offset = x(d) / paddedLength + fixedOffset;

      if (helpers.xWidth) {
        const percentOffset = range[0] / length;

        /* move to the right, then scale based on the actual visible point. How? */
        offset = offset + percentOffset;
        let axisOffset = -range[0];

        let paddedZoomedLength = helpers.xWidth + 0.2 * helpers.xWidth;
        let zoomedPadding = paddedZoomedLength - helpers.xWidth;
        let zoomedFixedOffset = (0.5 * zoomedPadding) / paddedZoomedLength;

        let pointOffset = x(d);

        /* Adjust offset for percent again */
        offset = pointOffset / paddedZoomedLength + zoomedFixedOffset;
      }

      /* Chart is zoomed or something. This is a real hacky solution,
       * sticking with it for now, but it's kind of garbage, I'll be honest. */
      selection.attr("transform", "translate(0,0)");
      selection
        .select("line")
        .attr("x1", `${offset * 100}%`)
        .attr("x2", `${offset * 100}%`);

      selection.select("text").attr("x", `${offset * 100}%`);
    });

    const widthsOfText = [];

    yAxis.selectAll(".tick").each((d, i, nodes) => {
      let node = nodes[i];

      let selection = d3.select(node);

      widthsOfText.push(selection.select("text").node().clientWidth);

      let range = y.range();
      let offset = (y(d) / Math.abs(range[1] - range[0])) * 100;
      offset = ((offset + 10) / 120) * 100;

      selection.attr("transform", "translate(0,0)");
      selection
        .select("line")
        .attr("y1", `${offset}%`)
        .attr("y2", `${offset}%`);

      selection.select("text").attr("y", `${offset}%`);
    });

    yAxis.style("width", `${Math.ceil(Math.max(...widthsOfText)) + 28}px`); // this was unnecessarily huge for some reason, so this is reasonable enough to show the numbers on the axis?
  };
}
