import TrainingVideos from "./TrainingVideos";
import UserGuide from "./UserGuide";
import AdminGuide from "./AdminGuide";
import ReleaseNotes from "./ReleaseNotes";
import type { HelpPage } from "./types";

const ALL_PAGES_LIST: HelpPage[] = [
  TrainingVideos,
  UserGuide,
  AdminGuide,
  ReleaseNotes,
];

function validateAllAssetsExist() {
  ALL_PAGES_LIST.forEach((page) => {
    page.subsections.forEach((subsection) => {
      subsection.content.forEach((content) => {
        if (content.type === "video") {
          if (!content.content) {
            throw new Error(
              `Page ${page.url} has a video content without a content property.`
            );
          }
          if (!content.captionedcontent) {
            throw new Error(
              `Page ${page.url} has a video content without a captionedcontent property.`
            );
          }
        } else if (content.type === "image") {
          if (!content.content) {
            throw new Error(
              `Page ${page.url} has an image content without a content property.`
            );
          }
        } else if (content.type === "pdf") {
          if (!content.content) {
            throw new Error(
              `Page ${page.url} has a paragraph content without a content property.`
            );
          }
        }
      });
    });
  });
}

validateAllAssetsExist();

export default ALL_PAGES_LIST;
