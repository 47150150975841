import React, { useEffect, useState } from "react";
import _ from "lodash";
import Fragment from "../../common/Fragment";
import InputOld from "../../common/InputOld";

import moment from "moment";
import useAPI from "../../../lib/useAPI";

import {
  INSIGHTS_LABELS,
  INSIGHT_ORDER,
  INSIGHT_UNITS,
  INSIGHT_DETAILS,
} from "../constants";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minutesToMilliseconds } from "date-fns";

export default function OperatingLimitsInsightCard({ limit, variable }) {
  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();
  const api = useAPI();

  const b = useBaseUrlExperimental();

  const data = useQuery({
    enabled: useAtomValue(Atoms.chartInViewportAtom),
    staleTime: minutesToMilliseconds(5),
    queryKey: [b, "ol-insights", limit._id, start, end],
    queryFn: async () => {
      const fullStart = moment(end).subtract(180, "days");
      const [insights, fullInsights] = await Promise.all([
        api.getOperatingLimitInsights(limit._id, start, end),
        api.getOperatingLimitInsights(limit._id, fullStart, end),
      ]);

      return {
        partial: insights,
        full: fullInsights,
      };
    },
  }).data;

  function formatInsightDuration(millis) {
    const duration = moment.duration(millis);
    if (millis === 0) return 0;
    if (duration.asSeconds() < 60)
      return `${round(duration.asSeconds())} seconds`;
    if (duration.asMinutes() >= 1 && duration.asMinutes() < 60)
      return `${round(duration.asMinutes())} minutes`;
    if (duration.asHours() >= 1 && duration.asHours() < 24)
      return `${round(duration.asHours(), 1)} hours`;
    return `${round(duration.asDays(), 1)} days`;
  }

  function round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  if (!data) {
    return null;
  }
  const range = moment(end).diff(start, "days") + 1;
  return (
    <table className="OperatingLimitsDetails__insights">
      <tr className="OperatingLimitsDetails__insights__header">
        <th className="OperatingLimitsDetails__insights__label OperatingLimitsDetails__insights__label--first">
          Metrics
        </th>
        <th className="OperatingLimitsDetails__insights__label">
          {`${range} Days (x-axis range)`}
        </th>

        <th className="OperatingLimitsDetails__insights__label">
          180 Days (long-term)
        </th>
      </tr>

      {_.map(INSIGHT_ORDER, (insight) => {
        let label = INSIGHTS_LABELS[insight];
        if (_.isFunction(label)) {
          label = label(limit);
        }

        function formatInsight(insight, value) {
          if (value == null) {
            return "N/A";
          }

          /** @type {"unit" | "time" | "decimal" | "%" | "" | undefined} */
          const unit_ = INSIGHT_UNITS[insight];

          switch (unit_) {
            case "%":
              return `${round(value * 100)}%`;
            case "time":
              return `${formatInsightDuration(value)}`;
            case "decimal":
              return `${round(value, 1)}`;
            case "unit":
              return variable && variable.units_of_measurement
                ? `${round(value, 1)} ${variable.units_of_measurement}`
                : round(value, 1);

            default:
              return round(value);
          }
        }

        return (
          <Fragment key={insight}>
            <tr className="OperatingLimitsDetails__insights__row">
              <td className="OperatingLimitsDetails__insights__insight">
                {label}
                {INSIGHT_DETAILS[insight] && (
                  <InputOld
                    type="info"
                    classes={{
                      Input: "OperatingLimitsDetails__insights__icon",
                      Input__label:
                        "OperatingLimitsDetails__insights__icon__label",
                    }}
                    label={INSIGHT_DETAILS[insight]}
                    icon="info-circle"
                  />
                )}
              </td>
              <td className="OperatingLimitsDetails__insights__value">
                {formatInsight(insight, data.partial[insight])}
              </td>
              <td className="OperatingLimitsDetails__insights__value">
                {formatInsight(insight, data.full[insight])}
              </td>
            </tr>
          </Fragment>
        );
      })}
    </table>
  );
}
