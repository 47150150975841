import { useCallback, useEffect, useRef, useState } from "react";

export default function useIntersectionObserver() {
  const [enteredTheScreen, setEnteredTheScreen] = useState(false);

  const observerRef = useRef();

  /**
   * This gets fire on every mount/unmount of the element you give subscribe to
   * as a ref.
   *
   * So, it should clean up the observer every cycle.
   */
  const subscribe = useCallback((node) => {
    // console.log("nodeee");
    // if (observerRef.current) {
    //   setEnteredTheScreen(false);
    //   observerRef.current.disconnect();
    //   observerRef.current = null;
    // }

    if (!node) {
      return;
    } else {
      const observer = new IntersectionObserver(
        (entries) => {
          const [{ isIntersecting }] = entries;
          setEnteredTheScreen(isIntersecting);
        },
        {
          threshold: 0,
          root: null,
          rootMargin: "0%",
        }
      );
      observer.observe(node);
      observerRef.current = observer; // save it to clean up later
    }
  }, []);

  // this is here because I'm paranoid but it can't hurt
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, []);

  return { subscribe, enteredTheScreen };
}
