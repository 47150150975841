/**
 * Polyfills for methods/CSS classes that Babel doesn't catch.
 *
 * Why polyfill instead of just avoiding these methods? In short, avoiding them
 * is error-prone. It is unlikely that people in the future will know which
 * methods are specifically a problem for us. And since we don't have an amazing
 * setup yet for testing on IE, cutting errors off at the source is safer than
 * hoping we catch them during testing.
 *
 * Polyfills on the other hand guarantee that we will 100% never see the error
 * again.
 */

//Number.EPSILON constant (https://stackoverflow.com/questions/39695614/browsersupport-of-number-epsilon)
if (!Number.EPSILON) {
  Number.EPSILON = 2.220446049250313e-16;
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    value: function (valueToFind, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === "number" &&
            typeof y === "number" &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

//developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
if (!String.prototype.includes) {
  Object.defineProperty(String.prototype, "includes", {
    value: function (search, start) {
      if (typeof start !== "number") {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    },
  });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, "findIndex", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true,
  });
}

if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

// Production steps of ECMA-262, Edition 5, 15.4.4.21
// Reference: http://es5.github.io/#x15.4.4.21
// https://tc39.github.io/ecma262/#sec-array.prototype.reduce
if (!Array.prototype.reduce) {
  Object.defineProperty(Array.prototype, "reduce", {
    value: function (callback /*, initialValue*/) {
      if (this === null) {
        throw new TypeError(
          "Array.prototype.reduce " + "called on null or undefined"
        );
      }
      if (typeof callback !== "function") {
        throw new TypeError(callback + " is not a function");
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // Steps 3, 4, 5, 6, 7
      var k = 0;
      var value;

      if (arguments.length >= 2) {
        value = arguments[1];
      } else {
        while (k < len && !(k in o)) {
          k++;
        }

        // 3. If len is 0 and initialValue is not present,
        //    throw a TypeError exception.
        if (k >= len) {
          throw new TypeError(
            "Reduce of empty array " + "with no initial value"
          );
        }
        value = o[k++];
      }

      // 8. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kPresent be ? HasProperty(O, Pk).
        // c. If kPresent is true, then
        //    i.  Let kValue be ? Get(O, Pk).
        //    ii. Let accumulator be ? Call(
        //          callbackfn, undefined,
        //          « accumulator, kValue, k, O »).
        if (k in o) {
          value = callback(value, o[k], k, o);
        }

        // d. Increase k by 1.
        k++;
      }

      // 9. Return accumulator.
      return value;
    },
  });
}

// https://stackoverflow.com/questions/42830257/alternative-version-for-object-values
// Modified, because it's kind of not a great polyfill.
if (!Object.values) {
  Object.values = function values(obj) {
    return Object.keys(obj).map(function (e) {
      return obj[e];
    });
  };
}

// https://github.com/jerrybendy/url-search-params-polyfill

/* @author Jerry Bendy <jerry@icewingcc.com>
 * @licence MIT */
(function (self) {
  

  var nativeURLSearchParams =
      self.URLSearchParams && self.URLSearchParams.prototype.get
        ? self.URLSearchParams
        : null,
    isSupportObjectConstructor =
      nativeURLSearchParams &&
      new nativeURLSearchParams({ a: 1 }).toString() === "a=1",
    // There is a bug in safari 10.1 (and earlier) that incorrectly decodes `%2B` as an empty space and not a plus.
    decodesPlusesCorrectly =
      nativeURLSearchParams &&
      new nativeURLSearchParams("s=%2B").get("s") === "+",
    __URLSearchParams__ = "__URLSearchParams__",
    // Fix bug in Edge which cannot encode ' &' correctly
    encodesAmpersandsCorrectly = nativeURLSearchParams
      ? (function () {
          var ampersandTest = new nativeURLSearchParams();
          ampersandTest.append("s", " &");
          return ampersandTest.toString() === "s=+%26";
        })()
      : true,
    prototype = URLSearchParamsPolyfill.prototype,
    iterable = !!(self.Symbol && self.Symbol.iterator);

  if (
    nativeURLSearchParams &&
    isSupportObjectConstructor &&
    decodesPlusesCorrectly &&
    encodesAmpersandsCorrectly
  ) {
    return;
  }

  /**
   * Make a URLSearchParams instance
   *
   * @param {object|string|URLSearchParams} search
   * @constructor
   */
  function URLSearchParamsPolyfill(search) {
    search = search || "";

    // support construct object with another URLSearchParams instance
    if (
      search instanceof URLSearchParams ||
      search instanceof URLSearchParamsPolyfill
    ) {
      search = search.toString();
    }
    this[__URLSearchParams__] = parseToDict(search);
  }

  /**
   * Appends a specified key/value pair as a new search parameter.
   *
   * @param {string} name
   * @param {string} value
   */
  prototype.append = function (name, value) {
    appendTo(this[__URLSearchParams__], name, value);
  };

  /**
   * Deletes the given search parameter, and its associated value,
   * from the list of all search parameters.
   *
   * @param {string} name
   */
  prototype["delete"] = function (name) {
    delete this[__URLSearchParams__][name];
  };

  /**
   * Returns the first value associated to the given search parameter.
   *
   * @param {string} name
   * @returns {string|null}
   */
  prototype.get = function (name) {
    var dict = this[__URLSearchParams__];
    return this.has(name) ? dict[name][0] : null;
  };

  /**
   * Returns all the values association with a given search parameter.
   *
   * @param {string} name
   * @returns {Array}
   */
  prototype.getAll = function (name) {
    var dict = this[__URLSearchParams__];
    return this.has(name) ? dict[name].slice(0) : [];
  };

  /**
   * Returns a Boolean indicating if such a search parameter exists.
   *
   * @param {string} name
   * @returns {boolean}
   */
  prototype.has = function (name) {
    return hasOwnProperty(this[__URLSearchParams__], name);
  };

  /**
   * Sets the value associated to a given search parameter to
   * the given value. If there were several values, delete the
   * others.
   *
   * @param {string} name
   * @param {string} value
   */
  prototype.set = function set(name, value) {
    this[__URLSearchParams__][name] = ["" + value];
  };

  /**
   * Returns a string containg a query string suitable for use in a URL.
   *
   * @returns {string}
   */
  prototype.toString = function () {
    var dict = this[__URLSearchParams__],
      query = [],
      i,
      key,
      name,
      value;
    for (key in dict) {
      name = encode(key);
      for (i = 0, value = dict[key]; i < value.length; i++) {
        query.push(name + "=" + encode(value[i]));
      }
    }
    return query.join("&");
  };

  // There is a bug in Safari 10.1 and `Proxy`ing it is not enough.
  var forSureUsePolyfill = !decodesPlusesCorrectly;
  var useProxy =
    !forSureUsePolyfill &&
    nativeURLSearchParams &&
    !isSupportObjectConstructor &&
    self.Proxy;
  /*
   * Apply polifill to global object and append other prototype into it
   */
  Object.defineProperty(self, "URLSearchParams", {
    value: useProxy
      ? // Safari 10.0 doesn't support Proxy, so it won't extend URLSearchParams on safari 10.0
        new Proxy(nativeURLSearchParams, {
          construct: function (target, args) {
            return new target(new URLSearchParamsPolyfill(args[0]).toString());
          },
        })
      : URLSearchParamsPolyfill,
  });

  var USPProto = self.URLSearchParams.prototype;

  USPProto.polyfill = true;

  /**
   *
   * @param {function} callback
   * @param {object} thisArg
   */
  USPProto.forEach =
    USPProto.forEach ||
    function (callback, thisArg) {
      var dict = parseToDict(this.toString());
      Object.getOwnPropertyNames(dict).forEach(function (name) {
        dict[name].forEach(function (value) {
          callback.call(thisArg, value, name, this);
        }, this);
      }, this);
    };

  /**
   * Sort all name-value pairs
   */
  USPProto.sort =
    USPProto.sort ||
    function () {
      var dict = parseToDict(this.toString()),
        keys = [],
        k,
        i,
        j;
      for (k in dict) {
        keys.push(k);
      }
      keys.sort();

      for (i = 0; i < keys.length; i++) {
        this["delete"](keys[i]);
      }
      for (i = 0; i < keys.length; i++) {
        var key = keys[i],
          values = dict[key];
        for (j = 0; j < values.length; j++) {
          this.append(key, values[j]);
        }
      }
    };

  /**
   * Returns an iterator allowing to go through all keys of
   * the key/value pairs contained in this object.
   *
   * @returns {function}
   */
  USPProto.keys =
    USPProto.keys ||
    function () {
      var items = [];
      this.forEach(function (item, name) {
        items.push(name);
      });
      return makeIterator(items);
    };

  /**
   * Returns an iterator allowing to go through all values of
   * the key/value pairs contained in this object.
   *
   * @returns {function}
   */
  USPProto.values =
    USPProto.values ||
    function () {
      var items = [];
      this.forEach(function (item) {
        items.push(item);
      });
      return makeIterator(items);
    };

  /**
   * Returns an iterator allowing to go through all key/value
   * pairs contained in this object.
   *
   * @returns {function}
   */
  USPProto.entries =
    USPProto.entries ||
    function () {
      var items = [];
      this.forEach(function (item, name) {
        items.push([name, item]);
      });
      return makeIterator(items);
    };

  if (iterable) {
    USPProto[self.Symbol.iterator] =
      USPProto[self.Symbol.iterator] || USPProto.entries;
  }

  function encode(str) {
    var replace = {
      "!": "%21",
      "'": "%27",
      "(": "%28",
      ")": "%29",
      "~": "%7E",
      "%20": "+",
      "%00": "\x00",
    };
    return encodeURIComponent(str).replace(
      /[!'\(\)~]|%20|%00/g,
      function (match) {
        return replace[match];
      }
    );
  }

  function decode(str) {
    return str
      .replace(/[ +]/g, "%20")
      .replace(/(%[a-f0-9]{2})+/gi, function (match) {
        return decodeURIComponent(match);
      });
  }

  function makeIterator(arr) {
    var iterator = {
      next: function () {
        var value = arr.shift();
        return { done: value === undefined, value: value };
      },
    };

    if (iterable) {
      iterator[self.Symbol.iterator] = function () {
        return iterator;
      };
    }

    return iterator;
  }

  function parseToDict(search) {
    var dict = {};

    if (typeof search === "object") {
      // if `search` is an array, treat it as a sequence
      if (isArray(search)) {
        for (var i = 0; i < search.length; i++) {
          var item = search[i];
          if (isArray(item) && item.length === 2) {
            appendTo(dict, item[0], item[1]);
          } else {
            throw new TypeError(
              "Failed to construct 'URLSearchParams': Sequence initializer must only contain pair elements"
            );
          }
        }
      } else {
        for (var key in search) {
          if (search.hasOwnProperty(key)) {
            appendTo(dict, key, search[key]);
          }
        }
      }
    } else {
      // remove first '?'
      if (search.indexOf("?") === 0) {
        search = search.slice(1);
      }

      var pairs = search.split("&");
      for (var j = 0; j < pairs.length; j++) {
        var value = pairs[j],
          index = value.indexOf("=");

        if (-1 < index) {
          appendTo(
            dict,
            decode(value.slice(0, index)),
            decode(value.slice(index + 1))
          );
        } else {
          if (value) {
            appendTo(dict, decode(value), "");
          }
        }
      }
    }

    return dict;
  }

  function appendTo(dict, name, value) {
    var val =
      typeof value === "string"
        ? value
        : value !== null &&
          value !== undefined &&
          typeof value.toString === "function"
        ? value.toString()
        : JSON.stringify(value);

    // #47 Prevent using `hasOwnProperty` as a property name
    if (hasOwnProperty(dict, name)) {
      dict[name].push(val);
    } else {
      dict[name] = [val];
    }
  }

  function isArray(val) {
    return !!val && "[object Array]" === Object.prototype.toString.call(val);
  }

  function hasOwnProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
})(
  typeof global !== "undefined"
    ? global
    : typeof window !== "undefined"
    ? window
    : this
);

// Promise.finally
// https://stackoverflow.com/questions/53327711/how-to-add-a-polyfill-to-support-finally-in-edge
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    finally(fn) {
      const onFinally = (callback) => Promise.resolve(fn()).then(callback);
      return this.then(
        (result) => onFinally(() => result),
        (reason) => onFinally(() => Promise.reject(reason))
      );
    },
  }.finally;

/* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN */
Number.isNaN =
  Number.isNaN ||
  function isNaN(input) {
    return typeof input === "number" && input !== input;
  };
