export enum ShutdownRuleCategory {
  Shutdown,
  Slowdown,
  EquipmentShutdown,
  Calibration,
  Transition,
}

export type DTO_ShutdownRule = {
  _id: string;
  shortId: number;
  createdAt: string;
  updatedAt: string;
  ruleModifiedAt: string;
  name: string;
  description: string;
  bindingGroupIds: string[];
  bindingVariableIds: string[];
  bindingVariableIdsSet: string[];
  folderIds: string[];
  clusters: string[];
  expression: string;
  category:
    | "Shutdown"
    | "Slowdown"
    | "EquipmentShutdown"
    | "Calibration"
    | "Transition"
    | null;
  watchlist: boolean;
  deleted: boolean;
  buffer: number;
  unit: string;
};

export type CreateShutdownRulePayload = {
  [K in keyof Pick<
    DTO_ShutdownRule,
    | "name"
    | "description"
    | "expression"
    | "bindingGroupIds"
    | "bindingVariableIds"
    | "category"
    | "buffer"
    | "clusters"
  >]: NonNullable<DTO_ShutdownRule[K]>;
};

export const shutdownValueToLabel = {
  Shutdown: "Shutdown",
  Slowdown: "Slowdown",
  EquipmentShutdown: "Equipment Shutdown",
  Calibration: "Calibration",
  Transition: "Transition",
} as const satisfies {
  [C in NonNullable<DTO_ShutdownRule["category"]>]: string;
};

type ShutdownValueToLabel = typeof shutdownValueToLabel;

type ShutdownCategory = {
  [K in keyof ShutdownValueToLabel]: {
    label: ShutdownValueToLabel[K];
    value: K;
  };
}[keyof ShutdownValueToLabel];

const DEFAULT_SHUTDOWN_CATEGORY: ShutdownCategory = {
  label: "Shutdown",
  value: "Shutdown",
};

export const ShutdownCategoriesSelectOptions: ShutdownCategory[] = [
  DEFAULT_SHUTDOWN_CATEGORY,
  { label: "Slowdown", value: "Slowdown" },
  { label: "Equipment Shutdown", value: "EquipmentShutdown" },
  { label: "Calibration", value: "Calibration" },
  { label: "Transition", value: "Transition" },
];
