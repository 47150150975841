import _ from "lodash";
import { GroupCard } from "./group-card";
import GroupEditCreateForm from "./group-edit-or-create-form";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import useBoolean from "../../common/hooks/useBoolean";

import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { addToast, addUnknownErrorToast } from "../../toast/use-toast-store";
import { CreateSectionButton } from "./create-section-button";
import {
  useGroupsQuery,
  useInvalidateGroupsQuery,
  useInvalidateSectionsQuery,
} from "../../../hooks/tanstack-query";
import { useGroupsWithSections } from "./use-groups-with-sections";
import { SectionsArea } from "./section-card";
import { useMutation } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  createGroup,
  reorderAllGroupsForAUnit,
} from "../../../frameworks/fetcher/api-routes-experimental";
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ComponentProps, useEffect, useId, useState } from "react";
import { SortableContext } from "@dnd-kit/sortable";
import { useMemo } from "use-memo-one";
import { Group } from "../../../lib/api-schema/group";
import { Button } from "../../ui/button";
import { Plus } from "lucide-react";
import { iife } from "../../../lib/utils";

const PAGE_NAME = "Groups + Sections";

function GroupsManager() {
  usePermissionBasedDocumentTitleForSettingsPage();
  const hasEditAccess = useHasEditPermission();

  const groups = useGroupsQuery().data;

  const [createMode, enterCreateMode, exitCreateMode] = useBoolean(false);

  const groupsWithSections = useGroupsWithSections();

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditAccess && (
            <>
              <Button size={"xs"} onClick={enterCreateMode}>
                <Plus className="size-3.5 mr-1" /> Create Group
              </Button>
              <CreateSectionButton className="ml-3" />
            </>
          )}
        </div>
        <div>
          {groups?.length != null && (
            <span className="mr-5 italic text-lg text-titlegrey">
              {iife(() => {
                if (!groupsWithSections) return undefined;
                const numGroups =
                  groupsWithSections.remainingGroups.length +
                  groupsWithSections.sectionsWithGroups.reduce(
                    (sum, x) => sum + x.groups.length,
                    0
                  );
                const numSections =
                  groupsWithSections.sectionsWithGroups.length;

                const tokens = [
                  numGroups > 0
                    ? `${numGroups} Group${numGroups === 1 ? "" : "s"}`
                    : undefined,
                  numSections > 0
                    ? `${numSections} Section${numSections === 1 ? "" : "s"}`
                    : undefined,
                ].filter((x) => x !== undefined);

                if (tokens.length === 0) return undefined;

                return `Displaying ${tokens.join(", ")}`;
              })}
            </span>
          )}
        </div>
      </div>
      {createMode && (
        <div className="p-4 mt-3 border-1 border border-bordgrey bg-white rounded-xl animate-in slide-in-from-bottom-5 fade-in-20 duration-300">
          <CreateGroupForm close={exitCreateMode} />
        </div>
      )}
      {groupsWithSections && (
        <div className="list-none my-5 drop-shadow-md">
          {/* groups that dont belong to a section go on top  */}
          <LoneGroupsArea groups={groupsWithSections.remainingGroups} />
          {groupsWithSections.remainingGroups.length > 0 &&
            groupsWithSections.sectionsWithGroups.length > 0 && (
              <div className="w-full h-[1.5px] bg-xslate-6 my-8"></div>
            )}
          <SectionsArea />
        </div>
      )}
    </>
  );
}

function LoneGroupsArea({ groups }: { groups: Group[] }) {
  const groupsWithSections = useGroupsWithSections();
  const hasEditAccess = useHasEditPermission();
  const [copiedGroups, setCopiedGroups] = useState(groups);

  useEffect(() => {
    /**
     * Every drag and drop event will cause a PATCH update.
     * We do not want to wait for the request to finish
     * before we see the cards switch places. It must be
     * done immediately, so we'll keep a copy of the groups
     * and update it manually on drag and drop. Them, when
     * the request finishes, it'll update again but we'll
     * have already done it optimistically.
     */
    setCopiedGroups(groups);
  }, [setCopiedGroups, groups]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const currentOrder = useMemo(
    () => copiedGroups.map((x) => x._id),
    [copiedGroups]
  );

  const droppableContainerId = useId();
  const { setNodeRef } = useDroppable({
    id: droppableContainerId,
  });

  const refreshSections = useInvalidateSectionsQuery();
  const refreshGroups = useInvalidateGroupsQuery();
  const b = useBaseUrlExperimental();
  const updateGroupOrderMut = useMutation({
    mutationFn: async (newLoneGroupsOrder: string[]) => {
      if (!hasEditAccess) return;

      if (!groupsWithSections) throw new Error("Please try again.");

      const theseGoOnTop = newLoneGroupsOrder;
      const theseGoOnTheBottom = groupsWithSections.sectionsWithGroups.flatMap(
        (x) => x.groups.map((x) => x._id)
      );

      const newDisplayIdsOrdering = [...theseGoOnTop, ...theseGoOnTheBottom];

      await reorderAllGroupsForAUnit(b, newDisplayIdsOrdering);
    },
    onSuccess: () => {
      refreshSections();
      refreshGroups();
    },
    onError: (e) => {
      refreshSections();
      refreshGroups();
      addUnknownErrorToast(e);
    },
  });

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={
        hasEditAccess
          ? (e) => {
              const theThingDragging = e.active.id.toString();

              const over = e.over?.id.toString();

              if (over === undefined) {
                return; // no-op
              }

              if (over === droppableContainerId) return;

              if (theThingDragging === over) return; // no-op because same element

              const idxA = currentOrder.indexOf(theThingDragging);
              const idxB = currentOrder.indexOf(over);

              if (idxA === -1 || idxB === -1) {
                throw new Error("unhandled case onDragEnd 2");
              }

              const newOrder = currentOrder.filter(
                (x) => x !== theThingDragging
              );
              newOrder.splice(idxB, 0, theThingDragging);

              // mutate
              updateGroupOrderMut.mutate(newOrder);

              // optimistic update so the user sees instant re-ordering
              setCopiedGroups(
                copiedGroups.slice().sort((a, b) => {
                  const idxA = newOrder.indexOf(a._id);
                  const idxB = newOrder.indexOf(b._id);
                  return idxA - idxB;
                })
              );
            }
          : undefined
      }
    >
      <SortableContext items={currentOrder}>
        <div ref={setNodeRef}>
          {copiedGroups.map((group) => {
            return (
              <GroupCard
                renderAsDraggable
                key={group._id}
                group={group}
                className={"bg-white"}
              />
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
}

function CreateGroupForm({
  close,
  defaults,
}: Pick<ComponentProps<typeof GroupEditCreateForm>, "defaults" | "close">) {
  const refreshGroups = useInvalidateGroupsQuery();
  const b = useBaseUrlExperimental();
  const createMut = useMutation({
    mutationFn: async (payload: { variables: string[]; name: string }) => {
      return await createGroup(b, payload);
    },
    onSuccess: ({ name }) => {
      addToast({
        title: `${name} created`,
        variant: "success",
      });
      refreshGroups();
      close();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });

  return (
    <GroupEditCreateForm
      onSubmit={(payload) => createMut.mutate(payload)}
      close={close}
      defaults={defaults}
    />
  );
}

export default GroupsManager;
