import { z } from "zod";

export function getAPIUrl() {
  const isDev = process.env.NODE_ENV !== "production";

  /**
   * Building the app will do: isDev=true
   *
   * If you're npm starting the app on an internal box, you must add REACT_APP_DEV_API_URL to .env
   */
  return isDev
    ? z
        .string({
          errorMap: (issue, ctx) => {
            if (issue.code === "invalid_type") {
              return {
                message: `process.env.REACT_APP_DEV_API_URL must be a string, but received ${issue.received}`,
              };
            }

            return {
              message: ctx.defaultError,
            };
          },
        })
        .url({
          message:
            "REACT_APP_DEV_API_URL must be a valid URL: " +
            process.env.REACT_APP_DEV_API_URL,
        })
        .refine(
          (v) => {
            return !v.endsWith("/");
          },
          (v) => ({
            message: `REACT_APP_DEV_API_URL must not end with /. Received: '${v}'`,
          })
        )
        .refine(
          (v) => {
            return v === v.trim();
          },
          (v) => {
            return {
              message: `REACT_APP_DEV_API_URL must not have leading or trailing spaces. Received: '${v}''`,
            };
          }
        )
        .parse(process.env.REACT_APP_DEV_API_URL)
    : "/api"; // same domain in prod
}
