import { useConfigRequired } from "../../zustand/config/useConfigStore";
import { PlantTimeClock } from "./plant-time-clock";
import { cn } from "../../lib/utils";
import { FooterLogo } from "../nmm-branding/footer-logo";
import { DynamicRiskAnalyzerLogo } from "../nmm-branding/dynamic-risk-analyzer-logo";
import { ThemeToggle2 } from "./theme-toggle";

function Footer({ className }: { className?: string }) {
  const Config = useConfigRequired();
  return (
    <footer
      className={cn(
        "flex flex-col gap-10 mt-12 border-t border-xslate-4 py-12 px-8",
        className
      )}
    >
      <div className="flex w-full justify-between DIN">
        <div className="mr-auto">
          <DynamicRiskAnalyzerLogo className="w-80 fill-zinc-500" />
          <span className="text-[18px]">
            discovering near-misses hidden in data
          </span>
        </div>
        <div className="flex flex-col items-end">
          <a
            href="https://www.nearmissmgmt.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FooterLogo className="grayscale hover:grayscale-0 w-[12.4rem]" />
          </a>
          <p className="text-[1rem] my-0 py-0 text-right">
            v{Config.verName}
            <br />
            <PlantTimeClock />
            <br />
            {Config.timezone}
          </p>
        </div>
      </div>

      <div className="flex gap-10 text-[16px]">
        <a
          href="mailto:support@nearmissmgmt.com"
          tabIndex={500}
          target="_blank"
          rel="noreferrer"
        >
          <i style={{ width: "24", height: "24" }} className="fa fa-envelope" />
          &nbsp;support@nearmissmgmt.com
        </a>
        <a href="tel:2676036380" tabIndex={500}>
          <i style={{ width: "24", height: "24" }} className="fa fa-phone" />
          &nbsp;1 (267) 603 6380
        </a>
        <a
          href="https://calendly.com/nmmsupport"
          tabIndex={500}
          target="_blank"
          rel="noreferrer"
        >
          <i style={{ width: "24", height: "24" }} className="fa fa-calendar" />
          &nbsp;Schedule DRA Training
        </a>
        <ThemeToggle2 className="ml-auto" />
      </div>
      <div className="flex flex-col gap-4 w-full">
        <p className="text-[14px]">
          All images, graphs, text, code, or other content in this publication
          are, to the extent permitted by law, copyrighted and otherwise
          proprietary. No reproduction, distribution, transmission, or other use
          of any part of this information is permitted without the prior written
          permission of Near-Miss Management LLC.
        </p>
        <p className="text-[14px]">
          Copyright &copy; {new Date().getFullYear()} Near-Miss Management LLC.
          All rights reserved. <br />{" "}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
