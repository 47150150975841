import { z } from "zod";

export const YYYY_MM_DD = z.custom<`${number}-${number}-${number}`>(
  (v) => {
    if (typeof v !== "string") return false;

    const parts = v.split("-");

    if (parts.length !== 3) return false;

    const [y, m, d] = parts;

    if (!y || !m || !d) return false;

    if (y.length !== 4) return false;
    if (m.length !== 2) return false;
    if (d.length !== 2) return false;

    const isNumber = z.coerce.number().int();

    return (
      isNumber.safeParse(y).success &&
      isNumber.safeParse(m).success &&
      isNumber.safeParse(d).success
    );
  },
  {
    message: "Expected date string in format YYYY-MM-DD",
  }
);

export type YYYY_MM_DD = z.infer<typeof YYYY_MM_DD>;
