import { useState, useRef } from "react";
import {
  CreateShutdownRulePayload,
  ShutdownCategoriesSelectOptions,
} from "../../../lib/api-schema/shutdown-rule";
import * as ManagerComponents from "../../manager-components";
import { PropsWithCn } from "../../types/component.types";
import { cn } from "../../../lib/utils";
import { z } from "zod";
import { addToast, addUnknownErrorToast } from "../../toast/use-toast-store";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import validateExpression from "../../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../../shared-ui/expression-building-input/constants";
import { Label } from "../../../shared-ui/frontend/label";

type Inputable<K> = { [P in keyof K]: K[P] extends number ? string : K[P] };

type PayloadAsInputable = Inputable<CreateShutdownRulePayload>;

export function ShutdownRuleForm({
  disabled,
  close,
  defaultValues,
  onSubmit,
  className,
}: {
  onSubmit: (p: CreateShutdownRulePayload) => void;
  disabled: boolean;
  close: () => void;
  defaultValues?: CreateShutdownRulePayload;
} & PropsWithCn) {
  const [formState, setFormState] = useState<PayloadAsInputable>({
    bindingGroupIds: defaultValues?.bindingGroupIds ?? [],
    description: defaultValues?.description ?? "",
    bindingVariableIds: defaultValues?.bindingVariableIds ?? [],
    expression: defaultValues?.expression ?? "",
    name: defaultValues?.name ?? "",
    buffer: defaultValues?.buffer?.toString() ?? "3",
    category: defaultValues?.category ?? "Shutdown",
    clusters: defaultValues?.clusters ?? [],
  });

  const allVariables = useVariablesArrayQuery();
  const options =
    allVariables.data?.map((v) => ({
      label: v.trimmedName,
      value: v.trimmedName,
    })) || [];
  const symbols = allVariables.data?.map((o) => o.name) || [];

  return (
    <ManagerComponents.FormContainer
      className={cn(className)}
      onSubmit={(e) => {
        e.preventDefault();

        try {
          validateExpression(formState.expression, symbols, FUNCTIONS, true);
          const parsedBufferHours = z
            .number()
            .safeParse(parseInt(formState.buffer));

          if (!parsedBufferHours.success) {
            addUnknownErrorToast("Buffer must be a number");
            return;
          }

          const asMinutes = Math.floor(parsedBufferHours.data * 60);
          onSubmit({
            ...formState,
            buffer: asMinutes,
          });
        } catch (e) {
          addUnknownErrorToast(e);
        }
      }}
    >
      <ManagerComponents.InputWithLabel
        id="id"
        label="ID"
        className="w-full"
        required
        minLength={1}
        value={formState.name}
        disabled={disabled}
        onChange={(e) =>
          setFormState((old) => ({ ...old, name: e.target.value }))
        }
      />

      <ManagerComponents.InputWithLabel
        id="description"
        label="Description"
        className="w-full"
        required
        minLength={1}
        value={formState.description}
        disabled={disabled}
        onChange={(e) =>
          setFormState((old) => ({ ...old, description: e.target.value }))
        }
      />

      <Label>Expression</Label>
      <InstantCalculator
        onInput={(expr) => {
          setFormState((old) => ({ ...old, expression: expr }));
        }}
        booleanExpression={true}
        defaultValue={formState.expression}
      />

      <ManagerComponents.GroupMultiSelect
        disabled={disabled}
        value={formState.bindingGroupIds}
        onChange={(groupIds) =>
          setFormState((old) => ({ ...old, bindingGroupIds: groupIds }))
        }
      />

      <ManagerComponents.TagsMultiSelect
        disabled={disabled}
        value={formState.bindingVariableIds}
        noPortal
        onChange={(vids) =>
          setFormState((old) => ({ ...old, bindingVariableIds: vids }))
        }
      />

      <ManagerComponents.ClusterMultiSelect
        disabled={disabled}
        value={formState.clusters}
        onChange={(vids) => setFormState((old) => ({ ...old, clusters: vids }))}
      />

      <ManagerComponents.SelectWithLabel
        bordered
        id="category"
        label="Category"
        options={ShutdownCategoriesSelectOptions}
        value={formState.category}
        onChange={(e) => {
          const v = e.target
            .value as (typeof ShutdownCategoriesSelectOptions)[number]["value"];
          setFormState((old) => ({ ...old, category: v }));
        }}
      />

      <ManagerComponents.InputWithLabel
        type="number"
        id="buffer"
        label="Buffer (hours)"
        className="w-full"
        required
        minLength={1}
        value={formState.buffer}
        disabled={disabled}
        onChange={(e) =>
          setFormState((old) => ({ ...old, buffer: e.target.value }))
        }
      />

      <ManagerComponents.CancelSaveFooter
        className="mt-3"
        close={{
          disabled,
          onClick: close,
        }}
        submit={{
          disabled,
        }}
      />
    </ManagerComponents.FormContainer>
  );
}
