import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Button from "./Button/Button";

export default function usePagination(items, itemsPerPage = 25, buttons) {
  // page is zero indexed
  const [pageNum, setPageNum] = useState(0);

  /**
   * Keeping track of the length of the array allows us to
   * recognize when the state has grown or become smaller, at which
   * point we want to reset the pagination back to 0.
   *
   * If the state has changed, like the objects inside them have been
   * updated, we probably want to stay on the same page for our use cases,
   * which is the manager pages.
   *
   * This prevents the user from going back to page 1 after they update some
   * tag on page 5. It it s optimal to stay on page 5 and see that the tag
   * has changed in the UI.
   */
  const oldItemsCount = useRef(items.length);

  useEffect(() => {
    if (items.length !== oldItemsCount.current) {
      oldItemsCount.current = items.length;
      /**
       * The main goal here is to only reset the page back to the first when
       * the array size changes
       */
      setPageNum(0);
    }
  }, [items]);

  const maxPageNum = Math.ceil(items.length / itemsPerPage);

  const nextPage = () => {
    setPageNum((old) => {
      if (old === maxPageNum - 1) {
        // we are at the last page
        return old;
      } else {
        return old + 1;
      }
    });
  };

  const prevPage = () => {
    setPageNum((old) => {
      if (old === 0) {
        return old;
      } else {
        return old - 1;
      }
    });
  };

  const currPageNum = maxPageNum ? pageNum + 1 : 0; // for display purposes we probably want 1 indexed

  const firstItemIndex = pageNum * itemsPerPage; // where is the first element we want to show?

  return {
    // slice handles the ending index being out of bounds, so this is okay :)
    items: items.slice(firstItemIndex, firstItemIndex + itemsPerPage),
    currPageNum,
    nextPage,
    prevPage,
    maxPageNum,
    from: items.length && firstItemIndex + 1,
    to: Math.min(firstItemIndex + itemsPerPage, items.length),
    buttons: buttons ? (
      <div className="btn-group py-6 flex justify-end">
        <Button
          disabled={1 === currPageNum || maxPageNum === 0}
          onClick={prevPage}
          icon={FaArrowLeft}
        />
        <Button>
          Page {currPageNum} of {maxPageNum}
        </Button>
        <Button
          disabled={maxPageNum === currPageNum}
          onClick={nextPage}
          icon={FaArrowRight}
        />
      </div>
    ) : null,
  };
}
