import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

export type CorrelatedTags = {
  Excellent: string[];
  "Very Good": string[];
  Good: string[];
  Fair: string[];
  Poor: string[];
};

function createCorrelatedTagsDrawerStore() {
  return createStore<{
    tagName: string | undefined;
    setTagName: (tagName: string | undefined) => void;
    tags: CorrelatedTags | undefined;
    setTags: (tags: CorrelatedTags | undefined) => void;
  }>((set) => ({
    tagName: undefined,
    setTagName: (tagName) => set({ tagName }),
    tags: undefined,
    setTags: (tags) => set({ tags }),
  }));
}

type CorrelatedTagsDrawerStore = ReturnType<
  typeof createCorrelatedTagsDrawerStore
>;

const CorrelatedTagsDrawerStoreContext = createContext<
  CorrelatedTagsDrawerStore | undefined
>(undefined);

function CorrelatedTagsDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createCorrelatedTagsDrawerStore);
  return (
    <CorrelatedTagsDrawerStoreContext.Provider value={store}>
      {children}
    </CorrelatedTagsDrawerStoreContext.Provider>
  );
}

function useCorrelatedTagsDrawerStoreNotRequired() {
  return useContext(CorrelatedTagsDrawerStoreContext);
}

function useCorrelatedTagsDrawerStore() {
  const store = useContext(CorrelatedTagsDrawerStoreContext);

  if (!store) {
    throw new Error(
      "useCorrelatedTagsStore must be used within a CorrelatedTagsStoreProvider"
    );
  }

  return store;
}

export {
  CorrelatedTagsDrawerStoreProvider,
  useCorrelatedTagsDrawerStore,
  useCorrelatedTagsDrawerStoreNotRequired,
};
