import { z } from "zod";

// day => (clusterId => {maxLevel, isShutdown})
export const cdsSchema = z.record(
  z.string(),
  z.record(
    z.string(),
    z.object({ maxLevel: z.number(), isShutdown: z.boolean() })
  )
);

export type cdsSchema = z.infer<typeof cdsSchema>;
