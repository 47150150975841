/**
 * Global colors for anomaly
 */

import { type AnomalyLevelEnum } from "./anomaly-levels";
/**
 * If we use this in multiple places on the same page and we perhaps
 * mess with the order
 */

type Color = {
  value: AnomalyLevelEnum;
  color: string;
  label: string;
  r: number;
  g: number;
  b: number;
  rgb: `rgb(${number}, ${number}, ${number})`;
};

function generateColor(o: Omit<Color, "rgb">): Color {
  const { r, g, b } = o;
  const rgb: `rgb(${number}, ${number}, ${number})` = `rgb(${r}, ${g}, ${b})`;

  const out = { ...o, rgb };
  Object.freeze(out);
  return out;
}

export const GREEN: Color = generateColor({
  value: 0,
  color: "green",
  label: "No Risk",
  r: 136,
  g: 196,
  b: 37,
});

export const YELLOW: Color = generateColor({
  value: 1,
  color: "yellow",
  label: "Low Risk",
  r: 248,
  g: 202,
  b: 0,
});

export const ORANGE: Color = generateColor({
  value: 2,
  color: "orange",
  label: "Medium Risk",
  r: 233,
  g: 127,
  b: 2,
});

export const RED: Color = generateColor({
  value: 3,
  color: "red",
  label: "High Risk",
  r: 194,
  g: 26,
  b: 1,
});

export const COLORS_ARRAY = [GREEN, YELLOW, ORANGE, RED];

// get a fresh copy if you want
export default function getColors() {
  return [...COLORS_ARRAY];
}

export const COLOR_MAP: { [Level in AnomalyLevelEnum]: Color } = {
  0: GREEN,
  1: YELLOW,
  2: ORANGE,
  3: RED,
};
type Hex = `#${string}`;

const hardcoded: Hex[] = [
  "#0595D0",
  "#ff1493",
  "#ff9900",
  "#9900FF",
  "#37b000",
  "#04ffff",
  "#ff78f7",
  "#afb000",
  "#cb32ff",
  "#37ff64",
  "#7c9cdb",
  "#ffcb32",
  "#e3da10",
  "#a0df66",
  "#40ef99",
  "#ff4500",
  "#ffa07a",
  "#4d0078",
  "#ff0033",
  "#96ceb4",
  "#8b9dc3",
  "#44bec7",
];

export function getChartColor(n: number): string {
  return hardcoded[n % hardcoded.length] || "#000000";
}

interface UniqueColorGenerator {
  // guarantees unique colors
  getN(existingColors: string[], n: number): string[]; // generates a new color
}

class UniqueRandomColors implements UniqueColorGenerator {
  getN(existingColors: string[], n: number): string[] {
    const nOut: string[] = [];

    for (const h of hardcoded) {
      if (nOut.length === n) return nOut;

      if (!existingColors.includes(h) && !nOut.includes(h)) {
        nOut.push(h);
      }
    }

    let out = randomHSL();
    while (nOut.length < n) {
      if (!existingColors.includes(out) && !nOut.includes(out)) {
        nOut.push(out);
      }

      out = randomHSL();
    }

    return nOut;
  }
}

function getRandomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomL() {
  return `${getRandomInteger(4, 8)}0%`;
}

function getRandomS() {
  return `${getRandomInteger(3, 10)}0%`;
}

function randomHSL() {
  return `hsl(${~~(360 * Math.random())}, ${getRandomS()},  ${getRandomL()})`;
}

const colorGen: UniqueColorGenerator = new UniqueRandomColors();

export { UniqueRandomColors, type UniqueColorGenerator, colorGen };
