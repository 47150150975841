import Button from "../../common/Button/Button";
import { UseMutationResult } from "@tanstack/react-query";
import Notification, {
  NotificationType,
  NotificationSchedule,
  NotificationScheduleType,
  NotificationScheduleLabels,
  CustomNotificationFormData,
} from "../../../types/api/Notification";
import { cn } from "../../../lib/utils";
import { InputLabel } from "./input-label";
import Select from "../../common/Select/Select";
import { addToast } from "../../toast/use-toast-store";
import { useRef } from "react";
import { parseExprRef } from "../../../shared-ui/expression-building-input/utils";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import validateExpression from "../../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../../shared-ui/expression-building-input/constants";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { generateMessageFromError } from "../../../frameworks/fetcher/api-routes-experimental";

export default function CustomNotificationForm({
  defaultData = {
    schedule: {
      type: NotificationScheduleType.ONCE,
    },
  },
  mutation,
  close,
  className,
}: {
  defaultData: {
    schedule: NotificationSchedule;
  };
  className?: string;
  /* We don't care about the types of any other parts of the mutation response, we're never going to reference them. */
  mutation: UseMutationResult<Notification>;
  close: () => void;
}) {
  const inputRef = useRef<HTMLDivElement>(null);

  const { status, mutate } = mutation;
  const loading = status === "loading";

  const allVariables = useVariablesArrayQuery();
  const symbols = allVariables.data?.map((o) => o.name) || [];

  const submit = async (target: HTMLFormElement) => {
    try {
      const expr = parseExprRef(inputRef.current?.innerHTML || "");
      validateExpression(expr, symbols, FUNCTIONS, true);
      const data = new FormData(target);
      const payload = {
        ...defaultData,
        ...{
          customExpression: expr,
          schedule: { type: data.get("schedule") },
          type: NotificationType.CUSTOM,
          value: 10,
        },
      };

      /* TODO: see if there's a cleaner way to handle this. */
      mutate(payload, {
        onSuccess: () => close(),
        onError: (err) => {
          addToast({
            title: "Issue with notification",
            description: generateMessageFromError(err),
            variant: "danger",
          });
        },
      });
    } catch (err) {
      addToast({
        title: "Error saving notification",
        description: generateMessageFromError(err),
        variant: "danger",
      });
    }
  };

  return (
    <form
      className={cn(
        "w-full animate-in slide-in-from-bottom-4 fade-in-20 p-3",
        className
      )}
      onSubmit={(event) => {
        event.preventDefault();
        submit(event.currentTarget);
      }}
    >
      <InputLabel>
        <span>Notification Rule</span>
      </InputLabel>
      <InstantCalculator
        defaultInputRef={inputRef}
        className={"h-9 py-2 rounded border-xslate-7 text-sm"}
        booleanExpression={true}
        // @ts-ignore
        defaultValue={defaultData.customExpression}
      />

      <InputLabel>
        <span>Notification Frequency</span>
      </InputLabel>
      <Select
        disabled={loading}
        required={true}
        name="schedule"
        bordered
        className="w-full"
        size="sm"
        options={Object.values(NotificationScheduleType).map(
          (schedule: NotificationScheduleType) => ({
            value: schedule,
            label: NotificationScheduleLabels[schedule],
          })
        )}
      />

      <div className="flex justify-between mt-6">
        <Button
          type="reset"
          size="sm"
          className="btn-outline btn-error"
          disabled={loading}
          onClick={() => {
            close();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={loading}
          icon={loading ? "spinner pulse fw" : ""}
          size="sm"
          className="btn-primary"
        >
          Save
        </Button>
      </div>
    </form>
  );
}
