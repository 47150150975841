import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../lib/utils";

const badgeVariants = cva(
  "inline-flex items-center px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-xindigo-8 bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "text-foreground border-xslate-7 bg-white",
        success: "border-xgreen-8 bg-xgreen-3 text-xgreen-11 hover:bg-xgreen-3",
        primary:
          "border-xindigo-8 bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4",
        danger: "border-xred-8 bg-xred-3 text-xred-11 hover:bg-xred-4",
        slate: "border-xslate-8 bg-xslate-3 text-xslate-11 hover:bg-xslate-4",
      },
      side: {
        left: "rounded-l-full border",
        right: "rounded-r-full border-y border-r",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

type DoubleBadgeProps = {
  left: React.HTMLAttributes<HTMLDivElement> &
    Pick<VariantProps<typeof badgeVariants>, "variant">;
  right: React.HTMLAttributes<HTMLDivElement> &
    Pick<VariantProps<typeof badgeVariants>, "variant">;
};

function DoubleBadge({
  left: { variant, className, ...leftProps },
  right: { className: rightClassName, variant: variantRight, ...rightProps },
}: DoubleBadgeProps) {
  return (
    <div className="inline-flex">
      <div
        className={cn(
          badgeVariants({ variant: variant, side: "left" }),
          className
        )}
        {...leftProps}
      />
      <div
        className={cn(
          badgeVariants({ variant: variantRight, side: "right" }),
          rightClassName
        )}
        {...rightProps}
      />
    </div>
  );
}

export { DoubleBadge, badgeVariants };
