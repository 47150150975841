import {
  DEFAULT_FILL_OPACITY,
  DEFAULT_STROKE_OPACITY,
  LIGHT_FILL_OPACITY,
  LIGHT_STROKE_OPACITY,
} from "../utils/drawUtils";
import { Scale, SvgSelector } from "../types";
import * as d3 from "d3";
import { cn } from "../../../lib/utils";
import { DensityClass } from "../utils/chartUtils";

export const drawDensityChart = (
  svgSelector: SvgSelector,
  data: [number, number][],
  variableRef: string,
  variableColor: string,
  xScale: Scale,
  yScale: Scale,
  highlight: boolean
) => {
  /* Density line */
  svgSelector
    .append("g")
    .attr("transform", `translate(0, 0)`)
    .attr("class", cn("chart", DensityClass, variableRef))
    .attr("id", `${DensityClass}-${variableRef}`)
    .selectAll("areas")
    .data([data])
    .enter()
    .insert("path")
    .attr("vector-effect", "non-scaling-stroke")
    .datum((d) => d)
    .attr("stroke", "#666")
    .attr("stroke-width", 1.2)
    .attr(
      "stroke-opacity",
      highlight ? DEFAULT_STROKE_OPACITY : LIGHT_STROKE_OPACITY
    )
    .style("fill", variableColor)
    .style(
      "fill-opacity",
      highlight ? DEFAULT_FILL_OPACITY : LIGHT_FILL_OPACITY
    )
    .attr(
      "d",
      d3
        .line()
        .curve(d3.curveBasis)
        .x((d) => xScale(d[0]))
        .y((d) => yScale(d[1]))
    );
};
