import { create } from "zustand";
import { labelSchema } from "../lib/api-schema/label";
import { getLabels } from "../frameworks/fetcher/api-routes-experimental";
import * as R from "remeda";

type LabelStore = {
  labelsMap: Record<string, labelSchema> | undefined;
  init: () => void;
  ready: boolean;
};

export const useLabelStore = create<LabelStore>()((set) => {
  return {
    labelsMap: undefined,
    init: async () => {
      const labels = await getLabels();

      set({
        labelsMap: R.mapToObj(labels, (label) => [label._id, label]),
        ready: true,
      });
    },
    ready: false,
  };
});

export function getLabelById(id: string) {
  const { ready, labelsMap } = useLabelStore.getState();

  if (!ready) throw new Error("label store not initialized");

  const label = labelsMap?.[id];

  return label;
}

export function initLabelStore() {
  useLabelStore.getState().init();
}

export function getAllLabels() {
  const { ready, labelsMap } = useLabelStore.getState();

  if (!ready || !labelsMap) throw new Error("label store not initialized");

  return Object.values(labelsMap);
}
