import { useState } from "react";
import BarChart from "../../charts/BarChart";
import classNames from "classnames";
import Button from "../../common/Button/Button";
import { _7_30_90 } from "../../../frameworks/fetcher/api-routes-experimental";
import { operatingLimitSchema } from "../../../lib/api-schema/operating-limit";
import { useOperatingLimitsProbabilityQuery } from "../../../hooks/tanstack-query";
import { Loader2 } from "lucide-react";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import moment from "moment";
import { minutesToMilliseconds } from "date-fns";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";

const MODES = { 7: "7 days", 30: "30 days", 90: "90 days" } as const satisfies {
  [N in _7_30_90]: string;
};

export function PredictiveProbabilityCard(
  props: {
    limit: Pick<operatingLimitSchema, "level" | "_id">;
  } & {
    enabled?: boolean;
  }
) {
  const enabled = useAtomValue(Atoms.chartInViewportAtom);

  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();
  const probQuery = useOperatingLimitsProbabilityQuery(
    {
      id: props.limit._id,
      start,
      end,
    },
    { enabled, staleTime: minutesToMilliseconds(10) }
  );
  const [selectedNumDays, setSelectedNumDays] = useState<_7_30_90>(7);

  if (probQuery.isLoading)
    return (
      <div className="flex items-center justify-center min-h-[50px]">
        <Loader2 className="animate-spin-slow" />
      </div>
    );

  return (
    <>
      <div className="absolute flex btn-group top-[0.5em] right-[45%]">
        {Object.entries(MODES).map(([dayAsStr, value]) => {
          const isActive = dayAsStr === selectedNumDays.toString();
          return (
            <Button
              key={value}
              size="xs"
              className={classNames({
                "bg-base-100 text-neutral hover:bg-neutral hover:text-neutral-content":
                  !isActive,
                "bg-neutral text-neutral-content": isActive,
              })}
              onClick={() => setSelectedNumDays(parseInt(dayAsStr) as _7_30_90)}
            >
              {value}
            </Button>
          );
        })}
      </div>
      <BarChart
        selectedIndex={undefined}
        selectedRange={undefined}
        barColors={undefined}
        hiddenData={undefined}
        onclick={undefined}
        classes={{
          BarChart: "OperatingLimitsDetails__BarChart",
          BarChart__xAxis: "OperatingLimitsDetails__BarChart__xAxis",
        }}
        chartTitle={`Probability to Cross ${props.limit.level} in Next ${selectedNumDays} Days`}
        showTitle
        chartKeys={["Count"]}
        data={
          probQuery.data?.map((datum) => {
            return {
              key: moment(datum.date).format("YYYY-MM-DD"),
              value: (datum.smoothedProbability?.[selectedNumDays] ?? 0) * 100,
            };
          }) ?? []
        }
        range={[0, 100]}
        yAxisLabel={"Exceedances"}
        xAxisLabel={"Date"}
        unit={"%"}
      />
    </>
  );
}
