import { useState } from "react";
import OperatingModesCard from "./om-card";

import useHasEditPermission from "../../../zustand/useHasEditPermission";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { OperatingModeForm } from "./operating-mode-form";
import { useCreateOperatingModeMutation } from "./mutations";
import ErrorScreen from "../../common/error-screen";
import {
  useOperatingModesQuery,
  useUpdateOperatingModesQuery,
} from "./queries";

const PAGE_NAME = "Operating Modes";

function OperatingModesManager() {
  const [createMode, setCreateMode] = useState(false);

  const modesQuery = useOperatingModesQuery();
  const updateModesQuery = useUpdateOperatingModesQuery();

  const createMutation = useCreateOperatingModeMutation({
    onSuccess: (newPayload) => {
      setCreateMode(false);
      updateModesQuery((curr) => [newPayload, ...curr]);
    },
  });

  usePermissionBasedDocumentTitleForSettingsPage();
  const hasEditPermission = useHasEditPermission();

  const modesList = modesQuery.data;

  return (
    <>
      <ErrorScreen visible={modesQuery.isError} />
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditPermission && (
            <CreateNewEntityButton onClick={() => setCreateMode(true)} />
          )}
        </div>
        {modesQuery.data && (
          <div>
            <span className="mr-5 italic text-[1.25rem] text-titlegrey">
              Displaying {modesQuery.data.length} {PAGE_NAME}
            </span>
          </div>
        )}
      </div>
      {createMode && (
        <OperatingModeForm
          close={() => setCreateMode(false)}
          disabled={createMutation.isLoading}
          onSubmit={createMutation.mutate}
        />
      )}
      <div className="my-6 drop-shadow-md">
        {modesList ? (
          modesList.map((thisMode) => (
            <OperatingModesCard key={thisMode._id} mode={thisMode} />
          ))
        ) : (
          <div className="flex text-textgrey justify-center text-[1.5rem] mt-5">
            No {PAGE_NAME.toLowerCase()} to show
          </div>
        )}
      </div>
    </>
  );
}

export default OperatingModesManager;
