import { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { ClusterDriftScore } from "./cds";
import { ClusterCharts } from "./cluster-charts";
import { ClusterSidebar } from "./cluster-sidebar";
import DateSelectorsGroupSelect from "../dateSelector/DateSelectorsGroupSelect";
import {
  useAriaClustersQuery,
  useGroupsQuery,
} from "../../hooks/tanstack-query";
import { FullscreenProvider } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import Fullscreen from "./fullscreen";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../time-series/secondary-variable-view/control-buttons";
import { GlobalTooltip } from "../time-series/global-tooltip";

function Aria() {
  useDocumentTitle("ARIA > DRA");

  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;
  const clustersQuery = useAriaClustersQuery();
  const clusters = clustersQuery.data;

  // TEMPORARY -- for now we only want to show static clusters
  useEffect(() => {
    setSelectedClusters(
      (clusters || [])
        .filter((c) => c.type === "static" && c.aria_enabled)
        .map((c) => c._id)
    );
  }, [clusters]);

  return (
    <MainLayout
      showDateNav={true}
      dateStateNavChildren={
        <>
          {/* group dropdown */}
          <DateSelectorsGroupSelect
            action={(groupId: string) => {
              const groupObj = groups!.find((g) => g._id === groupId);
              if (!groupObj) {
                throw new Error("group not found in group select");
              }
              // if any groups variables are also in a cluster, we should show those clusters
              setSelectedClusters(
                (clusters || [])
                  .filter(
                    (c) =>
                      c.variables.some((vid) =>
                        groupObj.variables.includes(vid)
                      ) &&
                      c.aria_enabled &&
                      c.type === "static"
                  )
                  .map((c) => c._id)
              );
            }}
            options={(groups ?? []).map((g) => ({
              value: g._id,
              label: g.name,
            }))}
            value={undefined}
          />
          {/* cluster type dropdown (reusing DateSelectorsGroupSelect component for styling) */}
          {/* TEMPORARY -- we're only showing static clusters for now */}
          {/* <DateSelectorsGroupSelect
              action={(type: string) => {
                setSelectedClusters(
                  (clusters || [])
                    .filter((c) => type === "all" || c.type === type)
                    .map((c) => c._id)
                );
              }}
              options={[
                { value: "all", label: "All Clusters" },
                { value: "static", label: "Static Clusters" },
                { value: "dynamic", label: "Dynamic Clusters" },
              ]}
              value={undefined}
            /> */}
        </>
      }
    >
      {/* <DateStateNav /> */}
      <ClusterDriftScore selectedClusters={selectedClusters} className="m-4" />
      <div className="flex flex-row pr-4 gap-4">
        <ClusterSidebar selectedClusters={selectedClusters} />
        <ClusterCharts selectedClusters={selectedClusters} />
      </div>
    </MainLayout>
  );
}

export default function Wrapped() {
  return (
    <FullscreenProvider>
      <TimeseriesChartTooltipStoreProvider>
        {() => (
          <>
            <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
            <GlobalTooltip />
            <Fullscreen />
            <Aria />
          </>
        )}
      </TimeseriesChartTooltipStoreProvider>
    </FullscreenProvider>
  );
}
