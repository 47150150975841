import * as React from "react";
import { variableSchema } from "../../../lib/api-validators";
import { CommandGroup, CommandItem } from "../../ui/command";
import { LucideIcon, Plus } from "lucide-react";
import { IconType } from "react-icons";
import { PropsWithCn } from "../../types/component.types";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../shared-ui/frontend/dialog";
import { TagsMultiSelect } from "../../manager-components";
import { CustomRangePopover } from "../../dateSelector/custom-range-button/custom-range-button";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "../variability-drawer";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { Label } from "../../../shared-ui/frontend/label";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import * as LuxonFormatTokens from "../../../shared-ui/lib/luxon-format-tokens";

export function AddVariablesButtonWithDialog() {
  const useStore = useGetUseVariabilityDrawerStore();
  const [firstBucket] = useMin1Groups();
  const { start: firstBucketStart, end: firstBucketEnd } = firstBucket;

  const [open, setOpen_DONT_CALL_DIRECTLY] = React.useState(false);

  const [selectedVariableIds, setSelectedVariableIds] = React.useState<
    string[]
  >([]);

  const zone = useTimezone();

  const [dates, setDates] = React.useState<[DateTime, DateTime]>(() => [
    DateTime.fromMillis(firstBucketStart, { zone }),
    DateTime.fromMillis(firstBucketEnd, { zone }),
  ]);

  function handleDialogOpenChange(isOpen: boolean) {
    // reset it for next time they open
    setDates([
      DateTime.fromMillis(firstBucketStart, { zone }),
      DateTime.fromMillis(firstBucketEnd, { zone }),
    ]);
    setSelectedVariableIds([]);
    setOpen_DONT_CALL_DIRECTLY(isOpen);
  }

  return (
    <Dialog open={open} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger asChild>
        <Button variant={open ? "default" : "outline"} size="sm">
          <Plus className="h-4 w-4" />
          <span className="uppercase ml-1">add</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select tags and date range</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <TagsMultiSelect
            noPortal // very important since it's already in a dialog with a portal
            placeholder="Select at least one"
            value={selectedVariableIds}
            onChange={(ids) => setSelectedVariableIds(ids)}
          />

          <Label>Date range</Label>
          <CustomRangePopover
            withoutPortal
            value={{
              start: dates[0]
                .setZone("local", { keepLocalTime: true })
                .toJSDate(),
              end: dates[1]
                .setZone("local", { keepLocalTime: true })
                .toJSDate(),
            }}
            calendarPosition="top"
            onChange={({ start, end }) => {
              setDates([
                DateTime.fromJSDate(start).setZone(zone, {
                  keepLocalTime: true,
                }),
                DateTime.fromJSDate(end).setZone(zone, {
                  keepLocalTime: true,
                }),
              ]);
            }}
          >
            <Button
              variant="outline"
              size="default"
              className="border border-xslate-7"
            >
              <span className="">
                {dates[0].toFormat(LuxonFormatTokens.LONG)} -{" "}
                {dates[1].toFormat(LuxonFormatTokens.LONG)}
              </span>
            </Button>
          </CustomRangePopover>
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            size={"sm"}
            onClick={() => {
              if (!selectedVariableIds)
                return addUnknownErrorToast("Select at least one variable");

              const err = useStore
                .getState()
                .addVariables(selectedVariableIds, [
                  dates[0].toMillis(),
                  dates[1].toMillis(),
                ]);
              if (err) return addUnknownErrorToast(err);
              handleDialogOpenChange(false);
            }}
          >
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function GroupOfVariables({
  icon: Icon,
  variables,
  heading,
  onSelect,
  className,
}: {
  variables: variableSchema[];
  icon?: LucideIcon | IconType;
  onSelect?: (variable: variableSchema) => void;
} & Pick<React.ComponentProps<typeof CommandGroup>, "heading"> &
  PropsWithCn) {
  const icon = Icon ? (
    <Icon className="h-4 w-4 shrink-0" />
  ) : (
    <div className="h-4 w-4 shrink-0" />
  );

  return (
    <CommandGroup heading={heading}>
      {variables.map((variable) => {
        const vid = variable._id;
        const nameWithDesc = variable.nameWithDescription;

        return (
          <CommandItem
            className={className}
            key={vid}
            value={nameWithDesc.toLowerCase()} // this needs to be the value for searching purposes internally
            onSelect={onSelect && (() => onSelect(variable))}
          >
            <div className="flex gap-2 items-center">
              {icon}
              <span className="break-all">{nameWithDesc}</span>
            </div>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
}
