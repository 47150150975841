import "./Input.scss";

import React from "react";
import MultiSelector, { components } from "react-select";
import stylable from "../utils/stylable";
import ReactEnhanced from "../../frameworks/ReactEnhanced";
import iconMapper from "../../lib/iconMapper";
import { MultiSelect } from "@mantine/core";
import _ from "lodash";

/*
 * Handle persistant unique IDs across all instances of the component.
 * Most of our components don't need this, but labels require the use of an id.
 * This is really the only state we need for inputs, but it's reasonably necessary.
 */
const id = (function () {
  let base = 0;
  return () => base++;
})();

class InputOld extends ReactEnhanced.Component {
  static defaultProps = {
    labelPosition: "auto",
  };

  constructor(props) {
    super(props);
    this.pure = true;
    this.id = props.id || `Input-${id()}`;
    this.effects = [];
    this.state = {};

    /* For search */
    this.state.searchTerm = "";
    this.state.results = null;
    this.state.expanded = false;
  }

  focus() {
    document.getElementById(this.id).focus();
  }

  /* Pseudo-polyfill for upcoming React useEffect method */
  useEffect(method) {
    this.effects.push(method);
  }

  componentDidMount() {
    /* Fix for an iOS/Mac specific bug where clicking on a button does not set focus correctly.
     * Firefox/Safari on Mac treat button presses as if they are basically blur events. */
    const input = document.getElementById(this.id);
    if (input && input.nodeName === "BUTTON") {
      input.addEventListener("mousedown", function () {
        /* Wait for the event to finish synchronously bubbling up the entire stack. */
        /* Firefox/Safari set the focus as part of the default browser behavior that happens here. */
        setTimeout(() => {
          input.focus();
        }, 0);
      });
    }

    this.effects.forEach((effect) => effect());
  }

  componentDidUpdate(prevProps) {
    this.effects.forEach((effect) => effect(prevProps));
  }

  render() {
    const that = this;
    const props = that.props;

    //TODO: Some kind of validation?
    const {
      label,
      hint,
      style,
      href,
      tooltip,
      labelPosition,
      bind,
      hideLabelBreakpoint,
      password,
      eventListeners,
      uppercase,
      active,
      onFocus,
      reactIcon,
      print,
    } = props;

    /* Quick toggle that switches some inputs into a disabled "loading" state. */
    const processing = props.processing;

    // NOTE FOR BRYAN: icon can be Edit or Delete or others
    const icon = (() => {
      /* if in processing, show loading spinner. */
      if (processing) {
        return "spinner pulse fw";
      }

      /* Icon can not be added unless label is also present. */
      if (!label && props.icon) {
        console.warn(
          "`Input` elements will not accept an icon unless a `label` is also present (used for hovers and general accessibility)"
        );
      }

      return label ? props.icon : "";
    })();

    /*@TODO Implement: label can not be hidden unless icon is visible. */
    /* @TODO DanShumway -- check in with Tim about why this is allowed, should be: */
    /* `hideLabel = icon && props.hideLabel` */
    const hideLabel = props.hideLabel;

    const disabled = (() => {
      if (processing) {
        return true;
      }
      return _.isFunction(props.disabled) ? props.disabled() : !!props.disabled;
    })();

    const id = this.id;
    const selectable = props.selectable == null ? true : !!props.selectable;
    const classes = props.classes || {};
    const bordered = props.bordered;
    const value = props.bind ? props.bind.value : props.value;

    const action = (() => {
      const action = props.bind ? props.bind.setValue : props.action;
      return action || (() => {});
    })();

    const input = (function (type) {
      switch (type) {
        case "group":
          return (
            <React.Fragment>
              {/* HTML standards say that labels should only be used directly for selectable inputs.
               * Groups don't qualify for that definition. */}
              <div className="Input__label Input__label--header">{label}</div>
              <div className="Input__group">{that.props.children}</div>
            </React.Fragment>
          );

        case "select":
          let resize = (target) => {
            if (!props.autoSize) {
              return;
            }

            const select = target;
            const option = select.querySelector(
              `option[value="${select.value}"]`
            );

            const template = document.createElement("span");
            template.textContent = option.textContent;

            template.style.padding = "0.5em"; //most browsers incorrectly report back padding for options.
            template.style.fontSize = option.style.fontSize;
            template.style.visibility = "hidden"; //prevent accidental flash of render.

            select.parentElement.appendChild(template);
            const bounds = template.getBoundingClientRect();

            select.style.width = `${bounds.right - bounds.left}px`;
            select.parentElement.removeChild(template);
          };

          that.useEffect((prevProps) => {
            if (
              (prevProps && !prevProps.bind && prevProps.value === value) ||
              (prevProps && prevProps.bind && prevProps.bind.value === value)
            ) {
              return;
            } //Don't recalculate if nothing has changed.
            const select = document.getElementById(id);
            resize(select);
          });

          return (
            <React.Fragment>
              {label && (
                <label
                  htmlFor={id}
                  className={`Input__label Input__label--header ${
                    hideLabel && "Input__label--hidden"
                  } ${classes.Input__label}`}
                >
                  {label}
                </label>
              )}

              <select
                className={
                  "select font-normal " +
                  (props.autoSize ? "Input--type-select-autosize " : "") +
                  classes.Input +
                  (bordered ? " select-bordered" : "") +
                  (props.size ? ` ${props.size}` : " select-sm")
                }
                style={style}
                multiple={props.multiple ?? false}
                disabled={disabled}
                onChange={(evt) => {
                  action(evt.target.value);
                  resize(evt.target);
                }}
                name={props.name}
                value={value ? value : undefined}
                id={id}
                ref={props.refId || undefined}
              >
                {props.options.map((option) => (
                  <option
                    disabled={option.disabled ?? false}
                    value={option.value}
                    key={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </React.Fragment>
          );

        case "textarea":
          /* TODO: allow auto-expand */
          return (
            <React.Fragment>
              {label && !hideLabel && (
                <label
                  htmlFor={id}
                  className={`Input__label Input__label--header ${classes.Input__label}`}
                >
                  {label}
                </label>
              )}
              <textarea
                className={"Input !resize-y " + classes.Input}
                defaultValue={props.defaultValue}
                ref={props.refId || undefined}
                {...(eventListeners || {})}
                style={style}
                id={id}
                rows="3"
                placeholder={hint}
                disabled={disabled}
                value={value || ""}
                name={props.name}
                onChange={(evt) => {
                  /* @TODO danshumway - check in with Tim, this should be optional behavior. */
                  /* We should not be overriding styles inline by default. */
                  /* Also, use the style computation above, it's dangerous to directly modify the DOM. */
                  // adjust height of textarea to fit content
                  evt.target.style.height = "auto";
                  evt.target.style.height = evt.target.scrollHeight + "px";
                  action(evt.target.value);
                }}
              />
            </React.Fragment>
          );

        case "button":
          throw new Error("deprecated");
        case "submit":
          throw new Error("deprecated");
        case "cancel":
          throw new Error("deprecated");
        case "alternateButton":
          throw new Error("deprecated");
        case "link":
          throw new Error("deprecated");
        case "dangerButton":
          throw new Error("deprecated");

        case "multipleselect":
          let fixedValue = ((values) => {
            let isArray = _.isArray(values);
            values = isArray ? values : [values];

            values = _.reduce(
              values,
              (arr, value) => {
                if (
                  _.isObject(value) &&
                  value.value !== undefined &&
                  _.isString(value.label)
                ) {
                  arr.push(value);
                } else {
                  const fallback = _.find(
                    props.options,
                    (option) => option.value === value
                  );
                  fallback && arr.push(fallback);
                }

                return arr;
              },
              []
            );

            return values;
          })(value);

          // console.log("fixed value", fixedValue);
          const MultiValueRemove = (props) => {
            if (props.data.isFixed) {
              return null;
            }
            return <components.MultiValueRemove {...props} />;
          };

          return (
            <React.Fragment>
              {label && (
                <div
                  className={stylable(
                    classes,
                    `Input__labelContainer Input__labelContainer--type-multipleselect`
                  )}
                >
                  {icon && (
                    <i
                      className={`${iconMapper(icon)} Input__icon ${
                        classes.Input__icon || ""
                      }`}
                      aria-hidden="true"
                    />
                  )}

                  <label
                    htmlFor={id}
                    className={`Input__label ${
                      hideLabel
                        ? "Input__label--hidden"
                        : "Input__label--header"
                    } ${classes.Input__label}`}
                  >
                    {label}

                    {tooltip && (
                      <InputOld
                        type="info"
                        classes={{
                          Input: `Input__Multipleselect__tooltip ${
                            classes.Input__Multipleselect || ""
                          }`,
                          Input__label: `Input__label--tooltip Input__Multipleselect__tooltip__label ${
                            classes["Input__Multipleselect__tooltip__label"] ||
                            ""
                          }`,
                        }}
                        label={tooltip}
                      />
                    )}
                  </label>
                </div>
              )}
              {props.isMulti === false ? (
                <MultiSelector
                  id={id}
                  className={stylable(
                    classes,
                    `border-[#d8d4d4] Input Input--type-multipleselect ${
                      disabled && "Input--disabled"
                    } ${icon && hideLabel && "Input--inline"}`
                  )}
                  isMulti={props.isMulti != null ? props.isMulti : true}
                  isDisabled={disabled}
                  options={props.options}
                  classNamePrefix="react-select"
                  value={fixedValue || []}
                  components={{ MultiValueRemove }}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={
                    props.isMulti != null ? !props.isMulti : false
                  }
                  onChange={(value) => {
                    if (props.isMulti == null || !!props.isMulti) {
                      value = value || [];
                      value = _.isArray(value) ? value : [value];
                    }

                    /* Support ignoring the whole object thing */
                    if (props.returnOnlyValues) {
                      value = _.isArray(value)
                        ? _.map(value, (value) => value.value)
                        : value.value;
                    }

                    action(value);
                  }}
                  styles={{
                    // container: (base, { data }) => {
                    //   return {
                    //     ...base,
                    //     zIndex: 15
                    //   }
                    // },
                    menuPortal: (base, { data }) => {
                      return {
                        ...base,
                        // zIndex: 1093
                      };
                    },
                    menuList: (base, { data }) => {
                      return {
                        ...base,
                        zIndex: 150,
                      };
                    },
                    menu: (base, { data }) => {
                      return {
                        ...base,
                        zIndex: 150,
                      };
                    },
                    multiValue: (base, { data }) => {
                      return {
                        ...base,
                        backgroundColor: data.color || "#ccc",
                        // zIndex: 15
                      };
                    },
                    option: (base, { data }) => {
                      return {
                        ...base,
                        color: data.color,
                        // zIndex: 30
                      };
                    },
                    multiValueLabel: (base, { data }) => {
                      return {
                        ...base,
                        color: data.color === undefined ? "black" : "white",
                      };
                    },
                  }}
                />
              ) : (
                <MultiSelect
                  maxDropdownHeight={370}
                  id={id}
                  itemComponent={props.itemComponent || undefined}
                  valueComponent={props.valueComponent || undefined}
                  dropdownPosition="flip"
                  classNames={{
                    input: "rounded-md p-[0.2em]",
                    value: !props.valueComponent ? "bg-zinc-300" : "",
                    dropdown: "border-2 border-bordgrey2 mb-24",
                  }}
                  data={props.options}
                  placeholder="Select"
                  maxSelectedValues={props.isMulti ? 1 : undefined}
                  disabled={disabled}
                  value={(fixedValue || []).map((o) => o.value)}
                  clearable={false}
                  creatable={false}
                  searchable={true}
                  onChange={(value) => {
                    if (props.returnOnlyValues) {
                      return action(value);
                    }

                    const out = _.map(value, (s) =>
                      _.find(props.options, (o) => o.value == s)
                    );

                    action(out);
                  }}
                />
              )}
            </React.Fragment>
          );

        case "info":
          return (
            <span
              className={`Input Input--type-info ${classes.Input || ""}`}
              tabIndex={selectable ? 0 : undefined}
            >
              <i
                className={`${iconMapper(icon || "info-circle")} Input__icon ${
                  classes.Input__icon || ""
                }`}
                aria-hidden="true"
              />

              <span
                className={stylable(
                  classes,
                  `Input__label Input__label--hidden`
                )}
              >
                {label}
              </span>
            </span>
          );

        case "checkbox":
        case "fancycheckbox":
          let labelDOM = (
            <label
              htmlFor={id}
              className={`Input__label Input__label--inline ${
                (hideLabel && "Input__label--hidden") || ""
              } ${classes.Input__label || ""}`}
            >
              {label}
            </label>
          );

          return (
            <div className={`${classes.Container || ""}`}>
              {labelPosition === "before" ? labelDOM : null}

              <input
                id={id}
                type="checkbox"
                disabled={disabled}
                name={props.name}
                checked={value}
                className={`Input ${
                  type === "fancycheckbox"
                    ? "Input--type-fancycheckbox"
                    : "Input--type-checkbox"
                } ${classes.Input || ""}`}
                onChange={(evt) => action(evt.target.checked)}
              />

              {labelPosition !== "before" ? labelDOM : null}
            </div>
          );

        case "search":
          let onSearch = _.throttle(async (searchTerm) => {
            let searchFn = props.search || (() => {});
            let results = searchTerm !== "" ? await searchFn(searchTerm) : [];
            /* load results, but only if nothing has changed in the meantime. */
            if (searchTerm === that.state.searchTerm) {
              that.setState({ results: results });
            }
          }, 500);

          let buildResult =
            props.buildResult ||
            ((result, props) => (
              <button
                className={
                  "Input__searchResult__element" +
                  (classes.Input__searchResult__element || "")
                }
                onClick={() => {
                  props.action(result);
                }}
              >
                {result.name}
              </button>
            ));

          let visibleClass = that.state.expanded
            ? "Input__searchResults--visible "
            : "";

          return (
            <React.Fragment>
              {label && (
                <label
                  htmlFor={id}
                  className={`Input__label Input__label--header ${classes.Input__label}`}
                >
                  {label}
                </label>
              )}

              <div
                className={
                  "Input__container " + (classes.Input__container || "")
                }
                onBlur={(e) => {
                  if (
                    !e.currentTarget.contains(
                      e.relatedTarget || document.activeElement
                    )
                  ) {
                    that.setState({ expanded: false });
                  }
                }}
                onFocus={() => that.setState({ expanded: true })}
              >
                <input
                  className={
                    "input " +
                    classes.Input +
                    (bordered === false ? "" : " input-bordered") +
                    (props.size ? ` ${props.size}` : "")
                  }
                  style={style}
                  id={id}
                  type="text"
                  placeholder={hint || ""}
                  disabled={disabled}
                  value={that.state.searchTerm || ""}
                  onChange={(evt) => {
                    that.setState(
                      {
                        searchTerm: evt.target.value,
                      },
                      () => onSearch(that.state.searchTerm)
                    );
                  }}
                />

                {that.state.searchTerm && that.state.expanded && (
                  <span tabIndex={-1}>
                    {/* Wrapper class to deal with a truly stupid Firefox behavior
                     * on Mac. If I get more energy I'll write more details here,
                     * but you can search for Firefox losing focus when buttons
                     * are clicked on Mac in the meantime if you're curious. */}
                    <button
                      className={
                        "Input__searchClose " +
                        (classes.Input__searchClose || "")
                      }
                      tabIndex={-1}
                      onClick={() =>
                        that.setState({
                          expanded: false,
                          searchTerm: "",
                        })
                      }
                    >
                      <i className="fa fa-close" aria-hidden="true"></i>
                      <span className="Input__searchClose__label">
                        Close Search
                      </span>
                    </button>
                  </span>
                )}

                {that.state.results && (
                  <ul
                    className={
                      "Input__searchResults " +
                      visibleClass +
                      (classes.Input__searchResults || "")
                    }
                  >
                    {_.map(that.state.results, (result) => (
                      <li
                        className={
                          "Input__searchResult " +
                          (classes.Input__searchResult || "")
                        }
                        tabIndex={-1}
                        key={result._id}
                      >
                        {buildResult(result, props)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </React.Fragment>
          );
        // case 'calendar':
        //   return (
        //     <div className={`${classes.container}`}>
        //       {label && <label htmlFor={id} className={`Input__label Input__label--header mb-3 ml-1 ${classes.Input__label}`}>{label}</label>}
        //         <CalenderSelector
        //           dateFormat={props.dateFormat}
        //           disabledDays={props.disabledDays}
        //           onCalenderDateChange={(value) => action(value)}
        //           selectedDate={value}
        //         />
        //     </div>
        //   )
        case "inlinetext":
        default: // case 'text':
          let labelStyle = type === "inlinetext" ? "" : "Input__label--header";

          return (
            <React.Fragment>
              {label && (
                <label
                  htmlFor={id}
                  className={`Input__label ${labelStyle} ${classes.Input__label}`}
                >
                  {label}
                </label>
              )}
              <input
                className={
                  "input " +
                  classes.Input +
                  (bordered === false ? "" : " input-bordered") +
                  (props.size ? ` ${props.size}` : "")
                }
                style={style}
                id={id}
                type={password === true ? "password" : "text"}
                placeholder={hint || ""}
                disabled={disabled}
                defaultValue={props.defaultValue}
                required={props.required || false}
                pattern={props.pattern || undefined}
                title={props.title || undefined}
                minLength={props.minlength || 0}
                maxLength={props.maxlength || undefined}
                value={value || ""}
                ref={props.refId || undefined}
                // onKeyUp={(evt) => action(evt.target)} />
                {...(eventListeners || {})}
                onChange={(evt) => action(evt.target.value)}
                onFocus={onFocus}
              />
            </React.Fragment>
          );
      }
    })(props.type);

    return <React.Fragment key={props.label}>{input}</React.Fragment>;
  }
}

export default InputOld;
