import { Check, Plus } from "lucide-react";
import {
  PersonalFoldersQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { Button } from "../../../shared-ui/frontend/button";
import { Input } from "../../../shared-ui/frontend/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import * as HeaderComponents from "../../../shared-ui/time-series-2/secondary-variable-view/header";
import { VariableInfoTooltip } from "../../charts/DTC/variable-info-tooltip";
import { WatchlistFlagButton } from "../../charts/DTC/watchlist-flag-button";
import { useMutation } from "@tanstack/react-query";
import { postFolder } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { memo, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  Atoms,
  ChartVariant,
  isVariableTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  cn,
  PropsWithCnAndChildren,
  type PropsWithCn,
} from "../../../shared-ui/frontend/cn";
import { FaChartLine } from "react-icons/fa";
import { useExitSlopingTrends } from "./bottom-controls";
import { Badge } from "../../../shared-ui/frontend/badge";
import { VariabilityStatisticsPopover } from "./control-buttons";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { useGetUseProfileBookStoreNotRequired } from "../../pb/use-profile-book-store";

const numExpressionTrendLinesAtom = atom(
  (get) => get(Atoms.onlyExpressionTrendLinesAtom).length
);

const numVariableTrendLinesAtom = atom(
  (get) => get(Atoms.onlyVariableTrendLinesAtom).length
);

const Header = memo(function Header({
  onClose,
  expandable,
}: {
  onClose?: () => void;
  expandable?: boolean;
}) {
  const numExprs = useAtomValue(numExpressionTrendLinesAtom);
  const numVars = useAtomValue(numVariableTrendLinesAtom);
  const totalTrendLines = numExprs + numVars;

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  const variablesQuery = useVariablesArrayQuery();

  const primaryVariableObj = useMemo(() => {
    if (primaryTrendLine.type !== "variable") return;
    if (!variablesQuery.data) return;

    return variablesQuery.data.find(
      (x) => x._id === primaryTrendLine.bv.slice(24)
    );
  }, [variablesQuery.data, primaryTrendLine]);

  // kind of a hack to see if we're on ARIA page
  const isARIA = useAtomValue(Atoms.chartVariantAtom) === ChartVariant.Aria;

  const daDisableAtom = useAtomValue(Atoms.daBarsAtomAtom);

  const showExpandButton = expandable && totalTrendLines === 1;

  return (
    <HeaderComponents.Container className="gap-0 @lg:gap-2">
      <WatchlistButton className="hidden @4xl:inline-flex" />
      <HeaderComponents.SidebarButton />
      {primaryVariableObj && (
        <HeaderComponents.SingleVariableTitle
          name={primaryVariableObj.trimmedName}
          description={primaryVariableObj.description}
          uom={primaryVariableObj.units_of_measurement ?? undefined}
        />
      )}
      {primaryTrendLine.type === "variable" && totalTrendLines === 1 ? (
        <>
          <VariableInfoTooltip
            variableId={primaryTrendLine.bv.slice(24)}
            className="hidden @4xl:inline-flex"
          />
          <VariabilityStatisticsPopover
            variableId={primaryTrendLine.bv.slice(24)}
            color={primaryTrendLine.color}
            className="hidden @4xl:inline-flex"
          />
        </>
      ) : (
        !isARIA &&
        numVars > 0 && (
          <Badge className="select-none" variant={"secondary"}>
            {numVars} Tags
          </Badge>
        )
      )}

      {showExpandButton && (
        <HeaderComponents.ExpandButton className="hidden @4xl:inline-flex" />
      )}
      <div className="inline-flex ml-auto gap-2 items-center">
        <LowHighSideLabel className={"@lg:mr-3 text-xs @lg:text-sm"} />
        <CloseSlopingTrendsButton />
        <HeaderComponents.YAxisModeTabs />
        {daDisableAtom && (
          <>
            <DaPatchesToggleButton>
              {isARIA ? "Drift Patches" : "Anomaly Patches"}
            </DaPatchesToggleButton>
            {!isARIA && <DaBarsToggleButton />}
          </>
        )}
        {/* {!isARIA && <SaveMultiviewButton />} */}
        <HeaderComponents.SpotlightToggle />
        <HeaderComponents.FullscreenButton />
        {onClose && <HeaderComponents.CloseButton onClick={onClose} />}
      </div>
    </HeaderComponents.Container>
  );
});

function CloseSlopingTrendsButton({ className }: PropsWithCn) {
  const isSlopingTrendsView = useAtomValue(Atoms.slopingTrendsAtom);
  const exit = useExitSlopingTrends();

  if (!isSlopingTrendsView) return;

  return (
    <Button
      variant={"outline"}
      size={"xxs"}
      onClick={exit}
      className={cn("hidden @4xl:inline-flex", className)}
    >
      <FaChartLine className="size-3 mr-2" />
      Close Sloping Trends
    </Button>
  );
}

function LowHighSideLabel({ className }: PropsWithCn) {
  const isSingleView = useAtomValue(Atoms.singleVariableViewAtom);
  const isSlopingTrendsView = useAtomValue(Atoms.slopingTrendsAtom);
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const variablesQuery = useVariablesArrayQuery();

  if (!isSingleView || !variablesQuery.data) return;

  if (primaryTrendLine.type !== "variable") return;

  const v = variablesQuery.data.find(
    (x) => x._id === primaryTrendLine.bv.slice(24)
  );

  if (!v) return;

  const getLabel = (label: string) => {
    return (
      <span className={cn("text-zinc-700 text-sm font-light", className)}>
        {label}{" "}
        <span className="text-red-600 uppercase font-semibold">OFF</span>
      </span>
    );
  };

  /**
   * If we're in sloping trends view, try to show the sloping trend label
   * first, if one exists.
   */
  if (isSlopingTrendsView) {
    const someSlopeIsOff = v.hide_negative_slopes || v.hide_positive_slopes;

    if (someSlopeIsOff) {
      const slopeLabel =
        [
          v.hide_positive_slopes && "Positive",
          v.hide_negative_slopes && "Negative",
        ]
          .filter(Boolean)
          .join(" & ") + " Slope:";

      return getLabel(slopeLabel);
    }
  }

  // Only show a label relating to anomalies if anomaly toggle is on
  const shouldShowAnomalyLabelIfTheyExist = primaryTrendLine.anom;
  if (!shouldShowAnomalyLabelIfTheyExist) return;

  /**
   * Even if we're in sloping trend view, but we didn't find any sloping
   * labels, we can still show the low/high side labels.
   */
  const someAnomalyIsOff = !v.low_side || !v.high_side;
  const nothingIsOff = !someAnomalyIsOff; // nothing to show
  if (nothingIsOff) return;

  const anomalyLabel =
    [!v.low_side && "Low-Side", !v.high_side && "High-Side"]
      .filter(Boolean)
      .join(" & ") + " Anomaly:";

  return getLabel(anomalyLabel);
}

function WatchlistButton({ className }: PropsWithCn) {
  const single = useAtomValue(Atoms.singleVariableViewAtom);

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const variables = useVariablesArrayQuery().data;

  if (!single || primaryTrendLine.type !== "variable") return;

  const variable = variables?.find(
    (x) => x._id === primaryTrendLine.bv.slice(24)
  );

  return (
    variable && (
      <WatchlistFlagButton variable={variable} className={className} />
    )
  );
}

function DaBarsToggleButton() {
  const daBarsAtom = useAtomValue(Atoms.daBarsAtomAtom);

  if (!daBarsAtom) throw new Error("only render when this is defined");
  const hasAtLeastOneVariableTrendLine = useAtomValue(
    Atoms.atLeastOneVariableTrendLineAtom
  );

  const [showDaBars, setShowDaBars] = useAtom(daBarsAtom);

  if (!hasAtLeastOneVariableTrendLine) return;

  return (
    <Button
      onClick={() => setShowDaBars((curr) => !curr)}
      variant={!showDaBars ? "outline" : "default"}
      size={"xxs"}
      className="hidden @4xl:inline-flex"
    >
      {showDaBars && <Check className="mr-1 size-3" />}
      Anomaly Levels
    </Button>
  );
}

const someVariableHasAnomAtom = atom((get) => {
  return get(Atoms.onlyVariableTrendLinesAtom).some((x) => x.anom);
});

function DaPatchesToggleButton({
  className,
  children,
}: PropsWithCnAndChildren) {
  const daBarsAtom = useAtomValue(Atoms.daBarsAtomAtom);
  if (!daBarsAtom) throw new Error("only render when this is defined");

  const hasAtLeastOneVariableTrendLine = useAtomValue(
    Atoms.atLeastOneVariableTrendLineAtom
  );

  const isAnomalyColoration = useAtomValue(someVariableHasAnomAtom);
  const setAllAnomalyColoration = useSetAtom(Atoms.setAllAnomalyColorationAtom);

  if (!hasAtLeastOneVariableTrendLine) return;
  return (
    <Button
      onClick={() => setAllAnomalyColoration(!isAnomalyColoration)}
      variant={!isAnomalyColoration ? "outline" : "default"}
      size={"xxs"}
      className={cn("hidden @4xl:inline-flex", className)}
    >
      {isAnomalyColoration && <Check className="mr-1 size-3" />}
      {children}
    </Button>
  );
}

function _SaveMultiviewButton() {
  const usePbStoreMaybe = useGetUseProfileBookStoreNotRequired();
  const { register, handleSubmit, reset } = useForm<{ name: string }>({
    shouldUseNativeValidation: true,
  });
  const [open, setOpen] = useState(false);
  const isMulti = !useAtomValue(Atoms.singleVariableViewAtom);
  const vs = useAtomValue(Atoms.selectedVariablesAtom);

  const baseUrl = useBaseUrlExperimental();

  const invalidateFoldersQuery = PersonalFoldersQuery.useInvalidate();

  const mut = useMutation({
    mutationFn: (name: string) => {
      return postFolder(baseUrl, {
        name,
        variables: [
          assertMinLen1(
            vs.filter(isVariableTrendLine).map((x) => x.bv.slice(24))
          ),
        ],
      });
    },
    onSuccess: ({ name, _id }) => {
      invalidateFoldersQuery(); // new data will be fetched

      reset();

      setOpen(false);

      const pbStateMaybe = usePbStoreMaybe?.getState();

      if (pbStateMaybe) {
        pbStateMaybe.setTitle({
          type: "personal-folders",
          _id,
        });
      }
      addSuccessToast(`${name} saved`);
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });

  if (!isMulti) return;

  const onSubmit = handleSubmit((data) => {
    const name = data.name.trim();

    if (!name)
      return addToast({
        title: "Name is required",
        variant: "danger",
      });
    mut.mutate(data.name);
  });

  return (
    <Popover
      open={open}
      onOpenChange={(x) => {
        setOpen(x);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          size={"xxs"}
          className="hidden @4xl:inline-flex"
        >
          <Plus className="mr-1 size-3" />
          Save Multiview
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-transparent p-0 border-0">
        <form className="flex" onSubmit={onSubmit}>
          <Input
            {...register("name", {
              required: "Name is required",
              minLength: 1,
            })}
            disabled={mut.isLoading}
            className="rounded-r-none focus-visible:ring-0 focus-visible:ring-offset-0"
            placeholder="Enter a name"
          />
          <Button
            type="submit"
            disabled={mut.isLoading}
            variant={"default"}
            size={"default"}
            className="rounded-l-none"
          >
            <Plus className="size-5" />
          </Button>
        </form>
      </PopoverContent>
    </Popover>
  );
}

export { Header };
