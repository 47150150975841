import { getAPIUrl } from "../api-url";

async function handleRes(res) {
  const newRes = {
    headers: res.headers,
    ok: res.ok,
    redirected: res.redirected,
    status: res.status,
    statusText: res.statusText,
    type: res.type,
    url: res.url,
  };
  const resContentType = res.headers.get("Content-Type");

  if (resContentType == null) newRes.body = "";
  else if (resContentType.includes("application/json"))
    newRes.body = await res.json();
  else if (resContentType.includes("text")) newRes.body = await res.text();
  else if (resContentType.includes("multipart/form-data"))
    newRes.body = await res.formData();
  else newRes.body = await res.blob();
  if (!newRes.ok) throw newRes;
  newRes.data = newRes.body;
  return newRes;
}

class Auth {
  constructor() {
    this.user = {
      currentUnit: null,
      units: null,
      role: null,
      _id: null,
      hasEditPermission: false,
    };

    this.requestNew = false;

    this.APIServer = getAPIUrl();

    //Actual requests
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.patch = this.patch.bind(this);
    this.delete = this.delete.bind(this);

    // auth stuff
    this.authenticate = this.authenticate.bind(this);
    this.reAuthenticate = this.reAuthenticate.bind(this);
    this.updateAuth = this.updateAuth.bind(this);
    this.unAuthenticate = this.unAuthenticate.bind(this);
    // user stuff
    this.initializeUser = this.initializeUser.bind(this);
    this.changeUnit = this.changeUnit.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }

  async get(path, customHeaders = {}) {
    await this.updateAuth();

    const headers = {
      "Cache-Control": "no-cache, must-revalidate",
      Pragma: "no-cache",

      "content-type": "application/json; charset=utf-8",
      accept: "application/json",
    };

    const payloadHeaders = Object.assign(headers, customHeaders);

    const res = await fetch(`${this.APIServer}${path}`, {
      method: "GET",
      cache: "reload",
      headers: new Headers(payloadHeaders),
      credentials: "include",
    });

    const data = handleRes(await res);
    return data;
  }

  async post(path, params = null, noPrefix = false) {
    await this.updateAuth();

    const headerObj = {
      "Cache-Control": "no-cache, must-revalidate",
      Pragma: "no-cache",

      "content-type": "application/json; charset=utf-8",
      accept: "application/json",
    };

    var payload = {
      method: "POST",
      cache: "reload",
      headers: new Headers(headerObj),
      credentials: "include",
    };

    if (params != null) payload.body = JSON.stringify(params);
    // const url = noPrefix ? this.APIServer.replace("/api", "") :
    const res = await fetch(`${this.APIServer}${path}`, payload);

    const data = handleRes(await res);
    return data;
  }

  async patch(path, params = null) {
    await this.updateAuth();

    var payload = {
      method: "PATCH",
      cache: "reload",
      headers: new Headers({
        "Cache-Control": "no-cache, must-revalidate",
        Pragma: "no-cache",

        "content-type": "application/json; charset=utf-8",
        accept: "application/json",
      }),
      credentials: "include",
    };

    if (params != null) payload.body = JSON.stringify(params);
    const res = await fetch(`${this.APIServer}${path}`, payload);

    const data = handleRes(await res);
    return data;
  }

  async delete(path, params = null) {
    await this.updateAuth();

    var payload = {
      method: "DELETE",
      cache: "reload",
      headers: new Headers({
        "Cache-Control": "no-cache, must-revalidate",
        Pragma: "no-cache",

        "content-type": "application/json; charset=utf-8",
        accept: "application/json",
      }),
      credentials: "include",
    };

    if (params != null) payload.body = JSON.stringify(params);
    const res = await fetch(`${this.APIServer}${path}`, payload);

    const data = handleRes(await res);
    return data;
  }

  // authentication
  // -----------------------

  async updateAuth() {}

  isAuthenticated() {
    return false;
  }

  async authenticate(email, password) {}

  // TODO, fix
  async reAuthenticate() {}

  setAuthData(token) {}

  unAuthenticate() {}

  /**
   * User Info
   *
   * Data is the JWT payload returned by the API
   */
  initializeUser(data) {
    this.user.currentUnit = (() => {
      /* Try to use the unit in the URL if possible
       * TODO: get login working with this. */
      const pathParts = window.location.pathname
        .split("/")
        .filter((s) => !!s.trim());
      const potentialUnit = pathParts[0];
      if (data.units.includes(potentialUnit)) {
        return potentialUnit;
      }

      /* Fall back on what the user has set. */
      if (data.units.includes(this.user.currentUnit)) {
        console.warn(
          [
            "%c-----NMM------",
            "`currentUnit` should be set using the URL. This is deprecated. See `API.js` `initializeUser`",
            "--------------",
          ].join("\n"),
          "color:red;font-weight:bold;font-size:1.5em;"
        );
        return this.user.currentUnit;
      }

      /* Explain what is happening: */
      console.warn(
        [
          "%c-----NMM------",
          "User unit is not set and/or URL unit is not attached to user. See `API.js` `initializeUser`",
          "--------------",
        ].join("\n"),
        "color:red;font-weight:bold;font-size:1.5em;"
      );

      // Return the first unit in the JWT payload by default
      return data.units[0];
    })();

    this.user.units = data.units;
    this.user.role = data.role;
    this.user.hasEditPermission = data.role === "Admin" || data.role === "Root";
    this.user._id = data.id;
    this.user.first = "N";
    this.user.last = "A";

    const userInfo = this.getUserData();
    userInfo
      .then(
        function (data) {
          this.user.first = data.body.first;
          this.user.last = data.body.last;
          this.user.email = data.body.email;
        }.bind(this)
      )
      .catch((err) => {
        console.log({ err });
      });
  }

  async getUserData() {
    const data = await this.get(
      "/" + this.user.currentUnit + "/users/" + this.user._id
    );

    return data;
  }

  changeUnit(unit) {
    if (this.user.units.includes(unit)) {
      if (this.user.currentUnit != unit) {
        this.user.currentUnit = unit;
      }
      return true;
    } else {
      return false;
    }
  }
}

export default new Auth();
