import { variableSchema } from "../../lib/api-validators";

export default interface Notification {
  _id: string;
  userId: string;
  description: string;
  type: NotificationType;
  sendCount?: number;
  lastSend?: string;
  pending: boolean;
  enabled: boolean;
  parameters?: PatternNotificationParameters;
  schedule: NotificationSchedule;
  criteria: NotificationCriteria;
  variable?: variableSchema;
  variableId?: string;
  operatingLimitId?: string;
  calculationVariableId?: string;
  calculatedVariableList?: variableSchema[];
  createdAt: string;
  updatedAt: string;
}

export interface CustomNotification extends Notification {
  customExpression: string;
}

export interface CustomNotificationFormData {
  customExpression: string;
  schedule: NotificationScheduleType;
}

// we want to use the string version of the enum because HTML select elements
// only support strings as values
// we can do numbers too but then we would have to parseInt the value and validate
// it is a NotificationCriteria. Either way works.
export type CriteriaForPatternNotification = Extract<
  keyof typeof NotificationCriteria,
  "IDENTICAL_MATCH" | "PROBABLE_MATCH" | "EXCEEDS_THRESHOLD"
>;

export interface PatternNotificationFormData {
  threshold?: number;
  start: Date;
  end: Date;
  criteria: CriteriaForPatternNotification;
  schedule: NotificationScheduleType;
}

export interface PatternNotificationParameters {
  threshold?: number;
  start: string;
  end: string;
}

export interface NotificationSchedule {
  type: NotificationScheduleType;
}

export enum NotificationCriteria {
  FIRST_DEGREE_ANOMALY = 0,
  SECOND_DEGREE_ANOMALY = 1,
  THIRD_DEGREE_ANOMALY = 2,
  SHORT_TERM_SLOPE = 3,
  MEDIUM_TERM_SLOPE = 4,
  LONG_TERM_SLOPE = 5,
  EXCEEDS_LIMIT = 6,
  PROBABILITY_EXCEEDS_50_PERCENT = 7,
  PROBABILITY_EXCEEDS_75_PERCENT = 8,
  NODE_ACTIVE = 9,
  CUSTOM = 10,
  IDENTICAL_MATCH = 11,
  PROBABLE_MATCH = 12,
  EXCEEDS_THRESHOLD = 13,
}
export interface PatternNotificationParameters {
  threshold?: number;
  start: string;
  end: string;
}

export enum NotificationScheduleType {
  ONCE = "Once",
  EVERY_OCCURANCE = "Every_Occurance",
}

export const NotificationScheduleLabels: Readonly<{
  [notif in NotificationScheduleType]: string;
}> = {
  [NotificationScheduleType.ONCE]:
    "Notify immediately for first occurrence of the day | Once a day notification",
  [NotificationScheduleType.EVERY_OCCURANCE]:
    "Notify immediately | Multiple notifications a day",
};
// export type NotificationScheduleLabels = typeof NotificationScheduleLabels;

export const PatternNotificationCriteriaLabels: Readonly<{
  [criteria: number]: string;
}> = {
  [NotificationCriteria.IDENTICAL_MATCH]:
    "Notify when there is an Identical Match",
  [NotificationCriteria.PROBABLE_MATCH]:
    "Notify when there is an Identical or Probable Match",
  [NotificationCriteria.EXCEEDS_THRESHOLD]:
    "Notify when Percentage of Match exceeds this threshold (specify)",
};

export enum NotificationType {
  DRA = "DRA",
  SLOPE = "Slope",
  FAULT_TREE_NODE = "FaultTreeNode",
  OPERATING_LIMIT = "OperatingLimit",
  PATTERN = "Pattern",
  CUSTOM = "Custom",
}

export const NotificationTypeLabels: Readonly<{
  [notif in NotificationType]: string;
}> = {
  [NotificationType.DRA]: "DRA",
  [NotificationType.SLOPE]: "Sloping Trend",
  [NotificationType.FAULT_TREE_NODE]: "Fault Trees",
  [NotificationType.OPERATING_LIMIT]: "Operating Fitness",
  [NotificationType.PATTERN]: "Pattern",
  [NotificationType.CUSTOM]: "Custom",
};

export interface NotificationsAvailable {
  notifications: Notification[];
  availableNotifications: {
    label: string;
    type: NotificationType;
    value: number;
    operatingLimitId?: string;
  }[];
}
