import { useLocation } from "react-router-dom";
import useAllowedSettingsLinks from "./useAllowedSettingsLinks";
import classNames from "classnames";
import { LinkWithQuery } from "./LinkWithQuery2";
import { cn } from "../../lib/utils";

export function SettingsSidebar() {
  const links = useAllowedSettingsLinks();

  const loc = useLocation();

  /**
   * The breakpoints to show or hide this must be consistent with the breakpoint in Nav component.
   *
   * Specifically, we want to show the Advanced settings hover feature on smaller than LG and have
   * a regular button with no hover on LG and up
   */

  return (
    <div
      className={cn(
        "col-span-2 text-[14.5px] sticky top-0 overflow-y-scroll h-screen w-full border-r border-slate-200 text-sm"
      )}
    >
      <div className="flex flex-col px-4 mt-4 pb-4">
        {links.map((link, idx) => {
          const key = link.name + (link.url ? "-link" : "-header");
          const active = link?.url && loc.pathname.endsWith(link?.url);

          const theNextEltIsAHeader =
            idx + 1 <= links.length - 1 ? !links[idx + 1]?.url : false;
          const isAHeader = !link.url;

          const linkElementNotHeaderElement = (
            <div
              key={key}
              className={classNames(
                "rounded-md p-1.5 px-3 flex items-center gap-3 group",
                active
                  ? "bg-indigo-800 text-base-100 hover:cursor-default"
                  : "hover:bg-zinc-200 hover:cursor-pointer hover:text-indigo-800 text-neutral active:scale-95 transition-all hover:pl-4",
                theNextEltIsAHeader ? "mb-6" : "mb-[2px]"
              )}
            >
              {link.icon ? (
                <div
                  className={cn(
                    !active && "text-gray-500 group-hover:text-indigo-800"
                  )}
                >
                  <link.icon />
                </div>
              ) : null}
              {link.name}
            </div>
          );
          return link.url ? (
            active ? (
              linkElementNotHeaderElement
            ) : (
              <LinkWithQuery
                key={link.url}
                to={`..${link.url}`}
                relative="path"
                pick={{
                  d: true,
                  mo: true,
                  y: true,
                  z: true,
                  cd: true,
                }}
              >
                {linkElementNotHeaderElement}
              </LinkWithQuery>
            )
          ) : (
            <div
              key={key}
              className={classNames(
                "text-slate-400 mb px-1 text-[13px] font-semibold",
                {
                  "mb-1": isAHeader,
                }
              )}
            >
              {link.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
