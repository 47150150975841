import React from "react";
import { cn } from "./cn";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const INPUT_LOOKALIKE_CLASSNAMES =
  "flex h-10 w-full rounded-md border border-xslate-7 bg-white px-3 py-2 text-sm dark:bg-xslate-1 ring-offset-background hover:border-xslate-8 placeholder:text-xslate-9 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-xslate-7 focus-visible:ring-offset-2 dark:bg-xslate-1 disabled:cursor-not-allowed disabled:opacity-50";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          INPUT_LOOKALIKE_CLASSNAMES,
          "file:border-0 file:bg-transparent file:text-sm file:font-medium",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
