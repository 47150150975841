import _ from "lodash";
import React, { useState, useMemo } from "react";
import Form from "../../common/Form";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import type { variableSchema } from "../../../lib/api-validators";
import Input from "../../common/Input/Input";
import { useListOfChartsFromSearchParams } from "../profile-book";

export type VariableSearchResultComponent = React.FC<{
  variable: variableSchema;
}>;

function VariableSearch({
  buildResult: ResultComponent,
  query,
  setQuery,
}: {
  buildResult: VariableSearchResultComponent;
  query: string;
  setQuery: (query: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const variablesQuery = useVariablesArrayQuery();
  const displayShortIds = useListOfChartsFromSearchParams();

  const matcher = (str: string, variable: variableSchema) => {
    return (
      variable.trimmedName.toLowerCase().includes(str.toLowerCase()) ||
      variable.description.toLowerCase().includes(str.toLowerCase())
    );
  };

  // show the currently displayed charts at the top of this list
  const variablesSortedByCurrentlyShown = useMemo(() => {
    if (!variablesQuery.data) return [];

    const matchedVariables = variablesQuery.data.filter(
      (v) => v.type != null && v.type !== "FaultTreeNode" && matcher(query, v)
    );
    if (!displayShortIds || !displayShortIds.length) return matchedVariables;

    return matchedVariables.sort((a, b) => {
      const aIndex = displayShortIds.findIndex(
        (shortIds) => shortIds[0] === a.short_id
      );
      const bIndex = displayShortIds.findIndex(
        (shortIds) => shortIds[0] === b.short_id
      );

      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });
  }, [displayShortIds, variablesQuery.data, query]);

  return (
    <Form exitOnFocusLost={true} focusable={true} onExit={() => setOpen(false)}>
      <div className="w-full">
        <Input
          placeholder="Search variables"
          onFocus={() => setOpen(true)}
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="w-full"
          size="sm"
          bordered
        />
        {open ? (
          <div className="w-full relative">
            <div className="absolute w-full" style={{ zIndex: 1000 }}>
              <div className="mt-2 bg-base-100 border-indigo-200 rounded-md hover:cursor-pointer max-h-[70vh] overflow-y-scroll border-2 shadow-2xl box-border">
                {variablesSortedByCurrentlyShown.map((v) => (
                  <ResultComponent key={v._id} variable={v} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Form>
  );
}

export { VariableSearch };
