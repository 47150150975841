import { z } from "zod";

const faultTreeNodeStatus = z.enum([
  "Active",
  "Inactive",
  "ActiveShutdown",
  "InactiveShutdown",
  "Error",
  "MajorDrift",
  "ModerateDrift",
  "NoDrift",
]);

export type faultTreeNodeStatus = z.infer<typeof faultTreeNodeStatus>;

export const faultTreeNodeStatusSeriesSchema = z.object({
  date: z.string().datetime(),
  status: faultTreeNodeStatus,
  duration: z.number().int(),
  processing: z.boolean(),
  resolutionDate: z.string().datetime().nullish(),
});

export type faultTreeNodeStatusSeriesSchema = z.infer<
  typeof faultTreeNodeStatusSeriesSchema
>;
