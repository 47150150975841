import * as R from "remeda";

export function mapFilterTruthy<TArrElt, TMapped>(
  arr: TArrElt[],
  fn: (t: TArrElt) => TMapped
) {
  return R.pipe(arr, R.map(fn), R.filter(R.isTruthy));
}

function minLen1<T>(x: T[]): x is [T, ...T[]] {
  return x.length > 0;
}

function assertMinLen1<T>(x: T[]): [T, ...T[]] {
  if (!minLen1(x)) {
    throw new Error("Expected a non-empty array");
  }
  return x as [T, ...T[]];
}

const iife = <T>(fn: () => T): T => fn();

export { minLen1, iife, assertMinLen1 };
