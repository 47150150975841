import { isBefore, subDays } from "date-fns";
import { variableSchema } from "../../lib/api-validators";
import { UseTagsManagerStoreProvider } from "../tags/manager/tags-manager-store-provider";
import { GenericTagsManager_NEEDS_PROVIDER } from "../tags/manager/generic-tags-manager";

const isPotentiallyABadTag = (x: variableSchema) => !x.expression; // calculated tags can never be stale

const threeDaysAgo = () => subDays(new Date(), 3);

const enabledFiltersBadTags = {
  "No Data in Last 30 days": (x) => {
    if (!isPotentiallyABadTag(x)) return false; // no calculated tags

    if (!x.opc_time) return isBefore(new Date(x.created_at), threeDaysAgo());

    const ago = subDays(new Date(), 30);
    const lastDataPoint = new Date(x.opc_time);
    return isBefore(lastDataPoint, ago);
  },
  "No Data in Last 90 days": (x) => {
    if (!isPotentiallyABadTag(x)) return false; // no calculated tags

    if (!x.opc_time) return isBefore(new Date(x.created_at), threeDaysAgo());

    const ago = subDays(new Date(), 90);
    const lastDataPoint = new Date(x.opc_time);
    return isBefore(lastDataPoint, ago);
  },
  "No Data in Last 180 days": (x) => {
    if (!isPotentiallyABadTag(x)) return false; // no calculated tags

    if (!x.opc_time) return isBefore(new Date(x.created_at), threeDaysAgo());

    const ago = subDays(new Date(), 180);
    const lastDataPoint = new Date(x.opc_time);
    return isBefore(lastDataPoint, ago);
  },
} as const satisfies Record<string, (x: variableSchema) => boolean>;

const enabledSortsBadTags = {
  "last opc": (a, b) => {
    if (!a.opc_time) return 1;
    if (!b.opc_time) return -1;
    return new Date(b.opc_time).getTime() - new Date(a.opc_time).getTime();
  },
  Alphabetical: (a, b) => {
    return a.trimmedName.localeCompare(b.trimmedName);
  },
} as const satisfies Record<
  string,
  (a: variableSchema, b: variableSchema) => number
>;

function StaleTagsManager() {
  return (
    <UseTagsManagerStoreProvider
      enabledFilters={enabledFiltersBadTags}
      enabledSorts={enabledSortsBadTags}
      initialFilter="No Data in Last 30 days"
      initialSort="last opc"
      isStaleTagsPage
    >
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GenericTagsManager_NEEDS_PROVIDER />
    </UseTagsManagerStoreProvider>
  );
}

export { StaleTagsManager };
