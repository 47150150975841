import { type MinLen1 } from "../../lib/types";
import * as R from "remeda";
import { assertMinLen1, iife, minLen1 } from "../../lib/utils";
import {
  type TimeseriesForBvForDraw,
  type TimeseriesForBv,
  TimeseriesForBvFromVariable,
} from "../types";
import * as d3 from "d3";
import { manipulateStageOffsetsForAlignByStageView2 } from "../align-by-stage-utils";
import { AnomalyLevelEnum } from "../../lib/anomaly-levels";
import { rangeOverlaps } from "./utils";
import { DateTime } from "luxon";
import { trendDataMatches, TrendLineVariant } from "./draw";

function buildDrawDataForDiscontinuousDRA(
  dataFromApi: MinLen1<TimeseriesForBv>,
  excludedModesSet: Set<string>,
  originalDomain: [number, number],
  timezone: string
):
  | {
      timeseries: MinLen1<TimeseriesForBvForDraw>;
      daBar: (TrendLineVariant & {
        bounds: [number, number];
        d: AnomalyLevelEnum;
        shutdownOccurred: boolean;
        allShutdown: boolean;
      })[];
    }
  | undefined {
  const out = dataFromApi
    .map((d) => {
      let offset = 0;
      const { stages } = d;

      const newStages: ((typeof stages)[number] & {
        moveBack: number;
      })[] = [];

      for (const stage of stages) {
        const remove = excludedModesSet.has(stage._id);
        if (remove) {
          offset += stage.d[1] - stage.d[0];
        } else newStages.push({ ...stage, moveBack: offset });
      }

      if (!minLen1(newStages)) return undefined;

      const firstStage = R.first(newStages);
      const lastStage = R.last(newStages);
      const t0 = firstStage.d[0] - firstStage.moveBack;
      const t1 = lastStage.d[1] - lastStage.moveBack;

      const stretcher = d3.scaleLinear().domain([t0, t1]).range(d.d);

      const withOffsets = iife(() => {
        const out = newStages.map(
          ({ moveBack, ...rest }): TimeseriesForBvForDraw["stages"][number] => {
            const offset = (t: number) => stretcher(t - moveBack);
            offset.invert = (t: number) => stretcher.invert(t) + moveBack;

            return {
              ...rest,
              offset,
            };
          }
        );

        if (!minLen1(out)) throw new Error("No stages");

        return out;
      });

      return {
        ...d,
        r: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          R.firstBy(withOffsets, (x) => x.r[0])!.r[0],
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          R.firstBy(withOffsets, [(x) => x.r[1], "desc"])!.r[1],
        ] as [number, number],
        stages: withOffsets,
      } satisfies TimeseriesForBvForDraw;
    })
    .filter((x) => x !== undefined);

  if (!minLen1(out)) return undefined;

  const daBar = iife(() => {
    const [leftEdgeWithNoZooming, rightEdgeWithNoZooming] = originalDomain;
    const startEndTuples: [number, number][] = [];
    let startOfDay = DateTime.fromMillis(leftEdgeWithNoZooming, {
      zone: timezone,
    }).startOf("day");
    while (startOfDay.toMillis() < rightEdgeWithNoZooming) {
      startEndTuples.push([
        startOfDay.toMillis(),
        startOfDay.endOf("day").toMillis(),
      ]);
      startOfDay = startOfDay
        .plus({
          days: 1,
        })
        // just in case
        .startOf("day");
    }

    const shiftedDas: (TrendLineVariant & {
      bounds: [number, number];
      d: AnomalyLevelEnum;
      shutdownOccurred: boolean;
      allShutdown: boolean;
    })[] = [];

    for (const bv of dataFromApi) {
      const filteredBv = out.find((x) => trendDataMatches(x, bv));

      if (!filteredBv) continue;

      const perDay: {
        bounds: [number, number];
        d: AnomalyLevelEnum;
        shutdownOccurred: boolean;
        allShutdown: boolean;
      }[] = [];

      for (const bounds of startEndTuples) {
        let dataForThisDay = {
          da: AnomalyLevelEnum["No Risk"],
          shutdownOccurred: false,
          allShutdown: true,
        };

        for (const stage of bv.stages) {
          const isShutdown = stage._id === "000000000000000000000000";

          const [stageStart, stageEnd] = stage.d;
          if (stageStart > bounds[1]) break;
          if (stageEnd < bounds[0]) continue;

          for (const segment of stage.ptsPartitioned) {
            const segStart = segment.pts[0].t;
            const segEnd = (
              segment.pts[segment.pts.length - 1] ?? segment.pts[0]
            ).t;

            if (segStart > bounds[1]) break;
            if (segEnd < bounds[0]) continue;

            /**
             * if we get to this point, that means the segment overlaps
             * with the bounds at least at 1 point.
             */

            const d = segment.d; // all points within a segment have the same d
            dataForThisDay.da = Math.max(
              dataForThisDay.da,
              d ?? AnomalyLevelEnum["No Risk"]
            ) as AnomalyLevelEnum;
            dataForThisDay.shutdownOccurred =
              dataForThisDay.shutdownOccurred || isShutdown;
            dataForThisDay.allShutdown =
              dataForThisDay.allShutdown && isShutdown;
          }
        }

        perDay.push({
          bounds,
          d: dataForThisDay.da,
          shutdownOccurred: dataForThisDay.shutdownOccurred,
          allShutdown: dataForThisDay.allShutdown,
        });
      }

      let j = 0;
      for (const { bounds, d, shutdownOccurred, allShutdown } of perDay) {
        let forDay = undefined as [number, number] | undefined;
        let currStage = filteredBv.stages[j];

        while (currStage && rangeOverlaps(bounds, currStage.d)) {
          const end = Math.min(bounds[1], currStage.d[1]);

          if (!forDay) {
            forDay = [
              currStage.offset(Math.max(bounds[0], currStage.d[0])),
              currStage.offset(end),
            ];
            shiftedDas.push({
              ...bv,
              bounds: forDay,
              d,
              shutdownOccurred,
              allShutdown,
            });
          } else {
            forDay[1] = currStage.offset(end);
          }

          if (currStage.d[1] > end) {
            break; // dont j++
          }

          currStage = filteredBv.stages[++j]!;
        }
      }
    }

    return shiftedDas;
  });

  return { timeseries: out, daBar };
}

function buildDrawDataForRegularSeriesView(
  dataFromApi: MinLen1<TimeseriesForBv>,
  excludedModesSet: Set<string>
): MinLen1<TimeseriesForBvForDraw> | undefined {
  const out = dataFromApi
    .map((d): TimeseriesForBvForDraw | undefined => {
      const filteredStages = d.stages.filter(
        (s) => !excludedModesSet.has(s._id)
      );

      if (!minLen1(filteredStages)) return undefined;

      return {
        ...d,
        stages: iife(() => {
          const out = filteredStages.map(
            (s): TimeseriesForBvForDraw["stages"][number] => {
              const offset = (t: number) => t;
              offset.invert = (t: number) => t;

              return {
                ...s,
                offset,
              };
            }
          );

          return assertMinLen1(out);
        }),
        r: [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          R.firstBy(filteredStages, (x) => x.r[0])!.r[0],
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          R.firstBy(filteredStages, [(x) => x.r[1], "desc"])!.r[1],
        ] as [number, number],
      };
    })
    .filter((x) => x !== undefined);

  if (!minLen1(out)) return undefined;

  return out;
}

function buildDrawDataForAlignByStartView(
  dataFromApi: MinLen1<TimeseriesForBv>
): MinLen1<TimeseriesForBvForDraw> {
  return dataFromApi.map((d): TimeseriesForBvForDraw => {
    const start = d.stages[0].d[0];

    return {
      ...d,
      stages: iife(() => {
        const out = d.stages.map(
          (s): TimeseriesForBvForDraw["stages"][number] => {
            const offset = (t: number) => t - start;
            offset.invert = (t: number) => t + start;
            return {
              ...s,
              offset,
            };
          }
        );

        if (!minLen1(out)) throw new Error("No stages");

        return out;
      }),
    };
  }) as MinLen1<TimeseriesForBvForDraw>;
}

function buildDrawDataForParallelAlignByStageView(
  data: MinLen1<TimeseriesForBv>,
  stageMap: Record<string, { isDefault: boolean }>
): MinLen1<TimeseriesForBvForDraw> | undefined {
  const alignByStageData = manipulateStageOffsetsForAlignByStageView2(
    data,
    stageMap
  );

  if (!alignByStageData) return undefined;

  alignByStageData.withOffsets.map(
    ({ stages, ...rest }): TimeseriesForBvForDraw => {
      return {
        ...rest,
        stages: stages.map(
          ({
            distanceFromStageStart,
            ...rest
          }): TimeseriesForBvForDraw["stages"][number] => {
            const stageStart = alignByStageData.stageStarts[rest._id];

            if (stageStart === undefined) {
              throw new Error("No stage start");
            }

            const o = rest.d[0] - stageStart - distanceFromStageStart;

            const offset = (t: number) => t - o;
            offset.invert = (t: number) => t + o;

            return {
              ...rest,
              offset,
            };
          }
        ) as MinLen1<TimeseriesForBvForDraw["stages"][number]>,
      };
    }
  );
}

export {
  buildDrawDataForDiscontinuousDRA,
  buildDrawDataForRegularSeriesView,
  buildDrawDataForAlignByStartView,
  buildDrawDataForParallelAlignByStageView,
};
