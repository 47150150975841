import { useEffect } from "react";
import {
  anomalySchema,
  commentsSummarySchema,
} from "../../../lib/api-validators";
import * as R from "remeda";
import { formatTime } from "../utils/utils";
import { views } from "../constants";
import moment from "moment";
import { z } from "zod";
import {
  ANOMALY_LEVELS_ASC,
  ANOMALY_LEVELS_DESC,
  AnomalyLevelEnum,
} from "../../../types/api/Anomaly";
import type {
  CacheData,
  FinalFormattedVariable,
  FormattableItem,
  FormattedDataInner,
  PreformatClusterItem,
  PreformatPlainItem,
} from "./types";
import { useGetUseDriStore } from "./create-use-dri-store";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  getAnomaly,
  getCommentsSummary,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { acknowledgementSchema } from "../../../lib/api-schema/acknowledgment";
import { useDateState } from "../../../zustand/useDateState";
import { useSelectedGroup } from "../use-selected-group";

/**
 * The logic for generating what variables to show in the sidebar
 * has me lost in the sauce but I just covered over what was in
 * Sails but made a few changes for a more functional approach so as
 * to be able to make changes more easily later on.
 *
 * All of the types around the data are in ./types.ts and they're not ideal
 * but they are correct...
 */

export function useHandleAnomaliesChange() {
  const useDriStore = useGetUseDriStore();
  const variables = useDriStore((s) => s.variables);
  const clusterShortIdMap = useDriStore((s) => s.clusters);
  const viewMode = useDriStore((s) => s.viewMode);

  const $ds = useDateState();

  const group = useSelectedGroup();

  const baseUrlSlash = useBaseUrlExperimental();

  useEffect(() => {
    if (!group || !group._id || !variables || !clusterShortIdMap) {
      return;
    }

    const thisUnitHasClusters = Object.values(clusterShortIdMap).length > 0;

    const initDataForThisGroup = async (): Promise<CacheData> => {
      const data = await getAnomaly(
        baseUrlSlash,
        { varIds: group.variables },
        $ds.axisRangeTo.dateString
      ).then((data) => anomalySchema.array().parse(data));

      // used for sorting
      const metaDataMap = R.mapToObj(data, (anom) => [
        anom.variableId,
        {
          level: anom.level ?? 0,
          time: 0,
        },
      ]);

      const getDataForClusterView = async () => {
        if (!clusterShortIdMap) throw new Error("checked above");

        const clusterIdMap = R.mapKeys(clusterShortIdMap, (_, v) => v._id);

        const checkFamilyAnomForVars = R.pipe(
          data,
          R.reduce((shortIds, anom) => {
            const v = variables[anom.variableId];
            v &&
              shortIds.push(
                ...v.families.reduce(
                  (arr, cid) => {
                    const cluster = clusterIdMap[cid];
                    if (!cluster) return arr;

                    arr.push(cluster._id);
                    return arr;
                  },
                  [] as typeof shortIds
                )
              );
            return shortIds;
          }, [] as string[]),
          R.flatten(),
          R.uniq(),
          (uniqueFamilyShortIds) => {
            const checkFamilyAnomForVars = R.pipe(
              uniqueFamilyShortIds,
              R.reduce((vids, shortId) => {
                const cluster = clusterShortIdMap[shortId];
                cluster && vids.push(...cluster.variables);
                return vids;
              }, [] as string[]),
              R.uniq()
            );

            return checkFamilyAnomForVars;
          }
        );

        const anomsWithOtherVarsFromFamilies = await getAnomaly(
          baseUrlSlash,
          { varIds: checkFamilyAnomForVars },
          $ds.axisRangeTo.dateString
        ).then((data) => anomalySchema.array().parse(data));

        // extend meta data with data from variables from clusters
        for (const anom of anomsWithOtherVarsFromFamilies) {
          const vid = anom.variableId;
          metaDataMap[vid] = {
            level: anom.level ?? 0,
            time: 0,
          };
        }

        const varAnoms = R.mapToObj(anomsWithOtherVarsFromFamilies, (anom) => [
          anom.variableId,
          anom,
        ]);

        const intermediateData = R.pipe(
          data,
          R.filter((anom) => !!variables[anom.variableId]),
          R.map((anom) => {
            const variableObj = variables[anom.variableId];

            if (!variableObj) throw new Error("no family");

            const time = anom.anomalyMilliseconds / 1000;

            const base: PreformatPlainItem = {
              ...variableObj,
              da: anom.level,
              time,
              time_formatted: formatTime(time),
              internal_time: anom.anomalyMilliseconds,
              cluster: undefined,
              indicators: undefined,
              associatedFamilyId: undefined,
            };

            const clustersMappedById = R.mapKeys(clusterShortIdMap, (_, v) => {
              return v._id;
            });

            const familyOfVars = variableObj.families.reduce((arr, cid) => {
              const cluster = clustersMappedById[cid];
              cluster && arr.push(cluster._id);
              return arr;
            }, [] as string[]);

            if (familyOfVars.length === 0) return base;

            return familyOfVars.reduce((arr, cid) => {
              const familyObj = clustersMappedById[cid];

              if (!familyObj) throw new Error("impossible");

              const tmpFamilyObj = R.clone(familyObj);

              const indicators: PreformatPlainItem["indicators"] = {
                3: false,
                2: false,
                1: false,
                0: false,
              };

              // keep track of which variable Id contributed what level so we can use this to sort (only in cluster view)

              for (const vid of tmpFamilyObj.variables) {
                const varAnom = varAnoms[vid];

                if (varAnom) {
                  indicators[varAnom.level] = true;
                }
              }
              const out: PreformatPlainItem = {
                ...base,
                cluster: tmpFamilyObj,
                indicators,
                associatedFamilyId: tmpFamilyObj._id,
              };
              arr.push(out);
              return arr;
            }, [] as PreformatPlainItem[]);
          }),
          R.flatten()
        );

        const groupedFamilyObject: PreformatClusterItem[] = R.pipe(
          intermediateData,
          R.groupBy((o) => o.associatedFamilyId),
          (o) => {
            const bypassed =
              intermediateData
                .filter((o) => o.associatedFamilyId === undefined)
                .map((o) => ({
                  ...o,
                  nothighest: false,
                })) ?? [];

            const groupedByAssociated = Object.values(o);

            const highests = R.map(groupedByAssociated, (arr) => {
              const sorted = [...arr].sort((a, b) => {
                if (a.da === b.da) {
                  return b.internal_time - a.internal_time;
                }
                return b.da - a.da;
              });

              const highest = sorted.at(0);

              if (!highest) throw new Error("impossible");

              return { ...highest, nothighest: false };
            });

            const watchlists = R.pipe(
              groupedByAssociated,
              R.flatten(),
              R.filter((o) => !!o.watchlist),
              R.map((o) => ({
                ...o,
                nothighest: true,
              }))
            );

            return [...watchlists, ...highests, ...bypassed];
          }
        );

        return groupedFamilyObject;
      };

      async function formatData(
        listOfVars: FormattableItem[]
      ): Promise<FormattedDataInner> {
        // _.forEach(listOfVars, (o) => {
        //   o.watchlist = variables[o._id].watchlist;
        // });

        if (!clusterShortIdMap) throw new Error("no clusters");

        const vars_ = R.reduce(
          listOfVars,
          (arr, o) => {
            if (o.associatedFamilyId) {
              const family = clusterShortIdMap[o.associatedFamilyId];
              if (!family) throw new Error("no family");
              arr.push(...family.variables);
            } else {
              arr.push(o._id);
            }
            return arr;
          },
          [] as string[]
        );

        listOfVars.sort((a, b) => {
          if (a.time === b.time) {
            if (a.trimmedName > b.trimmedName) {
              return 1;
            } else {
              return -1;
            }
          } else return b.time - a.time;
        });

        const selectedDate = moment.utc($ds.axisRangeTo.dateString);
        const summaryDataRequest = getCommentsSummary(baseUrlSlash, {
          start_date: selectedDate.startOf("day").toISOString(),
          end_date: selectedDate.endOf("day").toISOString(),
          variableIds: vars_,
        }).then((data) => commentsSummarySchema.parse(data));

        const ackMap = await getAnomaly(
          baseUrlSlash,
          { varIds: vars_ },
          $ds.axisRangeTo.dateString,
          {
            include: "acknowledgment",
          }
        ).then((anoms) => {
          const parsed = anomalySchema
            .extend({
              included: z.object({
                acknowledgment: acknowledgementSchema.nullable(),
              }),
            })
            .array()
            .parse(anoms);
          return R.mapToObj(parsed, (anom) => {
            const acks = anom.included.acknowledgment;
            if (acks) {
              return [anom.variableId, acks];
            }
            return ["undefined", acks];
          });
        });

        const summaryData = await summaryDataRequest;

        const organizedBySidebarFolderData = listOfVars.reduce(
          (obj, v) => {
            const acks = ackMap[v._id];

            const getBase = () => {
              const baseInner = {
                ...v,
                ack: !!acks,
              };

              if (v.associatedFamilyId !== undefined) {
                const family = clusterShortIdMap[v.associatedFamilyId];
                if (!family) throw new Error("no family");

                const tmp_num_comments = R.sumBy(
                  family.variables,
                  (variable) => {
                    const variables_counts =
                      summaryData.variables_counts[variable];
                    return variables_counts ?? 0;
                  }
                );

                return {
                  ...baseInner,
                  numOfCommentsOrig: summaryData.variables_counts[v._id] ?? 0,
                  numOfComments: tmp_num_comments,
                  comment: tmp_num_comments > 0,
                };
              }

              const variables_counts = summaryData.variables_counts[v._id];
              return {
                ...baseInner,
                numOfCommentsOrig: 0,
                numOfComments: variables_counts ?? 0,
                comment: !!variables_counts,
              };
            };

            const outerBase = getBase();

            if (v.cluster) {
              const { indicators } = v;
              const hasNonZeroIndicator = ANOMALY_LEVELS_ASC.filter(
                (v) => v !== AnomalyLevelEnum["No Risk"]
              ).some((num) => indicators?.[num]);

              const clusterBase = {
                ...outerBase,
                hasIndicators: hasNonZeroIndicator || !!indicators?.[0], // not sure why we need the 0 ?,
              };

              if (v.watchlist) {
                const notHighestIsFalseOrUndefined =
                  !("nothighest" in v) || !v.nothighest;

                if (notHighestIsFalseOrUndefined && !hasNonZeroIndicator) {
                  obj.anom[v.da].push(clusterBase);
                }

                if (notHighestIsFalseOrUndefined && hasNonZeroIndicator) {
                  const highestDegreeFound = ANOMALY_LEVELS_DESC.filter(
                    (v) => v !== AnomalyLevelEnum["No Risk"]
                  ).find((num) => !!indicators?.[num]);

                  highestDegreeFound &&
                    obj.anom[highestDegreeFound].push({
                      ...clusterBase,
                      da: highestDegreeFound,
                    });
                }

                // add to watchlist if not in already
                if (!obj.watch[v._id])
                  obj.watch[v._id] = {
                    ...clusterBase,
                    comment: !!clusterBase.numOfCommentsOrig,
                    numOfComments: clusterBase.numOfCommentsOrig,
                  };
              } else if (hasNonZeroIndicator) {
                const highestDegreeFound = ANOMALY_LEVELS_DESC.filter(
                  (v) => v !== 0
                ).find((num) => !!indicators?.[num]);

                highestDegreeFound &&
                  obj.anom[highestDegreeFound].push({
                    ...clusterBase,
                    da: highestDegreeFound,
                  });
              } else {
                obj.anom[v.da].push({ ...clusterBase });
              }
            } else {
              const nonClusterBase = {
                ...outerBase,
                hasIndicators: false,
              };

              obj.anom[v.da].push(nonClusterBase);
              if (!!v.watchlist && !obj.watch[v._id]) {
                obj.watch[v._id] = nonClusterBase;
              }
            }

            return obj;
          },
          {
            watch: {}, // use key to check for duplicates before adding more, see below
            anom: {
              "0": [],
              "1": [],
              "2": [],
              "3": [],
            },
          } as {
            watch: Record<string, FinalFormattedVariable>;
            anom: Record<AnomalyLevelEnum, FinalFormattedVariable[]>;
          }
        );

        const out: FormattedDataInner = {
          watch: Object.values(organizedBySidebarFolderData.watch),
          "0": organizedBySidebarFolderData.anom["0"],
          "1": organizedBySidebarFolderData.anom["1"],
          "2": organizedBySidebarFolderData.anom["2"],
          "3": organizedBySidebarFolderData.anom["3"],
        };
        return out;
      }

      const getDataForPlainView = () =>
        formatData(
          data.reduce((formattables, anom) => {
            const time = anom.anomalyMilliseconds / 1000;

            const variable = variables[anom.variableId];

            variable &&
              formattables.push({
                ...variable,
                time,
                time_formatted: formatTime(time),
                da: anom.level,
                internal_time: anom.anomalyMilliseconds,
              });
            return formattables;
          }, [] as FormattableItem[])
        );

      const plainData = getDataForPlainView();

      // if the unit has no clusters, we don't want to calculate anything, because
      // getDataForClusterView will show everything plain view does and then the
      // clusters on top of that. But we don't want to show anything because
      // plain view should be the only selectable view in this case.
      const empty: FormattedDataInner = {
        "0": [],
        "1": [],
        "2": [],
        "3": [],
        watch: [],
      };

      const out: {
        [TKey in keyof typeof views]: FormattedDataInner;
      } = {
        [views.plain]: await plainData,
        [views.cluster]: thisUnitHasClusters
          ? await getDataForClusterView().then((data) => formatData(data))
          : empty,
      };

      return {
        ...out,
        metaDataMap,
      };
    };

    const standardizedKeyForCache = moment
      .utc($ds.axisRangeTo.dateString)
      .startOf("day")
      .toISOString(); // just a random standardization to keep in the cache

    // start here
    const cachedData = useDriStore
      .getState()
      .getCache(standardizedKeyForCache, group._id);

    const getNewListOpenState = (anoms: FormattedDataInner) => {
      const out = R.mapValues(anoms, (v) => v.length > 0);
      return out;
    };

    if (!cachedData) {
      useDriStore.setState({
        loading: true,
      });
      initDataForThisGroup()
        .then((anoms) => {
          // save in cache
          useDriStore
            .getState()
            .setCache(standardizedKeyForCache, group._id, anoms);

          const anomsForThisView = anoms[viewMode];
          useDriStore.setState({
            viewMode: thisUnitHasClusters ? viewMode : views.plain,
            listOpen: getNewListOpenState(anomsForThisView),
            anomalies: anoms,
            loading: false,
          });
        })
        .finally(() => {
          useDriStore.setState({
            loading: false,
          });
        });
    } else {
      const dataFromCache = cachedData[viewMode]; // trying to prevent duplicate API calls if they're switching between dates they've already seen
      useDriStore.setState({
        viewMode: thisUnitHasClusters ? viewMode : views.plain,
        listOpen: getNewListOpenState(dataFromCache),
        anomalies: cachedData,
      });
    }
  }, [
    group,
    $ds.axisRangeTo.dateString,
    variables,
    clusterShortIdMap,
    viewMode,
    baseUrlSlash,
    useDriStore,
  ]);
}
