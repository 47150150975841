import {
  type ComponentProps,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { getTzAwareDateStateDomainSync } from "./chart-range/use-tz-aware-date-state-domain";
import {
  TrendChartQueryType,
  useInstantCalculatorQuery,
  useTrendChartQuery,
} from "../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import { createStore, Provider, useAtomValue, useSetAtom } from "jotai";
import {
  Atoms,
  ChartVariant,
  InitialTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { chartFormat } from "../../../frameworks/fetcher/utils";
import {
  GenericSecondaryVariableViewNeedsProvider,
  type AvailableProps,
  useDebouncedEffectiveRangeForTimeseriesQuery,
} from "./dra-and-aria-secondary-variable.view";
import { useDateState } from "../../../zustand/useDateState";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { useGetUseProfileBookStoreNotRequired } from "../../pb/use-profile-book-store";
import { TimeseriesForBv } from "../../../shared-ui/time-series-2/types";
import { useGetUseViewModeStoreNotRequired } from "../../../shared-ui/time-series-2/grid-view-store";
import { minutesToMilliseconds } from "date-fns";
import { useCommentsListQuery } from "./comments/comment-pills";
import { useGetUseDriStoreNotRequired } from "../../dri/hooks/create-use-dri-store";

export function chartDimensionsConfig(
  opts:
    | {
        isGridView: false;
      }
    | {
        isGridView: true;
        numCols: number;
      }
): Required<
  Pick<
    ComponentProps<typeof DRASecondaryVariableViewNeedsProvider>,
    "axesFontScale" | "height" | "width" | "lineWidthScale"
  >
> {
  if (opts.isGridView) {
    const ratio = 3;
    const width = 2000;
    const height = width / ratio;

    const twoColsOrLess = opts.numCols <= 2;

    return {
      axesFontScale: twoColsOrLess ? 1.1 : 1.6,
      height,
      width,
      lineWidthScale: 0.3,
    };
  }

  const ratio = 4;
  const width = 3000;
  const height = width / ratio;

  return {
    axesFontScale: 0.9,
    height: height,
    width: width,
    lineWidthScale: 0.3,
  };
}

/**
 * Use this if you want to provide your own store provider. See
 * the component below for an example of how to provide your
 * own store
 *
 * If you don't want to provide your own store provider, use the
 * component below.
 */
function DRASecondaryVariableViewNeedsProvider({
  ...props
}: Omit<
  AvailableProps,
  keyof Pick<
    AvailableProps,
    "data" | "analysisPeriod" | "allowModeTransparency"
  >
>) {
  const usePbStore = useGetUseProfileBookStoreNotRequired();
  const useDriStore = useGetUseDriStoreNotRequired();
  const useViewModeStore = useGetUseViewModeStoreNotRequired();
  const setExpanded = useSetAtom(Atoms.expandedAtom);

  const setAnomAll = useSetAtom(Atoms.setAllAnomalyColorationAtom);
  const setShutdownAll = useSetAtom(Atoms.setAllShutdownMaskAtom);
  const setModeTransparencyAll = useSetAtom(Atoms.setAllModeTransparencyAtom);
  const variableIds = useAtomValue(Atoms.onlyVariableTrendLinesAtom)
    .map((x) => x.bv.slice(24))
    .sort();
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const [startRequest, endRequest] =
    useDebouncedEffectiveRangeForTimeseriesQuery();

  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);

  const isZoomed = start !== startRequest || end !== endRequest;

  const timeseriesQuery = useTrendChartQuery(
    {
      variables: variableIds,
      start: startRequest,
      end: endRequest,
      idk: isZoomed
        ? {
            type: TrendChartQueryType.Zoomed,
            originalEnd: end,
            originalStart: start,
          }
        : { type: TrendChartQueryType.Regular },
    },
    {
      enabled: canRequestData && variableIds.length > 0,
      staleTime: minutesToMilliseconds(3),
    }
  );

  const expressionsToRequest = useAtomValue(
    Atoms.onlyExpressionTrendLinesAtom
  ).map(({ expression, id }) => ({ expression, id }));

  const icQuery = useInstantCalculatorQuery(expressionsToRequest, start, end, {
    enabled: canRequestData && expressionsToRequest.length > 0,
  });

  const timeseriesDataAsChartFormat = useMemo(() => {
    const ts: TimeseriesForBv[] =
      (timeseriesQuery.data &&
        chartFormat(timeseriesQuery.data, "0".repeat(24))) ??
      [];

    const ic = icQuery.data ?? [];

    return ts.concat(ic);
  }, [timeseriesQuery.data, icQuery.data]);

  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.showAnomalies,
      (currAnomOn) => {
        setAnomAll(currAnomOn);
      }
    );
  }, [usePbStore, setAnomAll]);
  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.globalShutdownToggle,
      (currShutdownOn) => {
        setShutdownAll(currShutdownOn);
      }
    );
  }, [usePbStore, setShutdownAll]);
  useEffect(() => {
    if (!usePbStore) return;
    return usePbStore.subscribe(
      (s) => s.globalModeTransparencyToggle,
      (currModeTransparencyOn) => {
        setModeTransparencyAll(currModeTransparencyOn);
      }
    );
  }, [usePbStore, setModeTransparencyAll]);
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.showAnomalies,
      (currAnomOn) => {
        setAnomAll(currAnomOn);
      }
    );
  });
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.globalShutdownToggle,
      (currShutdownOn) => {
        setShutdownAll(currShutdownOn);
      }
    );
  });
  useEffect(() => {
    if (!useDriStore) return;
    return useDriStore.subscribe(
      (s) => s.globalModeTransparencyToggle,
      (currModeTransparencyOn) => {
        setModeTransparencyAll(currModeTransparencyOn);
      }
    );
  });
  useEffect(() => {
    if (!useViewModeStore) return;
    return useViewModeStore.subscribe(
      (s) => s.viewMode,
      (newViewMode) => {
        if (newViewMode === "grid") {
          setExpanded(false);
        }
      }
    );
  }, [useViewModeStore, setExpanded]);
  const zone = useTimezone();
  const ds = useDateState();
  const startAnalysis = DateTime.fromISO(ds.axisRangeTo.dateString, {
    zone: zone,
  });
  const endAnalysis = startAnalysis.endOf("day").toMillis();

  const commentsQuery = useCommentsListQuery({
    /**
     * When changing the date range, this query will
     * momentarily have no data as it loads, which causes
     * the red block to disappear momentarily. Keep the
     * previous data until the new data arrives so the
     * red block doesn't flicker.
     */
    keepPreviousData: true,
  });

  return (
    <GenericSecondaryVariableViewNeedsProvider
      allowModeTransparency
      analysisPeriod={[startAnalysis.toMillis(), endAnalysis]}
      data={timeseriesDataAsChartFormat}
      redBlockStart={useMemo(() => {
        if (!commentsQuery.publicComments.data) return;
        const comments = commentsQuery.publicComments.data.docs;
        const openIssues = comments.filter(
          (x) => x.type === "Issue" && !x.issue_resolved
        );

        if (!openIssues.length) return undefined;

        let min = Infinity;

        for (const x of openIssues) {
          const t = DateTime.fromISO(x.start_date, { zone: "utc" })
            // comments still use fake dates
            .setZone(zone, {
              keepLocalTime: true,
            })
            .toMillis();
          min = t < min ? t : min;
        }

        return min; // guaranteed not to be Infinity
      }, [commentsQuery.publicComments.data, zone])}
      {...props}
    />
  );
}

function DRASecondaryVariableViewProvider({
  initialBatchVariables,
  initialExpanded,
  decideAnomBooleanForNewlyAddedVariableTrendLines,
  children,
  variant,
}: {
  initialExpanded: boolean;
  initialBatchVariables: [InitialTrendLine, ...InitialTrendLine[]];
  variant?: ChartVariant;
  decideAnomBooleanForNewlyAddedVariableTrendLines?: (
    initialSt: boolean,
    isOperatingLimitsVariant: boolean
  ) => boolean;
} & PropsWithChildren) {
  const zone = useTimezone();
  const [s] = useState(() =>
    createJotaiStoreForChartSync({
      zone,
      decideAnomBooleanForNewlyAddedVariableTrendLines,
      initialBatchVariables,
      initialExpanded,
      variant,
    })
  );
  return <Provider store={s}>{children}</Provider>;
}

function createJotaiStoreForChartSync({
  initialBatchVariables,
  initialExpanded,
  decideAnomBooleanForNewlyAddedVariableTrendLines,
  variant,
  zone,
}: {
  initialExpanded: boolean;
  initialBatchVariables: [InitialTrendLine, ...InitialTrendLine[]];
  variant?: ChartVariant;
  decideAnomBooleanForNewlyAddedVariableTrendLines?: (
    initialSt: boolean,
    isOperatingLimitsVariant: boolean
  ) => boolean;
  zone: string;
}) {
  const s = createStore();
  s.set(Atoms.setInitialStateAtom, {
    batchVariablesOrExpressions: initialBatchVariables,
    decideAnomBooleanForNewlyAddedVariableTrendLines,
    expanded: initialExpanded,
    variant,
  });

  const [start, end] = getTzAwareDateStateDomainSync(
    new URLSearchParams(window.location.search),
    zone
  );
  s.set(Atoms.setDomainAtom, [start, end]);
  return s;
}

export {
  DRASecondaryVariableViewProvider,
  DRASecondaryVariableViewNeedsProvider,
  createJotaiStoreForChartSync,
};
