import {
  type ComponentProps,
  type PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { MODE_VIEWS } from "../constants/dynamicTrendChartConstants";
import { useOperatingModesQuery } from "../../om/manager/queries";
import { OperatingMode } from "../../../lib/api-schema/om";
import { Button } from "../../ui/button";
import { Check, X } from "lucide-react";
import { cn } from "../../../lib/utils";
import { PopoverContent } from "../../ui/popover";
import { type PropsWithCn } from "../../types/component.types";
import { CustomRangePopover } from "../../dateSelector/custom-range-button/custom-range-button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../shared-ui/frontend/dialog";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import {
  ABBREVIATED_MONTH_DATE,
  LONG,
  TWELVE_HR_TIME,
} from "../../../shared-ui/lib/luxon-format-tokens";

export function DTCMasksDropdown2({
  toggledModes: excludedMap,
  isMultiView,
  variableId,
  children,
  isOperatingLimitChart,
  onModeToggle: setExcludedMap,
  onModesLoaded,
  align,
  dates: groupDates,
  side,
  withoutPortal,
  showExcludedDates,
}: PropsWithChildren<{
  toggledModes: Record<string, boolean>;
  isMultiView?: boolean;
  variableId: string | string[];
  dates?: [number, number];
  isOperatingLimitChart?: boolean;
  onModeToggle: (toggledModes: Record<string, boolean>) => void;
  onModesLoaded?: (modes: OperatingMode[]) => void;
  showExcludedDates: boolean;
}> &
  Pick<
    ComponentProps<typeof PopoverContent>,
    "side" | "align" | "withoutPortal"
  >) {
  const loaded = useRef(false);
  const modesQuery = useOperatingModesQuery();

  variableId = variableId instanceof Array ? variableId : [variableId];

  useEffect(() => {
    if (loaded.current) return;
    if (!modesQuery.data) return;

    onModesLoaded?.(
      modesQuery.data.filter((m) => {
        return m.bindingVariableIdsSet.reduce((result, id) => {
          return result || variableId.includes(id);
        }, false);
      })
    );
    loaded.current = true;
  }, [modesQuery.data, variableId, onModesLoaded]);

  type ModeToggle = {
    label: string;
    included: boolean;
    id: string;
    className?: string;
  };

  const zone = useTimezone();

  const getModes = (): ModeToggle[] => {
    const shutDown: ModeToggle = {
      label: "Shutdown",
      included: !excludedMap[MODE_VIEWS.shutdown],
      id: MODE_VIEWS.shutdown,
    };

    if (!modesQuery.data || modesQuery.data.length === 0) return [shutDown];

    const modes = modesQuery.data.filter((m) =>
      m.bindingVariableIdsSet.reduce((result, id) => {
        return result || variableId.includes(id);
      }, false)
    );

    const modeLabels = modes.map((mode) => {
      return {
        label: mode.name,
        included:
          !excludedMap[
            mode._id
          ] /* Remember, UI is inverted from internal data */,
        id: mode._id,
      };
    });

    const getDateLabel = (range: {
      end: number;
      start: number;
    }): {
      start: string;
      end: string;
      label: string;
      full?: string;
    } => {
      const { end, start } = range;
      const startDate = DateTime.fromMillis(start, { zone });
      const endDate = DateTime.fromMillis(end, { zone });

      const isDefaultTime = () => {
        const startTime = startDate.toFormat(TWELVE_HR_TIME);
        const endTime = endDate.toFormat(TWELVE_HR_TIME);

        return startTime === "12:00 AM" && endTime === "11:59 PM";
      };

      const shortFormat = ABBREVIATED_MONTH_DATE;
      const fullFormat = LONG;

      const fullStartLabel = startDate.toFormat(fullFormat);
      const fullEndLabel = endDate.toFormat(fullFormat);

      const full = `${fullStartLabel}  —  ${fullEndLabel}`;

      if (isDefaultTime()) {
        const startLabel = startDate.toFormat(shortFormat);
        const endLabel = endDate.toFormat(shortFormat);
        return {
          start: startLabel,
          end: endLabel,
          label: `${startLabel}  —  ${endLabel}`,
          full,
        };
      }

      return {
        start: fullStartLabel,
        end: fullEndLabel,
        label: full,
      };
    };

    // only get dates from exclusion map
    const datesToToggle = Object.entries(excludedMap)
      .filter(([id, excluded]) => id.match(/[0-9]+-[0-9]+/g))
      .map(([id, excluded]) => {
        const [start, end] = id.split("-").map(Number);
        // we filtered to get only tags that are time ranges for date exclusion
        const formatLabel = getDateLabel({ end: end!, start: start! });
        return {
          label: formatLabel.label,
          included: !excluded,
          id: id,
        };
      });
    const allOn = Object.values(excludedMap).every((excluded) => {
      const isOn = !excluded;
      return isOn;
    });

    if (showExcludedDates) {
      const modeToggles: ModeToggle[] = [
        {
          label: "View All",
          included: allOn,
          id: MODE_VIEWS.view_all,
          className: "border-xslate-8 viewAll",
        },
        {
          label: "Exclude Dates",
          included: false,
          id: MODE_VIEWS.exclude_dates,
        },
        ...modeLabels,
        shutDown,
        {
          label: "Remainder",
          included: !excludedMap[MODE_VIEWS.remainder],
          id: MODE_VIEWS.remainder,
        },
        ...datesToToggle,
      ];
      return modeToggles.map((mode) => {
        if (
          mode.id !== MODE_VIEWS.view_all &&
          mode.id !== MODE_VIEWS.exclude_dates
        ) {
          return {
            ...mode,
            className: "col-span-2",
          };
        } else return mode;
      });
    }

    return [
      {
        label: "View All",
        included: allOn,
        id: MODE_VIEWS.view_all,
        className: "viewAll",
      },
      ...modeLabels,
      shutDown,
      {
        label: "Remainder",
        included: !excludedMap[MODE_VIEWS.remainder],
        id: MODE_VIEWS.remainder,
      },
      ...datesToToggle,
    ];
  };

  const modes = getModes();

  const setModeState = (modeId: string, isOn: boolean) => {
    if (modeId === MODE_VIEWS.view_all) {
      if (isOn) {
        return setExcludedMap(
          Object.fromEntries(
            Object.keys(excludedMap).map((key) => [key, false] as const)
          )
        ); // turn them all on by excluding none
      }
      return; // it doesn't make sense to do anything in this case
    }

    const copy = { ...excludedMap };

    // if isOn=true we want to store false. If isOn=false we want to store true
    // excluded map is inverted from UI
    const isOff = !isOn;
    copy[modeId] = isOff;

    setExcludedMap(copy);
  };

  const showModeTogglers = !isMultiView && !isOperatingLimitChart;

  return (
    <PopoverContent
      side={side ?? "top"}
      className="w-max p-2 border-xslate-7"
      align={align ?? "end"}
      withoutPortal={withoutPortal}
    >
      <div
        className={cn(
          "grid auto-rows-max gap-1.5",
          showExcludedDates ? "grid-cols-2" : "grid-cols-1"
        )}
      >
        {children}
        {children && showModeTogglers && (
          <div className="w-full h-[0.5px] bg-xslate-8 my-1" />
        )}
        {showModeTogglers &&
          modes.map((mode) => {
            if (mode.id === MODE_VIEWS.exclude_dates && groupDates) {
              return (
                <DateExcluder
                  key="exclude_dates"
                  dates={groupDates}
                  handleDateChange={setModeState}
                />
              );
            }
            return (
              <MaskButton
                active={mode.included}
                onClick={(e) => {
                  e.stopPropagation();
                  setModeState(mode.id, !mode.included);
                }}
                key={mode.label}
                variant={mode.included ? "default" : "outline"}
                className={mode.className}
              >
                {mode.label}
              </MaskButton>
            );
          })}
      </div>
    </PopoverContent>
  );
}

export function MaskButton({
  onClick,
  active,
  children,
  variant,
  className,
}: Pick<ComponentProps<typeof Button>, "onClick" | "variant"> &
  PropsWithChildren & { active: boolean } & PropsWithCn) {
  const viewAll = className?.includes("viewAll");
  return (
    <Button
      onClick={onClick}
      className={cn(
        "justify-start",
        className,
        variant === "outline" && !viewAll && "border-xred-10"
      )}
      size={"xs"}
      variant={variant}
    >
      {viewAll ? (
        <Check className={cn("h-4 w-4", !active && "text-transparent")} />
      ) : active ? (
        <Check className="h-4 w-4" />
      ) : (
        <X className="h-4 w-4 text-xred-10" />
      )}
      <span className={cn("ml-1", !active && !viewAll && "text-xred-10")}>
        {children}
      </span>
    </Button>
  );
}

function DateExcluder({
  dates: groupDates,
  handleDateChange,
}: {
  dates: [number, number];
  handleDateChange: (modeId: string, isOn: boolean) => void;
}) {
  const zone = useTimezone();

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState<[DateTime, DateTime]>([
    DateTime.fromMillis(groupDates[0], { zone }),
    DateTime.fromMillis(groupDates[1], { zone }),
  ]);

  function handleOpenChange(b: boolean) {
    setOpen(b);
  }

  /*
   * dialog trigger can render "children"
   * pass in title, description
   * submit function for onclick on end
   * need to pass in dates and setDates function
   */
  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          className="justify-center border-xred-10"
          size="xs"
          variant="outline"
        >
          <span className="text-xred-10">Exclude Dates</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Exclude date range</DialogTitle>
          <DialogDescription>
            The selected date range will be excluded from the data.
          </DialogDescription>
        </DialogHeader>
        <CustomRangePopover
          withoutPortal
          side="bottom"
          value={{
            start: dates[0]
              .setZone("local", {
                keepLocalTime: true,
              })
              .toJSDate(),
            end: dates[1]
              .setZone("local", {
                keepLocalTime: true,
              })
              .toJSDate(),
          }}
          calendarPosition="top"
          onChange={({ start, end }) => {
            setDates([
              DateTime.fromJSDate(start).setZone(zone, {
                keepLocalTime: true,
              }),
              DateTime.fromJSDate(end).setZone(zone, {
                keepLocalTime: true,
              }),
            ]);
          }}
        >
          <Button variant="outline" size="sm" className="w-full my-4">
            <span>
              {dates[0].toFormat(LONG)} - {dates[1].toFormat(LONG)}
            </span>
          </Button>
        </CustomRangePopover>
        <DialogFooter>
          <Button
            variant="outline"
            size={"sm"}
            onClick={(e) => {
              // we need to utc-ify the dates
              dates &&
                handleDateChange(
                  (
                    dates.map((date) => date.toMillis()) satisfies number[]
                  ).join("-"),
                  false
                );
              handleOpenChange(false);
              e.stopPropagation();
            }}
          >
            Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
