import React from "react";
import { type MultiSelectValueProps, Box, CloseButton } from "@mantine/core";

function LabelMultiSelectValueWithFixed({
  value,
  label,
  onRemove,
  classNames,
  isFixed,
  ...others
}: MultiSelectValueProps & { value: string; isFixed?: boolean }) {
  return (
    <div {...others}>
      <Box
        className={"bg-zinc-300 color"}
        sx={(theme) => ({
          display: "flex",
          cursor: "default",
          alignItems: "center",
          color: "#495057",
          paddingLeft: 10,
          paddingRight: 6,
          borderRadius: 4,
          minHeight: 22,
        })}
      >
        <Box sx={{ lineHeight: 1, fontSize: 12, minHeight: 0 }}>{label}</Box>
        {isFixed ? null : (
          <CloseButton
            className="text-black hover:bg-red-300 hover:text-red-500 ml-1"
            onMouseDown={onRemove}
            variant="transparent"
            size={22}
            iconSize={14}
            tabIndex={-1}
          />
        )}
      </Box>
    </div>
  );
}

export default LabelMultiSelectValueWithFixed;
