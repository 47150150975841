import _ from "lodash";

export default function (classes, classNames) {
  if (_.isObject(classNames)) {
    /* Allow merging classes down. */
    return _.assignWith({ ...classes }, classNames, (left, right) => {
      return (left || "") + " " + (right || "");
    });
  }

  classNames = classNames.trim().split(" ");
  classes = classes || {};

  return _.reduce(
    classNames,
    (classString, className) => {
      classString += `${className} `;

      if (classes[className]) {
        classString += `${classes[className]} `;
      }

      return classString;
    },
    ""
  ).trim();
}

export function ellipsify(str, maxLength) {
  if (str.length > maxLength) {
    return str.substr(0, maxLength) + "...";
  }
  return str;
}
