import assets from "../assets";
import type { HelpPage } from "./types";

const ReleaseNotes: HelpPage = {
  color: "#38bdff",
  sectiontitle: "Release Notes",
  url: "releasenotes",
  subtitle: "Stay informed of our latest features and releases",
  subsections: [
    {
      id: "subsection-174",
      title: "2024.2",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features: ",
          id: "content-175",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "ARIA (New DRA Module) ",
              id: "content-list-176",
              marginLeft: "0",
            },
            {
              value: "Dynamic Clusters (Autonomous) ",
              id: "content-list-177",
              marginLeft: "0",
            },
            {
              value: "Correlated Tags (Autonomous) ",
              id: "content-list-440",
              marginLeft: "0",
            },
            {
              value: "Improved Instant Calculator ",
              id: "content-list-441",
              marginLeft: "0",
            },
            {
              value: "Redesigned and Improved Trendcharts ",
              id: "content-list-452",
              marginLeft: "0",
            },
            {
              value: "Simplified Editing of Dates ",
              id: "content-list-463",
              marginLeft: "0",
            },
            {
              value: "New Grid and Overlay Views  ",
              id: "content-list-474",
              marginLeft: "0",
            },
            {
              value: "New Filter Options on DRI and Profile Book Pages ",
              id: "content-list-485",
              marginLeft: "0",
            },
            {
              value: "Improved Personal Folders ",
              id: "content-list-567",
              marginLeft: "0",
            },
            {
              value: "New Hierarchical Level in DRA: Section   ",
              id: "content-list-568",
              marginLeft: "0",
            },
            {
              value: "General System Enhancements and Bug Fixes ",
              id: "content-list-579",
              marginLeft: "0",
            },
          ],
          id: "content-178",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below: ",
          id: "content-620",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2024.2.pdf",
          displayname: "Download DRA Release Notes 2024.2",
          codename: "rn20242",
          id: "content-651",
          marginTop: "0",
          marginBottom: "0",
          content: assets.rn20242,
        },
      ],
    },
    {
      id: "subsection-21",
      title: "2024.1",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-22",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Instant Calculator",
              id: "content-list-24",
              marginLeft: "0",
            },
            {
              value: "Box Plots in Variability Dynamics",
              id: "content-list-25",
              marginLeft: "0",
            },
            {
              value:
                "Standard ‘Process Capability Analysis’ integrated with DRA’s Variability Dynamics ",
              id: "content-list-26",
              marginLeft: "0",
            },
            {
              value: "Clusters and Groups folders on Profile Book ",
              id: "content-list-27",
              marginLeft: "0",
            },
            {
              value: "Color Indicators on Operating Fitness List ",
              id: "content-list-28",
              marginLeft: "0",
            },
            {
              value: "Smart Expression Builder ",
              id: "content-list-29",
              marginLeft: "0",
            },
            {
              value: "Microsoft Entra ID Integration/single sign on",
              id: "content-list-30",
              marginLeft: "0",
            },
            {
              value: "General System Enhancements and Bug Fixes ",
              id: "content-list-31",
              marginLeft: "0",
            },
          ],
          id: "content-23",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below: ",
          id: "content-32",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2024.1.pdf",
          displayname: "Download DRA Release Notes 2024.1",
          codename: "rn20241",
          id: "content-33",
          marginTop: "0",
          marginBottom: "0",
          content: assets.rn20241,
        },
      ],
    },
    {
      id: "subsection-34",
      title: "2023.3.3",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-35",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "'Variability Dynamics' - New Risk Identification Module",
              id: "content-list-37",
              marginLeft: "0",
            },
            {
              value: "'Bad Tags' Page",
              id: "content-list-38",
              marginLeft: "0",
            },
            {
              value: "Option to Re-order Groups",
              id: "content-list-39",
              marginLeft: "0",
            },
            {
              value: "Shutdown Rules and Operating Modes for Clusters",
              id: "content-list-40",
              marginLeft: "0",
            },
            {
              value: "New Operators",
              id: "content-list-41",
              marginLeft: "0",
            },
            {
              value: "General System Enhancements and Bug Fixes",
              id: "content-list-42",
              marginLeft: "0",
            },
          ],
          id: "content-36",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-43",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2023.3.3.pdf",
          displayname: "Download DRA Release Notes 2023.3.3",
          codename: "rn202333",
          id: "content-44",
          marginTop: "0",
          marginBottom: "0",
          content: assets.rn202333,
        },
      ],
    },
    {
      id: "subsection-45",
      title: "2023.3",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-46",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Sloping Trend: Track Changes in Slopes over Time",
              id: "content-list-48",
              marginLeft: "0",
            },
            {
              value:
                "Pattern Notifications (With Easy Click and Drag functionality)",
              id: "content-list-49",
              marginLeft: "0",
            },
            {
              value: "File Attachments for Fault Tree Nodes",
              id: "content-list-50",
              marginLeft: "0",
            },
            {
              value: "‘Custom’ Option in Date Ranges",
              id: "content-list-51",
              marginLeft: "0",
            },
            {
              value:
                "Option to Turn Off Anomaly Calculations for Operating Modes",
              id: "content-list-52",
              marginLeft: "0",
            },
            {
              value: "New Operators",
              id: "content-list-53",
              marginLeft: "0",
            },
            {
              value: "General System Enhancements and Bug Fixes",
              id: "content-list-54",
              marginLeft: "0",
            },
          ],
          id: "content-47",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-55",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2023.3.pdf",
          displayname: "Download DRA Release Notes 2023.3",
          codename: "rn20233",
          id: "content-56",
          marginTop: "0",
          marginBottom: "0",
          content: assets.rn20233,
        },
      ],
    },
    {
      id: "subsection-57",
      title: "2023.2",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-58",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "User Teams",
              id: "content-list-60",
              marginLeft: "0",
            },
            {
              value: "Fault Tree Improvements",
              id: "content-list-61",
              marginLeft: "0",
            },
            {
              value: "Acknowledgement Improvements",
              id: "content-list-62",
              marginLeft: "0",
            },
            {
              value: "General System Enhancements and Bug Fixes",
              id: "content-list-63",
              marginLeft: "0",
            },
          ],
          id: "content-59",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-64",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2023.2.pdf",
          displayname: "Download DRA Release Notes 2023.2",
          codename: "rn20232",
          id: "content-65",
          marginTop: "0",
          marginBottom: "0",
          content: assets.rn20232,
        },
      ],
    },
    {
      id: "subsection-66",
      title: "2023.1",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-67",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "A Contemporary Look and Complementing Capabilities",
              id: "content-list-69",
              marginLeft: "0",
            },
            {
              value: "Fault Trees – Revised and Redesigned",
              id: "content-list-70",
              marginLeft: "0",
            },
            {
              value: "Pattern Matching",
              id: "content-list-71",
              marginLeft: "0",
            },
            {
              value: "Periodicity Monitoring",
              id: "content-list-72",
              marginLeft: "0",
            },
            {
              value: "General Enhancements and Bug Fixes",
              id: "content-list-73",
              marginLeft: "0",
            },
          ],
          id: "content-68",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-74",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2023.1.pdf",
          displayname: "Download DRA Release Notes 2023.1",
          codename: "rn20231",
          id: "content-75",
          content: assets.rn20231,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-76",
      title: "2022.1b",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-77",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Help Page",
              id: "content-list-79",
              marginLeft: "0",
            },
          ],
          id: "content-78",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-80",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2022.1b.pdf",
          displayname: "Download DRA Release Notes 2022.1b",
          codename: "rn20221b",
          id: "content-81",
          content: assets.rn20221b,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-82",
      title: "2022.1",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-83",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Dynamic Trendcharts on Fault Trees and Operating Fitness",
              id: "content-list-85",
              marginLeft: "0",
            },
            {
              value: "General Enhancements and Bug Fixes",
              id: "content-list-86",
              marginLeft: "0",
            },
          ],
          id: "content-84",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-87",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2022.1.pdf",
          displayname: "Download DRA Release Notes 2022.1",
          codename: "rn20221",
          id: "content-88",
          content: assets.rn20221,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-89",
      title: "2021.2b",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-90",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Shift Reports",
              id: "content-list-92",
              marginLeft: "0",
            },
            {
              value: "Fresh Anomaly Folders",
              id: "content-list-93",
              marginLeft: "0",
            },
            {
              value: "Shutdown Buffer",
              id: "content-list-94",
              marginLeft: "0",
            },
            {
              value: "Full-width view for Fault Trees",
              id: "content-list-95",
              marginLeft: "0",
            },
            {
              value: "Public API supports Fault Trees results",
              id: "content-list-96",
              marginLeft: "0",
            },
            {
              value: "New Summary Options for Acknowledgments",
              id: "content-list-97",
              marginLeft: "0",
            },
            {
              value: "Option to View Select Limits in Operating Fitness Report",
              id: "content-list-98",
              marginLeft: "0",
            },
            {
              value: "Security Enhancements and Bug Fixes",
              id: "content-list-99",
              marginLeft: "0",
            },
          ],
          id: "content-91",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-100",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2021.2b.pdf",
          displayname: "Download DRA Release Notes 2021.2b",
          codename: "rn20212b",
          id: "content-101",
          content: assets.rn20212b,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-102",
      title: "2021.1b",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-103",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Improvements in Analytics Algorithms",
              id: "content-list-105",
              marginLeft: "0",
            },
            {
              value: "Export Acknowledgment Summary",
              id: "content-list-106",
              marginLeft: "0",
            },
            {
              value: "Export Personal Notifications",
              id: "content-list-107",
              marginLeft: "0",
            },
            {
              value: "Security Enhancements and Bug Fixes",
              id: "content-list-108",
              marginLeft: "0",
            },
          ],
          id: "content-104",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-109",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2021.1b.pdf",
          displayname: "Download DRA Release Notes 2021.1b",
          codename: "rn20211b",
          id: "content-110",
          content: assets.rn20211b,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-111",
      title: "2021.1",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:\n",
          id: "content-112",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Personal Notifications",
              id: "content-list-114",
              marginLeft: "0",
            },
            {
              value: "New Operators",
              id: "content-list-115",
              marginLeft: "0",
            },
            {
              value: "Fresh Anomaly Report",
              id: "content-list-116",
              marginLeft: "0",
            },
            {
              value: "Add Specific Time Periods in Shutdown Rules",
              id: "content-list-117",
              marginLeft: "0",
            },
            {
              value:
                "Option to Turn Off Positive or Negative Slope Calculations (Sloping Trend)",
              id: "content-list-118",
              marginLeft: "0",
            },
            {
              value: "Option to Download Operating Limits (Operating Fitness)",
              id: "content-list-119",
              marginLeft: "0",
            },
            {
              value: "Speed Improvements and Bug Fixes",
              id: "content-list-120",
              marginLeft: "0",
            },
          ],
          id: "content-113",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-121",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2021.1.pdf",
          displayname: "Download DRA Release Notes 2021.1",
          codename: "rn20211",
          id: "content-122",
          content: assets.rn20211,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-123",
      title: "2020.3",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-124",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "DRA Realtime (Add-On)",
              id: "content-list-126",
              marginLeft: "0",
            },
            {
              value: "Operating Fitness Report",
              id: "content-list-127",
              marginLeft: "0",
            },
            {
              value: "Improved and Faster Analytics",
              id: "content-list-128",
              marginLeft: "0",
            },
            {
              value: "Speed Improvements and Bug Fixes",
              id: "content-list-129",
              marginLeft: "0",
            },
          ],
          id: "content-125",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-130",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2020.3.pdf",
          displayname: "Download DRA Release Notes 2020.3",
          codename: "rn20203",
          id: "content-131",
          content: assets.rn20203,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-132",
      title: "2020.2",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:\n",
          id: "content-133",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Operating Fitness (Add-On)",
              id: "content-list-135",
              marginLeft: "0",
            },
            {
              value: "Sloping Trend Report",
              id: "content-list-136",
              marginLeft: "0",
            },
            {
              value: "Improved Analytics Engine",
              id: "content-list-137",
              marginLeft: "0",
            },
            {
              value: "General Improvements and Bug Fixes",
              id: "content-list-138",
              marginLeft: "0",
            },
          ],
          id: "content-134",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-139",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2020.2.pdf",
          displayname: "Download DRA Release Notes 2020.2",
          codename: "rn20202",
          id: "content-140",
          content: assets.rn20202,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-141",
      title: "2020.1a, 2020.1b",
      content: [
        {
          type: "paragraph",
          content: "This release includes the following new features:",
          id: "content-142",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value: "Fault Tree Heatmap",
              id: "content-list-144",
              marginLeft: "0",
            },
            {
              value: "Create Open Issues",
              id: "content-list-145",
              marginLeft: "0",
            },
            {
              value:
                "Tag Indexing and Validation (Applicable for Customers with PI and CTC historians)",
              id: "content-list-146",
              marginLeft: "0",
            },
            {
              value: "Condensed Reports",
              id: "content-list-147",
              marginLeft: "0",
            },
            {
              value: "Support for Unicode Characters in Comments",
              id: "content-list-148",
              marginLeft: "0",
            },
            {
              value:
                "Faster Loading of DRA Webpages during (a) Slow Network Connectivity and (b) Back-Processing",
              id: "content-list-149",
              marginLeft: "0",
            },
            {
              value: "Better Memory Usage and Faster Processing",
              id: "content-list-150",
              marginLeft: "0",
            },
            {
              value: "General Improvements and Bug Fixes",
              id: "content-list-151",
              marginLeft: "0",
            },
          ],
          id: "content-143",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "For more details, download the full Release Notes (PDF) below:",
          id: "content-152",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "pdf",
          filename: "DRA Release Notes 2020.1a and 2020.1b.pdf",
          displayname: "Download DRA Release Notes 2020.1a and 2020.1b",
          codename: "rn20201a1b",
          id: "content-153",
          content: assets.rn20201a1b,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
  ],
};

export default ReleaseNotes;
