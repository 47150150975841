import * as d3 from "d3";

function getDomainFromScale(
  scale:
    | d3.ScaleLinear<number, number, never>
    | d3.ScaleTime<number, number, never>
) {
  const out = scale.domain();

  const first = out[0];
  const last = out[out.length - 1];

  if (first === undefined || last === undefined) {
    throw new Error("Unexpected undefined in domain");
  }
  return [first.valueOf(), last.valueOf()] as [number, number];
}

function getRangeFromScale(
  scale:
    | d3.ScaleLinear<number, number, never>
    | d3.ScaleTime<number, number, never>
) {
  const out = scale.range();

  const first = out[0];
  const last = out[out.length - 1];

  if (first === undefined || last === undefined) {
    throw new Error("Unexpected undefined in domain");
  }
  return [first, last] as [number, number];
}

function rangeOverlaps(
  [windowStart, windowEnd]: [number, number],
  [start, end]: [number, number]
) {
  return end >= windowStart && start <= windowEnd;
}

function rangeIsInside(
  [windowStart, windowEnd]: [number, number],
  [start, end]: [number, number]
) {
  return start >= windowStart && end <= windowEnd;
}

export { getDomainFromScale, getRangeFromScale, rangeOverlaps, rangeIsInside };
