import React, { useEffect, useState } from "react";
import First from "../../common/First";
import Fragment from "../../common/Fragment";
import MainLoader from "../../common/MainLoader";
import PageError from "../../common/PageError";
import FaultTreeCard from "./FaultTreeManagerCard";
import { useNavigate } from "react-router";
import useAPI from "../../../lib/useAPI";
import FaultTreeTemplate from "../../d3ft/FaultTreeTemplate";
import { useModal } from "../../common/useModal";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import _ from "lodash";

import LoadingScreen from "../../common/LoadingScreen";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { TreeTemplateNumbers } from "../../d3ft/FaultTreeTemplates";
import { createFaultTreeFromTemplate } from "../../../frameworks/fetcher/api-routes-experimental";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

const PAGE_NAME = "Fault Trees";

/**
 * A work around for using React Hooks with a class based component
 */
function FaultTreeManager() {
  usePermissionBasedDocumentTitleForSettingsPage();

  const nav = useNavigate();
  const hasEditAccess = useAuthStore((s) => s.user.hasEditPermission);
  const api = useAPI();
  const { openModal, closeModal } = useModal();

  const [trees, setTrees] = useState([]);

  const [pageError, setPageError] = useState();
  const [initialLoading, setInitialLoading] = useState(false);

  const [loadingMessage, setAPILoadingMessage] = useState(false);

  const baseUrlSlash = useBaseUrlExperimental();

  useEffect(() => {
    api
      .getFaultTrees()
      .then((trees_) => {
        setTrees(trees_);
        setInitialLoading(false);
      })
      .catch((e) => {
        setPageError(e);
      });
  }, []);

  // when you create or copy a fault tree and the API gives you a response...
  function addTreeToState(mongoObject) {
    setTrees((curr) => [...curr, mongoObject]);
  }

  async function deleteTree(tree) {
    await api
      .deleteFaultTree(tree._id)
      .then(() => {
        addSuccessToast(`${tree.name} deleted`);
        setTrees((curr) => curr.filter((t) => !Object.is(t, tree)));
      })
      .catch((errors) => addUnknownErrorToast(errors));
  }

  async function createTree(template) {
    setAPILoadingMessage("Building Fault Tree");
    createFaultTreeFromTemplate(baseUrlSlash, template).then((response) => {
      setTrees((curr) => [...curr, response]);
      //Navigate to editor on new tree creation.
      nav("../../ft/edit/" + response._id, { relative: "path" });
    });
  }

  async function togglePublished(tree) {
    const isPublished = !!tree.published;
    return api
      .patchFaultTree(tree._id, {
        published: !tree.published,
      })
      .then(() => {
        // tree.published = !tree.published;
        setTrees((curr) =>
          _.map(curr, (t) =>
            Object.is(t, tree) ? { ...t, published: !t.published } : t
          )
        );
        addSuccessToast(
          `${isPublished ? "Unpublished" : "Published"} ${tree.name}`
        );
      })
      .catch((errors) => addUnknownErrorToast(errors));
  }

  return (
    <First>
      <MainLoader match={initialLoading && !pageError} />
      <PageError
        match={!!pageError}
        message="An error has occured. Please refresh the page."
      />
      <Fragment match={!initialLoading && !pageError}>
        <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
          <LoadingScreen message={loadingMessage} visible={!!loadingMessage} />
          <div className="flex items-center mb-2 sm:mb-0">
            <span className="text-[2rem] sm:text-[1.75rem] mr-2">
              {PAGE_NAME}
            </span>

            {hasEditAccess && (
              <CreateNewEntityButton
                onClick={() =>
                  openModal({
                    title: "Select a fault tree template to get started",
                    closeLabel: "Cancel",
                    closePosition: "top",
                    closeIcon: true,
                    size: "w-[80vw] max-h-none max-w-none",
                    body: {
                      component: (
                        <div className="w-full max-h-[75vh]">
                          <div className="flex">
                            {TreeTemplateNumbers.map((template) => {
                              return (
                                <FaultTreeTemplate
                                  key={template.toString()}
                                  template={template}
                                  onClick={() => {
                                    createTree(template);
                                    closeModal();
                                    addToast({
                                      title: "Creating new tree...",
                                      variant: "primary",
                                    });
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ),
                    },
                  })
                }
              />
            )}
          </div>
          <div>
            {!!trees.length && (
              <span className="mr-5 italic text-[1.25rem] text-titlegrey">
                Displaying{" "}
                {trees.filter((tree) => hasEditAccess || tree.published).length}{" "}
                {PAGE_NAME}
              </span>
            )}
          </div>
        </div>
        <div className="drop-shadow-md my-6">
          {trees.length ? (
            trees
              .filter((tree) => hasEditAccess || tree.published)
              .map((tree) => (
                <FaultTreeCard
                  addTreeToState={addTreeToState}
                  setAPILoadingMessage={setAPILoadingMessage}
                  key={tree._id}
                  tree={tree}
                  deleteTree={() => deleteTree(tree)}
                  togglePublished={() => togglePublished(tree)}
                />
              ))
          ) : (
            <div className="flex text-textgrey justify-center text-[1.5rem] mt-5">
              <span>No fault trees to display</span>
            </div>
          )}
        </div>
      </Fragment>
    </First>
  );
}

export default FaultTreeManager;
