import { z } from "zod";
import { getAPIUrl } from "../../api-url";

const APIServer = getAPIUrl();

function getFullPath(path: string) {
  return `${APIServer}${z.string().startsWith("/").parse(path)}`;
}

const DEFAULT_HEADERS = {
  "Cache-Control": "no-cache, must-revalidate",
  Pragma: "no-cache",
  "content-type": "application/json; charset=utf-8",
  accept: "application/json",
} as const;

type HeadersPick = { [TKey in keyof typeof DEFAULT_HEADERS]?: boolean };

const DEFAULT_HEADERS_PICK: HeadersPick = {
  "Cache-Control": true,
  Pragma: true,
  "content-type": true,
  accept: true,
};

export async function POST_FILE(url: string, data: FormData) {
  const res = await fetch(getFullPath(url), {
    method: "POST",
    body: data,
    credentials: "include",
  });

  return (await res.json()) as unknown;
}

export async function GET(
  url: string,
  headersPick: HeadersPick = DEFAULT_HEADERS_PICK,
  signal?: AbortSignal
) {
  const res = await RAW_GET(url, headersPick, signal);
  await handleFailedRequest(res);
  return (await res.json()) as unknown;
}

async function handleFailedRequest(res: Response) {
  if (res.ok) return;

  console.error(`Request to ${res.url} failed with status ${res.status}`);

  let json;
  try {
    json = await res.json();
  } catch (e) {
    console.error(`Could not obtain JSON response. Raising error.`);
    throw e;
  }

  const parsedE = z
    .object({
      errors: z.string().array(),
    })
    .safeParse(json);

  if (parsedE.success) throw new Error(parsedE.data.errors.join("\n"));

  let s;

  try {
    s = JSON.stringify(json);
  } catch (e) {
    console.error(`Could not stringify JSON response. Raising error.`, e);
    throw new Error("Unknown error @ " + res.url);
  }

  // throw the error with the response body
  throw new Error(s);
}

export async function RAW_GET(
  url: string,
  headersPick: HeadersPick,
  signal?: AbortSignal
) {
  type partialHeaders = Partial<typeof DEFAULT_HEADERS>;
  const headers: partialHeaders = Object.fromEntries(
    Object.entries(headersPick)
      .filter(([, value]) => value)
      .map(([key]) => [
        key as keyof HeadersPick,
        DEFAULT_HEADERS[key as keyof HeadersPick],
      ])
  );

  return await fetch(getFullPath(url), {
    headers: headers,
    credentials: "include",
    signal,
  });
}

const createFetcher = (method: "POST" | "DELETE" | "PATCH") => {
  return async <T>(url: string, body?: T) => {
    const res = await fetch(getFullPath(url), {
      method,
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(body),
      credentials: "include",
    });

    await handleFailedRequest(res);
    return (await res.json()) as unknown;
  };
};

export const POST = createFetcher("POST");
export const PATCH = createFetcher("PATCH");
export const DELETE = createFetcher("DELETE");
export { DEFAULT_HEADERS_PICK };
