import { useParams } from "react-router";
import useConfigStore, {
  useConfigRequired,
} from "../../../zustand/config/useConfigStore";

/**
 * Can only be used in a context where both auth and config stores are initialized
 */
export default function useCurrentUnitObject(chooseFirstIfNoCurrent?: boolean) {
  const Config = useConfigRequired();
  const configReady = useConfigStore((s) => s.initialized);
  const p = useParams();
  const { unit } = p;

  if (!unit) {
    if (configReady) {
      return chooseFirstIfNoCurrent
        ? Config.units.find((u) => u!.addons && u!.addons.realTime) ||
            Config.units[0]
        : undefined;
    } else {
      return undefined;
    }
  }

  if (!Config) {
    throw new Error("This shouldn't be happening");
  }

  const currentUnit = Config.units.find(
    (u) => u.url.replace("/", "").toLowerCase() === unit.toLowerCase()
  );

  if (!currentUnit && chooseFirstIfNoCurrent) {
    return (
      Config.units.find((u) => u!.addons && u!.addons.realTime) ||
      Config.units[0]
    );
  }

  return currentUnit;
}

export function useHasMultipleHistorians() {
  const currentUnit = useCurrentUnitObject();
  if (!currentUnit) throw new Error("No current unit");
  const hasMultipleHistorians = currentUnit.alternateHistorians.length > 0;

  return hasMultipleHistorians;
}
