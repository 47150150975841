import * as R from "remeda";
import { useSpecialtyReportsQuery } from "../../../hooks/tanstack-query";
import { useUserRequired } from "../../../zustand/auth/useAuthStore";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { type userSchema } from "../../../lib/api-schema/user";
import { Badge } from "../../../shared-ui/frontend/badge";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import { Pencil, Trash2 } from "lucide-react";
import { useState } from "react";
import { Button } from "../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { iife } from "../../../lib/utils";
import {
  UserRoleNumber,
  UserRoleString,
} from "../../../zustand/auth/types/UserRole";
import { UserForm } from "./user-form";
import { useMutation } from "@tanstack/react-query";
import {
  deleteUser,
  updateUser,
} from "../../../frameworks/fetcher/api-routes-experimental";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";

function UserCard({
  user,
  onMutation,
}: {
  user: userSchema;
  onMutation: () => void;
}) {
  const me = useUserRequired();
  const hasEditAccess = me.hasEditPermission;

  const baseUrl = useBaseUrlExperimental();
  const reportsQuery = useSpecialtyReportsQuery(baseUrl);

  const reportNames = R.filter(
    user.reports.map(
      (id) => reportsQuery.data?.find((x) => x._id === id)?.name
    ),
    R.isTruthy
  );

  const [isEditing, setIsEditing] = useState(false);

  const isMyself = user._id === me._id;

  const { canEdit, canDelete: deleteable } = iife(() => {
    const isLessThanMe =
      UserRoleNumber.enum[user.role] < UserRoleNumber.enum[me.role];
    return {
      canEdit: hasEditAccess && (isLessThanMe || isMyself),
      canDelete: hasEditAccess && isLessThanMe && !isMyself,
    };
  });

  const updateUserMut = useMutation({
    mutationFn: (p: {
      email: string;
      first: string;
      last: string;
      reports: string[];
      role: UserRoleString;
    }) => updateUser(baseUrl, user._id, p),
    onSuccess: (_, { first, last }) => {
      onMutation();
      setIsEditing(false);
      addSuccessToast(`${first} ${last} updated`);
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  return (
    <div className="mt-6 border border-solid border-[#dbdbdb] bg-white rounded-lg">
      {/* name and description (hide if this is a new user)  */}
      <div className="border-b border-solid border-bordgrey text-[#000000] flex sm:items-center justify-between text-[1.05em] sm:py-[0.15em] py-[0.35em] px-[0.5em] rounded-t-xl">
        <div className="py-[0.4em] flex flex-col sm:flex-row sm:items-center">
          {user.first && user.last && (
            <span className="font-semibold px-0 sm:my-[0.2em] mx-0">
              {`${user.first} ${user.last}`}
            </span>
          )}
          {user.email && (
            <span className="sm:px-2 font-semibold my-[0.2em] mx-0">
              <a className="text-hrefblue" href={"mailto:" + user.email}>
                {user.email}
              </a>
            </span>
          )}
          {user.role && <Badge variant={"amber"}>{user.role}</Badge>}
        </div>

        {/* edit and delete buttons  */}
        <div className="mt-1 sm:mt-0 flex flex-col sm:flex-row">
          {deleteable && <DeleteButton user={user} onMutation={onMutation} />}

          {canEdit && !isEditing && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={"ghost"}
                    size={"icon"}
                    onClick={() => setIsEditing(true)}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>

      {isEditing ? (
        <UserForm
          className="border-0 py-0"
          onClose={() => setIsEditing(false)}
          onSubmit={updateUserMut.mutate}
          disabled={updateUserMut.isLoading}
          defaultValues={user}
          showRoleSelect
          disableRoleSelect={isMyself}
          includeRootInRoleSelect={user.role === "Root"}
        />
      ) : (
        <>
          {reportNames.length > 0 && (
            <div className="px-2">
              <PillRow label="Reports" items={reportNames} />
            </div>
          )}
          <UpdatedAt timestamp={user.updated_at} />
        </>
      )}
    </div>
  );
}

function DeleteButton({
  user: { _id: userId, first, last },
  onMutation,
}: {
  user: userSchema;
  onMutation: () => void;
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const b = useBaseUrlExperimental();

  const deleteMut = useMutation({
    mutationFn: () => deleteUser(b, userId),
    onSuccess: onMutation,
    onError: (e) => addUnknownErrorToast(e),
  });

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={"ghost"}
              size={"icon"}
              onClick={() => setShowConfirmation(true)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Delete user</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <GenericDeleteTriggerWithConfirmationPopup
        onOpenChange={setShowConfirmation}
        open={showConfirmation}
        confirmDisabled={deleteMut.isLoading}
        onConfirm={deleteMut.mutate}
        title="Are you sure you want to delete this user?"
        description={`${first} ${last}`}
      />
    </>
  );
}

export { UserCard };
