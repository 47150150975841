import { create } from "zustand";

type HoverData = {
  svgX: number;
  windowX: number;
  variableId: string;
  graph: HTMLElement;
};

type VariabilityHoverTooltipStore = {
  data: HoverData | undefined;
  reset: () => void;
  setData: (data: HoverData) => void;
  hideHoverLineFn: undefined | (() => void);
};

export const useVariabilityTooltipStore =
  create<VariabilityHoverTooltipStore>()((set) => {
    return {
      data: undefined,
      setData: (data) => {
        set({ data });
      },
      reset: () => {
        set({ data: undefined });
      },
      hideHoverLineFn: undefined,
    };
  });
