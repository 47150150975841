import _ from "lodash";
import React, { useState, useRef, forwardRef } from "react";
import Form from "../common/Form";
import InputOld from "../common/InputOld";
import useAPI from "../../lib/useAPI";
import LabelMultiSelectDropdownItem from "./LabelMultiSelectDropdownItem";
import LabelMultiSelectValue from "./LabelMultiSelectValue";
import LabelMultiSelectValueWithFixed from "./LabelMultiSelectValueWithFixed";
import { Tooltip } from "@mantine/core";
import Button from "../common/Button/Button";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../toast/use-toast-store";

function CommentCreateOrEditForm({
  closeForm,
  comment,
  users,
  teams,
  variables,
  labels,
  updateState,
  parentData,
}) {
  const api = useAPI();
  const isEditMode = !parentData; // if a comment object is not passed in, we are creating a new reply rather than editing one

  const [selectedTeams, setSelectedTeams] = useState(
    isEditMode
      ? (() => {
          const currentlyAssociatedUsers = comment.tagged_teams.map(
            (t) => t._id
          );

          return teams.reduce((arr, team) => {
            if (currentlyAssociatedUsers.includes(team.value)) {
              arr.push(team);
            }
            return arr;
          }, []);
        })()
      : []
  );
  const [selectedUsers, setSelectedUsers] = useState(
    isEditMode
      ? (() => {
          const currentlyAssociatedUsers = comment.tagged_users.map(
            (u) => u._id
          );

          return users.reduce((arr, user) => {
            if (currentlyAssociatedUsers.includes(user.value)) {
              arr.push(user);
            }
            return arr;
          }, []);
        })()
      : []
  );
  const [selectedLabels, setSelectedLabels] = useState(
    isEditMode
      ? (() => {
          const currentlyAssociatedLabels = comment.labels.map((l) => l.name);

          return labels.reduce((arr, currLabel) => {
            if (currentlyAssociatedLabels.includes(currLabel.label)) {
              arr.push(currLabel);
            }
            return arr;
          }, []);
        })()
      : []
  );

  // we want the first variable of any comment to be a fixed value in the multiple select
  // probably because this comment was made on the trend chart of that variable
  const [selectedVars, setSelectedVars] = useState(
    isEditMode
      ? (() => {
          // const currentlyAssociatedVars = comment.variables.map((v) => v._id)

          return variables.reduce((arr, variable) => {
            const idx = comment.variables.findIndex(
              (v) => v._id === variable.value
            );

            if (idx < 0) {
              // this variable is not associated with this comment
              return arr;
            }

            if (idx === 0) {
              // put this into the multipleselect as a fixed option
              // we assume the first variable in this comment's variables array is the one this comment belongs to
              arr.unshift({ ...variable, isFixed: true });
            } else {
              arr.push(variable);
            }
            return arr;
          }, []);
        })()
      : [
          {
            ...variables.find((v) => v.value === parentData.variableId),
            isFixed: true,
          },
        ]
  );

  const [commentText, setText] = useState(comment?.text || "");

  // booleans to show the extra edit options or not, load them in as shown if there is data present
  const [addUsersAndTeams, setAddUsersAndTeams] = useState(!!comment?.tagged_users?.length || !!comment?.tagged_teams?.length);
  const [addLabels, setAddLabels] = useState(!!comment?.labels?.length);
  const [addVariables, setAddVariables] = useState(false);

  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const submitForm = async (toggleIssueResolution = false) => {
    /**
     * Some things to consider, if the user edits the labels, tagged users, or variables, and then
     * toggles off the edits, make sure to NOT include these edits in the request. In short,
     * only apply the edits that are VISIBLE to the user.
     */
    setLoading(true);
    const data = {};

    if (!commentText) {
      setLoading(false);
      return addToast({
        title: "Comment text is required",
        variant: "danger",
      });
    }

    if (commentText !== (comment?.text || "")) {
      data.text = commentText;
    }

    const users = selectedUsers.map((u) => u.value);
    const teams = selectedTeams.map((t) => t.value);

    if (
      addUsersAndTeams &&
      (isEditMode
        ? !_.isEqual(
            teams,
            comment.tagged_teams.map((t) => t._id)
          )
        : true)
    ) {
      data.tagged_teams = teams;
    }

    if (
      addUsersAndTeams &&
      (isEditMode
        ? !_.isEqual(
            users,
            comment.tagged_users.map((u) => u._id)
          )
        : true)
    ) {
      data.tagged_users = users;
    }

    const vars = selectedVars.map((v) => v.value);

    if (
      addVariables &&
      (isEditMode
        ? !_.isEqual(
            vars,
            comment.variables.map((v) => v._id)
          )
        : true)
    ) {
      data.variables = vars;
    }

    const labels = selectedLabels.map((l) => l.value);

    if (
      addLabels &&
      (isEditMode
        ? !_.isEqual(
            labels,
            comment.labels.map((l) => l._id)
          )
        : true)
    ) {
      data.labels = labels;
    }

    if (!isEditMode) {
      data.variables = vars;
    }

    if (Object.keys(data).length === 0) {
      setLoading(false);
      closeForm();
      return;
    }

    if (toggleIssueResolution) {
      data.issue_resolution = !parentData.isResolved;
    }

    try {
      const payload = await (isEditMode
        ? api.patchComment(comment._id, data)
        : api.postCommentReply(parentData._id, data));
      addSuccessToast(`Comment ${isEditMode ? "edited" : "created"}`);
      updateState(payload, toggleIssueResolution);
    } catch (e) {
      addUnknownErrorToast(e);
    }

    setLoading(false);
    closeForm();
  };

  // by default, no users are selected on a new reply/comment

  return (
    <Form disabled={loading} ref={formRef}>
      <InputOld
        type="textarea"
        value={commentText}
        action={setText}
        classes={{ Input: "w-full" }}
      />
      {/* optional buttons  */}
      <div>
        <Tooltip label="Add Users" withArrow>
          <Button
            icon="at"
            onClick={() => {
              setAddUsersAndTeams(!addUsersAndTeams);
            }}
            className="btn-ghost"
          />
        </Tooltip>
        <Tooltip label="Add Variables" withArrow>
          <Button
            icon="hashtag"
            className="btn-ghost"
            onClick={() => setAddVariables(!addVariables)}
          />
        </Tooltip>
        <Tooltip label="Add Labels" withArrow>
          <Button
            icon="tag"
            onClick={() => setAddLabels(!addLabels)}
            className="btn-ghost"
          />
        </Tooltip>
        <span>(optional)</span>
      </div>
      {addUsersAndTeams ? (
        <InputOld
          type="multipleselect"
          label="Tag Users"
          value={selectedUsers}
          disabled={!!comment?.private}
          action={setSelectedUsers}
          options={users}
        />
      ) : null}
      {addUsersAndTeams ? (
        <InputOld
          type="multipleselect"
          label="Tag Teams"
          value={selectedTeams}
          disabled={!!comment?.private}
          action={setSelectedTeams}
          options={teams}
        />
      ) : null}
      {addVariables ? (
        <InputOld
          type="multipleselect"
          label="Add Variables"
          value={selectedVars}
          action={setSelectedVars}
          options={variables}
          valueComponent={LabelMultiSelectValueWithFixed}
        />
      ) : null}
      {addLabels ? (
        <InputOld
          type="multipleselect"
          label="Add Labels"
          value={selectedLabels}
          action={setSelectedLabels}
          options={labels.map(({ label, value, color }) => ({
            label,
            value,
            color,
          }))}
          itemComponent={LabelMultiSelectDropdownItem}
          valueComponent={LabelMultiSelectValue}
        />
      ) : null}
      <div className="flex justify-between mt-6 gap-1">
        <Button onClick={closeForm} className="btn-outline btn-error" size="xs">
          Cancel
        </Button>
        <Button
          onClick={() => submitForm(false)}
          className="btn-primary ml-auto"
          size="xs"
        >
          {isEditMode ? "save" : "reply"}
        </Button>
        {!isEditMode && parentData.isIssue ? (
          <Button
            onClick={() => submitForm(true)}
            className="btn-primary"
            size="xs"
          >{`REPLY & ${
            parentData.isResolved ? "REOPEN" : "CLOSE"
          } ISSUE`}</Button>
        ) : null}
      </div>
    </Form>
  );
}

export default CommentCreateOrEditForm;
