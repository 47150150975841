import { useGroupsQuery } from "../../../hooks/tanstack-query";

export function useUnitOverviewQuery() {
  const groupsQuery = useGroupsQuery();

  if (groupsQuery.isError) throw groupsQuery.error;
  if (groupsQuery.isLoading) return undefined;

  return groupsQuery.data;
}
