import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtDeleted, unit } from "./common";

const typelessFolderSchema = MongoIdUpdatedAtCreatedAtDeleted.merge(
  unit
).extend({
  name: z.string(),
  short_id: z.number().int(),
  variables: z.string().array().min(1).array(), // must have at least one in every subarray
  // user: this exists too but why do I need data about myself?
});

const multiViewFolderSchema = typelessFolderSchema.extend({
  type: z.literal("savedView"),
});

const regularFolderSchema = typelessFolderSchema.extend({
  type: z.literal("folder"),
});

export const folderSchema = z.union([
  multiViewFolderSchema,
  regularFolderSchema,
]);

export type MultiViewFolder = z.infer<typeof multiViewFolderSchema>;
export type RegularFolder = z.infer<typeof regularFolderSchema>;
export type folderSchema = z.infer<typeof folderSchema>;
