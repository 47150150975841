export function validateFilename(name: string): string | undefined {
  if (!name || !name.trim()) {
    return "Filename is required";
  }

  if (!name.includes(".") || name.trim().endsWith(".")) {
    return "Filename must include an extension";
  }
}

export function validateCodename(name: string): string | undefined {
  if (!name || !name.trim()) {
    return "Codename is required";
  }

  if (name.includes(" ")) {
    return "Codename cannot contain spaces";
  }

  if (name.includes(".")) {
    return "Codename cannot contain periods";
  }
}
