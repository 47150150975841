import React from "react";
import { forwardRef } from "react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  color: string;
}

const LabelMultiSelectDropdownItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, color, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} style={{ color }}>
      {label}
    </div>
  )
);

export default LabelMultiSelectDropdownItem;
