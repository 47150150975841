import { SuccessAlert, WarningAlert, DangerAlert } from "../../common/alert";

const TYPES = {
  warning: "warning",
  success: "success",
  error: "error",
};

function FaultTreeError(props) {
  const type = props.type || TYPES.error;

  if (!TYPES[type]) {
    throw new Error(`unknown type passed into FaultTreeError: ${type}`);
  }

  if (type === TYPES.success) {
    return <SuccessAlert>{props.children}</SuccessAlert>;
  } else if (type === TYPES.error) {
    return <DangerAlert>{props.children}</DangerAlert>;
  } else {
    return <WarningAlert>{props.children}</WarningAlert>;
  }
}

export default FaultTreeError;
