import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtCamel } from "./common";

export const slopeSchema = MongoIdUpdatedAtCreatedAtCamel.extend({
  anom: z.number().int(),
  data: z
    .object({
      date: z.string().datetime(),
      value: z.number(),
    })
    .array()
    .min(2),
  dayDuration: z.number().int(),
  end: z.string().datetime(),
  intercept: z.number(),
  segEnd: z.string().datetime(),
  segStart: z.string().datetime(),
  slope: z.number(),
  start: z.string().datetime(),
  unit: z.string(),
  variableId: z.string(),
  win: z.number().int(),
  firstOccurrence: z.string().datetime().nullish(),
  slopePercentChange: z.number().nullish(),
}).transform((data) => {
  const { data: points } = data;

  const first = points[0];
  const last = points.at(-1);

  if (!first || !last) throw new Error("Invalid points");

  return {
    ...data,
    data: data.data as [
      (typeof data.data)[number],
      (typeof data.data)[number],
      ...typeof data.data,
    ],
  };
});

export type slopeSchema = z.infer<typeof slopeSchema>;
