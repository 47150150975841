import { ComponentProps } from "react";
import { cn } from "../../lib/utils";
import { useBaseUrlExperimental } from "../../zustand/useBaseUrl";
import { LinkWithQuery } from "./LinkWithQuery2";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import { useLocation } from "react-router-dom";
import { OVERALL_GROUP_NAME } from "../../lib/api-schema/misc";
import { useGroupsQuery } from "../../hooks/tanstack-query";
import {
  axisRangeIndexSchema,
  defaultRangeIndex,
} from "../../constants/dateState";

function useNavItems() {
  const currentUnit = useCurrentUnitObject();
  const baseUrlSlash = useBaseUrlExperimental();

  const groupsQuery = useGroupsQuery();

  const { isLoading: groupsLoading, isError } = groupsQuery;

  if (!currentUnit) throw new Error("dont render nav if no unit");

  const getOverallGroup = () => {
    if (groupsLoading || isError) return undefined;
    const overallGroup = groupsQuery.data.find(
      (g) => g.name === OVERALL_GROUP_NAME
    );
    if (!overallGroup) throw new Error("no overall group");
    return overallGroup;
  };

  const overallGroup = getOverallGroup();

  const navItems: ({
    url: string;
    label: string;
  } & Pick<ComponentProps<typeof LinkWithQuery>, "set">)[] = [
    {
      url: baseUrlSlash + "/ov",
      label: "Overview",
    },
    {
      label: "Dynamic Risk Index",
      url: baseUrlSlash + `/dri`,
      set: overallGroup && {
        g: overallGroup.short_id.toString(),
      },
    },
    {
      label: "Profile Book",
      url: baseUrlSlash + `/pb`,

      set: overallGroup && { g: overallGroup.short_id.toString() },
    },
  ];

  if (currentUnit.addons?.faultTrees) {
    navItems.push({
      label: "Fault Trees",
      url: baseUrlSlash + `/ft`,
    });
  }

  if (currentUnit.addons?.operatingLimits) {
    navItems.push({
      label: "Operating Fitness",
      url: baseUrlSlash + `/of`,
    });
  }

  if (currentUnit.addons?.aria) {
    navItems.push({
      label: "ARIA",
      url: baseUrlSlash + `/aria`,
    });
  }

  return navItems.map((n) => {
    const { set } = n;
    const newSet = set ? { ...set } : {};
    newSet.z = axisRangeIndexSchema.parse(defaultRangeIndex).toString();
    return {
      ...n,
      set: newSet,
    };
  });
}

export function CenterNavButtons() {
  const location = useLocation();
  const path = location.pathname;

  const navItemsMaybe = useNavItems();

  if (!navItemsMaybe) return null;

  return (
    <div className="navbar-center lg:items-center h-10 flex">
      {navItemsMaybe.map(({ label, url, set }) => {
        const isActive = path.startsWith(url);

        return (
          <LinkWithQuery
            key={url}
            className={cn(
              "text-sm flex items-center h-full box-border px-4 border-l border-x-zinc-500 py-1 transition border-t-4 border-b-4 border-b-transparent last:border-r",
              isActive
                ? "border-t-primary bg-zinc-700"
                : "hover:border-t-primary hover:bg-zinc-700 border-t-transparent"
            )}
            pick={{ d: true, mo: true, y: true }}
            set={set}
            to={url}
          >
            {label}
          </LinkWithQuery>
        );
      })}
    </div>
  );
}
