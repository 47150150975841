import { create } from "zustand";
import * as React from "react";
import { QueryMode, type LimitLevelNum, type LimitType } from "../constants";
import { addToast } from "../../toast/use-toast-store";

type ExcludeLevels = { [L in LimitLevelNum]: boolean };
type ExcludeTypes = { [T in LimitType]: boolean };

export type OlDetailsStore = {
  excludeLevels: ExcludeLevels;
  excludeTypes: ExcludeTypes;
  queryMode: QueryMode;
  manuallySearchedVariableIds: string[];

  setExcludeLevel: (
    levelsOrFn: ExcludeLevels | ((levels: ExcludeLevels) => ExcludeLevels)
  ) => void;
  setExcludeType: (
    typesOrFn: ExcludeTypes | ((types: ExcludeTypes) => ExcludeTypes)
  ) => void;
  setQueryMode: (mode: QueryMode) => void;
  setManuallySearchedVariableIds: (ids: string[]) => void;
  addSearchVariableId: (id: string) => void;
  removeSearchVariableId: (id: string) => void;
};

function createUseOlDetailsStore() {
  return create<OlDetailsStore>()((set) => {
    const out: OlDetailsStore = {
      excludeLevels: {
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
      },
      excludeTypes: {
        high: false,
        low: false,
      },
      queryMode: "exceedance",
      manuallySearchedVariableIds: [],
      addSearchVariableId: (id) => {
        set((s) => {
          return {
            // add to beginning so new charts appear on top
            manuallySearchedVariableIds: [id, ...s.manuallySearchedVariableIds],
          };
        });
      },
      setManuallySearchedVariableIds: (ids) => {
        set({ manuallySearchedVariableIds: ids });
      },
      removeSearchVariableId: (id) => {
        set((s) => {
          return {
            manuallySearchedVariableIds: s.manuallySearchedVariableIds.filter(
              (i) => i !== id
            ),
          };
        });
      },
      setExcludeLevel: (levelsOrFn) => {
        set((s) => {
          const levels =
            typeof levelsOrFn === "function"
              ? levelsOrFn(s.excludeLevels)
              : levelsOrFn;
          return { excludeLevels: levels };
        });
      },
      setExcludeType: (typesOrFn) => {
        set((s) => {
          const types =
            typeof typesOrFn === "function"
              ? typesOrFn(s.excludeTypes)
              : typesOrFn;

          if (types.high && types.low) {
            addToast({
              title: "At least one of High or Low must remain on",
              variant: "danger",
            });
            return {};
          }
          return { excludeTypes: types };
        });
      },
      setQueryMode: (queryMode) => set({ queryMode }),
    };

    return out;
  });
}

type UseOlDetailsStore = ReturnType<typeof createUseOlDetailsStore>;

const OlDetailsStoreContext = React.createContext<
  UseOlDetailsStore | undefined
>(undefined);

export function OlDetailsStoreProvider(props: React.PropsWithChildren) {
  const store = React.useRef<UseOlDetailsStore>();

  if (!store.current) {
    // initialize the store
    store.current = createUseOlDetailsStore();
  }

  return (
    <OlDetailsStoreContext.Provider value={store.current}>
      {props.children}
    </OlDetailsStoreContext.Provider>
  );
}

export function useGetUseOlDetailsStore() {
  const store = React.useContext(OlDetailsStoreContext);

  if (!store) {
    throw new Error(
      "useGetUseOlDetailsStore must be used within a OlDetailsStoreProvider"
    );
  }

  return store;
}
