import _ from "lodash";
import { useState, useEffect, useCallback, useRef } from "react";
import useAPI from "../../../lib/useAPI";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import moment from "moment";

function formatMoment(mom) {
  return mom.format("MMMM D, YYYY H:mm");
}

function isValidComment(c) {
  return Array.isArray(c.variables) && c.variables.length && c.author;
}

function formatComment(c) {
  return {
    ...c,
    searchString: c.searchString.toLowerCase(),
  };
}

function formatComments(cs) {
  return _.reduce(
    cs,
    (arr, c) => {
      isValidComment(c) && arr.push(formatComment(c));
      return arr;
    },
    []
  );
}

export default function usePaginatedComments() {
  const api = useAPI();
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const [initialLoading, setInitialLoading] = useState(true);

  const [commentsCount, setCount] = useState(null);
  const page = useRef(1);
  const [queryObject, setQueryObject] = useState({ private: false });

  const [downloadLoading, setdownloadLoading] = useState(false);

  const setQueryObjectDebounced = useCallback(
    _.debounce(setQueryObject, 275),
    []
  );

  async function requestData(queryObjectChanged = false) {
    setLoading(true);
    const { docs, has_more } = await api.getComments({
      ...queryObject,
      page: page.current,
    });
    const formatted = formatComments(docs);
    queryObjectChanged
      ? setComments(formatted)
      : setComments((curr) => [...curr, ...formatted]);
    setHasMore(has_more);
    setLoading(false);
    page.current = page.current + 1;
  }

  async function getCommentCounts() {
    const { private: isPrivate, ...rest } = queryObject;

    const payload = { isPrivate };

    _.forEach(rest, (v, k) => {
      if (k === "labelIds[]") {
        payload.labelIds = [v];
      } else {
        payload[k] = v;
      }
    });

    const res = await api.getCommentsSummary(payload, {
      totalsOnly: true,
      include: "replies",
    });
    return res.total + res.included.replies_count.total;
  }

  async function downloadComments() {
    setdownloadLoading(true);

    const { private: isPrivate, ...rest } = queryObject;
    const params = { private: isPrivate, limit: commentsCount, page: 1 };

    _.forEach(rest, (v, k) => {
      if (k === "labelIds[]") {
        params.labelIds = v;
      } else {
        params[k] = v;
      }
    });

    api
      .getComments(params)
      .then(({ docs }) => {
        function generateRowFromComment(c) {
          return {
            "POST TIME": formatMoment(moment(c.edited_at)),
            AUTHOR: c.author
              ? c.author.first + " " + c.author.last
              : "User no longer exists",
            COMMENT: c.text,
            TAGS: c.variables.map((v) => v.name).join(", "),
            "START TIME": formatMoment(moment.utc(c.start_date)),
            "END TIME": formatMoment(moment.utc(c.end_date)),
            LABELS: c.labels.map((l) => l.name).join(", "),
            "USERS TAGGED": c.tagged_users
              .reduce((arr, u) => {
                if (u) {
                  arr.push(u.first + " " + u.last);
                }
                return arr;
              }, [])
              .join(", "),
            "TEAMS TAGGED": c.tagged_teams
              .reduce((arr, t) => {
                if (t) {
                  arr.push(t.name);
                }
                return arr;
              }, [])
              .join(", "),
          };
        }

        const downloadArray = _.reduce(
          docs,
          (arr, c) => {
            const parent = generateRowFromComment(c);
            arr.push(parent);
            arr.push(...c.replies.map(generateRowFromComment));

            // add an empty row
            const empty = _.reduce(
              parent,
              (o, v, k) => {
                o[k] = "";
                return o;
              },
              {}
            );
            arr.push(empty);
            return arr;
          },
          []
        );

        downloadXLSX(downloadArray, "dra_comments.xlsx", [
          "POST TIME",
          "AUTHOR",
          "COMMENT",
          "TAGS",
          "START TIME",
          "END TIME",
          "LABELS",
          "USERS TAGGED",
          "TEAMS TAGGED",
        ]);
      })
      .finally(() => setdownloadLoading(false));
  }

  useEffect(() => {
    page.current = 1;

    setCount(null);
    getCommentCounts().then(setCount);
    requestData(true).then(() => setInitialLoading(false));
  }, [queryObject, api]);

  const loadMore = () => (hasMore ? requestData() : null);

  const update = (commentObj, newCommentObj) => {
    /**
     * find old comment object in state. replace it with the returned
     * object by the API
     */
    setComments((old) =>
      _.reduce(
        old,
        (arr, c) =>
          commentObj.is_root
            ? [...arr, Object.is(commentObj, c) ? newCommentObj : c]
            : [
                ...arr,
                c.discussion_id === commentObj.discussion_id
                  ? {
                      ...c,
                      replies: _.reduce(
                        c.replies,
                        (arr_, r) => [
                          ...arr_,
                          Object.is(commentObj, r) ? newCommentObj : r,
                        ],
                        []
                      ),
                    }
                  : c,
              ],
        []
      )
    );
  };

  /**
   * To be used when a comment reply is created
   */
  const insertReply = (
    parentCommentObj,
    replyCommentObj,
    toggleParentIssueResolution
  ) => {
    setComments((old) => {
      const newComments = [...old];
      const idx = _.findIndex(newComments, (c) =>
        Object.is(c, parentCommentObj)
      );
      const copiedParent = {
        ...parentCommentObj,
        replies: [...parentCommentObj.replies, formatComment(replyCommentObj)],
      };
      if (toggleParentIssueResolution) {
        copiedParent.issue_resolved = !copiedParent.issue_resolved;
      }
      newComments[idx] = copiedParent;

      return newComments;
    });
    setCount((curr) => curr + 1);
  };

  const delete_ = async (obj) => {
    await api.deleteComment(obj._id);
    setComments((old) => {
      if (obj.is_root) {
        return old.filter((c) => !Object.is(c, obj));
      } else {
        const parentComment = old.find(
          (c) => c.discussion_id === obj.discussion_id
        );
        parentComment.replies = parentComment.replies.filter(
          (c) => !Object.is(c, obj)
        );
        return [...old];
      }
    });
    setCount((curr) => curr - 1);
  };

  return {
    downloadComments,
    downloadLoading,
    searchString: queryObject.search,
    loading,
    initialLoading,
    comments,
    loadMore,
    setQueryObjectDebounced,
    setQueryObject,
    hasMore,
    delete: delete_,
    update,
    insertReply,
    commentsCount,
  };
}
