/**
 * Global colors for anomaly
 */

/**
 * If we use this in multiple places on the same page and we perhaps
 * mess with the order
 */
const getAriaColors = () =>
  [
    {
      value: 0,
      color: "green",
      label: "No Drift",
      // // #D5B3F5
      // r: 213,
      // g: 179,
      // b: 245,
      // #EBDCFC
      r: 235,
      g: 220,
      b: 252,
      rgb: "",
    },
    {
      value: 1,
      color: "orange",
      label: "Moderate Drift",
      // // #A95CE1
      // r: 169,
      // g: 92,
      // b: 225,
      // // #9E41DC
      // r: 158,
      // g: 65,
      // b: 220,
      // // #B176E3
      // r: 177,
      // g: 118,
      // b: 227,
      // #b984e6
      r: 185,
      g: 132,
      b: 230,
      rgb: "",
    },
    {
      value: 2,
      color: "red",
      label: "Major Drift",
      // // #55147A
      // r: 85,
      // g: 20,
      // b: 122,
      // #681E94
      r: 104,
      g: 30,
      b: 148,
      rgb: "",
    },
  ].map((o) => {
    const { r, g, b } = o;
    o.rgb = `rgb(${r}, ${g}, ${b})`;
    return o;
  });

export default getAriaColors;
