import { useState, useEffect } from "react";

export default function CommentViewTypeSelect({
  initialLoading,
  value,
  onChange,
  options,
}) {
  return (
    <div className="form-control">
      <label className="input-group input-group-sm">
        <span>View</span>
        {initialLoading ? null : (
          <select
            className="select select-sm select-bordered text-center"
            onChange={({ target: { value } }) => onChange(value)}
            value={value}
            disabled={initialLoading}
          >
            {options.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
        )}
      </label>
    </div>
  );
}
