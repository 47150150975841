import { Alert } from "@mantine/core";
import React, { useEffect } from "react";
import Input from "../../../common/Input/Input";
import { type Content } from "../types/BuilderTypes";
import type EditorProps from "./EditorProps";
import { z } from "zod";

type Props = EditorProps<Content>;

function MarginInputs({
  editContent,
  content: { marginBottom, marginTop },
}: Props) {
  /**
   * Since we added this feature later, we want to make sure every old page has it by handling it here
   */
  useEffect(() => {
    editContent((c) => {
      if (!marginTop) {
        c.marginTop = "0";
      }
      if (!marginBottom) {
        c.marginBottom = "0";
      }
    });
  }, []);

  function generateError(key: string, marg?: string) {
    if (!marg || !marg.trim()) {
      return key + " is required";
    }

    const parsed = z.coerce.number().safeParse(marg);

    if (!parsed.success) {
      return key + " is not a number";
    }

    if (parsed.data.toString() !== marg) {
      return key + " cannot contain spaces";
    }
  }

  const err =
    generateError("Margin top", marginTop) ||
    generateError("Margin bottom", marginBottom);

  const errorComponent = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  return (
    <>
      {errorComponent}
      <label>
        Margin Top
        <Input
          bordered
          size="sm"
          value={marginTop ?? "0"}
          onChange={(e) => {
            editContent((c) => {
              c.marginTop = e.target.value;
            });
          }}
        />
      </label>
      <label>
        Margin Bottom
        <Input
          bordered
          size="sm"
          value={marginBottom ?? "0"}
          onChange={(e) => {
            editContent((c) => {
              c.marginBottom = e.target.value;
            });
          }}
        />
      </label>
    </>
  );
}

export default MarginInputs;
