import { z } from "zod";
import { limitLevelSchema } from "../../../components/of/constants";
import { variableSchema } from "../../api-validators";

export const probabilityOrExceedanceVariablesSchema = z.object({
  fullVariableCount: z.number().int().min(0),
  exceedanceCount: z.number().int().min(0),
  levelCounts: z.record(limitLevelSchema, z.number().int().min(0)),
  matchingLimitIds: z.array(z.string()),
  variables: variableSchema.array(),
  variableLimitMap: z.record(
    z.string(),
    z.object({
      limits: z
        .object({
          level: limitLevelSchema,
          totalDuration: z.number().int(),
        })
        .array(),
    })
  ),
});

export type probabilityOrExceedanceVariablesSchema = z.infer<
  typeof probabilityOrExceedanceVariablesSchema
>;
