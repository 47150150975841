import useAllowedUnits from "../../common/hooks/useAllowedUnits";
import { getGroups } from "../../../frameworks/fetcher/api-routes-experimental";
import { validateBaseUrl } from "../../../zustand/useBaseUrl";
import { groupSchema } from "../../../lib/api-validators";
import { OVERALL_GROUP_NAME } from "../../../lib/api-schema/misc";
import { useQueries } from "@tanstack/react-query";

export function usePlantOverviewQuery() {
  const allowedUnits = useAllowedUnits();
  const groupsQueries = useQueries({
    queries: allowedUnits.map((unit) => {
      const baseUrl = validateBaseUrl(`/${unit.name}`);
      return {
        queryKey: ["groups", baseUrl],
        queryFn: () =>
          getGroups(baseUrl).then((groups) =>
            groupSchema.array().parse(groups)
          ),
      };
    }),
  });

  const dataOut = groupsQueries.map((q, index) => {
    if (q.isError) {
      return undefined;
    }

    if (q.isLoading) {
      return undefined;
    }

    const unitObject = allowedUnits[index];

    if (!unitObject) throw new Error("unitObject is undefined");

    const overallGroup = q.data.find((g) => g.name === OVERALL_GROUP_NAME);
    if (!overallGroup) throw new Error("no overall group found");

    return {
      overallGroup,
      unitData: {
        name: unitObject.name,
        shortName: unitObject.shortName.toUpperCase(),
        displayName: unitObject.displayName.toUpperCase(),
        realTime: !!unitObject.addons?.realTime,
      },
    };
  });

  return dataOut;
}
