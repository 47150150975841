import { RAW_GET } from "../../../frameworks/fetcher/fetcher-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import classNames from "classnames";
import { faultTreeNodeFileSchema } from "../../../lib/api-schema/ft/fault-tree-node-file";
import { cn } from "../../../lib/utils";
import { Trash2 } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { deleteFtNodeFile } from "../../../frameworks/fetcher/api-routes-experimental";
import { addToast } from "../../toast/use-toast-store";

export function FilesPills({
  files,
  className,
  allowDelete,
}: {
  files: faultTreeNodeFileSchema[];
  className?: string;
  allowDelete?: boolean;
}) {
  const baseUrlSlash = useBaseUrlExperimental();
  const deleteMutation = useMutation({
    mutationFn: async (fileId: string) => {
      await deleteFtNodeFile(baseUrlSlash, fileId);
      return true;
    },
  });

  if (!files || files.length === 0) return null;

  const downloadFile = async (file: (typeof files)[number]) => {
    RAW_GET(`${baseUrlSlash}/fault-tree-nodes/file/${file._id}`, {
      "Cache-Control": true,
      "content-type": true,
      Pragma: true,
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        // Create a downloadable link
        const a = document.createElement("a");
        a.href = url;
        a.download = file.filename;
        document.body.appendChild(a);
        a.click();
        // addToast({
        //   title: file.filename,
        //   description: `Downloaded ${file.filename}`,
        // });
        // Clean up
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  };

  const deletingDisabled = deleteMutation.isLoading;

  return (
    <>
      <div
        className={cn(
          "flex items-center gap-2 animate-in slide-in-from-bottom-3",
          className
        )}
      >
        <ul
          className={cn(
            "gap-2 flex w-full",
            allowDelete ? "flex-col" : "flex-wrap"
          )}
        >
          {files.map((file) => {
            const { filename, _id } = file;
            return (
              <div
                className={cn(
                  "justify-between items-center",
                  allowDelete ? "flex" : "inline-flex max-w-min"
                )}
              >
                <button
                  key={_id}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(file);
                  }}
                  className={classNames(
                    "badge badge-xs border-zinc-400 text-indigo-600 text-[13px] p-[0.6rem] min-w-max active:scale-95 transition-all hover:bg-indigo-600 hover:text-white bg-zinc-100"
                  )}
                >
                  {filename}
                </button>
                {allowDelete && (
                  <Trash2
                    onClick={
                      deletingDisabled
                        ? undefined
                        : () => {
                            const shouldDelete = window.confirm(
                              `Are you sure you want to delete "${filename}?"`
                            );

                            if (!shouldDelete) return;

                            deleteMutation
                              .mutateAsync(_id)
                              .then(() => {
                                addToast({
                                  title: `${filename} deleted`,
                                  description: "",
                                  variant: "success",
                                });
                              })
                              .then(() => {
                                deleteMutation.reset();
                              });
                          }
                    }
                    className={cn(
                      "w-4 h-4",
                      deletingDisabled
                        ? "text-zinc-200"
                        : "text-xred-9 hover:text-xred-10 cursor-pointer active:scale-95"
                    )}
                  />
                )}
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
}
