import { cn } from "../../../lib/utils";
import * as React from "react";
import Button from "../../common/Button/Button";
import { PopoverContent } from "../../ui/popover";
import { PropsWithCn } from "../../types/component.types";

type ButtonProps = {
  selected: boolean;
  onClick: () => void;
  label: string;
};

export function CustomRangeButtonPopoverContent({
  children,
  endButton: end,
  startButton: start,
  className,
  ...popoverContentProps
}: React.PropsWithChildren<
  {
    startButton: ButtonProps;
    endButton: ButtonProps;
  } & Pick<
    React.ComponentProps<typeof PopoverContent>,
    "side" | "sideOffset" | "onInteractOutside" | "withoutPortal"
  > &
    PropsWithCn
>) {
  return (
    <PopoverContent
      /**
       * When the popover is open, clicking outside of it will not close it.
       * This is required for the variability drawer. It was behaving weird
       * without this. You can remove it to see its behavior. To replicate,
       * comment out this prop, open a variability view, open the time picker
       * component in the custom date range calendar. It'll flicker and close
       * the whole popover.
       *
       * TODO: consider making this a prop so that we can turn it off for
       * places that don't need it because ideally we DO want this behavior
       * in other places. But it doesn't hurt anything for now.
       * */
      className={cn(
        "rounded-md overflow-clip border-zinc-400 p-1 z-[201]",
        className
      )}
      onOpenAutoFocus={(e) => e.preventDefault()}
      {...popoverContentProps}
    >
      <div className={cn("flex justify-between items-center gap-3")}>
        <Button
          size="sm"
          className={cn(
            "normal-case font-normal",
            !start.selected ? "btn-ghost" : "btn-primary"
          )}
          onClick={start.onClick}
        >
          {start.label}
        </Button>
        <div className="w-[10px] h-[2px] bg-slate-600 rounded-md" />
        <Button
          size="sm"
          onClick={end.onClick}
          className={cn(
            "normal-case font-normal",
            !end.selected ? "btn-ghost" : "btn-primary"
          )}
        >
          {end.label}
        </Button>
        {children}
      </div>
    </PopoverContent>
  );
}
