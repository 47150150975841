import React, { PropsWithChildren } from "react";
import useAuthStore from "../../zustand/auth/useAuthStore";
import useConfigStore from "../../zustand/config/useConfigStore";

/**
 * The app cannot be loaded without the config, auth, and datestate initialized
 */
function AppDataBoundary({ children }: PropsWithChildren) {
  const authReady = useAuthStore((s) => s.initialized);
  const configReady = useConfigStore((s) => s.initialized);

  return configReady && authReady ? <>{children}</> : null;
}

export default AppDataBoundary;
