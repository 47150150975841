import BellNotificationButton from "../../common/BellNotificationButton";
import { useUserRequired } from "../../../zustand/auth/useAuthStore";
import { AckTypeDropdown } from "../../ack/AckTypeDropdown";
import { getAcknowledgements } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useDateState } from "../../../zustand/useDateState";
import { useGetUseFaultTreeDetailsStore } from "../details/ft-details-store";
import useAPI from "../../../lib/useAPI";
import { useRefetchFaultTreeChartQuery } from "../../d3ft/FaultTreeChart2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { YYYY_MM_DD } from "../../../lib/validators";
import { AxisRangeButtonGroupAndLabelToLeft } from "../../dateSelector/axis-range-button-group";
import { Button } from "../../../shared-ui/frontend/button";
import { X } from "lucide-react";
import { DrawerClose } from "../../../shared-ui/frontend/drawer";

enum STATUS_ENUM {
  INACTIVE,
  ACTIVE,
  PROCESSING,
  ERROR,
}

export function useInvalidateAckQueriesForVariable() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return (nodeOrVariableId: string) => {
    cli.invalidateQueries(["acks", baseUrl, nodeOrVariableId]);
  };
}

function useFaultTreeNodeAckQuery(
  nodeId: string,
  start: YYYY_MM_DD,
  end: YYYY_MM_DD
) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["acks", baseUrl, nodeId, start, end],
    queryFn: () =>
      getAcknowledgements(baseUrl, {
        end: start,
        start: end,
        varId: nodeId,
      }).then((acks_) =>
        [...acks_].sort(
          (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        )
      ),
  });
}

export function Header() {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const tree = useFaultTreeDetailsStore((s) => s.tree);
  const causeFtToRedraw = useRefetchFaultTreeChartQuery(); // a hook that invalidates the query used to draw the chart, so your acks will show up if you call this

  const invalidateAckQueries = useInvalidateAckQueriesForVariable(); // the ft status series thingy uses a query with the same key
  const api = useAPI();

  if (!selectedNode) throw new Error("this component shouldn't render then");

  const $ds = useDateState();
  const selectedDate = $ds.axisRangeTo.dateString;
  const MY_ID = useUserRequired()._id;

  const chartStatusIcon = (() => {
    const nodeStatus = selectedNode.status;
    let nodeStatusIconStyles = "";
    let nodeStatusIconText = "";

    switch (nodeStatus) {
      case STATUS_ENUM.ACTIVE:
        nodeStatusIconStyles = "active";
        nodeStatusIconText = "ACTIVE";
        break;
      case STATUS_ENUM.PROCESSING:
        nodeStatusIconStyles = "processing";
        nodeStatusIconText = "PROCESSING";
        break;
      case STATUS_ENUM.ERROR:
        nodeStatusIconStyles = "error";
        nodeStatusIconText = "ERROR";
        break;
      case STATUS_ENUM.INACTIVE:
        nodeStatusIconStyles = "inactive";
        nodeStatusIconText = "INACTIVE";
        break;
      default:
        nodeStatusIconStyles = "inactive";
        nodeStatusIconText = "UNKNOWN";
    }

    return (
      <div
        className={`faultTree-nodeDetails-status-icon ${nodeStatusIconStyles}`}
      >
        {nodeStatusIconText}
      </div>
    );
  })();

  const acksQuery = useFaultTreeNodeAckQuery(
    selectedNode._id,
    selectedDate,
    selectedDate
  );
  const acks = acksQuery.data ?? [];

  const getErrorComponent = () => {
    const error = selectedNode.error;

    switch (error) {
      case "Task":
        return "Error processing 'Tag Expression'. Check if expression is valid.";
      case "Expression":
        return "Error parsing 'Node Expression'. Check if expression is valid.";
      case "Corrupt":
        return "Fault Tree Node is corrupt. Contact NMM support.";
      default:
        return null;
    }
  };

  const errorComponent = getErrorComponent();

  const participatedInAcks = acks.some((a) => a.author._id === MY_ID);
  const myMostRecentAck = acks.find((a) => a.author._id === MY_ID);

  return (
    <div className="flex flex-col lg:flex-row justify-between lg:items-center">
      <div className={"faultTree-nodeDetails-header"}>
        {chartStatusIcon}
        <span className={"faultTree-nodeDetails-header-name"}>
          {selectedNode.name}
        </span>

        {errorComponent && (
          <div className="faultTree-nodeDetails-header-error">
            {errorComponent}
          </div>
        )}

        <div className="ml-2 inline">
          <BellNotificationButton
            position="bottom-end"
            variableId={selectedNode._id}
            notificationTypes={["FaultTreeNode"]}
            operatingLimitId={undefined}
            groupId={undefined}
          />
        </div>
        <div className="inline ml-2">
          <AckTypeDropdown
            acks={acks}
            className={"dropdown-bottom"}
            acknowledge={(type) => {
              const newAckStatus = participatedInAcks
                ? !myMostRecentAck?.unacknowledgment
                : false;
              api
                .postAcknowledgement(
                  selectedNode._id,
                  selectedDate,
                  selectedDate,
                  // if we're changing the ack type, then we're acknowledging
                  myMostRecentAck?.type === type ? newAckStatus : false,
                  type
                )
                .then(() => {
                  invalidateAckQueries(selectedNode._id);
                  acksQuery.refetch();
                  if (!tree) throw new Error("how?");
                  causeFtToRedraw(tree.mainTree._id, selectedDate);
                });
            }}
          />
        </div>
      </div>

      <div className="flex items-center">
        <AxisRangeButtonGroupAndLabelToLeft className="text-[0.8rem]" />
        <DrawerClose asChild>
          <Button size="icon" variant={"ghost"}>
            <X className="size-4" />
          </Button>
        </DrawerClose>
      </div>
    </div>
  );
}
