/**
 * Global colors for anomaly
 */

/**
 * If we use this in multiple places on the same page and we perhaps
 * mess with the order
 */
const getColors = () =>
  [
    {
      value: 0,
      color: "green",
      label: "No Risk",
      r: 136,
      g: 196,
      b: 37,
    },
    {
      value: 1,
      color: "yellow",
      label: "Low Risk",
      r: 248,
      g: 202,
      b: 0,
    },
    {
      value: 2,
      color: "orange",
      label: "Medium Risk",
      r: 233,
      g: 127,
      b: 2,
    },
    {
      value: 3,
      color: "red",
      label: "High Risk",
      r: 194,
      g: 26,
      b: 1,
    },
  ].map((o) => {
    const { r, g, b } = o;
    o.rgb = `rgb(${r}, ${g}, ${b})`;
    return o;
  });

export default getColors;
