import React from "react";
import Button from "./Button/Button";

type AlertClass = {
  classes?: string;
};

export function SuccessAlert(props: React.PropsWithChildren<AlertClass>) {
  return (
    <div
      className={
        "alert alert-success shadow-md" +
        (props.classes ? ` ${props.classes}` : "")
      }
    >
      <div>
        <svg
          className="stroke-current flex-shrink-0 h-6 w-6 overflow-visible"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></path>
        </svg>
        <span>{props.children}</span>
      </div>
    </div>
  );
}

export function DangerAlert(props: React.PropsWithChildren<AlertClass>) {
  return (
    <div
      className={
        "alert alert-error shadow-md" +
        (props.classes ? ` ${props.classes}` : "")
      }
    >
      <div>
        <svg
          className="stroke-current flex-shrink-0 h-6 w-6 overflow-visible"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></path>
        </svg>
        <span>{props.children}</span>
      </div>
    </div>
  );
}

export function WarningAlert(props: React.PropsWithChildren<AlertClass>) {
  return (
    <div
      className={
        "alert alert-warning shadow-md" +
        (props.classes ? ` ${props.classes}` : "")
      }
    >
      <div>
        <svg
          className="stroke-current flex-shrink-0 h-6 w-6 overflow-visible"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></path>
        </svg>
        <span>{props.children}</span>
      </div>
    </div>
  );
}

export function Alert({
  error,
  clear,
  classes,
}: {
  error?: {
    isError: boolean;
    message: string;
    closeable: boolean;
  };
  classes?: string;
  clear: NonNullable<React.ComponentProps<"button">["onClick"]>;
}) {
  return error ? (
    error.isError ? (
      <div
        className={
          "alert alert-error shadow-md" + (classes ? ` ${classes}` : "")
        }
      >
        <div>
          <svg
            className="stroke-current flex-shrink-0 h-6 w-6 overflow-visible" // the SVG is resizing oddly sometimes
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error.message}</span>
        </div>
        {error.closeable && (
          <div className="flex-none">
            <Button className="btn-ghost" onClick={clear}>
              Close
            </Button>
          </div>
        )}
      </div>
    ) : (
      <div className="alert alert-success shadow-md">
        <div>
          <svg
            className="stroke-current flex-shrink-0 h-6 w-6 overflow-visible"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></path>
          </svg>
          <span>{error.message}</span>
        </div>
        {error.closeable && (
          <div className="flex-none">
            <Button className="btn-ghost" onClick={clear}>
              Close
            </Button>
          </div>
        )}
      </div>
    )
  ) : null;
}
