import React, { useState } from "react";
import First from "../First";
import Fragment from "../Fragment";
import InputOld from "../InputOld";
import Input from "../Input/Input";
import _ from "lodash";
// import ExpressionBuildingInput from "../ExpressionBuildingInput";
import Button from "../Button/Button";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

function CreateEntityForm(props) {
  const getInitialValues = () => {
    const form = props.form;
    const out = {};
    form.forEach((f) => {
      const { datakey, defaultValue } = f;
      out[datakey] = defaultValue;
    });

    return out;
  };
  const [formState, setFormState] = useState(getInitialValues());

  const bordered = props.bordered;
  const rounded = props.rounded;
  // disable form on submission
  const [loading, setLoading] = useState(false);

  const submitForm = () => {
    setLoading(true);
    // apply custom validation function on the form
    const errorMessage = props.validation ? props.validation(formState) : false;

    if (errorMessage) {
      setLoading(false);
      return addToast({
        title: errorMessage,
        variant: "danger",
      });
    }

    /**
     * props have some options for building the JSON passed to fetch
     * for example, some transforming functions, whether or not to
     * include some keys, or to create new keys based off existing ones,
     * or whether to only include those values that have changed by the user.
     * More useful in edit mode rather than create new mode.
     *
     * This implementation aims to do exactly what the Sails app does,
     * in terms of what to include/exclude in JSON.
     */
    const dataToApi = {};

    props.form.forEach(
      ({
        datakey,
        defaultValue,
        transform,
        conditionals,
        _return,
        returnOnlyIfDifferent,
      }) => {
        const currValue = formState[datakey];
        if (_return) {
          // we might only want to include the value only if it has changes for Patch requests
          // to decrease payload size
          // a small optimization
          if (returnOnlyIfDifferent) {
            if (
              currValue !== defaultValue &&
              !_.isEqual(currValue, defaultValue)
            ) {
              if (conditionals) {
                if (
                  Object.entries(conditionals).reduce(
                    (prevBool, [datakey, value]) =>
                      prevBool && formState[datakey] === value,
                    true
                  )
                ) {
                  dataToApi[datakey] = transform
                    ? transform(formState)
                    : currValue;
                } else {
                  // condition doesn't match, don't return it
                }
              } else {
                // no conditions exist, but value is different from default, return it
                dataToApi[datakey] = transform
                  ? transform(formState)
                  : currValue;
              }
            } else {
              // current value is the default, don't include it
            }
          } else {
            // return the value regardless of whether it's different from its default
            if (conditionals) {
              if (
                Object.entries(conditionals).reduce(
                  (prevBool, [datakey, value]) =>
                    prevBool && formState[datakey] === value,
                  true
                )
              ) {
                dataToApi[datakey] = transform
                  ? transform(formState)
                  : currValue;
              } else {
                // condition doesn't match, don't return it
              }
            } else {
              // no conditions exist, return it
              dataToApi[datakey] = transform ? transform(formState) : currValue;
            }
          }
        }
      }
    );

    if (Object.keys(dataToApi).length === 0) {
      setLoading(false);
      props.close?.(); // close the form
      addSuccessToast("Saved");
      return;
    }

    const { submit, successMessage } = props.submission;

    return submit(props.transform ? props.transform(dataToApi) : dataToApi)
      .then((responseObj) => {
        props.close?.(); // close the form
        addSuccessToast(successMessage(responseObj));
        setFormState(getInitialValues());
      })
      .catch((e) => addUnknownErrorToast(e))
      .finally(() => {
        setLoading(false);
      });
  };

  if (Array.isArray(props.buttons)) {
    throw new Error("deprecated buttons prop");
  }

  return (
    <div
      className={
        "p-4" +
        (bordered ? " mt-3 border-1 border border-bordgrey bg-white" : "") +
        (rounded ? " rounded-xl" : "")
      }
    >
      {props.form.map((f) => {
        return (
          <div className="form-control w-full" key={f.datakey}>
            {((inputType) => {
              if (inputType === "text") {
                return (
                  <First key={f.datakey}>
                    <InputOld
                      classes={{ Input: "input-bordered" }}
                      tooltip={f.tooltip}
                      match={
                        f.conditionals
                          ? Object.entries(f.conditionals).reduce(
                              (prevBool, [datakey, value]) =>
                                prevBool && formState[datakey] === value,
                              true
                            )
                          : null
                      }
                      type={f.type}
                      disabled={loading || !!f.disabled}
                      value={formState[f.datakey]}
                      label={f.label}
                      hideLabel={f.hideLabel}
                      action={(value) =>
                        setFormState((oldState) => ({
                          ...oldState,
                          [f.datakey]: value,
                        }))
                      }
                    />
                  </First>
                );
              } else if (inputType === "expression") {
                return (
                  <First key={f.datakey}>
                    <label className="text-[#5b616b] mt-2 uppercase">
                      {f.label}
                      <Input
                        defaultValue={f.defaultValue}
                        options={f.options}
                        className={"w-full"}
                        placeholder={f.hint}
                        bordered={true}
                        match={
                          f.conditionals
                            ? Object.entries(f.conditionals).reduce(
                                (prevBool, [datakey, value]) =>
                                  prevBool && formState[datakey] === value,
                                true
                              )
                            : null
                        }
                        disabled={loading || !!f.disabled}
                        onChange={(v) =>
                          setFormState((oldState) => ({
                            ...oldState,
                            [f.datakey]: v.target.value,
                          }))
                        }
                      />
                    </label>
                  </First>
                );
              } else if (inputType === "select") {
                return (
                  <Fragment key={f.datakey}>
                    <InputOld
                      bordered={f.bordered}
                      size={f.size}
                      style={f.style}
                      type={f.type}
                      classes={f.classes}
                      disabled={loading || f.disabled}
                      value={formState[f.datakey]}
                      label={f.label}
                      hideLabel={f.hideLabel}
                      action={(value) =>
                        setFormState((oldState) => ({
                          ...oldState,
                          [f.datakey]: value,
                        }))
                      }
                      options={f.options}
                    />
                    <First>
                      <Fragment
                        // this match checks if the conditionalRender Object exists
                        match={f.options.reduce(
                          (prevBool, currOptions) =>
                            prevBool || !!currOptions.conditionalRender,
                          false
                        )}
                      >
                        {(() => {
                          const conditionalRender = f.options.find(
                            ({ value }) => value === formState[f.datakey]
                          )?.conditionalRender;

                          return conditionalRender ? (
                            <InputOld
                              hint={conditionalRender.hint}
                              type={conditionalRender.type}
                              classes={conditionalRender.classes}
                              disabled={loading}
                              value={formState[conditionalRender.datakey] ?? ""}
                              label={conditionalRender.label}
                              hideLabel={conditionalRender.hideLabel}
                              action={(value) =>
                                setFormState((oldState) => ({
                                  ...oldState,
                                  [conditionalRender.datakey]: value,
                                }))
                              }
                            />
                          ) : null;
                        })()}
                      </Fragment>
                    </First>
                  </Fragment>
                );
              } else if (inputType === "toggle") {
                const { label, datakey, defaultValue } = f;

                const Input = (
                  <Fragment>
                    <input
                      checked={formState[datakey]}
                      disabled={loading}
                      onChange={(e) =>
                        setFormState((oldState) => ({
                          ...oldState,
                          [datakey]: e.target.checked,
                        }))
                      }
                      type="checkbox"
                      className="toggle checked:border-primary checked:bg-primary"
                    />
                    <span className="ml-4">{label}</span>
                  </Fragment>
                );
                return (
                  <First key={f.datakey}>
                    <Fragment match={!!f.container}>
                      <div className={f.container.style || ""}>{Input}</div>
                    </Fragment>
                    <Fragment match={!f.container}>{Input}</Fragment>
                  </First>
                );
              } else if (inputType === "multipleselect") {
                const { label, defaultValue, datakey, options } = f;
                return (
                  <InputOld
                    key={f.datakey}
                    tooltip={f.hint}
                    disabled={loading}
                    label={label}
                    type="multipleselect"
                    action={(value) =>
                      setFormState((oldState) => ({
                        ...oldState,
                        [datakey]: value,
                      }))
                    }
                    value={formState[datakey]}
                    options={[...defaultValue, ...options]}
                  />
                );
              } else if (inputType === "calendar") {
                const {
                  label,
                  datakey,
                  dateFormat,
                  disabledDays,
                  onCalenderDateChange,
                } = f;
                return (
                  <InputOld
                    key={f.datakey}
                    classes={{
                      container: "pl-1 mt-2",
                    }}
                    label={label}
                    type="calendar"
                    dateFormat={dateFormat}
                    disabledDays={disabledDays}
                    value={formState[datakey]}
                    action={(value) => {
                      onCalenderDateChange(value);
                      setFormState((oldState) => ({
                        ...oldState,
                        [datakey]: value,
                      }));
                    }}
                  />
                );
              }
            })(f.type)}
          </div>
        );
      })}

      <div className="flex justify-between mb-2 mt-6 px-0">
        {props.close ? (
          <Button
            disabled={loading}
            onClick={() => {
              setFormState(getInitialValues());
              props.close();
            }}
            className="btn-outline btn-error"
          >
            Cancel
          </Button>
        ) : (
          <div />
        )}
        <div className="flex gap-2">
          {/* there can be extra optional buttons  */}
          {/* {Array.isArray(props.buttons) &&
            props.buttons.map((btn, idx) => {
              btn.disabled = btn.disabled || loading;
              return (
                <InputOld
                  {...btn}
                  key={`${idx}`}
                  disabled={loading || btn.disabled}
                  action={() => btn.action(formState)}
                />
              );
            })} */}

          <Button
            loading={loading}
            disabled={loading}
            onClick={() => submitForm()}
            className="btn-primary"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateEntityForm;
