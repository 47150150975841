import * as React from "react";
import { ellipsify } from "../../utils/stylable";
import { Loader, Tooltip } from "@mantine/core";
import Input from "../../common/Input/Input";
import { useAckManagerAnomaliesQuery } from "./use-ack-man-anomalies-query";
import { useGetUseAckManStore } from "./store/ack-man-store";
import moment from "moment";
import { cn } from "../../../lib/utils";
import { FaCheck } from "react-icons/fa";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (cb: (...args: any[]) => any, delay = 300) => {
  let timeout: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => cb(...args), delay) as unknown as number;
  };
};

enum DisplayAckFilter {
  ALL,
  ACKNOWLEDGED,
  UNACKNOWLEDGED,
}

const displayAckFilter_ASC: DisplayAckFilter[] = (
  Object.values(DisplayAckFilter).filter(
    (v) => typeof v === "number"
  ) as DisplayAckFilter[]
).sort((a, b) => a - b);

const labels: Record<DisplayAckFilter, string> = {
  "0": "Total",
  "1": "Acknowledged",
  "2": "Unacknowledged",
};

export function CountCardsAndTable() {
  const useAckManStore = useGetUseAckManStore();

  const checkedMap = useAckManStore((s) => s.checkedMap);
  const onlyShowFresh = useAckManStore((s) => s.fresh);
  const onlyActiveFaultTreeNodes = useAckManStore(
    (s) => s.onlyActiveFaultTreeNodes
  );

  const anomsQuery = useAckManagerAnomaliesQuery();
  const anomalyData = anomsQuery.data ?? [];
  const isFetchingData = anomsQuery.isLoading || anomsQuery.isFetching;

  const [query, setQuery] = React.useState("");
  const setQueryDebounced = React.useMemo(
    () => debounce((q) => setQuery(q.trim().toLowerCase())),
    []
  );

  const [acknowledgedFilter, setAcknowledgedFilter] =
    React.useState<DisplayAckFilter>(0); //

  const buildFilteredDataAndCounts = (formattedAnoms: typeof anomalyData) => {
    let filteredData = formattedAnoms.filter((a) => {
      const isFtNode = a.type === "forFtNodes";

      const passesToggledOnFilters = isFtNode
        ? onlyActiveFaultTreeNodes
        : checkedMap[a.level];

      if (!passesToggledOnFilters) return false; // do the toggles match?

      if (!a.searchString.includes(query)) return false; // does the query match?

      if (onlyShowFresh) return isFtNode ? false : a.isFresh; // only show fresh anomalies?

      return true; // all filters passed
    });

    /**
     * Calculate the counts after the filter
     */
    const total = filteredData.length;
    let ack = filteredData.filter((a) => a.acknowledged).length;
    let unack = filteredData.filter((a) => !a.acknowledged).length;

    // this must be done after the counts are calculated above
    if (acknowledgedFilter !== DisplayAckFilter.ALL) {
      // this means show either only ack or only unack
      filteredData = filteredData.filter((a) => {
        if (acknowledgedFilter === DisplayAckFilter.ACKNOWLEDGED)
          return a.acknowledged;
        else if (acknowledgedFilter === DisplayAckFilter.UNACKNOWLEDGED)
          return !a.acknowledged;

        const _: never = acknowledgedFilter;
        throw new Error("handle newly added case");
      });
    }

    const counts: Record<DisplayAckFilter, number> = {
      "0": total,
      "1": ack,
      "2": unack,
    };

    return { counts, data: filteredData };
  };

  const filteredData = buildFilteredDataAndCounts(anomalyData);
  const filteredAnoms = filteredData.data;

  const TABLE_HEADER_LABELS: {
    label: string;
    tooltip?: string;
    fn: (anom: (typeof filteredAnoms)[number]) => React.ReactElement;
  }[] = [
    {
      label: "Type",
      fn: ({ level: levelMaybe, stripes, type: type_ }) => (
        <td className="py-1">
          <Tooltip
            withArrow
            label={
              type_ === "forFtNodes"
                ? `Fault Tree Node`
                : `Level ${levelMaybe} anomaly`
            }
            classNames={{ tooltip: "font-normal" }}
            position="right"
          >
            <div
              data-level={levelMaybe?.toString() ?? "none"}
              className={cn(
                "mx-auto rounded-full max-h-4 aspect-square",
                type_ === "forTags" &&
                  "data-[level='1']:bg-lvl1 data-[level='2']:bg-lvl2 data-[level='3']:bg-lvl3",
                type_ === "forFtNodes" && "bg-ft-active",
                stripes && `striped${levelMaybe}`
              )} // see AnomalyColorsStripes.scss
            />
          </Tooltip>
        </td>
      ),
    },
    {
      label: "Status",
      fn: ({ acknowledged }) => (
        <td>{acknowledged ? "Acknowledged" : "Unacknowledged"}</td>
      ),
    },
    {
      label: "Fresh",
      tooltip:
        "Fresh Anomaly Definition: When a variable's degree of anomaly for a day is greater than its degree of anomaly for the previous day, it is considered a Fresh or New Anomaly",
      fn: ({ isFresh }) => (
        <td>{isFresh && <FaCheck className="mx-auto" />}</td>
      ),
    },
    {
      label: "ID",
      fn: ({ variable_name }) => <td className="lowercase">{variable_name}</td>,
    },
    {
      label: "Description",
      fn: ({ variable_description }) => (
        <td className="lowercase">{ellipsify(variable_description, 150)}</td>
      ),
    },
    {
      label: "Acknowledged By",
      fn: ({ author, acknowledged }) => (
        <td>{author && acknowledged ? author : "-"}</td>
      ),
    },
    {
      label: "Acknowledgment Time",
      fn: ({ date, acknowledged }) => (
        <td>
          {(acknowledged && date && moment(date).format("YYYY-MM-DD h:mm A")) ||
            "-"}
        </td>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-evenly mt-8 gap-4">
        {displayAckFilter_ASC.map((ackNum) => {
          const count = filteredData.counts[ackNum];
          const label = labels[ackNum];
          return (
            <div
              key={label}
              className={cn(
                "stats flex-1 relative",
                ackNum === acknowledgedFilter
                  ? "border border-primary bg-indigo-100 shadow-xl"
                  : "hover:bg-indigo-800 hover:text-primary-content hover:cursor-pointer shadow"
              )}
              onClick={() => setAcknowledgedFilter(ackNum)}
            >
              {isFetchingData ? (
                <div className="absolute bottom-3 right-3">
                  <Loader color={"dark"} size="md" />
                </div>
              ) : null}

              <div className="stat">
                <div className="stat-title">{label}</div>
                <div className="stat-value">{count}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex mb-2 mt-8 justify-start items-center gap-4">
        <Input
          placeholder="Search"
          size="sm"
          className="min-w-[300px] input-bordered"
          onChange={(e) => setQueryDebounced(e.target.value)}
        />
        <label className="cursor-pointer label inline-flex gap-2">
          <span className="label-text whitespace-nowrap">Fresh Anomalies</span>
          <input
            type="checkbox"
            checked={onlyShowFresh}
            onChange={(e) => {
              const checked = e.target.checked;
              useAckManStore.getState().setFresh(checked);
            }}
            className="checkbox checkbox-error checkbox-xs"
          />
        </label>
      </div>

      <div className="mb-8">
        {filteredAnoms.length === 0 ? (
          <div className="flex justify-center pt-4">
            <span className="font-semibold text-[1.3rem] mt-16">
              No Results
            </span>
          </div>
        ) : (
          <>
            <table className="w-full text-[0.9rem] overflow-x-auto table-fixed">
              <thead className="text-center bg-gray-200">
                <tr>
                  {TABLE_HEADER_LABELS.map((headerDetails) => {
                    return (
                      <th
                        className="py-4 first:rounded-tl-lg last:rounded-tr-lg"
                        key={headerDetails.label}
                      >
                        {headerDetails.label}
                        {headerDetails.tooltip ? (
                          <Tooltip
                            withArrow
                            classNames={{ tooltip: "font-normal" }}
                            position="bottom"
                            label={headerDetails.tooltip}
                          >
                            <i className="fa fa-info-circle ml-2" />
                          </Tooltip>
                        ) : null}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-center bg-white">
                {filteredAnoms.map((anom) => (
                  <tr
                    key={anom._id}
                    className="hover:bg-indigo-100 border-b border-l border-r"
                  >
                    {TABLE_HEADER_LABELS.map(({ fn, label }) => {
                      return (
                        <React.Fragment key={label}>{fn(anom)}</React.Fragment>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}
