import React, { useState } from "react";
import moment from "moment";
import isSameDay from "date-fns/isSameDay";
import { Tooltip } from "@mantine/core";
import Button from "../Button/Button";
import useMaxDate from "../hooks/useMaxDate";
import { useDateState, type DateState } from "../../../zustand/useDateState";
import * as CalendarComponents from "../calendar";
import { addDays, isAfter, subDays } from "date-fns";
import { RangeCalendar } from "./range-calendar";
import { EARLIEST_DATE } from "./constants";
import useCurrentUnitObject from "../hooks/useCurrentUnitObject";
import { useConfigRequired } from "../../../zustand/config/useConfigStore";
import EditableDateTimeSingle from "../../dateSelector/editable/EditableDateTimeSingle";
import EditableDateTimeRange from "../../dateSelector/editable/EditableDateTimeRange";
import { DateTime } from "luxon";
import { useCallback } from "use-memo-one";

function EditableDateInputs({ closeCalendar }: { closeCalendar: () => void }) {
  const dateState = useDateState();

  return dateState.mode === "range" ? (
    <EditableDateTimeRange
      // adding key because when date is selected on calendar,
      // the date in the editable component won't be updated

      className="h-8 bg-white dark:bg-xslate-2 border border-x-0 border-xslate-7"
      start={moment(dateState.selectedDateStart).valueOf()}
      end={dateState.axisRangeTo.local.getTime()}
      options={{
        fontSize: "base",
        showTime: false,
        allowOutsideClick: true,
      }}
      onChangeHandler={({ start, end }) => {
        console.log("new range", {
          start: new Date(start),
          end: new Date(end),
        });
        dateState.setRange(new Date(start), new Date(end));
      }}
      close={closeCalendar}
    />
  ) : (
    <EditableDateTimeSingle
      className="h-8 bg-white dark:bg-xslate-2 border border-x-0 border-xslate-7 min-w-[175px]"
      date={DateTime.fromISO(dateState.axisRangeTo.dateString).toMillis()}
      options={{
        fontSize: "base",
        showTime: false,
        allowOutsideClick: true,
      }}
      onChange={(t) => dateState.setEnd(new Date(t))}
      close={closeCalendar}
    />
  );
}

export function DateStateCalendar({
  allowRangeCalendar, // not all pages can use the range calendar
}: {
  allowRangeCalendar?: boolean;
}) {
  /**
   * If we're rendering this component on the plant overview page,
   * currUnit will be undefined, so we need to check all allowed units
   * to see if any of them have real time enabled.
   */
  const { hasRealTimeUnits } = useConfigRequired();
  const currUnit = useCurrentUnitObject();

  const maxDate = useMaxDate();
  const dateState = useDateState();

  const [isOpen, setIsOpen] = useState(false);
  const closeCalendar = useCallback(() => setIsOpen(false), []);

  const buttonShouldSayToday = currUnit
    ? currUnit.isRealTime
    : hasRealTimeUnits;
  const axisRangeTo = dateState.axisRangeTo.local;

  const max = buttonShouldSayToday ? new Date() : subDays(new Date(), 1);

  const todayOrYesterdayButtonIsDisabled =
    isSameDay(axisRangeTo, max) || isAfter(axisRangeTo, max); // isAfter should be redundant, but just in case

  const todayOrYesterdayString = buttonShouldSayToday ? "Today" : "Yesterday";

  const onSingleDateSelect = (d: Date) => {
    dateState.setEnd(d);
    closeCalendar();
  };

  const calendarMode = dateState.mode;

  const selectedDateStartAsDate = moment(dateState.selectedDateStart).toDate();
  const leftArrowDisabled =
    calendarMode === "single"
      ? isSameDay(EARLIEST_DATE, axisRangeTo)
      : isSameDay(selectedDateStartAsDate, EARLIEST_DATE);

  const rightArrowDisabled = isSameDay(axisRangeTo, maxDate);

  type ArrowDirection = "left" | "right";

  const onArrowSingle = (dir: ArrowDirection) => {
    if (calendarMode === "range") throw new Error("shouldn't be called");
    setIsOpen(false);

    const fn = dir === "left" ? subDays : addDays;
    dateState.setEnd(fn(axisRangeTo, 1));
  };

  const onArrowRange = (dir: ArrowDirection) => {
    if (calendarMode === "single") throw new Error("shouldn't be called");
    setIsOpen(false);

    const fn = dir === "left" ? subDays : addDays;

    dateState.setRange(fn(selectedDateStartAsDate, 1), fn(axisRangeTo, 1));
  };
  const onArrowClick = calendarMode === "single" ? onArrowSingle : onArrowRange;

  const endDateString = moment(axisRangeTo).format("LL");
  const displayDateString =
    dateState.mode === "range"
      ? `${moment(dateState.selectedDateStart).format("LL")} - ${endDateString}`
      : endDateString;

  return (
    <CalendarComponents.Dropdown
      open={isOpen}
      setOpen={setIsOpen}
      trigger={{
        leftArrowButton: {
          disabled: leftArrowDisabled,
          onClick: () => onArrowClick("left"),
        },
        rightArrowButton: {
          disabled: rightArrowDisabled,
          onClick: () => onArrowClick("right"),
        },
        label: displayDateString,
      }}
      triggerAltComponent={<EditableDateInputs closeCalendar={closeCalendar} />}
    >
      {/* what you see in the dropdown  */}
      {allowRangeCalendar && <ModeTabs />}
      {dateState.mode === "single" ? (
        <CalendarComponents.SingleDay
          value={dateState.axisRangeTo.local}
          key={dateState.axisRangeTo.dateString}
          onChange={onSingleDateSelect}
          footer={
            <div className="pt-2">
              <Tooltip
                position="right"
                label={
                  todayOrYesterdayButtonIsDisabled
                    ? `${todayOrYesterdayString} is currently selected`
                    : `Automatically select ${todayOrYesterdayString}`
                }
              >
                <Button
                  className="btn-primary"
                  size="xs"
                  onClick={() => {
                    const newDate = buttonShouldSayToday
                      ? new Date()
                      : subDays(new Date(), 1);
                    onSingleDateSelect(newDate);
                  }}
                  disabled={todayOrYesterdayButtonIsDisabled}
                >
                  {todayOrYesterdayString}
                </Button>
              </Tooltip>
              <Button
                className="btn-outline float-right"
                size="xs"
                onClick={closeCalendar}
              >
                Close
              </Button>
            </div>
          }
        />
      ) : allowRangeCalendar ? (
        <RangeCalendar close={closeCalendar} />
      ) : null}
    </CalendarComponents.Dropdown>
  );
}

const MODES: {
  [TMode in DateState["mode"]]: {
    mode: TMode;
    label: string;
  };
} = {
  single: {
    mode: "single",
    label: "Select Day",
  },
  range: {
    mode: "range",
    label: "Select Range",
  },
};

function ModeTabs() {
  const dateState = useDateState();

  return (
    <div className="tabs w-full">
      {Object.values(MODES).map(({ label, mode: value }) => {
        const isActive = value === dateState.mode;
        return (
          <button
            key={value}
            data-active={isActive.toString()}
            className="h-8 tab tab-lifted tab-sm data-[active=true]:tab-active flex-1"
            onClick={
              isActive
                ? undefined
                : () => {
                    if (value === "range") {
                      dateState.enterRangeMode();
                    } else if (value === "single") {
                      dateState.exitRangeMode();
                    } else {
                      const _exhaustiveCheck: never = value;
                      throw new Error("unsupported");
                    }
                  }
            }
          >
            {label}
          </button>
        );
      })}
    </div>
  );
}
