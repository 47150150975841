/* Simple meomize implementation
 *
 * FAQ:
 *
 * - Why aren't we using `React.memo`?
 *
 *     React.memo wraps entire components, it doesn't allow you to memoize
 *     within render functions or state computation. We need memoization that
 *     allows us to cache part of a render function.
 *
 * - Why aren't we using the `useMemo` hook?
 *
 *     React hooks can't be used in classes.
 *
 * - Why aren't we using `_.memoize`?
 *
 *     `_.memoize` is *almost* what we want, but it caches everything, not just
 *     the most recent value. We can configure it not to do that, but that's
 *     hard to remember and we're unlikely to actually do it. Easier to just
 *     have a custom utility that's always correct.
 *
 * - Why not use X?
 *
 *     We actually do have a few tiny nice behaviors that we want to support
 *     that aren't super common (intuiting keys based on function parameters,
 *     inline instance functions, etc...). But ultimately it's just that this
 *     isn't that complicated, so it's overkill to make it complicated.
 *
 */
import _ from "lodash";
const map = new WeakMap();
export default function cache(method) {
  map.set(method, {
    value: null,
    args: null,
  });

  return function () {
    let cached = map.get(method);
    if (!cached) {
      throw new Error("This error is impossible. What did you do?");
    }

    if (cached.args && _.isEqual(cached.args, arguments)) {
      return cached.value;
    }

    /* Update cache and return. */
    cached.args =
      arguments; /* cache first, for the rare case where calling the method changes the arguments. */
    let value = method.apply(this, arguments);
    cached.value = value;
    return value;
  };
}
