import React from "react";

/*
 * React fragments arbitrarily have extra restrictions over normal
 * components for... some reason.
 *
 * This is a tiny reiimplementation of React Fragments without any of those
 * restrictions.
 */
function Fragment(props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}

export default Fragment;
