import { Popover } from "@mantine/core";
import React, { useState } from "react";
import Button from "../common/Button/Button";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { BiCog } from "react-icons/bi";
import { useDateState } from "../../zustand/useDateState";

function DeveloperViewTemplate({ icon, title, data }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      classNames={{ dropdown: "bg-slate-900 rounded-lg text-gray-300" }}
      width={500}
      position="right"
      withArrow
      shadow="md"
      opened={isOpen}
      onChange={setIsOpen}
    >
      <Popover.Target>
        <Button
          icon={icon}
          onClick={() => {
            setIsOpen(true);
          }}
          className="btn-ghost !text-[1rem]"
        />
      </Popover.Target>
      <Popover.Dropdown>
        <div className="w-full text-center mb-2">{title}</div>
        <div className="w-full text-center mb-2">
          Only visible in development mode
        </div>
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </Popover.Dropdown>
    </Popover>
  );
}

function DeveloperView() {
  const { onAxisRangeSelect, setEnd, ...rest } = useDateState();
  const currentUnit = useCurrentUnitObject();

  return process.env.NODE_ENV !== "production" ? (
    <>
      <DeveloperViewTemplate
        icon={BsFillCalendarEventFill}
        title="Current DateState"
        data={rest}
        // data={{
        //   new: rest,
        //   old: _.reduce(
        //     DateState,
        //     (o, v, k) => {
        //       !_.isFunction(v) && (o[k] = v);
        //       return o;
        //     },
        //     {}
        //   ),
        // }}
      />
      <DeveloperViewTemplate
        icon={BiCog}
        title="Current Unit Object"
        data={currentUnit || null}
      />
    </>
  ) : null;
}

export default DeveloperView;
