import { useMemo, type PropsWithChildren } from "react";
import * as R from "remeda";

import { useGetUseTagsManagerStore } from "./tags-manager-store-provider";
import { PaginationProvider, usePagination } from "../../common/pagination";
import {
  useGroupsQuery,
  useInvalidateVariablesQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { useUserRequired } from "../../../zustand/auth/useAuthStore";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import { type variableSchema } from "../../../lib/api-validators";
import { iife } from "../../../lib/utils";
import { BaseTagForm } from "./base-tag-form";
import { useCreateTagMutation } from "./mutations";
import { TagCard } from "./tag-card";
import { type QueryBy } from "./tags-manager-store-provider";
import { Input } from "../../../shared-ui/frontend/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";
import { PropsWithCn, cn } from "../../../shared-ui/frontend/cn";
import { matchSorter } from "match-sorter";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Button } from "../../../shared-ui/frontend/button";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import { Download } from "lucide-react";

const PAGE_SIZE = 30;

function usePaginatedTags() {
  /**
   * usePagination must be used where a WithPaginationProvider
   * lives further up in the tree.
   *
   * Since it's generic, that is we can use it to paginate any
   * array, we pass it a type
   */
  return usePagination<variableSchema>();
}

const SPECIAL_DATA_KEY_FOR_GROUPS_SEARCHING = "GROUPS_NAMES";

type KeyofVariableSchema = keyof variableSchema;

const queryByMatchSorterKeysPartial: Record<
  Exclude<QueryBy, "All attributes">,
  string[]
> = {
  "Tag ID": ["name"] satisfies KeyofVariableSchema[],
  description: ["description"] satisfies KeyofVariableSchema[],
  expression: ["expression"] satisfies KeyofVariableSchema[],
  "groups associated": [SPECIAL_DATA_KEY_FOR_GROUPS_SEARCHING],
  "units of measurement": [
    "units_of_measurement",
  ] satisfies KeyofVariableSchema[],
};

const queryByMatchSorterKeys: Record<QueryBy, string[]> = {
  "All attributes": Object.values(queryByMatchSorterKeysPartial).flat(),
  ...queryByMatchSorterKeysPartial,
};

function WithPaginationProvider({ children }: PropsWithChildren) {
  const variablesQuery = useVariablesArrayQuery();
  const groupsQuery = useGroupsQuery();
  const useStore = useGetUseTagsManagerStore();
  const query = useStore((s) => s.query);
  const queryBy = useStore((s) => s.queryBy);

  const filterFn = useStore((s) => {
    const out = s.enabledFilters[s.filter];
    if (!out) throw new Error(`Filter ${s.filter.toString()} not found`);
    return out;
  });

  const sortFn = useStore((s) => {
    const out = s.enabledSorts[s.sort];
    if (!out) throw new Error(`Sort ${s.sort.toString()} not found`);
    return out;
  });

  const tags = useMemo((): variableSchema[] | undefined => {
    const out = variablesQuery.data
      ?.filter((x) => x.type === "Tag" && filterFn(x))
      .sort(sortFn);

    if (!out) return undefined;

    if (query.trim() === "") return out;

    if (queryBy === "groups associated") {
      if (!groupsQuery.data) return undefined;

      const idToName = R.mapToObj(groupsQuery.data, (g) => [g._id, g.name]);

      const dataToSearch = out.map((v) => {
        return {
          ...v,
          /**
           * Here we add an extra key onto variableSchema,
           * so that matchSorter can use it to filter, but
           * we still return a variableSchema type. That
           * is, outside of this useMemo, nobody knows we
           * added an extra key. This is why the useMemo
           * function return type is explicitly defined
           * above.
           */
          [SPECIAL_DATA_KEY_FOR_GROUPS_SEARCHING]: R.filter(
            v.groups.map((gid) => idToName[gid]),
            R.isTruthy
          ),
        };
      });
      return matchSorter(dataToSearch, query, {
        keys: [SPECIAL_DATA_KEY_FOR_GROUPS_SEARCHING],
        threshold: matchSorter.rankings.CONTAINS,
      });
    } else {
      return matchSorter(out, query, {
        keys: queryByMatchSorterKeys[queryBy],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }
  }, [variablesQuery.data, query, queryBy, groupsQuery.data, filterFn, sortFn]);

  return (
    <PaginationProvider<variableSchema> data={tags} pageSize={PAGE_SIZE}>
      {children}
    </PaginationProvider>
  );
}

function Header() {
  const useStore = useGetUseTagsManagerStore();
  const me = useUserRequired();

  const { data, page, pageSize } = usePaginatedTags();

  const isStalePage = useStore((s) => s.isStaleTagsPage);

  return (
    <div className="flex flex-row items-center mt-6">
      <span className="text-[2rem] sm:text-[1.75rem] mr-2">
        {(isStalePage ? "Bad " : "") + "Tags"}
      </span>
      {!isStalePage && me.hasEditPermission && (
        <CreateNewEntityButton
          onClick={() => useStore.getState().setCreateMode(true)}
        />
      )}
      {iife(() => {
        if (!data) return undefined;

        let label;

        if (!data.length) label = "Displaying 0 of 0 Tags";
        else {
          const start = page * pageSize + 1;
          const end = Math.min(start + pageSize - 1, data.length);

          label = `Displaying ${start}-${end} of ${data.length} Tags`;
        }

        return (
          <span className="mr-5 italic text-[1.25rem] text-titlegrey ml-auto">
            {label}
          </span>
        );
      })}
      <DownloadButton />
    </div>
  );
}

function DownloadButton() {
  const { data } = usePaginatedTags();

  const groupsQuery = useGroupsQuery();

  if (!data || !groupsQuery.data) return null;

  const download = () => {
    const groupNameMap = R.mapToObj(groupsQuery.data, (g) => [g._id, g.name]);

    let maxNumGroupsForAnyV = 0;

    const toStaticColumns = (x: variableSchema) =>
      ({
        TAG_ID: x.trimmedName,
        DESCRIPTION: x.description,
        "UNIT OF MEASUREMENT": x.units_of_measurement || "",
        "LOW SIDE ANOMALY": x.low_side ? "on" : "off",
        "HIGH SIDE ANOMALY": x.high_side ? "on" : "off",
        "CALCULATED EXPRESSION": x.expression || "",
      }) satisfies Record<string, string>;

    const groupNameKey = (i: number) => `GROUP${i + 1}`;

    const rows = data.map((x) => {
      const groupNames = R.filter(
        x.groups.map((gid) => groupNameMap[gid]),
        R.isTruthy
      );

      maxNumGroupsForAnyV = Math.max(maxNumGroupsForAnyV, groupNames.length);

      const staticColumns = toStaticColumns(x);

      const groupColumns = R.mapToObj.indexed(groupNames, (g, i) => {
        return [groupNameKey(i), g];
      });

      const out = {
        staticColumns,
        groupColumns,
      };

      return out;
    });

    for (const row of rows) {
      // fill in empty strings for missing groups
      for (let i = 0; i < maxNumGroupsForAnyV; i++) {
        if (!row.groupColumns[groupNameKey(i)])
          row.groupColumns[groupNameKey(i)] = "";
      }
    }

    const [first] = rows;

    const columnOrder = first
      ? Object.keys(first.staticColumns).concat(
          Array.from({ length: maxNumGroupsForAnyV }).map((_, i) =>
            groupNameKey(i)
          )
        )
      : [];

    downloadXLSX(
      rows.map((x) => ({ ...x.groupColumns, ...x.staticColumns })),
      "dra_variables.xlsx",
      columnOrder
    );
  };

  return (
    <Button variant={"ghost"} size={"sm"} onClick={download}>
      <Download className="h-4 w-4 mr-1" />
      DOWNLOAD
    </Button>
  );
}

function FormMaybe() {
  const useStore = useGetUseTagsManagerStore();
  const showForm = useStore((s) => s.createMode);
  const closeForm = () => useStore.getState().setCreateMode(false);
  const refetch = useInvalidateVariablesQuery();

  const createNewTagMutation = useCreateTagMutation(() => {
    closeForm();
    refetch();
  });

  if (!showForm) return null;

  return (
    <BaseTagForm
      disabled={createNewTagMutation.isLoading}
      close={closeForm}
      onSubmit={createNewTagMutation.mutate}
    />
  );
}

function FiltersAndSearch({ className }: PropsWithCn) {
  const useStore = useGetUseTagsManagerStore();
  const sort = useStore((s) => s.sort);
  const filter = useStore((s) => s.filter);

  const query = useStore((s) => s.query);
  const queryBy = useStore((s) => s.queryBy);
  const enabledFilters = useStore((s) => s.enabledFilters);
  const enabledSorts = useStore((s) => s.enabledSorts);

  return (
    <div className={cn("flex", className)}>
      <Input
        value={query}
        onChange={(e) => useStore.getState().setQuery(e.target.value)}
        className="h-8 max-w-[130px] inline rounded-r-none focus-visible:max-w-[300px]"
        placeholder="Search"
      />
      <Select
        value={queryBy}
        onValueChange={(s) => useStore.getState().setQueryBy(s as QueryBy)}
      >
        <SelectTrigger className="max-w-max rounded-l-none">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Search by</SelectLabel>
            {Object.keys(queryByMatchSorterKeys).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select
        value={sort.toString()}
        onValueChange={(s) => useStore.getState().setSort(s)}
      >
        <SelectTrigger className="max-w-max ml-auto">
          <span>Sort: {sort.toString()}</span>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.keys(enabledSorts).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        value={filter.toString()}
        onValueChange={(s) => useStore.getState().setFilter(s)}
      >
        <SelectTrigger className="max-w-max ml-2">
          <span>Filter: {filter.toString()}</span>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.keys(enabledFilters).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function TagsList() {
  const { slice } = usePaginatedTags();

  /**
   * Call upon update to get new data, this will cause the cards
   * to get the latest info and re-render. Also will cause the
   * paginator to reset to page 0. There is no easy way around
   * this because this page shows sorted data. And sorting
   * will most likely change upon updating, because our
   * default sort is by "last updated".
   */
  const refetch = useInvalidateVariablesQuery();

  if (!slice) return undefined;

  return (
    <div className="flex justify-center flex-col mt-2 drop-shadow-md">
      {slice.map((v) => (
        <TagCard key={v._id} tag={v} onUpdate={refetch} onDelete={refetch} />
      ))}
    </div>
  );
}

function Paginator() {
  const { page, pageSize, data, hasPrev, prev, hasNext, next } =
    usePaginatedTags();

  /**
   * Data is undefined while we wait for data to load
   */
  if (!data) return null;

  return (
    <div className="py-6 flex justify-end">
      <Button
        disabled={!hasPrev}
        onClick={prev}
        size={"xxs"}
        className="rounded-r-none border-r-0"
      >
        <FaArrowLeft />
      </Button>
      <Button
        className="rounded-none cursor-pointer pointer-events-none"
        size={"xxs"}
      >
        Page {page + 1} of {Math.floor(data.length / pageSize) + 1}
      </Button>
      <Button
        disabled={!hasNext}
        onClick={next}
        size={"xxs"}
        className="rounded-l-none border-l-0"
      >
        <FaArrowRight />
      </Button>
    </div>
  );
}

function GenericTagsManager_NEEDS_PROVIDER() {
  /**
   * Rendering without provider will throw an error
   *
   * We do this because 2 pages are using this component
   * with their own provider
   */
  return (
    <WithPaginationProvider>
      <Header />
      <FormMaybe />
      <FiltersAndSearch className="mt-2" />
      <TagsList />
      <Paginator />
    </WithPaginationProvider>
  );
}

export { GenericTagsManager_NEEDS_PROVIDER };
