import * as React from "react";
import {
  MdArrowRight as ArrowRight,
  MdArrowLeft as ArrowLeft,
} from "react-icons/md";
import Button from "./Button/Button";
import "./time-picker.css";
import { DayPicker, useNavigation, useDayPicker } from "react-day-picker";
import moment from "moment";
import useMaxDate from "./hooks/useMaxDate";
import { EARLIEST_DATE } from "./date-state-calendar/constants";
import { PropsWithCn } from "../types/component.types";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  addDays,
  addMonths,
  format,
  isSameDay,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import { z } from "zod";

import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shared-ui/frontend/select";

export function Dropdown({
  trigger: triggerProps,
  triggerAltComponent,
  setOpen,
  open,
  children,
}: React.PropsWithChildren<{
  trigger: Omit<
    React.PropsWithoutRef<React.ComponentProps<typeof Trigger>>,
    "setOpen" | "open"
  >;
  triggerAltComponent?: React.JSX.Element;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {/* what you see when the calendar isn't open  */}
        <Trigger
          open={open}
          setOpen={setOpen}
          altComponent={triggerAltComponent}
          {...triggerProps}
        />
      </PopoverTrigger>
      <PopoverContent
        className="translate-x-2 rounded-lg border border-bordgrey2 p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {children}
      </PopoverContent>
    </Popover>
  );
}

/**
 * Mantine uses a ref to determine where to put the dropdown, so we must pass it along
 */

const Trigger = React.forwardRef<
  HTMLDivElement,
  {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    label: string;
    leftArrowButton: Pick<
      React.ComponentProps<"button">,
      "onClick" | "disabled"
    >;
    rightArrowButton: Pick<
      React.ComponentProps<"button">,
      "onClick" | "disabled"
    >;
    altComponent?: React.JSX.Element;
  }
>(
  (
    { open, setOpen, label, leftArrowButton, rightArrowButton, altComponent },
    ref
  ) => {
    return (
      <div ref={ref} className="input-group input-group-sm w-max">
        <Button
          icon={ArrowLeft}
          className="btn-square btn-outline border bg-base-100 border-bdgrey text-[1.1rem]"
          disabled={leftArrowButton.disabled}
          onClick={(e) => {
            e.preventDefault();
            leftArrowButton.onClick?.(e);
          }}
        />
        {open && altComponent !== undefined ? (
          altComponent
        ) : (
          <input
            className="input text-center input-bordered input-sm min-w-[175px] cursor-pointer border-l-0 border-r-0 border-bdgrey"
            type="text"
            size={label.length}
            readOnly
            onClick={() => setOpen((curr) => !curr)}
            value={label}
          />
        )}
        <Button
          icon={ArrowRight}
          className="btn-square btn-outline border bg-base-100 border-bdgrey text-[1.1rem]"
          disabled={rightArrowButton.disabled}
          onClick={(e) => {
            e.preventDefault();
            rightArrowButton.onClick?.(e);
          }}
        />
      </div>
    );
  }
);

Trigger.displayName = "CalendarTrigger";

/**
 * This is just the calendar component itself. Pretty generic because
 * we use it in a few places.
 *
 *
 * The date state calendar uses this component alongside its own Datestate
 * logic.
 */

function Arrows() {
  const maxDate = useMaxDate();

  const { nextMonth, previousMonth, goToMonth, currentMonth } = useNavigation();
  const { disabled } = useDayPicker();

  const januaryFirstOfThisYear = startOfYear(currentMonth);

  const earliestYear = startOfYear(EARLIEST_DATE);

  const isExplicitlyDisabled = typeof disabled === "boolean" && disabled;

  return (
    <div className="flex gap-2">
      <Button
        className="btn-ghost border border-zinc-300"
        disabled={!previousMonth || isExplicitlyDisabled}
        onClick={previousMonth && (() => goToMonth(previousMonth))}
      >
        <ChevronLeft className="w-4 h-4" />
      </Button>
      <Select
        disabled={isExplicitlyDisabled}
        value={currentMonth.getMonth().toString()}
        onValueChange={(v) => {
          const monthZeroIndexed = z.coerce.number().parse(v);
          const newMonth = new Date(currentMonth);
          newMonth.setMonth(monthZeroIndexed);

          if (startOfMonth(newMonth) > maxDate) {
            return;
          }
          goToMonth(newMonth);
        }}
      >
        <SelectTrigger className="border-zinc-300">
          <SelectValue />
        </SelectTrigger>
        <SelectContent
          /**
           * WithoutPortal prevents the content from being portal'ed into
           * the body. We were having zindex issues before, where the calendar wasn't portaled
           * but this was portaled. They should be the same.
           */
          withoutPortal
        >
          <SelectGroup>
            {Array.from({ length: 12 }).map((_, i) => {
              const d = addMonths(januaryFirstOfThisYear, i);
              const value = d.getMonth().toString();

              const currCopy = new Date(currentMonth);
              currCopy.setMonth(i);

              const disabled = startOfMonth(currCopy) > maxDate;

              return (
                <SelectItem disabled={disabled} value={value} key={value}>
                  {format(d, "MMMM")}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select
        disabled={isExplicitlyDisabled}
        value={currentMonth.getFullYear().toString()}
        onValueChange={(yearString) => {
          const year = z.coerce.number().parse(yearString);

          const newMonth = new Date(currentMonth);
          newMonth.setFullYear(year);

          if (newMonth > maxDate) {
            goToMonth(maxDate);
          } else {
            goToMonth(newMonth);
          }
        }}
      >
        <SelectTrigger className="border-zinc-300">
          <SelectValue className="font-semibold" />
        </SelectTrigger>
        <SelectContent withoutPortal>
          <SelectGroup>
            {Array.from({
              length: maxDate.getFullYear() - earliestYear.getFullYear() + 1,
            }).map((_, i) => {
              const yearLabel = (earliestYear.getFullYear() + i).toString();

              return (
                <SelectItem value={yearLabel} key={yearLabel}>
                  {yearLabel}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button
        className="btn-ghost border border-zinc-300"
        disabled={!nextMonth || isExplicitlyDisabled}
        onClick={nextMonth && (() => goToMonth(nextMonth))}
      >
        <ChevronRight className="w-4 h-4" />
      </Button>
    </div>
  );
}

export function SingleDay({
  value,
  onChange,
  footer,
  className,
  disabled,
}: PropsWithCn<{
  value: Date;
  onChange: (d: Date) => void;
  footer?: React.ComponentProps<typeof DayPicker>["footer"];
  disabled?: boolean;
}>) {
  const maxDate = useMaxDate();

  /**
   * If we're rendering this component on the plant overview page,
   * currUnit will be undefined, so we need to check all allowed units
   * to see if any of them have real time enabled.
   */

  function handleDateSelect(date: Date | undefined) {
    if (!date) return;
    // we want to do different things for day and range modes

    const newDate = moment(date).toDate();
    onChange(newDate);
  }

  return (
    <DayPicker
      components={{
        Caption: Arrows,
      }}
      disabled={disabled || [{ after: maxDate }, { before: EARLIEST_DATE }]}
      className={className}
      mode="single"
      defaultMonth={value}
      selected={value}
      onSelect={handleDateSelect}
      footer={footer}
      fromDate={EARLIEST_DATE}
      toDate={maxDate}
      captionLayout="dropdown"
      classNames={{
        day_selected: "!bg-indigo-700 !text-white",
        month: "flex flex-col items-center gap-2",
      }}
    />
  );
}

/**
 * A composition of the components above into a component that we use across multiple pages.
 *
 * Essentially it is the date select with left and right arrows and a dropdown calendar.
 */
export function SingleDaySelectWithArrows({
  close,
  onChange,
  value,
  trigger,
  disabled,
  closeOnChange,
}: {
  close?: Omit<React.ComponentProps<typeof Button>, "onClick">;
  closeOnChange?: boolean;
} & Omit<React.ComponentProps<typeof SingleDay>, "footer" | "className"> & {
    trigger?: React.ComponentProps<typeof Dropdown>["trigger"];
  }) {
  const [open, setOpen] = React.useState(false);
  const maxDate = useMaxDate();

  const getTriggerProps = (): React.ComponentProps<
    typeof Dropdown
  >["trigger"] => {
    if (!trigger) {
      return {
        label: moment(value).format("MMMM D, YYYY"),
        leftArrowButton: {
          disabled: false,
          onClick: () => {
            onChange(subDays(value, 1));
            closeOnChange && setOpen(false);
          },
        },
        rightArrowButton: {
          disabled: isSameDay(value, maxDate),
          onClick: () => {
            onChange(addDays(value, 1));
            closeOnChange && setOpen(false);
          },
        },
      };
    }

    if (closeOnChange) {
      const { leftArrowButton, rightArrowButton } = trigger;
      return {
        ...trigger,
        leftArrowButton: {
          ...leftArrowButton,
          onClick: (e) => {
            leftArrowButton.onClick?.(e);
            setOpen(false);
          },
        },
        rightArrowButton: {
          ...rightArrowButton,
          disabled: rightArrowButton.disabled || isSameDay(value, maxDate),
          onClick: (e) => {
            rightArrowButton.onClick?.(e);
            setOpen(false);
          },
        },
      };
    }

    return trigger;
  };

  return (
    <Dropdown open={open} setOpen={setOpen} trigger={getTriggerProps()}>
      <SingleDay
        onChange={
          closeOnChange
            ? (d) => {
                onChange(d);
                setOpen(false);
              }
            : onChange
        }
        value={value}
        disabled={disabled}
        footer={
          close && (
            <div>
              <Button {...close} onClick={() => setOpen(false)}>
                {close.children ?? "Close"}
              </Button>
            </div>
          )
        }
      />
    </Dropdown>
  );
}
