import { z } from "zod";
import {
  CUSTOM_RANGE,
  PREDEFINED_RANGE,
  RANGES,
  defaultAxisRange,
  defaultEndTime,
  defaultRangeIndex,
  defaultStartTime,
} from "../constants/dateState";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { isSameDay, isToday, startOfDay, subDays } from "date-fns";
import { YYYY_MM_DD } from "../lib/validators";
import { useConfigRequired } from "./config/useConfigStore";
import useCurrentUnitObject from "../components/common/hooks/useCurrentUnitObject";
import * as React from "react";

const TIME_DELIMITER = "-";

export const DATESTATE_SP_KEYS = {
  MONTH: "mo",
  YEAR: "y",
  DAY: "d",
  AXIS_RANGE_INDEX: "z",
  SELECTED_DATE_START: "sds",
  TIME: "time",
  CUSTOM_NUM_DAYS: "cd",
} as const;

export type DateStateSearchParamKeys =
  (typeof DATESTATE_SP_KEYS)[keyof typeof DATESTATE_SP_KEYS];

export type DateState = {
  axisRangeIndex: number;
  axisRangeFrom: ReturnType<typeof getAllDateRelatedData>;
  axisRangeTo: ReturnType<typeof getAllDateRelatedData>;
  axisRange: PREDEFINED_RANGE | CUSTOM_RANGE;

  /**
   * When mode is single, selectedDateStart should match axisRangeFrom.dateString
   * I'll probably validate this below.
   *
   * This design isn't ideal but it came from Sails and some of the components that
   * are hard to change still use this like the DTC and OF pages.
   *
   * Whatever.  I'll just make it work.
   */
  mode: "single" | "range";
  selectedDateStart: YYYY_MM_DD;
};

function localToUTC(local: Date) {
  return moment.utc(getDateString(local)).toDate();
}

function getDateString(local: Date) {
  return YYYY_MM_DD.parse(moment(local).format("YYYY-MM-DD"));
}

function getDateParts(dateString: string) {
  const justDate = dateString.split("T")[0];
  if (!justDate) throw new Error("Invalid date string");

  const [y, m, d] = justDate.split("-");
  if (!y || !m || !d) throw new Error("Invalid date string");

  return {
    y,
    m,
    d,
  };
}

function getAllDateRelatedData(midnightLocalDate: Date) {
  const utc = localToUTC(midnightLocalDate);
  const dateString = getDateString(midnightLocalDate);

  return {
    isToday: isToday(midnightLocalDate),
    local: midnightLocalDate,
    utc,
    dateString: YYYY_MM_DD.parse(dateString),
    dateParts: getDateParts(dateString),
  };
}

export function useDateState() {
  const { hasRealTimeUnits } = useConfigRequired();
  const currentUnit = useCurrentUnitObject();

  const [searchParams, setSp] = useSearchParams();

  const customNumDays = z.coerce
    .number()
    .int()
    .positive()
    .nullable()
    .parse(searchParams.get(DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS));

  const sds = searchParams.get(DATESTATE_SP_KEYS.SELECTED_DATE_START);

  const parsed = z
    .object({
      [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: z.coerce
        .number()
        .int()
        .min(0)
        .max(RANGES.length), // don't do - 1 because we support custom range which is the last index
      [DATESTATE_SP_KEYS.MONTH]: z.coerce.number().int().min(1).max(12),
      [DATESTATE_SP_KEYS.YEAR]: z.coerce
        .number()
        .int()
        .positive()
        .max(new Date().getFullYear()),
      [DATESTATE_SP_KEYS.DAY]: z.coerce.number().int().min(1).max(31),
      [DATESTATE_SP_KEYS.SELECTED_DATE_START]: YYYY_MM_DD.nullable(), // i dont want the presence of non YYYY_MM_DD string to be an error so I just provide the default null
    })
    .safeParse({
      [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: searchParams.get(
        DATESTATE_SP_KEYS.AXIS_RANGE_INDEX
      ),
      [DATESTATE_SP_KEYS.MONTH]: searchParams.get(DATESTATE_SP_KEYS.MONTH),
      [DATESTATE_SP_KEYS.YEAR]: searchParams.get(DATESTATE_SP_KEYS.YEAR),
      [DATESTATE_SP_KEYS.DAY]: searchParams.get(DATESTATE_SP_KEYS.DAY),
      [DATESTATE_SP_KEYS.SELECTED_DATE_START]: sds,
    });

  let out: DateState;

  const effects: (() => void)[] = [];

  if (parsed.success) {
    const data = parsed.data;

    const midNightLocal = new Date(`${data.mo}/${data.d}/${data.y}`);
    const toDateData = getAllDateRelatedData(midNightLocal);
    let axisRangeIndex = data.z;

    let range;
    range = RANGES[data.z]; // z can be 1 above (RANGES.length - 1) if custom range, that is if z===RANGES.length, we are custom
    if (!range) {
      // this SHOULD mean they're in custom mode, so we verify it below

      if (data.z !== RANGES.length) throw new Error("Invalid range index"); // checking that they aren't above the custom range index

      // all good: this means we are in custom range mode with the correct data
      if (customNumDays === null)
        throw new Error(
          `If you are in custom mode, you must provide a customNumDays. Never set ${DATESTATE_SP_KEYS.AXIS_RANGE_INDEX}=${RANGES.length} without setting ${DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS}`
        );

      const custom: CUSTOM_RANGE = {
        days: customNumDays,
        label: "unused", // doesn't matter
      };
      range = custom;
    }

    const fromDateData = getAllDateRelatedData(
      subDays(toDateData.local, range.days - 1)
    );

    if (data.sds !== null) {
      // if isRangeMode, we must validate sds is valid
      const [ySds, mSds, dSds] = data.sds.split("-");
      if (!ySds || !mSds || !dSds) throw new Error("Invalid date string");

      const sdsDate = new Date(`${mSds}/${dSds}/${ySds}`);

      if (isSameDay(sdsDate, toDateData.local) || sdsDate > toDateData.local) {
        // it's invalid
        // set the sds date to one day before the toDateData.local
        const correctedSds = YYYY_MM_DD.parse(
          subDays(toDateData.local, 1).toISOString().split("T")[0]
        );
        effects.push(() => {
          setSp((curr) => {
            const copy = new URLSearchParams(curr);
            copy.set(DATESTATE_SP_KEYS.SELECTED_DATE_START, correctedSds);
            return copy;
          });
        });

        out = {
          axisRangeIndex,
          axisRangeFrom: fromDateData,
          axisRangeTo: toDateData,
          axisRange: range,
          mode: "range",
          selectedDateStart: correctedSds,
        };
      } else {
        // it's valid
        out = {
          axisRangeIndex,
          axisRangeFrom: fromDateData,
          axisRangeTo: toDateData,
          axisRange: range,
          mode: "range",
          selectedDateStart: data.sds,
        };
      }
    } else {
      // we are in single mode and sds is null
      out = {
        axisRangeIndex,
        axisRangeFrom: fromDateData,
        axisRangeTo: toDateData,
        axisRange: range,
        mode: "single",
        selectedDateStart: toDateData.dateString, // in single mode, these should match. I hate this but that's what the DTC relies on
      };
    }
  } else {
    // if parsing fails, we're just going to set mode to single and set end date to today (or yesterday if no real time units)
    // we should be more robust but really the rest of our code should be written so we never
    // get here, so let's take the quick route for now

    const getDefaultAxisToDate = () => {
      /**
       * we need to decide if the default is today or yesterday
       * depending on the units we have, and wether or not
       * there is a current unit (if the url is "/{unit}")
       */
      const today = new Date();

      if (currentUnit)
        return currentUnit.isRealTime ? today : subDays(today, 1);

      /**
       * In the case that we are on the plant overview page,
       * currentUnit will be undefined. If the plant has at
       * least one real time unit, we want to default to today.
       * This will cause the petal charts of the units that are
       * daily (if any) to have an empty day, but that's ok?
       */
      if (hasRealTimeUnits) return today;

      // If no real-time units (or no units at all), default to yesterday
      return subDays(today, 1);
    };

    const toDateData = getAllDateRelatedData(getDefaultAxisToDate());
    const range = defaultAxisRange;

    const fromDateData = getAllDateRelatedData(
      subDays(toDateData.local, range.days - 1)
    );

    effects.push(() => {
      setSp((curr) => {
        const copy = new URLSearchParams(curr);

        copy.set(
          DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
          defaultRangeIndex.toString()
        );

        copy.set(DATESTATE_SP_KEYS.MONTH, toDateData.dateParts.m);
        copy.set(DATESTATE_SP_KEYS.YEAR, toDateData.dateParts.y);
        copy.set(DATESTATE_SP_KEYS.DAY, toDateData.dateParts.d);

        return copy;
      });
    });

    out = {
      axisRangeIndex: defaultRangeIndex,
      axisRangeFrom: fromDateData,
      axisRangeTo: toDateData,
      axisRange: range,
      mode: "single",
      selectedDateStart: toDateData.dateString, // in single mode, these should match. I hate this but that's what the DTC relies on
    };
  }

  React.useEffect(() => {
    if (effects.length === 0) return;
    effects.forEach((fn) => fn());
  });

  const applyAxisRangeIndex = (idx: number, sp: URLSearchParams) => {
    const index_ = z.number().int().min(0).max(RANGES.length).parse(idx);

    sp.set(DATESTATE_SP_KEYS.AXIS_RANGE_INDEX, index_.toString());
  };

  function onAxisRangeSelect(idx: number) {
    setSp((curr) => {
      const copy = new URLSearchParams(curr);
      applyAxisRangeIndex(idx, copy);
      return copy;
    });
  }

  const setEndParams = (localDate: Date, sp: URLSearchParams) => {
    sp.set(DATESTATE_SP_KEYS.MONTH, (localDate.getMonth() + 1).toString());
    sp.set(DATESTATE_SP_KEYS.YEAR, localDate.getFullYear().toString());
    sp.set(DATESTATE_SP_KEYS.DAY, localDate.getDate().toString());
  };

  const getTimeParams = () => {
    const timeKeys = searchParams.get(DATESTATE_SP_KEYS.TIME);

    if (timeKeys) {
      const timeParse = z
        .string()
        .transform((v) => {
          const decoded = decodeURIComponent(v);
          let [start, end] = decoded.split(TIME_DELIMITER);
          if (!start || !end) throw new Error("invalid time");

          return [start, end] as const;
        })
        .parse(timeKeys);

      return timeParse;
    }

    return [defaultStartTime, defaultEndTime] as const;
  };

  const multiSet = (opts: {
    end?: Date;
    rangeIdx?: number;
    time?: {
      start: string;
      end: string;
    };
    customNumDays?: number;
    sds?: string;
  }) => {
    setSp((curr) => {
      const copy = new URLSearchParams(curr);
      if (opts.end) {
        setEndParams(opts.end, copy);
      }

      if (opts.rangeIdx !== undefined) {
        applyAxisRangeIndex(opts.rangeIdx, copy);
      }

      if (opts.time) {
        copy.set(
          DATESTATE_SP_KEYS.TIME,
          encodeURIComponent(`${opts.time.start}-${opts.time.end}`)
        );
      }

      if (opts.customNumDays) {
        copy.set(
          DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS,
          opts.customNumDays.toString()
        );
      }

      if (opts.sds) {
        copy.set(DATESTATE_SP_KEYS.SELECTED_DATE_START, opts.sds);
      }
      return copy;
    });
  };

  function setEnd(localDate: Date) {
    setSp((curr) => {
      const copy = new URLSearchParams(curr);
      setEndParams(localDate, copy);
      return copy;
    });
  }

  const setEndAndRange = (localDate: Date, rangeIdx: number) => {
    setSp((curr) => {
      const copy = new URLSearchParams(curr);

      setEndParams(localDate, copy);
      applyAxisRangeIndex(rangeIdx, copy);

      return copy;
    });
  };

  function enterRangeMode() {
    if (out.mode === "range") throw new Error("already in range mode");

    setSp((curr) => {
      const copy = new URLSearchParams(curr);

      copy.set(
        DATESTATE_SP_KEYS.SELECTED_DATE_START,
        YYYY_MM_DD.parse(
          subDays(out.axisRangeTo.local, 1).toISOString().split("T")[0]
        )
      );

      return copy;
    });
  }

  function exitRangeMode() {
    if (out.mode === "single")
      throw new Error("currently in single mode. cant exit range mode");

    setSp((curr) => {
      const copy = new URLSearchParams(curr);
      copy.delete(DATESTATE_SP_KEYS.SELECTED_DATE_START);
      return copy;
    });
  }

  /**
   * Meant to be interfaced with the date range picker but only in range mode
   */
  const setRange = (localStart: Date, localEnd: Date) => {
    if (out.mode === "single") throw new Error("currently in single mode");

    setSp((curr) => {
      const copy = new URLSearchParams(curr);

      /**
       * Handle widening the rane if necessary, we never shrink the range though, see early return below
       */
      const handleNewAxisRangeIndex = () => {
        const startStart = startOfDay(localStart);
        const endStart = startOfDay(localEnd);

        const days =
          (endStart.getTime() - startStart.getTime()) / (1000 * 60 * 60 * 24);

        const inclusive = days + 1; // inclusive of start and end

        if (inclusive <= out.axisRange.days) return; // no need to change

        const rangeIdx = RANGES.findIndex((r) => inclusive <= r.days);

        if (rangeIdx === -1) {
          copy.set(
            DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
            RANGES.length.toString()
          );
          copy.set(DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS, inclusive.toString());
        } else
          copy.set(DATESTATE_SP_KEYS.AXIS_RANGE_INDEX, rangeIdx.toString());
      };

      handleNewAxisRangeIndex();
      copy.set(
        DATESTATE_SP_KEYS.SELECTED_DATE_START,
        getDateString(localStart)
      );

      const { d, m, y } = getDateParts(getDateString(localEnd));

      copy.set(DATESTATE_SP_KEYS.MONTH, m);
      copy.set(DATESTATE_SP_KEYS.YEAR, y);
      copy.set(DATESTATE_SP_KEYS.DAY, d);

      return copy;
    });
  };

  const isCustom =
    defaultStartTime !== getTimeParams()[0] ||
    defaultEndTime !== getTimeParams()[1];

  return {
    ...out,
    onAxisRangeSelect,
    setEnd,
    enterRangeMode,
    exitRangeMode,
    setRange,
    setEndAndRange,
    multiSet,
    customNumDays,
    isCustom,
    util: {
      getAllDateRelatedData,
    },
    getTimeParams,
    defaultStartTime,
    defaultEndTime,
  };
}

/**
 * Copied and pasted from above to make it synchronous
 */
export function getDateStateSync(searchParams: URLSearchParams) {
  const customNumDays = z.coerce
    .number()
    .int()
    .positive()
    .nullable()
    .parse(searchParams.get(DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS));

  const sds = searchParams.get(DATESTATE_SP_KEYS.SELECTED_DATE_START);

  const parsed = z
    .object({
      [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: z.coerce
        .number()
        .int()
        .min(0)
        .max(RANGES.length), // don't do - 1 because we support custom range which is the last index
      [DATESTATE_SP_KEYS.MONTH]: z.coerce.number().int().min(1).max(12),
      [DATESTATE_SP_KEYS.YEAR]: z.coerce
        .number()
        .int()
        .positive()
        .max(new Date().getFullYear()),
      [DATESTATE_SP_KEYS.DAY]: z.coerce.number().int().min(1).max(31),
      [DATESTATE_SP_KEYS.SELECTED_DATE_START]: YYYY_MM_DD.nullable(), // i dont want the presence of non YYYY_MM_DD string to be an error so I just provide the default null
    })
    .safeParse({
      [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: searchParams.get(
        DATESTATE_SP_KEYS.AXIS_RANGE_INDEX
      ),
      [DATESTATE_SP_KEYS.MONTH]: searchParams.get(DATESTATE_SP_KEYS.MONTH),
      [DATESTATE_SP_KEYS.YEAR]: searchParams.get(DATESTATE_SP_KEYS.YEAR),
      [DATESTATE_SP_KEYS.DAY]: searchParams.get(DATESTATE_SP_KEYS.DAY),
      [DATESTATE_SP_KEYS.SELECTED_DATE_START]: sds,
    });

  let out: DateState;

  if (parsed.success) {
    const data = parsed.data;

    const midNightLocal = new Date(`${data.mo}/${data.d}/${data.y}`);
    const toDateData = getAllDateRelatedData(midNightLocal);
    let axisRangeIndex = data.z;

    let range;
    range = RANGES[data.z]; // z can be 1 above (RANGES.length - 1) if custom range, that is if z===RANGES.length, we are custom
    if (!range) {
      // this SHOULD mean they're in custom mode, so we verify it below

      if (data.z !== RANGES.length) throw new Error("Invalid range index"); // checking that they aren't above the custom range index

      // all good: this means we are in custom range mode with the correct data
      if (customNumDays === null)
        throw new Error(
          `If you are in custom mode, you must provide a customNumDays. Never set ${DATESTATE_SP_KEYS.AXIS_RANGE_INDEX}=${RANGES.length} without setting ${DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS}`
        );

      const custom: CUSTOM_RANGE = {
        days: customNumDays,
        label: "unused", // doesn't matter
      };
      range = custom;
    }

    const fromDateData = getAllDateRelatedData(
      subDays(toDateData.local, range.days - 1)
    );

    if (data.sds !== null) {
      // if isRangeMode, we must validate sds is valid
      const [ySds, mSds, dSds] = data.sds.split("-");
      if (!ySds || !mSds || !dSds) throw new Error("Invalid date string");

      const sdsDate = new Date(`${mSds}/${dSds}/${ySds}`);

      if (isSameDay(sdsDate, toDateData.local) || sdsDate > toDateData.local) {
        // it's invalid
        // set the sds date to one day before the toDateData.local
        const correctedSds = YYYY_MM_DD.parse(
          subDays(toDateData.local, 1).toISOString().split("T")[0]
        );

        out = {
          axisRangeIndex,
          axisRangeFrom: fromDateData,
          axisRangeTo: toDateData,
          axisRange: range,
          mode: "range",
          selectedDateStart: correctedSds,
        };
      } else {
        // it's valid
        out = {
          axisRangeIndex,
          axisRangeFrom: fromDateData,
          axisRangeTo: toDateData,
          axisRange: range,
          mode: "range",
          selectedDateStart: data.sds,
        };
      }
    } else {
      // we are in single mode and sds is null
      out = {
        axisRangeIndex,
        axisRangeFrom: fromDateData,
        axisRangeTo: toDateData,
        axisRange: range,
        mode: "single",
        selectedDateStart: toDateData.dateString, // in single mode, these should match. I hate this but that's what the DTC relies on
      };
    }
  } else throw new Error("Invalid date state");

  const getTimeParams = () => {
    const timeKeys = searchParams.get(DATESTATE_SP_KEYS.TIME);

    if (timeKeys) {
      const timeParse = z
        .string()
        .transform((v) => {
          const decoded = decodeURIComponent(v);
          let [start, end] = decoded.split(TIME_DELIMITER);
          if (!start || !end) throw new Error("invalid time");

          return [start, end] as const;
        })
        .parse(timeKeys);

      return timeParse;
    }

    return [defaultStartTime, defaultEndTime] as const;
  };

  const isCustom =
    defaultStartTime !== getTimeParams()[0] ||
    defaultEndTime !== getTimeParams()[1];

  return { ...out, isCustom, getTimeParams };
}
