import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { useRedirectSearchParam } from "./NavigateToLogin";
import { LoginForm } from "./login-form";
import { LoginContainer } from "./container";
import { getMsalUrl } from "../../frameworks/fetcher/api-routes-experimental";
import { useConfigRequired } from "../../zustand/config/useConfigStore";
import { ThemeToggle } from "../nav/theme-toggle";

/**
 * redirects to the plant overview page
 * if the user is authenticated.
 */
function Login(props: { setPass?: boolean; adminBypass?: boolean }) {
  const redirectURL = useRedirectSearchParam();
  const navigate = useNavigate();
  const authed = useAuthStore((s) => s.authed);

  const [submitting, setSubmitting] = useState(false);

  const isMsal = useConfigRequired().entra;

  const bypassRedirect = props.adminBypass;

  useEffect(() => {
    isMsal &&
      !bypassRedirect &&
      void getMsalUrl().then((u) => {
        window.location.href = u;
      });
  }, [isMsal, bypassRedirect]);

  useEffect(() => {
    authed && navigate(redirectURL ?? "/");
  }, [authed, redirectURL, navigate]);

  if (isMsal && !props.adminBypass) return null;

  return (
    <LoginContainer isLoading={submitting}>
      <LoginForm
        {...props}
        setSubmitting={setSubmitting}
        submitting={submitting}
      />

      <ThemeToggle className="fixed bottom-4 left-1/2 -translate-y-1/2" />
    </LoginContainer>
  );
}

export default Login;
