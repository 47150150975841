import { PropsWithChildren, useMemo } from "react";
import {
  InferQueryResultData,
  useInvalidateUsersQuery,
  useUsersQuery,
} from "../../../hooks/tanstack-query";
import { Input } from "../../../shared-ui/frontend/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";
import { useUserRequired } from "../../../zustand/auth/useAuthStore";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import { UserForm } from "./user-form";
import { PaginationProvider, usePagination } from "../../common/pagination";
import {
  type QueryBy,
  UseUsersManagerStoreProvider,
  useGetUseUsersManagerStore,
} from "./users-manager-store-provider";
import { matchSorter } from "match-sorter";
import { PropsWithCn, cn } from "../../../shared-ui/frontend/cn";
import { UserCard } from "./user-card";
import { Button } from "../../../shared-ui/frontend/button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { iife } from "../../../lib/utils";
import { useMutation } from "@tanstack/react-query";
import { createUser } from "../../../frameworks/fetcher/api-routes-experimental";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { UserRoleString } from "../../../zustand/auth/types/UserRole";
import { Download } from "lucide-react";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import api from "../../../lib/api";

type User = InferQueryResultData<ReturnType<typeof useUsersQuery>>[number];

const queryByMatchSorterKeys: Record<QueryBy, string[]> = {
  email: ["email"] satisfies (keyof User)[],
  "first name": ["first"] satisfies (keyof User)[],
  "last name": ["last"] satisfies (keyof User)[],
};

function WithPaginationProvider({ children }: PropsWithChildren) {
  const usersQuery = useUsersQuery();

  const useStore = useGetUseUsersManagerStore();

  const queryBy = useStore((s) => s.queryBy);
  const query = useStore((s) => s.query.trim().toLowerCase());

  const filterFn = useStore((s) => {
    const fn = s.enabledFilters[s.filter];
    if (!fn) throw new Error("Filter function not defined");
    return fn;
  });

  const sortFn = useStore((s) => {
    const fn = s.enabledSorts[s.sort];
    if (!fn) throw new Error("Sort function not defined");
    return fn;
  });

  const usersList = useMemo((): User[] | undefined => {
    if (!usersQuery.data) return undefined;

    const filtered = usersQuery.data.filter((x) => filterFn(x));

    if (!query) return filtered.sort(sortFn);

    return matchSorter(filtered, query, {
      keys: queryByMatchSorterKeys[queryBy],
      threshold: matchSorter.rankings.CONTAINS,
    }).sort(sortFn);
  }, [usersQuery.data, query, queryBy, filterFn, sortFn]);

  return (
    <PaginationProvider<User> pageSize={30} data={usersList}>
      {children}
    </PaginationProvider>
  );
}

function FiltersAndSearch({ className }: PropsWithCn) {
  const useStore = useGetUseUsersManagerStore();
  const sort = useStore((s) => s.sort);
  const filter = useStore((s) => s.filter);

  const query = useStore((s) => s.query);
  const queryBy = useStore((s) => s.queryBy);
  const enabledFilters = useStore((s) => s.enabledFilters);
  const enabledSorts = useStore((s) => s.enabledSorts);

  return (
    <div className={cn("flex", className)}>
      <Input
        value={query}
        onChange={(e) => useStore.getState().setQuery(e.target.value)}
        className="h-8 max-w-[130px] inline rounded-r-none focus-visible:max-w-[300px]"
        placeholder="Search"
      />
      <Select
        value={queryBy}
        onValueChange={(s) => useStore.getState().setQueryBy(s as QueryBy)}
      >
        <SelectTrigger className="max-w-max rounded-l-none">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Search by</SelectLabel>
            {Object.keys(queryByMatchSorterKeys).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select
        value={sort.toString()}
        onValueChange={(s) => useStore.getState().setSort(s)}
      >
        <SelectTrigger className="max-w-max ml-auto">
          <span>Sort: {sort.toString()}</span>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.keys(enabledSorts).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        value={filter.toString()}
        onValueChange={(s) => useStore.getState().setFilter(s)}
      >
        <SelectTrigger className="max-w-max ml-2">
          <span>Filter: {filter.toString()}</span>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.keys(enabledFilters).map((label) => (
              <SelectItem key={label} value={label}>
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function Paginator() {
  const { page, pageSize, data, hasPrev, prev, hasNext, next } =
    usePaginatedUsers();

  /**
   * Data is undefined while we wait for data to load
   */
  if (!data) return null;

  return (
    <div className="py-6 flex justify-end">
      <Button
        disabled={!hasPrev}
        onClick={prev}
        size={"xxs"}
        className="rounded-r-none border-r-0"
      >
        <FaArrowLeft />
      </Button>
      <Button
        className="rounded-none cursor-pointer pointer-events-none"
        size={"xxs"}
      >
        Page {page + 1} of {Math.floor(data.length / pageSize) + 1}
      </Button>
      <Button
        disabled={!hasNext}
        onClick={next}
        size={"xxs"}
        className="rounded-l-none border-l-0"
      >
        <FaArrowRight />
      </Button>
    </div>
  );
}

function UsersList() {
  const { slice } = usePagination<User>();

  const refreshQuery = useInvalidateUsersQuery();

  return (
    slice && (
      <div className="drop-shadow-md my-5">
        {slice.map((user) => (
          <UserCard key={user._id} user={user} onMutation={refreshQuery} />
        ))}
      </div>
    )
  );
}

function FormMaybe() {
  const useStore = useGetUseUsersManagerStore();
  const showForm = useStore((s) => s.createMode);
  const baseUrl = useBaseUrlExperimental();
  const baseUrlNoSlash = baseUrl.replace("/", "");

  const closeForm = () => useStore.getState().setCreateMode(false);

  const refetchUsers = useInvalidateUsersQuery();

  const createUserMut = useMutation({
    mutationFn: (p: {
      email: string;
      first: string;
      last: string;
      reports: string[];
      role: UserRoleString;
    }) => createUser({ ...p, units: [baseUrlNoSlash] }),
    onError(e) {
      addUnknownErrorToast(e);
    },
    onSuccess: ({ first, last }) => {
      addSuccessToast(`Added ${first} ${last} to ${baseUrlNoSlash}`);
      closeForm();
      refetchUsers();
    },
  });

  if (!showForm) return null;

  return (
    <UserForm
      disabled={createUserMut.isLoading}
      onSubmit={createUserMut.mutate}
      onClose={closeForm}
    />
  );
}

function usePaginatedUsers() {
  const pagination = usePagination<User>();
  return pagination;
}

function Header() {
  const useStore = useGetUseUsersManagerStore();

  const pagination = usePaginatedUsers();
  const { data, page, pageSize } = pagination;

  const user = useUserRequired();
  const canEdit = user.hasEditPermission;

  return (
    <div className="flex flex-row items-center mt-6">
      <span className="text-[2rem] sm:text-[1.75rem] mr-2">Users</span>
      {canEdit && (
        <CreateNewEntityButton
          onClick={() => useStore.getState().setCreateMode(true)}
        />
      )}
      {iife(() => {
        if (!data) return undefined;

        let label;

        if (data.length) label = "Displaying 0 of 0 users";

        const start = page * pageSize + 1;
        const end = Math.min(start + pageSize - 1, data.length);

        label = `Displaying ${start}-${end} of ${data.length} users`;

        return (
          <span className="mr-5 italic text-[1.25rem] text-titlegrey ml-auto">
            {label}
          </span>
        );
      })}
      <DownloadButton />
    </div>
  );
}

function DownloadButton() {
  const { data } = usePaginatedUsers();
  if (!data) return null;

  const download = () => {
    api.getSpecialtyReports().then((reports) => {
      const rows = data.map((u) => ({
        "First Name": u.first,
        "Last Name": u.last,
        Email: u.email,
        Role: u.role,
        Reports: reports
          // todo the reports are not typed correctly
          // @ts-ignore
          .filter((r) => r.users.includes(u._id))
          // @ts-ignore
          .map((r) => r.name)
          .join(", "),
      }));
      downloadXLSX(rows, "users.xlsx");
    });
  };

  return (
    <Button variant={"ghost"} size={"sm"} onClick={download}>
      <Download className="h-4 w-4 mr-1" />
      DOWNLOAD
    </Button>
  );
}

const enabledFilters = {
  All: () => true,
  "Admin Role": (u) => u.role === "Admin",
  "User Role": (u) => u.role === "User",
} satisfies Record<string, (a: User) => boolean>;

const enabledSorts = {
  "first name": (a, b) => a.first.localeCompare(b.first),
  "last name": (a, b) => a.last.localeCompare(b.last),
  "last updated": (a, b) =>
    new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  email: (a, b) => a.email.localeCompare(b.email),
} satisfies Record<string, (a: User, b: User) => number>;

function UsersManager2() {
  return (
    <UseUsersManagerStoreProvider
      enabledFilters={enabledFilters}
      initialFilter="All"
      initialSort="first name"
      enabledSorts={enabledSorts}
    >
      <WithPaginationProvider>
        <Header />
        <FormMaybe />
        <FiltersAndSearch />
        <UsersList />
        <Paginator />
      </WithPaginationProvider>
    </UseUsersManagerStoreProvider>
  );
}

export { UsersManager2 };
