import classNames from "classnames";
import React, { forwardRef } from "react";
import _ from "lodash";
import { type BaseButtonProps } from "./BaseButtonProps";
import { type IconType_ } from "../../../zustand/config/types/IconType";

type CustomButtonProps = {
  active?: boolean;
  loading?: boolean; // if true, replaces the icon with loading spinner
  icon?: IconType_; // should be a component from react-icons or a FontAwesome string: fa-{thispartofthestring}
  iconClasses?: string;
  size?: "xs" | "sm" | "md" | "lg"; // default size is SM
} & BaseButtonProps;

/**
 * Daisy button
 *
 * Default classnames are "btn-sm" & "btn"
 * Extend by passing className:string prop
 *
 * active:bool - adds "btn-active" to classname if true
 *
 * loading:bool - add "loading" to classname if true (see Daisy button)
 *
 * icon - pass a string from fontawesome or a react-icons Element
 *
 * iconClasses - classNames to be applied to the icon if present
 *
 * size - Daisy size ex. "lg". default is "sm"
 */
const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      size,
      icon,
      className,
      children,
      iconClasses,
      active,
      loading = false,
      type = "button",
      ...rest
    },
    ref
  ) => {
    const buttonSize = "btn-" + (size ?? "sm");
    const buttonClasses = classNames("btn", buttonSize, className, {
      loading, // daisy ui will give it a spinner if loading class is added
      "btn-active": !!active,
    });

    const iconComponent = (() => {
      if (!icon) {
        return null;
      }

      const marginClass = children && "mr-1";

      if (_.isString(icon)) {
        return (
          <i
            className={classNames("fa", "fa-" + icon, iconClasses, marginClass)}
          />
        );
      }

      if (Array.isArray(icon)) {
        const mappedFAClasses = icon.map((i) => "fa-" + i);
        return (
          <i
            className={classNames(
              "fa",
              iconClasses,
              ...mappedFAClasses,
              marginClass
            )}
          />
        );
      }

      const ReactIconsIcon: IconType_ = icon;

      return <ReactIconsIcon className={classNames(iconClasses)} />;
    })();

    return (
      <button ref={ref} type={type} className={buttonClasses} {...rest}>
        {iconComponent}
        {children}
      </button>
    );
  }
);

export default Button;

export { Button as DaisyButton };
