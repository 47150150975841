import { useQuery } from "@tanstack/react-query";
import { useConfigRequired } from "../../../zustand/config/useConfigStore";
import { getEntraUsersMap } from "../../../frameworks/fetcher/api-routes-experimental";
import * as MC from "../../manager-components";
import { useEffect, useId } from "react";
import { Label } from "../../../shared-ui/frontend/label";
import { Input } from "../../../shared-ui/frontend/input";
import { Controller, useForm } from "react-hook-form";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { PrestyledMultiSelect } from "../../common/prestyled-multi-select";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, AlertDescription } from "../../../shared-ui/frontend/alert";
import { type Equal } from "type-testing";
import { useSpecialtyReportsQuery } from "../../../hooks/tanstack-query";
import * as ManagerComponents from "../../manager-components";
import { PropsWithCn } from "../../../shared-ui/frontend/cn";
import { userSchema } from "../../../lib/api-schema/user";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";

type Inputs = {
  email: string;
  first: string;
  last: string;
  reports: string[];
  role: userSchema["role"];
};

const resolver = z
  .object({
    email: z.string().email({ message: "Invalid email" }),
    first: z.string().min(1, {
      message: "First name is required",
    }),
    last: z.string().min(1, {
      message: "Last name is required",
    }),
    reports: z.array(z.string()),
  })
  .merge(userSchema.pick({ role: true }));

/**
 * The resolver must be the same as the form inputs, this type check
 * will error if you ever change it and it doesn't match. A type-level
 * safeguard.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _thisMustAlwaysBeTrue: Equal<z.infer<typeof resolver>, Inputs> = true;

function UserForm({
  onClose,
  onSubmit,
  disabled,
  className,
  defaultValues,
  showRoleSelect,
  disableRoleSelect,
  includeRootInRoleSelect,
}: PropsWithCn<{
  onClose: () => void;
  onSubmit: (payload: Inputs) => void;
  disabled?: boolean;
  defaultValues?: Inputs;
  showRoleSelect?: boolean;
  disableRoleSelect?: boolean;
  includeRootInRoleSelect?: boolean;
}>) {
  const unit = useCurrentUnitObject();
  if (!unit) throw new Error("No unit");

  const config = useConfigRequired();
  const isEntra = config.entra;

  const entraUsersMapQuery = useQuery({
    // Remove the queryKey property
    queryKey: ["entraUsersMap"],
    queryFn: getEntraUsersMap,
    refetchOnWindowFocus: false,
  });

  const entraUsersMap = isEntra ? entraUsersMapQuery.data : undefined;

  const emailId = useId();
  const firstNameId = useId();
  const lastNameId = useId();
  const reportsId = useId();
  const roleId = useId();

  const baseUrl = useBaseUrlExperimental();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Inputs>({
    resolver: zodResolver(resolver),

    /**
     * See issue https://github.com/react-hook-form/react-hook-form/issues/3758
     *
     * Because we use watch below, we need to set defaultValues here, otherwise
     * the values could be undefined, when they're typed as a string.
     *
     * I think this is a library bug to be honest.
     *
     * For now, I'm just gonna assume it's best practice to always define defaultValues
     */
    defaultValues: (defaultValues ?? {
      reports: [],
      email: "",
      first: "",
      last: "",
      role: "User",
    }) satisfies Inputs,
    disabled: disabled,
  });

  const currentEmail = watch("email");

  /**
   * Try to autofill the user if possible
   */
  useEffect(() => {
    if (!entraUsersMap) return;

    const user = entraUsersMap[currentEmail.trim().toLowerCase()];
    if (!user) return;
    setValue("first", user.first);
    setValue("last", user.last);
  }, [currentEmail, entraUsersMap, setValue]);

  const reportsQuery = useSpecialtyReportsQuery(baseUrl);

  const reports = reportsQuery.data;

  const onSubmit_ = handleSubmit(onSubmit);

  return (
    <MC.FormContainer className={className} onSubmit={onSubmit_}>
      {Object.values(errors).map((x, i) => {
        return (
          x.message && (
            <Alert
              variant={"destructive"}
              key={x.message + i.toString()} // just in case messages are same
              className="animate-in slide-in-from-top-2"
            >
              <AlertDescription>{x.message}</AlertDescription>
            </Alert>
          )
        );
      })}

      <Label className="[&:not(:first-child)]:mt-3" htmlFor={emailId}>
        Email
      </Label>
      <Input
        id={emailId}
        {...register("email", { required: true, minLength: 1 })}
      />

      <Label htmlFor={firstNameId}>First Name</Label>
      <Input
        id={firstNameId}
        {...register("first", { required: true, minLength: 1 })}
      />

      <Label htmlFor={lastNameId}>Last Name</Label>
      <Input
        id={lastNameId}
        {...register("last", { required: true, minLength: 1 })}
      />

      <Label htmlFor={reportsId}>Reports</Label>
      <Controller
        disabled={reportsQuery.isLoading}
        control={control}
        name="reports"
        render={({ field }) => (
          <PrestyledMultiSelect
            id={reportsId}
            data={
              reports?.map((x) => ({
                value: x._id,
                label: x.name,
              })) ?? []
            }
            {...field}
          />
        )}
      />

      {showRoleSelect && (
        <>
          <Label htmlFor={roleId}>Role</Label>
          <Controller
            disabled={reportsQuery.isLoading}
            control={control}
            name="role"
            render={({
              field: { name, onBlur, onChange, value, ref, disabled },
            }) => (
              <Select
                disabled={disableRoleSelect || disabled}
                value={value}
                onValueChange={onChange}
                name={name}
              >
                <SelectTrigger onBlur={onBlur} ref={ref} id={roleId}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={"User" satisfies Inputs["role"]}>
                      User
                    </SelectItem>
                    <SelectItem value={"Admin" satisfies Inputs["role"]}>
                      Admin
                    </SelectItem>
                    {includeRootInRoleSelect && (
                      <SelectItem value={"Root" satisfies Inputs["role"]}>
                        Root
                      </SelectItem>
                    )}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
          />
        </>
      )}

      <ManagerComponents.CancelSaveFooter
        className="mt-4"
        close={{
          onClick: onClose,
          disabled: disabled,
        }}
        submit={{
          disabled: disabled,
        }}
      />
    </MC.FormContainer>
  );
}

export { UserForm };
