import InputOld from "../common/InputOld";

export default function DateSelectorsGroupSelect({
  action,
  value,
  options,
}: {
  action: (value: string) => void;
  options: { value: string; label: string }[];
  value: string | undefined;
}) {
  return (
    <InputOld
      classes={{
        Input:
          "w-full md:max-w-[200px] select-bordered border-bdgrey font-normal mb-2 md:mb-0 md:mr-2",
      }}
      type="select"
      action={action}
      value={value}
      options={options}
    />
  );
}
