import { Button } from "../../ui/button";
import { Check, X } from "lucide-react";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "../variability-drawer";
import { v4 } from "uuid";
import { MODE_VIEWS } from "../../charts/constants/dynamicTrendChartConstants";
import { GoArrowSwitch } from "react-icons/go";
import { AddVariablesButtonWithDialog } from "./add-variables-button-with-dialog";
import { PropsWithCn } from "../../types/component.types";
import { cn } from "../../../lib/utils";
import { CardsHeader } from "./cards-header";
import { FaUndo } from "react-icons/fa";
import { uniq } from "remeda";
import React, { useEffect, useRef } from "react";

const getDrawDims = (elt: HTMLDivElement) => {
  const height = elt.getBoundingClientRect().height;

  const margin = 10;

  // some math I came up with so that it looks good on every screen
  const heightForSvg = window.innerHeight * 0.98 - height - 2.5 * margin;
  const widthForSvg = window.innerWidth - 2 * margin;

  return {
    height: heightForSvg,
    width: widthForSvg,
  };
};

export function VariabilityHeader({ className }: PropsWithCn) {
  const useStore = useGetUseVariabilityDrawerStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const showLineGraphInHistogram = useStore((s) => s.showLineGraphInHistogram);
  const showBarsInHistogram = useStore((s) => s.showBarsInHistogram);
  const showLimitLines = useStore((s) => s.showLimitLines);
  const showBoxPlot = useStore((s) => s.showBoxPlot);
  const view = useStore((s) => s.view);

  const groups = useMin1Groups();

  const [firstBucket, ...restBuckets] = groups;
  const hasMoreThanOneBucket = restBuckets.length > 0;

  const moreThanOneVariableSelected =
    groups.flatMap((x) => x.variables).length > 1;
  const showRelativeViewSwitcher =
    moreThanOneVariableSelected || hasMoreThanOneBucket;

  const onlyOneUniqueVariable =
    uniq(groups.flatMap((x) => x.variables.map((v) => v._id))).length === 1;

  useEffect(() => {
    const curr = containerRef.current;

    // we want a resize observer so when we open variability drawer for the first time, it takes into account the variable cards
    // before, the variability svgs were too big because the variable cards were not rendered when calculating draw dims
    const resizeObserver = new ResizeObserver((entries) => {
      // we only want to track resizing of header, nothing else
      if (entries.length > 1) throw new Error("Too many elements are resizing");

      const first = entries[0];
      if (!first) throw new Error("No entries");

      useStore.setState({
        svgContainerRatio: getDrawDims(first.target as HTMLDivElement),
      });
    });

    if (curr) {
      resizeObserver.observe(curr);
      return () => {
        resizeObserver.unobserve(curr);
      };
    }
  }, [containerRef, useStore]);

  return (
    <div ref={containerRef} className={cn("flex flex-col sticky top-0 z-10")}>
      {/* Chart controls*/}
      <div className="flex items-center border-b border-xslate-5 bg-white shadow-sm px-4 rounded-t-lg">
        <div className="inline-flex gap-2 items-center">
          <span className="font-semibold tracking-tight text-lg text-xslate-12">
            Variability Dynamics
          </span>
        </div>

        {/* Button end */}
        <div className="inline-flex gap-2 ml-auto items-center">
          {onlyOneUniqueVariable && (
            <Button
              size={"sm"}
              variant={showLimitLines ? "default" : "outline"}
              onClick={() =>
                useStore.setState({
                  showLimitLines: !showLimitLines,
                })
              }
            >
              {showLimitLines && (
                <Check className="h-4 w-4 translate-y-[1.5px]" />
              )}
              <span className={cn(showLimitLines && "ml-1")}>
                Limits (Operating Fitness)
              </span>
            </Button>
          )}
          <Button
            className="my-2"
            size={"sm"}
            variant={showBoxPlot ? "default" : "outline"}
            onClick={() =>
              useStore.setState({
                showBoxPlot: !showBoxPlot,
              })
            }
          >
            {showBoxPlot && <Check className="h-4 w-4 translate-y-[1.5px]" />}
            <span className={cn(showBoxPlot && "ml-1")}>Box Plot</span>
          </Button>
          <Button
            className="my-2"
            size={"sm"}
            variant={showBarsInHistogram ? "default" : "outline"}
            onClick={() =>
              useStore.setState({
                showBarsInHistogram: !showBarsInHistogram,
              })
            }
          >
            {showBarsInHistogram && (
              <Check className="h-4 w-4 translate-y-[1.5px]" />
            )}
            <span className={cn(showBarsInHistogram && "ml-1")}>Histogram</span>
          </Button>
          <Button
            size={"sm"}
            variant={showLineGraphInHistogram ? "default" : "outline"}
            onClick={() =>
              useStore.setState({
                showLineGraphInHistogram: !showLineGraphInHistogram,
              })
            }
          >
            {showLineGraphInHistogram && (
              <Check className="h-4 w-4 translate-y-[1.5px]" />
            )}
            <span className={cn(showLineGraphInHistogram && "ml-1")}>
              Density
            </span>
          </Button>

          <div className="self-stretch w-[0.5px] bg-xslate-7"></div>
          {/* Debugging tool for during development */}
          <AddVariablesButtonWithDialog />

          {showRelativeViewSwitcher && (
            <>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  switch (view) {
                    case "absolute":
                      useStore.setState({ view: "relative" });
                      break;
                    case "relative":
                      useStore.setState({ view: "absolute" });
                      break;
                    default:
                      const _exhaustiveCheck: never = view;
                      throw new Error(
                        `Unrecognized view type: ${_exhaustiveCheck}`
                      );
                  }
                }}
              >
                <GoArrowSwitch className="h-4 w-4" />
                <span className="ml-2 uppercase">
                  {view === "relative" ? "Relative" : "Absolute"}
                </span>
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  useStore.setState((curr) => ({
                    view: "absolute",
                    groups: [
                      {
                        excludedModesMap: { [MODE_VIEWS.shutdown]: true },
                        start: firstBucket.start,
                        end: firstBucket.end,
                        variables: [firstBucket.variables[0]],
                        id: v4(),
                      },
                    ],
                    massSelected: [],
                  }));
                }}
              >
                <FaUndo className="h-3 w-3" />
                <span className="ml-1 uppercase">Reset</span>
              </Button>
            </>
          )}

          <Button
            variant="outline"
            size="sm"
            className="uppercase"
            onClick={() => useStore.getState().close()}
          >
            <X className="h-4 w-4" />
            <span className="ml-1">close</span>
          </Button>
        </div>
      </div>

      <CardsHeader />
    </div>
  );
}
