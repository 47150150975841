import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import LoggedInRoute from "./components/login/logged-in-route-protect";
import "./lib/polyfills";
import PlantOverview from "./components/ov/pov/PlantOverview";
import Login from "./components/login/Login";
import FaultTreeEditor from "./components/ft/editor/FaultTreeEditor";
import FaultTreeManager from "./components/ft/manager/FaultTreeManager";
import OperatingModesManager from "./components/om/manager/om-manager";
import OperatingLimitsManager from "./components/of/manager/OperatingLimitsManager";
import SpecialtyReportManager from "./components/sr/manager/SpecialtyReportManager";
import Notifications from "./components/notifications/manager/NotificationsManager";
import ClustersManager from "./components/clusters/manager/ClustersManager";
import AriaManager from "./components/aria/manager/AriaManager";
import BackProcessManager from "./components/bp/manager/back-process-manager";
import GroupsManager from "./components/groups/manager/groups-manager";
import { TeamsManagerDeprecated } from "./components/users/manager/teams-manager-deprecated";
import ReportsLibrary from "./components/rl/manager/ReportsLibrary";
import UnitOverview from "./components/ov/unit-ov/unit-overview";
import Comments from "./components/comments/Comments";
import FaultTreeDetails from "./components/ft/details/FaultTreeDetails";
import DynamicRiskIndex from "./components/dri/DynamicRiskIndex";
import HelpHome from "./components/help/HelpHome";
import HelpPageTemplate from "./components/help/components/HelpPageTemplate";
import useAuthStore from "./zustand/auth/useAuthStore";
import NotFound from "./components/routing/NotFound";
import OperatingLimitsOverview from "./components/of/overview/OperatingLimitsOverview";
import AddonRoute from "./components/common/AddonRoute";
import Builder from "./components/help/builder/Builder";
import HelpPages from "./components/help/pages";
import ResetPass from "./components/login/ResetPass";
import { ProfileBookWithProviders } from "./components/pb/profile-book";
import NavigateToLogin from "./components/login/NavigateToLogin";
import { routerPaths } from "./constants/routerPaths";
import Aria from "./components/aria/index";
import { DriStoreProvider } from "./components/dri/hooks/create-use-dri-store";
import {
  NoAccountYetPage,
  UnknownErrorPage,
} from "./components/login/saml-error-pages";
import { FaultTreeOverview2 } from "./components/ft/overview/FaultTreeOverview2";
import { AckManager } from "./components/ack/manager/ack-manager";
import { OperatingLimitsDetails } from "./components/of/details/operating-limits-details-page";
import { ShutdownRulesManager } from "./components/shutdowns/manager/shutdown-rules-manager";
import { useConfigRequired } from "./zustand/config/useConfigStore";
import "./shared-ui/frontend/dra-globals.css";
import { UsersManager2 } from "./components/users/manager/users-manager";
import { TagsManager } from "./components/tags/manager/tags-manager";
import { StaleTagsManager } from "./components/staleTags/stale-tags-manager";
import { ManagerLayoutOutlet } from "./components/layouts/ManagerLayout";
import InstantCalculatorPage from "./components/instant-calculator/index";
import { FullscreenProvider } from "./shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { TimeseriesChartTooltipStoreProvider } from "./shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { UseViewModeStoreProvider } from "./shared-ui/time-series-2/grid-view-store";
import { TagsFilterStoreProvider } from "./components/tags/use-tags-filter-store";

function RootRedirect() {
  return useAuthStore((s) => s.authed) ? (
    <PlantOverview />
  ) : (
    <NavigateToLogin />
  );
}

function Root() {
  const config = useConfigRequired();

  return (
    <Router>
      <Routes>
        {/* will redirect to pov if logged in  */}
        <Route index element={<RootRedirect />} />
        <Route path={routerPaths.builder} element={<Builder />} />
        {config.entra && (
          <Route
            path={routerPaths.backdoorLogin}
            element={<Login adminBypass />}
          />
        )}
        <Route path={routerPaths.login} element={<Login />} />
        <Route path={routerPaths.setpass} element={<ResetPass />} />
        {config.entra && (
          <Route path={"entra"}>
            {/* unknown errors on backend redirect here */}
            <Route
              path={routerPaths.entra.error}
              element={<UnknownErrorPage />}
            />
            {/* the admin has not set up the account */}
            <Route
              path={routerPaths.entra.noAccount}
              element={<NoAccountYetPage />}
            />
          </Route>
        )}

        <Route path={routerPaths.help} element={<LoggedInRoute />}>
          <Route index element={<HelpHome />} />
          {HelpPages.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              element={<HelpPageTemplate {...page} />}
            />
          ))}
        </Route>
        <Route path=":unit" element={<LoggedInRoute />}>
          <Route index element={<Navigate replace to={"ov"} />} />
          <Route
            path={`ov`}
            element={
              <UseViewModeStoreProvider>
                <UnitOverview />
              </UseViewModeStoreProvider>
            }
          />
          <Route path={`pb`} element={<ProfileBookWithProviders />} />
          <Route
            path={`dri`}
            element={
              <DriStoreProvider>
                <FullscreenProvider>
                  <UseViewModeStoreProvider>
                    <TagsFilterStoreProvider>
                      <DynamicRiskIndex />
                    </TagsFilterStoreProvider>
                  </UseViewModeStoreProvider>
                </FullscreenProvider>
              </DriStoreProvider>
            }
          />
          <Route path="aria" element={<Aria />} />
          <Route path={`comments`} element={<Comments />} />
          <Route
            path="instant-calculator"
            element={<InstantCalculatorPage />}
          />

          <Route path={routerPaths.control} element={<ManagerLayoutOutlet />}>
            <Route path={routerPaths.tags} element={<TagsManager />} />
            <Route path={routerPaths.groups} element={<GroupsManager />} />
            <Route
              path={routerPaths.shutdownrules}
              element={<ShutdownRulesManager />}
            />
            <Route
              path={routerPaths.operatingmodes}
              element={<OperatingModesManager />}
            />
            <Route path={routerPaths.clusters} element={<ClustersManager />} />
            <Route path={routerPaths.aria} element={<AriaManager />} />
            <Route
              path={routerPaths.acknowledgements}
              element={<AckManager />}
            />
            <Route
              path={routerPaths.reportslibrary}
              element={<ReportsLibrary />}
            />
            <Route
              path={routerPaths.faulttrees}
              element={<FaultTreeManager />}
            />
            <Route
              path={routerPaths.operatingfitnessManager}
              element={<OperatingLimitsManager />}
            />
            <Route
              path={routerPaths.specialtyreports}
              element={<SpecialtyReportManager />}
            />
            <Route
              path={routerPaths.teams}
              element={<TeamsManagerDeprecated />}
            />
            <Route path={routerPaths.users} element={<UsersManager2 />} />
            <Route
              path={routerPaths.backprocessing}
              element={<BackProcessManager />}
            />
            <Route
              path={routerPaths.staleTags}
              element={<StaleTagsManager />}
            />
          </Route>

          <Route
            path={routerPaths.faulttrees}
            element={<AddonRoute addonKey="faultTrees" asOutlet={true} />}
          >
            <Route index element={<FaultTreeOverview2 />} />
            <Route path="details/:treeId" element={<FaultTreeDetails />} />
            <Route path="edit" element={<FaultTreeEditor />} />
            <Route path="edit/:treeId" element={<FaultTreeEditor />} />
            <Route
              path=":startdate/:enddate"
              element={<FaultTreeOverview2 />}
            />
          </Route>

          {/* this route is the index page for advanced settings when on mobile  */}

          <Route path={routerPaths.notifications} element={<Notifications />} />
          <Route
            path={routerPaths.operatingfitness}
            element={<AddonRoute addonKey="operatingLimits" asOutlet />}
          >
            <Route index element={<OperatingLimitsOverview />} />
            <Route
              path={`details/:groupId`}
              element={<OperatingLimitsDetails />}
            />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default Root;
