import { useState, useEffect } from "react";

function CommentFilterSelect({ filterOptions, initialLoading, onChange }) {
  const [filterValue, setFilterValue] = useState(filterOptions[0].value);

  useEffect(() => {
    onChange?.(filterOptions.find((o) => o.value === filterValue).onFilter);
  }, [filterValue]);

  return (
    <div className="form-control">
      <label className="input-group input-group-sm">
        <span>Filter</span>
        {initialLoading ? null : (
          <select
            className="select select-sm select-bordered text-center"
            onChange={({ target: { value } }) => setFilterValue(value)}
            value={filterValue}
            disabled={initialLoading}
          >
            {filterOptions.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
        )}
      </label>
    </div>
  );
}

export default CommentFilterSelect;
