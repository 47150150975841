import { useMemo } from "react";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import { useConfigRequired } from "../../../zustand/config/useConfigStore";

export default function useAllowedUnits() {
  const user = useAuthStore((s) => s.user);
  const Config = useConfigRequired();
  const allowedUnits = useMemo(() => {
    if (!user) {
      return [];
    } else {
      const userAssociatedUnitNames = user.units.map((u) => u.toLowerCase());
      return Config.units.filter((u) =>
        userAssociatedUnitNames.includes(u.name.toLowerCase())
      );
    }
  }, [user, Config]);

  return allowedUnits;
}
