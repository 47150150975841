import { z } from "zod";
import { MongoId } from "../common";

export const ftStatusSchema = MongoId.extend({
  date: z.string().datetime(),
  error: z.string().nullable().optional(),
  errorMessage: z.string().nullable().optional(),
  inVersion: z.number().int(),
  outVersion: z.number().int(),
  resolutionDate: z.string().datetime().nullish(),
  result: z.boolean(),
  status: z.number().int(),
  variableId: z.string(),
}).nullable();

export type ftStatusSchema = z.infer<typeof ftStatusSchema>;
