import { create } from "zustand";
import { addHours } from "date-fns";
import { GET } from "../../frameworks/fetcher/fetcher";
import type ConfigFromAPI from "./types/ConfigFromAPI";
import { browserSettings } from "./constants";
import type FrontendConfig from "./types/FrontendConfig";
import { historianSchema } from "./types/historian";
import { ZodError } from "zod";

// idk why we need this, got it from Sails

const CONFIG_EXPIRE_HOURS = 3;

type ConfigState = {
  initialized: boolean;
  config?: FrontendConfig;
};

function handleResponse(payload: ConfigFromAPI) {
  for (const u of payload.units) {
    try {
      historianSchema.parse(u.historian);
    } catch (error) {
      error instanceof ZodError &&
        console.error(`Historian of ${u.name} is invalid. Check config.yaml`);
      throw error;
    }

    try {
      historianSchema.array().parse(u.alternateHistorians);
    } catch (error) {
      error instanceof ZodError &&
        console.error(
          `At least one of alternateHistorians of ${u.name} are invalid. Check config.yaml`
        );
      throw error;
    }
  }

  const units = payload.units.map((u) => {
    return {
      ...u,
      isRealTime: u.addons?.realTime ?? false,
    };
  });

  const out: FrontendConfig = {
    ...payload,
    hasDailyUnits: units.some((u) => !u.isRealTime),
    hasRealTimeUnits: units.some((u) => u.isRealTime),
    units,
    browserSettings,
    exp: addHours(new Date(), CONFIG_EXPIRE_HOURS).getTime(),
  };

  const customerMeta = payload.customerMeta;
  if (customerMeta) {
    const { plantName, companyName } = customerMeta;
    out.facilityName = [companyName || "", plantName || ""].join(" ");
  }

  return out;
}

const useConfigStore = create<ConfigState>()((set) => {
  const init = async () => {
    const { data } = await GET("/config");
    const config = handleResponse(data);
    set({ config, initialized: true }); // state is merged not overwritten
  };

  void init();

  return {
    initialized: false,
    config: undefined,
  };
});

// use the Zustand store directly
export default useConfigStore;

/**
 * Other hooks so that it is less annoying to use slices of the above store
 */
export function useConfigRequired() {
  const config = useConfigStore((s) => s.config);
  if (!config) throw new Error("config not initialized");
  return config;
}

export function useIsConfigInitialized() {
  return useConfigStore((s) => s.initialized);
}

export function useTimezone() {
  return useConfigRequired().timezone;
}
