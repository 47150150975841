import { useSearchParams } from "react-router-dom";
import { useGroupsQuery } from "../../hooks/tanstack-query";
import DateSelectorsGroupSelect from "../dateSelector/DateSelectorsGroupSelect";
import { useSelectedGroup } from "./use-selected-group";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";

export function GroupSelect() {
  const selectedGroup = useSelectedGroup();
  const groupsQuery = useGroupsQuery();
  const [, setSearchParams] = useSearchParams();

  const groups = groupsQuery.data;

  if (!groups) return;

  return (
    <DateSelectorsGroupSelect
      action={(gid) => {
        const g = groups.find((x) => x._id === gid);
        if (!g) return;
        setSearchParams((curr) => {
          const copy = new URLSearchParams(curr);
          copy.delete(DRAParamsMap.v);
          copy.delete(DRAParamsMap.dv);
          copy.set(DRAParamsMap.g, g.short_id.toString());

          return copy;
        });
      }}
      options={groups.map((g) => ({
        value: g._id,
        label: g.name,
      }))}
      value={selectedGroup?._id}
    />
  );
}
