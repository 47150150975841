import { GenericCommentForm, OnCommentCreate } from "./generic-comment-form";
import { useMutation } from "@tanstack/react-query";
import {
  CommentEndpoints,
  type EditCommentPayload,
} from "../../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../../toast/use-toast-store";
import { Comments } from "../../../../hooks/tanstack-query";
import Comment from "../../../../types/api/Comment";
import { assertMinLen1 } from "../../../../shared-ui/lib/utils";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
import { useTimezone } from "../../../../zustand/config/useConfigStore";

function EditCommentForm({
  comment,
  close,
  noPortalMultiSelects,
}: {
  comment: Comment;
  close?: () => void;
  noPortalMultiSelects?: true;
}) {
  const zone = useTimezone();
  const brushState = useAtomValue(Atoms.brushStoreAtom);

  const b = useBaseUrlExperimental();

  const invalidateCommentsList = Comments.list.useInvalidate();
  const invalidateSingleCommentQueries = Comments.single.useInvalidate();

  const m = useMutation({
    mutationFn: async (p: EditCommentPayload) => {
      await CommentEndpoints.edit(b, comment._id, p);
    },
    onError: (e) => {
      addUnknownErrorToast(e);

      // just in case it was successful but req failed
      invalidateCommentsList();
      invalidateSingleCommentQueries();
    },
    onSuccess: () => {
      invalidateCommentsList();
      invalidateSingleCommentQueries();
      close?.();
    },
  });

  const onSubmit: OnCommentCreate = (d) => {
    const payload: EditCommentPayload = {
      labels: d.taggedLabels,
      link: window.location.href,
      tagged_teams: d.taggedTeams,
      tagged_users: d.taggedUsers,
      text: d.comment,
      variables: d.taggedVariables,
    };

    const canUpdateRange = comment.is_root;

    if (canUpdateRange && brushState && brushState.for === "comment-edit") {
      const unorderedRange = brushState.brushStore.getState().unorderedRange;

      const rightBrush = unorderedRange[1];
      const start =
        rightBrush === undefined
          ? unorderedRange[0]
          : Math.min(unorderedRange[0], rightBrush);

      const end =
        rightBrush === undefined
          ? Date.now()
          : Math.max(unorderedRange[0], rightBrush);

      const fakeify = (t: number) => {
        // If t points to 5PM zone time, this function returns 5PM UTC time
        // So it does add/subtract milliseconds to make this happen.
        return DateTime.fromMillis(t, { zone }) // parse the time in the user's timezone
          .setZone("utc", { keepLocalTime: true }) // change to utc, but keep the same local time
          .toMillis();
      };

      /**
       * We are still using fake dates in comments, and I don't want
       * to just start using real dates without migrating the db first..
       *
       * So just make it fake for now
       * */
      const startFake = fakeify(start);
      const endFake = fakeify(end);

      payload.end_date = endFake;
      payload.start_date = startFake;
    }

    m.mutate(payload);
  };

  return (
    <GenericCommentForm
      showRangeLabel={comment.is_root}
      noPortalMultiSelects={noPortalMultiSelects}
      className="border-t border-xslate-6 mt-2"
      isLoading={false}
      onSubmit={onSubmit}
      close={close}
      defaults={{
        taggedLabels: comment.labels.map((x) => x._id),
        taggedTeams: comment.tagged_teams.map((x) => x._id),
        taggedUsers: comment.tagged_users.map((x) => x._id),
        taggedVariables: assertMinLen1(comment.variables.map((x) => x._id)),
        comment: comment.text,
        issueResolution: true,
      }}
    />
  );
}

export { EditCommentForm };
