import { z } from "zod";

export const createdAt = z.object({
  created_at: z.string().datetime(),
});
const createdAtCamel = z.object({
  createdAt: z.string().datetime(),
});

export const updatedAt = z.object({
  updated_at: z.string().datetime(),
});
const updatedAtCamel = z.object({
  updatedAt: z.string().datetime(),
});

export const deleted = z.object({
  deleted: z.boolean(),
});

export const MongoId = z.object({
  _id: z.string(),
});

export const units = z.object({
  units: z.string().array(),
});

export const unit = z.object({
  unit: z.string(),
});

export const shortId = z.object({
  shortId: z.number().int(),
});

export const MongoIdUpdatedAtCreatedAt =
  MongoId.merge(updatedAt).merge(createdAt);

export const MongoIdUpdatedAtCreatedAtCamel =
  MongoId.merge(updatedAtCamel).merge(createdAtCamel);

export const MongoIdUpdatedAtCreatedAtDeleted = MongoId.merge(updatedAt)
  .merge(createdAt)
  .merge(deleted);

export const MongoIdUpdatedAtCreatedAtDeletedCamel = MongoId.merge(
  updatedAtCamel
)
  .merge(createdAtCamel)
  .merge(deleted);
