import { produce } from "immer";
import _ from "lodash";
import React, { useState } from "react";
import Button from "../../common/Button/Button";
import Select from "../../common/Select/Select";
import { type HelpPage } from "../pages/types";
import pages from "../pages";

/**
 * A component to load the JSON of a previous help page so you can edit it
 */
function JSONLoader({ load }: { load: (p: HelpPage) => void }) {
  const [selectedPage, setSelectedPage] = useState<HelpPage>();

  /**
   * Load the help page into state
   */
  function loadData(p: HelpPage) {
    const copied = produce(p, (d) => {
      d.subsections.forEach((s) => {
        s.id = _.uniqueId("subsection-"); // replace the IDs to be safe
        s.content.forEach((c) => {
          c.id = _.uniqueId("content-");

          if (c.type === "list") {
            c.content.forEach((l) => {
              l.id = _.uniqueId("content-list-");
              l.marginLeft = l.marginLeft ?? "0";
            });
          }
        });
      });
    });

    load(copied);
  }

  return (
    <>
      Load an old page to edit, or continue making a new one below
      <div className="border border-zinc-300 p-4 rounded-md flex gap-3">
        <Select
          className="grow"
          options={pages.map((p) => ({
            value: p.sectiontitle,
            label: p.sectiontitle,
          }))}
          onChange={(e) => {
            setSelectedPage(
              pages.find((p) => e.target.value === p.sectiontitle)!
            );
          }}
        />
        <Button
          onClick={() => {
            const selected = selectedPage || pages[0];
            if (!selected) throw new Error();
            loadData(selected);
          }}
        >
          Load Page To edit
        </Button>
      </div>
    </>
  );
}

export default JSONLoader;
