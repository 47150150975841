import { equals } from "remeda";
import { z } from "zod";

export const defaultStartTime = "00:00";
export const defaultEndTime = "23:59";

export const RANGES = [
  {
    days: 7,
    label: "7d",
  },
  {
    days: 14,
    label: "14d",
  },
  {
    days: 30,
    label: "30d",
  },
  {
    days: 60,
    label: "60d",
  },
  {
    days: 120,
    label: "120d",
  },
  {
    days: 180,
    label: "180d",
  },
  {
    days: 365,
    label: "1y",
  },
] as const satisfies readonly { days: number; label: string }[];

(() => {
  if (
    !equals(
      RANGES,
      [...RANGES].sort((a, b) => a.days - b.days)
    )
  ) {
    throw new Error("Your ranges must be in ascending order");
  }
})();

type RANGE_DAYS = (typeof RANGES)[number]["days"];

/**
 * Start every page on 30 days
 */
const defaultAxisRangeDays: RANGE_DAYS = 30;

export type PREDEFINED_RANGE = (typeof RANGES)[number];
export type CUSTOM_RANGE = {
  days: number;
  label: string;
};

type HasSameKeys<T, U> = keyof T extends keyof U
  ? keyof U extends keyof T
    ? true
    : false
  : false;

const _check: HasSameKeys<PREDEFINED_RANGE, CUSTOM_RANGE> = true; // ensure the types are the same, this will fail if they are not

export const axisRangeIndexSchema = z.number().int().min(0).max(RANGES.length);

export const defaultRangeIndex = RANGES.findIndex(
  (range) => range.days === defaultAxisRangeDays
);

export const defaultRangeDaysForSlopingTrends: RANGE_DAYS = 180;

export const defaultSlopingTrendsRangeIndex = RANGES.findIndex(
  (range) => range.days === defaultRangeDaysForSlopingTrends
);

export const defaultAxisRange = (() => {
  const out = RANGES[defaultRangeIndex];
  if (!out) throw new Error("defaultAxisRange not found");
  return out;
})();

if (defaultRangeIndex === -1) {
  throw new Error("defaultRangeIndex not found");
}

if (defaultSlopingTrendsRangeIndex === -1) {
  throw new Error("defaultSlopingTrendsRangeIndex not found");
}
