import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { getOperatingModes } from "../../../frameworks/fetcher/api-routes-experimental";
import { OperatingMode } from "../../../lib/api-schema/om";
import { type BaseQueryOptions } from "../../../hooks/tanstack-query";

const getQueryKey = (b: ReturnType<typeof useBaseUrlExperimental>) =>
  [b, "operating-modes"] as const;

export const useOperatingModesQuery = (opts?: BaseQueryOptions) => {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryFn: () => getOperatingModes(b),
    queryKey: getQueryKey(b),
    ...opts,
  });
};

export const useUpdateOperatingModesQuery = () => {
  const cli = useQueryClient();
  const b = useBaseUrlExperimental();

  return (updater: (curr: OperatingMode[]) => OperatingMode[]) => {
    cli.setQueryData<Awaited<ReturnType<typeof getOperatingModes>>>(
      getQueryKey(b),
      (curr) => {
        if (!curr) return undefined;

        return updater(curr);
      }
    );
  };
};
