import { DateTime } from "luxon";
import { useDateState } from "../../zustand/useDateState";
import { useSearchParams } from "react-router-dom";
import {
  Acknowledgements,
  Comments,
  useAnomalousVariablesQuery,
  useClustersQuery,
  useFreshAnomaliesQuery,
  useGroupsQuery,
} from "../../hooks/tanstack-query";
import { iife } from "../../lib/utils";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import { OVERALL_GROUP_NAME } from "../../lib/api-schema/misc";
import { useMemo } from "react";
import { AnomalyLevelEnum } from "../../types/api/Anomaly";
import { AnomalyTimeFilters, TagFilters } from "./use-tags-filter-store";
import { parseDuration } from "./utils";

export function useTagsFilter(
  tagFilters: TagFilters,
  anomalyTimeFilter: AnomalyTimeFilters
) {
  const ds = useDateState();
  const fakeZone = "UTC"; // anomalies, comments, and acks use fake dates
  const startMillis = DateTime.fromISO(ds.axisRangeTo.dateString, {
    zone: fakeZone,
  }).toMillis();
  const endMillis = DateTime.fromISO(ds.axisRangeTo.dateString, {
    zone: fakeZone,
  })
    .endOf("day")
    .toMillis();
  // fresh anomalies
  const [sp] = useSearchParams();
  const groups = useGroupsQuery().data;
  const groupShortId = iife(() => {
    const g = sp.get(DRAParamsMap.g);
    if (!g?.trim()) return undefined;
    const short = parseInt(g);
    if (isNaN(short)) return undefined;
    if (short.toString() !== g.trim()) throw new Error("impossible");
    return short;
  });
  const group =
    groups?.find((x) => x.short_id === groupShortId) ??
    groups?.find((g) => g.name === OVERALL_GROUP_NAME) ??
    groups?.[0];
  const groupVars = group?.variables;
  const anomsQuery = useAnomalousVariablesQuery(
    {
      varIds: groupVars,
    },
    ds.axisRangeTo.dateString,
    {
      enabled: !!groupVars,
    }
  );
  const anomalyFolders = useMemo(() => {
    if (!anomsQuery.data) return undefined;
    return {
      third: anomsQuery.data.filter(
        (x) => x.level === AnomalyLevelEnum["High Risk"]
      ),
      second: anomsQuery.data.filter(
        (x) => x.level === AnomalyLevelEnum["Medium Risk"]
      ),
      first: anomsQuery.data.filter(
        (x) => x.level === AnomalyLevelEnum["Low Risk"]
      ),
    };
  }, [anomsQuery.data]);
  const freshAnomsQuery = useFreshAnomaliesQuery(
    {
      date: ds.axisRangeTo.dateString,
      deg1array: anomalyFolders?.first.map((x) => x.variableId),
      deg2array: anomalyFolders?.second.map((x) => x.variableId),
      deg3array: anomalyFolders?.third.map((x) => x.variableId),
    },
    {
      enabled: !!anomalyFolders,
      keepPreviousData: true,
    }
  );
  const freshAnoms = [
    ...(freshAnomsQuery.data?.freshFirst ?? []),
    ...(freshAnomsQuery.data?.freshSecond ?? []),
    ...(freshAnomsQuery.data?.freshThird ?? []),
  ];
  const commentsQuery = Comments.commentedVariables.useQuery(
    startMillis,
    endMillis
  );
  const variableIdsWithComments = commentsQuery.data || [];
  const acksQuery = Acknowledgements.acknowledgedVariables.useQuery(
    startMillis,
    endMillis
  );
  const acks = acksQuery.data || [];
  const clustersQuery = useClustersQuery();
  const varsInCluster =
    clustersQuery.data?.reduce((acc, cluster) => {
      return [...acc, ...cluster.variables];
    }, [] as string[]) || [];
  const anomTimes =
    anomsQuery.data?.map((a) => {
      return {
        t: a.anomalyMilliseconds,
        v: a.variableId,
      };
    }) || [];
  const ltParsed = anomalyTimeFilter.lt
    ? parseDuration(anomalyTimeFilter.lt).toMillis()
    : 1000 * 60 * 60 * 25; // 25 hours just to make sure we include everything
  const gtParsed = anomalyTimeFilter.gt
    ? parseDuration(anomalyTimeFilter.gt).toMillis()
    : 0;

  const check1 = (vid: string) => {
    if (tagFilters.Comments === undefined) return true;
    if (commentsQuery.isFetching) return true;
    const hasComment = variableIdsWithComments.includes(vid);
    return tagFilters.Comments ? hasComment : !hasComment;
  };

  const check2 = (vid: string) => {
    if (tagFilters.Acknowledgments === undefined) return true;
    if (acksQuery.isFetching) return true;
    const hasAcks = acks.includes(vid);
    return tagFilters.Acknowledgments ? hasAcks : !hasAcks;
  };

  const check3 = (vid: string) => {
    if (tagFilters.Anomalies === undefined) return true;
    if (freshAnomsQuery.isFetching) return true;
    const hasFreshAnoms = freshAnoms.includes(vid);
    return tagFilters.Anomalies ? hasFreshAnoms : !hasFreshAnoms;
  };

  const check4 = (vid: string) => {
    if (tagFilters.Clusters === undefined) return true;
    if (clustersQuery.isFetching) return true;
    const hasCluster = varsInCluster.includes(vid);
    return tagFilters.Clusters ? hasCluster : !hasCluster;
  };

  const check5 = (vid: string) => {
    if (anomsQuery.isFetching) return true;
    const t = anomTimes.find((c) => c.v === vid);
    const time = t === undefined ? 0 : t.t;
    return time >= gtParsed && time <= ltParsed;
  };

  const canShowVariable = (vid: string) => {
    return (
      check1(vid) && check2(vid) && check3(vid) && check4(vid) && check5(vid)
    );
  };

  return canShowVariable;
}
