import { z } from "zod";
import {
  MongoIdUpdatedAtCreatedAtDeletedCamel,
  shortId,
  unit,
} from "../common";

export const faultTreeNodeSchema = MongoIdUpdatedAtCreatedAtDeletedCamel.merge(
  shortId
)
  .merge(unit)
  .extend({
    name: z.string(),
    expression: z.string(),
    expressionVariableIds: z.array(
      z.object({
        _id: z.string(),
        type: z.number().int(),
      })
    ),
    recommendation: z.string(),
    parentId: z.string(),
    faultTreeId: z.string(),
    folderIds: z.array(z.string()),
    watchlist: z.boolean(),
    tagExpression: z.string(),
    nodeExpression: z.string(),
    expressionConnector: z.string(),
    shouldBeProcessed: z.boolean(),
  });

export type faultTreeNodeSchema = z.infer<typeof faultTreeNodeSchema>;
