import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import { Info } from "lucide-react";
import { PropsWithCn } from "../types/component.types";
import { useVariablesArrayQuery } from "../../hooks/tanstack-query";
import { ClusterScoreBadge } from "../aria/cluster-score-badge";

function ClusterInfoTooltip({
  className,
  ...props
}: PropsWithCn<{
  cluster: {
    score?: number | null;
    variables: string[];
  };
  withoutPortal?: true;
}>) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          onClick={(e) => e.stopPropagation()}
          variant={"ghost"}
          size={"icon-sm"}
          className={cn("hover:text-xindigo-11", className)}
        >
          <Info className="size-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        withoutPortal={props.withoutPortal}
        className="flex flex-col gap-3 min-w-[300px] max-w-[50vw] text-sm rounded-lg border-xslate-7 cursor-default"
        onClick={(e) => e.stopPropagation()}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <ClusterInfoPopoverContent {...props} />
      </PopoverContent>
    </Popover>
  );
}

function ClusterInfoPopoverContent({
  cluster,
}: {
  cluster: {
    score?: number | null;
    variables: string[];
  };
}) {
  // need to inherit cluster score
  // cluster drawer has cluster info and score already
  const tags = useVariablesArrayQuery().data || [];
  const tagsToShow = cluster.variables
    .map((v) => {
      const foundTag = tags.find((t) => t._id === v);
      if (foundTag) return { name: foundTag.trimmedName };
      return undefined;
    })
    .filter((x) => x !== undefined);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <span className="text-xslate-11 leading-none mb-0.5 uppercase font-normal">
          Members
        </span>
        {tagsToShow.map((t, i) => (
          <span key={i} className="ml-2 font-normal">
            {t.name}
          </span>
        ))}
      </div>
    </div>
  );
}

export default ClusterInfoTooltip;
