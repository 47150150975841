import _ from "lodash";
import React, { useState, useCallback, useMemo } from "react";
import { subDays, addDays, isSameDay, isBefore, isAfter } from "date-fns";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { useProcStartDate } from "../../../lib/useProcStartDate";

function useDateRange(rangeLength, start, format) {
  const currUnit = useCurrentUnitObject();
  const procStartDate = useProcStartDate(currUnit);

  const rangeEnd = start ? start : new Date();

  const [currRange, setCurrRange] = useState(
    _.reverse(
      Array.from(Array(rangeLength).keys()).map((num) => subDays(rangeEnd, num))
    )
  );

  const next = useCallback(() => {
    setCurrRange((curr) =>
      curr
        .map((d) => addDays(d, rangeLength))
        .filter((d) => isSameDay(rangeEnd, d) || isBefore(d, rangeEnd))
    );
  }, [rangeEnd]);

  const prev = useCallback(() => {
    setCurrRange((curr) =>
      curr
        .map((d) => subDays(d, rangeLength))
        .filter((d) => isSameDay(procStartDate, d) || isAfter(d, procStartDate))
    );
  }, [procStartDate]);

  const formatted = useMemo(
    () => currRange.map(format ? format : (v) => v),
    [currRange]
  );

  return {
    range: formatted,
    next,
    prev,
    hasNext: !currRange.some((d) => isSameDay(d, rangeEnd)),
    hasPrev: currRange.every((d) => !isSameDay(d, procStartDate)),
    setRangeEnd: (date) => {
      // manually jump to a date
      setCurrRange(
        _.reverse(
          Array.from(Array(rangeLength).keys()).map((num) => subDays(date, num))
        )
      );
    },
  };
}

export default useDateRange;
