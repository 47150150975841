import { useState, useEffect } from "react";
import _ from "lodash";
import useAPI from "../../../lib/useAPI";
import { produce } from "immer";

function useInitializeCommentsPage() {
  const api = useAPI();

  const [initialLoading, setInitialLoading] = useState(true); // display loading screen
  const [pageError, setPageError] = useState(false); // page level error that will tell them to reload

  // extra data passed as props to all commentcards, for editing purposes
  const [labels, setLabels] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      await Promise.all([
        api.getLabels(),
        api.getTags(),
        api.getUsers(),
        api.getTeams(),
      ]).then(([labels_, tags_, users_, teams_]) => {
        const labelsOut = _.map(labels_, (label) => ({
          style: _.kebabCase(label.name),
          label: `${label.name}`,
          value: `${label._id}`,
          color: label.color,
          onFilter: produce((state) => {
            delete state.activeIssue;
            delete state.inactiveIssue;
            state["labelIds[]"] = label._id;
          }),
        }));
        const tagsOut = _.map(tags_, (variable) => ({
          label: `${variable.name} | ${variable.description}`,
          value: variable._id,
        }));
        const usersOut = _.map(users_, (user) => ({
          label: `${user.first} ${user.last}`,
          value: user._id,
        }));
        const teamsOut = _.map(teams_, (team) => ({
          label: `${team.name} (${team.users.length} member${
            team.users.length === 1 ? "" : "s"
          })`,
          value: team._id,
        }));

        // i hate naming things the same in a given file even if they're protected by scoping
        setLabels(labelsOut);
        setVariables(tagsOut);
        setUsers(usersOut);
        setTeams(teamsOut);
      });
    };

    setInitialLoading(true);
    setPageError(false);
    setLabels([]);
    setUsers([]);
    setTeams([]);
    setVariables([]);

    initialize()
      .then(() => setInitialLoading(false))
      .catch((e) => setPageError(e));
  }, [api]);

  return { initialLoading, pageError, labels, users, teams, variables };
}

export default useInitializeCommentsPage;
