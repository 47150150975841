import { ScrollArea } from "../../frontend/scroll-area";
import {
  type PropsWithCn,
  type PropsWithCnAndChildren,
  cn,
} from "../../frontend/cn";
import { Atoms, type SelectedVariable } from "../svv-store/use-svv-store";
import { Button } from "../../frontend/button";
import { type ComponentProps } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { LuLampDesk } from "react-icons/lu";
import { iife } from "../../lib/utils";

type ControlButtonSize = Pick<ComponentProps<typeof Button>, "size">;

function Container({ children, className }: PropsWithCnAndChildren) {
  const chartAreaHeight = useAtomValue(Atoms.chartAreaDimensionsAtom)?.h;

  if (chartAreaHeight === undefined) {
    return;
  }

  return (
    <ScrollArea
      className={cn(
        "w-56 shrink-0 self-stretch border-r border-xslate-7",
        className
      )}
      style={{ height: chartAreaHeight }}
    >
      {children}
    </ScrollArea>
  );
}

const selectAllAtom = atom(null, (get, set) => {
  const a = get(Atoms.excludedBatchVariablesSetAtomAtom);
  if (a) {
    set(a.expression, new Set());
    set(a.variable, new Set());
  }
});

export function IncludeAllButton({ className }: PropsWithCn) {
  const excludedAtoms = useAtomValue(Atoms.excludedBatchVariablesSetAtomAtom);

  const excludedExpressions = useAtomValue(excludedAtoms.expression);
  const excludedBatchVariables = useAtomValue(excludedAtoms.variable);
  const selectAll = useSetAtom(selectAllAtom);
  const somethingIsExcluded =
    excludedBatchVariables.size > 0 || excludedExpressions.size > 0;

  if (!somethingIsExcluded) return;

  return (
    <Button
      className={cn("animate-in slide-in-from-top-5", className)}
      variant={"outline"}
      size={"xxs"}
      onClick={selectAll}
    >
      Select All
    </Button>
  );
}

function ColoredCheckbox({
  batchVariable,
  className,
}: {
  batchVariable: SelectedVariable;
} & PropsWithCn) {
  const checkedSet = useAtomValue(Atoms.checkedBatchVariablesAtom);

  const checked = iife(() => {
    switch (batchVariable.type) {
      case "variable":
        return checkedSet.variable.has(batchVariable.bv);
      case "expression":
        return checkedSet.expression.has(batchVariable.id);
      default:
        const _exhaustiveCheck: never = batchVariable;
        throw new Error("unreachable");
    }
  });

  return (
    <div
      className={cn(
        "size-3 cursor-pointer appearance-none rounded-sm border-2 active:scale-95 transition-transform shrink-0",
        className
      )}
      style={{
        borderColor: batchVariable.color,
        backgroundColor: checked ? batchVariable.color : undefined,
      }}
    />
  );
}

function PinButton({
  batchVariable,
  className,
  size,
}: { batchVariable: SelectedVariable } & PropsWithCn<ControlButtonSize>) {
  const single = useAtomValue(Atoms.singleVariableViewAtom);
  const excludeAllButMe = useSetAtom(Atoms.excludeAllButOneAtom);

  if (single) return;

  return (
    <Button
      variant={"ghost"}
      size={size ?? "icon"}
      className={cn("size-5", className)}
      style={{ transform: "scale(-1, 1)" }}
      onClick={(e) => {
        e.stopPropagation();
        excludeAllButMe(
          iife(() => {
            switch (batchVariable.type) {
              case "variable":
                return batchVariable.bv;
              case "expression":
                return batchVariable.id;
              default:
                const _exhaustiveCheck: never = batchVariable;
                throw new Error("unreachable");
            }
          }),
          batchVariable.type
        );
      }}
    >
      <LuLampDesk className={cn("size-4 text-xslate-8")} />
    </Button>
  );
}

export { Container, ColoredCheckbox, PinButton };
