import { useMemo } from "react";
import formatErrors from "./formatErrors";
import moment from "moment";
import backgroundRequest from "./backgroundrequest";
import useAuthStore from "../zustand/auth/useAuthStore";
import { GET, POST, PATCH, DELETE } from "../frameworks/fetcher/fetcher";
import _ from "lodash";
import useBaseUrl from "../zustand/useBaseUrl";

function includeInObjectIf(obj, predicate) {
  const out = {};
  _.forEach(obj, (v, k) => {
    if (predicate(v, k)) {
      out[k] = v;
    }
  });
  return out;
}

function optionsToQueryString(options) {
  const queryParamsArr = _.reduce(
    options,
    (arr, value, key) => {
      if (value && key) {
        arr.push(`${key}=${Array.isArray(value) ? value.join(",") : value}`);
      }
      return arr;
    },
    []
  );

  return queryParamsArr.length ? `?${queryParamsArr.join("&")}` : "";
}

function completePath(base, options = {}) {
  return base + optionsToQueryString(options);
}

export default function useAPI() {
  const baseUrl = useBaseUrl();

  const userId = useAuthStore((s) => s.user?._id);

  const endpoints = useMemo(() => {
    const updateEndpoints = () => {
      const ATTACHED_TOKEN_FETCHERS = {
        GET: GET,
        POST: POST,
        PATCH: PATCH,
        DELETE: DELETE,
      };

      _.forEach(Object.keys(ATTACHED_TOKEN_FETCHERS), (methodName) => {
        const origFn = ATTACHED_TOKEN_FETCHERS[methodName];

        ATTACHED_TOKEN_FETCHERS[methodName] = (path, options) => {
          return origFn(path, { body: options ?? {} });
        };
      });

      let endpoints = {
        getFaultTrees: () =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/fault-trees`),
        getFaultTree: (treeId) =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/fault-trees/${treeId}`),
        patchFaultTree: (treeId, tree) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/fault-trees/${treeId}`,
            tree
          ),
        deleteFaultTree: (treeId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/fault-trees/${treeId}`),

        getFaultTreeStatus: (treeId, date) => {
          date = moment(date).format("YYYY-MM-DD");
          date = encodeURIComponent(date);
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-trees/${treeId}/status?date=${date}`
          );
        },
        getFaultTreeNodeResults: (treeId, start, end) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          start = encodeURIComponent(start);
          end = encodeURIComponent(end);
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-trees/${treeId}/node-results?start=${start}&end=${end}`
          );
        },

        copyFaultTree: (treeId, tree) => {
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/fault-trees/${treeId}/copy`,
            tree
          );
        },

        getFaultTreeChart: (treeId, date) => {
          /* Strip potential seconds/milliseconds off of date. */
          date = moment(date).format("YYYY-MM-DD");
          date = encodeURIComponent(date);
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-trees/treeChartData/${treeId}?date=${date}`
          );
        },

        getFaultTreeNode: (nodeId) =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/fault-tree-nodes/${nodeId}`),
        getFaultTreeNodes: (treeId) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-trees/${treeId}/fault-tree-nodes/`
          ),
        patchFaultTreeNode: (nodeId, node) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/fault-tree-nodes/${nodeId}`,
            node
          ),
        postFaultTreeNode: (node) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/fault-tree-nodes/`, node),
        deleteFaultTreeNode: (nodeId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(
            `${baseUrl}/fault-tree-nodes/${nodeId}`
          ),
        getFaultTreeNodeStatus: (nodeId, date) => {
          date = moment(date).format("YYYY-MM-DD");
          date = encodeURIComponent(date);
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-tree-nodes/${nodeId}/status?start=${date}&end=${date}`
          );
        },
        getFaultTreeNodeResolutionDate: (nodeId) => {
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/fault-tree-nodes/${nodeId}/resolution-date`
          );
        },

        getFaultTreeNodeStatusSeries: (nodeId, start, end) => {
          if (!nodeId || !start || !end)
            return Promise.reject("Incorrect parameters");
          start = encodeURIComponent(moment(start).format("YYYY-MM-DD"));
          end = encodeURIComponent(moment(end).format("YYYY-MM-DD"));
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables/${nodeId}/statusSeries?start=${start}&end=${end}`
          );
        },

        // TODO I'm not sure what to call this because we have another /slopes route
        getVariablesSlopes: (groupId, date) => {
          const searchParams = {};

          if (groupId) {
            searchParams.groupId = groupId;
          }
          if (date) {
            searchParams.date = moment(date).format("YYYY/MM/DD");
          }

          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables/slopes?${new URLSearchParams(
              searchParams || {}
            ).toString()}`
          );
        },

        getOperatingModes: () =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/operating-modes`),
        patchOperatingMode: (modeId, mode) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/operating-modes/${modeId}`,
            mode
          ),
        postOperatingMode: (mode) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/operating-modes/`, mode),
        deleteOperatingMode: (modeId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(
            `${baseUrl}/operating-modes/${modeId}`
          ),

        getShutdownRules: () =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/shutdown-rules`),
        deleteShutdownRule: (ruleId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/shutdown-rules/${ruleId}`),
        postShutdownRule: (rule) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/shutdown-rules`, rule),
        patchShutdownRule: (ruleId, rule) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/shutdown-rules/${ruleId}`,
            rule
          ),

        getClusters: (includesArray) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/families` +
              (includesArray ? `?include=${includesArray.join(",")}` : "")
          ),
        postCluster: (cluster) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/families`, cluster),
        deleteCluster: (clusterId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/families/${clusterId}`),
        patchCluster: (clusterId, cluster) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/families/${clusterId}`,
            cluster
          ),

        getTags: (includesArray) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/tags` +
              (includesArray ? `?include=${includesArray.join(",")}` : "")
          ),
        deleteTags: (tagId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/tags/${tagId}`),
        postTag: (tag) => ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/tags`, tag),
        patchTag: (tagId, tag) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/tags/${tagId}`, tag),

        getGroups: (hidden) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/groups?hidden=${hidden ? "true" : "false"}`
          ),
        getSingleGroup: (gid) =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/groups/${gid}`),

        patchGroups: (gid, variables) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/groups/${gid}`, variables),
        postGroups: (variables) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/groups`, variables),
        deleteGroup: (gid) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/groups/${gid}`),

        patchFolder: (folderId, name, variables) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/folders/${folderId}`, {
            name,
            variables,
          }),
        postFolder: (name, variables) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/folders`, {
            name,
            variables,
          }),
        deleteFolder: (fid) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/folders/${fid}`),

        patchSavedView: (savedViewId, savedView) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/folders/${savedViewId}`, {
            ...savedView,
            type: "savedView",
          }),
        postSavedView: (savedView) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/folders`, {
            ...savedView,
            type: "savedView",
          }),

        getFolders: () => ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/folders`),

        getVariable: (id) =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/variables/${id}`),
        getReferencingVariables: (id) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables/${id}/referencing-variables`
          ),

        getVariables: (query) => {
          let queryParams = _.map(query, (value, key) => {
            /* Handle list params like `excludeIds` */
            if (value instanceof Array) {
              value = value.join(",");
            }

            return `${key}=${value}`;
          });

          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables?${queryString}`
          );
        },

        getExceedanceVariables: (query) => {
          let queryParams = _.map(query, (value, key) => `${key}=${value}`);
          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables/exceeded?${queryString}`
          );
        },

        getProbabilityVariables: (query, probabilityQueries) => {
          const params = { ...query, ...probabilityQueries };
          let queryParams = _.map(params, (value, key) => `${key}=${value}`);
          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/variables/ol-probability?${queryString}`
          );
        },

        getUsers: () => ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/users`),
        postUser: (data) => ATTACHED_TOKEN_FETCHERS.POST(`/users`, data),
        deleteUser: (id, data) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/users/${id}/remove-unit?unit=${data}`
          ),
        patchUser: (id, data) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/users/${id}`, data),

        getReports: (options) =>
          ATTACHED_TOKEN_FETCHERS.GET(
            completePath(`${baseUrl}/reports`, options)
          ),
        getSpecialtyReports: () =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/specialty-reports`),
        patchSpecialtyReport: (id, data) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/specialty-reports/${id}`,
            data
          ),
        deleteSpecialtyReport: (id) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/specialty-reports/${id}`),
        postSpecialtyReport: (data) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/specialty-reports/`, data),
        regenerateSpecialtyReport: (id) =>
          ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/specialty-reports/${id}/regenerate`
          ),
        resendSpecialtyReport: (id, users) => {
          return users
            ? ATTACHED_TOKEN_FETCHERS.POST(
                `${baseUrl}/specialty-reports/${id}/resend?users=${users.join(
                  ","
                )}`
              )
            : ATTACHED_TOKEN_FETCHERS.POST(
                `${baseUrl}/specialty-reports/${id}/resend`
              );
        },

        getLimits: (id) => {
          const query = id ? `?variableId=${id}` : "";
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limits/${query}`
          );
        },
        deleteOperatingLimit: (limitId) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(
            `${baseUrl}/operating-limits/${limitId}`
          ),
        patchOperatingLimit: (limitId, data) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/operating-limits/${limitId}`,
            data
          ),
        postOperatingLimit: (data) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/operating-limits`, data),
        getOperatingLimitHistogram: (id, start, end, type) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limits/histogram/${id}?start=${start}&end=${end}&type=${type}`
          );
        },
        getOperatingLimitTotalExceedances: (id, start, end) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limits/insights/totalExceedances/${id}?start=${start}&end=${end}`
          );
        },
        getOperatingLimitStatusSeries: (id, start, end) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limits/status-series/${id}?start=${start}&end=${end}`
          );
        },
        getOperatingLimitInsights: (id, start, end, type) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limits/insights/${id}?start=${start}&end=${end}&type=${type}`
          );
        },
        getOperatingLimitProbabilities: (id, start, end) => {
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/operating-limit-stats/${id}?start=${start}&end=${end}`
          );
        },

        getExceedanceGroupCounts: (id, start, end, options) => {
          let queryParams = _.map(options, (value, key) => {
            if (value === undefined) {
              return null;
            }
            /* Handle list params like `excludeIds` */
            if (value instanceof Array) {
              value = value.join(",");
            }

            return `${key}=${value}`;
          });

          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/limit-exceedances/count?groupId=${id}&start=${start}&end=${end}&${queryString}`
          );
        },

        getExceedanceVariableCounts: (id, start, end, options) => {
          let queryParams = _.map(options, (value, key) => {
            /* Handle list params like `excludeIds` */
            if (value instanceof Array) {
              value = value.join(",");
            }

            return `${key}=${value}`;
          });

          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          start = moment(start).format("YYYY-MM-DD");
          end = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/limit-exceedances/count?variableId=${id}&start=${start}&end=${end}&${queryString}`
          );
        },

        /* TODO: static charts are going to eventually be merged together. */
        getStaticTrendChart: (variableId, start, end, height, width) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/charts/ft-trend?varId=${variableId}&start=${startFormatted}&end=${endFormatted}&height=${height}&width=${width}`,
            { accept: "text/html" }
          );
        },

        getGenericStaticTrendChart: (variableId, start, end, height, width) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/charts/ol-trend?varId=${variableId}&start=${startFormatted}&end=${endFormatted}&height=${height}&width=${width}`,
            { accept: "text/html" }
          );
        },

        downloadLimits: () => {
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/operating-limits/download/`
          );
        },

        downloadNotifications: () => {
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/notifications/download/`
          );
        },

        downloadAcknowledgements: (
          date,
          downloadType,
          groupId,
          levels,
          onlyFresh,
          filterVariableIds = []
        ) => {
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/acknowledgments/download/`,
            {
              date: moment(date).format("YYYY-MM-DD"),
              downloadType,
              groupId,
              levels,
              onlyFresh,
              filterVariableIds,
            }
          );
        },

        getNotifications: (
          notificationTypes,
          variableId,
          operatingLimitId,
          groupId
        ) => {
          let query = [];
          if (variableId) query.push(`variableId=${variableId}`);
          if (operatingLimitId)
            query.push(`operatingLimitId=${operatingLimitId}`);
          if (groupId) query = query.push(`groupId=${groupId}`);
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/notifications?` +
              (notificationTypes
                ? "types[]=" + notificationTypes.join("&types[]=") + "&"
                : "types[]=&") +
              query.join("&")
          );
        },

        getUserNotifications: async (notificationType) => {
          let query = "";
          if (notificationType) {
            query = `${query}&type=${notificationType}`;
          }
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/users/${userId}/notifications?${query}`
          );
        },

        subscribeNotification: (
          type,
          value,
          variableId,
          operatingLimitId,
          groupId
        ) => {
          return ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/notifications`, {
            type,
            value,
            variableId,
            operatingLimitId,
            groupId,
          });
        },

        toggleNotification: (notificationId) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/notifications/toggle/${notificationId}`
          ),

        deleteNotification: (notificationId) => {
          return ATTACHED_TOKEN_FETCHERS.DELETE(
            `${baseUrl}/notifications/${notificationId}`
          );
        },

        createNotification: async (notification) => {
          const queryParam = notification.customExpression
            ? `?customExpression=${encodeURI(notification.customExpression)}`
            : "";
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/notifications/${queryParam}`,
            notification
          );
        },

        getTrends: (variableId, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/trends?varId=${variableId}&start=${startFormatted}&end=${endFormatted}`
          );
        },

        getThinTrends: (variableId, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/trends?thin=true&varId=${variableId}&start=${startFormatted}&end=${endFormatted}`
          );
        },

        getSlopes: (variableId, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/slopes/forAnalysisPeriod?variableId=${variableId}&start=${startFormatted}&end=${endFormatted}`
          );
        },

        getComment: (id) =>
          ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/comments/${id}`),

        getComments: (query = {}) => {
          if (query.start) {
            query.start_date = moment(query.start).toISOString();
          }
          if (query.end) {
            query.end_date = moment(query.end).toISOString();
          }

          if (query.limit == null) {
            query.limit = 5;
          }
          query.page = query.page || 1;
          query.private = query.private ?? false;

          let queryParams = _.map(query, (value, key) => `${key}=${value}`);
          let queryString = queryParams.length
            ? `${queryParams.join("&")}`
            : "";
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/comments?${queryString}`
          );
        },

        // getCommentsSummary: (
        //   variableIds,
        //   start_date,
        //   end_date,
        //   isPrivate = false,
        //   limit,
        //   page,
        //   query = {}
        // ) => {
        getCommentsSummary: (payload, query = {}) => {
          const {
            variableIds,
            start_date,
            end_date,
            isPrivate,
            limit,
            page,
            ...rest
          } = payload;
          const data = {
            start_date,
            end_date,
            variableIds,

            private: !!isPrivate,
            limit: limit || 0,
            page: page || 1,

            ...rest,
          };

          const cleanedData = includeInObjectIf(data, (v) => v != null);

          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/comments/summary?${new URLSearchParams(
              query
            ).toString()}`,
            cleanedData
          );
        },

        patchComment: async (commentId, comment) => {
          return await ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/comments/${commentId}`,
            comment
          );
        },

        postComment: async (comment) => {
          return await ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/comments`,
            comment
          );
        },

        postCommentReply: async (commentId, reply) => {
          return await ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/comments/${commentId}/reply`,
            reply
          );
        },

        deleteComment: async (commentId) => {
          return await ATTACHED_TOKEN_FETCHERS.DELETE(
            `${baseUrl}/comments/${commentId}`
          );
        },

        createShutdownRule: async (data) => {
          return await ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/shutdown-rules`,
            data
          );
        },

        getRisks: async (groupId, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return await ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/risks?groupId=${groupId}&start=${startFormatted}&end=${endFormatted}`
          );
        },

        getAnomalySummaries: async (variables, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return await ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/anomalies/summaries`,
            {
              varIds: variables,
              start: startFormatted,
              end: endFormatted,
            }
          );
        },

        getAnomaly: async (variables, date, queries) => {
          const md = moment(date);
          const y = md.format("YYYY");
          const m = md.format("MM");
          const d = md.format("DD");

          const queryString = queries
            ? `?${queries.map(([k, v]) => `${k}=${v}`).join("&")}`
            : "";
          return await ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/anomalies/${y}/${m}/${d}${queryString}`,
            {
              varIds: variables,
            }
          );
        },

        getAnomalyRange: async (variable, start, end) => {
          const startFormatted = moment(start).format("YYYY-MM-DD");
          const endFormatted = moment(end).format("YYYY-MM-DD");
          return await ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/anomalies?start=${startFormatted}&end=${endFormatted}&varId=${variable}`
          );
        },

        getFreshAnomalies: (date, deg1array, deg2array, deg3array) => {
          return ATTACHED_TOKEN_FETCHERS.POST(
            `${baseUrl}/variables/freshAnomalies`,
            {
              date: moment(date).format("YYYY-MM-DD"),
              deg1array: deg1array || [],
              deg2array: deg2array || [],
              deg3array: deg3array || [],
            }
          );
        },

        setWatchlist: (variableId, status) => {
          return ATTACHED_TOKEN_FETCHERS.PATCH(
            `${baseUrl}/variables/${variableId}/watchlist`,
            {
              watchlist: status,
            }
          );
        },

        getAcknowledgments: (variableId, start, end) => {
          const startFormatted = moment(start).toISOString();
          const endFormatted = moment(end).toISOString();
          return ATTACHED_TOKEN_FETCHERS.GET(
            `${baseUrl}/acknowledgments?start=${startFormatted}&end=${endFormatted}&varId=${variableId}`
          );
        },

        postAcknowledgement: (
          variableId,
          start,
          end,
          unacknowledgment = false,
          type = "normal"
        ) => {
          return ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/acknowledgments`, {
            variable: variableId,
            start: moment.utc(start).startOf("day").toISOString(),
            end: moment.utc(end).endOf("day").toISOString(),
            unacknowledgment: unacknowledgment,
            type: type,
          });
        },

        getTeams: () => ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/teams`),
        createTeam: (team) =>
          ATTACHED_TOKEN_FETCHERS.POST(`${baseUrl}/teams`, team),
        updateTeam: (id, update) =>
          ATTACHED_TOKEN_FETCHERS.PATCH(`${baseUrl}/teams/${id}`, update),
        deleteTeam: (id) =>
          ATTACHED_TOKEN_FETCHERS.DELETE(`${baseUrl}/teams/${id}`),

        getLabels: () => ATTACHED_TOKEN_FETCHERS.GET(`/labels`),
        dummyRequest: (data, timeout) => {
          return new Promise((res, rej) => {
            setTimeout(() => res(data), timeout == null ? 1500 : timeout);
          });
        },

        getTimestamp: (type) => {
          return ATTACHED_TOKEN_FETCHERS.GET(`${baseUrl}/cached-data/${type}`);
        },
      };

      // combine both types of endpoints
      endpoints = baseUrl ? Object.assign(endpoints, {}) : {};

      /* Endpoint standardization and request prioritization. */
      Object.keys(endpoints).forEach(function (key) {
        function isResponse(response) {
          return !!response;
        }

        const endpoint = endpoints[key];

        endpoints[key] = function () {
          /* Append options parameter to the end of the function. */
          const options = arguments[endpoint.length] || {};

          const call = async () => {
            /* More precise request throttling. */
            if (options.throttle) {
              await new Promise((res) =>
                setTimeout(() => res(), options.throttle)
              );
            }

            /* Standardize the data that gets returned from requests. */
            return endpoint
              .apply(this, arguments)
              .then((response) => {
                return isResponse(response) ? response.data : response;
              })
              .catch((err) => {
                return Promise.reject(formatErrors(err));
              });
          };

          /* Defer call if request is deprioritized. */
          if (options.background) {
            return backgroundRequest(call);
          }

          return call();
        };
      });

      return endpoints;
    };

    const out = updateEndpoints();

    return out;
  }, [baseUrl, userId]);

  return endpoints;
}
