import { useEffect } from "react";
import { useNotificationPatchMutation } from "../tanstack/useNotificationsQuery";
import { useGetUseDateSelectorsStore } from "../../charts/DTC/useDateSelectorStore";
import PatternNotificationForm, {
  PatternNotificationForm2,
} from "./PatternNotificationForm";
import Notification, {
  NotificationCriteria,
} from "../../../types/api/Notification";
import { z } from "zod";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useAtomValue } from "jotai";
export default function PatternNotificationEditForm({
  close,
  notification,
}: {
  close: () => void;
  notification: Notification;
}) {
  const notificationObj = notification;
  const mutation = useNotificationPatchMutation(notification._id);
  const useDateSelectorsStore = useGetUseDateSelectorsStore();
  const dateSelectors = useDateSelectorsStore();

  useEffect(() => {
    if (notificationObj.parameters) {
      dateSelectors.setDates({
        start: new Date(notificationObj.parameters.start),
        end: new Date(notificationObj?.parameters.end),
      });
    }
    dateSelectors.setEditing(true);
    return () => {
      dateSelectors.setDates(null);
      dateSelectors.setEditing(false);
    };
  }, []);

  if (!notificationObj) {
    return <div>Loading...</div>;
  }

  const criteria = NotificationCriteria[notificationObj.criteria];

  if (
    criteria !== "IDENTICAL_MATCH" &&
    criteria !== "PROBABLE_MATCH" &&
    criteria !== "EXCEEDS_THRESHOLD"
  ) {
    throw new Error("how are you editing this?");
  }

  return (
    <PatternNotificationForm
      variableId={z.string().parse(notification.variableId)}
      mutation={mutation}
      defaultData={{
        ...notificationObj,
        schedule: notificationObj.schedule.type,
        criteria,
      }}
      close={close}
    />
  );
}

export function PatternNotificationEditForm2({
  close,
  notification,
}: {
  close: () => void;
  notification: Notification;
}) {
  const notificationObj = notification;
  const mutation = useNotificationPatchMutation(notification._id, {
    onSuccess: close,
  });

  const criteria = NotificationCriteria[notificationObj.criteria];

  if (
    criteria !== "IDENTICAL_MATCH" &&
    criteria !== "PROBABLE_MATCH" &&
    criteria !== "EXCEEDS_THRESHOLD"
  ) {
    throw new Error("how are you editing this?");
  }

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  if (!brushState || brushState.for !== "pattern-notif-edit") return;

  return (
    <PatternNotificationForm2
      variableId={z.string().parse(notification.variableId)}
      mutation={mutation}
      defaultData={{
        ...notificationObj,
        schedule: notificationObj.schedule.type,
        criteria,
      }}
      close={close}
    />
  );
}
