import * as R from "remeda";
import * as d3 from "d3";
import {
  binarySearchPoint as binarySearchPointCannotFail,
  binarySearchSegment,
  binarySearchStage,
} from "../utils";
import {
  type Points,
  type Point,
  LineWidth,
  type TimeseriesForBvForDraw,
} from "../types";
import { AnomalyLevelEnum } from "../../lib/anomaly-levels";
import { BatchViewMode, Timeseries, YAxisMode } from "../../time-series/types";
import { iife, minLen1 } from "../../lib/utils";
import {
  NUM_MS_FOR_STAGE_SEPARATORS,
  type DataForAlignByStageView,
} from "../align-by-stage-utils";
import { MinLen1 } from "../../lib/types";
import { DateTime } from "luxon";
import { DayHoverDrawer } from "./draw-day-hover";
import {
  getDomainFromScale,
  getRangeFromScale,
  rangeIsInside,
  rangeOverlaps,
} from "./utils";
import { OutOfYBoundsAwareSegmentDrawer } from "./out-of-bounds-aware-trend-drawer";
import { LONG } from "../../lib/luxon-format-tokens";

// Multiple the current line width (of the trend lines) by this factor
// when you are drawing slopes.
const SLOPES_LW_FACTOR = 2.2;

type ShouldDrawStage = (
  stage: TimeseriesForBvForDraw["stages"][number]
) => boolean;

type ShouldDrawSegment = (segment: Points) => boolean;

const stageIsRemainder: ShouldDrawStage = (stage) => stage._id === "Remainder";

const stageIsNotRemainder: ShouldDrawStage = (stage) =>
  !stageIsNotRemainder(stage);

const stageIsShutdown: ShouldDrawStage = (stage) =>
  stage._id === "000000000000000000000000";

const stageIsNotShutdown: ShouldDrawStage = (stage) => !stageIsShutdown(stage);

const segmentIsAnomalous: ShouldDrawSegment = (segment) => {
  const isAnomalous = segment.d
    ? segment.d > AnomalyLevelEnum["No Risk"]
    : false;
  return isAnomalous;
};
const segmentIsNotAnomalous: ShouldDrawSegment = (segment) =>
  !segmentIsAnomalous(segment);

const alwaysDraw = (() => true) satisfies ShouldDrawSegment | ShouldDrawStage;

const SHUTDOWN_ID = "0".repeat(24);

const RED_WITH_OPACITY = "rgba(247, 209, 198, 0.322)";

const ANALYSIS_PERIOD_GREY = "#eeeeee";
const PURPLE_ANOMALY_RECTS = "#cbc6e0";
const SHUTDOWN_COLOR = "#555555";
const DEFAULT_BLUE = "#049cdb";
const DEFAULT_PURPLE = "#762a83";

const ANOM_COLORS = {
  GREEN: "rgb(136, 196, 37)",
  YELLOW: "#f8ca00",
  ORANGE: "#E97f02",
  RED: "#c21a01",
} as const;

type SlopeColors = keyof Pick<typeof ANOM_COLORS, "ORANGE" | "RED" | "YELLOW">;

const GREEN = ANOM_COLORS.GREEN;
const YELLOW = ANOM_COLORS.YELLOW;
const ORANGE = ANOM_COLORS.ORANGE;
const RED = ANOM_COLORS.RED;
interface MyD3Scale {
  (t: number): number | undefined;
  invert: (t: number) => number | undefined;
  domain: () => [number, number];
  range: () => [number, number];
}

const HOVER_CIRCLE_DATASET_KEY = "bv";

type MutuallyExclusive<T extends string> = string extends T
  ? never
  : {
      [K in T]: Record<Extract<T, K>, true> & Record<Exclude<T, K>, false>;
    }[T];

type HoverPoint = {
  v: number;
  t: number;
  percentageLeft: number;
  stageId: string;
};

type HoverData = {
  intersectionPointsMap: {
    [bv: string]: HoverPoint;
  };
  percentageLeft: number;
  percentageTop: number;
  line:
    | {
        type: "expression";
        id: string;
      }
    | { type: "variable"; bv: string };
  point: HoverPoint;
  slopingTrendId: string | undefined;
};

type OptionalClamp = [number | undefined, number | undefined];

/**
 * Some things we just have to take a diff code path for
 * like drawing the DA bars.
 */
type App =
  | {
      type: "DRA";
      drawExpandedDaWhenOneVariable: boolean;
      slopes:
        | { extent: [Point, Point]; _id: string; anom: number }[]
        | undefined;
    }
  | {
      type: "ARC";
    };

type ValidYAxisMode = (typeof YAxisMode)[Extract<
  keyof typeof YAxisMode,
  "Absolute" | "Relative" | "Swimlane"
>];

type HoveredLine = (
  | {
      type: "expression";
      id: string;
    }
  | { type: "variable"; bv: string }
) & { stage: TimeseriesForBvForDraw["stages"][number]["_id"] };

export type TrendLineVariant =
  | {
      type: "expression";
      id: string;
    }
  | {
      type: "variable";
      bv: string;
    };

type DrawProps = {
  /**
   * If this is is true, we just don't show anomaly patches.
   *
   * If this is false, we draw the line as grey.
   *
   * The behaviors are different in DRA/Batch
   */
  stillUseColorForAnomalyColorationOff: boolean;
  anomalyPatchesThicknessScale?: number;
  timezone: string;
  axesFontScale?: number;
  anomalyRectangles?: boolean;
  viewMode: ValidYAxisMode;
  /**
   * Draw things in this canvas if they need to be redrawn
   * on hover. For example, the trend lines. Hovering changes
   * the boldness/opacity of the lines.
   *
   * This canvas is also affected by zoom, but that's obvious
   */
  hoverAffectedCanvas: HTMLCanvasElement;
  /**
   * Draw things in this canvas if they don't need to be redrawn
   * on hover. For example, the stage separators.
   *
   * This canvas is also affected by zoom, but that's obvious
   */
  hoverAgnosticCanvas: HTMLCanvasElement;
  darkTrendCanvas: HTMLCanvasElement;
  dayHoverCanvas: HTMLCanvasElement;
  jsdomCanvas: HTMLCanvasElement | undefined;
  overlapMode: BatchViewMode;
  app: App;
  svg: SVGSVGElement;
  data: [TimeseriesForBvForDraw, ...TimeseriesForBvForDraw[]];
  width: number;
  height: number;
  primaryTrendLineBatchVariableOrId:
    | {
        type: "expression";
        id: string;
      }
    | {
        type: "variable";
        bv: string;
      };
  theme: "light" | "dark";
  daBarHeightScale?: number;
  redBlockStart?: number; // used for red blocks if we're in DRA and have issue comments
  discontinuousDas:
    | (TrendLineVariant & {
        bounds: [number, number];
        d: AnomalyLevelEnum;
        shutdownOccurred: boolean;
      })[]
    | undefined;
  limits:
    | {
        data: {
          start: number;
          end: number | null;
          value: number;
        }[];
        level: string;
      }[]
    | undefined;
  drawOptionsMap: Record<
    string,
    {
      color: string;
      anomPatches: boolean; // should we draw anomaly patches?
      daBars: boolean; // should we draw da bars?
    }
  >;
  noDaBars?: boolean;
  primaryVariableColor?: [string, string];
  numTicks?: number;
  pinnedBatchVariable?: TrendLineVariant;
  taller?: boolean;
  interactivity?: {
    /**
     * When spotlight is on, hovering over a line will
     * dim all the other lines.
     *
     * When spotlight is off, hovering over a line will
     * only thicken the hovered line, and everything else
     * will remain the same.
     */
    spotlight: boolean;
    setXScale: (s: MyD3Scale | undefined) => void;
    brushing:
      | {
          get: () => [number, number | undefined];
          set: (d: [number, number]) => void;
          canvas: HTMLCanvasElement;
          setHandleData: (d: {
            leftPercent: number;
            topPercent: number;
            heightPercent: number;
          }) => void;
          setRedrawer: (
            fn: ((r: [number, number | undefined]) => void) | undefined
          ) => void;
        }
      | undefined;
    zoom?: {
      get: () =>
        | d3.D3ZoomEvent<SVGSVGElement, readonly [number, number]>
        | undefined;
      set: (
        e: d3.D3ZoomEvent<SVGSVGElement, readonly [number, number]>
      ) => void;
    };

    hoveredLineSync: {
      get: () => HoveredLine | undefined;
      set: (bv: HoveredLine | undefined) => void;
    };
    ignoreMouseLeaveSvgIfFocusGoesIntoId?: string;
    onHover?: (hoverData: HoverData | undefined) => void;
    onLineClick?: (o: {
      bvOrId: string;
      clientX: number;
      clientY: number;
    }) => void;
    subscribeToOnHighlightRedrawer?: (
      fn: (highlightedLine: HoveredLine | undefined) => void
    ) => void;
    notifyGlobalRange?: (
      d:
        | {
            min: number; // the min of the tuples in the perBv data
            max: number; // the max of the tuples in the perBv data
            perBv: Record<string, [number, number] | undefined>;
          }
        | undefined
    ) => void;
    notifyDomain?: (d: [number, number]) => void;
  };
  yClamps?: Record<string, OptionalClamp>;
  lineWidthScale?: number;

  /**
   * We will probably just pass in Variable ids as the batch variable
   * for DRA, so we need a way to convert them to the actual variable id
   * that works in both apps.
   */
  variableIdFromBatchVariable: (bv: string) => string;

  /**
   * In batch, we don't tell the chart where the left and right bounds
   * of the x-axis should be. That is decided by the data (or the batch).
   *
   * But in DRA, we want the x-axis to be drawn at exact bounds, so this
   * prop is used to tell the draw function "hey dont calculate the bounds
   * dynamically from the data, use what I give you".
   */
  overrideXAxisBounds?: [number, number];
  padding?: ConfigurablePadding;
  onlyMaxMinYAxes?: boolean;
  isModeTransparency?: boolean;
  analysisPeriod?: [number, number];
  hackForDemo?: boolean;
};

const BASE_DA_BAR_HEIGHT = 20;
const DA_BAR_PADDING = 5;
const LINE_WIDTH_FOR_STAGE_SEPARATOR = 10;

type Padding = {
  bottom: number;
  left: number;
  right: number;
  top: number;
};

type ConfigurablePadding = Partial<Padding>;

const DEFAULT_PADDING = {
  bottom: 60,
  left: 35,
  right: 110,
} as const satisfies Partial<Padding>;

const getPadding = (
  numVerticalDaBars: number,
  daBarHeight: number,
  optionalConfig?: ConfigurablePadding
): Padding => {
  if (numVerticalDaBars < 0) throw new Error("impossible 23");

  const heightForAllDaBars =
    numVerticalDaBars * daBarHeight + numVerticalDaBars * DA_BAR_PADDING;

  return {
    ...DEFAULT_PADDING,
    ...optionalConfig,
    top: (optionalConfig?.top ?? 0) + heightForAllDaBars,
  } as const;
};

const EXPANDED_DA_BAR_HEIGHT = 200;

const getPaddingForExpandedDa = (): Padding => {
  return {
    top: EXPANDED_DA_BAR_HEIGHT + DA_BAR_PADDING,
    ...DEFAULT_PADDING,
  } as const;
};

const translate = (x: number, y: number) => {
  return ["transform", `translate(${x}, ${y})`] as const;
};

const getTrendLineWidth2 = (scale: number, isSwimlane: boolean) => {
  const base = 10;

  return (h: LineWidth): number => {
    // if we are in swimlane, is there a reason to change the line width?
    const lw = isSwimlane
      ? base
      : iife(() => {
          switch (h) {
            case LineWidth.Regular:
              return base;
            case LineWidth.Bold:
              return base * 1.5;
            default:
              const _: never = h;
              throw new Error("unhandled case");
          }
        });
    return lw * scale;
  };
};

function parseOverlapMode(
  overlapMode: BatchViewMode
): MutuallyExclusive<BatchViewMode> {
  switch (overlapMode) {
    case BatchViewMode.ParallelStage:
      return {
        "parallel-stage": true,
        "parallel-start": false,
        series: false,
      };
    case BatchViewMode.series:
      return {
        "parallel-stage": false,
        "parallel-start": false,
        series: true,
      };
    case BatchViewMode.ParallelStart:
      return {
        "parallel-stage": false,
        "parallel-start": true,
        series: false,
      };
    default:
      const _: never = overlapMode;
      throw new Error("impossible 24");
  }
}

function modifyColor(color: string) {
  const d3Color = d3.color(color);
  if (!d3Color) throw new Error("Invalid color");
  return (howMuchDarker: number, opacity: number) => {
    const darker = d3Color.darker(howMuchDarker);
    darker.opacity = opacity;

    return darker.toString();
  };
}

/**
 * Add a little bit of space to the top and bottom of the y-axis
 */
const paddedYTopBottom = ([dataMin, dataMax]: [number, number]) => {
  const diff = dataMax - dataMin;
  // if (diff < 0) throw new Error("impossible 25");
  const toPad = diff * 0.0115;
  return [dataMin - toPad, dataMax + toPad];
};

function getTopOfDaBarPosition(barHeight: number, daBarsLevel: number) {
  return daBarsLevel * (barHeight + DA_BAR_PADDING);
}

function clampAdjustedRelativeYScaleDomain(
  inViewRange: [number, number],
  clamp: OptionalClamp | undefined
): [number, number] | undefined {
  if (!clamp) return inViewRange;

  const [clampMin, clampMax] = clamp;

  const [inViewDataMin, inViewDataMax] = inViewRange;

  if (clampMin === undefined) {
    if (clampMax === undefined) {
      return inViewRange;
    }

    // handle only max
    if (clampMax <= inViewDataMin) {
      // TODO what if they're equal?
      // there will be nothing to draw
      return undefined;
    }

    return [inViewDataMin, clampMax];
  } else {
    if (clampMax === undefined) {
      // handle only min
      if (clampMin >= inViewDataMax) {
        // there will be nothing to draw
        return undefined;
      }

      return [clampMin, inViewDataMax];
    } else {
      // handle both

      if (clampMax === clampMin) return undefined;
      if (clampMin > clampMax) return undefined;

      return [clampMin, clampMax];
    }
  }
}

function hardcodedNumYTicks(domain: number[], numTicks = 5) {
  const [min, max] = domain as [number, number];
  const diff = max - min;
  const step = diff / (numTicks - 1);
  return Array.from({ length: numTicks }).map((_, i) => min + step * i);
}

interface DrawFn {
  (
    opts: DrawProps
  ): (cleanupOpts: { shouldResetZoomOnSvgHTMLElement: boolean }) => void;
}

export const TALLER_CHART_SCALE = 1.6;

export function isVariableVariant<T extends TrendLineVariant>(
  v: T
): v is Extract<T, { type: "variable" }> {
  return v.type === "variable";
}

export function isExpressionVariant<T extends TrendLineVariant>(
  v: T
): v is Extract<T, { type: "expression" }> {
  return v.type === "expression";
}

export function getBvOrId<T extends TrendLineVariant>(v: T): string {
  if (isVariableVariant(v)) {
    return v.bv;
  }

  if (isExpressionVariant(v)) {
    return v.id;
  }

  throw new Error("impossible 26");
}

export function trendDataMatches<
  A extends TrendLineVariant,
  B extends TrendLineVariant,
>(a: A, b: B): boolean {
  return getBvOrId(a) === getBvOrId(b);
}

const draw: DrawFn = ({
  data: ogData,
  pinnedBatchVariable,
  height,
  timezone,
  hoverAffectedCanvas,
  darkTrendCanvas,
  hoverAgnosticCanvas,
  jsdomCanvas,
  dayHoverCanvas,
  svg,
  width,
  viewMode,
  overlapMode,
  primaryTrendLineBatchVariableOrId: primaryBatchVariable,
  theme,
  drawOptionsMap,
  interactivity,
  yClamps,
  lineWidthScale,
  variableIdFromBatchVariable,
  padding,
  axesFontScale,
  overrideXAxisBounds,
  stillUseColorForAnomalyColorationOff,
  app,
  taller,
  daBarHeightScale,
  noDaBars,
  primaryVariableColor,
  numTicks,
  limits,
  redBlockStart,
  anomalyRectangles,
  discontinuousDas,
  anomalyPatchesThicknessScale,
  isModeTransparency,
  onlyMaxMinYAxes,
  analysisPeriod,
  hackForDemo,
}: DrawProps) => {
  const slopesToDraw =
    app.type === "DRA" && app.slopes?.length
      ? iife(() => {
          const yellow: typeof app.slopes = [];
          const orange: typeof app.slopes = [];
          const red: typeof app.slopes = [];

          for (const slope of app.slopes!) {
            if (slope.anom < 90) yellow.push(slope);
            else if (slope.anom < 180) orange.push(slope);
            else red.push(slope);
          }

          const out: {
            slopes: typeof app.slopes;
            color: SlopeColors;
          }[] = [
            { slopes: yellow, color: "YELLOW" },
            { slopes: orange, color: "ORANGE" },
            { slopes: red, color: "RED" },
          ];
          return out;
        })
      : undefined;

  const dummyCanvas = jsdomCanvas || document.createElement("canvas");

  const colorAndAssociatedAnomLevel: {
    d: AnomalyLevelEnum;
    color: CanvasRenderingContext2D["fillStyle"];
    shutdownColor: CanvasRenderingContext2D["fillStyle"];
  }[] = [
    {
      d: AnomalyLevelEnum["High Risk"],
      color: RED,
      shutdownColor: createShutdownStripedPattern(RED, dummyCanvas),
    },
    {
      d: AnomalyLevelEnum["Medium Risk"],
      color: ORANGE,
      shutdownColor: createShutdownStripedPattern(ORANGE, dummyCanvas),
    },
    {
      d: AnomalyLevelEnum["Low Risk"],
      color: YELLOW,
      shutdownColor: createShutdownStripedPattern(YELLOW, dummyCanvas),
    },
    {
      d: AnomalyLevelEnum["No Risk"],
      color: GREEN,
      shutdownColor: createShutdownStripedPattern(GREEN, dummyCanvas),
    },
  ];

  const [BLUE, PURPLE] = primaryVariableColor || [DEFAULT_BLUE, DEFAULT_PURPLE];
  const modifyBlue = modifyColor(BLUE);
  const modifyPurple = modifyColor(PURPLE);
  const modifyShutdownColor = modifyColor(SHUTDOWN_COLOR);

  if (taller) {
    height = height * TALLER_CHART_SCALE;
  }

  const getMinMaxLimits = (
    zoomedWindow: [number, number]
  ): [number, number] => {
    if (!limits || !limits.length) throw new Error("dont call this function");

    let maxLimit = -Infinity;
    let minLimit = Infinity;
    for (const { data } of limits) {
      for (const { end, start, value } of data) {
        // only the last limit can be null-ended
        // so assume it goes on forever
        if (!rangeOverlaps([start, end ?? Infinity], zoomedWindow)) continue;

        maxLimit = Math.max(maxLimit, value);
        minLimit = Math.min(minLimit, value);
      }
    }

    return [minLimit, maxLimit];
  };

  const shouldDrawColoredCirclesNextToDaBars =
    !noDaBars && ogData.length > 1 && viewMode !== YAxisMode.Swimlane;

  const getTrendLineWidth = getTrendLineWidth2(
    lineWidthScale ?? 1,
    viewMode === YAxisMode.Swimlane
  );
  const xAxisMode = parseOverlapMode(overlapMode);

  /**
   * This is ideally configured through the consumer.
   * The 30 has no real significance since our svg
   * is scaled through CSS anyway.
   */
  const axesFontSize = 30 * (axesFontScale ?? 1);
  const GREY = theme === "light" ? "#e8e4e4" : "#2c2c2c";

  const data = iife(function attachColor() {
    type WithColor = TimeseriesForBvForDraw & {
      daBarsLevel?: number;
    } & DrawProps["drawOptionsMap"][string];

    const data = ogData.map((d): WithColor => {
      const drawOptions =
        drawOptionsMap[
          iife(() => {
            switch (d.type) {
              case "expression":
                return d.id;
              case "variable":
                return d.bv;
              default:
                const _: never = d;
                throw new Error("impossible 27");
            }
          })
        ];

      if (!drawOptions) throw new Error("impossible 28");

      return {
        ...d,
        ...drawOptions,
      };
    });

    if (!minLen1(data)) throw new Error("impossible 29");

    return data;
  });

  /**
   * This is run to mutate the above data. We calculate what index
   * to draw the da bars at depending on the x-axis mode.
   *
   * This is dynamic because we only draw DA bars for which anomaly
   * coloration is on. That is, if you don't add or remove any data,
   * but you turn on/off anomaly coloration, the chart can get
   * taller/shorter depending on the number of da bars.
   */
  iife(function populateDaBarsLevels_MUTATES() {
    if (noDaBars) return;

    switch (overlapMode) {
      case BatchViewMode.series:
        console.log("case 100");
        /**
         * all batch variables that have the same variable
         * should fall on the same daBarsLevel in series view
         *
         * This is because they will never overlap in series view.
         * That is, the same variable across diff batches will never
         * have a point at the same time.
         * */
        const variableIdToIndex: Record<string, number> = {};

        if (primaryBatchVariable.type === "variable") {
          variableIdToIndex[
            variableIdFromBatchVariable(primaryBatchVariable.bv)
          ] = 0;
        }
        console.log("data", data);
        if (viewMode === YAxisMode.Swimlane) {
          for (let i = 0; i < data.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const x = data[i]!;

            const shouldDrawADaBar = x.daBars;
            if (!shouldDrawADaBar) continue;

            if (x.type === "expression") throw new Error("impossible 30");

            console.log("decided", i);
            x.daBarsLevel = i;
          }
        } else
          for (const x of data) {
            const shouldDrawADaBar = x.daBars;
            if (!shouldDrawADaBar) continue;

            if (x.type === "expression")
              throw new Error(
                "should not be trying to draw DA bars for expression trend lines"
              );

            const variableId = x.bv.slice(24);

            if (
              isVariableVariant(primaryBatchVariable) &&
              x.bv === primaryBatchVariable.bv
            ) {
              // explicitly set the primary batch variable to be at the top
              x.daBarsLevel = 0;
              continue;
            }

            const newValue = variableIdToIndex[variableId];
            if (newValue === undefined) {
              const nextIndex = Object.keys(variableIdToIndex).length;
              x.daBarsLevel = nextIndex;
              variableIdToIndex[variableId] = nextIndex;
            } else {
              x.daBarsLevel = newValue;
            }
          }
        break;
      default:
        /**
         * In the other two modes, each batch variable gets its own
         * daBarsLevel. This is because they can overlap in time.
         */
        let nextAvailableLevel = 1;

        for (const x of data) {
          if (isExpressionVariant(x)) continue;
          if (
            isVariableVariant(primaryBatchVariable) &&
            x.bv === primaryBatchVariable.bv
          ) {
            x.daBarsLevel = 0;
          } else if (x.daBars) {
            x.daBarsLevel = nextAvailableLevel++;
          }
        }
    }
  });

  // let primaryDataProvided = false;
  // for (const x of data) {
  //   primaryDataProvided = primaryDataProvided || x.daBarsLevel === 0;
  // }

  // if (!primaryDataProvided) {
  //   // no daBarsLevel was === 0 above, decrement all so we get a 0
  //   for (const x of data) {
  //     if (x.daBarsLevel) {
  //       x.daBarsLevel--;
  //     }
  //   }
  // }

  const maxDaBarIndex =
    R.maxBy(data, (x) => x.daBarsLevel ?? -1)?.daBarsLevel ?? -1;

  const numDaBars = noDaBars ? 0 : maxDaBarIndex + 1; // if there are no da bars, this is 0

  /**
   * Draw expanded DA bars if there is only one da bar (the primary)
   * and we were only given that primary batch variable to draw.
   */
  const shouldDrawExpandedDa =
    !noDaBars &&
    app.type === "DRA" &&
    app.drawExpandedDaWhenOneVariable &&
    numDaBars === 1 &&
    ogData.length === 1;

  const DA_BAR_HEIGHT = BASE_DA_BAR_HEIGHT * (daBarHeightScale ?? 1);

  const PADDING = shouldDrawExpandedDa
    ? getPaddingForExpandedDa()
    : getPadding(
        viewMode === YAxisMode.Swimlane ? 0 : numDaBars,
        DA_BAR_HEIGHT,
        padding
      );

  const adjustedHeight = height - PADDING.top - PADDING.bottom;
  const adjustedWidth = width - PADDING.left - PADDING.right;

  interactivity?.brushing?.setHandleData({
    heightPercent: adjustedHeight / height,
    leftPercent: PADDING.left / width,
    topPercent: PADDING.top / height,
  });

  const dayHoverDrawer = new DayHoverDrawer({
    canvas: dayHoverCanvas,
    timezone,
    trendChartHeight: adjustedHeight,
    padding: PADDING,
  });

  /**
   * The trends are drawn in this canvas. By removing the PADDING.bottom,
   * I can ensure that trend lines going below the x-axis (because of clamping)
   * will be clipped. That is, Canvas automatically handles clipping things
   * that are drawn out of bounds. When we clamp, we don't filter out the data,
   * that is smaller than min clamp or bigger than max clamp. We just draw it
   * out of bounds, and canvas clips it.
   */
  hoverAffectedCanvas.height = height;
  hoverAffectedCanvas.width = width;
  darkTrendCanvas.height = height;
  darkTrendCanvas.width = width;

  hoverAgnosticCanvas.height = height;
  hoverAgnosticCanvas.width = width;

  dayHoverCanvas.width = width;
  dayHoverCanvas.height = height;

  if (interactivity?.brushing) {
    interactivity.brushing.canvas.width = width;
    interactivity.brushing.canvas.height = height;
  }

  const trendContext = hoverAffectedCanvas.getContext("2d");
  const darkTrendContext = darkTrendCanvas.getContext("2d");

  const svgSelection = d3
    .select<SVGSVGElement, readonly [number, number]>(svg)
    .attr("viewBox", `0 0 ${width} ${height}`);

  const anomalyLevelScale = d3
    .scaleLinear()
    .domain([
      AnomalyLevelEnum["No Risk"] - 1,
      AnomalyLevelEnum["High Risk"] + 1,
    ])
    .range([PADDING.top, 0]);

  if (shouldDrawExpandedDa) {
    const draExpandedDaGroup = svgSelection
      .append("g")
      .attr(...translate(PADDING.left + adjustedWidth, 0));

    // draw the y-axis
    const anomalyAxis = d3.axisRight(anomalyLevelScale);
    anomalyAxis.tickValues([
      AnomalyLevelEnum["High Risk"],
      AnomalyLevelEnum["Medium Risk"],
      AnomalyLevelEnum["Low Risk"],
      AnomalyLevelEnum["No Risk"],
    ]);
    anomalyAxis.tickFormat((t) => t.toString());
    draExpandedDaGroup.call(anomalyAxis);
    draExpandedDaGroup.attr("font-size", axesFontSize);
  }

  /**
   * create a group that is padding aware, then we no
   * longer need to translate anything with padding in mind.
   * consider this new group "the svg"
   */
  const mainGroup = svgSelection
    .append("g")
    .attr(...translate(PADDING.left, PADDING.top));

  const originalDomain = iife((): [number, number] => {
    if (xAxisMode.series) {
      if (overrideXAxisBounds) return overrideXAxisBounds;

      const maxXValue = (data[data.length - 1] ?? data[0]).d[1];
      const minXValue = data[0].d[0];
      return [minXValue, maxXValue];
    }

    if (xAxisMode["parallel-stage"]) {
      throw new Error("TODO fix this");
      // const longestSequenceMs = alignByStageData?.longestSequenceLen;
      // if (longestSequenceMs === undefined) throw new Error("impossible 31 1");
      // return [0, longestSequenceMs];
    }

    // all thats left is parallel start mode

    // data should be sorted by x value (time) from API
    const longestLine = R.firstBy(data, [
      (x) => x.d[1] - x.d[0],
      "desc",
    ]) as (typeof data)[number]; // we know this exists because data is not empty

    return [0, longestLine.d[1] - longestLine.d[0]];
  });

  const originalXScale = d3
    .scaleTime()
    .domain(originalDomain)
    .range([0, adjustedWidth]);

  const xAxis = d3.axisBottom(originalXScale).tickFormat((t) => {
    const d = DateTime.fromMillis(t.valueOf(), { zone: timezone });
    /**
     * Note that setting the zone affects how the
     * startOf function behaves. It will consider
     * the "start" in their timezone, not the
     * browser's. Which is what we want because we're
     * trying to format and display things relative
     * to the plant.
     */

    // Is it 12 AM plant time?
    if (d.startOf("day").equals(d)) {
      return d.toFormat("M/d");
    }

    if (d.startOf("hour").equals(d)) {
      return d.toFormat("h a");
    }

    if (d.startOf("minute").equals(d)) {
      return d.toFormat("h:mm a");
    }

    // don't show the ticks if it's second/millisecond specific
    return "";
  });

  // Add the y-axis to the SVG
  const yAxisGroup = mainGroup.append("g").attr(...translate(adjustedWidth, 0));
  const xAxisSelection = mainGroup
    .append("g")
    .attr(...translate(0, adjustedHeight))
    .attr("font-size", axesFontSize);

  const hoverLine = svgSelection
    .append("line")
    .attr("class", "timeline-vertical-line")
    .style("stroke", "var(--slate-11)")
    .style("stroke-dasharray", "12")
    .attr(...translate(PADDING.left, PADDING.top))
    .style("pointer-events", "none")
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", 0)
    .attr("y2", adjustedHeight);

  const hoverCircle = svgSelection
    .append("circle")
    .attr("r", 10)
    .attr("fill", "var(--chart-line-color)");

  const onLineClick = interactivity?.onLineClick;

  if (onLineClick) {
    svgSelection.style("cursor", "pointer");
    svgSelection.on("click", (e: MouseEvent) => {
      const clickedBv =
        hoverCircle.node()?.dataset[HOVER_CIRCLE_DATASET_KEY] ??
        getBvOrId(primaryBatchVariable);

      onLineClick({
        bvOrId: clickedBv,
        clientX: e.clientX,
        clientY: e.clientY,
      });
      interactivity?.onHover?.(undefined);
      // hoverCircle.attr("fill", "red"); // for testing
    });
  } else {
    svgSelection.style("cursor", null);
    svgSelection.on("click", null);
  }

  const hideHoverLine = () => hoverLine.style("opacity", 0);
  const hideHoverCircle = () => hoverCircle.style("opacity", 0);

  hideHoverLine();
  hideHoverCircle();

  const daDrawDataForDRA = iife(function calculateDaDrawDataForDRA() {
    if (app.type !== "DRA") return undefined;

    const prefilteredData = data;

    if (discontinuousDas) {
      const out = discontinuousDas
        .map((x): DrawRectData | undefined => {
          const d = prefilteredData.find((y) => trendDataMatches(y, x));

          if (!d) throw new Error("impossible 2");

          if (d.daBarsLevel === undefined) return undefined;

          return {
            bounds: x.bounds,
            d: x.d,
            daBarLevel: d.daBarsLevel,
            shutdownOccurred: x.shutdownOccurred,
          };
        })
        .filter((x) => x !== undefined);

      return out;
    }

    /**
     * Must use pre-filtered data because
     * DRA is concerned with the data for a
     * day even if it's half filtered out
     * (user zoomed half the day away).
     */

    const [leftEdgeWithNoZooming, rightEdgeWithNoZooming] = originalDomain;

    const startEndTuples: [number, number][] = [];

    let startOfDay = DateTime.fromMillis(leftEdgeWithNoZooming, {
      zone: timezone,
    }).startOf("day");

    while (startOfDay.toMillis() < rightEdgeWithNoZooming) {
      startEndTuples.push([
        startOfDay.toMillis(),
        startOfDay.endOf("day").toMillis(),
      ]);
      startOfDay = startOfDay
        .plus({
          days: 1,
        })
        // just in case
        .startOf("day");
    }

    type DrawRectData = {
      bounds: [number, number];
      d: AnomalyLevelEnum;
      daBarLevel: number;
      shutdownOccurred: boolean;
    };

    const out: DrawRectData[] = [];

    for (const bv of prefilteredData) {
      const daBarLevel = bv.daBarsLevel;
      if (daBarLevel === undefined) continue; // anomaly coloration is off

      for (const bounds of startEndTuples) {
        let dataForTheseBounds = {
          da: AnomalyLevelEnum["No Risk"],
          shutdownOccurred: false,
        };

        for (const stage of bv.stages) {
          const [stageStart, stageEnd] = stage.d;
          if (stageStart > bounds[1]) break;
          if (stageEnd < bounds[0]) continue;

          for (const segment of stage.ptsPartitioned) {
            const segStart = segment.pts[0].t;
            const segEnd = (
              segment.pts[segment.pts.length - 1] ?? segment.pts[0]
            ).t;

            if (segStart > bounds[1]) break;
            if (segEnd < bounds[0]) continue;

            /**
             * if we get to this point, that means the segment overlaps
             * with the bounds at least at 1 point.
             */

            const d = segment.d; // all points within a segment have the same d
            dataForTheseBounds.da = Math.max(
              dataForTheseBounds.da,
              d ?? AnomalyLevelEnum["No Risk"]
            ) as AnomalyLevelEnum;
            dataForTheseBounds.shutdownOccurred =
              dataForTheseBounds.shutdownOccurred ||
              stage._id === "000000000000000000000000";
          }
        }

        out.push({
          bounds,
          d: dataForTheseBounds.da,
          shutdownOccurred: dataForTheseBounds.shutdownOccurred,
          daBarLevel,
        });
      }
    }

    return out;
  });

  const drawWithZoomState = (xS: d3.ScaleTime<number, number, never>) => {
    const zoomedWindow = getDomainFromScale(xS);

    if (interactivity && interactivity.notifyDomain) {
      interactivity.notifyDomain(zoomedWindow);
    }

    type TimeseriesForBvWithOwnRelativeScale = (typeof data)[number] & {
      relativeScale: d3.ScaleLinear<number, number, never> | undefined;
    };

    type HighlightToData = Partial<
      Record<
        LineWidth,
        {
          /**
           * We make this distinction with primary data because it is the only line
           * that can have 2 colors, blue and purple.
           *
           * All the other lines are always 1 color (we darked or lighten the same color
           * if necessary, but it's still just one color)
           */
          primaryData?: TimeseriesForBvWithOwnRelativeScale; // undefined because you can zoom the primary data out of view
          coloredData?: MinLen1<TimeseriesForBvWithOwnRelativeScale>;
          greyData?: MinLen1<TimeseriesForBvWithOwnRelativeScale>;
        }
      >
    >;

    const getInViewData = (): {
      all: TimeseriesForBvWithOwnRelativeScale[];
      // this will be in "all" as well but its convenient if we have a reference to it
      primary: TimeseriesForBvWithOwnRelativeScale | undefined;
      absoluteYScale: d3.ScaleLinear<number, number, never> | undefined;
    } => {
      // filter the data by batch variables that are in view
      // we'll go into the data later on and filter in a more
      // fine grained manner (stages, segments, points) after

      const batchVariablesInView = data.filter((d) => {
        const firstStage = d.stages[0];
        const lastStage = d.stages[d.stages.length - 1] ?? firstStage;

        const firstPoint = firstStage.ptsPartitioned[0].pts[0].t;

        const lastSegment =
          lastStage.ptsPartitioned[lastStage.ptsPartitioned.length - 1]!;
        const lastPoint = lastSegment.pts[lastSegment.pts.length - 1]!.t;

        return rangeOverlaps(zoomedWindow, [
          firstStage.offset(firstPoint),
          lastStage.offset(lastPoint),
        ]);
      });
      console.time("mapper");

      const withinZoomedWindowData: TimeseriesForBvWithOwnRelativeScale[] = [];
      console.time("mapper 6");
      for (const d of batchVariablesInView) {
        console.time("a + b");
        console.time("mapper 6a");
        const wholeBatchVariableInView = rangeIsInside(
          zoomedWindow,
          iife(() => {
            const firstStage = d.stages[0];
            const lastStage = d.stages[d.stages.length - 1] ?? firstStage;
            const start = firstStage.offset(firstStage.d[0]);
            const end = lastStage.offset(lastStage.d[1]);

            return [start, end];
          })
        );
        const stagesThatAreAtLeastPartiallyInView = iife(() => {
          if (wholeBatchVariableInView) return d.stages;

          let fromIdx = -1;

          for (let i = 0; i < d.stages.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const stage = d.stages[i]!;

            const segments = stage.ptsPartitioned;

            const lastSegment = segments[segments.length - 1]!;

            const lastPoint = lastSegment.pts[lastSegment.pts.length - 1]!.t;
            if (stage.offset(lastPoint) >= zoomedWindow[0]) {
              fromIdx = i;
              break;
            }
          }

          let toIdx = -1;

          for (let i = d.stages.length - 1; i >= 0; i--) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const stage = d.stages[i]!;
            const firstPoint = stage.ptsPartitioned[0].pts[0].t;
            if (stage.offset(firstPoint) <= zoomedWindow[1]) {
              toIdx = i;
              break;
            }
          }

          return (
            fromIdx === -1 || toIdx === -1
              ? []
              : d.stages.slice(fromIdx, toIdx + 1)
          ).map((s) => {
            const firstSegment = s.ptsPartitioned[0];
            const lastSegment =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              s.ptsPartitioned[s.ptsPartitioned.length - 1]!;
            const firstPoint = firstSegment.pts[0];
            const lastPoint =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              lastSegment.pts[lastSegment.pts.length - 1]!;

            const shouldNotDoFiltering = rangeIsInside(zoomedWindow, [
              s.offset(firstPoint.t),
              s.offset(lastPoint.t),
            ]);

            if (shouldNotDoFiltering) return s;

            let fromIdx = -1;

            for (let i = 0; i < s.ptsPartitioned.length; i++) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const segment = s.ptsPartitioned[i]!;
              const segEnd = segment.pts[segment.pts.length - 1]!.t;
              if (s.offset(segEnd) >= zoomedWindow[0]) {
                fromIdx = i;
                break;
              }
            }

            let toIdx = -1;

            for (let i = s.ptsPartitioned.length - 1; i >= 0; i--) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const segment = s.ptsPartitioned[i]!;
              const segStart = segment.pts[0].t;
              if (s.offset(segStart) <= zoomedWindow[1]) {
                toIdx = i;
                break;
              }
            }

            if (fromIdx === -1 || toIdx === -1) throw new Error("impossible 3");

            const sliced = s.ptsPartitioned.slice(fromIdx, toIdx + 1);

            if (!minLen1(sliced)) throw new Error("impossible 3");

            return {
              ...s,
              ptsPartitioned: sliced,
            };

            // /**
            //  * Try to only remove segments if you need to by first checking
            //  * the stage is fully in view.
            //  */
            // const removedSegments = s.ptsPartitioned.filter((segment) => {
            //   const segmentEnd = (
            //     segment.pts[
            //       segment.pts.length - 1
            //     ] as (typeof segment)["pts"][number]
            //   ).t;
            //   const segmentStart = segment.pts[0].t;
            //   const thisSegmentIsAtLeastPartiallyInView = rangeOverlaps(
            //     zoomedWindow,
            //     [offset(segmentStart), offset(segmentEnd)]
            //   );

            //   return thisSegmentIsAtLeastPartiallyInView;
            // });

            // if (!minLen1(removedSegments)) throw new Error("impossible 4");

            // return {
            //   ...s,

            //   // TODO why did i do this again???? this is key to fixing the segments going out of bounds issue
            //   ptsPartitioned: removedSegments,
            // };
          });
        });

        console.timeEnd("mapper 6a");

        if (!minLen1(stagesThatAreAtLeastPartiallyInView)) {
          // can only be len 0 in parallel stage mode due to discontinuous data. Otherwise, it's a bug
          if (!xAxisMode["parallel-stage"]) throw new Error("impossible 5");
          /**
           * TODO: explain this if block better because it's not
           * so obvious why this is necessary.
           */ else {
            continue;
          }
        }
        console.time("mapper 6b");
        /**
         * This function returns a scale that we'll use to draw the points
         * for the this batch variable if and only if the clamps set
         * by the user are valid. If the clamps are not valid, we consider
         * this batch variable as "not in view",
         *
         * no scale => it's not in view because of the clamps.
         *
         * But, we still return the data for it without a scale, because
         * the DA bars for it can still be drawn, even if the trend lines
         * are not in view
         */
        const getMyOwnRelativeYScaleIfClampsAreValid = ():
          | TimeseriesForBvWithOwnRelativeScale["relativeScale"]
          | undefined => {
          const isPrimaryAndHasLimits =
            isVariableVariant(d) &&
            isVariableVariant(primaryBatchVariable) &&
            d.bv === primaryBatchVariable.bv &&
            limits &&
            limits.length > 0; // only variable trend lines can have limits?

          if (wholeBatchVariableInView && !xAxisMode["parallel-stage"]) {
            /**
             * In parallel stage mode, we removed the default stages,
             * so the d.r is no longer valid, so we have to resort
             * to manually calculating it below.
             *
             * So we can only return early if we're not in parallel stage
             * mode.
             */

            // TODO: adjust y scale here when disabling modes
            const adjustedRange = clampAdjustedRelativeYScaleDomain(
              isPrimaryAndHasLimits
                ? iife(() => {
                    const [minLimit, maxLimit] = getMinMaxLimits(zoomedWindow);
                    return [
                      Math.min(minLimit, d.r[0]),
                      Math.max(maxLimit, d.r[1]),
                    ];
                  })
                : d.r,
              yClamps?.[getBvOrId(d)]
            );

            return (
              adjustedRange && d3.scaleLinear().domain(adjustedRange) // set range later
            );
          }

          // the whole batch isn't in view, so we need to go thru it
          let maxY = -Infinity;
          let minY = Infinity;

          console.time("mapper 3");
          for (const s of stagesThatAreAtLeastPartiallyInView) {
            if (
              rangeIsInside(zoomedWindow, [s.offset(s.d[0]), s.offset(s.d[1])])
            ) {
              // whole stage in view
              maxY = Math.max(maxY, s.r[1]);
              minY = Math.min(minY, s.r[0]);
              continue;
            }

            // this stage is partially in view, so we need to go thru the segments

            console.time("mapper 4");
            const interper = d3.scaleLinear();

            for (let i = 0; i < s.ptsPartitioned.length; i++) {
              const segment = s.ptsPartitioned[i]!;
              const isFirstOrLast =
                i === 0 || i === s.ptsPartitioned.length - 1;

              if (isFirstOrLast) {
                for (let i = 0; i < segment.pts.length; i++) {
                  const point = segment.pts[
                    i
                  ] as (typeof segment)["pts"][number];
                  const pointTime = s.offset(point.t);

                  if (pointTime < zoomedWindow[0]) {
                    const nextPoint = segment.pts[i + 1];

                    if (nextPoint && s.offset(nextPoint.t) > zoomedWindow[0]) {
                      // we need to interpolate a point
                      const fakeV = interper
                        .domain([s.offset(point.t), s.offset(nextPoint.t)])
                        .range([point.v, nextPoint.v])(zoomedWindow[0]);

                      maxY = Math.max(maxY, fakeV);
                      minY = Math.min(minY, fakeV);
                    }

                    continue;
                  }

                  maxY = Math.max(maxY, point.v);
                  minY = Math.min(minY, point.v);

                  const nextPoint = segment.pts[i + 1];

                  if (nextPoint && s.offset(nextPoint.t) > zoomedWindow[1]) {
                    // we need to interpolate a point
                    const fakeV = interper
                      .domain([s.offset(point.t), s.offset(nextPoint.t)])
                      .range([point.v, nextPoint.v])(zoomedWindow[1]);

                    maxY = Math.max(maxY, fakeV);
                    minY = Math.min(minY, fakeV);

                    /**
                     * If we find that the next point falls of the screen, we
                     * can stop processing this segment because it'll be wasted
                     * iterations (they all fall off the screen after this point)
                     */
                    break;
                  }
                }
              } else {
                maxY = Math.max(maxY, segment.r[1]);
                minY = Math.min(minY, segment.r[0]);
              }
            }

            console.timeEnd("mapper 4");
          }
          console.timeEnd("mapper 3");

          if (maxY === -Infinity || minY === Infinity)
            throw new Error("impossible 6"); // probably can remove this later on

          if (Math.abs(maxY - minY) <= 0.001) {
            // prevents flickering when the data is flat
            maxY += 1;
            minY -= 1;
          }

          const adjustedRange = clampAdjustedRelativeYScaleDomain(
            isPrimaryAndHasLimits
              ? iife(() => {
                  /**
                   * If limits exist and we're processing the data for the primary
                   * batch variable, we need to ensure that the limit lines are
                   * visible. So we need to adjust the range to include the limits.
                   */
                  const [minLimit, maxLimit] = getMinMaxLimits(zoomedWindow);

                  return [Math.min(minLimit, minY), Math.max(maxLimit, maxY)];
                })
              : [minY, maxY],
            yClamps?.[getBvOrId(d)]
          );

          return (
            adjustedRange && d3.scaleLinear().domain(adjustedRange) // set range later
          );
        };

        withinZoomedWindowData.push({
          ...d,
          // TODO filter the segments in the stages too?
          stages: stagesThatAreAtLeastPartiallyInView,
          relativeScale: getMyOwnRelativeYScaleIfClampsAreValid(),
        });

        console.timeEnd("mapper 6b");
        console.timeEnd("a + b");
      }

      /**
       * We only filter by x values here. That is, the zoom window.
       * We'll filter by y values later.
       */
      console.timeEnd("mapper 6");

      // we set the ranges of the d3 scales after the filter above, because it depends on the length
      const baseHeightPerChart = adjustedHeight / withinZoomedWindowData.length;
      for (let idx = 0; idx < withinZoomedWindowData.length; idx++) {
        const d = withinZoomedWindowData[idx]!;
        if (!d.relativeScale) continue;
        const relativeScaleRange = iife((): [number, number] => {
          if (viewMode !== YAxisMode.Swimlane) return [adjustedHeight, 0];

          let myHeight = baseHeightPerChart;
          let myTop = idx * myHeight;

          if (d.daBars) {
            myHeight = myHeight - DA_BAR_HEIGHT - DA_BAR_PADDING;
            myTop = myTop + DA_BAR_HEIGHT + DA_BAR_PADDING; // plus to bring it further down (because canvas coords )
          }

          const littlePadding = myHeight * 0.15; // put a little bit of padding at the bottom by bringing bottomCoord upwards a bit (subtract)

          // put the larger number first because min Y values go further down the screen
          // this will be used like d3.scaleLinear([minYValue, maxYValue]).range([bottomCoord, topCoord])
          return [myHeight + myTop - littlePadding, myTop];
        });

        d.relativeScale.range(relativeScaleRange);
      }

      console.timeEnd("mapper");

      /**
       * Use the relative scales from the data above to calculate the
       * absolute y scale.
       *
       * We just loop over them and take the max of their maxes
       * and the min of their mins.
       */
      const absoluteYScale = iife(() => {
        let minAndMaxY = undefined as [number, number] | undefined;

        for (const x of withinZoomedWindowData) {
          if (!x.relativeScale) continue;
          const [min, max] = getDomainFromScale(x.relativeScale);

          if (minAndMaxY === undefined) {
            minAndMaxY = [min, max];
          } else {
            minAndMaxY[0] = Math.min(minAndMaxY[0], min);
            minAndMaxY[1] = Math.max(minAndMaxY[1], max);
          }
        }

        /**
         * If there's no data in view, we return undefined
         */
        return (
          minAndMaxY &&
          d3.scaleLinear().domain(minAndMaxY).range([adjustedHeight, 0])
        );
      });

      return {
        all: withinZoomedWindowData,
        primary: iife(() => {
          switch (primaryBatchVariable.type) {
            case "variable":
              return withinZoomedWindowData.find(
                (x) => isVariableVariant(x) && x.bv === primaryBatchVariable.bv
              );
            case "expression":
              return withinZoomedWindowData.find(
                (x) =>
                  isExpressionVariant(x) && x.id === primaryBatchVariable.id
              );
          }
        }),
        absoluteYScale,
      };
    };

    console.time("getInViewData");
    const inViewData = getInViewData();

    const potentiallyDiscontinuousPrimaryScale = iife(
      function handleXAxisScalingAsYouZoom() {
        if (!inViewData.primary) return;

        const primaryStages = inViewData.primary.stages;
        const d: number[] = [];
        const r: number[] = [];

        for (const {
          d: [start, end],
          offset,
        } of primaryStages) {
          d.push(start, end);
          r.push(xS(offset(start)), xS(offset(end)));
        }

        const potentiallyDiscontinuousScale = d3.scaleTime().domain(d).range(r);

        const x0 = potentiallyDiscontinuousScale.invert(0).getTime();
        const x1 = potentiallyDiscontinuousScale
          .invert(adjustedWidth)
          .getTime();

        d.push(x0, x1);
        r.push(0, adjustedWidth);

        potentiallyDiscontinuousScale
          .domain(d.filter((x) => x >= x0 && x <= x1).sort((a, b) => a - b))
          .range(
            r.filter((x) => x >= 0 && x <= adjustedWidth).sort((a, b) => a - b)
          );

        // Apply custom formatter or reset it
        xAxis.scale(potentiallyDiscontinuousScale);

        // Let d3 decide which ticks to show
        xAxis.tickValues(null);

        // decide custom ticks if necessary
        xAxisMode.series &&
          iife(function handleTickValues() {
            const something = potentiallyDiscontinuousScale
              .ticks(8)
              .map((x) =>
                DateTime.fromJSDate(x)
                  .setZone(timezone, { keepLocalTime: true })
                  .toMillis()
              );

            const diffFromUtcServerBrowser =
              DateTime.now().offset - DateTime.now().setZone(timezone).offset;

            diffFromUtcServerBrowser !== 0 &&
              iife(() => {
                const [first, second] = something;
                if (!first || !second) return;
                const last = something.at(-1);
                if (!last) throw new Error("impossible");

                const diff = second - first;

                let val = DateTime.fromMillis(first).minus({
                  milliseconds: diff,
                });

                // keep going backwards
                while (val.toMillis() >= x0) {
                  // but only add it if its actually in bounds
                  val.toMillis() <= x1 && something.push(val.toMillis());
                  val = val.minus({ milliseconds: diff });
                }

                val = DateTime.fromMillis(last).plus({
                  milliseconds: diff,
                });

                // keep going forwards
                while (val.toMillis() <= x1) {
                  // but only add it if its actually in bounds
                  val.toMillis() >= x0 && something.push(val.toMillis());
                  val = val.plus({ milliseconds: diff });
                }
              });

            // shifting of timezones have produced out of bounds ticks (most likely)
            const out = something.filter((x) => x >= x0 && x <= x1);

            xAxis.tickValues(out);
          });

        xAxisSelection.call(xAxis);
        xAxisSelection.attr("font-size", axesFontSize);

        return potentiallyDiscontinuousScale;
      }
    );

    console.timeEnd("getInViewData");

    if (interactivity && interactivity.notifyGlobalRange) {
      if (!inViewData.absoluteYScale) {
        /**
         * Tell the consumer that nothing is in view
         */
        interactivity.notifyGlobalRange(undefined);
      } else {
        // if the absolute Y Scale exists, then at least 1 relative y scale exists too
        const tuples = inViewData.all.map(
          (x): [string, [number, number] | undefined] =>
            [
              getBvOrId(x),
              x.relativeScale && getDomainFromScale(x.relativeScale),
            ] as const
        );

        /**
         * If the key exists, that means it is in view in terms of X,
         * but if the value is undefined then that means it is not in
         * view because of y clamps.
         */
        const perBv = Object.fromEntries(tuples);

        if (tuples.length === 0) {
          // if the absolute Y Scale exists, then at least 1 relative y scale exists too
          throw new Error("impossible 7");
        }

        const [min, max] = getDomainFromScale(inViewData.absoluteYScale);

        /**
         * Tell the consumer we're about to draw stuff and here are
         * the new ranges.
         */
        interactivity.notifyGlobalRange({
          min,
          max,
          perBv,
        });
      }
    }

    /**
     * After we notify the consumer of the new ranges, pad
     * all the ranges so that they're not right at the top and
     * bottom edges of the chart.
     *
     * This is because the corners of lines can cause it to overflow
     * and seem as though it goes over the top and bottom, even tho
     * from and x and y perspective, it does not.
     *
     * This can get worse or better depending on how thick the
     * line width is, so adjust the padding as needed
     */
    for (const { relativeScale } of inViewData.all) {
      if (!relativeScale) continue;
      relativeScale.domain(
        paddedYTopBottom(relativeScale.domain() as [number, number])
      );
    }
    if (inViewData.absoluteYScale) {
      inViewData.absoluteYScale.domain(
        paddedYTopBottom(inViewData.absoluteYScale.domain() as [number, number])
      );
    }

    const setupStageSeparatorContext = () => {
      const stageSepContext = hoverAgnosticCanvas.getContext("2d");
      if (!stageSepContext) throw new Error("impossible 8");

      stageSepContext.clearRect(
        0,
        0,
        hoverAgnosticCanvas.width,
        hoverAgnosticCanvas.height
      );

      return stageSepContext;
    };

    const beginStageSeparatorsPath = (ctx: CanvasRenderingContext2D) => {
      ctx.beginPath();
      ctx.strokeStyle = "grey"; // TODO choose new color
      ctx.lineWidth = LINE_WIDTH_FOR_STAGE_SEPARATOR;
    };

    const drawSingleStageSeparator = (
      ctx: CanvasRenderingContext2D,
      canvasLeft: number,
      canvasRight: number
    ) => {
      let y = PADDING.top + LINE_WIDTH_FOR_STAGE_SEPARATOR / 2;

      while (y < adjustedHeight + PADDING.top) {
        ctx.moveTo(canvasLeft, y);
        ctx.lineTo(canvasRight, y);
        y += 20;
      }
    };

    const drawDaBarsOfThisColorArc = (
      ctx: CanvasRenderingContext2D,
      thisSegmentIsTheTargetedColor: (segmentDa: Points["d"]) => boolean,
      targetColor: string
    ) => {
      ctx.beginPath();
      ctx.fillStyle = targetColor;

      for (const bv of inViewData.all) {
        const daBarLevel = bv.daBarsLevel;
        if (daBarLevel === undefined) continue; // anomaly coloration is off
        const yTop = getTopOfDaBarPosition(DA_BAR_HEIGHT, daBarLevel);

        for (const stage of bv.stages) {
          for (const segment of stage.ptsPartitioned) {
            const firstPoint = segment.pts[0];

            if (!thisSegmentIsTheTargetedColor(segment.d)) continue;
            const lastPoint = segment.pts[segment.pts.length - 1] ?? firstPoint;

            /**
             * Every point in this segment is the same color,
             * so we just grab the first and last points to
             * figure out how long this rectangle should be.
             */
            const from = stage.offset(firstPoint.t);
            const to = stage.offset(lastPoint.t);

            /**
             * Not every point in the segment is guaranteed to be in view,
             * the segment is only at least partially in view.
             */
            const fromPos = xS(Math.max(from, zoomedWindow[0])) + PADDING.left;
            const toPos = xS(Math.min(to, zoomedWindow[1])) + PADDING.left;

            const width = toPos - fromPos;

            ctx.rect(fromPos, yTop, width, DA_BAR_HEIGHT);
          }
        }
      }
      ctx.fill();
    };

    const drawAllDaBarsDRA = (ctx: CanvasRenderingContext2D) => {
      if (!daDrawDataForDRA) throw new Error("must be defined in this case");

      const midPoint = ([left, right]: [number, number]) => (left + right) / 2;

      /**
       * This block handles drawing the DA bar as expanded connected dots
       * for the primary batch variable.
       */
      if (shouldDrawExpandedDa) {
        ctx.beginPath();
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;

        const interper = d3.scaleLinear();

        for (let i = 0; i < daDrawDataForDRA.length; i++) {
          const {
            bounds,
            d,
            daBarLevel,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          } = daDrawDataForDRA[i]!;

          if (daBarLevel !== 0) throw new Error("impossible 9");

          // draw the circle at 12PM (this actually comes out to 1 millisecond before 12PM, but that's cool)
          const mid = midPoint(bounds);

          if (mid < zoomedWindow[0]) {
            // is the next point in view?
            const nextPoint = daDrawDataForDRA[i + 1];
            if (!nextPoint) continue;
            const nextMid = midPoint(nextPoint.bounds);

            const nextInView = nextMid >= zoomedWindow[0];
            if (!nextInView) continue;

            const anomalyInterp =
              d === nextPoint.d
                ? d
                : interper.domain([mid, nextMid]).range([d, nextPoint.d])(
                    zoomedWindow[0]
                  );
            ctx.moveTo(PADDING.left, anomalyLevelScale(anomalyInterp));
            continue;
          } else if (mid > zoomedWindow[1]) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const prevPoint = daDrawDataForDRA[i - 1]!;
            const prevMid = midPoint(prevPoint.bounds);
            const anomalyInterp =
              d === prevPoint.d
                ? d
                : interper.domain([mid, prevMid]).range([d, prevPoint.d])(
                    zoomedWindow[1]
                  );
            ctx.lineTo(
              xS(zoomedWindow[1]) + PADDING.left,
              anomalyLevelScale(anomalyInterp)
            );

            break;
          }

          // this point is in view
          if (i === 0) {
            ctx.moveTo(xS(mid) + PADDING.left, anomalyLevelScale(d));
          } else ctx.lineTo(xS(mid) + PADDING.left, anomalyLevelScale(d));
        }

        ctx.stroke();

        const circleRadius = iife(() => {
          const numMsDay = 1000 * 60 * 60 * 24;
          const dur = zoomedWindow[1] - zoomedWindow[0];
          const days = Math.ceil(dur / numMsDay);
          if (days <= 60) return 14;
          if (days <= 100) return 11;
          if (days <= 180) return 8;
          if (days <= 300) return 6;
          return 4;
        });

        for (const {
          color: targetColor,
          d: targetDa,
        } of colorAndAssociatedAnomLevel) {
          ctx.beginPath();
          ctx.fillStyle = targetColor;

          for (const { d, bounds, shutdownOccurred } of daDrawDataForDRA) {
            if (shutdownOccurred) continue;

            const mid = midPoint(bounds);
            if (mid < zoomedWindow[0]) continue;
            if (mid > zoomedWindow[1]) break;
            if (d !== targetDa) continue;

            ctx.arc(
              xS(mid) + PADDING.left,
              anomalyLevelScale(d),
              circleRadius,
              0,
              Math.PI * 2
            );
          }
          ctx.fill();
        }

        for (const {
          color: targetColor,
          d: targetDa,
          shutdownColor,
        } of colorAndAssociatedAnomLevel) {
          ctx.beginPath();
          ctx.fillStyle = shutdownColor;

          for (const { d, bounds, shutdownOccurred } of daDrawDataForDRA) {
            if (!shutdownOccurred) continue;

            const mid = midPoint(bounds);
            if (mid < zoomedWindow[0]) continue;
            if (mid > zoomedWindow[1]) break;
            if (d !== targetDa) continue;

            ctx.arc(
              xS(mid) + PADDING.left,
              anomalyLevelScale(d),
              circleRadius,
              0,
              Math.PI * 2
            );
          }
          ctx.fill();
        }

        ctx.beginPath();
        ctx.fillStyle = "white";
        ctx.fillRect(adjustedWidth + PADDING.left, 0, 25, PADDING.top);

        return; // we're done drawing the expanded DA bars, the rest of the code is for multiple collapsed
      }

      /**
       * Must use pre-filtered data because
       * DRA is concerned with the data for a
       * day even if it's half filtered out
       * (user zoomed half the day away).
       */
      const prefilteredData = data;

      /**
       * Draw green DA bars everywhere, then draw individual
       * colors on top of it
       */
      ctx.beginPath();
      if (viewMode === YAxisMode.Swimlane) {
        for (let i = 0; i < inViewData.all.length; i++) {
          const { daBars: shouldDrawDaBars } = inViewData.all[i]!;
          if (!shouldDrawDaBars) continue;

          const top = i * (adjustedHeight / inViewData.all.length); // there is no padding top?

          ctx.rect(
            PADDING.left,
            top + PADDING.top,
            adjustedWidth,
            DA_BAR_HEIGHT
          );
        }
      } else {
        for (const bv of prefilteredData) {
          const daBarLevel = bv.daBarsLevel;
          if (daBarLevel === undefined) continue; // anomaly coloration is off
          const yTop = getTopOfDaBarPosition(DA_BAR_HEIGHT, daBarLevel);
          const fromPos = xS(zoomedWindow[0]) + PADDING.left;
          const toPos = xS(zoomedWindow[1]) + PADDING.left;

          const width = toPos - fromPos;

          ctx.rect(fromPos, yTop, width, DA_BAR_HEIGHT);
        }
      }
      ctx.fillStyle = GREEN;
      ctx.fill();

      // for (const { color, data } of daDrawDataForDRA) {
      //   ctx.beginPath();
      //   ctx.fillStyle = color;
      //   for (const {
      //     bounds: [from, to],
      //     daBarLevel,
      //   } of data) {
      //     if (from > zoomedWindow[1] || to < zoomedWindow[0]) continue;
      //     const yTop = getTopOfDaBarPosition(DA_BAR_HEIGHT, daBarLevel);
      //     const fromPos = xS(Math.max(from, zoomedWindow[0])) + PADDING.left;
      //     const toPos = xS(Math.min(to, zoomedWindow[1])) + PADDING.left;

      //     const width = toPos - fromPos;

      //     ctx.rect(fromPos, yTop, width, DA_BAR_HEIGHT);
      //   }
      //   ctx.fill();
      // }

      /**
       * This block handles drawing the DA bars as rectangles
       * for all the variables.
       */
      for (const { color, d: targetAnomaly } of colorAndAssociatedAnomLevel) {
        // we already drew green
        if (targetAnomaly === AnomalyLevelEnum["No Risk"]) continue;
        ctx.beginPath();
        ctx.fillStyle = color;
        for (const {
          bounds: [from, to],
          d,
          daBarLevel,
          shutdownOccurred,
        } of daDrawDataForDRA) {
          if (shutdownOccurred) continue;
          if (d !== targetAnomaly) continue;

          if (from > zoomedWindow[1] || to < zoomedWindow[0]) continue;

          const yTop =
            viewMode === YAxisMode.Swimlane
              ? iife(() => {
                  const chartHeight = adjustedHeight / inViewData.all.length;
                  const top = daBarLevel * chartHeight;
                  return top + PADDING.top; // padding.top should be 0 rn but this might change
                })
              : getTopOfDaBarPosition(DA_BAR_HEIGHT, daBarLevel);

          const fromPos = xS(Math.max(from, zoomedWindow[0]));
          const toPos = xS(Math.min(to, zoomedWindow[1]));
          const width = toPos - fromPos;

          ctx.rect(fromPos + PADDING.left, yTop, width, DA_BAR_HEIGHT);
        }
        ctx.fill();
      }

      for (const {
        d: targetAnomaly,
        shutdownColor,
      } of colorAndAssociatedAnomLevel) {
        ctx.beginPath();

        ctx.fillStyle = shutdownColor;

        for (const {
          bounds: [from, to],
          d,
          daBarLevel,
          shutdownOccurred,
        } of daDrawDataForDRA) {
          if (!shutdownOccurred) continue;
          if (d !== targetAnomaly) continue;

          if (from > zoomedWindow[1] || to < zoomedWindow[0]) continue;

          const yTop =
            viewMode === YAxisMode.Swimlane
              ? iife(() => {
                  const chartHeight = adjustedHeight / inViewData.all.length;
                  const top = daBarLevel * chartHeight;
                  return top + PADDING.top; // padding.top should be 0 rn but this might change
                })
              : getTopOfDaBarPosition(DA_BAR_HEIGHT, daBarLevel);
          const fromPos = xS(Math.max(from, zoomedWindow[0]));
          const toPos = xS(Math.min(to, zoomedWindow[1]));
          const width = toPos - fromPos;

          ctx.rect(fromPos + PADDING.left, yTop, width, DA_BAR_HEIGHT);
        }
        ctx.fill();
      }
    };

    (typeof requestAnimationFrame !== "undefined"
      ? requestAnimationFrame
      : iife)(function drawThingsThatDontNeedToRedrawDuringHover() {
      const stageSepContext = setupStageSeparatorContext();

      beginStageSeparatorsPath(stageSepContext);
      switch (overlapMode) {
        case BatchViewMode.series:
          if (app.type === "DRA") {
            break;
          }
          for (const d of inViewData.all) {
            /**
             * It's important to remember that they're filtered,
             * so the stages that exist here are only the ones
             * you can see, so you need to add stage separators
             * before the first one (potentially) and after the
             * last one (potentially).
             */
            const filteredStages = d.stages;
            for (let i = 0; i < filteredStages.length; i++) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const stage = filteredStages[i]!;
              if (!rangeOverlaps(zoomedWindow, stage.d)) continue;

              /**
               * In series mode you can assume that the next stage is
               * different
               */
              const nextDifferentStage = filteredStages[i + 1];

              // do we need to draw a stage separator before this stage?
              const isFirstFilteredStage = i === 0;
              const shouldCheckIfWeNeedToDrawSeparatorBeforeThis =
                isFirstFilteredStage && d.d[0] !== stage.d[0];
              if (shouldCheckIfWeNeedToDrawSeparatorBeforeThis) {
                // check if you need to draw one before it
                if (stage.d[0] > zoomedWindow[0]) {
                  const from = zoomedWindow[0];
                  const to = stage.d[0];

                  const fromPos = xS(from) + PADDING.left;
                  const toPos = xS(to) + PADDING.left;

                  drawSingleStageSeparator(stageSepContext, fromPos, toPos);
                }
              }

              if (nextDifferentStage) {
                const lastSegmentOfThisStage =
                  stage.ptsPartitioned[stage.ptsPartitioned.length - 1];
                if (!lastSegmentOfThisStage) throw new Error("impossible 10");
                const lastPointOfThisStage =
                  lastSegmentOfThisStage.pts[
                    lastSegmentOfThisStage.pts.length - 1
                  ] ?? lastSegmentOfThisStage.pts[0];

                const firstPointOfNextStage =
                  nextDifferentStage.ptsPartitioned[0].pts[0];

                const from = lastPointOfThisStage.t;
                const to = firstPointOfNextStage.t;

                const fromPos = xS(from) + PADDING.left;
                const toPos = xS(to) + PADDING.left;

                drawSingleStageSeparator(stageSepContext, fromPos, toPos);
              } else {
                // this is the last stage
                const shouldCheckIfWeNeedToDrawSeparatorAfterThis =
                  d.d[1] !== stage.d[1];
                if (shouldCheckIfWeNeedToDrawSeparatorAfterThis) {
                  // check if you need to draw one after it
                  if (stage.d[1] < zoomedWindow[1]) {
                    const from = stage.d[1];
                    const to = zoomedWindow[1];

                    const fromPos = xS(from) + PADDING.left;
                    const toPos = xS(to) + PADDING.left;

                    drawSingleStageSeparator(stageSepContext, fromPos, toPos);
                  }
                }
              }
            }
          }

          stageSepContext.stroke();

          break;
        case BatchViewMode.ParallelStage:
          throw new Error("TODO FIX THIS");
          // if (!alignByStageData) throw new Error("must be defined");
          // const stages = alignByStageData.uniqueStageDeps;

          // for (const stageSequence of stages) {
          //   /**
          //    * Start at 1 to draw the separator at the beginning of
          //    * each stage, except the first one.
          //    */
          //   for (let i = 1; i < stageSequence.length; i++) {
          //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          //     const stageId = stageSequence[i]!;
          //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          //     const separatorEnd = alignByStageData.stageStarts[stageId]!;
          //     const separatorStart = separatorEnd - NUM_MS_FOR_STAGE_SEPARATORS;

          //     if (!rangeOverlaps(zoomedWindow, [separatorStart, separatorEnd]))
          //       continue;

          //     const from = Math.max(separatorStart, zoomedWindow[0]);
          //     const to = Math.min(separatorEnd, zoomedWindow[1]);

          //     const fromPos = xS(from) + PADDING.left;
          //     const toPos = xS(to) + PADDING.left;

          //     drawSingleStageSeparator(stageSepContext, fromPos, toPos);
          //   }
          // }

          // stageSepContext.stroke();
          break;
        case BatchViewMode.ParallelStart:
          break;
        default:
          const _: never = overlapMode;
          throw new Error("unreachable");
      }

      if (!noDaBars) {
        switch (app.type) {
          case "ARC":
            // draw da bars for all modes
            drawDaBarsOfThisColorArc(
              stageSepContext,
              (da) => da === AnomalyLevelEnum["No Risk"],
              GREEN
            );
            drawDaBarsOfThisColorArc(
              stageSepContext,
              (da) => da === AnomalyLevelEnum["Low Risk"],
              YELLOW
            );
            drawDaBarsOfThisColorArc(
              stageSepContext,
              (da) => da === AnomalyLevelEnum["Medium Risk"],
              ORANGE
            );
            drawDaBarsOfThisColorArc(
              stageSepContext,
              (da) => da === AnomalyLevelEnum["High Risk"],
              RED
            );
            break;
          case "DRA":
            drawAllDaBarsDRA(stageSepContext);
            break;
          default:
            throw new Error("unreachable");
        }
      }

      if (
        xAxisMode.series &&
        redBlockStart !== undefined &&
        inViewData.primary &&
        rangeOverlaps(zoomedWindow, [redBlockStart, Infinity])
      ) {
        stageSepContext.beginPath();

        let fromT = undefined as number | undefined;

        const firstStage = inViewData.primary.stages[0];
        const lastStage =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          inViewData.primary.stages[inViewData.primary.stages.length - 1]!;

        const left = firstStage.d[0];
        const right = lastStage.d[1];

        if (redBlockStart < left) {
          fromT = redBlockStart;
        } else if (redBlockStart > right) {
          fromT = undefined;
        } else {
          for (let i = 0; i < inViewData.primary.stages.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const s = inViewData.primary.stages[i]!;

            const left = s.d[0];
            const right = s.d[1];

            const isInThisStage =
              redBlockStart >= left && redBlockStart <= right;

            if (isInThisStage) {
              fromT = s.offset(redBlockStart);
              break;
            }

            const next = inViewData.primary.stages[i + 1];
            if (next) {
              const isInSpaceBetween =
                redBlockStart < next.d[0] && right < redBlockStart;
              if (isInSpaceBetween) {
                fromT = next.offset(next.d[0]);
                break;
              }
            }
          }
        }

        if (fromT !== undefined) {
          const from = xS(Math.max(fromT, zoomedWindow[0]));
          const to = xS(Math.min(Infinity, zoomedWindow[1]));
          if (viewMode === YAxisMode.Swimlane) {
            if (inViewData.primary && inViewData.primary.relativeScale) {
              const r = getRangeFromScale(inViewData.primary.relativeScale);

              const height = r[0] - r[1]; // the first number is bigger due to canvas coordinates

              // if the lane exists
              stageSepContext.rect(
                from + PADDING.left,
                PADDING.top + r[1],
                to - from,
                height
              );
              stageSepContext.fillStyle = RED_WITH_OPACITY;
              stageSepContext.fill();
            }
          } else {
            stageSepContext.rect(
              from + PADDING.left,
              PADDING.top,
              to - from,
              adjustedHeight
            );
            stageSepContext.fillStyle = RED_WITH_OPACITY;
            stageSepContext.fill();
          }
        }
      }
      /**
       * Used to position comment domain and brush handles
       */
      if (interactivity) {
        if (potentiallyDiscontinuousPrimaryScale) {
          interactivity.setXScale(
            iife(() => {
              const scale: MyD3Scale = (t: number) => {
                const left = potentiallyDiscontinuousPrimaryScale(t);
                if (left < 0 || left > adjustedWidth) return undefined;

                return (left + PADDING.left) / width;
              };
              scale.invert = (percentLeft: number) => {
                if (
                  percentLeft < PADDING.left / width ||
                  percentLeft > (PADDING.left + adjustedWidth) / width
                )
                  return undefined;
                return potentiallyDiscontinuousPrimaryScale
                  .invert(percentLeft * width - PADDING.left)
                  .getTime();
              };

              scale.range = () =>
                [
                  PADDING.left / width,
                  (PADDING.left + adjustedWidth) / width,
                ] as [number, number];

              scale.domain = () =>
                getDomainFromScale(potentiallyDiscontinuousPrimaryScale);
              return scale;
            })
          );
        } else {
          interactivity.setXScale(undefined);
        }
      }

      iife(function drawCommentBrushBlock() {
        if (!potentiallyDiscontinuousPrimaryScale) return;
        const brushing = interactivity?.brushing;
        if (!brushing) return;

        // draw comment brush block
        const {
          get: getBrushRange,
          setRedrawer,
          canvas: brushCanvas,
        } = brushing;

        const drawSorted = (
          r: [number, number | undefined],
          ctxModifier: (c: CanvasRenderingContext2D) => void
        ) => {
          brushCanvas.width = width;
          brushCanvas.height = height;
          const ctx = brushCanvas.getContext("2d");
          if (!ctx) throw new Error("impossible 11");
          ctx.beginPath();
          ctxModifier(ctx);
          const [b0, b1] = r;

          if (
            rangeOverlaps(
              getDomainFromScale(potentiallyDiscontinuousPrimaryScale),
              [r[0], r[1] ?? Infinity]
            )
          ) {
            const x0 = Math.max(potentiallyDiscontinuousPrimaryScale(b0), 0);
            const x1 =
              b1 === undefined
                ? adjustedWidth
                : Math.min(
                    potentiallyDiscontinuousPrimaryScale(b1),
                    adjustedWidth
                  );

            const left = x0 + PADDING.left;
            const right = x1 + PADDING.left;

            ctx.fillRect(left, PADDING.top, right - left, adjustedHeight);
          }
        };

        const draw = (r: [number, number | undefined]) => {
          if (r[1] === undefined) {
            drawSorted([r[0], undefined], (ctx) => {
              ctx.fillStyle = "rgba(245, 191, 178, 0.48)";
            });
          } else {
            drawSorted(
              [r[0], r[1]].sort((a, b) => a - b) as [number, number],
              (ctx) => {
                ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
              }
            );
          }
        };
        draw(getBrushRange());
        setRedrawer(draw);
      });
    });

    /**
     * This cache saves work done in groupByHighlightData
     * every time `redrawTrendsOnHighlightChange` is called.
     * It's called pretty frequently, on every hover event.
     */
    const highlightGroupingCache: Record<string, HighlightToData> = {};

    const groupByHighlightData = (
      theHoveredBv: TrendLineVariant | undefined
    ): HighlightToData => {
      // const spotlight = interactivity?.spotlight ?? false;

      const UNDEFINED_KEY = "undefined";
      const cacheKey = iife(() => {
        // just need a unique key for each trend line
        if (!theHoveredBv) return UNDEFINED_KEY;
        switch (theHoveredBv.type) {
          case "expression":
            return `${theHoveredBv.type}-${theHoveredBv.id}`;
          case "variable":
            return `${theHoveredBv.type}-${theHoveredBv.bv}`;
        }
      });
      const cached = highlightGroupingCache[cacheKey];

      if (cached) return cached;

      const out: HighlightToData = {};

      for (const x of inViewData.all) {
        const thisIsTheHoveredLine = theHoveredBv
          ? trendDataMatches(x, theHoveredBv)
          : false;

        const thisIsThePinnedLine = pinnedBatchVariable
          ? trendDataMatches(x, pinnedBatchVariable)
          : false;

        const shouldBeBold =
          (thisIsTheHoveredLine || thisIsThePinnedLine) &&
          inViewData.all.length > 1;

        if (shouldBeBold) {
          // get the bold object or set it
          const obj = out[LineWidth.Bold] ?? (out[LineWidth.Bold] = {});

          const thisIsThePrimaryLine = trendDataMatches(
            x,
            primaryBatchVariable
          );

          if (thisIsThePrimaryLine) {
            obj.primaryData = x;
            continue;
          }

          /**
           * TODO, make this easier to understand and less hacky.
           *
           * stillUseColorForAnomalyColorationOff was a hack added for DRA
           * because this chart was developed for Batch first.
           *
           * And in Batch, if the anom boolean is off, we draw the line
           * with grey data and ignore its color. But in DRA, we want to
           * draw the line with its color (there is no such thing as grey
           * lines, every line has a color).
           */
          if (x.anomPatches || stillUseColorForAnomalyColorationOff) {
            obj.coloredData ? obj.coloredData.push(x) : (obj.coloredData = [x]);
            continue;
          }

          obj.greyData ? obj.greyData.push(x) : (obj.greyData = [x]);
          continue;
        }

        // it should be regular thickness

        // get the regular object or set it
        const obj = out[LineWidth.Regular] ?? (out[LineWidth.Regular] = {});
        const thisIsThePrimaryLine = trendDataMatches(x, primaryBatchVariable);
        if (thisIsThePrimaryLine) {
          obj.primaryData = x;
          continue;
        }

        /**
         * stillUseColorForAnomalyColorationOff is sort of an override
         */
        if (x.anomPatches || stillUseColorForAnomalyColorationOff) {
          obj.coloredData ? obj.coloredData.push(x) : (obj.coloredData = [x]);
          continue;
        }
        obj.greyData ? obj.greyData.push(x) : (obj.greyData = [x]);
      }

      // cache the result
      highlightGroupingCache[cacheKey] = out;
      return out;
    };

    /**
     * We save this function because there are some actions
     * that only require a redraw of the canvas due to the
     * highlighted line changing (which changes the opacity
     * of many lines).
     *
     * But we don't want to do all of the work above, such
     * as filtering of the data.
     *
     * So this `singleH` parameter can change by the user
     * hovering over the chart, which obviously does not
     * change the window (or what data can be seen). So call
     * this function in those cases to save work.
     *
     * This also provides a way for React to call this function
     * when other actions change the highlighted line. Like
     * hovering over respective batch cards.
     *
     * Otherwise, we would need to pass in the highlighted line
     * as a piece of state, causing each new batch card hover
     * to cause a re-filtering of the data (wasted work).
     *
     * I did do it both ways and this way is noticeably faster.
     */
    const redrawTrendsOnHighlightChange_onlyCallDirectlyOnce = (
      /**
       * Undefined because you can hover in between stage separators
       * where there is no intersection with any line.
       */
      hoveredLineData: HoveredLine | undefined
    ) => {
      const groupedByLineThickness = groupByHighlightData(hoveredLineData);

      const drawTheseSegmentsForWholeBatchVariable = (
        d: TimeseriesForBvWithOwnRelativeScale,
        ctx: CanvasRenderingContext2D,
        yScaleToUse: d3.ScaleLinear<number, number, never>,
        shouldDrawStage: ShouldDrawStage,
        shouldDrawSegment: ShouldDrawSegment,
        padding: { top: number; left: number },
        zoomedWindow: [number, number]
        // shouldDrawStars?: true
      ) => {
        const [rangeBottom, rangeTop] = getRangeFromScale(yScaleToUse); // range bottom is greater than range top because of canvas coordinates

        /**
         * There is a possibility that each "d" or each data belonging to a batch variable
         * hangs off the left edge and right edge of the canvas. But we keep this boolean
         * because after we check the very first segment, we no longer need to check any
         * other segments. Saving work. This is a micro-optimization.
         */
        let firstSegmentDrawn = false;

        const lastStageIdx = d.stages.length - 1;
        for (let sIdx = 0; sIdx < d.stages.length; sIdx++) {
          const s = d.stages[sIdx] as (typeof d.stages)[number];

          if (!shouldDrawStage(s)) continue;

          const isLastStage = sIdx === lastStageIdx;
          const lastSegmentIdx = s.ptsPartitioned.length - 1;

          // draw each segment if it matches the condition
          for (let segIdx = 0; segIdx < s.ptsPartitioned.length; segIdx++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const segment = s.ptsPartitioned[segIdx]!;
            if (!shouldDrawSegment(segment)) continue;

            const isLastSegment = segIdx === lastSegmentIdx;

            const firstPointOfSegment = segment.pts[0];

            const outOfBoundsAwareDrawer = new OutOfYBoundsAwareSegmentDrawer(
              ctx,
              rangeTop + padding.top,
              rangeBottom + padding.top
            );

            if (!firstSegmentDrawn) {
              firstSegmentDrawn = true;

              const firstInBoundPointIdx = segment.pts.findIndex(
                (p) => s.offset(p.t) >= zoomedWindow[0]
              );
              if (firstInBoundPointIdx === -1) throw new Error("impossible 12");

              const wholeSegmentInView = firstInBoundPointIdx === 0;

              if (wholeSegmentInView) {
                // the whole segment is in view, no special logic needed
              } else {
                // firstInBoundPointIdx should be >=1
                const prevOutOfBoundsPoint = segment.pts[
                  firstInBoundPointIdx - 1
                ] as (typeof segment)["pts"][number];
                const point = segment.pts[
                  firstInBoundPointIdx
                ] as (typeof segment)["pts"][number];

                // find the slope to artifically create a point that is x = window[0]
                // so the line doesn't hang off the left edge of the canvas
                // this is linear interpolation

                const fakeV = d3
                  .scaleLinear()
                  .domain([s.offset(prevOutOfBoundsPoint.t), s.offset(point.t)])
                  .range([prevOutOfBoundsPoint.v, point.v])(zoomedWindow[0]);

                outOfBoundsAwareDrawer.point(
                  padding.left, // this the same as `xS(window[0]) + PADDING.left`
                  yScaleToUse(fakeV) + padding.top
                );

                if (isLastSegment && isLastStage) {
                  const lastPointInBoundsIdx = segment.pts.findLastIndex(
                    (p) => s.offset(p.t) <= zoomedWindow[1]
                  );

                  if (lastPointInBoundsIdx === -1)
                    throw new Error("impossible 13");

                  if (lastPointInBoundsIdx === segment.pts.length - 1) {
                    // the whole segment is in view, no special logic needed
                  } else {
                    const lastPointInBounds = segment.pts[
                      lastPointInBoundsIdx
                    ] as (typeof segment)["pts"][number];
                    const nextOutOfBoundsPoint = segment.pts[
                      lastPointInBoundsIdx + 1
                    ] as (typeof segment)["pts"][number];

                    for (
                      let i = firstInBoundPointIdx;
                      i < lastPointInBoundsIdx + 1;
                      i++
                    ) {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      const point = segment.pts[i]!;
                      outOfBoundsAwareDrawer.point(
                        xS(s.offset(point.t)) + padding.left,
                        yScaleToUse(point.v) + padding.top
                      );
                    }

                    // artifically create a point that is x = window[1]
                    // so the line doesn't hang off the right edge of the canvas
                    // this is linear interpolation
                    const fakeV = d3
                      .scaleLinear()
                      .domain([
                        s.offset(lastPointInBounds.t),
                        s.offset(nextOutOfBoundsPoint.t),
                      ])
                      .range([lastPointInBounds.v, nextOutOfBoundsPoint.v])(
                      zoomedWindow[1]
                    );

                    outOfBoundsAwareDrawer.point(
                      adjustedWidth + padding.left, // this the same as `xS(window[1]) + PADDING.left`
                      yScaleToUse(fakeV) + padding.top
                    );
                    continue;
                  }
                }

                for (
                  let i = firstInBoundPointIdx;
                  i < segment.pts.length;
                  i++
                ) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const point = segment.pts[i]!;
                  outOfBoundsAwareDrawer.point(
                    xS(s.offset(point.t)) + padding.left,
                    yScaleToUse(point.v) + padding.top
                  );
                }
                continue;
              }
            }

            outOfBoundsAwareDrawer.point(
              xS(s.offset(firstPointOfSegment.t)) + padding.left,
              yScaleToUse(firstPointOfSegment.v) + padding.top
            );

            if (isLastSegment && isLastStage) {
              const lastPointInBoundsIdx = segment.pts.findLastIndex(
                (p) => s.offset(p.t) <= zoomedWindow[1]
              );

              if (lastPointInBoundsIdx === -1) throw new Error("impossible 14");

              if (lastPointInBoundsIdx === segment.pts.length - 1) {
                // the whole segment is in view, no special logic needed
              } else {
                const lastPointInBounds = segment.pts[
                  lastPointInBoundsIdx
                ] as (typeof segment)["pts"][number];
                const nextOutOfBoundsPoint = segment.pts[
                  lastPointInBoundsIdx + 1
                ] as (typeof segment)["pts"][number];

                for (let i = 1; i < lastPointInBoundsIdx + 1; i++) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const point = segment.pts[i]!;
                  outOfBoundsAwareDrawer.point(
                    xS(s.offset(point.t)) + padding.left,
                    yScaleToUse(point.v) + padding.top
                  );
                }

                // artifically create a point that is x = window[1]
                // so the line doesn't hang off the right edge of the canvas
                // this is linear interpolation
                const fakeV = d3
                  .scaleLinear()
                  .domain([
                    s.offset(lastPointInBounds.t),
                    s.offset(nextOutOfBoundsPoint.t),
                  ])
                  .range([lastPointInBounds.v, nextOutOfBoundsPoint.v])(
                  zoomedWindow[1]
                );

                outOfBoundsAwareDrawer.point(
                  adjustedWidth + padding.left, // this the same as `xS(window[1]) + PADDING.left`
                  yScaleToUse(fakeV) + padding.top
                );
                continue;
              }
            }

            for (let i = 1; i < segment.pts.length; i++) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const point = segment.pts[i]!;
              outOfBoundsAwareDrawer.point(
                xS(s.offset(point.t)) + padding.left,
                yScaleToUse(point.v) + padding.top
              );
            }
          }
        }
      };

      if (!trendContext || !darkTrendContext) throw new Error("impossible 15");

      // clear the canvas
      (typeof requestAnimationFrame !== "undefined"
        ? requestAnimationFrame
        : iife)(() => {
        trendContext.reset && trendContext.reset();
        trendContext.clearRect(
          0,
          0,
          hoverAffectedCanvas.width,
          hoverAffectedCanvas.height
        );

        darkTrendContext.reset && darkTrendContext.reset();
        darkTrendContext.clearRect(
          0,
          0,
          darkTrendCanvas.width,
          darkTrendCanvas.height
        );

        const drawSegmentsWithThisThickness = (lineWidth: LineWidth) => {
          const lw = getTrendLineWidth(lineWidth);
          trendContext.lineWidth = lw;
          darkTrendContext.lineWidth = lw * (anomalyPatchesThicknessScale ?? 1);

          const LIGHT_OPACITY_FACTOR = 0.1;

          /**
           * Use this opacity factor if we're not in transparency mode.
           *
           * Transparency mode currently only applies to the primary
           * variable anyway.
           *
           * TODO: refactor this so we don't have this edge case in the code
           * because it's not very clear what's going on. I just wanted
           * to finish up this feature.
           */
          const opacityFactorForNonTransparencyMode = iife(
            (): 1 | typeof LIGHT_OPACITY_FACTOR => {
              switch (lineWidth) {
                case LineWidth.Regular:
                  // there is a hovered line and we are in spotlight mode
                  const regularLinesShouldHaveLowerOpacity =
                    hoveredLineData && interactivity?.spotlight;
                  return regularLinesShouldHaveLowerOpacity
                    ? LIGHT_OPACITY_FACTOR
                    : 1;
                case LineWidth.Bold:
                  // the hovered line is always bold so it should always have full opacity
                  return 1;
                default:
                  const _: never = lineWidth;
                  throw new Error("unreachable");
              }
            }
          );

          const forLineThickness = groupedByLineThickness[lineWidth];

          if (!forLineThickness) return;

          if (forLineThickness.greyData) {
            trendContext.beginPath();

            trendContext.strokeStyle = modifyColor(GREY)(
              0,
              opacityFactorForNonTransparencyMode
            );

            const drawGreyData = (
              strokeStyle: string,
              shouldDraw: ShouldDrawStage
            ) => {
              if (!forLineThickness.greyData) return;

              trendContext.beginPath();
              for (const d of forLineThickness.greyData) {
                const yScaleToUse = iife(() => {
                  switch (viewMode) {
                    case YAxisMode.Absolute:
                      return inViewData.absoluteYScale;
                    case YAxisMode.Relative:
                      return d.relativeScale;
                    case YAxisMode.Swimlane:
                      return d.relativeScale;
                    default:
                      const _: never = viewMode;
                      throw new Error("unreachable");
                  }
                });

                if (!yScaleToUse) {
                  /**
                   * If we're in relative mode, and this batch variable doesn't have a relative scale,
                   * then it's not in view.
                   *
                   * If we're in absolute mode, and we don't have an absolute scale, then nothing
                   * is in view.
                   */
                  continue;
                }

                drawTheseSegmentsForWholeBatchVariable(
                  d,
                  trendContext,
                  yScaleToUse,
                  shouldDraw,
                  alwaysDraw,
                  PADDING,
                  zoomedWindow
                );
              }
              trendContext.strokeStyle = strokeStyle;
              trendContext.stroke();
            };

            const modifyGrey = modifyColor(GREY);
            drawGreyData(
              modifyGrey(0, opacityFactorForNonTransparencyMode),
              alwaysDraw
            );
            trendContext.stroke();
          }
          /***********************************/

          const { primaryData } = forLineThickness;
          let primaryYScaleToUse = iife(() => {
            switch (viewMode) {
              case YAxisMode.Absolute:
                return inViewData.absoluteYScale;
              case YAxisMode.Relative:
                return primaryData?.relativeScale;
              case YAxisMode.Swimlane:
                return primaryData?.relativeScale;
              default:
                const _: never = viewMode;
                throw new Error("unreachable");
            }
          });
          if (primaryData && primaryYScaleToUse) {
            const drawPrimaryData = (
              ctx: CanvasRenderingContext2D,
              shouldDrawStage: ShouldDrawStage,
              shouldDrawSegment: ShouldDrawSegment,
              color: string
            ) => {
              ctx.beginPath();
              drawTheseSegmentsForWholeBatchVariable(
                primaryData,
                ctx,
                primaryYScaleToUse,
                shouldDrawStage,
                shouldDrawSegment,
                PADDING,
                zoomedWindow
              );
              ctx.strokeStyle = color;
              ctx.stroke();
            };

            if (primaryData.anomPatches && !hackForDemo) {
              if (isModeTransparency) {
                /**
                 * If there is a stage currently being hovered on, choose that.
                 * Else choose the stage that occurs right-most
                 */
                const theHoveredStage =
                  hoveredLineData?.stage ??
                  (
                    primaryData.stages[primaryData.stages.length - 1] ??
                    primaryData.stages[0]
                  )._id;

                const matchesTheHoveredStage: ShouldDrawStage = (s) =>
                  s._id === theHoveredStage;

                // blue lines
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && matchesTheHoveredStage(s), // difference
                  segmentIsNotAnomalous,
                  modifyBlue(0, 1)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && !matchesTheHoveredStage(s), // difference
                  segmentIsNotAnomalous,
                  modifyBlue(0, LIGHT_OPACITY_FACTOR)
                );
                // purple lines
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && matchesTheHoveredStage(s), // difference
                  segmentIsAnomalous,
                  modifyPurple(0, 1)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && !matchesTheHoveredStage(s), // difference
                  segmentIsAnomalous,
                  modifyPurple(0, LIGHT_OPACITY_FACTOR)
                );
                // shutdown lines
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsShutdown(s) && matchesTheHoveredStage(s), // difference
                  alwaysDraw, // draw all segments in shutdown,
                  modifyShutdownColor(0, 1)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsShutdown(s) && !matchesTheHoveredStage(s), // difference
                  alwaysDraw, // draw all segments in shutdown,
                  modifyShutdownColor(0, LIGHT_OPACITY_FACTOR)
                );
              } else {
                drawPrimaryData(
                  trendContext,
                  stageIsNotShutdown,
                  segmentIsNotAnomalous,
                  modifyBlue(0, opacityFactorForNonTransparencyMode)
                );
                drawPrimaryData(
                  trendContext,
                  stageIsNotShutdown,
                  segmentIsAnomalous,
                  modifyPurple(0, opacityFactorForNonTransparencyMode)
                );
                drawPrimaryData(
                  trendContext,
                  stageIsShutdown,
                  alwaysDraw, // draw all segments in shutdown,
                  modifyShutdownColor(0, opacityFactorForNonTransparencyMode)
                );
              }
            } else {
              if (isModeTransparency) {
                /**
                 * If there is a stage currently being hovered on, choose that.
                 * Else choose the stage that occurs right-most
                 */
                const theHoveredStage =
                  hoveredLineData?.stage ??
                  (
                    primaryData.stages[primaryData.stages.length - 1] ??
                    primaryData.stages[0]
                  )._id;

                const matchesTheHoveredStage: ShouldDrawStage = (s) =>
                  s._id === theHoveredStage;

                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && matchesTheHoveredStage(s),
                  alwaysDraw,
                  modifyBlue(0, 1)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsNotShutdown(s) && !matchesTheHoveredStage(s),
                  alwaysDraw,
                  modifyBlue(0, LIGHT_OPACITY_FACTOR)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsShutdown(s) && matchesTheHoveredStage(s),
                  alwaysDraw, // draw all segments in shutdown
                  modifyShutdownColor(0, 1)
                );
                drawPrimaryData(
                  trendContext,
                  (s) => stageIsShutdown(s) && !matchesTheHoveredStage(s),
                  alwaysDraw, // draw all segments in shutdown
                  modifyShutdownColor(0, LIGHT_OPACITY_FACTOR)
                );
              } else {
                // dont differentiate anomaly patches
                drawPrimaryData(
                  trendContext,
                  stageIsNotShutdown,
                  alwaysDraw,
                  modifyBlue(0, opacityFactorForNonTransparencyMode)
                );
                drawPrimaryData(
                  trendContext,
                  stageIsShutdown,
                  alwaysDraw, // draw all segments in shutdown
                  modifyShutdownColor(0, opacityFactorForNonTransparencyMode)
                );
              }
            }

            if (shouldDrawColoredCirclesNextToDaBars && primaryData.daBars) {
              if (primaryData.daBarsLevel === undefined)
                throw new Error("impossible 16");

              // draw circles
              const yTop = getTopOfDaBarPosition(
                DA_BAR_HEIGHT,
                primaryData.daBarsLevel
              );

              trendContext.beginPath();
              trendContext.fillStyle = modifyColor(BLUE)(
                0,
                opacityFactorForNonTransparencyMode
              );
              trendContext.arc(
                PADDING.left + adjustedWidth + 5 + DA_BAR_HEIGHT / 2,
                yTop + DA_BAR_HEIGHT / 2,
                DA_BAR_HEIGHT / 2,
                0,
                2 * Math.PI
              );
              trendContext.fill();
            }
          }

          /***********************************/

          if (forLineThickness.coloredData) {
            for (const d of forLineThickness.coloredData) {
              const yScaleToUse = iife(() => {
                switch (viewMode) {
                  case YAxisMode.Absolute:
                    return inViewData.absoluteYScale;
                  case YAxisMode.Relative:
                    return d.relativeScale;
                  case YAxisMode.Swimlane:
                    return d.relativeScale;
                  default:
                    const _: never = viewMode;
                    throw new Error("unreachable");
                }
              });
              /**
               * If we're in relative mode, and this batch variable doesn't have a relative scale,
               * then it's not in view.
               *
               * If we're in absolute mode, and we don't have an absolute scale, then nothing
               * is in view.
               */
              if (!yScaleToUse) continue;

              const callWhileFullBrightness = () => {
                if (d.daBars) {
                  if (d.daBarsLevel === undefined)
                    throw new Error("impossible 17");
                  // draw circles
                  const yTop = getTopOfDaBarPosition(
                    DA_BAR_HEIGHT,
                    d.daBarsLevel
                  );

                  trendContext.beginPath();
                  trendContext.arc(
                    PADDING.left + adjustedWidth + 5 + DA_BAR_HEIGHT / 2,
                    yTop + DA_BAR_HEIGHT / 2,
                    DA_BAR_HEIGHT / 2,
                    0,
                    2 * Math.PI
                  );
                  trendContext.fill();
                }
              };

              const modifyThisColor = modifyColor(d.color);

              const drawColoredLine = (
                ctx: CanvasRenderingContext2D,
                shouldDrawStage: ShouldDrawStage,
                shouldDrawSegment: ShouldDrawSegment,
                color: string
              ) => {
                ctx.beginPath();
                drawTheseSegmentsForWholeBatchVariable(
                  d,
                  ctx,
                  yScaleToUse,
                  shouldDrawStage,
                  shouldDrawSegment,
                  PADDING,
                  zoomedWindow
                );
                ctx.strokeStyle = color;
                ctx.fillStyle = color;
                ctx.stroke();
              };

              const drawAnomalyPatchesADifferentColor =
                d.anomPatches && !hackForDemo;
              if (drawAnomalyPatchesADifferentColor) {
                drawColoredLine(
                  trendContext,
                  stageIsNotShutdown,
                  segmentIsNotAnomalous,
                  modifyThisColor(0, opacityFactorForNonTransparencyMode)
                );
                shouldDrawColoredCirclesNextToDaBars &&
                  callWhileFullBrightness();

                // darken for the anomalies
                drawColoredLine(
                  darkTrendContext,
                  stageIsNotShutdown,
                  segmentIsAnomalous,
                  modifyThisColor(2.2, opacityFactorForNonTransparencyMode)
                );

                // draw shutdowns
                drawColoredLine(
                  darkTrendContext,
                  stageIsShutdown,
                  alwaysDraw,
                  modifyShutdownColor(0, opacityFactorForNonTransparencyMode)
                );
              } else {
                // anomaly is off for this batch variable
                drawColoredLine(
                  trendContext,
                  stageIsNotShutdown,
                  alwaysDraw,
                  modifyThisColor(0, opacityFactorForNonTransparencyMode)
                );

                // draw shutdowns
                drawColoredLine(
                  trendContext,
                  stageIsShutdown,
                  alwaysDraw,
                  modifyShutdownColor(0, opacityFactorForNonTransparencyMode)
                );
                shouldDrawColoredCirclesNextToDaBars &&
                  callWhileFullBrightness();
              }
            }
          }

          if (slopesToDraw && primaryYScaleToUse) {
            for (const { color, slopes } of slopesToDraw) {
              trendContext.beginPath();

              for (const { extent } of slopes) {
                if (!rangeOverlaps(zoomedWindow, [extent[0].t, extent[1].t]))
                  continue;

                const sc = d3
                  .scaleLinear()
                  .domain([extent[0].t, extent[1].t])
                  .range([extent[0].v, extent[1].v]);

                const t0 = Math.max(extent[0].t, zoomedWindow[0]);
                const t1 = Math.min(extent[1].t, zoomedWindow[1]);

                const v0 = sc(t0);
                const v1 = sc(t1);

                trendContext.moveTo(
                  xS(t0) + PADDING.left,
                  primaryYScaleToUse(v0) + PADDING.top
                );

                trendContext.lineTo(
                  xS(t1) + PADDING.left,
                  primaryYScaleToUse(v1) + PADDING.top
                );
              }

              trendContext.strokeStyle = modifyColor(ANOM_COLORS[color])(
                0,
                opacityFactorForNonTransparencyMode
              );
              trendContext.lineWidth = lw * SLOPES_LW_FACTOR;
              trendContext.stroke();
            }
          }
        };

        if (anomalyRectangles && app.type === "DRA") {
          // // draw magenta boxes
          trendContext.beginPath();
          for (const x of inViewData.all) {
            if (!x.anomPatches) continue;

            for (const s of x.stages) {
              for (const seg of s.ptsPartitioned) {
                if (!seg.d) continue; // not anomalous

                const fromPos =
                  xS(Math.max(seg.pts[0].t, zoomedWindow[0])) + PADDING.left;
                const toPos =
                  xS(
                    Math.min(
                      zoomedWindow[1],
                      (seg.pts[seg.pts.length - 1] ?? seg.pts[0]).t
                    )
                  ) + PADDING.left;

                trendContext.rect(
                  fromPos,
                  PADDING.top,
                  toPos - fromPos,
                  adjustedHeight
                );
              }
            }
          }
          trendContext.fillStyle = modifyColor(PURPLE_ANOMALY_RECTS)(0, 0.7);
          trendContext.fill();
        }

        // draw grey analysis period thing
        if (analysisPeriod && rangeOverlaps(zoomedWindow, analysisPeriod)) {
          if (!xAxisMode.series) throw new Error("unsupported");

          trendContext.fillStyle = ANALYSIS_PERIOD_GREY;
          const fromPos =
            xS(Math.max(analysisPeriod[0], zoomedWindow[0])) + PADDING.left;
          const toPos =
            xS(Math.min(zoomedWindow[1], analysisPeriod[1])) + PADDING.left;
          const width = toPos - fromPos;

          if (viewMode === YAxisMode.Swimlane) {
            /**
             * This case must be handled differently
             * because there are multiple da bars
             * that could be potentially covered if
             * we just draw one big block like below.
             */
            trendContext.beginPath();
            for (const x of inViewData.all) {
              const r = x.relativeScale;
              if (!r) continue;
              const [rangeBottom, rangeTop] = getRangeFromScale(r);

              const height = rangeBottom - rangeTop;
              trendContext.rect(fromPos, rangeTop, width, height);
            }
            trendContext.fill();
          } else {
            trendContext.fillRect(fromPos, PADDING.top, width, adjustedHeight);
          }
        }

        // drawSegmentsInThisHighlightMode(Thickness.Dim);
        drawSegmentsWithThisThickness(LineWidth.Regular);
        drawSegmentsWithThisThickness(LineWidth.Bold);

        /**
         * Draw operating limit lines if they exist, but only
         * in absolute view mode.
         */
        if (viewMode === YAxisMode.Absolute && limits) {
          const scale = inViewData.absoluteYScale;
          if (!scale) throw new Error("impossible 18");
          const [minValue, maxValue] = getDomainFromScale(scale);

          trendContext.beginPath();
          trendContext.lineWidth = getTrendLineWidth(LineWidth.Regular) * 0.7;
          trendContext.setLineDash([15, 15]);
          trendContext.strokeStyle = "black";

          for (const { data } of limits) {
            for (const { end, start, value } of data) {
              if (!rangeOverlaps([start, end ?? Date.now()], zoomedWindow))
                continue;

              // is the y-value in view?
              if (value < minValue || value > maxValue) continue;

              // draw the line
              const y = scale(value) + PADDING.top;

              trendContext.moveTo(
                xS(Math.max(start, zoomedWindow[0])) + PADDING.left,
                y
              );
              trendContext.lineTo(
                xS(
                  end === null
                    ? zoomedWindow[1]
                    : Math.min(end, zoomedWindow[1])
                ) + PADDING.left,
                y
              );
            }
          }

          trendContext.stroke();
        }

        /***********************************/
      });

      switch (viewMode) {
        case YAxisMode.Swimlane:
          yAxisGroup.selectAll("*").remove();

          for (const d of inViewData.all) {
            // if a trend line is clamped in such a way it's not in view
            // it won't have a relative scale
            if (!d.relativeScale) continue;

            const yAxis = d3.axisRight(d.relativeScale);

            const shouldOnlyShow2Ticks =
              onlyMaxMinYAxes ||
              !hoveredLineData ||
              !trendDataMatches(d, hoveredLineData);
            shouldOnlyShow2Ticks &&
              yAxis.tickValues(hardcodedNumYTicks(d.relativeScale.domain()));

            const myGroup = yAxisGroup.append("g");
            myGroup.call(yAxis);
            myGroup.attr("font-size", axesFontSize);
            myGroup.selectAll("text").attr("fill", d.color);
          }

          break;
        case YAxisMode.Relative:
          yAxisGroup.selectAll("*").remove();

          const showColoredRelativeYAxisForASingleBatchVariable = (
            line: TrendLineVariant
          ) => {
            for (const d of inViewData.all) {
              if (!trendDataMatches(d, line)) continue;
              if (!d.relativeScale) break;
              // draw the y-axis
              const yAxis = d3.axisRight(d.relativeScale);
              onlyMaxMinYAxes &&
                yAxis.tickValues(hardcodedNumYTicks(d.relativeScale.domain()));

              yAxisGroup.call(yAxis);
              yAxisGroup.attr("font-size", axesFontSize);

              yAxisGroup.selectAll("text").attr("fill", d.color);
              break;
            }
          };

          if (pinnedBatchVariable)
            return showColoredRelativeYAxisForASingleBatchVariable(
              pinnedBatchVariable
            );

          if (hoveredLineData)
            return showColoredRelativeYAxisForASingleBatchVariable(
              hoveredLineData
            );

          break;
        default:
          break;
      }
    };

    let lastHighlightedLine =
      inViewData.all.length > 1
        ? interactivity?.hoveredLineSync?.get()
        : undefined;

    interactivity?.hoveredLineSync?.set(lastHighlightedLine);
    redrawTrendsOnHighlightChange_onlyCallDirectlyOnce(lastHighlightedLine);

    const redrawWithCache = (newHighlightedLine: HoveredLine | undefined) => {
      interactivity?.hoveredLineSync?.set(newHighlightedLine);

      const lineHasChanged = iife(() => {
        if (newHighlightedLine) {
          if (lastHighlightedLine) {
            return !trendDataMatches(newHighlightedLine, lastHighlightedLine);
          }

          return true; // one is defined and the other is not
        }

        if (lastHighlightedLine) {
          return true; // one is defined and the other is not
        }

        return false; // both are undefined
      });
      const stageChanged =
        newHighlightedLine?.stage !== lastHighlightedLine?.stage;
      const weCareIfHoveredStageChanged = isModeTransparency;

      if (
        lineHasChanged ||
        (weCareIfHoveredStageChanged ? stageChanged : false)
      ) {
        redrawTrendsOnHighlightChange_onlyCallDirectlyOnce(newHighlightedLine);
        lastHighlightedLine = newHighlightedLine;
      }
    };

    interactivity?.subscribeToOnHighlightRedrawer?.(redrawWithCache);

    if (viewMode === YAxisMode.Absolute && inViewData.absoluteYScale) {
      // draw the y-axis
      const yAxis = d3.axisRight(inViewData.absoluteYScale);
      onlyMaxMinYAxes &&
        yAxis.tickValues(
          hardcodedNumYTicks(inViewData.absoluteYScale.domain())
        );
      yAxisGroup.call(yAxis);
      yAxisGroup.attr("font-size", axesFontSize);
    }

    const removeHoverEffects = () => {
      hideHoverLine();
      hideHoverCircle();
      interactivity?.onHover?.(undefined);
    };

    const handleHover = (event: MouseEvent) => {
      const [x, y] = d3.pointer(event);

      // out of bounds
      if (
        x < PADDING.left ||
        x > adjustedWidth + PADDING.left ||
        y < PADDING.top ||
        y > adjustedHeight + PADDING.top
      ) {
        removeHoverEffects();
        redrawWithCache(undefined);
        dayHoverDrawer.clear();
        return;
      }

      // convert the x value into a time
      // Note this time is relative to our xScale, which is different in different modes
      const manipulatedTime = xS.invert(x - PADDING.left).getTime();

      if (app.type === "DRA") {
        if (!xAxisMode.series) throw new Error("unsupported");
        dayHoverDrawer.setHoverTime(manipulatedTime).draw(xS);
      }
      hoverLine.style("opacity", 1);
      hoverLine.attr(...translate(x, PADDING.top));

      const onHover = interactivity?.onHover;
      if (!onHover) return;

      // find the y-values at this time for each batch variable
      const findPointIntersectionBatchVariable = (
        x: TimeseriesForBvWithOwnRelativeScale
      ): HoverPoint | undefined => {
        const stageMaybeIn = binarySearchStage(
          x.stages,
          manipulatedTime,
          (s, t) => s.offset(t)
        );
        if (!stageMaybeIn) return undefined;

        const segment = binarySearchSegment(
          stageMaybeIn.ptsPartitioned,
          manipulatedTime,
          (t) => stageMaybeIn.offset(t)
        );
        if (!segment) return undefined;

        const point = binarySearchPointCannotFail(
          segment,
          manipulatedTime,
          (t) => stageMaybeIn.offset(t)
        );

        const { t: interpolatedT, v } = point;

        return {
          stageId: stageMaybeIn._id,
          percentageLeft: (xS(interpolatedT) + PADDING.left) / width,
          t: stageMaybeIn.offset.invert(interpolatedT),
          v,
        };
      };

      const findSlopeIntersection = (time: number) => {
        const shouldDo = app.type === "DRA" && app.slopes?.length;

        if (!shouldDo) return undefined;

        return app.slopes?.find((x) => {
          const [start, end] = x.extent;
          return time >= start.t && time <= end.t;
        })?._id;
      };
      const hoverData: HoverData["intersectionPointsMap"] = {};

      let closest = undefined as
        | ({
            distance: number;
            y: { canvas: number; percentageTop: number } | undefined;
            point: HoverPoint;
          } & HoveredLine)
        | undefined;

      for (const x of inViewData.all) {
        const yScale = iife(() => {
          switch (viewMode) {
            case YAxisMode.Absolute:
              return inViewData.absoluteYScale;
            case YAxisMode.Relative:
              return x.relativeScale;
            case YAxisMode.Swimlane:
              return x.relativeScale;
            default:
              const _: never = viewMode;
              throw new Error("unreachable");
          }
        });

        /**
         * Was used previously to determine if the point was in the swimlane
         */
        // if (viewMode === YAxisMode.Swimlane && yScale) {
        //   const [higher, lower] = getRangeFromScale(yScale);
        //   // not in the lane
        //   if (y > higher + PADDING.top || y < lower + PADDING.top) continue;
        // }

        const found = findPointIntersectionBatchVariable(x);

        if (!found) continue;

        const atCanvasY = yScale?.(found.v);
        const adjustedY = y - PADDING.top;
        const distance =
          atCanvasY === undefined ? Infinity : Math.abs(atCanvasY - adjustedY);

        if (!closest || distance < closest.distance) {
          closest = {
            ...x,
            distance,
            stage: found.stageId,
            y:
              atCanvasY === undefined
                ? undefined
                : {
                    canvas: atCanvasY,
                    percentageTop: iife(() => {
                      if (!yScale || atCanvasY === undefined)
                        throw new Error("impossible 20");

                      const percentageTop =
                        (yScale(found.v) + PADDING.top) / height;

                      return percentageTop;
                    }),
                  },
            point: found,
          };
        }
        // keep track of where the vertical line intersects all trend lines
        hoverData[getBvOrId(x)] = found;
      }

      if (
        // we found a point that is visible (because clamping can make it invisible, but still detected by the above logic)
        closest &&
        closest.y !== undefined &&
        closest.y.canvas + PADDING.top <= height - PADDING.bottom &&
        closest.y.canvas >= 0
      ) {
        hoverCircle
          .style("opacity", 1)
          .attr("cx", x)
          .attr("cy", closest.y.canvas + PADDING.top)
          .attr(`data-${HOVER_CIRCLE_DATASET_KEY}`, getBvOrId(closest));

        onHover({
          line: closest,
          intersectionPointsMap: hoverData,
          percentageLeft: closest.point.percentageLeft,
          percentageTop: closest.y.percentageTop,
          point: closest.point,
          slopingTrendId: findSlopeIntersection(closest.point.t),
        });
      } else {
        hideHoverCircle();
        onHover(undefined);
      }

      redrawWithCache(closest);
    };

    svgSelection.on("mousemove", (e: MouseEvent) => {
      handleHover(e);
    });
    svgSelection.on("mouseleave", (e: MouseEvent) => {
      removeHoverEffects();

      const r = e.relatedTarget as HTMLElement | null;
      const ignoreId = interactivity?.ignoreMouseLeaveSvgIfFocusGoesIntoId;
      if (ignoreId && r && r.id === ignoreId) {
        return;
      }
      redrawWithCache(undefined);
    });
  };

  /**
   * The goal is this whole zoom handler runs in no more than 50ms
   * Any more and it will feel laggy with fast zooming
   *
   * Optimize this by not filtering/mapping too much in here.
   * If you do, profile it to see if it's slow.
   *
   * In general, giving above 90% of the time to the canvas drawing
   * is what we want. Don't let the processing of data take up
   * a larger proportion.
   */
  const onSvgZoom = (
    e: d3.D3ZoomEvent<SVGSVGElement, readonly [number, number]>
  ) => {
    interactivity?.zoom?.set?.(e);

    // we rescale the original x scale because transform.k (and x and y) is relative to the original domain
    // notice how we never rescale on `newXScale`
    const newXScale = e.transform.rescaleX(originalXScale);

    const [x, y] = d3.pointer(e.sourceEvent);

    const outOfBounds =
      x < PADDING.left ||
      x > adjustedWidth + PADDING.left ||
      y < PADDING.top ||
      y > adjustedHeight + PADDING.top;
    if (app.type === "DRA" && !outOfBounds) {
      if (!xAxisMode.series) throw new Error("unsupported");
      const manipulatedTime = newXScale.invert(x - PADDING.left).getTime();
      dayHoverDrawer.setHoverTime(manipulatedTime).draw(newXScale);
    }

    // hideHoverLine(); // do we wanna do this?
    hideHoverCircle();
    interactivity?.onHover?.(undefined); // the previous hover state may not be valid anymore, since zoom will shift things
    drawWithZoomState(newXScale);
  };

  /**
   * If there is a previous zoom event, let the zoom handler do the first
   * draw of the cycle. If there isn't, we need to draw the initial view
   * right now.
   */

  let z;
  if (interactivity?.zoom && (z = interactivity.zoom.get())) {
    onSvgZoom(z);
    d3.zoom<SVGSVGElement, readonly [number, number]>().transform(
      svgSelection,
      z.transform
    );
  } else {
    drawWithZoomState(originalXScale);
    if (interactivity) {
      d3.zoom<SVGSVGElement, readonly [number, number]>().transform(
        svgSelection,
        d3.zoomIdentity
      );
    }
  }

  svgSelection.on("wheel", function (event: WheelEvent) {
    /**
     * This allows us to zoom with the scroll wheel
     */
    event.preventDefault();
  });

  const zoomObj = d3
    .zoom<SVGSVGElement, readonly [number, number]>()
    .scaleExtent([1, 15000])
    .extent([
      [0, 0],
      [adjustedWidth, adjustedHeight],
    ])
    .translateExtent([
      [0, 0],
      [adjustedWidth, adjustedHeight],
    ])
    .on("zoom", onSvgZoom);

  svgSelection.call(zoomObj);

  return function cleanup({
    shouldResetZoomOnSvgHTMLElement,
  }: {
    shouldResetZoomOnSvgHTMLElement: boolean;
  }) {
    interactivity?.setXScale(undefined);

    // interactivity?.notifyDomain?.(undefined); // idk if i need this
    // interactivity?.notifyGlobalRange?.(undefined);
    svgSelection.on(".mouseleave", null);
    svgSelection.on(".mousemove", null);
    svgSelection.on(".wheel", null);
    svgSelection.on(".zoom", null);
    svgSelection.selectAll("*").remove();

    /**
     * I want the __zoom property on the SVG DOM element
     * to be reset, but I don't want to call onSvgZoom,
     * so create an identical zoom object without the
     * on callback and apply it on the svg element.
     */
    shouldResetZoomOnSvgHTMLElement &&
      d3
        .zoom<SVGSVGElement, readonly [number, number]>()
        .transform(svgSelection, d3.zoomIdentity);
  };

  // if (typeof window === "undefined") return; // server side rendering
};

function createShutdownStripedPattern(
  color: string,
  canvas: HTMLCanvasElement
) {
  // create a 10x10 px canvas for the pattern's base shape
  canvas.width = 30;
  canvas.height = 60;

  const context = canvas.getContext("2d");

  if (!context) throw new Error("impossible 21");

  // set the background color to the anomaly level
  context.fillStyle = color;
  context.fillRect(0, 0, 30, 60);

  // draw the stipes with these styles
  context.strokeStyle = modifyColor("black")(0, 0.5);
  context.lineWidth = 15;

  // draw the line
  context.beginPath();
  context.moveTo(0, 60);
  context.lineTo(30, -10);
  context.stroke();

  // repeat so that the line is not just a single line duhhhh
  const out = context.createPattern(canvas, "repeat");
  if (!out) throw new Error("impossible 22");
  return out;
}

export { draw, type App };
