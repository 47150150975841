import _ from "lodash";

/* Most of this should actually be unnecessary now, because I've copied in the
 * official shims to the CSS file. Left here in case we need anything additional,
 * but I think these shims aren't really doing anything necessary at this point? */
const ICON_SHIMS = {
  "arrow-circle-o-right": "far fa-arrow-alt-circle-right",
  "arrow-circle-o-up": "far fa-arrow-alt-circle-up",
  "arrow-circle-o-down": "far fa-arrow-alt-circle-down",
  "arrow-circle-o-left": "far fa-arrow-alt-circle-left",
  pencil: "pencil-alt",
  "line-chart": "chart-line",
  "area-chart": "chart-area",
  close: "times",
};

export default function iconMapper(iconstring) {
  const icons = iconstring.split(" ");
  const shimmed = _.reduce(
    icons,
    (result, icon) => {
      if (ICON_SHIMS[icon]) {
        result.push.apply(result, ICON_SHIMS[icon].split(" "));
      } else {
        result.push(icon);
      }

      return result;
    },
    []
  );

  let attached = false;
  const fixed = _.map(shimmed, (icon) => {
    if (icon === "fa" || icon === "far" || icon === "fas" || icon === "nm") {
      attached = true;
      return icon; /* being used as a prefix. */
    }

    const prefixed = (() => {
      if (icon[0] !== "f" && icon[0] !== "n") {
        return false;
      } /* don't run regex if we don't have to. */
      return !!icon.match(/(fa-)|(nm-)/);
    })();

    return prefixed ? icon : `fa-${icon}`;
  }).join(" ");

  return attached ? fixed : `fa ${fixed}`;
}
