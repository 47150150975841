import { createContext, useContext } from "react";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

/**
 * A store that handles the state for the clamps form.
 *
 * It stores the input value (as a string, not a number)
 * and the boolean of isAuto.
 */

type SingleClampStore = {
  inputValue: string;
  setInputValue: (text: string) => void;
  auto: boolean;
  setAuto: (auto: boolean) => void;
};

type InitialValues = Pick<SingleClampStore, "inputValue" | "auto">;

function createUseClampStore(initial: InitialValues) {
  return create<SingleClampStore>()(
    subscribeWithSelector((set) => ({
      inputValue: initial.inputValue,
      auto: initial.auto,
      setAuto: (auto) => set({ auto }),
      setInputValue: (text) =>
        set((curr) =>
          curr.inputValue !== text ? { inputValue: text } : { curr }
        ),
    }))
  );
}

type UseClampStore = ReturnType<typeof createUseClampStore>;

const UseMinMaxClampsStoresContext = createContext<
  readonly [UseClampStore, UseClampStore] | undefined
>(undefined);

function useGetUseMinMaxClampsStores() {
  return useContext(UseMinMaxClampsStoresContext);
}

export {
  useGetUseMinMaxClampsStores,
  type UseClampStore,
  createUseClampStore,
  UseMinMaxClampsStoresContext,
  type InitialValues,
};
