import React from "react";
import getColors from "../common/colors";
import { PropsWithCn } from "../types/component.types";
import { cn } from "../../lib/utils";

const colors = getColors();
colors.sort((a, b) => b.value - a.value);

export default function PetalLegend({
  col,
  className,
  bordered,
}: PropsWithCn<{ col?: boolean; bordered?: boolean }>) {
  return (
    <div
      className={cn(
        "flex bg-xslate-2 rounded-lg py-1 px-4",
        bordered && "border border-xslate-6",
        {
          "flex-col": !!col,
          "gap-5": !col,
        },
        className
      )}
    >
      {/** @ts-expect-error */}
      {colors.map(({ label, rgb }) => (
        <div className="flex gap-1 items-center" key={label + rgb}>
          <div className={`h-3 w-3 rounded-full`} style={{ background: rgb }} />
          <span className="text-xs text-xslate-11">{label}</span>
        </div>
      ))}
    </div>
  );
}
