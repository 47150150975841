import * as React from "react";
import type { SubSectionComponent } from "../pages/types";
import Image from "./Image";
import List from "./List";
import Paragraph from "./Paragraph";
import PDF from "./PDF";
import SubTitle from "./SubTitle";
import Video from "./Video";

export default function Section({
  content,
  title,
  setImageLoaded,
  allAssetsLoaded,
}: Pick<SubSectionComponent, "content" | "title"> & {
  setImageLoaded: (imageId: string) => void;
  allAssetsLoaded: boolean;
}) {
  const myId = title.trim();

  return (
    <div className="p-3 mb-6 flex flex-col gap-3" id={myId}>
      <SubTitle title={title} />
      {content.map((c, idx) => {
        const key = `${c.type}-${idx}`;
        const t = c.type;
        if (t === "list") {
          return <List key={key} {...c} />;
        } else if (t === "paragraph") {
          return <Paragraph key={key} {...c} />;
        } else if (t === "image") {
          return (
            <Image
              key={key}
              {...c}
              setImageLoaded={setImageLoaded}
              allAssetsLoaded={allAssetsLoaded}
            />
          );
        } else if (t === "pdf") {
          return <PDF key={key} {...c} />;
        } else if (t === "video") {
          return <Video {...c} key={key} />;
        } else {
          // if c is not of the types above, TS should not complain about this line
          // since it can narrow down that there are no other possibilities
          const _exhaustiveCheck: never = c;
        }
      })}
    </div>
  );
}
