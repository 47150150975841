import { Tooltip } from "@mantine/core";
import classNames from "classnames";
import React, { useState, useEffect, createContext, useContext } from "react";
import { CgClose } from "react-icons/cg";
import Button from "./Button/Button";
import InputOld from "./InputOld";

/**
 * We want to have this concept of a global modal, since Daisy recommends
 * having the modal component be as high up as possible.
 *
 * With that being said, whenever a modal is open, the contents outside the
 * modal are unclickable, which pretty much makes it the ideal situation to
 * have this "global" modal.
 *
 * This naturally points us toward a React Context, so that any component
 * in the tree can use this modal, that is open it, close it, and pass data
 * to it to show, hence making it pretty flexible.
 *
 * HOW TO USE: ModalProvider is only exported to be used at the top level, to
 * wrap the app. Do not use it elsewhere.
 * In any other component, import and call useModal(), which returns a few functions
 * that take in arguments to customize the modal view, and then open it. Once it is
 * open, the modal has a close button by default. Other buttons can be configured
 * through said functions as options. See comment for isOpen below
 *
 */

const modalContext = createContext();

export function useModal() {
  return useContext(modalContext);
}

export function ModalProvider(props) {
  /**
   * isOpen actually an object that holds properties for the modal, we use
   * its existence or truthiness to render the modal. While falsy, no
   * modal is shown
   *
   * ex. {
   *  onClose: *func*,
   *  size: *tailwind width class like w-11/12*,
   *  title: *title of modal*,
   *  body: {
   *    text: *modal body text*,
   *    component: *JSX to show in the body also*,
   *
   *  },
   *  closeLable: *String to display for close button*
   *  buttons: *Array of buttons to display alongisde the default ones*
   *
   * }
   */

  const [isOpen, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
    isOpen?.onClose?.();
  };
  const open = ({
    title,
    body,
    buttons,
    onClose,
    closeLabel,
    size,
    closePosition,
    closeIcon,
  }) => {
    setOpen({
      title,
      body,
      buttons,
      onClose,
      closeLabel,
      size,
      closePosition,
      closeIcon: !!closeIcon,
    });
  };

  const button = (
    <Button
      className={classNames({
        "btn-ghost": isOpen && isOpen.closeIcon,
        "btn-outline btn-error": !(isOpen && isOpen.closeIcon),
      })}
      icon={isOpen && isOpen.closeIcon ? CgClose : undefined}
      onClick={close}
    >
      {isOpen && isOpen.closeIcon ? null : "Close"}
    </Button>
  );

  const closeButton =
    isOpen?.closeIcon || isOpen?.closeLabel ? (
      isOpen.closeIcon ? (
        <Tooltip label="Close" withArrow position="left">
          {button}
        </Tooltip>
      ) : (
        button
      )
    ) : null;
  const openClass = isOpen ? " modal-open" : "";

  const actions =
    isOpen?.buttons || isOpen?.closePosition === "top" ? (
      <div className="modal-action !min-h-0">
        {isOpen?.closePosition === "top" ? null : closeButton}
        {isOpen.buttons?.map?.((btn, idx) => {
          const { label, ...rest } = btn;
          return (
            <Button key={idx.toString()} {...rest}>
              {label}
            </Button>
          );
        })}
      </div>
    ) : null;

  const modal = (
    <div className={"modal " + openClass}>
      <div className={"modal-box overflow-visible py-8 " + isOpen.size}>
        <div className="flex justify-between">
          <h3 className="font-bold text-lg">{isOpen.title}</h3>
          {isOpen?.closePosition === "top" ? closeButton : null}
        </div>
        {isOpen.body?.text && <p className="italic py-4">{isOpen.body.text}</p>}
        {typeof isOpen.body?.component === "function" ? (
          <isOpen.body.component />
        ) : (
          isOpen.body?.component
        )}
        {actions}
      </div>
    </div>
  );

  const value = {
    closeModal: close,
    openModal: open,
  };
  return (
    <modalContext.Provider value={value}>
      {props.children}
      {modal}
    </modalContext.Provider>
  );
}
