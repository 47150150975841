import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { getFaultTreeNodeFiles } from "../../../frameworks/fetcher/api-routes-experimental";

export const FT_NODE_FILES_QUERY_KEY = "ft-node-files";

const useGetQueryKey = () => {
  const baseUrlSlash = useBaseUrlExperimental();

  return (nodeId: string | undefined) => {
    if (!nodeId) return [FT_NODE_FILES_QUERY_KEY, baseUrlSlash] as const;
    return [FT_NODE_FILES_QUERY_KEY, baseUrlSlash, nodeId] as const;
  };
};

function useFilesQuery(nodeId: string | undefined) {
  const getQueryKey = useGetQueryKey();
  const baseUrlSlash = useBaseUrlExperimental();

  const query = useQuery({
    queryKey: getQueryKey(nodeId),
    queryFn: () => getFaultTreeNodeFiles(baseUrlSlash, nodeId ?? ""),
    enabled: !!nodeId,
  });

  return query;
}

const useInvalidateQueryForNode = () => {
  const qc = useQueryClient();
  const getQueryKey = useGetQueryKey();

  return (nodeId: string) => {
    qc.invalidateQueries(getQueryKey(nodeId));
  };
};

const useInvalidateQueryForUnit = () => {
  const qc = useQueryClient();
  const getQueryKey = useGetQueryKey();

  return () => {
    qc.invalidateQueries(getQueryKey(undefined));
  };
};

export const FaultTreeNodeFiles = {
  useQuery: useFilesQuery,
  useInvalidateQueryForNode,
  useInvalidateQueryForUnit,
};
