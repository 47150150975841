import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtDeleted } from "./common";

export const UserRole = z.enum(["User", "Admin", "Root"]);
export type UserRole = z.infer<typeof UserRole>;

export const userSchema = MongoIdUpdatedAtCreatedAtDeleted.extend({
  email: z.string(),
  first: z.string(),
  last: z.string(),
  receive_reports: z.boolean(),
  reports: z.string().array(),
  units: z.string().array(),
  role: UserRole,
});

export type userSchema = z.infer<typeof userSchema>;
