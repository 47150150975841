import { Navigate, useLocation } from "react-router-dom";
import { useConfigRequired } from "../../zustand/config/useConfigStore";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { z } from "zod";

export default function NotFound() {
  const { pathname } = useLocation();
  const Config = useConfigRequired();
  const isAuthed = useAuthStore((s) => s.authed);
  const user = useAuthStore((s) => s.user);
  if (!isAuthed) {
    return <Navigate replace to="/login" />;
  }

  const configUnits = Config!.units.map((u) => u.name.toLowerCase());
  const thisUsersUnits = user!.units.filter((u) =>
    configUnits.includes(u.toLowerCase())
  );

  const tryingToExtractUnit = z.string().parse(pathname.split("/")[1]).trim();
  const matchedUnit = thisUsersUnits.find(
    (u) => u.toLowerCase() === tryingToExtractUnit.toLowerCase()
  );
  return <Navigate replace to={matchedUnit ? `${matchedUnit}/ov` : "/"} />;
}
