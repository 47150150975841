import { variableSchema } from "../../../lib/api-validators";
import { GenericTagsManager_NEEDS_PROVIDER } from "./generic-tags-manager";
import { UseTagsManagerStoreProvider } from "./tags-manager-store-provider";

const enabledFilters = {
  All: () => true,
  "Calculated Tags": (x) => !!x.expression?.trim(),
  "Historian Tags": (x) => !x.expression?.trim(),
  "High Side Anomaly ON": (x) => x.high_side,
  "High Side Anomaly OFF": (x) => !x.high_side,
  "Low Side Anomaly ON": (x) => x.low_side,
  "Low Side Anomaly OFF": (x) => !x.low_side,
  "Sloping Trends Positive": (x) => !x.hide_positive_slopes,
  "Sloping Trends Negative": (x) => !x.hide_negative_slopes,
  "Sloping Trends OFF": (x) => !x.st_enabled,
} as const satisfies Record<string, (x: variableSchema) => boolean>;

const enabledSorts = {
  "Recently Updated": (a, b) => {
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  },
  Alphabetical: (a, b) => {
    return a.trimmedName.localeCompare(b.trimmedName);
  },
} as const satisfies Record<
  string,
  (a: variableSchema, b: variableSchema) => number
>;

function TagsManager() {
  return (
    <UseTagsManagerStoreProvider
      enabledFilters={enabledFilters}
      enabledSorts={enabledSorts}
      initialFilter="All"
      initialSort="Recently Updated"
      isStaleTagsPage={false}
    >
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GenericTagsManager_NEEDS_PROVIDER />
    </UseTagsManagerStoreProvider>
  );
}
export { TagsManager };
