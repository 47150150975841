import moment from "moment";
import subDays from "date-fns/subDays";
import isSameDay from "date-fns/isSameDay";
import Button from "../Button/Button";
import useMaxDate from "../hooks/useMaxDate";
import { useDateState } from "../../../zustand/useDateState";
import { addDays, format, isAfter, isBefore } from "date-fns";
import { useState } from "react";
import { cn } from "../../../lib/utils";
import { RANGES } from "../../../constants/dateState";
import * as CalendarComponents from "../calendar";

type WhichCalendar = "start" | "end";

export function RangeCalendar({ close }: { close: () => void }) {
  const maxDate = useMaxDate();
  const dateState = useDateState();

  const [whichCalendar, setWhichCalendar] = useState<WhichCalendar>("start");

  if (dateState.mode !== "range") throw new Error("Invalid mode");

  const startDate = moment(dateState.selectedDateStart).toDate();
  const endDate = dateState.axisRangeTo.local;

  const handleDateSelect = (d: Date | undefined) => {
    if (!d) return;

    if (whichCalendar === "start") {
      const shouldAdjustEndDate = isSameDay(d, endDate) || isBefore(endDate, d); // must be at least 1 day apart
      const end = shouldAdjustEndDate ? addDays(d, 1) : endDate;

      const endIsPastMax = !isSameDay(end, maxDate) && isAfter(end, maxDate);

      const adjustedEnd = endIsPastMax ? maxDate : end;
      const adjustedStart = endIsPastMax ? subDays(adjustedEnd, 1) : d;

      dateState.setRange(adjustedStart, adjustedEnd);
      return;
    }

    if (whichCalendar === "end") {
      const shouldAdjustStartDate =
        isSameDay(d, startDate) || isBefore(d, startDate); // must be at least 1 day apart

      const start = shouldAdjustStartDate ? subDays(d, 1) : startDate;

      dateState.setRange(start, d);
      return;
    }

    throw new Error("unsupported");
  };

  return (
    <>
      <StartOrEndSelector
        selectedCalendar={whichCalendar}
        setSelectedCalendar={setWhichCalendar}
      />

      <CalendarComponents.SingleDay
        key={
          whichCalendar === "start"
            ? dateState.selectedDateStart
            : dateState.axisRangeTo.dateString
        }
        value={whichCalendar === "start" ? startDate : endDate}
        onChange={handleDateSelect}
        footer={
          <div className="pt-2">
            <Button
              size="xs"
              className="btn-outline btn-error"
              onClick={() =>
                dateState.setRange(
                  subDays(maxDate, (RANGES.at(0)?.days ?? 7) - 1),
                  maxDate
                )
              }
            >
              reset range
            </Button>
            <Button
              className="btn-outline float-right"
              size="xs"
              onClick={close}
            >
              Close
            </Button>
          </div>
        }
      />

      {/* <DayPicker
        disabled={[{ after: maxDate }, { before: EARLIEST_DATE }]}
        mode="single"
        defaultMonth={whichCalendar === "start" ? startDate : endDate}
        selected={whichCalendar === "start" ? startDate : endDate}
        onSelect={handleDateSelect}
        fromDate={EARLIEST_DATE}
        toDate={maxDate}
        captionLayout="dropdown"
      /> */}
    </>
  );
}

function StartOrEndSelector({
  selectedCalendar,
  setSelectedCalendar,
}: {
  selectedCalendar: WhichCalendar;
  setSelectedCalendar: (which: WhichCalendar) => void;
}) {
  const dateState = useDateState();
  const fromDate = moment(dateState.selectedDateStart).toDate();
  const toDate = dateState.axisRangeTo.local;

  return (
    <div className="flex items-center px-4 gap-2 py-1 border-y mt-2 border-bordgrey2">
      <Button
        className={cn(
          "flex-1",
          selectedCalendar === "start" ? "btn-primary" : "btn-ghost"
        )}
        size="sm"
        onClick={() => setSelectedCalendar("start")}
      >
        {format(fromDate, "PP")}
      </Button>
      <div className="h-[1.5px] w-[12px] rounded-xl bg-gray-400" />
      <Button
        className={cn(
          "flex-1",
          selectedCalendar === "end" ? "btn-primary" : "btn-ghost"
        )}
        onClick={() => setSelectedCalendar("end")}
        size="sm"
      >
        {format(toDate, "PP")}
      </Button>
    </div>
  );
}
