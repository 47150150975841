import React from "react";
import moment from "moment";
import _ from "lodash";
import BarChart from "../../charts/BarChart";
import useAPI from "../../../lib/useAPI";
import { getRangeLabel, formatDuration4 } from "./utils";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minutesToMilliseconds } from "date-fns";

export default function OperatingLimitsTimeCard({ id }) {
  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();
  const api = useAPI();

  const b = useBaseUrlExperimental();

  const data = useQuery({
    enabled: useAtomValue(Atoms.chartInViewportAtom),
    staleTime: minutesToMilliseconds(5),
    queryKey: [b, "ol-hist-time", start, end, id],
    queryFn: async () => {
      const time = await api.getOperatingLimitHistogram(id, start, end, "time");

      const max = _.maxBy(time.data, (day) => day.value || 0);

      const unit = (() => {
        let duration = moment.duration(max);
        if (duration.asSeconds() < 60) return "seconds";
        if (duration.asMinutes() >= 1 && duration.asMinutes() < 60)
          return "minutes";
        if (duration.asHours() >= 1 && duration.asHours() < 24) return "hours";
        return "hours";
      })();

      const data_ = _.map(time.data, (day) => {
        let value = (() => {
          switch (unit) {
            case "seconds":
              return moment.duration(day.value || 0).asSeconds();
            case "minutes":
              return moment.duration(day.value || 0).asMinutes();
            case "hours":
              return moment.duration(day.value || 0).asHours();
            case "days":
              return moment.duration(day.value || 0).asDays();
            default:
              return moment.duration(day.value || 0).asHours();
          }
        })();

        return {
          key: DateTime.fromISO(day.date, { zone: "utc" }).toISODate(),
          value: [value],
        };
      });
      data_.unit = unit;

      return data_;
    },
  }).data;

  if (!data || !data.length) {
    return null;
  }

  const totalCount = _.reduce(
    data,
    (sum, day) => {
      return sum + day.value[0];
    },
    0
  );

  return (
    <div>
      <p className="absolute top-3 right-[29rem] text-[13px] font-bold">
        Exceedance Time:{" "}
        {formatDuration4(data[data.length - 1]?.value[0] * 60 * 60 * 1000)} (
        {moment.utc(data[data.length - 1].key).format("MMM D")}),{" "}
        {formatDuration4(totalCount * 60 * 60 * 1000)} (
        {getRangeLabel(data[0].key, data[data.length - 1].key, true)})
      </p>
      <BarChart
        classes={{
          BarChart: "OperatingLimitsDetails__BarChart",
          BarChart__xAxis: "OperatingLimitsDetails__BarChart__xAxis",
        }}
        chartTitle={`Exceedance Time (in ${data.unit})`}
        showTitle={true}
        chartKeys={["Amount"]}
        data={data}
        yAxisLabel={"Exceedances"}
        xAxisLabel={"Date"}
        unit={(hours) => `${formatDuration4(hours * 60 * 60 * 1000)}`}
      />
    </div>
  );
}
