import React from "react";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { type TimeFormat } from "../../../shared-ui/lib/date-utils";

function UpdatedAt({
  timestamp,
  noContainer,
}: {
  timestamp?: string;
  noContainer?: boolean;
}) {
  const zone = useTimezone();

  const out = (
    <span>
      Last Updated:{" "}
      {timestamp
        ? DateTime.fromISO(timestamp, { zone }).toFormat(
            "dd-LLL-yyyy hh:mm a" satisfies TimeFormat
          )
        : "N/A"}
    </span>
  );

  return noContainer ? (
    out
  ) : (
    <div className="mr-2 italic text-[0.8rem] text-zinc-400 my-1 flex justify-end">
      {out}
    </div>
  );
}

export default React.memo(UpdatedAt);
