import assets from "../assets";
import type { HelpPage } from "./types";

const TrainingVideos: HelpPage = {
  color: "#94d82d",
  sectiontitle: "Training Videos",
  url: "training",
  subtitle:
    "Welcome to DRA! Learn how to navigate through DRA via short videos",
  subsections: [
    {
      id: "subsection-13",
      title: "Introduction",
      content: [
        {
          type: "paragraph",
          content:
            "DRA Fundamentals Training is designed for first time users as well as those who need a refresher. This video reviews what is Dynamic Risk Analyzer, the primary and secondary dashboards, and what each part of the training is about.",
          id: "content-14",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Intro.mp4",
          codename: "trainingintro",
          captionedcodename: "trainingintrocc",
          captionedfilename: "DRA Training Intro CC.vtt",
          id: "content-15",
          content: assets.trainingintro,
          captionedcontent: assets.trainingintrocc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-16",
      title: "Part 1 - Overview",
      content: [
        {
          type: "paragraph",
          content:
            "This video reviews the Overview Dashboard, what is Dynamic Risk Index, and how to change the calendar date and date range.",
          id: "content-17",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Part 1.mp4",
          codename: "trainingpart1",
          captionedcodename: "trainingpart1cc",
          captionedfilename: "DRA Training Part 1 CC.vtt",
          id: "content-18",
          content: assets.trainingpart1,
          captionedcontent: assets.trainingpart1cc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-19",
      title: "Part 2 - Dynamic Risk Index",
      content: [
        {
          type: "paragraph",
          content:
            "This video reviews the Dynamic Risk Index dashboard, what is Degree of Anomaly, Shutdown Rules, Trend chart and additional support functions such as how to add a variable to the Watchlist, overlap variables, and acknowledge a variable.",
          id: "content-20",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Part 2.mp4",
          codename: "trainingpart2",
          captionedcodename: "trainingpart2cc",
          captionedfilename: "DRA Training Part 2 CC.vtt",
          id: "content-21",
          content: assets.trainingpart2,
          captionedcontent: assets.trainingpart2cc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-22",
      title: "Part 3 - Profile Book, Sloping Trend",
      content: [
        {
          type: "paragraph",
          content:
            "This video reviews the functionality of the 3rd primary dashboard, Profile Book, which includes the Sloping Trend results and several Smart Folders.",
          id: "content-23",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Part 3.mp4",
          codename: "trainingpart3",
          captionedcodename: "trainingpart3cc",
          captionedfilename: "DRA Training Part 3 CC.vtt",
          id: "content-24",
          content: assets.trainingpart3,
          captionedcontent: assets.trainingpart3cc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-25",
      title: "Part 4 - Settings",
      content: [
        {
          type: "paragraph",
          content:
            "This video reviews one of the secondary dashboards, the Settings dashboard, which includes Tags, Groups, Shutdowns, Clusters, and Reports. It also demonstrates how one can view and download reports.",
          id: "content-26",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Part 4.mp4",
          codename: "trainingpart4",
          captionedcodename: "trainingpart4cc",
          captionedfilename: "DRA Training Part 4 CC.vtt",
          id: "content-27",
          content: assets.trainingpart4,
          captionedcontent: assets.trainingpart4cc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-28",
      title: "Part 5 - Comments, Notifications",
      content: [
        {
          type: "paragraph",
          content:
            "This video reviews how to make comments and notifications and how to review them in the Comments and Notifications dashboards.",
          id: "content-29",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "DRA Training Part 5.mp4",
          codename: "trainingpart5",
          captionedcodename: "trainingpart5cc",
          captionedfilename: "DRA Training Part 5 CC.vtt",
          id: "content-30",
          content: assets.trainingpart5,
          captionedcontent: assets.trainingpart5cc,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-212",
      title: "Fault Trees",
      content: [
        {
          type: "paragraph",
          content:
            "This video demonstrates an add-on module in DRA called Fault Trees. It shows how you can build dynamic fault trees that can be linked to your process data - enabling you to identify probable root causes and respond to key process issues timely and effectively.",
          id: "content-213",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "FT Training.mp4",
          codename: "ftTraining",
          captionedcodename: "ftTrainingVTT",
          captionedfilename: "FT Training.vtt",
          id: "content-214",
          content: assets.ftTraining,
          captionedcontent: assets.ftTrainingVTT,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-64",
      title: "Operating Fitness",
      content: [
        {
          type: "paragraph",
          content:
            "This video demonstrates an add-on module in DRA called Operating Fitness. It shows how you can analyze critical risk and safety limits of your process, such as integrity operating limits and environmental limits, and obtain useful results on when those limits were crossed and the probabilities of crossing them in the future.",
          id: "content-65",
          marginBottom: "0.5",
          marginTop: "0",
        },
        {
          type: "video",
          filename: "OF Training.mp4",
          codename: "ofTraining",
          captionedcodename: "ofTrainingVTT",
          captionedfilename: "OF Training.vtt",
          id: "content-66",
          content: assets.ofTraining,
          captionedcontent: assets.ofTrainingVTT,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
  ],
};

export default TrainingVideos;
