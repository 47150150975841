import React, { useState } from "react";
import ExpressionRow from "../../common/manager/ExpressionRow";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import { shutdownValueToLabel } from "../../../lib/api-schema/shutdown-rule";
import { Badge } from "../../ui/badge";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { DTO_ShutdownRule } from "../../../lib/api-schema/shutdown-rule";
import {
  useClustersQuery,
  useGroupsQuery,
  useInvalidateShutdownRulesQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { useMutation } from "@tanstack/react-query";
import {
  deleteShutdownRule,
  editShutdownRule,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { ShutdownRuleForm } from "./shutdown-form";

function useDeleteShutdownRuleMutation() {
  const baseUrl = useBaseUrlExperimental();
  const invalidateListQuery = useInvalidateShutdownRulesQuery();

  return useMutation({
    mutationFn: ({ _id }: { _id: string; name: string }) =>
      deleteShutdownRule(baseUrl, _id),
    onSuccess: (_, { name }) => {
      addSuccessToast(`${name} deleted`);
      invalidateListQuery();
    },
    onError: (e) => addUnknownErrorToast(e),
  });
}

function useEditShutdownRuleMutation(_id: string, close: () => void) {
  const invalidateListQuery = useInvalidateShutdownRulesQuery();
  const baseUrl = useBaseUrlExperimental();
  return useMutation({
    mutationFn: (payload: Parameters<typeof editShutdownRule>[2]) => {
      return editShutdownRule(baseUrl, _id, payload);
    },
    onSuccess: (sdr) => {
      close();
      addSuccessToast(`${sdr.name} updated`);
      invalidateListQuery();
    },
    onError: (e) => addUnknownErrorToast(e),
  });
}

export function ShutdownCard({ rule }: { rule: DTO_ShutdownRule }) {
  const [editMode, setEditMode] = useState(false);

  const deleteMut = useDeleteShutdownRuleMutation();
  const editMut = useEditShutdownRuleMutation(rule._id, () =>
    setEditMode(false)
  );

  const variablesQuery = useVariablesArrayQuery();
  const groupsQuery = useGroupsQuery();
  const clustersQuery = useClustersQuery();

  const hasEditPermission = useHasEditPermission();

  const getAssociatedTagNames = () => {
    if (!variablesQuery.data) return [];
    return rule.bindingVariableIds.reduce((acc, id) => {
      const variable = variablesQuery.data.find((v) => v._id === id);
      variable && acc.push(variable.trimmedName);
      return acc;
    }, [] as string[]);
  };

  const getAssociatedGroupNames = () => {
    if (!groupsQuery.data) return [];
    return rule.bindingGroupIds.reduce((acc, id) => {
      const group = groupsQuery.data.find((g) => g._id === id);
      group && acc.push(group.name);
      return acc;
    }, [] as string[]);
  };

  const getAssociatedClusterNames = () => {
    if (!clustersQuery.data) return [];
    return rule.clusters.reduce((acc, id) => {
      const cluster = clustersQuery.data.find((c) => c._id === id);
      cluster && acc.push(cluster.name);
      return acc;
    }, [] as string[]);
  };

  const tagNames = getAssociatedTagNames();
  const groupNames = getAssociatedGroupNames();
  const clusterNames = getAssociatedClusterNames();

  return (
    <div className="mt-6 border border-solid border-[#dbdbdb] bg-white rounded-lg overflow-visible">
      <div className="text-[#000000] text-[1.05em] flex items-center justify-between py-[0.15em] px-[0.5em] border-b border-solid border-bordgrey overflow-visible">
        <div className="py-1 flex items-center gap-2">
          <span className="tracking-tight font-medium px-0 my-[0.2em] mx-0 Manager__entity__name">
            {`${rule.name} | ${rule.description}`}
          </span>

          {/* it seems like the old data had category as a key but as of recent I haven't seen any. Nevertheless, this is here  */}
          <Badge variant={"amber"}>
            {rule.category
              ? shutdownValueToLabel[rule.category]
              : shutdownValueToLabel.Shutdown}
          </Badge>
          <Badge variant={"amber"}>{`${Math.floor(
            rule.buffer / 60
          )} hour (buffer)`}</Badge>
        </div>

        {hasEditPermission && (
          <div>
            <Tooltip label="Delete" withArrow>
              <Button
                disabled={deleteMut.isLoading || editMut.isLoading}
                className="ml-auto btn-ghost"
                icon="trash-o"
                onClick={() => {
                  window.confirm(
                    `Are you sure you want to delete shutdown rule ${rule.name} | ${rule.description}?`
                  ) && deleteMut.mutate(rule);
                }}
              />
            </Tooltip>
            {!editMode && (
              <Tooltip label="Edit" withArrow>
                <Button
                  disabled={deleteMut.isLoading || editMut.isLoading}
                  className="ml-auto btn-ghost"
                  icon="pencil"
                  onClick={() => setEditMode(true)}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      <div className="overflow-visible">
        {editMode ? (
          <ShutdownRuleForm
            className="border-0"
            close={() => setEditMode(false)}
            disabled={deleteMut.isLoading}
            onSubmit={(payload) => editMut.mutate(payload)}
            defaultValues={{
              bindingGroupIds: rule.bindingGroupIds,
              bindingVariableIds: rule.bindingVariableIds,
              buffer: Math.floor(rule.buffer / 60),
              category: rule.category ?? "Shutdown",
              description: rule.description,
              expression: rule.expression,
              name: rule.name,
              clusters: rule.clusters,
            }}
          />
        ) : (
          <>
            <div className="Manager__entity__body">
              <div className="px-[0.5rem]">
                {rule.expression ? (
                  <ExpressionRow expression={rule.expression} />
                ) : null}
              </div>
              {groupNames.length > 0 && (
                <PillRow className="px-2" label="Groups" items={groupNames} />
              )}
              {tagNames.length > 0 && (
                <PillRow className="px-2" label="Tags" items={tagNames} />
              )}
              {clusterNames.length > 0 && (
                <PillRow
                  className="px-2"
                  label="Clusters"
                  items={clusterNames}
                />
              )}
            </div>
            <UpdatedAt timestamp={rule.updatedAt} />
          </>
        )}
      </div>
    </div>
  );
}
