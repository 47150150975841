import React, { useState, useMemo, useId } from "react";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { Input } from "../../../shared-ui/frontend/input";
import { Label } from "../../../shared-ui/frontend/label";
import { TagsSwapList } from "./form/swap-list";
import { Button } from "../../ui/button";
import { Group } from "../../../lib/api-schema/group";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";

function GroupEditCreateForm({
  close,
  onSubmit,
  defaults,
  className,
}: {
  close: () => void;
  onSubmit: (payload: { variables: string[]; name: string }) => void;
  defaults?: Pick<Group, "name" | "variables">;
} & PropsWithCn) {
  const [groupName, setGroupName] = useState(defaults?.name ?? "");
  const [tagIdsSelected, setTagIds] = useState(defaults?.variables ?? []);

  const variables = useVariablesArrayQuery().data;

  const tags = useMemo(() => {
    return variables?.filter((v) => v.type === "Tag");
  }, [variables]);

  const excluded = useMemo(() => {
    return (
      tags &&
      tags.filter((t) => !tagIdsSelected.includes(t._id)).map((x) => x._id)
    );
  }, [tagIdsSelected, tags]);

  const groupNameId = useId();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (tagIdsSelected.length === 0) {
          return addUnknownErrorToast("Select at least one tag for the group");
        }

        const trimmedName = groupName.trim();

        if (trimmedName.length === 0) {
          return addUnknownErrorToast("Group name is required");
        }

        onSubmit({
          name: trimmedName,
          variables: tagIdsSelected,
        });
      }}
      className={cn("flex flex-col gap-5", className)}
    >
      <Label htmlFor={groupNameId}>Group Name</Label>
      <Input
        id={groupNameId}
        required
        minLength={1}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <div className="grid grid-cols-2 gap-3">
        <TagsSwapList
          type="Included"
          disabled={false}
          inThisList={tagIdsSelected}
          onClick={(vids) => {
            setTagIds((curr) => curr.filter((v) => !vids.includes(v)));
          }}
        />
        {excluded && (
          <TagsSwapList
            type="Excluded"
            disabled={false}
            inThisList={excluded}
            onClick={(vid) => {
              setTagIds((curr) => curr.concat(vid));
            }}
          />
        )}
      </div>
      <div className="flex justify-between">
        <Button
          size={"sm"}
          variant={"destructive"}
          type="button"
          onClick={close}
        >
          Cancel
        </Button>
        <Button size={"sm"} type="submit">
          Save
        </Button>
      </div>
    </form>
  );
}

export default GroupEditCreateForm;
