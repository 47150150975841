import { type ReactNode, type PropsWithChildren } from "react";
import { type PropsWithCn, cn } from "../../frontend/cn";
import {
  type HoverData,
  useGetUseTimeseriesChartsTooltipStore,
} from "./use-global-timeseries-tooltip-and-clicked-line-store";
import { createPortal } from "react-dom";

function Container({
  children,
  className,
  uom,
}: PropsWithCn & {
  uom?: string;
  children: (tooltipData: HoverData) => ReactNode;
}) {
  const useStore = useGetUseTimeseriesChartsTooltipStore();

  if (!useStore)
    throw new Error(
      "useStore is undefined. Only render this component inside a TimeseriesChartTooltipStoreProvider."
    );

  const tooltipData = useStore((s) => s.hoverData);

  if (!tooltipData) return null;

  const { left, width, top, height } =
    tooltipData.container.getBoundingClientRect();
  /**
   * Figure out where to place the tooltip.
   * Percentage values are percentages of the
   * container. get it's top left corner
   * and then add the percentage of the width and height
   * to find where to place the tooltip.
   */
  const fixedLeft = tooltipData.percentageLeft * width + left;
  const fixedTop = (tooltipData.percentageTop + 0.05) * height + top;

  const estimatedWidthOfTooltip = 230;
  const half = estimatedWidthOfTooltip / 2;

  const windowWidth = window.innerWidth;

  const minLeft = half;
  const maxLeft = windowWidth - half;

  const tooltip = (
    <div
      className={cn(
        // pointer-events-none is to allow the mouse to go through this element
        // so we can continue hovering over the chart even if mouse is over this tooltip
        "pointer-events-none fixed left-0 flex top-0 -translate-x-1/2 flex-col rounded-md border border-xslate-6 bg-zinc-950/70 p-2 text-xs text-white transition-[top] duration-[300ms] ease-in-out",
        className
      )}
      style={{
        top: fixedTop,
        left: Math.max(Math.min(maxLeft, fixedLeft), minLeft),
        zIndex: 999999,
      }}
    >
      <div className="mb-2 flex items-center border-b border-inherit pb-2 gap-2">
        <span className="text-md leading-2 whitespace-nowrap font-semibold">
          {tooltipData.v.toFixed(2)}
        </span>
        {uom && <span className="font-light">{uom}</span>}
      </div>
      <div className="grid grid-cols-[auto_auto] justify-items-start gap-x-8 min-w-max">
        {children(tooltipData)}
      </div>
    </div>
  );

  return createPortal(tooltip, document.body);
}

function Value({ children }: PropsWithChildren) {
  // so that the tooltip doesn't get too wide from really long tag names perhaps
  return <span className="col-auto max-w-[200px] break-all">{children}</span>;
}

function Label({ children }: PropsWithChildren) {
  return (
    <span className="col-start-1 justify-self-start whitespace-nowrap uppercase text-amber-500">
      {children}
    </span>
  );
}

function ValueLoader() {
  return (
    <div className="pointer-events-none col-auto w-[115px] animate-pulse self-stretch rounded-md bg-xslate-9" />
  );
}

export { Container, Value, Label, ValueLoader };
