import React from "react";
import "./MainLoader.scss";

function MainLoader(props) {
  const classes = props.classes || {};
  const message = props.message || "Loading...";
  const showMessage = !props.nomessage;

  return (
    <div className={`MainLoader ${classes.MainLoader || ""}`}>
      {/* This is wrapped because of some font-awesome CSS decisions.
       * Font-awesome aggressively inherits font-size from the parent element.
       * It resists setting font-size directly on the icon. */}
      <div className={`MainLoader__icon ${classes.MainLoader__icon || ""}`}>
        <i className={`fa fa-spinner fa-pulse fa-fw`} aria-hidden="true"></i>
      </div>
      {showMessage && (
        <div className={`MainLoader__label ${classes.MainLoader__label || ""}`}>
          {message}
        </div>
      )}
    </div>
  );
}

export default MainLoader;
