import { useCallback, useEffect, useRef, useState } from "react";
import {
  draw,
  getBvOrId,
  TALLER_CHART_SCALE,
  trendDataMatches,
  type App,
} from "./draw/draw";
import {
  type TimeseriesForBv,
  type Point,
  TimeseriesForBvForDraw,
} from "./types";
import { iife, minLen1 } from "../lib/utils";
import * as R from "remeda";
import { useGetUseTimeseriesChartsTooltipStore } from "./global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { Atoms } from "./svv-store/use-svv-store";
import { BatchViewMode, YAxisMode } from "../time-series/types";
import { useFullscreenContext } from "./fullscreen/fullscreen-provider";
import { useStore } from "zustand";
import {
  useAtomValue,
  useSetAtom,
  useStore as useJotaiStore,
  atom,
  createStore,
} from "jotai";
import { CommentPills } from "../../components/time-series/secondary-variable-view/comments/comment-pills";
import { cn } from "../frontend/cn";
import "./brush-handle.css";
import {
  buildDrawDataForAlignByStartView,
  buildDrawDataForDiscontinuousDRA,
  buildDrawDataForParallelAlignByStageView,
  buildDrawDataForRegularSeriesView,
} from "./draw/build-draw-data";
import { MinLen1 } from "../lib/types";
import { useIsRenderedAsFullscreen } from "./secondary-variable-view/secondary-variable-view";

type Props = {
  app: App["type"];
  anomalyPatchesThicknessScale?: number;
  width: number;
  height: number;
  timezone: string;
  slopes?: { extent: [Point, Point]; _id: string; anom: number }[];
  lineWidthScale?: number;
  axesFontScale?: number;
  data: TimeseriesForBv[] | undefined;
  limits?: {
    data: {
      start: number;
      end: number | null;
      value: number;
    }[];
    level: string;
  }[];

  stages: { isDefault: boolean; _id: string }[] | undefined;
  theme: "light" | "dark";
  overrideXAxis?: [number, number];
  stillUseColorForAnomalyColorationOff: boolean;
  redBlockStart?: number;
  noDaBars?: boolean;
  primaryVariableColor?: [string, string];
  numTicks?: number;
  // is this chart allowed to get taller?
  ableToGetTaller?: boolean;
  anomalyRectangles?: boolean;
  onlyMinMaxYAxes?: boolean;
  onLineClick?: (o: {
    bvOrId: string; // which line was clicked?
    clientX: number;
    clientY: number;
    jotaiStore: ReturnType<typeof createStore>;
  }) => void;
  analysisPeriod?: [number, number];
  hackForDemo?: boolean;
  commentsOnlyInFullscreen?: boolean;
  hideCommentPills?: boolean;
  inViewOverride?: boolean;
} & Pick<Parameters<typeof draw>[0], "padding">;

const ID_FOR_CLICKED_LINE_OVERLAY = "clicked-line-overlay";

function useShouldRenderOrReact() {
  const isRenderedAsFullscreen = useIsRenderedAsFullscreen();
  const jotStore = useJotaiStore();

  const fsCtx = useFullscreenContext();

  if (isRenderedAsFullscreen) return true; // always render the fullscreen chart

  // this is not a fullscreen chart, what should we do?

  if (!fsCtx) return true; // no fullscreen capability, so render

  const stores = fsCtx.stores;

  if (!stores) {
    /**
     * Fullscreen capability exists but there is not a current fullscreen chart
     */
    return true;
  }

  // we're not rendered as fullscreen, so only draw me if i'm not the fullscreen chart
  return stores.jotaiStore !== jotStore;
}

export const heightFactorAtom = atom((get) => {
  const outerDimensions = get(Atoms.containerDimensionsAtom);
  const chartArea = get(Atoms.chartAreaDimensionsAtom);
  if (!outerDimensions || !chartArea) return 1;

  /**
   * Has the chart area's width changed because the
   * sidebar is open? If so, return the ratio of its
   * decrease in width, so that we can apply the
   * reverse to the height, so that the chart doesn't
   * cause layout shift when the sidebar opens.
   */
  return chartArea.w / outerDimensions.w;
});

const isContinuousAtom = atom((get) => {
  // continuous shifting only occurs in single variable view and if X Axis continuity is enabled
  const singleVar = get(Atoms.singleVariableViewAtom);
  const out = get(Atoms.xAxisContinuityAtom);
  if (out) return singleVar && get(out);
  return singleVar;
});

const shouldDrawDaBarsAtom = atom((get) => {
  const a = get(Atoms.daBarsAtomAtom);
  return a ? get(a) : undefined;
});

function TimeseriesChart({
  limits,
  timezone,
  app,
  ableToGetTaller,
  slopes,
  noDaBars,
  numTicks,
  anomalyPatchesThicknessScale,
  theme,
  padding,
  axesFontScale,
  onLineClick,
  overrideXAxis,
  hackForDemo,
  anomalyRectangles,
  redBlockStart,
  stillUseColorForAnomalyColorationOff,
  onlyMinMaxYAxes,
  data,
  stages,
  height,
  width,
  analysisPeriod,
  commentsOnlyInFullscreen,
  lineWidthScale,
  primaryVariableColor,
  hideCommentPills,
  inViewOverride,
  ...rest
}: Props) {
  const _: keyof typeof rest extends never ? true : false = true;

  const inViewFromIntersectionObserver = useAtomValue(
    Atoms.chartInViewportAtom
  );

  const inView = inViewOverride ?? inViewFromIntersectionObserver;

  const useTooltipStore = useGetUseTimeseriesChartsTooltipStore();

  const GREY = theme === "light" ? "#e8e4e4" : "#2c2c2c";

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  const shouldRender = useShouldRenderOrReact();

  const hFactor = useAtomValue(heightFactorAtom);
  const allBatchVariables = useAtomValue(Atoms.selectedVariablesAtom);
  const yAxisMode = useAtomValue(Atoms.yAxisModeAtom);
  const xAxisMode = useAtomValue(useAtomValue(Atoms.xAxisModeAtomAtom));
  const expanded = useAtomValue(Atoms.expandedAtom);
  const spotlight = useAtomValue(useAtomValue(Atoms.spotlightAtomAtom));
  const openSidebar = useAtomValue(
    useAtomValue(Atoms.cardsSidebarOpenAtomAtom)
  );
  const batchVariablesToRender = useAtomValue(Atoms.checkedBatchVariablesAtom);
  const excludedModes = useAtomValue(Atoms.excludedModesAtom);
  const isContinuous = useAtomValue(isContinuousAtom);

  const setDrawnDomain = useSetAtom(useAtomValue(Atoms.drawnDomainAtomAtom));

  const trendRef = useRef<HTMLCanvasElement>(null);
  const darkTrendRef = useRef<HTMLCanvasElement>(null);
  const stageSepRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const dayHoverRef = useRef<HTMLCanvasElement>(null);
  const brushRef = useRef<HTMLCanvasElement>(null);

  const setGlobalRange = useSetAtom(Atoms.globalRangeAtom);
  const jotaiStore = useJotaiStore();
  const setHoverIntersectionMap = useSetAtom(Atoms.hoverIntersectionMapAtom);

  const isModeTransparency = useAtomValue(Atoms.isModeTransparencyAtom);

  const [primaryColor, darkerColor] = primaryVariableColor ?? [
    undefined,
    undefined,
  ];

  const [analysisPeriodStart, analysisPeriodEnd] = analysisPeriod ?? [
    undefined,
    undefined,
  ];

  const shouldDrawDaBars = useAtomValue(shouldDrawDaBarsAtom);

  const isRenderedAsFullscreen = useIsRenderedAsFullscreen();

  /**
   * Handle updating state when prop changes
   */
  useEffect(() => {
    if (isRenderedAsFullscreen && commentsOnlyInFullscreen !== undefined)
      throw new Error(
        "This option should not be set for charts that are rendered as fullscreen."
      );

    const isNotAFullscreenChart = !isRenderedAsFullscreen;

    isNotAFullscreenChart &&
      jotaiStore.set(
        Atoms.onlyShowCommentsInFullscreenAtom,
        !!commentsOnlyInFullscreen
      );
  }, [jotaiStore, commentsOnlyInFullscreen, isRenderedAsFullscreen]);

  let modifiedHeight = height / hFactor;

  const shouldBeTaller = ableToGetTaller
    ? expanded || allBatchVariables.length > 1
    : false;

  if (shouldBeTaller) {
    modifiedHeight = modifiedHeight * TALLER_CHART_SCALE;
  }

  // remove the decimal places, since we put this in useEffect and minute differences shouldn't matter?
  modifiedHeight = Math.round(modifiedHeight * 1000) / 1000;

  useEffect(() => {
    const trendCanvas = trendRef.current;
    const stageSepCanvas = stageSepRef.current;
    const darkTrendCanvas = darkTrendRef.current;
    const svgElt = svgRef.current;
    const dayHoverCanvas = dayHoverRef.current;

    const earlyReturnClearCanvases = (id: number) => {
      console.log("earlyReturnClearCanvases", id);
      if (trendCanvas) {
        trendCanvas.height = modifiedHeight;
        trendCanvas.width = width;
      }

      if (stageSepCanvas) {
        stageSepCanvas.height = modifiedHeight;
        stageSepCanvas.width = width;
      }

      if (darkTrendCanvas) {
        darkTrendCanvas.height = modifiedHeight;
        darkTrendCanvas.width = width;
      }

      if (dayHoverCanvas) {
        dayHoverCanvas.height = modifiedHeight;
        dayHoverCanvas.width = width;
      }
    };

    // this is a new mode we added later on that doesn't require this chart to be drawn
    if (yAxisMode === YAxisMode.Variability) return earlyReturnClearCanvases(1);

    if (
      !stageSepCanvas ||
      !trendCanvas ||
      !svgElt ||
      !darkTrendCanvas ||
      !dayHoverCanvas ||
      !shouldRender ||
      !inView ||
      !data
    )
      return earlyReturnClearCanvases(2);

    const timeseriesData = data
      .filter((x) => {
        switch (x.type) {
          case "expression":
            return batchVariablesToRender.expression.has(x.id);
          case "variable":
            return batchVariablesToRender.variable.has(x.bv);
          default:
            const _: never = x;
            throw new Error("unreachable");
        }
      })
      // sort for swimlane mode
      .sort((a, b) => {
        return (
          allBatchVariables.findIndex((x) => trendDataMatches(x, a)) -
          allBatchVariables.findIndex((x) => trendDataMatches(x, b))
        );
      });

    if (!minLen1(timeseriesData)) return earlyReturnClearCanvases(3);

    const debouncedSetHoverIntersectionMap: typeof setHoverIntersectionMap =
      iife(() => {
        let hoverTimer: ReturnType<typeof setTimeout> | null = null;

        return (...args) => {
          hoverTimer && clearTimeout(hoverTimer);
          hoverTimer = setTimeout(() => {
            setHoverIntersectionMap(...args);
          }, 30);
        };
      });

    const isAlignByStage = xAxisMode === BatchViewMode.ParallelStage;

    if (isAlignByStage && !stages) return earlyReturnClearCanvases(4); // stage data not ready

    const hasExcludedModes = excludedModes.size > 0;

    const debouncedSetRangoInfo: typeof setGlobalRange = iife(() => {
      let hoverTimer: ReturnType<typeof setTimeout> | null = null;

      return (...args) => {
        hoverTimer && clearTimeout(hoverTimer);
        hoverTimer = setTimeout(() => {
          setGlobalRange(...args);
        }, 200);
      };
    });

    const effectiveXAxisMode = xAxisMode ?? BatchViewMode.series;

    let drawData: MinLen1<TimeseriesForBvForDraw> | undefined;

    let discontinuousDas = undefined as
      | undefined
      | NonNullable<
          ReturnType<typeof buildDrawDataForDiscontinuousDRA>
        >["daBar"];

    switch (effectiveXAxisMode) {
      case BatchViewMode.series:
        if (hasExcludedModes && isContinuous) {
          const d = buildDrawDataForDiscontinuousDRA(
            timeseriesData,
            excludedModes,
            iife(() => {
              const x = overrideXAxis;
              if (!x) throw new Error("expected overrideXAxis");
              return x;
            }),
            timezone
          );
          drawData = d?.timeseries;
          discontinuousDas = d?.daBar;
        } else {
          drawData = buildDrawDataForRegularSeriesView(
            timeseriesData,
            excludedModes
          );
        }
        break;
      case BatchViewMode.ParallelStage:
        if (!stages) throw new Error("impossible");
        drawData = buildDrawDataForParallelAlignByStageView(
          timeseriesData,
          R.mapToObj(stages, (x) => [x._id, x])
        );
        break;
      case BatchViewMode.ParallelStart:
        drawData = buildDrawDataForAlignByStartView(timeseriesData);
        break;
      default:
        const _: never = effectiveXAxisMode;
        throw new Error("unreachable");
    }

    if (!drawData) return earlyReturnClearCanvases(5); // no data left after transforms

    const drawAndCleanup = () => {
      const svgCleanup = draw({
        discontinuousDas: discontinuousDas,
        limits,
        dayHoverCanvas,
        darkTrendCanvas,
        timezone: timezone,
        app: iife(() => {
          // use this to pass in app specific options to the draw function
          switch (app) {
            case "ARC":
              return { type: "ARC" };
            case "DRA":
              return {
                type: "DRA",
                slopes,
                /**
                 * The fullscreen chart isn't allowed to expand, so if we
                 * are in full screen, always draw single variable view
                 * with expanded da.
                 *
                 * Otherwise, only draw expanded if the chart is expanded.
                 */
                drawExpandedDaWhenOneVariable: isRenderedAsFullscreen
                  ? true
                  : expanded,
              };
            default:
              const _: never = app;
              throw new Error("unreachable");
          }
        }),
        stillUseColorForAnomalyColorationOff,
        padding,
        variableIdFromBatchVariable: (bv) => bv.slice(24),
        lineWidthScale,
        theme,
        isModeTransparency,
        drawOptionsMap: R.mapToObj(allBatchVariables, (x) => {
          switch (x.type) {
            case "expression":
              return [
                x.id,
                { anomPatches: false, color: x.color, daBars: false },
              ];
            case "variable":
              return [
                x.bv,
                {
                  anomPatches: x.anom,
                  color: x.color,
                  daBars: shouldDrawDaBars ?? x.anom,
                },
              ];
            default:
              const _: never = x;
              throw new Error("unreachable");
          }
        }),
        data: drawData,
        onlyMaxMinYAxes: onlyMinMaxYAxes,
        height: modifiedHeight,
        width: width,
        hoverAffectedCanvas: trendCanvas,
        svg: svgElt,
        hoverAgnosticCanvas: stageSepCanvas,
        jsdomCanvas: undefined,
        viewMode: yAxisMode ?? YAxisMode.Absolute,
        overlapMode: xAxisMode ?? BatchViewMode.series,
        hackForDemo,
        anomalyRectangles,
        axesFontScale,
        primaryTrendLineBatchVariableOrId: allBatchVariables[0],
        overrideXAxisBounds: overrideXAxis,
        redBlockStart,
        taller: false,
        noDaBars,
        primaryVariableColor:
          primaryColor && primaryColor
            ? [primaryColor, darkerColor]
            : undefined,
        numTicks,
        analysisPeriod: iife(() => {
          if (
            analysisPeriodStart === undefined ||
            analysisPeriodEnd === undefined
          )
            return undefined;
          return [analysisPeriodStart, analysisPeriodEnd];
        }),
        anomalyPatchesThicknessScale,
        daBarHeightScale: allBatchVariables.length > 1 ? 1 : 1.1,
        interactivity: {
          setXScale: (scale) => {
            jotaiStore.set(Atoms.setXScaleAtom, scale);
          },
          spotlight,
          brushing:
            brushState && brushRef.current
              ? {
                  get: () => brushState.brushStore.getState().unorderedRange,
                  set: (d) => brushState.brushStore.getState().setRange(d),
                  canvas: brushRef.current,
                  setHandleData: (d) =>
                    brushState.brushStore.getState().setHandleData(d),
                  setRedrawer: (d) =>
                    brushState.brushStore.getState().setRedrawer(d),
                }
              : undefined,
          notifyDomain: setDrawnDomain,
          notifyGlobalRange: debouncedSetRangoInfo,
          hoveredLineSync: {
            get: () => jotaiStore.get(Atoms.hoveredLineAtom),
            set: (line) => jotaiStore.set(Atoms.hoveredLineAtom, line),
          },
          zoom: {
            get: () => jotaiStore.get(jotaiStore.get(Atoms.zoomAtomAtom)),
            set: (line) =>
              jotaiStore.set(jotaiStore.get(Atoms.zoomAtomAtom), line),
          },
          onLineClick: iife(() => {
            if (!onLineClick) return undefined;
            return ({ bvOrId, clientX, clientY }) => {
              onLineClick?.({ bvOrId, clientX, clientY, jotaiStore });
            };
          }),

          onHover: (hoverData) => {
            if (hoverData) {
              const {
                percentageLeft,
                intersectionPointsMap,
                line,
                point,
                percentageTop,
              } = hoverData;
              debouncedSetHoverIntersectionMap(intersectionPointsMap);
              const div = jotaiStore.get(Atoms.canvasesContainerAtom);
              div &&
                useTooltipStore?.getState().setTooltipData({
                  // the draw function shouldn't have to care about where the top of the svg is
                  ...line,
                  percentageTop,
                  percentageLeft,
                  stageId: point.stageId,
                  v: point.v,
                  t: point.t,
                  shouldDisplayTagId: allBatchVariables.length > 1,
                  container: div,
                  slopingTrendId: hoverData.slopingTrendId,
                });
            } else {
              useTooltipStore?.getState().removeHoverData();
              debouncedSetHoverIntersectionMap(undefined);
            }
          },
          ignoreMouseLeaveSvgIfFocusGoesIntoId: ID_FOR_CLICKED_LINE_OVERLAY,
          subscribeToOnHighlightRedrawer: (redraw) => {
            /**
             * We can't do:
             * `jotaiStore.set(Atoms.redrawCanvasFnAtom, redraw);`
             *
             * as expected because React will think we're using a
             * state updater function and actually call it.
             *
             * It will try to store redraw(), when we want to store redraw.
             */
            jotaiStore.set(Atoms.redrawCanvasFnAtom, () => redraw);
          },
        },
        yClamps: R.mapToObj(allBatchVariables, (x) => [
          getBvOrId(x),
          [x.yClampMin, x.yClampMax],
        ]),
      });

      const removeStoreSubscription = jotaiStore.sub(
        Atoms.hoveredLineAtom,
        () => {
          const redraw = jotaiStore.get(Atoms.redrawCanvasFnAtom);
          if (!redraw) return;
          const bv = jotaiStore.get(Atoms.hoveredLineAtom);
          redraw(bv);
        }
      );

      return () => {
        removeStoreSubscription();
        jotaiStore.set(Atoms.redrawCanvasFnAtom, undefined); // remove the reference to the old redrawer, as the next render will have a new one

        svgCleanup({
          shouldResetZoomOnSvgHTMLElement:
            xAxisMode !==
            jotaiStore.get(jotaiStore.get(Atoms.xAxisModeAtomAtom)),
        });
      };
    };

    return drawAndCleanup();
  }, [
    expanded,
    shouldDrawDaBars,
    inView,
    hackForDemo,
    isModeTransparency,
    jotaiStore,
    setDrawnDomain,
    anomalyRectangles,
    redBlockStart,
    limits,
    slopes,
    noDaBars,
    isRenderedAsFullscreen,
    app,
    overrideXAxis,
    onLineClick,
    axesFontScale,
    padding,
    theme,
    hFactor,
    openSidebar,
    setHoverIntersectionMap,
    lineWidthScale,
    shouldRender,
    GREY,
    brushState,
    stages,
    data,
    modifiedHeight,
    width,
    batchVariablesToRender,
    allBatchVariables,
    excludedModes,
    onlyMinMaxYAxes,
    setGlobalRange,
    primaryColor,
    darkerColor,
    anomalyPatchesThicknessScale,
    numTicks,
    analysisPeriodStart,
    analysisPeriodEnd,
    isContinuous,
    timezone,
    stillUseColorForAnomalyColorationOff,
    spotlight,
    yAxisMode,
    xAxisMode,
    useTooltipStore, // never changes, but eslint wants it here
  ]);

  const setContainer = useSetAtom(Atoms.canvasesContainerAtom);

  const cb = useCallback(
    (e: HTMLDivElement | null) => {
      setContainer(e);
    },
    [setContainer]
  );

  const onlyShowBrushHandlesIfFullscreen = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom
  );

  const canShowBrushHandles = onlyShowBrushHandlesIfFullscreen
    ? isRenderedAsFullscreen
    : true;

  // this is a new mode we added later on that doesn't require this chart to be drawn
  if (yAxisMode === YAxisMode.Variability) return null;
  if (!shouldRender) return null;

  return (
    <div className="relative" ref={cb}>
      {/* put the svg on top of the canvas so it can respond to mouse events */}
      <svg
        className={
          "WHY_DAN absolute inset-0 z-10 mx-auto my-0 w-full overflow-visible select-none"
        }
        ref={svgRef}
      />
      <canvas
        className="absolute inset-0 mx-auto my-0 w-full"
        ref={stageSepRef}
      />
      <canvas className="absolute inset-0 mx-auto my-0 w-full" ref={trendRef} />
      <canvas
        className="absolute inset-0 mx-auto my-0 w-full"
        ref={darkTrendRef}
      />
      {canShowBrushHandles && brushState && (
        <>
          <BrushHandle which="left" />
          <BrushHandle which="right" />
          <canvas
            className="absolute z-[9] inset-0 mx-auto my-0 w-full"
            ref={brushRef}
          />
        </>
      )}
      {!hideCommentPills && <CommentPills />}
      <canvas
        className="mx-auto my-0 w-full overflow-clip"
        ref={dayHoverRef}
        height={height / hFactor}
        width={width}
      />
    </div>
  );
}

function BrushHandle({ which }: { which: "left" | "right" }) {
  const container = useAtomValue(Atoms.canvasesContainerAtom);
  const idx = iife(() => {
    switch (which) {
      case "left":
        return 0;
      case "right":
        return 1;
      default:
        const _: never = which;
        throw new Error("unreachable");
    }
  });

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  if (!brushState) throw new Error("impossible");
  const { brushStore } = brushState;

  const [dragging, setIsDragging] = useState(false);

  const leftRight = useStore(brushStore, (s) => s.unorderedRange);
  const bound = leftRight[idx];
  const handleData = useStore(brushStore, (s) => s.handleData);
  const setRange = useStore(brushStore, (s) => s.setRange);

  const xScale = useAtomValue(Atoms.getXScaleAtom);

  useEffect(function handleReleaseMouse() {
    const cb = () => setIsDragging(false);
    document.addEventListener("mouseup", cb);

    return () => document.removeEventListener("mouseup", cb);
  }, []);

  useEffect(
    function handleDrag() {
      if (!dragging || !xScale) return;

      const cb = (e: MouseEvent) => {
        if (!container) throw new Error("impossible");
        const { width, left } = container.getBoundingClientRect();
        const percentLeft = (e.clientX - left) / width;
        const time = xScale.invert(percentLeft);
        if (time === undefined) {
          // probably dragged into padding area
          return;
        }

        setRange((curr) => {
          const copy = [...curr] as typeof curr;
          copy[idx] = time;
          return copy;
        });
      };

      document.addEventListener("mousemove", cb);
      return () => document.removeEventListener("mousemove", cb);
    },
    [dragging, container, setRange, idx, xScale]
  );

  const rightBrushFixed = bound === undefined;
  if (!xScale || !handleData || rightBrushFixed) return null;
  const leftPercent = xScale(bound);

  return (
    leftPercent !== undefined && (
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
          setIsDragging(true);
        }}
        onMouseUp={() => setIsDragging(false)}
        className={
          "absolute z-[50] -translate-x-1/2 rounded-lg cursor-ew-resize px-3 select-none group"
        }
        style={{
          left: `${leftPercent * 100}%`,
          top: `${handleData.topPercent * 100}%`,
          height: `${handleData.heightPercent * 100}%`,
        }}
        data-vaul-no-drag
      >
        <div
          className={cn(
            "relative w-[5px] h-full rounded-lg select-none group-hover:scale-[103%] transition-all duration-200 ease-in-out",
            leftRight[1] === undefined
              ? "bg-red-800 group-hover:bg-red-700"
              : "bg-zinc-800 group-hover:bg-zinc-700"
          )}
        >
          <div
            className={cn(
              "brush__triangle top-0 left-1/2 -translate-x-1/2 -translate-y-[120%] absolute group-hover:block hidden",
              leftRight[1] === undefined ? "bg-red-700" : "bg-amber-500"
            )}
          />
        </div>
      </div>
    )
  );
}

export { ID_FOR_CLICKED_LINE_OVERLAY, useShouldRenderOrReact, TimeseriesChart };
