import { atom, useAtomValue, useSetAtom, useStore } from "jotai";
import { BaseQueryOptions, Comments } from "../../../../hooks/tanstack-query";
import {
  Atoms,
  isVariableTrendLine,
} from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { DateTime } from "luxon";
import { useTimezone } from "../../../../zustand/config/useConfigStore";
import Comment from "../../../../types/api/Comment";
import { useMemo } from "use-memo-one";
import {
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "../../../../shared-ui/frontend/hover-card";
import { cn } from "../../../../lib/utils";
import { getFullName } from "../../../../shared-ui/lib/user-utils";
import {
  useIsFullscreen,
  useToggleFullscreen,
} from "../../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { CommentTypeBadge } from "../../../comments/comment-type-badge";
import { minutesToMilliseconds } from "date-fns";

const isOpenIssue = (comment: Comment) => {
  return comment.type === "Issue" && !comment.issue_resolved;
};

function useCommentsListQuery(opts?: BaseQueryOptions) {
  const p = useAtomValue(Atoms.primaryBatchVariableAtom);
  const zone = useTimezone();
  const [start_date, end_date] = useAtomValue(Atoms.getDomainAtom);

  const primaryIsVariableTrendLine = isVariableTrendLine(p);

  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);

  const s = DateTime.fromMillis(start_date, { zone })
    .setZone("utc", { keepLocalTime: true })
    .toMillis();
  const e = DateTime.fromMillis(end_date, { zone })
    .setZone("utc", { keepLocalTime: true })
    .toMillis();

  const variableId = primaryIsVariableTrendLine
    ? p.bv.slice(24)
    : "dummy-not-used";

  const publicComments = Comments.list.useQuery(
    {
      start_date: s,
      end_date: e,
      variableId,
    },
    {
      enabled: canRequestData && primaryIsVariableTrendLine,
      staleTime: minutesToMilliseconds(5),
      ...opts,
    }
  );

  const privateComments = Comments.list.useQuery(
    {
      start_date: s,
      end_date: e,
      variableId,
      private: true,
    },
    {
      enabled: canRequestData && primaryIsVariableTrendLine,
      staleTime: minutesToMilliseconds(5),
      ...opts,
    }
  );

  return { publicComments, privateComments };
}

const hoverTimeAtom = atom<number | undefined>(undefined);

function CommentPills() {
  const { privateComments, publicComments } = useCommentsListQuery();

  return (
    <>
      {publicComments.data?.docs.map((comment) => {
        return <Pill comment={comment} key={comment._id} />;
      })}
      {privateComments.data?.docs.map((comment) => {
        return <Pill comment={comment} key={comment._id} />;
      })}
    </>
  );
}

function Pill({ comment }: { comment: Comment }) {
  const zone = useTimezone();
  const xScale = useAtomValue(Atoms.getXScaleAtom);

  const setHoverTime = useSetAtom(hoverTimeAtom);

  const openIssue = isOpenIssue(comment);

  const [startAsZone, endAsZone] = useMemo(() => {
    const f = (t: string) =>
      DateTime.fromISO(t, {
        zone: "utc",
      })
        .setZone(zone, {
          keepLocalTime: true,
        })
        .toMillis();

    return [
      f(comment.start_date),
      // draw it til the end of the chart if it's an open issue
      openIssue ? undefined : f(comment.end_date),
    ] as const;
  }, [comment, zone, openIssue]);

  if (!xScale) return null;

  const getPosition = () => {
    const d = xScale.domain();

    if (startAsZone > d[1] || (endAsZone !== undefined && endAsZone < d[0])) {
      return undefined; // don't render if outside of domain
    }

    /**
     * Calculate the percentage distance the pill is from the container
     */
    const r = xScale.range(); // r[0] would indicate the percentage distance from the left of the container that the padding left is

    const leftPercent = xScale(startAsZone);
    const rightPercent = endAsZone === undefined ? r[1] : xScale(endAsZone);

    // if (leftPercent === undefined && rightPercent === undefined) {
    //   return undefined; // don't render if it's outside of the domain (or padding area)
    // }

    const roundedLeft = leftPercent !== undefined; // can be undefined is they go into padding area
    const roundedRight = rightPercent !== undefined;

    // if it goes into padding area, don't draw that part. draw up to the padding.
    const leftPercentFromLeft = leftPercent ?? r[0];
    const rightPercentFromLeft = rightPercent ?? r[1];
    const widthPercent = rightPercentFromLeft - leftPercentFromLeft;

    // we don't need the right side once we have the width (because CSS can handle it)

    return {
      left: leftPercentFromLeft,
      width: widthPercent,
      roundedLeft,
      roundedRight,
    };
  };

  const render = getPosition();

  if (!render) return null;

  return (
    <HoverCardRoot openDelay={50} closeDelay={50}>
      <HoverCardTrigger
        onMouseMove={(e) => {
          const mouseX = e.clientX;

          // position of the pill
          const { left, right } = (
            e.target as HTMLElement
          ).getBoundingClientRect();

          // how far from the left are we inside the pill?
          const percentFromLeftOfPill = (mouseX - left) / (right - left);

          // translate that to the percent from left of the entire container
          const toInvert = percentFromLeftOfPill * render.width + render.left;

          // invert the percent to a time value (using a d3-like scale)
          const t = xScale.invert(toInvert);
          if (t === undefined) throw new Error("impossible too");

          // store what time we just hovered on
          setHoverTime(t);
        }}
        style={{
          left: `${render.left * 100}%`,
          width: `${render.width * 100}%`,
        }}
        // ensure z index is high enough so we can hover
        className={cn(
          "absolute h-[5px] @lg:h-[7px] @2xl:h-[8.5px] @3xl:h-[11px] bottom-0 @3xl:translate-y-[20%] z-[11] border-y border-transparent   transition-colors duration-200 ease-in-out hover:z-[99999]",

          openIssue
            ? "bg-red-300/40 hover:bg-red-200 hover:border-red-600"
            : comment.private
              ? "bg-amber-200/40 hover:bg-amber-200 hover:border-amber-500"
              : "bg-zinc-300/40 hover:bg-zinc-200 hover:border-indigo-600",
          render.roundedLeft && "rounded-l-md border-l",
          render.roundedRight && "rounded-r-md border-r"
        )}
      />
      <PillHoverContent comment={comment} />
    </HoverCardRoot>
  );
}

function PillHoverContent({ comment }: { comment: Comment }) {
  const { privateComments, publicComments } = useCommentsListQuery();
  const zone = useTimezone();
  const hoverTime = useAtomValue(hoverTimeAtom);

  /**
   *
   */
  const getAllIntersectionComments = () => {
    if (
      !privateComments.data ||
      !publicComments.data ||
      hoverTime === undefined
    )
      return undefined;

    /**
     * The chart uses real dates and the comments implementation
     * uses fake dates. So to properly compare them and position
     * them, we need to convert the actual time we hovered on to
     * a fake date.
     */
    const fakeHoverTime = DateTime.fromMillis(hoverTime, {
      zone,
    })
      .setZone("utc", {
        keepLocalTime: true,
      })
      .toMillis();

    const publicFiltered = publicComments.data.docs.filter((x) => {
      const s = new Date(x.start_date).getTime();

      // make the comment "longer than it actually is" if it's an open issue
      // so it can get detected by the hover
      const e = isOpenIssue(x) ? Infinity : new Date(x.end_date).getTime();
      return s <= fakeHoverTime && e >= fakeHoverTime; // if the fake date is within the range
    });

    const privateFiltered = privateComments.data.docs.filter((x) => {
      const s = new Date(x.start_date).getTime();
      const e = new Date(x.end_date).getTime();
      return s <= fakeHoverTime && e >= fakeHoverTime; // if the fake date is within the range
    });

    return publicFiltered.concat(privateFiltered);
  };
  const list = getAllIntersectionComments();

  return (
    <HoverCardContent className="flex flex-col p-0 overflow-clip" side="top">
      {list ? (
        <>
          {list.map((x) => {
            return <Clickable comment={x} key={x._id} />;
          })}
        </>
      ) : (
        // if no hovering or query hasn't resolve, just show myself
        <Clickable comment={comment} />
      )}
    </HoverCardContent>
  );
}

function Clickable({ comment }: { comment: Comment }) {
  const jot = useStore();
  const toggleFullscreen = useToggleFullscreen();
  const isFullscreen = useIsFullscreen()(jot);

  const openFullscreenOnCommentPillClick = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom
  );

  const setSelectedCommentId = useSetAtom(Atoms.selectedCommentIdAtom);
  const tz = useTimezone();
  const openIssue = isOpenIssue(comment);

  return (
    <div
      className={cn(
        "border-y border-xslate-6 transition-colors duration-200 ease-in-out",
        openIssue
          ? "bg-xred-3 hover:bg-xred-4 active:bg-xred-5 text-xred-11"
          : "hover:bg-xslate-2"
      )}
    >
      <button
        onClick={() => {
          setSelectedCommentId(comment._id);
          if (
            openFullscreenOnCommentPillClick &&
            toggleFullscreen &&
            !isFullscreen
          )
            toggleFullscreen(jot);
        }}
        className="p-2.5 text-xs transition active:scale-95 text-left flex flex-col border-0 w-full"
      >
        <span className="select-none font-semibold tracking-tight">
          {getFullName(comment.author)}
        </span>
        <span className="select-none mb-3">{comment.text}</span>

        <CommentTypeBadge comment={comment} />
        <div className="mt-3 flex items-center justify-between w-full">
          <span className="">
            {DateTime.fromISO(comment.created_at, {
              /**
               * Created at dates are created automatically by mongo,
               * so we don't have to do any fake date stuff here.
               *
               * Simply parse the real date, them format it in the user's
               * tz.
               */
              zone: tz,
            }).toFormat("LLL d yyyy, h:mm a")}
          </span>
          {comment.replies.length > 0 && (
            <span className="font-light">({comment.replies.length})</span>
          )}
        </div>
      </button>
    </div>
  );
}

export { CommentPills, useCommentsListQuery };
