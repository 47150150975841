import classNames from "classnames";

function SubTitle({ title }: { title: string }) {
  return (
    <div className={classNames("flex text-[1.4rem] pb-3")}>
      <span>{title}</span>
    </div>
  );
}

export default SubTitle;
