import {
  AnomalyLevelEnum,
  AnomalyValidator,
} from "../../shared-ui/lib/anomaly-levels";
import { type MinLen1 } from "../../shared-ui/lib/types";
import * as R from "remeda";
import { TimeseriesForBv } from "../../shared-ui/time-series-2/types";
import { iife, minLen1 } from "../../shared-ui/lib/utils";

export type DRATimeseries = {
  value: number;
  variable: string;
  // stage: string;
  timestamp: number;
  da: number | null;
  mode: string | null;
  // batchVariable: string;
}[];

export function chartFormat(data: DRATimeseries, batch: string) {
  const ignoreSpecialAnomalyValue = (
    d: number | null
  ): AnomalyLevelEnum | null => {
    if (d === null) return d;

    const parsed = AnomalyValidator.safeParse(d);

    if (parsed.success) return parsed.data;
    return null;
  };

  type Segment = {
    pts: MinLen1<{
      v: number;
      t: number;
    }>;
    d: number | null;
    r: [number, number];
  };

  const partitionByAnomaly = (
    s: [
      {
        value: number;
        variable: string;
        timestamp: number;
        da: number | null;
        mode: string | null;
      },
      ...{
        value: number;
        variable: string;
        timestamp: number;
        da: number | null;
        mode: string | null;
      }[],
    ]
  ): MinLen1<Segment> => {
    const partitionedByAnomaly: Array<Segment> = [];

    const leftoverPoints = s;

    while (leftoverPoints.length > 0) {
      const firstPoint = leftoverPoints[0];
      if (!firstPoint) throw new Error("impossible");

      const anom = ignoreSpecialAnomalyValue(firstPoint.da);

      const idx = leftoverPoints.findIndex(
        (p) => ignoreSpecialAnomalyValue(p.da) !== anom
      );

      if (idx === -1) {
        // all the same

        partitionedByAnomaly.push({
          d: ignoreSpecialAnomalyValue(leftoverPoints[0].da),
          pts: iife(() => {
            const out = leftoverPoints.map((x): Segment["pts"][number] => {
              return {
                t: x.timestamp,
                v: x.value,
              };
            });
            if (!minLen1(out)) throw new Error("impossible");

            return out;
          }),
          r: [
            R.minBy(leftoverPoints, (x) => x.value)!.value,
            R.maxBy(leftoverPoints, (x) => x.value)!.value,
          ],
        });

        break;
      }

      const firstPart = leftoverPoints.splice(0, idx);
      if (!minLen1(firstPart)) throw new Error("impossible");

      const firstPointOfNextSegment = leftoverPoints[0]; // 0 because we spliced it

      firstPart.push({
        /**
         * Each point in partition is from the same
         * batchvariable and stage, but they have different
         * DAs. We want to show them as "connected" in the
         * chart, so we add a point to the end of each
         * partition to artificially connect them.
         */
        ...firstPointOfNextSegment,
        da: anom,
        timestamp: firstPointOfNextSegment.timestamp - 1,
        // minus 1 millisecond to connect them
      });

      partitionedByAnomaly.push({
        d: ignoreSpecialAnomalyValue(firstPart[0].da),
        pts: iife(() => {
          const out = firstPart.map((x): Segment["pts"][number] => {
            return {
              t: x.timestamp,
              v: x.value,
            };
          });

          if (!minLen1(out)) throw new Error("impossible");
          return out;
        }),
        r: [
          R.minBy(firstPart, (x) => x.value)!.value,
          R.maxBy(firstPart, (x) => x.value)!.value,
        ],
      });
    }

    if (!minLen1(partitionedByAnomaly)) throw new Error("expected >=1");

    return partitionedByAnomaly;
  };

  const m = R.groupBy(data, (d) => d.variable);

  // we need them sorted
  for (const arr of Object.values(m)) {
    arr.sort((a, b) => a.timestamp - b.timestamp);
  }

  const out: (TimeseriesForBv & { driftTime: number })[] = [];

  for (const variableId in m) {
    const sortedPoints = m[variableId]!;

    // domain and range for all the data of this variable id
    const d: [number, number] = [Infinity, -Infinity];
    const r: [number, number] = [Infinity, -Infinity];
    for (const x of sortedPoints) {
      if (x.timestamp < d[0]) d[0] = x.timestamp;
      if (x.timestamp > d[1]) d[1] = x.timestamp;
      if (x.value < r[0]) r[0] = x.value;
      if (x.value > r[1]) r[1] = x.value;
    }

    let left = 0;

    const partitionedByMode: Array<typeof sortedPoints> = [];

    while (left < sortedPoints.length) {
      const currStage = sortedPoints[left]!.mode;

      let right = left + 1;

      while (
        right < sortedPoints.length &&
        sortedPoints[right]!.mode === currStage
      ) {
        right++;
      }

      const stagePoints = sortedPoints.slice(left, right);

      if (!minLen1(stagePoints)) throw new Error("impossible");
      partitionedByMode.push(stagePoints);

      left = right;
    }

    const stages = partitionedByMode.map(
      (x): TimeseriesForBv["stages"][number] => {
        return {
          _id: x[0].mode ?? "Remainder",
          d: [x[0].timestamp, x[x.length - 1]!.timestamp],
          r: [
            R.minBy(x, (x) => x.value)!.value,
            R.maxBy(x, (x) => x.value)!.value,
          ],
          ptsPartitioned: partitionByAnomaly(x),
        };
      }
    );

    if (!minLen1(stages)) throw new Error("impossibel");

    const out_: TimeseriesForBv = {
      type: "variable",
      bv: `${batch}${variableId}`,
      stages,
      d,
      r,
    };

    // count duration this variable has da > 0
    const duration = 0;
    // data.filter(
    //   (d) =>
    //     d.variable === variable &&
    //     (d.da || 0) > 0 &&
    //     // only keep minutes that end with 0 to filter out vectorized interpolation
    //     // so we can count time by counting the number of minutes
    //     d.timestamp.endsWith(":00.000Z")
    // ).length;

    out.push({
      ...out_,
      driftTime: duration,
    });
  }

  return out;
}
