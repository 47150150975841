import { type MultiSelectValueProps, Box, CloseButton } from "@mantine/core";

function LabelMultiSelectValue({
  value,
  label,
  onRemove,
  classNames,
  color,
  ...others
}: MultiSelectValueProps & { value: string }) {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: "flex",
          cursor: "default",
          alignItems: "center",
          backgroundColor: color,
          color: theme.white,
          paddingLeft: 10,
          paddingRight: 6,
          borderRadius: 4,
        })}
      >
        <Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
        <CloseButton
          className="text-black hover:bg-red-300 hover:text-red-500 ml-1"
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

export default LabelMultiSelectValue;
