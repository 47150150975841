import { type Image as ImageType } from "./types/ComponentTypes";
import { generateStyleObjectFromMarginOptions } from "../utils";
import { useEffect, useRef, useState } from "react";
import { th } from "date-fns/locale";

/**
 * Default is numbered, pass in bullets=true for a bulleted list
 */
export default function Image({
  marginTop,
  marginBottom,
  content,
  id,
  setImageLoaded,
  allAssetsLoaded,
}: ImageType & {
  setImageLoaded: (imageId: string) => void;
  allAssetsLoaded: boolean;
}) {
  const containerRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!allAssetsLoaded) {
      const timer = setInterval(() => {
        const elt = containerRef.current;
        if (!elt) throw new Error("containerRef is null");

        if (elt.complete) {
          setImageLoaded(id);
        }
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [allAssetsLoaded, id]);

  return (
    <div
      className="flex justify-center"
      style={generateStyleObjectFromMarginOptions(marginTop, marginBottom)}
    >
      <img
        ref={containerRef}
        alt="TODO"
        src={content}
        width={"80%"}
        style={{ border: "1px solid lightgrey" }}
      />
    </div>
  );
}
