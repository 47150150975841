import { useEffect, useState } from "react";
import { atom, useStore } from "jotai";
import { Atoms } from "./use-svv-store";
import {
  getBvOrId,
  trendDataMatches,
  type TrendLineVariant,
} from "../draw/draw";

const hoverValueForBvAtom = atom((get) => {
  const m = get(Atoms.hoverIntersectionMapAtom);
  return (bv: TrendLineVariant) => m?.[getBvOrId(bv)];
});

function useDebouncedHoverDataForTrendLine(trendLine: TrendLineVariant) {
  const jot = useStore();

  /**
   * Normally I would say this is a terrible pattern (data syncing),
   * but this data changes very fast and we have a card for each
   * batch variable, so this ends up being a lot of render cycles.
   *
   * It actually causes the chart to lag during interaction if the
   * sidebar is open (and so these cards are showing), because React
   * steals time between each draw cycle. So, we defer the component
   * updates by debouncing.
   *
   * In short, store copies of the state, and sync it up every x seconds,
   * at which point the component will rerender, allowing the chart redraws
   * to take priority.
   */
  const [hoverData, setV] = useState<{ v: number; t: number } | undefined>(
    () => jot.get(Atoms.hoverIntersectionMapAtom)?.[getBvOrId(trendLine)]
  );
  const [isHovered, setIsHovered] = useState(() => {
    const hoveredLine = jot.get(Atoms.hoveredLineAtom);
    if (!hoveredLine) return false;

    return trendDataMatches(hoveredLine, trendLine);
  });

  useEffect(() => {
    let timer = undefined as undefined | ReturnType<typeof setTimeout>;
    const cleanup = jot.sub(hoverValueForBvAtom, () => {
      const hoveredStateValue = jot.get(hoverValueForBvAtom)(trendLine);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setV(hoveredStateValue);
      }, 50);
    });

    return () => {
      cleanup();
      clearTimeout(timer);
    };
  }, [trendLine, jot]);

  useEffect(() => {
    let timer = undefined as undefined | ReturnType<typeof setTimeout>;

    const cleanup = jot.sub(Atoms.hoveredLineAtom, () => {
      const newHoveredLine = jot.get(Atoms.hoveredLineAtom);
      const isHovered = newHoveredLine
        ? trendDataMatches(newHoveredLine, trendLine)
        : false;

      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsHovered(isHovered);
      }, 50);
    });

    return () => {
      cleanup();
      clearTimeout(timer);
    };
  }, [trendLine, jot]);

  return [isHovered, hoverData] as const;
}

export { useDebouncedHoverDataForTrendLine as useDebouncedHoverDataForBatchVariable };
