import { z } from "zod";
import { AnomalyValidator } from "../../types/api/Anomaly";

// export const anomalySummarySchema = z.object({
//   date: z.string().datetime(),
//   shutdown: z.boolean(),
//   levelCounts: z.record(z.string(), z.number().int()),
// });
export const anomalySummarySchema = z
  .object({
    date: z.string().datetime(),
    shutdownMilliseconds: z.number().int(),
    levelCounts: z.record(z.string(), z.number().int()),
    levelVariableIds: z.record(z.string(), z.string().array()),
  })
  .refine(({ levelVariableIds, levelCounts }) => {
    return (
      Object.keys(levelVariableIds).every((anom) => {
        return AnomalyValidator.safeParse(parseInt(anom)).success;
      }) &&
      Object.keys(levelCounts).every((anom) => {
        return AnomalyValidator.safeParse(parseInt(anom)).success;
      })
    );
  });

export type anomalySummarySchema = z.infer<typeof anomalySummarySchema>;
