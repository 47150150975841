import { z } from "zod";
export { UserRole } from "../../../lib/api-schema/user";
export { UserRole as default } from "../../../lib/api-schema/user";

enum UserRoleEnum {
  "User" = 0,
  "Admin" = 1,
  "Root" = 2,
}

export type UserRoleString = keyof typeof UserRoleEnum;

export const UserRoleNumber = z.nativeEnum(UserRoleEnum);
