import { Tooltip as MantineTooltip } from "@mantine/core";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import { DATESTATE_SP_KEYS } from "../../zustand/useDateState";
import {
  defaultRangeIndex,
  defaultSlopingTrendsRangeIndex,
} from "../../constants/dateState";
import { useSearchParams } from "react-router-dom";
import { cn, iife } from "../../lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shared-ui/frontend/popover";
import { Button } from "../../shared-ui/frontend/button";
import {
  FaChartLine,
  FaEllipsisH,
  FaFilter,
  FaTimesCircle,
} from "react-icons/fa";
import { Check, X } from "lucide-react";
import { useGetUseViewModeStore } from "../../shared-ui/time-series-2/grid-view-store";
import { useGetUseProfileBookStoreRequired } from "./use-profile-book-store";
import { useGetUseCreateOrEditFolderStore } from "./create-or-edit-folder-store";
import { ViewModeSelectors } from "../common/view-mode-selectors";
import { useGetUseDisplayedChartsStores } from "./use-displayed-charts-store";
import { Input } from "../../shared-ui/frontend/input";
import { useTagsFilterStoreContext } from "../tags/use-tags-filter-store";

export function ProfileBookControlButtons() {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const tagsFilterStore = useTagsFilterStoreContext();
  const showSlopingTrends = usePbStore((s) => s.showSlopingTrends);
  const setShowSlopingTrends = usePbStore((s) => s.setShowSlopingTrends);

  const setTitle = usePbStore((s) => s.setTitle);

  const [, setSp] = useSearchParams();

  const setSidebarTab = usePbStore((s) => s.setSidebarTab);
  const switchToFoldersTab = () => setSidebarTab("Folders");

  const useViewModeStore = useGetUseViewModeStore();
  const viewMode = useViewModeStore((s) => s.viewMode);
  const numCols = useViewModeStore((s) => s.numCols);

  const globalAnomaliesToggled = usePbStore((s) => s.showAnomalies);
  const setGlobalAnomalyToggle = usePbStore((s) => s.setShowAnomalies);
  const globalShutdownOff = usePbStore((s) => s.globalShutdownToggle);
  const globalModeTransparencyToggled = usePbStore(
    (s) => s.globalModeTransparencyToggle
  );

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const isEditingOrCreatingFolder = useGetUseCreateOrEditFolderStore()(
    (s) => s.data
  );

  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomTimes = tagsFilterStore((s) => s.anomalyTimeFilter);
  const isDefaultState =
    Object.values(tagFilters).every((v) => v === undefined) &&
    anomTimes.gt === "0m" &&
    anomTimes.lt === "24h";
  const setGt = (el: HTMLInputElement) => {
    tagsFilterStore.getState().setAnomalyTimeFilter({
      gt: el.value,
      lt: anomTimes.lt,
    });
  };
  const setLt = (el: HTMLInputElement) => {
    tagsFilterStore.getState().setAnomalyTimeFilter({
      gt: anomTimes.gt,
      lt: el.value,
    });
  };

  return (
    <>
      {!isEditingOrCreatingFolder && (
        <ViewModeSelectors
          withLabels
          enabledModes={["grid", "list", "chart"]}
          viewMode={viewMode}
          setViewMode={(newViewMode) => {
            useViewModeStore.getState().setViewMode(newViewMode);
          }}
          numCols={numCols}
          setNumCols={(newNumCols) => {
            useViewModeStore.getState().setNumCols(newNumCols);
          }}
        />
      )}
      <MantineTooltip
        position="top"
        label={`${
          showSlopingTrends ? "Clear" : "View"
        } Sloping Trends for all charts`}
      >
        <Button
          variant={showSlopingTrends ? "default" : "ghost"}
          size={"sm"}
          className="whitespace-nowrap"
          onClick={() => {
            const enteringSlopingTrends = !showSlopingTrends;
            setSp((curr) => {
              const copy = new URLSearchParams(curr);
              copy.set(
                DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
                (enteringSlopingTrends
                  ? defaultSlopingTrendsRangeIndex
                  : defaultRangeIndex
                ).toString()
              );

              return copy;
            });

            setShowSlopingTrends(enteringSlopingTrends);
            usePbStore.getState().setShowAnomalies(false);
            usePbStore.getState().setGlobalShutdownToggle(true);
          }}
        >
          <FaChartLine className="size-3 mr-1.5" />
          Sloping Trends
        </Button>
      </MantineTooltip>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={isDefaultState ? "ghost" : "default"} size={"sm"}>
            <FaFilter className="size-3 mr-1.5" /> Filter
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-2 flex flex-col gap-1.5" align="start">
          <h4 className="text-[0.9rem] font-semibold">Filter Tags</h4>
          <Button
            size={"xs"}
            className="justify-normal"
            onClick={() => {
              tagsFilterStore.getState().clearTagFilters();
            }}
            variant={isDefaultState ? "default" : "outline"}
          >
            {isDefaultState ? (
              <Check className="size-4 mr-1.5" />
            ) : (
              <X className="size-4 mr-1.5" />
            )}
            All Tags
          </Button>
          {Object.entries(tagFilters)
            .map(([filter, value]) => {
              return (
                <div className="btn-group" key={filter}>
                  {[true, false].map((bool) => {
                    return (
                      <Button
                        key={`${filter}-${bool ? "on" : "off"}`}
                        size={"xs"}
                        className={cn(
                          "justify-normal w-60",
                          bool ? "rounded-r-none border-r-0" : "rounded-l-none"
                        )}
                        onClick={() => {
                          Object.keys(tagFilters).forEach((f) => {
                            tagsFilterStore
                              .getState()
                              .setTagFilters(
                                f as keyof typeof tagFilters,
                                undefined
                              );
                          });
                          tagsFilterStore
                            .getState()
                            .setTagFilters(
                              filter as keyof typeof tagFilters,
                              bool
                            );
                        }}
                        variant={
                          value === undefined
                            ? "outline"
                            : bool === value
                              ? "default"
                              : "outline"
                        }
                      >
                        {isDefaultState || value === bool ? (
                          <Check className="size-4 mr-1.5" />
                        ) : (
                          <X className="size-4 mr-1.5" />
                        )}
                        {filter === "Clusters"
                          ? bool
                            ? "in Clusters"
                            : "not in Clusters"
                          : (bool
                              ? `with ${filter}`
                              : `without ${filter}`
                            ).replace("Anomalies", "Fresh Anomalies")}
                      </Button>
                    );
                  })}
                </div>
              );
            })
            .flat()}
          <p className="inline-flex text-sm font-medium">
            Anomaly time between:
          </p>
          <div className="flex flex-row">
            <div className="rounded-l border border-xslate-7 h-8 text-sm p-1">
              min:
            </div>
            <Input
              type="text"
              placeholder="e.g. 5m"
              className="rounded-none border-x-0 h-8"
              value={tagsFilterStore((s) => s.anomalyTimeFilter.gt)}
              onInput={(e) => setGt(e.target as HTMLInputElement)}
            />
            <div className="border border-xslate-7 h-8 text-sm p-1">max:</div>
            <Input
              type="text"
              placeholder="e.g. 2h 10m"
              className="rounded-l-none border-l-0 h-8"
              value={tagsFilterStore((s) => s.anomalyTimeFilter.lt)}
              onInput={(e) => setLt(e.target as HTMLInputElement)}
            />
          </div>
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={"ghost"} size={"sm"}>
            <FaEllipsisH className="size-3 mr-1.5" /> More
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-2 flex flex-col gap-1.5" align="start">
          <Button
            size={"sm"}
            variant={globalAnomaliesToggled ? "default" : "destructive"}
            className="justify-normal"
            onClick={() => {
              setGlobalAnomalyToggle(!globalAnomaliesToggled);
            }}
          >
            {iife(() => {
              const Icon = globalAnomaliesToggled ? Check : X;
              return <Icon className="size-4 mr-1.5" />;
            })}
            Anomalies
          </Button>
          <Button
            size={"sm"}
            variant={!globalShutdownOff ? "default" : "destructive"}
            className="justify-normal"
            onClick={() => {
              usePbStore.getState().setGlobalShutdownToggle(!globalShutdownOff);
            }}
          >
            {iife(() => {
              const Icon = !globalShutdownOff ? Check : X;
              return <Icon className="size-4 mr-1.5" />;
            })}
            Shutdowns
          </Button>
          <Button
            size={"sm"}
            variant={globalModeTransparencyToggled ? "default" : "destructive"}
            className="justify-normal"
            onClick={() => {
              usePbStore
                .getState()
                .setGlobalModeTransparencyToggle(
                  !globalModeTransparencyToggled
                );
            }}
          >
            {iife(() => {
              const Icon = globalModeTransparencyToggled ? Check : X;
              return <Icon className="size-4 mr-1.5" />;
            })}
            Mode Transparency
          </Button>
        </PopoverContent>
      </Popover>
      {/* cannot use this button if you are editing or creating a folder */}
      {!isEditingOrCreatingFolder && (
        <MantineTooltip label="Remove charts from view" position="top">
          <Button
            variant={"ghost"}
            size={"sm"}
            onClick={() => {
              setShowSlopingTrends(false);
              setSp((curr) => {
                const copy = new URLSearchParams(curr);
                copy.set(
                  DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
                  defaultRangeIndex.toString()
                );
                copy.delete(DRAParamsMap.selectedCommentId);
                return copy;
              });
              useDisplayedChartsStore.getState().replaceCharts([]);
              switchToFoldersTab();
              setTitle({ type: "current-tags" }); // unselect folder from left bar
              tagsFilterStore.getState().clearTagFilters();
            }}
          >
            <FaTimesCircle className="size-3 mr-1.5" />
            Clear
          </Button>
        </MantineTooltip>
      )}
    </>
  );
}
