import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../shared-ui/frontend/cn";
import DateInput from "./DateInput";
import type { DateFormat, EditableRangeProps } from "./types";
import { FontSizes } from "./styles";
import { DateTime } from "luxon";

export default function EditableDateTimeRange({
  start,
  end,
  className,
  options,
  onChangeHandler,
  close,
}: EditableRangeProps) {
  const dtFormat: DateFormat = options.showTime
    ? "yyyy/MM/dd HH:mm"
    : "yyyy/MM/dd";

  const endInputRef = useRef<HTMLInputElement>(null);
  const [startStr] = useState(DateTime.fromMillis(start).toFormat(dtFormat));
  const [endStr] = useState(DateTime.fromMillis(end).toFormat(dtFormat));

  // focus on input on init
  useEffect(() => {
    endInputRef.current?.focus();
  }, []);

  return (
    <div
      className={cn(
        className,
        "px-3 flex flex-row items-center justify-evenly"
      )}
      id="editable-date-selector"
      key="dt-range"
    >
      <DateInput
        date={startStr}
        showTime={options.showTime}
        onChange={(d) => {
          onChangeHandler({
            start: new Date(d).getTime(),
            end,
          });
        }}
        onSubmit={() => close?.()}
      />
      <span
        id="separator"
        className={cn("px-1 bg-transparent")}
        style={FontSizes[options.fontSize]}
      >
        &mdash;
      </span>
      <DateInput
        date={endStr}
        showTime={options.showTime}
        onChange={(d) => {
          onChangeHandler({
            start,
            end: new Date(d).getTime(),
          });
        }}
        onSubmit={() => close?.()}
        ref={endInputRef}
      />
    </div>
  );
}
