import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import useAuthStore from "../../../zustand/auth/useAuthStore";

/**
 * A hook that sets the document title on the manager pages based on the user's role
 */
function usePermissionBasedDocumentTitleForSettingsPage() {
  const isRootOrAdmin = useAuthStore((s) => s.user?.hasEditPermission);
  useDocumentTitle(`${isRootOrAdmin ? "Advanced " : ""}Settings > DRA`);
}

export default usePermissionBasedDocumentTitleForSettingsPage;
