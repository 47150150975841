import React, { useEffect, useCallback, useMemo } from "react";
import debounce from "./debounce";

/**
 * (fun) is memoized the moment useDebouncedOnWindowResize is called,
 * so ensure it uses no stale values.
 */
export default function useDebouncedOnWindowEvent(EVENT, fun, delay = 300) {
  const debouncedFun = useMemo(() => debounce(fun, delay), []);

  useEffect(() => {
    window.addEventListener(EVENT, debouncedFun);
    return () => window.removeEventListener(EVENT, debouncedFun);
  }, []);

  return debouncedFun;
}
