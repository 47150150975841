import React, { useState } from "react";
import _ from "lodash";
import UserPermissionRoute from "../../login/UserPermissionRoute";
import Button from "../../common/Button/Button";
import Input from "../../common/Input/Input";
import { produce } from "immer";
import Subsection from "./Subsection";
import { Draft as WritableDraft } from "immer";
import { swapTwoItemsInArray } from "./utils";
import { DEFAULT_THEME } from "@mantine/core";
import { type SubSection } from "./types/BuilderTypes";
import { subsectionTemplate } from "./templates";
import { Alert, ColorPicker } from "@mantine/core";
import classNames from "classnames";
import type { HelpPage } from "../pages/types";
import JSONLoader from "./JSONLoader";

const ASSETS_PATH_FROM_PAGES_JS = "../../assets/help/";

export type FormState = {
  sectiontitle: string;
  subtitle: string;
  color: string;
  url: string;
  subsections: SubSection[];
};

function componentStateTemplate(): FormState {
  return {
    color: "#ffffff",
    sectiontitle: "",
    url: "",
    subtitle: "",
    subsections: [subsectionTemplate()],
  };
}

/**
 * A page that helps build other help pages by generating JSON and import statements
 */
function Builder() {
  const [formState, setFormState] = useState<FormState>(
    componentStateTemplate()
  );

  const [codeJSON, setCodeJSON] = useState<string>();
  const [importStatements, setImportStatements] = useState<string>();
  const [assetTypeDeclartion, setAssetTypeDeclaration] = useState<string>();

  function loadPageIntoData(p: HelpPage) {
    setFormState(p);
  }

  function generateError(): string | null {
    if (!formState.url.trim()) {
      return "Url for this page is required. Don't include the '/'";
    }

    if (formState.url.includes("/") || formState.url.includes(" ")) {
      return "The url should not contain '/' or spaces";
    }

    if (!formState.color.trim()) {
      return "Color is required";
    }

    if (!formState.sectiontitle.trim()) {
      return "Section title is required";
    }

    if (!formState.subtitle.trim()) {
      return "Subtitle is required";
    }
    return null;
  }

  const err = generateError();

  const errComp = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  function setFormStateSimple<T extends keyof FormState>(
    k: T,
    v: FormState[T]
  ) {
    setFormState(
      produce((s) => {
        s[k] = v;
      })
    );
  }

  function generateJSON() {
    if (err) {
      return window.alert("Resolve errors before generating code");
    }

    const replaceStringInJsonPairs: [string, string][] = [];

    _.forEach(formState.subsections, (s) => {
      _.forEach(s.content, (c) => {
        if (c.type === "image" || c.type === "pdf" || c.type === "video") {
          replaceStringInJsonPairs.push([
            `"content": "${c.codename}"`,
            `"content": assets.${c.codename}`,
          ]);
        }
        if (c.type === "video") {
          replaceStringInJsonPairs.push([
            `"captionedcontent": "${c.captionedcodename}"`,
            `"captionedcontent": assets.${c.captionedcodename}`,
          ]);
        }
      });
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copiedState: any = produce<any, any>(formState, (draft: any) => {
      draft.sectiontitle.trim();
      draft.color.trim();
      draft.subtitle.trim();
      draft.url.trim();

      _.forEach(draft.subsections, (s) => {
        _.forEach(s.content, (c) => {
          if (c.type === "image" || c.type === "pdf") {
            c.content = c.codename;
          } else if (c.type === "video") {
            c.content = c.codename;
            c.captionedcontent = c.captionedcodename;
          }
        });
      });
    });

    let json = JSON.stringify(copiedState, null, 4);

    _.forEach(replaceStringInJsonPairs, (pair) => {
      json = json.replaceAll(pair[0], pair[1]);
    });

    setCodeJSON(json);
  }

  /**
   * Generates a copy and pasteable import statements to throw into assets.js
   */
  function generateImports(): string {
    const out: string[] = [];

    const variableNames: string[] = [];

    _.forEach(formState.subsections, (s) => {
      _.forEach(s.content, (c) => {
        if (c.type === "image" || c.type === "pdf" || c.type === "video") {
          variableNames.push(c.codename);
          out.push(
            `import ${c.codename} from "${ASSETS_PATH_FROM_PAGES_JS}${c.filename}";`
          );
        }
        if (c.type === "video") {
          variableNames.push(c.captionedcodename);
          out.push(
            `import ${c.captionedcodename} from "${ASSETS_PATH_FROM_PAGES_JS}${c.captionedfilename}";`
          );
        }
      });
    });
    const uniqueVariableNames = _.uniq(variableNames);

    const assetTypeDeclaration = uniqueVariableNames
      .map((s) => s + ": any;")
      .join("\n");

    const statements = _.uniq(out).join("\n");
    setImportStatements(_.uniq(out).join("\n"));
    setAssetTypeDeclaration(assetTypeDeclaration);
    return statements;
  }

  return (
    <UserPermissionRoute rolenum={3}>
      <div className="flex mx-32 pt-10">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            generateJSON();
            generateImports();
            window.alert("Done");
          }}
          className="flex flex-col gap-5 w-full"
        >
          <JSONLoader load={loadPageIntoData} />

          {errComp}
          <div className="flex justify-center gap-3">
            <ColorPicker
              value={formState.color}
              onChange={(color) => {
                setFormStateSimple("color", color);
              }}
              format="hex"
            />
            <ColorPicker
              value={formState.color}
              withPicker={false}
              onChange={(color) => {
                setFormStateSimple("color", color);
              }}
              format="hex"
              swatches={_.flatten(_.values(DEFAULT_THEME.colors))}
            />
            <div
              className="min-w-[20%] rounded-md shadow-md border border-bordgrey2"
              style={{ backgroundColor: formState.color }}
            >
              Your selected color
            </div>
          </div>
          <div className="flex justify-center gap-3 mt-5">
            <div className="form-control">
              <label className="input-group input-group-sm">
                <span>URL</span>
                <Input
                  bordered={true}
                  size="sm"
                  required={true}
                  value={formState.url}
                  onChange={(e) => setFormStateSimple("url", e.target.value)}
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group input-group-sm">
                <span>Title</span>
                <Input
                  bordered={true}
                  size="sm"
                  required={true}
                  value={formState.sectiontitle}
                  onChange={(e) =>
                    setFormStateSimple("sectiontitle", e.target.value)
                  }
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group input-group-sm">
                <span>Subtitle</span>
                <Input
                  bordered={true}
                  size="sm"
                  required={true}
                  value={formState.subtitle}
                  onChange={(e) =>
                    setFormStateSimple("subtitle", e.target.value)
                  }
                />
              </label>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            {formState.subsections.map((s, subsectionIndex, arr) => {
              return (
                <Subsection
                  key={s.id}
                  moveUp={() => {
                    setFormState(
                      produce((s) => {
                        const copy = swapTwoItemsInArray(
                          subsectionIndex,
                          subsectionIndex - 1,
                          s.subsections,
                          true
                        )!;
                        s.subsections = copy;
                      })
                    );
                  }}
                  moveDown={() => {
                    setFormState(
                      produce((s) => {
                        const copy = swapTwoItemsInArray(
                          subsectionIndex,
                          subsectionIndex + 1,
                          s.subsections,
                          true
                        )!;
                        s.subsections = copy;
                      })
                    );
                  }}
                  subsection={s}
                  isFirst={subsectionIndex === 0}
                  isLast={subsectionIndex === arr.length - 1}
                  canDelete={arr.length > 1}
                  delete_={() => {
                    setFormState(
                      produce((s) => {
                        _.remove(
                          s.subsections,
                          (_, idx) => idx === subsectionIndex
                        );
                      })
                    );
                  }}
                  createCopy={() => {
                    setFormState(
                      produce((s) => {
                        const sub = s.subsections[subsectionIndex];

                        if (!sub) throw new Error("Subsection not found");

                        const copy = produce(sub, (sub) => {
                          sub.id = _.uniqueId("subsection-");
                          _.forEach(sub.content, (c) => {
                            c.id = _.uniqueId("content-");
                          });
                        });
                        s.subsections.push(copy);
                      })
                    );
                  }}
                  editSubsection={(
                    mutator: (subsection: WritableDraft<SubSection>) => void
                  ) => {
                    setFormState(
                      produce((s) => {
                        const a = s.subsections[subsectionIndex];
                        if (!a) throw new Error("Subsection not found");
                        mutator(a);
                      })
                    );
                  }}
                />
              );
            })}
          </div>
          <div className="flex">
            <Button
              onClick={() => {
                setFormState(
                  produce((s) => {
                    s.subsections.push(subsectionTemplate());
                  })
                );
              }}
            >
              Add subsection
            </Button>
            <Button type="submit" className="ml-auto">
              Generate code
            </Button>
          </div>
        </form>
      </div>
      <div className="m-12 flex flex-col gap-4">
        <h1>Code</h1>
        <textarea
          readOnly
          className={classNames(
            "textarea textarea-bordered grow min-h-[300px]",
            {}
          )}
          placeholder="Hit Generate Code"
          value={codeJSON}
        />
        <h1>Imports</h1>
        <textarea
          readOnly
          className={classNames(
            "textarea textarea-bordered grow min-h-[300px]",
            {}
          )}
          placeholder="This box should be empty if you did not use any images, pdfs, or videos."
          value={importStatements}
        />
        <h1>Asset Types</h1>
        <textarea
          readOnly
          className={classNames(
            "textarea textarea-bordered grow min-h-[300px]",
            {}
          )}
          placeholder="This box should be empty if you did not use any images, pdfs, or videos."
          value={assetTypeDeclartion}
        />
        <pre className="border border-indigo-400 rounded-md p-2">
          {codeJSON}
        </pre>

        <pre className="border border-indigo-400 rounded-md p-2">
          {importStatements}
        </pre>
      </div>
    </UserPermissionRoute>
  );
}

export default Builder;
