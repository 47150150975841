import React, { useRef } from "react";
import Form from "../../common/Form";
import InputOld from "../../common/InputOld";
import First from "../../common/First";
import Fragment from "../../common/Fragment";
import _ from "lodash";
import flags from "../../../lib/flags";
import * as moment from "moment-timezone";
import "./OperatingLimitsManager.scss";
import UpdatedAt from "../../common/manager/UpdatedAt";
import { Tooltip } from "@mantine/core";
import { LIMIT_STATES } from "../constants";
import "./OperatingLimitsManager.scss";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import Button from "../../common/Button/Button";
import classNames from "classnames";
import { SingleDaySelectWithArrows } from "../../common/calendar";

function OperatingLimitsDropdown({
  prefix,
  deleteLimit,
  tag,
  toggleExpanded,
  toggleEdit,
  onFormExit,
  onSubmitEdit,
  onDateChange,
  removeSegment,
  addSegment,
  setSegmentValue,
}) {
  const hasEditAccess = useHasEditPermission();
  const tagId = tag._id;
  const editRef = useRef();

  return (
    <div
      key={tagId}
      className="bg-white rounded-xl mt-6 py-[0.3rem] border border-bordgrey2"
    >
      {/* Normal Display */}
      <div className="px-1 font-medium flex items-center !py-[0.3rem] !min-h-0">
        <Tooltip label={tag.expanded ? "Hide Limits" : "Show Limits"} withArrow>
          <Button
            className="btn-ghost"
            icon="chevron-right"
            iconClasses={classNames("transition-transform", {
              "rotate-90": tag.expanded,
            })}
            onClick={toggleExpanded}
          />
        </Tooltip>
        <span className="OperatingLimitsManager__limit__name">{`${tag.name}${
          tag.description ? ` | ${tag.description}` : ""
        }`}</span>
      </div>
      {tag.limits.length && tag.expanded && (
        <div className="p-0 w-full animate-in slide-in-from-bottom-3">
          {_.map(tag.limits, (limit) => {
            const limitId = limit.data._id;
            return (
              <div className="mt-6 border-t border-bordgrey2" key={limitId}>
                <span className="OperatingLimitsManager__limit__label">
                  {_.startCase(limit.data.level)}
                </span>
                <First>
                  <i
                    match={flags.set(limit.state, LIMIT_STATES.saving)}
                    className="OperatingLimitsManager__limit__saving fa fa-spinner fa-pulse fa-fw"
                    aria-hidden="true"
                  />
                  <Fragment
                    match={!flags.set(limit.state, LIMIT_STATES.editing)}
                  >
                    {hasEditAccess && (
                      <span className="OperatingLimitsManager__limit__actions mr-2">
                        <Tooltip label="Delete" withArrow>
                          <Button
                            className="btn-ghost"
                            icon="trash-o"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this limit?"
                                )
                              ) {
                                deleteLimit(limitId);
                              }
                            }}
                          />
                        </Tooltip>
                        <Tooltip label="Edit" withArrow>
                          <Button
                            className="btn-ghost"
                            id={`${prefix}-${limitId}-LimitEditBtn`}
                            icon="pencil"
                            onClick={() => toggleEdit(limit)}
                          />
                        </Tooltip>
                      </span>
                    )}
                  </Fragment>
                </First>
                <div>
                  {!flags.set(limit.state, LIMIT_STATES.editing) && (
                    <Fragment>
                      <ul className="OperatingLimitsManager__segments">
                        <div className="OperatingLimitsManager__limit__history">
                          <table className="OperatingLimitsManager__table shadow-lg">
                            <thead className="OperatingLimitsManager__table__head">
                              <tr className="bg-zinc-200">
                                <th>Effective Date</th>
                                <th>Limit Value</th>
                              </tr>
                            </thead>

                            <tbody className="OperatingLimitsManager__table__body">
                              {_.map(limit.workingSegments, (segment) => {
                                if (!!segment.start && segment.value !== null)
                                  return (
                                    <tr className="" key={segment.segmentId}>
                                      {!flags.set(
                                        limit.state,
                                        LIMIT_STATES.editing
                                      ) && (
                                        <Fragment>
                                          <td>{segment.start}</td>
                                          <td>{segment.value}</td>
                                        </Fragment>
                                      )}
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </ul>
                      <UpdatedAt timestamp={limit.data.updatedAt} />
                      {/* <div className="OperatingLimitsManager__limit__lastUpdated">
                              Last updated on:{" "}
                              {moment(limit.data.updatedAt)
                                .local()
                                .format("LLL")}
                            </div> */}
                    </Fragment>
                  )}
                  {!!flags.set(limit.state, LIMIT_STATES.editing) && (
                    <Form
                      classes={{
                        Form: "mx-4",
                      }}
                      ref={editRef}
                      selectOnMount={`${prefix}-${limitId}-LimitEditName`}
                      selectOnExit={`${prefix}-${limitId}-LimitEditBtn`}
                      onSubmit={() => onSubmitEdit(limit)}
                      onExit={() => onFormExit(limit)}
                    >
                      {limit.workingSegments?.length ? (
                        <table className="OperatingLimitsManager__table__edit">
                          <thead className="OperatingLimitsManager__table__head">
                            <tr className="">
                              <th className="OperatingLimitsManager__limit__date">
                                Effective Date
                              </th>
                              <th className="OperatingLimitsManager__limit__value">
                                Limit Value
                              </th>
                              <th className="OperatingLimitsManager__limit__value">
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody className="OperatingLimitsManager__table__body">
                            {_.map(limit.workingSegments, (segment) => {
                              const dateForCalendar = moment(
                                segment.start
                              ).toDate();

                              const handleDateChange = (date) =>
                                onDateChange(
                                  limitId,
                                  segment.segmentId,
                                  moment(date).format("MMM DD, YYYY")
                                );

                              return (
                                <tr key={segment.segmentId}>
                                  <td>
                                    <SingleDaySelectWithArrows
                                      closeOnChange
                                      value={dateForCalendar}
                                      onChange={handleDateChange}
                                      close={{
                                        className: "btn-outline float-right",
                                        size: "xs",
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <InputOld
                                      classes={{
                                        Input__label:
                                          "OperatingLimitsManager__limitEdit__label",
                                        Input:
                                          "OperatingLimitsManager__limit__value input-sm",
                                      }}
                                      action={(value) =>
                                        setSegmentValue(
                                          limitId,
                                          segment.segmentId,
                                          "value",
                                          value
                                        )
                                      }
                                      value={segment.value.toString()}
                                    />
                                  </td>

                                  <td>
                                    <Button
                                      className="btn-outline btn-error"
                                      id={`${prefix}-${limitId}-LimitEditRemove`}
                                      onClick={() =>
                                        removeSegment(limitId, segment)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : null}
                      <Button
                        className="btn-outline btn-primary my-2 ml-2"
                        id={`${prefix}-${limitId}-LimitEditAdd`}
                        onClick={() => addSegment(limitId)}
                      >
                        Add History
                      </Button>
                      <div className="flex justify-between mb-2 mt-6">
                        <Button
                          className="btn-error btn-outline"
                          id={`${prefix}-${limitId}-LimitEditCancel`}
                          onClick={() => editRef.current.exit()}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-primary"
                          id={`${prefix}-${limitId}-LimitEditSave`}
                          loading={
                            !!flags.set(limit.state, LIMIT_STATES.saving)
                          }
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  )}
                  <First>
                    <Fragment match={!!limit.errors.length}>
                      <div className="OperatingLimitsManager__saveMessage OperatingLimitsManager__saveMessage--error">
                        {/* This code is still compiled, even when it doesn't match */}
                        {(limit.errors[0] || {}).msg}
                      </div>
                    </Fragment>

                    <Fragment
                      match={flags.set(
                        limit.state,
                        LIMIT_STATES.save_completed
                      )}
                    >
                      <div className="OperatingLimitsManager__saveMessage">
                        Save Successful!
                      </div>
                    </Fragment>
                  </First>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default OperatingLimitsDropdown;
