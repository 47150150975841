import { useAtomValue } from "jotai";
import { CreateCommentForm } from "./create-comment-form";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { CommentThread } from "./comment-thread";
import { CreateCommentReplyForm } from "./create-reply-form";
import { Fragment } from "react";
import { useIsRenderedAsFullscreen } from "../../../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { useChartRenderOptions } from "../generic-secondary-variable-view";

function BottomCommentArea() {
  const { noPortalMultiSelects } = useChartRenderOptions();

  const thisChartIsFullscreen = useIsRenderedAsFullscreen();
  const shouldNotRenderInAnyView = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom
  );

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  const selectedCommentId = useAtomValue(Atoms.selectedCommentIdAtom);
  const isCreating = brushState && brushState.for === "comment-create";

  /**
   * This component should never render in fullscreen view.
   */
  if (thisChartIsFullscreen) return;

  /**
   * If we're not in fullscreen view, only render if the
   * "onlyShowCommentsInFullscreen" value is false.
   */
  if (shouldNotRenderInAnyView) return;

  /**
   * If we're creating a comment, show the form.
   */
  if (isCreating)
    return (
      <CreateCommentForm
        noPortalMultiSelects={noPortalMultiSelects ? true : undefined}
      />
    );

  /**
   * If we're viewing a comment, show the comment.
   */
  return (
    selectedCommentId && (
      <CommentThread
        key={selectedCommentId}
        commentId={selectedCommentId}
        noPortalMultiSelects={noPortalMultiSelects ? true : undefined}
      />
    )
  );
}

export { BottomCommentArea };
