import * as Sidebar from "../../../shared-ui/time-series-2/secondary-variable-view/left-sidebar";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { cn } from "../../../shared-ui/frontend/cn";
import {
  Atoms,
  SelectedVariable,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useDebouncedHoverDataForBatchVariable } from "../../../shared-ui/time-series-2/svv-store/use-debounced-hoverdata-for-batch-variable";
import { usePlantTimeFormatter } from "../../../hooks/use-plant-time-formatter";
import { ControlButtons } from "./control-buttons";
import * as d3 from "d3";
import { Atom, useAtomValue, useSetAtom, useStore } from "jotai";
import { formatDuration4 } from "../../../shared-ui/lib/date-utils";
import { BsClock as Clock } from "react-icons/bs";
import { isVariableVariant } from "../../../shared-ui/time-series-2/draw/draw";

function VariableCardsSidebar() {
  const selectedVariableAtoms = useAtomValue(Atoms.selectedVariableAtomsAtom);
  const openSidebar = useAtomValue(
    useAtomValue(Atoms.cardsSidebarOpenAtomAtom)
  );

  if (!openSidebar) return null;

  return (
    <Sidebar.Container>
      <div className="flex w-full justify-center">
        <Sidebar.IncludeAllButton className="border-t-0 rounded-t-none" />
      </div>
      <div className={cn("slide-up-fade-in flex shrink-0 flex-col gap-2 p-3")}>
        {selectedVariableAtoms.map((atom) => {
          return <VariableCard key={atom.toString()} atom={atom} />;
        })}
      </div>
    </Sidebar.Container>
  );
}

function VariableCard({
  atom,
  driftTime,
}: {
  atom: Atom<SelectedVariable>;
  driftTime?: number;
}) {
  const batchVariable = useAtomValue(atom);
  const isVariableTrendLine = isVariableVariant(batchVariable);

  const variables = useVariablesArrayQuery().data;

  const variableObject = isVariableTrendLine
    ? variables?.find((x) => x._id === batchVariable.bv.slice(24))
    : undefined;

  const { color } = batchVariable;
  const [isHovered, hoverState] =
    useDebouncedHoverDataForBatchVariable(batchVariable);

  const plantFormat = usePlantTimeFormatter("dd-LLL-yyyy hh:mm a");

  const d3Color = d3.color(color);
  const darker = d3Color?.darker(1).toString();
  const backgroundColor = d3Color?.copy({ opacity: 0.1 })?.toString();

  const checkedSets = useAtomValue(Atoms.checkedBatchVariablesAtom);

  const checked = isVariableTrendLine
    ? checkedSets.variable.has(batchVariable.bv)
    : checkedSets.expression.has(batchVariable.id);

  const jot = useStore();

  const toggleCheckedExpressionTrendLine = useSetAtom(
    Atoms.toggleExcludedExpressionTrendLineAtom
  );
  const toggleCheckedVariableTrendLine = useSetAtom(
    Atoms.toggleExcludedVariableTrendLineAtom
  );

  return (
    <div
      className={cn(
        "relative overflow-clip rounded-md border border-xslate-7 group flex flex-col gap-1 border-b pb-0 text-xs transition-all cursor-pointer"
      )}
      onMouseEnter={() =>
        jot.get(Atoms.redrawCanvasFnAtom)?.({
          ...batchVariable,
          stage: "Remainder",
        })
      }
      onMouseLeave={() => jot.get(Atoms.redrawCanvasFnAtom)?.(undefined)}
      style={
        checked && isHovered
          ? {
              borderColor: darker,
              backgroundColor,
            }
          : {}
      }
      onClick={() => {
        isVariableTrendLine
          ? toggleCheckedVariableTrendLine(batchVariable.bv)
          : toggleCheckedExpressionTrendLine(batchVariable.id);
      }}
    >
      <div className="flex items-start gap-1 pl-1 pt-1">
        <Sidebar.ColoredCheckbox batchVariable={batchVariable} />
        <span
          className={cn(
            "leading-3 flex items-center justify-between group-hover:font-medium break-all",
            isHovered && "font-medium"
          )}
        >
          {isVariableTrendLine
            ? variableObject?.trimmedName
            : batchVariable.expression}
          {driftTime && (
            <span className="text-xs text-xslate-11 flex flex-row ml-1">
              <Clock className="w-3 h-3 relative top-0.5 mr-0.5" />
              {formatDuration4(driftTime * 60 * 1000)}
            </span>
          )}
        </span>
        <Sidebar.PinButton
          className="ml-auto hover:bg-inherit hover:border-xslate-11 border border-transparent shrink-0 -translate-y-1"
          batchVariable={batchVariable}
        />
      </div>

      <p className="pl-1">
        {hoverState ? (
          <>
            <span className="font-bold">{hoverState.v.toFixed(2)}</span> |{" "}
            {plantFormat(hoverState.t)}
          </>
        ) : (
          <span className="text-transparent select-none">invisible</span>
        )}
      </p>

      <div className="p-0.5 flex justify-between">
        <ControlButtons batchVariable={batchVariable} />
      </div>
    </div>
  );
}

export { VariableCardsSidebar };
