import { useStore } from "zustand";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../../shared-ui/frontend/drawer";
import { AxisRangeButtonGroupAndLabelToLeft } from "../../dateSelector/axis-range-button-group";
import { Button } from "../../../shared-ui/frontend/button";
import { X } from "lucide-react";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import React, { useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import { VIEW_MODE, ViewModeSelectors } from "../../common/view-mode-selectors";
import {
  ChartVariant,
  InitialTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Badge } from "../../../shared-ui/frontend/badge";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import {
  CorrelatedTags,
  useCorrelatedTagsDrawerStore,
  useCorrelatedTagsDrawerStoreNotRequired,
} from "./use-correlated-tags-drawer-store";

function Internal({
  chartVariant,
}: {
  chartVariant: ChartVariant | undefined;
}) {
  const store = useCorrelatedTagsDrawerStore();
  const [viewMode, setViewMode] = useState<VIEW_MODE>("grid");
  const { tags, setTags, tagName } = useStore(store);
  const numCharts = Object.values(tags || {}).reduce(
    (acc, curr) => acc + curr.length,
    0
  );
  const [numCols, setNumCols] = useState<number>(0);

  useEffect(() => {
    setNumCols(numCharts > 9 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts]);

  const toggleFullscreen = useToggleFullscreen();

  const chartDims = chartDimensionsConfig(
    viewMode === "grid" ? { isGridView: true, numCols } : { isGridView: false }
  );

  return (
    <Drawer
      direction="right"
      open={!!tags}
      onOpenChange={(open) => {
        if (!open) {
          setTags(undefined);
        }
      }}
    >
      <DrawerContent className="w-[90dvw] h-[100dvh] right-0 left-auto overflow-scroll rounded-none p-0 bg-zinc-100">
        <div className="overflow-auto p-6">
          {tags && (
            <>
              <div className="min-w-max mt-1 flex justify-between">
                <div className="text-2xl font-semibold tracking-tight truncate max-w-xl">
                  Correlated Tags for {tagName}
                </div>
                <div>
                  <div className="inline-flex mr-2">
                    <ViewModeSelectors
                      withLabels
                      enabledModes={["grid", "list", "chart"]}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                      numCols={numCols}
                      setNumCols={setNumCols}
                    />
                  </div>
                  <AxisRangeButtonGroupAndLabelToLeft />
                  <DrawerClose asChild>
                    <Button size="icon" variant={"ghost"}>
                      <X className="size-4" />
                    </Button>
                  </DrawerClose>
                </div>
              </div>

              {Object.keys(tags).map((key) => {
                const vars = tags[key as keyof CorrelatedTags];
                if (vars.length === 0) return null;
                return (
                  <div key={key} className="mt-4">
                    <span className="text-xl font-medium tracking-tight">
                      {key} Correlation
                      <Badge
                        variant={"primary"}
                        className="ml-3 relative bottom-1"
                      >
                        {vars.length} tags
                      </Badge>
                    </span>
                    <div
                      className={cn(
                        "grid gap-4 py-4",
                        viewMode === "grid"
                          ? `grid-cols-${numCols}`
                          : "grid-cols-1"
                      )}
                    >
                      {viewMode !== "chart" ? (
                        vars.map((vid, _) => {
                          return (
                            <DRASecondaryVariableViewProvider
                              key={vid}
                              initialBatchVariables={[
                                {
                                  type: "variable",
                                  bv: "0".repeat(24) + vid,
                                },
                              ]}
                              initialExpanded={false}
                              variant={chartVariant}
                            >
                              <DRASecondaryVariableViewNeedsProvider
                                onLineClick={
                                  toggleFullscreen &&
                                  (({ jotaiStore: s }) => {
                                    toggleFullscreen(s);
                                  })
                                }
                                ableToGetTaller
                                {...chartDims}
                                padding={{
                                  left: 45,
                                }}
                                expandable
                                onlyMinMaxYAxes={viewMode === "grid"}
                              />
                            </DRASecondaryVariableViewProvider>
                          );
                        })
                      ) : (
                        <DRASecondaryVariableViewProvider
                          initialBatchVariables={
                            vars.map((vid) => ({
                              type: "variable",
                              bv: "0".repeat(24) + vid,
                            })) as [InitialTrendLine, ...InitialTrendLine[]]
                          }
                          initialExpanded={false}
                          variant={chartVariant}
                        >
                          <DRASecondaryVariableViewNeedsProvider
                            onLineClick={
                              toggleFullscreen &&
                              (({ jotaiStore: s }) => {
                                toggleFullscreen(s);
                              })
                            }
                            commentsOnlyInFullscreen={false}
                            respondToSelectedCommentId
                            ableToGetTaller={false}
                            {...chartDims}
                            padding={{
                              left: 45,
                            }}
                            expandable
                            numTicks={undefined}
                            closeAndDisableLimitStatusSeries={false}
                          />
                        </DRASecondaryVariableViewProvider>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function CorrelatedTagsDrawer({
  chartVariant,
}: {
  chartVariant?: ChartVariant | undefined;
}) {
  const s = useCorrelatedTagsDrawerStoreNotRequired();

  if (!s) return null;

  return (
    <TimeseriesChartTooltipStoreProvider>
      {() => (
        <>
          <GlobalTooltip />
          <Internal chartVariant={chartVariant} />
        </>
      )}
    </TimeseriesChartTooltipStoreProvider>
  );
}

export { CorrelatedTagsDrawer };
