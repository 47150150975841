import moment from "moment-timezone";
import _ from "lodash";
import api from "../frameworks/Auth";
import formatErrors from "./formatErrors";
import backgroundRequest from "./backgroundrequest";

const baseURL = () => {
  const unit =
    "/" + window.location.pathname.split("/").filter((s) => !!s.trim())[0];
  // console.log('calling api with baseUrl', unit)
  return unit;
};

// getVariables, getTags, getGroups, getClusters
const endpoints = {
  /**
   * These few methods at the top take in a base parameter
   * as a workaround for redux race conditions
   */
  getTeams: (base) => api.get(`${base || baseURL()}/teams`),
  getUsers: (base) => api.get(`${base || baseURL()}/users`),
  getVariables: (base, query) => {
    let queryParams = _.map(query, (value, key) => {
      /* Handle list params like `excludeIds` */
      if (value instanceof Array) {
        value = value.join(",");
      }

      return `${key}=${value}`;
    });

    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    return api.get(`${base || baseURL()}/variables?${queryString}`);
  },
  getTags: (base, includesArray) =>
    api.get(
      `${base || baseURL()}/tags` +
        (includesArray ? `?include=${includesArray.join(",")}` : "")
    ),

  getLabels: () => api.get(`/labels`), // does this need a base ?

  getOperatingModes: (base) => api.get(`${base || baseURL()}/operating-modes`),

  getGroups: (base, hidden) =>
    api.get(`${base || baseURL()}/groups?hidden=${hidden ? "true" : "false"}`),

  getClusters: (base, includesArray) =>
    api.get(
      `${base || baseURL()}/families` +
        (includesArray ? `?include=${includesArray.join(",")}` : "")
    ),
  getShutdownRules: (base) => api.get(`${base || baseURL()}/shutdown-rules`),

  getFaultTrees: () => api.get(`${baseURL()}/fault-trees`),
  getFaultTree: (treeId) => api.get(`${baseURL()}/fault-trees/${treeId}`),
  copyFaultTree: (treeId, tree) =>
    api.post(`${baseURL()}/fault-trees/${treeId}/copy`, tree),
  patchFaultTree: (treeId, tree) =>
    api.patch(`${baseURL()}/fault-trees/${treeId}`, tree),
  deleteFaultTree: (treeId, tree) =>
    api
      .delete(`${baseURL()}/fault-trees/${treeId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.body.errors)),

  getFaultTreeStatus: (treeId, date) => {
    date = moment(date).format("YYYY-MM-DD");
    date = encodeURIComponent(date);
    return api.get(`${baseURL()}/fault-trees/${treeId}/status?date=${date}`);
  },

  createFaultTree: function (tree) {
    const that = this;

    return (
      api
        .post(
          `${baseURL()}/fault-trees/`,
          tree || { name: `fault-tree-${new Date().valueOf()}` }
        )
        .then((response) => response.data)

        /* Quick fix for API changes (creating a root node by default) */
        .then(async (response) => {
          const id = response._id;

          await that.postFaultTreeNode({
            name: `node-${new Date().valueOf()}`,
            faultTreeId: id,
            parentId: null,
          });

          return that.getFaultTree(id);
        })
    );
  },

  getFaultTreeChart: (treeId, date) => {
    /* Strip potential seconds/milliseconds off of date. */
    date = moment(date).format("YYYY-MM-DD");
    date = encodeURIComponent(date);
    return api
      .get(`${baseURL()}/fault-trees/treeChartData/${treeId}?date=${date}`)
      .then((response) => {
        const tree = response.data.data;
        return tree;
      });
  },

  // Mark tasks for the specified variable IDs and all dependents to be reprocessed - start and end dates to specify a range may optionally be provided; ranges from the unit's procStartDate to yesterday by default
  queueBackprocessTask: (payload) =>
    api.post(`${baseURL()}/tasks/jobs`, payload),

  getFaultTreeNode: (nodeId) =>
    api.get(`${baseURL()}/fault-tree-nodes/${nodeId}`),
  getFaultTreeNodes: (treeId) =>
    api.get(`${baseURL()}/fault-trees/${treeId}/fault-tree-nodes/`),
  patchFaultTreeNode: (nodeId, node) =>
    api.patch(`${baseURL()}/fault-tree-nodes/${nodeId}`, node),
  postFaultTreeNode: (node) => api.post(`${baseURL()}/fault-tree-nodes/`, node),
  deleteFaultTreeNode: (nodeId) =>
    api.delete(`${baseURL()}/fault-tree-nodes/${nodeId}`),
  getFaultTreeNodeStatus: (nodeId, date) => {
    date = moment(date).format("YYYY-MM-DD");
    date = encodeURIComponent(date);
    return api.get(
      `${baseURL()}/fault-tree-nodes/${nodeId}/status?start=${date}&end=${date}`
    );
  },
  getFaultTreeNodeResolutionDate: (nodeId) => {
    return api.get(`${baseURL()}/fault-tree-nodes/${nodeId}/resolution-date`);
  },

  getFaultTreeNodeStatusSeries: (nodeId, start, end) => {
    if (!nodeId || !start || !end)
      return Promise.reject("Incorrect parameters");
    start = encodeURIComponent(start);
    end = encodeURIComponent(end);
    return api.get(
      `${baseURL()}/variables/${nodeId}/statusSeries?start=${start}&end=${end}`
    );
  },

  patchOperatingMode: (modeId, mode) =>
    api.patch(`${baseURL()}/operating-modes/${modeId}`, mode),
  postOperatingMode: (mode) => api.post(`${baseURL()}/operating-modes/`, mode),
  deleteOperatingMode: (modeId) =>
    api.delete(`${baseURL()}/operating-modes/${modeId}`),

  deleteShutdownRule: (ruleId) =>
    api.delete(`${baseURL()}/shutdown-rules/${ruleId}`),
  postShutdownRule: (rule) => api.post(`${baseURL()}/shutdown-rules`, rule),
  patchShutdownRule: (ruleId, rule) =>
    api.patch(`${baseURL()}/shutdown-rules/${ruleId}`, rule),

  postCluster: (cluster) => api.post(`${baseURL()}/families`, cluster),
  deleteCluster: (clusterId) =>
    api.delete(`${baseURL()}/families/${clusterId}`),
  patchCluster: (clusterId, cluster) =>
    api.patch(`${baseURL()}/families/${clusterId}`, cluster),

  deleteTags: (tagId) => api.delete(`${baseURL()}/tags/${tagId}`),
  postTag: (tag) => api.post(`${baseURL()}/tags`, tag),
  patchTag: (tagId, tag) => api.patch(`${baseURL()}/tags/${tagId}`, tag),

  patchGroups: (gid, variables) =>
    api.patch(`${baseURL()}/groups/${gid}`, variables),
  postGroups: (variables) => api.post(`${baseURL()}/groups`, variables),
  deleteGroup: (gid) => api.delete(`${baseURL()}/groups/${gid}`),

  patchFolder: (folderId, folder) =>
    api.patch(`${baseURL()}/folders/${folderId}`, folder),
  postFolder: (folder) => api.post(`${baseURL()}/folders`, folder),

  patchSavedView: (savedViewId, savedView) =>
    api.patch(`${baseURL()}/folders/${savedViewId}`, {
      ...savedView,
      type: "savedView",
    }),
  postSavedView: (savedView) =>
    api.post(`${baseURL()}/folders`, { ...savedView, type: "savedView" }),

  getVariable: (id) => api.get(`${baseURL()}/variables/${id}`),
  getReferencingVariables: (id) =>
    api.get(`${baseURL()}/variables/${id}/referencing-variables`),

  getExceedanceVariables: (query) => {
    let queryParams = _.map(query, (value, key) => `${key}=${value}`);
    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    return api.get(`${baseURL()}/variables/exceeded?${queryString}`);
  },

  getProbabilityVariables: (query, probabilityQueries) => {
    const params = { ...query, ...probabilityQueries };
    let queryParams = _.map(params, (value, key) => `${key}=${value}`);
    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    return api.get(`${baseURL()}/variables/ol-probability?${queryString}`);
  },

  postUser: (data) => api.post(`/users`, data),
  deleteUser: (id, data) =>
    api.patch(`${baseURL()}/users/${id}/remove-unit?unit=${data}`),
  patchUser: (id, data) => api.patch(`${baseURL()}/users/${id}`, data),

  getReports: () => api.get(`${baseURL()}/reports`),
  getSpecialtyReports: () => api.get(`${baseURL()}/specialty-reports`),
  patchSpecialtyReport: (id, data) =>
    api.patch(`${baseURL()}/specialty-reports/${id}`, data),
  deleteSpecialtyReport: (id) =>
    api.delete(`${baseURL()}/specialty-reports/${id}`),
  postSpecialtyReport: (data) =>
    api.post(`${baseURL()}/specialty-reports/`, data),
  regenerateSpecialtyReport: (id) =>
    api.post(`${baseURL()}/specialty-reports/${id}/regenerate`),
  resendSpecialtyReport: (id, users) => {
    return users
      ? api.post(
          `${baseURL()}/specialty-reports/${id}/resend?users=${users.join(",")}`
        )
      : api.post(`${baseURL()}/specialty-reports/${id}/resend`);
  },

  getLimits: (id) => {
    const query = id ? `?variableId=${id}` : "";
    return api.get(`${baseURL()}/operating-limits/${query}`);
  },
  deleteOperatingLimit: (limitId) =>
    api.delete(`${baseURL()}/operating-limits/${limitId}`),
  patchOperatingLimit: (limitId, data) =>
    api.patch(`${baseURL()}/operating-limits/${limitId}`, data),
  postOperatingLimit: (data) => api.post(`${baseURL()}/operating-limits`, data),
  getOperatingLimitHistogram: (id, start, end, type) => {
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/operating-limits/histogram/${id}?start=${start}&end=${end}&type=${type}`
    );
  },
  getOperatingLimitStatusSeries: (id, start, end) => {
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/operating-limits/status-series/${id}?start=${start}&end=${end}`
    );
  },
  getOperatingLimitInsights: (id, start, end, type) => {
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/operating-limits/insights/${id}?start=${start}&end=${end}&type=${type}`
    );
  },
  getOperatingLimitProbabilities: (id, start, end) => {
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/operating-limit-stats/${id}?start=${start}&end=${end}`
    );
  },

  getExceedanceGroupCounts: (id, start, end, options) => {
    let queryParams = _.map(options, (value, key) => {
      if (value === undefined) {
        return null;
      }
      /* Handle list params like `excludeIds` */
      if (value instanceof Array) {
        value = value.join(",");
      }

      return `${key}=${value}`;
    });

    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/limit-exceedances/count?groupId=${id}&start=${start}&end=${end}&${queryString}`
    );
  },

  getExceedanceVariableCounts: (id, start, end, options) => {
    let queryParams = _.map(options, (value, key) => {
      /* Handle list params like `excludeIds` */
      if (value instanceof Array) {
        value = value.join(",");
      }

      return `${key}=${value}`;
    });

    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    start = moment(start).format("YYYY-MM-DD");
    end = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/limit-exceedances/count?variableId=${id}&start=${start}&end=${end}&${queryString}`
    );
  },

  /* TODO: static charts are going to eventually be merged together. */
  getStaticTrendChart: (variableId, start, end, height, width) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/charts/ft-trend?varId=${variableId}&start=${startFormatted}&end=${endFormatted}&height=${height}&width=${width}`,
      { accept: "text/html" }
    );
  },

  getGenericStaticTrendChart: (variableId, start, end, height, width) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/charts/ol-trend?varId=${variableId}&start=${startFormatted}&end=${endFormatted}&height=${height}&width=${width}`,
      { accept: "text/html" }
    );
  },

  downloadLimits: () => {
    return api.post(`${baseURL()}/operating-limits/download/`);
  },

  downloadNotifications: () => {
    return api.post(`${baseURL()}/notifications/download/`);
  },

  getNotifications: (
    notificationTypes,
    variableId,
    operatingLimitId,
    groupId
  ) => {
    let query = "";
    if (variableId) query = `${query}&variableId=${variableId}`;
    if (operatingLimitId)
      query = `${query}&operatingLimitId=${operatingLimitId}`;
    if (groupId) query = `${query}&groupId=${groupId}`;
    return api.get(
      `${baseURL()}/notifications?types[]=${notificationTypes}${query}`
    );
  },

  getUserNotifications: async (notificationType) => {
    let userId = api.user._id;

    let query = "";
    if (notificationType) query = `${query}&type=${notificationType}`;
    const response = await api.get(
      `${baseURL()}/users/${userId}/notifications?${query}`
    );

    return _.map(response.data, (notification) => {
      if (notification.type === "Custom" && notification.variable) {
        notification.customExpression = notification.variable.expression;
      }

      return notification;
    });
  },

  subscribeNotification: (
    type,
    value,
    variableId,
    operatingLimitId,
    groupId
  ) => {
    return api.post(`${baseURL()}/notifications`, {
      type,
      value,
      variableId,
      operatingLimitId,
      groupId,
    });
  },

  toggleNotification: async (notificationId) => {
    const response = await api.patch(
      `${baseURL()}/notifications/toggle/${notificationId}`
    );

    return response;
  },

  deleteNotification: (notificationId) => {
    return api.delete(`${baseURL()}/notifications/${notificationId}`);
  },

  patchNotification: async (notificationId, notification) => {
    const response = await api.patch(
      `${baseURL()}/notifications/${notificationId}`,
      notification
    );
    const data = response.data;

    if (data.type === "Custom" && data.variable) {
      data.customExpression = data.variable.expression;
    }

    return data;
  },

  createNotification: async (notification) => {
    const queryParam = notification.customExpression
      ? `?customExpression=${encodeURI(notification.customExpression)}`
      : "";
    const response = await api.post(
      `${baseURL()}/notifications/${queryParam}`,
      notification
    );
    const data = response.data;

    if (data.type === "Custom" && data.variable) {
      data.customExpression = data.variable.expression;
    }

    return data;
  },

  getTrends: (variableId, start, end) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/trends?varId=${variableId}&start=${startFormatted}&end=${endFormatted}`
    );
  },

  getThinTrends: (variableId, start, end) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/trends?thin=true&varId=${variableId}&start=${startFormatted}&end=${endFormatted}`
    );
  },

  getSlopes: (variableId, start, end) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return api.get(
      `${baseURL()}/slopes/forAnalysisPeriod?variableId=${variableId}&start=${startFormatted}&end=${endFormatted}`
    );
  },

  getComment: (id) => api.get(`${baseURL()}/comments/${id}`),

  getComments: (query = {}) => {
    if (query.start) {
      query.start_date = moment(query.start).toISOString();
    }
    if (query.end) {
      query.end_date = moment(query.end).toISOString();
    }

    query.limit = 300;
    query.page = query.page || 1;
    query.private = query.private ?? false;

    let queryParams = _.map(query, (value, key) => `${key}=${value}`);
    let queryString = queryParams.length ? `${queryParams.join("&")}` : "";
    return api.get(`${baseURL()}/comments?${queryString}`);
  },

  patchComment: async (commentId, comment) => {
    return await api.patch(`${baseURL()}/comments/${commentId}`, comment);
  },

  postComment: async (comment) => {
    return await api.post(`${baseURL()}/comments`, comment);
  },

  postCommentReply: async (commentId, reply) => {
    return await api.post(`${baseURL()}/comments/${commentId}/reply`, reply);
  },

  deleteComment: async (commentId) => {
    return await api.delete(`${baseURL()}/comments/${commentId}`);
  },
  createShutdownRule: async (data) => {
    return await api.post(`${baseURL()}/shutdown-rules`, data);
  },

  getRisks: async (groupId, start, end) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return await api.get(
      `${baseURL()}/risks?groupId=${groupId}&start=${startFormatted}&end=${endFormatted}`
    );
  },

  deleteRisks: (groupIds) => api.delete(`${baseURL()}/risks`, { groupIds }),

  getAnomalySummaries: async (variables, start, end) => {
    const startFormatted = moment(start).format("YYYY-MM-DD");
    const endFormatted = moment(end).format("YYYY-MM-DD");
    return await api.post(`${baseURL()}/anomalies/summaries`, {
      variables: variables,
      start: startFormatted,
      end: endFormatted,
    });
  },

  getAnomaly: async (variables, date, queries) => {
    const md = moment(date);
    const y = md.format("YYYY");
    const m = md.format("MM");
    const d = md.format("DD");

    const queryString = queries
      ? `?${queries.map(([k, v]) => `${k}=${v}`).join("&")}`
      : "";
    return await api.post(
      `${baseURL()}/anomalies/${y}/${m}/${d}${queryString}`,
      {
        varIds: variables,
      }
    );
  },

  setWatchlist: (variableId, status) => {
    return api.patch(`${baseURL()}/variables/${variableId}/watchlist`, {
      watchlist: status,
    });
  },

  dummyRequest: (data, timeout) => {
    return new Promise((res, rej) => {
      setTimeout(() => res(data), timeout == null ? 1500 : timeout);
    });
  },

  getTimestamp: (type) => {
    return api.get(`${baseURL()}/cached-data/${type}`);
  },
};

/* Endpoint standardization and request prioritization. */
Object.keys(endpoints).forEach(function (key) {
  function isResponse(response) {
    return (
      response &&
      response.data != null &&
      response.headers != null &&
      response.status != null
    );
  }

  const endpoint = endpoints[key];

  /**
   * Essentially adding some middleware to the API call functions
   */
  endpoints[key] = function () {
    /* Append options parameter to the end of the function. */
    const options = arguments[endpoint.length] || {}; // get the last argument

    const call = async () => {
      /* More precise request throttling. */
      if (options.throttle) {
        await new Promise((res) => setTimeout(() => res(), options.throttle));
      }

      /* Standardize the data that gets returned from requests. */
      return endpoint
        .apply(this, arguments)
        .then((response) => {
          return isResponse(response) ? response.data : response;
        })
        .catch((err) => {
          return Promise.reject(formatErrors(err));
        });
    };

    /* Defer call if request is deprioritized. */
    if (options.background) {
      return backgroundRequest(call);
    }

    return call();
  };
});

export default endpoints;
