import { generateStyleObjectFromMarginOptions } from "../utils";
import { type Video as VideoType } from "./types/ComponentTypes";

export default function Video({
  captionedcontent,
  content,
  // captionedcodename,
  // captionedfilename,
  // codename,
  // filename,
  marginTop,
  marginBottom,
}: VideoType) {
  const videoSource = content;

  return (
    <div
      className="flex justify-center mx-16"
      style={generateStyleObjectFromMarginOptions(marginTop, marginBottom)}
    >
      <video
        onContextMenu={(e) => e.preventDefault()} // prevents from right clicking and "Save Video As"
        style={{ width: "100%" }}
        controls
        controlsList="nodownload" // trying to prevent them from downloading the video, although it is always possible
      >
        <source src={videoSource} />
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={captionedcontent}
          default
        />
        The content in incompatible with your browser
      </video>
    </div>
  );
}
