import { useState, useEffect, useRef } from "react";
import * as R from "remeda";
import "./FaultTreeOverviewCard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { Loader, Tooltip, Collapse } from "@mantine/core";
import { FaArrowRight } from "react-icons/fa";
import Button from "../../common/Button/Button";
import classNames from "classnames";
import { FaCircle, FaDotCircle, FaSquare } from "react-icons/fa";
import { FaultTreeCardStatusSeries2 } from "./FaultTreeCardStatusSeries2";
import { useDateState } from "../../../zustand/useDateState";
import {
  getFaultTreeNodeResolutionDate,
  getFaultTreeNodeStatusSeries,
  getFaultTreeNodes,
  getFaultTreeStatus,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";
import { faultTreeNodeStatusSeriesSchema } from "../../../lib/api-schema/ft/fault-tree-node-status-series";

const NODE_STATUSES = {
  INACTIVE: {
    value: "inactive",
    num: 0,
  },
  ACTIVE: {
    value: "active",
    num: 1,
  },
  PROCESSING: {
    value: "processing",
    num: 2,
  },
  ERROR: {
    value: "error",
    num: 3,
  },
} as const;

export function FaultTreeOverviewCard2({
  tree,
  setLastProcessed,
}: {
  tree: faultTreeSchema;
  setLastProcessed: (date: string) => void;
}) {
  const childDataRequested = useRef<string>();
  const baseUrl = useBaseUrlExperimental();
  const $ds = useDateState();
  const axisTo = $ds.axisRangeTo.dateString;
  const axisFrom = $ds.axisRangeFrom.dateString;
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showMore, setMore] = useState(false);

  type NodeWithChildren = {
    data: Awaited<ReturnType<typeof getFaultTreeNodes>>[number];
    children: NodeWithChildren[]; // cool recursion !!
  };
  type FlattenedTreeArray = Array<NodeWithChildren & { level: number }>;
  type FlattenedTreeNode = FlattenedTreeArray[number];

  const [childrenNodes, setChildrenNodes] =
    useState<
      (Pick<FlattenedTreeNode, "level"> & FlattenedTreeNode["data"])[]
    >();

  const [rootData, setRootData] = useState<{
    status: (typeof NODE_STATUSES)[keyof typeof NODE_STATUSES]["value"];
    series: faultTreeNodeStatusSeriesSchema[];
    count: number;
  }>(); // tree's root node data

  const [nodes, setNodes] = useState<
    Record<
      string,
      {
        expression: string;
        name: string;
        series: faultTreeNodeStatusSeriesSchema[];
        count: number;
      }
    >
  >({}); // children data

  const [loading, setLoading] = useState(true); // TODO some loading stuff

  useEffect(() => {
    async function initialize() {
      resetChildrenData();
      setLoading(true);
      setMore(false);
      try {
        const nodeResult = await getFaultTreeStatus(baseUrl, {
          date: axisTo,
          treeId: tree._id,
        });

        /**
         * So nodeResult can be null if the api is messing up and not processing things as fast as it should
         *
         * If that's the case we'll defined a default for the "last updated" timestamp so that the page
         * can continue to function as normal.
         *
         * The default will just be right now.
         */
        const resDate = nodeResult
          ? await getFaultTreeNodeResolutionDate(baseUrl, nodeResult.variableId)
          : moment().toISOString();

        const rootData_ = await getFaultTreeNodeStatusSeries(
          baseUrl,
          tree.rootNodeId,
          axisFrom,
          axisTo
        );

        setRootData({
          status: (() => {
            if (!nodeResult) {
              return NODE_STATUSES.INACTIVE.value;
            }

            if (nodeResult.inVersion !== nodeResult.outVersion) {
              return NODE_STATUSES.PROCESSING.value;
            }

            if (!nodeResult.status) {
              nodeResult.status = nodeResult.result ? 1 : 0;
            }

            const o = Object.values(NODE_STATUSES).find(
              (o) => o.num === nodeResult.status
            );

            if (!o) throw new Error("Unrecognized node status");

            return o.value;
          })(),
          series: rootData_.series,
          count: rootData_.series
            .map((s) => (s.status.startsWith("Active") ? 1 : 0))
            .reduce((a, b) => a + b, 0 as number),
        });

        if (resDate && setLastProcessed) {
          setLastProcessed(resDate);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
    void initialize();
  }, [axisTo, axisFrom, baseUrl, tree._id, tree.rootNodeId, setLastProcessed]);

  function resetChildrenData() {
    setNodes({});
    childDataRequested.current = undefined;
  }

  async function handleOpenCollapse() {
    if (childDataRequested.current === `${axisFrom}-${axisTo}`) {
      // we already have the children data or are currently waiting for the results to finish
      return;
    } else {
      childDataRequested.current = `${axisFrom}-${axisTo}`;
    }

    // build the children data
    async function getChildrenNodes() {
      const nodes_ = await getFaultTreeNodes(baseUrl, tree._id);

      /* Lookup map for below. */
      const nodeMap = R.mapToObj(nodes_, (node) => {
        const k = node._id;

        const v: NodeWithChildren = { data: node, children: [] };
        return [k, v];
      });

      /* Build (again, temporary) tree so we can sort nodes correctly. */
      const tree_ = R.pipe(
        nodeMap,
        R.values,
        R.reduce((result, node) => {
          const parent = node.data.parentId;

          const parentNode = nodeMap[parent];

          if (parentNode) {
            parentNode.children.push(node);
          }

          return parent ? result : node;
        }, null as (typeof nodeMap)[number] | null)
      );

      // Create flat list via DFS

      function flattenTree(rootNode: NodeWithChildren) {
        // Helper function for DFS traversal and flattening
        const dfs = (
          node: NodeWithChildren,
          resultArray: FlattenedTreeArray,
          level = 0
        ) => {
          resultArray.push({ ...node, level: level }); // Add the current node to the result array

          // Recursively traverse and flatten children
          if (node.children && node.children.length > 0) {
            for (const childNode of node.children) {
              dfs(childNode, resultArray, level + 1);
            }
          }
        };

        const flattenedResult: FlattenedTreeArray = [];
        dfs(rootNode, flattenedResult);
        return flattenedResult;
      }

      if (!tree_)
        throw new Error(
          "parent was not found. This is a bug because bfs needs it to run"
        );
      let sortedTreeNodes = flattenTree(tree_);
      return sortedTreeNodes.map(({ data, level }) => ({ ...data, level }));
    }

    /**
     * The children nodes need to be fetched only once, but the status series may be fetched multiple times depending on date changes
     */
    let childrenNodes_;
    if (childrenNodes) {
      childrenNodes_ = childrenNodes;
    } else {
      childrenNodes_ = await getChildrenNodes();
      setChildrenNodes(childrenNodes_);
    }

    const statusSeriesForEachChild = childrenNodes_.map(async (node) => {
      const { series } = await getFaultTreeNodeStatusSeries(
        baseUrl,
        node._id,
        $ds.axisRangeFrom.dateString,
        $ds.axisRangeTo.dateString
      );

      if (childDataRequested.current === `${axisFrom}-${axisTo}`) {
        // ensure that a previous request's data doesn't override a new one
        // in the case of spam clicking
        setNodes((curr) => ({
          ...curr,
          [node._id]: {
            expression: node.expression,
            name: node.name,
            series: series,
            count: series
              .map((s) => (s.status.startsWith("Active") ? 1 : 0))
              .reduce((a, b) => a + b, 0 as number),
          },
        }));
      }
    });

    await Promise.all(statusSeriesForEachChild);
  }

  const isActive = !!rootData && rootData.status === NODE_STATUSES.ACTIVE.value;
  const treeAddress = `${baseUrl}/ft/details/${tree._id}`;

  const cardClickLink = treeAddress + search;
  const nodeClickLink = (nodeId: string) => {
    const params = new URLSearchParams(search);
    params.set("selectednode", nodeId);

    return treeAddress + "?" + params.toString();
  };

  return (
    <div
      className="border hover:cursor-pointer border-bordgrey2 bg-base-100 rounded-xl w-full hover:border-indigo-800 group"
      onClick={() => navigate(cardClickLink)}
    >
      <div className="px-3 pt-3 text-xl font-medium min-h-[90px]">
        <div className="flex gap-4 mb-4 items-center font-semibold text-[1.1rem]">
          {tree.name}
          {loading ? (
            <Loader color="dark" size={20} />
          ) : (
            <div
              className={
                "text-[0.7rem] badge badge-outline" +
                (isActive ? " text-[#0071bc]" : "")
              }
            >
              {rootData?.status.toUpperCase()}
            </div>
          )}
          <div className="invisible group-hover:visible ml-auto">
            <FaArrowRight />
          </div>
        </div>

        <FaultTreeCardStatusSeries2
          height="1.5rem"
          showDateRow={true}
          variableId={tree.rootNodeId}
          series={rootData?.series}
        />
        <div className="flex justify-center">
          <Button
            disabled={loading}
            icon="chevron-up"
            className="hover:text-indigo-800 normal-case font-normal btn-ghost"
            iconClasses={classNames("mr-2 transition", {
              "rotate-180": !showMore,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setMore((b) => !b);

              handleOpenCollapse();
            }}
          >
            {showMore ? "Collapse" : "Expand"}
          </Button>
        </div>
        <Collapse in={showMore}>
          {childrenNodes ? (
            <div className="mb-3">
              <div className="flex">
                <div className="w-[22%] font-normal text-[0.9rem]">
                  <h4>Node name</h4>
                </div>
                <div className="flex-none mr-14 font-normal text-[0.9rem]">
                  <h4>Active</h4>
                </div>
              </div>
              {childrenNodes.map((node, index) => {
                const nodeId = node._id;
                const nodeData = nodes[nodeId];

                const exp = nodeData?.expression;
                const name = nodeData?.name;
                const series = nodeData?.series;

                return (
                  <div
                    key={nodeId}
                    className="flex hover:bg-amber-100 even:bg-gray-200 items-center px-2 py-[0.35rem]"
                    onClick={(e) => {
                      e.stopPropagation(); // prevent the card from being clicked, there is another navigate() above
                      const url = nodeClickLink(nodeId);
                      navigate(url);
                    }}
                  >
                    <div className="w-[22%]">
                      {nodeData ? (
                        <Tooltip
                          label={exp || "Expression: N/A"}
                          position="right"
                        >
                          <span
                            className="text-left font-normal text-[0.9rem]"
                            style={{ marginLeft: node.level * 0.75 + "rem" }}
                          >
                            {
                              // show circle on level 0, square on level 1, dot on level 2, etc
                              node.level % 3 === 0 ? (
                                <FaCircle className="mr-1 inline w-3 h-2" />
                              ) : node.level % 3 === 1 ? (
                                <FaSquare className="mr-1 inline w-3 h-2" />
                              ) : (
                                <FaDotCircle className="mr-1 inline w-3 h-2" />
                              )
                            }
                            {name}
                          </span>
                        </Tooltip>
                      ) : (
                        <Loader color="dark" size={25} />
                      )}
                    </div>
                    <div className="flex-none mr-14 w-2 font-normal text-[0.9rem]">
                      {nodeData?.count ?? <Loader color="dark" size={25} />}
                    </div>
                    <div className="flex-1">
                      {/* TODO make use of childrenData so that it doesn't have to refetch everytime we expand/collapse  */}
                      <FaultTreeCardStatusSeries2
                        height="1rem"
                        series={series}
                        variableId={nodeId}
                        showDateRow={false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center pb-5 pt-7">
              <Loader color="dark" size={25} />
            </div>
          )}
        </Collapse>
      </div>
    </div>
  );
}
