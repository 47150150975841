import "./AnomalyColorsStripes.scss";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { AckManStoreProvider } from "./store/ack-man-store";
import { Header } from "./header";
import { CountCardsAndTable } from "./main-content";

export function AckManager() {
  usePermissionBasedDocumentTitleForSettingsPage();

  // if (pageError)
  //   return (
  //     <PageError message="An error has occured. Please refresh the page." />
  //   );

  return (
    <AckManStoreProvider>
      {/* stuff  */}
      <Header />
      <CountCardsAndTable />
    </AckManStoreProvider>
  );
}
