import {
  getDateStateSync,
  useDateState,
} from "../../../../zustand/useDateState";
import {
  getTimeParamsAsDatesSync,
  useTimeParams,
} from "../../../dateSelector/custom-range-button/global-custom-range-button";
import { DateTime } from "luxon";
import { useTimezone } from "../../../../zustand/config/useConfigStore";

/**
 * date state only
 * gives us the days, and we have to manipulate the times
 * before sending it over to the API.
 */
const useTzAwareDateStateDomain = () => {
  const ds = useDateState();
  const [tStart, tEnd] = useTimeParams();

  const zone = useTimezone();

  /**
   * These dates only tells you the range of the
   * axis in days. You must modify the times to
   * get the correct range.
   */
  const axisFrom = ds.axisRangeFrom.dateString;
  const axisTo = ds.axisRangeTo.dateString;

  let s = DateTime.fromISO(axisFrom, { zone });
  let e = DateTime.fromISO(axisTo, { zone });

  if (ds.isCustom) {
    s = s.set({
      hour: tStart.getHours(),
      minute: tStart.getMinutes(),
    });
    e = e.set({
      hour: tEnd.getHours(),
      minute: tEnd.getMinutes(),
    });
  } else {
    /**
     * If we aren't using custom times, then
     * we mean from start of day to end of day.
     */
    s = s.startOf("day");
    e = e.endOf("day").startOf("minute");
  }

  return [s.toMillis(), e.toMillis()] as [number, number];
};

const getTzAwareDateStateDomainSync = (sp: URLSearchParams, zone: string) => {
  const ds = getDateStateSync(sp);
  const startAndEndTuple = ds.getTimeParams();
  const [tStart, tEnd] = getTimeParamsAsDatesSync(
    startAndEndTuple[0],
    startAndEndTuple[1]
  );

  /**
   * These dates only tells you the range of the
   * axis in days. You must modify the times to
   * get the correct range.
   */
  const axisFrom = ds.axisRangeFrom.dateString;
  const axisTo = ds.axisRangeTo.dateString;

  let s = DateTime.fromISO(axisFrom, { zone });
  let e = DateTime.fromISO(axisTo, { zone });

  if (ds.isCustom) {
    s = s.set({
      hour: tStart.getHours(),
      minute: tStart.getMinutes(),
    });
    e = e.set({
      hour: tEnd.getHours(),
      minute: tEnd.getMinutes(),
    });
  } else {
    /**
     * If we aren't using custom times, then
     * we mean from start of day to end of day.
     */
    s = s.startOf("day");
    e = e.endOf("day").startOf("minute");
  }

  return [s.toMillis(), e.toMillis()] as [number, number];
};

export { useTzAwareDateStateDomain, getTzAwareDateStateDomainSync };
