import { create } from "zustand";

// variable may not be unique, we need to track color as well
export type CapabilityStats = {
  ref: string; // since we can have same variable but multiple cards, we need unique identifier
  usl?: number;
  lsl?: number;
  target?: number;
  Cp?: number;
  Cpk?: number;
  CCpk?: number;
  Cpm?: number;
};
export type CapabilityStore = {
  data: CapabilityStats[];
  addToData: (data: CapabilityStats) => void;
  getData: (ref: string) => CapabilityStats | undefined;
};

export const useCapabilityStore = create<CapabilityStore>()((set, get) => {
  return {
    data: [],
    addToData: (newStat) => {
      const curr = get().data;
      const isPresent = curr.find((stat) => stat.ref === newStat.ref);
      if (isPresent && isSameStat(newStat, isPresent)) return;
      else
        set({
          data: curr.filter((stat) => stat.ref !== newStat.ref).concat(newStat),
        });
    },
    getData: (ref) => get().data.find((stat) => stat.ref === ref),
  };
});

function isSameStat(a: CapabilityStats, b: CapabilityStats): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
