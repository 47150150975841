class OutOfYBoundsAwareSegmentDrawer {
  private _prev: undefined | { x: number; y: number; out: boolean };
  private _ctx: CanvasRenderingContext2D;
  private _min: number;
  private _max: number;

  constructor(ctx: CanvasRenderingContext2D, min: number, max: number) {
    this._prev = undefined;
    this._ctx = ctx;
    this._min = min;
    this._max = max;
  }

  public point(x: number, y: number) {
    if (!this._prev) {
      this._ctx.moveTo(x, y);
      this._prev = {
        x,
        y,
        out: this.outOfBounds(y),
      };
      return;
    }

    const requestedPointOutOfBounds = this.outOfBounds(y);
    const prev = this._prev;
    const prevOutBounds = prev.out;

    if (requestedPointOutOfBounds) {
      if (prevOutBounds) {
        this._ctx.moveTo(x, y);
      } else {
        // draw line to the edge
        const bound = y < this._min ? this._min : this._max;
        const slope = (y - prev.y) / (x - prev.x);
        const newX = prev.x + (bound - prev.y) / slope;
        this._ctx.lineTo(newX, bound);
      }

      prev.x = x;
      prev.y = y;
      prev.out = true;
      return;
    }

    if (prevOutBounds) {
      // linear interp to move us in bounds
      // then draw line to requested point
      const bound = prev.y < this._min ? this._min : this._max;
      const slope = (y - prev.y) / (x - prev.x);
      const newX = prev.x + (bound - prev.y) / slope;
      this._ctx.moveTo(newX, bound);
    }
    this._ctx.lineTo(x, y);
    prev.x = x;
    prev.y = y;
    prev.out = false;
  }

  private outOfBounds(y: number) {
    return y < this._min || y > this._max;
  }
}

export { OutOfYBoundsAwareSegmentDrawer };
