import { create } from "zustand";
import * as React from "react";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";
import { faultTreeNodeSchema } from "../../../lib/api-schema/ft/fault-tree-node";
import { faultTreeNodeStatusSchema } from "../../../lib/api-schema/ft/fault-tree-node-status";
import { faultTreeResolutionDateSchema } from "../../../lib/api-schema/ft/fault-tree-resolution-date";

export type ExtendedFtNode = faultTreeNodeSchema &
  faultTreeNodeStatusSchema & { resolutionDate: faultTreeResolutionDateSchema };

type Tree = {
  mainTree: faultTreeSchema;
  nodes: faultTreeNodeSchema[];
};

export type FaultTreeDetailsStore = {
  tree: Tree | undefined;
  setTree: (tree: Tree | ((curr: Tree | undefined) => Tree)) => void;

  selectedNode: ExtendedFtNode | undefined;
  setSelectedNode: (s: ExtendedFtNode | undefined) => void;

  publishedTrees: faultTreeSchema[] | undefined;
  setPublishedTrees: (trees: faultTreeSchema[]) => void;

  lastProcessed: string | undefined;
  setLastProcessed: (lastProcessed: string | undefined) => void;
};

export function createUseFaultTreeDetailsStore() {
  return create<FaultTreeDetailsStore>((set, get) => ({
    tree: undefined,
    setTree: (tree) => {
      if (typeof tree === "function") {
        set((curr) => {
          return {
            tree: tree(curr.tree),
          };
        });
      } else {
        set({ tree });
      }
    },

    selectedNode: undefined,
    setSelectedNode: (selectedNode) => set({ selectedNode }),

    publishedTrees: undefined,
    setPublishedTrees: (publishedTrees) => set({ publishedTrees }),

    lastProcessed: undefined,
    setLastProcessed: (lastProcessed) => {
      set({ lastProcessed });
    },
  }));
}

export type UseFaultTreeDetailsStore = ReturnType<
  typeof createUseFaultTreeDetailsStore
>;

const FaultTreeDetailsStoreContext = React.createContext<
  UseFaultTreeDetailsStore | undefined
>(undefined);

export function FaultTreeDetailsStoreProvider({
  children,
}: React.PropsWithChildren) {
  const useFaultTreeDetailsStoreRef = React.useRef<UseFaultTreeDetailsStore>();

  if (!useFaultTreeDetailsStoreRef.current) {
    useFaultTreeDetailsStoreRef.current = createUseFaultTreeDetailsStore();
  }

  return (
    <FaultTreeDetailsStoreContext.Provider
      value={useFaultTreeDetailsStoreRef.current}
    >
      {children}
    </FaultTreeDetailsStoreContext.Provider>
  );
}

export function useGetUseFaultTreeDetailsStore() {
  const useFaultTreeDetailsStore = React.useContext(
    FaultTreeDetailsStoreContext
  );

  if (!useFaultTreeDetailsStore) {
    throw new Error(
      "useGetUseFaultTreeDetailsStore must be used within a FaultTreeDetailsStoreProvider"
    );
  }

  return useFaultTreeDetailsStore;
}
