import React from "react";
import Form from "../../common/Form";
import InputOld from "../../common/InputOld";
import First from "../../common/First";
import Fragment from "../../common/Fragment";
import MainLoader from "../../common/MainLoader";
import Error from "../../common/PageError";
import formatErrors from "../../../lib/formatErrors";
import download from "../../../lib/download";
import _ from "lodash";
import flags from "../../../lib/flags";
import * as moment from "moment-timezone";
import "./OperatingLimitsManager.scss";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import useAPI from "../../../lib/useAPI";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";

import { useProcStartDate } from "../../../lib/useProcStartDate";
import OperatingLimitsDropdown from "./OperatingLimitsDropdown";
import { produce } from "immer";
import { ALL_LIMIT_LEVELS_ORDERED, LIMIT_STATES } from "../constants";
import { Collapse } from "@mantine/core";
import Button from "../../common/Button/Button";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import * as CalendarComponents from "../../common/calendar";

// const DATE_FORMAT = "MMM DD, YYYY";
const SUCCESS_DURATION = 6000; /* save success duration */

const NEW_LIMIT_ID = "__newlimit"; /* unique identifier for new limits */

const PAGE_NAME = "Operating Fitness";

/*@TODO
 * Add a method for quickly setting state on individual limits, there's too much
 * code duplication happening with the current system and it's error-prone. */
class OperatingLimitsManager extends React.Component {
  constructor(props) {
    super(props);

    this.prefix = _.uniqueId("OperatorLimitsManager");

    this.state = {
      error:
        null /* Page level error (if something has gone so wrong that the full page can't load) */,
      limits: null /* pretty much all of the main data just goes here */,
      timeouts:
        {} /* Used for purely visual purposes (save confirmation, see method binding below) */,
      filter: "",
      tags: null,
    };

    /* On failure of any patch/delete/create, display message */
    /* On failure of get, display page-level message */
    ["createLimit", "patchLimit", "deleteLimit"].forEach((method) => {
      const original = this[method];

      this[method] = async function (id, payload) {
        id = typeof id === "string" ? id : "__newlimit";

        this.setState(
          produce((state) => {
            let limit = state.limits[id];
            limit.state = flags.set(limit.state, LIMIT_STATES.saving, true);
            limit.errors =
              []; /* Clear errors on save and on some edit transitions */
          })
        );

        let result = null;

        try {
          result = await original.apply(this, arguments);
          id = result
            ? result._id
            : id; /* ID replacement when saving new node */

          this.setState(
            produce((state) => {
              let tag = state.tags[id];
              if (tag) {
                tag.state = flags.set(
                  tag.state,
                  LIMIT_STATES.save_completed,
                  true
                );

                /* Avoid old timeouts clearing more recent success messages. */
                if (state.timeouts[id]) {
                  clearTimeout(state.timeouts[id]);
                }

                state.timeouts[id] = setTimeout(() => {
                  this.setState(
                    produce((state) => {
                      let tag = state.tags[id];
                      if (tag) {
                        tag.state = flags.set(
                          tag.state,
                          LIMIT_STATES.save_completed,
                          false
                        );
                      }
                    })
                  );
                }, SUCCESS_DURATION);
              } /* handle deletion */
            })
          );
        } catch (err) {
          console.log("error: ", err);

          /* set error state (@TODO: temporary? re-editing should remove errors) */
          this.setState(
            produce((state) => {
              let limit = state.limits[id];
              limit.errors = formatErrors(err);
            })
          );

          /* Make sure that failing still fails the overall method. */
          result = Promise.reject(err);
        }

        this.setState(
          produce((state) => {
            let limit = state.limits[id];
            if (!limit) {
              return;
            }

            limit.state = flags.set(limit.state, LIMIT_STATES.saving, false);
          })
        );

        return result;
      };
    });
  }

  setAsyncState(method) {
    return new Promise((resolve) =>
      this.setState.call(this, method, () => resolve())
    );
  }

  componentDidMount() {
    Promise.all([this.getTags(), this.getLimits()])
      .then(([tags, limits]) => {
        const tagsForSelect = _.map(tags, (tag) => ({
          value: tag._id,
          label: `${tag.name} - ${tag.description}`,
        }));
        return this.setAsyncState({ tags: tags, tagsForSelect, limits });
      })
      .catch(() => {
        this.setState({ error: "Error loading page." });
      });
  }

  async getTags() {
    const tags = await this.props.api.getTags(undefined);

    return _.reduce(
      tags,
      (result, tag) => {
        result[tag._id] = tag;
        tag.expanded = false;
        return result;
      },
      {}
    );
  }

  async getLimits() {
    const limits = await this.props.api.getLimits();

    return _.reduce(
      limits,
      (result, limit) => {
        const segments = this.getWorkingSegments(limit);
        result[limit._id] = {
          state: 0,
          errors: [],
          edits: {},
          data: limit,
          workingSegments: segments,
          workingSegmentsEdits: _.cloneDeep(segments),
        };

        return result;
      },
      {}
    );
  }

  async deleteLimit(id) {
    return this.props.api.deleteOperatingLimit(id).then(() => {
      return this.setAsyncState(
        produce((state) => {
          state.limits[id] = undefined;
        })
      );
    });
  }

  async patchLimit(id, data) {
    return this.props.api.patchOperatingLimit(id, data).then(async (limit) => {
      await this.setAsyncState(
        produce((state) => {
          state.limits[id].data = limit;
          const formattedSegments = this.getWorkingSegments(limit);
          state.limits[id].workingSegments = formattedSegments;

          state.limits[id].edits = {};
          state.limits[id].workingSegmentsEdits =
            _.cloneDeep(formattedSegments);
        })
      );

      return limit;
    });
  }

  async createLimit(data) {
    return this.props.api.postOperatingLimit(data).then(async (limit) => {
      await this.setAsyncState(
        produce((state) => {
          const segments = this.getWorkingSegments(limit);

          state.limits[limit._id] = {
            state: 0,
            errors: [],
            edits: {},
            data: limit,
            workingSegments: segments,
            workingSegmentsEdits: _.cloneDeep(segments),
          };

          state.limits.__newlimit = null;
        })
      );

      return limit;
    });
  }

  getWorkingSegments(limit) {
    return limit.data.map((segment) => ({
      value: segment.value,
      start: moment.utc(segment.start).format("MMM DD, YYYY"),
      segmentId: _.uniqueId(limit._id),
    }));
  }

  removeSegment(limitId, id) {
    this.setState(
      produce((state) => {
        let limit = state.limits[limitId];
        limit.workingSegmentsEdits = limit.workingSegmentsEdits.filter(
          (segment) => segment.segmentId !== id
        );
      })
    );
  }

  addSegment(id) {
    const d = moment(this.props.procStartDate);
    this.setState(
      produce((state) => {
        let limit = state.limits[id];
        limit.workingSegmentsEdits.push({
          segmentId: `${id}-${limit.workingSegmentsEdits.length + 1}`,
          start: d,
          value: "",
        });

        limit.workingSegmentsEdits = _.sortBy(
          limit.workingSegmentsEdits,
          (seg) => (seg.start ? new Date(seg.start).valueOf() : 0)
        );
      })
    );
  }

  buildHistory(limitId) {
    const limit = this.state.limits[limitId];

    const sortedSegments = _.sortBy(limit.workingSegmentsEdits, (seg) =>
      seg.start ? new Date(seg.start).valueOf() : 0
    );

    const builtSegments = sortedSegments.reduce((result, segment, i) => {
      const endDate = sortedSegments[i + 1]
        ? sortedSegments[i + 1].start
        : null;

      const value = parseFloat(segment.value);
      result.push({
        value,
        start: moment.utc(segment.start).toISOString(),
        end: !endDate ? endDate : moment.utc(endDate).toISOString(),
      });
      return result;
    }, []);
    return builtSegments;
  }

  async saveLimit(limitId) {
    const segments = this.buildHistory(limitId);
    let limit = this.state.limits[limitId];
    let data = _.extend({}, limit.data, limit.edits);

    data.type = data.level
      ? data.level.includes("high")
        ? "high"
        : "low"
      : "";
    data.data = segments;

    if (limitId === NEW_LIMIT_ID) await this.createLimit(data);
    else await this.patchLimit(limitId, data);
  }

  setSegmentValue(limitId, segmentId, prop, value) {
    /* TODO: expand to deal with groups and vars */
    this.setState(
      produce((state) => {
        let limit = state.limits[limitId];
        let segment = limit.workingSegmentsEdits.find(
          (segment) => segment.segmentId === segmentId
        );
        segment[prop] = value;
        limit.workingSegmentsEdits = _.sortBy(
          limit.workingSegmentsEdits,
          (seg) => (seg.start ? new Date(seg.start).valueOf() : 0)
        );
      })
    );
  }

  setLimitValue(limitId, prop, value) {
    const LEVEL_PROP_NAME = "level";
    if (prop === LEVEL_PROP_NAME) {
      const variableId = this.state.limits[limitId].edits.variableId;
      const limitAlreadyExists = _.values(this.state.limits).find(
        (limit) =>
          limit &&
          limit.data &&
          limit.data.variableId === variableId &&
          limit.data.level === value
      );
      if (limitAlreadyExists) {
        this.setState(
          produce((state) => {
            let limit = state.limits[limitId];
            const error =
              formatErrors(`'${value.toUpperCase()}' already exists for this tag.
            To add/edit a limit, navigate to its card below and click 'Edit'.`);
            limit.errors = error;
          })
        );
        return;
      }
    }

    this.setState(
      produce((state) => {
        let limit = state.limits[limitId];
        limit.edits[prop] = value;
        limit.errors = [];
      })
    );
  }

  onDateChange(limitId, segmentId, date) {
    this.setSegmentValue(limitId, segmentId, "start", date);
  }

  onFilterChange(filter) {
    this.setState({ filter });
  }

  downloadAsXLSX(csvString, filename) {
    const spl = csvString.split("\n");

    const restArr = spl
      .slice(1, spl.length)
      .map((s) => s.replace("\r", "").trim());

    const headerStrings = spl
      .slice(0, 1)[0]
      .split(",")
      .map((s) => s.trim()); // order by this

    const data = _.map(restArr, (rawRowString) => {
      const split = rawRowString.split(",").map((s) => s.trim());

      const out = {
        groupsArr: split.slice(headerStrings.length - 1, split.length),
      }; // everything but the groups column

      _.forEach(headerStrings, (colName, index) => {
        if (index !== headerStrings.length - 1) {
          out[colName] = split[index];
        }
      });
      return out;
    });

    const maxNumberOfGroups = _.reduce(
      data,
      (currMax, o) => {
        if (o.groupsArr.length > currMax) {
          return o.groupsArr.length;
        }
        return currMax;
      },
      0
    );

    _.forEach(data, (o) => {
      const { groupsArr } = o;

      for (let i = 0; i < maxNumberOfGroups; i++) {
        const groupKey = `GROUP${i + 1}`;
        o[groupKey] = groupsArr[i] ?? "";
      }

      delete o.groupsArr;
    });

    const columnOrder = headerStrings.slice(0, headerStrings.length - 1);

    for (let i = 0; i < maxNumberOfGroups; i++) {
      const groupKey = `GROUP${i + 1}`;
      columnOrder.push(groupKey);
    }

    downloadXLSX(data, filename, columnOrder);
  }

  async downloadLimits() {
    const csvString = await this.props.api.downloadLimits();
    const filename = "OperatingLimits.";
    try {
      this.downloadAsXLSX(csvString, filename + "xlsx");
    } catch {
      download.csvFromString(filename + "csv", csvString);
    }
  }

  render() {
    const that = this;

    const spinIcon = "spinner pulse fw";

    /* In the case we legitimately have zero limits,
     * `that.state.limits` will still be a (truthy) blank array */
    const loaded = !!that.state.limits && !!that.state.tags;
    const error = !!that.state.error;

    const limits = (that.limits = _.reduce(
      that.state.limits,
      (result, limit, id) => {
        if (!limit) {
          return result;
        } /* Skip deleted limits */

        const displayLimit = {
          editable: true,
          _id: id,
          state: limit.state,
          errors: limit.errors,
          data: _.extend({}, limit.data, limit.edits),
          workingSegments: limit.workingSegmentsEdits,
        };

        result[id] = displayLimit;

        return result;
      },
      {}
    ));

    const filter = that.state.filter.toLowerCase();
    const filteredTags = _.filter(
      that.state.tags,
      (tag) =>
        tag.description.toLowerCase().includes(filter) ||
        tag.name.toLowerCase().includes(filter)
    );

    const sortedTags = _.orderBy(filteredTags, (tag) => tag.name);

    const limitMap = _.reduce(
      limits,
      (result, limit) => {
        if (!limit.data._id) {
          return result;
        }

        result[limit.data.variableId] = result[limit.data.variableId] || [];
        result[limit.data.variableId].push(limit);
        return result;
      },
      {}
    );

    const tagsWithLimits = _.reduce(
      sortedTags,
      (result, tag) => {
        let limits = limitMap[tag._id];
        if (!limits || !limits.length) {
          return result;
        }

        result.push({
          ...tag,
          limits: _.orderBy(limits, ["data.value"], ["desc"]),
        });

        return result;
      },
      []
    );

    const count = tagsWithLimits.length;
    const limitCount = _.reduce(
      tagsWithLimits,
      (result, tag) => {
        return result + tag.limits.length;
      },
      0
    );
    const createRef = React.createRef();

    const procStartDate = moment(this.props.procStartDate);
    const hasEditAccess = this.props.hasEditAccess;
    return (
      <First>
        <MainLoader match={!loaded && !error} />
        <Error
          match={error}
          message="An error has occurred. Please refresh the page."
        />

        <Fragment>
          <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
            <div className="flex items-center mb-2 sm:mb-0">
              <span className="text-[2rem] sm:text-[1.75rem] mr-2">
                {PAGE_NAME}
              </span>

              {hasEditAccess && (
                <CreateNewEntityButton
                  onClick={() => {
                    this.setState(
                      produce((state) => {
                        state.limits[NEW_LIMIT_ID] = {
                          state: LIMIT_STATES.editing,
                          errors: [],
                          edits: {},
                          data: {},
                          workingSegments: [],
                          workingSegmentsEdits: [
                            {
                              segmentId: `${NEW_LIMIT_ID}-1`,
                              start: moment(procStartDate),
                            },
                          ],
                        };
                      })
                    );
                  }}
                />
              )}
            </div>
            <div>
              <span className="mr-5 italic text-[1.25rem] text-titlegrey">
                Displaying {limitCount} limits ({count} Tags)
              </span>
              {/* TODO implement download  */}
              <Button
                className="btn-ghost"
                icon="download"
                iconClasses="mr-1"
                onClick={() => this.downloadLimits()}
              >
                download
              </Button>
            </div>
          </div>
          {limits[NEW_LIMIT_ID] &&
          !!flags.set(limits[NEW_LIMIT_ID].state, LIMIT_STATES.editing) ? (
            <Form
              classes={{
                Form: "border border-bordgrey rounded-xl bg-[#fafafa] px-4 my-4",
              }}
              ref={createRef}
              onSubmit={() => this.saveLimit(NEW_LIMIT_ID)}
              onExit={() =>
                this.setState(
                  produce((state) => {
                    state.limits[NEW_LIMIT_ID].state = flags.set(
                      state.limits[NEW_LIMIT_ID],
                      LIMIT_STATES.editing,
                      false
                    );
                  })
                )
              }
            >
              <div>
                <div>
                  <InputOld
                    label="Select Tag"
                    classes={{
                      Input__label: "OperatingLimitsManager__limitEdit__label",
                      Input: "OperatingLimitsManager__limit__level",
                    }}
                    action={(value) =>
                      that.setLimitValue(
                        NEW_LIMIT_ID,
                        "variableId",
                        value.value
                      )
                    }
                    value={limits[NEW_LIMIT_ID]?.data.variableId}
                    type={"multipleselect"}
                    isMulti={false}
                    options={[{}, ...(this.state.tagsForSelect || [])]}
                  />
                </div>
                <div>
                  <InputOld
                    label="Select Limit Level"
                    classes={{
                      Input__label: "OperatingLimitsManager__limitEdit__label",
                      Input:
                        "OperatingLimitsManager__limit__level select-bordered",
                    }}
                    action={(value) =>
                      that.setLimitValue(NEW_LIMIT_ID, "level", value)
                    }
                    value={limits[NEW_LIMIT_ID]?.data.level}
                    type="select"
                    options={[
                      {},
                      ...ALL_LIMIT_LEVELS_ORDERED.map((level) => {
                        return {
                          value: level,
                          label: level.toUpperCase(),
                        };
                      }),
                    ]}
                  />
                </div>
                <table className="OperatingLimitsManager__table shadow-lg">
                  <thead className="OperatingLimitsManager__table__head">
                    <tr className="">
                      <th>
                        <span className="OperatingLimitsManager__limit__date OperatingLimitsManager__limit__label">
                          Effective Date
                        </span>
                      </th>
                      <th>
                        <span className="OperatingLimitsManager__limit__value OperatingLimitsManager__limit__label">
                          Limit Value
                        </span>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="OperatingLimitsManager__table__body">
                    {_.map(
                      limits[NEW_LIMIT_ID]?.workingSegments || [],
                      (segment) => {
                        const dateForCalendar = moment(segment.start).toDate();

                        const fmt =
                          moment(dateForCalendar).format("MMM DD, YYYY");

                        const handleDateChange = (date) =>
                          this.onDateChange(
                            NEW_LIMIT_ID,
                            segment.segmentId,
                            moment(date).format("MMM DD, YYYY")
                          );

                        return (
                          <tr className="" key={segment.segmentId}>
                            <td>
                              {/* <CalendarSelect
                                  value={moment(segment.start).toDate()}
                                  onDateChange={(date) =>
                                    this.onDateChange(
                                      NEW_LIMIT_ID,
                                      segment.segmentId,
                                      moment(date).format("MMM DD, YYYY")
                                    )
                                  }
                                  displayFormat={(d) =>
                                    moment(d).format("MMM DD, YYYY")
                                  }
                                /> */}
                              <CalendarComponents.SingleDaySelectWithArrows
                                closeOnChange
                                value={dateForCalendar}
                                onChange={handleDateChange}
                                close={{
                                  className: "btn-outline float-right",
                                  size: "xs",
                                }}
                              />
                            </td>
                            <td>
                              <InputOld
                                classes={{
                                  Input__label:
                                    "OperatingLimitsManager__limitEdit__label",
                                  Input:
                                    "OperatingLimitsManager__limit__value input-sm",
                                }}
                                action={(value) =>
                                  that.setSegmentValue(
                                    NEW_LIMIT_ID,
                                    segment.segmentId,
                                    "value",
                                    value
                                  )
                                }
                                value={segment.value}
                              />
                            </td>
                            <td>
                              <Button
                                className="btn-outline btn-error"
                                id={`${that.prefix}-${NEW_LIMIT_ID}-LimitEditRemove`}
                                onClick={() =>
                                  this.removeSegment(
                                    NEW_LIMIT_ID,
                                    segment.segmentId
                                  )
                                }
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <Button
                  className="btn-outline btn-primary my-2 ml-2"
                  id={`${that.prefix}-${NEW_LIMIT_ID}-LimitEditAdd`}
                  onClick={() => this.addSegment(NEW_LIMIT_ID)}
                >
                  Add History
                </Button>
                <div className="flex justify-between mb-2 mt-6">
                  <Button
                    className="btn-error btn-outline"
                    id={`${that.prefix}-${NEW_LIMIT_ID}-LimitEditCancel`}
                    onClick={() => createRef.current.exit()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-primary"
                    id={`${that.prefix}-${NEW_LIMIT_ID}-LimitEditSave`}
                    loading={
                      !!flags.set(
                        this.state.limits?.[NEW_LIMIT_ID]?.state,
                        LIMIT_STATES.saving
                      )
                    }
                    onClick={() => createRef.current.submit()}
                  >
                    Save
                  </Button>
                </div>
              </div>

              <First>
                <Fragment
                  match={!!this.state.limits?.[NEW_LIMIT_ID]?.errors.length}
                >
                  <div className="OperatingLimitsManager__saveMessage OperatingLimitsManager__saveMessage--error">
                    {/* This code is still compiled, even when it doesn't match */}
                    {(this.state.limits?.[NEW_LIMIT_ID]?.errors[0] || {}).msg}
                  </div>
                </Fragment>

                <Fragment
                  match={flags.set(
                    this.state.limits?.[NEW_LIMIT_ID]?.state,
                    LIMIT_STATES.save_completed
                  )}
                >
                  <div className="OperatingLimitsManager__saveMessage">
                    Save Successful!
                  </div>
                </Fragment>
              </First>
            </Form>
          ) : null}
          <div className="form-control mt-4">
            <label className="input-group input-group-sm">
              <span>Search</span>
              <input
                onChange={({ target: { value } }) => this.onFilterChange(value)}
                className="input input-sm input-bordered w-2/5"
                value={this.state.filter}
                placeholder="Search by Tag ID or Description"
              />
            </label>
          </div>
          <div className="my-6 drop-shadow-md">
            {!tagsWithLimits.length && (
              <li className="OperatingLimitsManager__noresults">
                No results found
              </li>
            )}
            {_.map(tagsWithLimits, (tag) => (
              <OperatingLimitsDropdown
                setSegmentValue={(...args) => this.setSegmentValue(...args)}
                deleteLimit={(limitId) => this.deleteLimit(limitId)}
                prefix={this.prefix}
                key={tag._id}
                tag={tag}
                onSubmitEdit={(limit) => {
                  const limitId = limit.data._id;
                  this.saveLimit(limitId).then(() => {
                    this.setState(
                      produce((state) => {
                        let limit = state.limits[limitId];
                        limit.state = flags.set(
                          tag.state,
                          LIMIT_STATES.editing,
                          false
                        );
                      })
                    );
                  });
                }}
                onDateChange={(...args) => {
                  this.onDateChange(...args);
                }}
                addSegment={(limitId) => this.addSegment(limitId)}
                removeSegment={(limitId, segment) =>
                  this.removeSegment(limitId, segment.segmentId)
                }
                onFormExit={(limit) => {
                  const limitId = limit.data._id;
                  this.setState(
                    produce((state) => {
                      let limit = state.limits[limitId];
                      limit.edits = {};
                      limit.workingSegmentsEdits = _.cloneDeep(
                        limit.workingSegments
                      );

                      limit.state = flags.set(
                        tag.state,
                        LIMIT_STATES.editing,
                        false
                      );
                    })
                  );
                }}
                toggleEdit={(limit) => {
                  this.setState(
                    produce((state) => {
                      state.limits[limit.data._id].state = flags.set(
                        limit.state,
                        LIMIT_STATES.editing,
                        true
                      );
                    })
                  );
                }}
                toggleExpanded={() => {
                  this.setState(
                    produce((state) => {
                      state.tags[tag._id].expanded =
                        !state.tags[tag._id].expanded;
                    })
                  );
                }}
              />
            ))}
          </div>
        </Fragment>
      </First>
    );
  }
}

function WrappedOperatingLimitsManager() {
  const api = useAPI();
  usePermissionBasedDocumentTitleForSettingsPage();

  const hasEditAccess = useHasEditPermission();
  const fullUnit = useCurrentUnitObject();
  /* Handle UTC issues with displaying inside the date selector. */

  const procStartDate = useProcStartDate(fullUnit);

  return (
    <OperatingLimitsManager
      hasEditAccess={hasEditAccess}
      procStartDate={procStartDate}
      api={api}
    />
  );
}

export default WrappedOperatingLimitsManager;
