import * as React from "react";
import LabelsBadge from "./labels-badge";
import { FaAt, FaHashtag, FaTag, FaUsers } from "react-icons/fa";
import type Comment from "../../types/api/Comment";
import { Badge } from "../ui/badge";
import { type IconType } from "react-icons";
import { cn } from "../../lib/utils";

function BadgeListContainer({
  badges,
  icon: Icon,
}: {
  icon: IconType;
  badges: React.ReactNode;
}) {
  return (
    <div className="flex items-center gap-2 ml-3">
      <Icon className="h-4 w-4 min-w-max" />
      <ul className="inline-flex flex-wrap gap-1">{badges}</ul>
    </div>
  );
}

export default function CommentBadgeList({
  comment,
  variant = "side",
}: {
  variant?: "side" | "block";
  comment: Comment;
}) {
  return (
    <div
      className={cn(
        "gap-3 flex flex-col py-2",
        variant === "side" && "w-1/4 border-l border-zinc-400",
        variant === "block" && "-ml-9"
      )}
    >
      {comment.tagged_users && comment.tagged_users.length > 0 && (
        <BadgeListContainer
          icon={FaAt}
          badges={
            <>
              {comment.tagged_users.map((u) => {
                const f = u.first;
                const l = u.last;

                return (
                  <li key={`${comment._id}${u._id}`}>
                    <Badge className="break-all" variant="primary">
                      {f} {l}
                    </Badge>
                  </li>
                );
              })}
            </>
          }
        />
      )}

      {comment.tagged_teams && comment.tagged_teams.length > 0 && (
        <BadgeListContainer
          icon={FaUsers}
          badges={
            <>
              {comment.tagged_teams.map((t) => {
                return (
                  <li key={`${comment._id}${t.name}`}>
                    <Badge className="break-all" variant="primary">
                      {t.name} ({t.users.length})
                    </Badge>
                  </li>
                );
              })}
            </>
          }
        />
      )}

      {comment.labels && comment.labels.length > 0 && (
        <BadgeListContainer
          icon={FaTag}
          badges={
            <>
              {comment.labels.map(({ color, name }) => (
                <li key={`${comment._id}${name}`}>
                  <LabelsBadge backgroundColor={color}>{name}</LabelsBadge>
                </li>
              ))}
            </>
          }
        />
      )}

      {comment.variables && comment.variables.length > 0 && (
        <BadgeListContainer
          icon={FaHashtag}
          badges={
            <>
              {comment.variables.map((v) => {
                return (
                  <li key={v._id}>
                    <Badge className="break-all" variant={"outline"}>
                      {v.name}
                    </Badge>
                  </li>
                );
              })}
            </>
          }
        />
      )}
    </div>
  );
}
