import { createPlantFormatter, TimeFormat } from "../shared-ui/lib/date-utils";
import { useConfigRequired } from "../zustand/config/useConfigStore";

function usePlantTimeFormatter(fmt?: TimeFormat) {
  const cfg = useConfigRequired();

  const tz = cfg.timezone;

  return createPlantFormatter(tz, fmt);
}

export { usePlantTimeFormatter };
