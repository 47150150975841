import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { create } from "zustand";

export type TagFilters = {
  // true means include tags with this type
  // undefined means don't filter by this type
  Comments: boolean | undefined;
  Anomalies: boolean | undefined;
  Acknowledgments: boolean | undefined;
  Clusters: boolean | undefined;
};
export type AnomalyTimeFilters = {
  lt: string;
  gt: string;
};

type TagsFilterState = {
  tagFilters: TagFilters;
  setTagFilters: (filter: keyof TagFilters, value: boolean | undefined) => void;
  anomalyTimeFilter: AnomalyTimeFilters;
  setAnomalyTimeFilter: (filter: AnomalyTimeFilters) => void;
  clearTagFilters: () => void;
};

function createTagsFilterStore() {
  return create<TagsFilterState>((set) => ({
    tagFilters: {
      Comments: undefined,
      Anomalies: undefined,
      Acknowledgments: undefined,
      Clusters: undefined,
    },
    setTagFilters: (filter, value) =>
      set((curr) => ({
        tagFilters: {
          ...curr.tagFilters,
          [filter]: value,
        },
      })),
    anomalyTimeFilter: {
      lt: "24h",
      gt: "0m",
    },
    setAnomalyTimeFilter: (filter) => set({ anomalyTimeFilter: filter }),
    clearTagFilters: () =>
      set({
        tagFilters: {
          Comments: undefined,
          Anomalies: undefined,
          Acknowledgments: undefined,
          Clusters: undefined,
        },
        anomalyTimeFilter: {
          lt: "24h",
          gt: "0m",
        },
      }),
  }));
}

type useTagsFilterStore = ReturnType<typeof createTagsFilterStore>;

const TagsFilterStoreContext = createContext<useTagsFilterStore | undefined>(
  undefined
);

function TagsFilterStoreProvider({ children }: PropsWithChildren) {
  const [useStore] = useState(() => createTagsFilterStore());
  return (
    <TagsFilterStoreContext.Provider value={useStore}>
      {children}
    </TagsFilterStoreContext.Provider>
  );
}

function useTagsFilterStoreContext() {
  const store = useContext(TagsFilterStoreContext);

  if (!store) {
    throw new Error(
      "useTagsFilterStoreContext must be used within a TagsFilterStoreProvider"
    );
  }

  return store;
}

export { TagsFilterStoreProvider, useTagsFilterStoreContext };
