import { z } from "zod";
import { MongoIdUpdatedAtCreatedAt, deleted, units } from "./common";
import { UserRoleNumber } from "../../zustand/auth/types/UserRole";

const authorSchema = MongoIdUpdatedAtCreatedAt.merge(deleted)
  .merge(units)
  .extend({
    role: UserRoleNumber,
    email: z.string(),
    first: z.string(),
    last: z.string(),
    receive_reports: z.boolean(),
  });

export const acknowledgementType = z.enum(["normal", "monitoring", "issue"]);
export type acknowledgementType = z.infer<typeof acknowledgementType>;

export const acknowledgementSchema = z.object({
  _id: z.string(),
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  end: z.string().datetime(),
  start: z.string().datetime(),
  unacknowledgment: z.boolean().default(false),
  type: acknowledgementType.optional(),
  unit: z.string(),
  variable: z.string(),
  author: authorSchema,
});

export type acknowledgementSchema = z.infer<typeof acknowledgementSchema>;
