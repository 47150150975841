import { useConfigRequired } from "../../../zustand/config/useConfigStore";
import { addToast } from "../../toast/use-toast-store";
import { useRef } from "react";
import Button from "../../common/Button/Button";
import * as R from "remeda";
import { Trash2 } from "lucide-react";
import { FaultTreeNodeFiles } from "./use-ft-node-files-query";
import { FileInput, FileInputProps } from "@mantine/core";

type ForbiddenMIMEPrefixes = "example" | "font" | "model" | "audio" | "video"; // essentially only text and image are allowed

const forbiddenMap: { [TPrefix in ForbiddenMIMEPrefixes]: TPrefix } = {
  example: "example",
  font: "font",
  model: "model",
  audio: "audio",
  video: "video",
};

const forbiddenArr = Object.values(forbiddenMap);

const ValueComponent: FileInputProps["valueComponent"] = ({ value }) => {
  if (!Array.isArray(value)) {
    throw new Error("should be an array of files");
  }

  return (
    <span className="text-white flex justify-center uppercase font-semibold">
      Add more files
    </span>
  );

  // return (
  //   <div className="rounded-full border border-zinc-200 px-2 text-[0.9rem]">
  //     {value.name}
  //   </div>
  // );
};

export function FileUploader({
  disabled,
  files,
  setFiles,
  nodeId,
}: {
  disabled: boolean;
  files: File[];
  setFiles: (fn: File[]) => void;
  nodeId: string;
}) {
  const filesQuery = FaultTreeNodeFiles.useQuery(nodeId);

  const config = useConfigRequired();
  const MAX_FILE_SIZE_BYTES = config.maxFileSizeMb * 1_000_000;

  const inputRef = useRef<HTMLInputElement>(null);

  const hasAtLeast1File = files.length > 0;

  if (filesQuery.isError || filesQuery.isLoading) {
    return null;
  }

  const dbFiles = filesQuery.data;

  return (
    <>
      <div className="flex flex-col p-3">
        {files.map((f) => {
          return (
            <div
              className="flex justify-between items-center"
              key={f.name + f.lastModified.toString()}
            >
              <span className="text-[0.8rem]">{f.name}</span>
              <Trash2
                onClick={() => {
                  setFiles(files.filter((f_) => f_ !== f));
                }}
                className="text-xred-9 hover:text-xred-10 w-4 h-4 cursor-pointer active:scale-95"
              />
            </div>
          );
        })}
      </div>
      <FileInput
        /**
         * This key is required because the file input cannot be easily reset.
         *
         * It just doesn't play well with React. Even if we use a regular <input type="file" /> and reset it, it doesn't work.
         */
        key={files.length.toString()}
        disabled={disabled}
        placeholder="Attach files (optional)"
        multiple
        value={files}
        valueComponent={ValueComponent}
        onChange={(newFiles) => {
          if (
            !newFiles.every((ff) => {
              if (forbiddenArr.some((prefix) => ff.type.startsWith(prefix))) {
                addToast({
                  title: `This file type is not allowed`,
                  description: "",
                  variant: "danger",
                });
                return false;
              } else if (ff.size > MAX_FILE_SIZE_BYTES) {
                addToast({
                  title: ff.name + " is too large",
                  description:
                    "Maximum upload size is " +
                    config.maxFileSizeMb.toString() +
                    "MB",
                  variant: "danger",
                });
                return false;
              }

              return true;
            })
          ) {
            return;
          }

          const allFiles = files.concat(newFiles);

          const namesGrouped = R.groupBy(
            allFiles.map((f) => f.name).concat(dbFiles.map((f) => f.filename)),
            (name) => name
          );

          const sameName = Object.entries(namesGrouped).find(([_, names]) => {
            return names.length > 1;
          });

          if (sameName) {
            addToast({
              title: `Duplicate file name: ${sameName[0]}`,
              description: "",
              variant: "danger",
            });
            return;
          }

          setFiles(allFiles);
        }}
        classNames={{
          input:
            "bg-neutral rounded-lg active:scale-95 transition-all border-0",
          placeholder: "text-white flex justify-center uppercase font-semibold",
        }}
      />
    </>
  );
}
