import React, { useState, useEffect } from "react";
import _ from "lodash";
import useAPI from "../../../lib/useAPI";
import { getAPIUrl } from "../../../api-url";
import useDateRange from "./useDateRange";
import { format } from "date-fns";
import classNames from "classnames";
import useBaseUrl from "../../../zustand/useBaseUrl";
import moment from "moment";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { SingleDaySelectWithArrows } from "../../common/calendar";

const PAGE_NAME = "Reports Library";

function dateFormatter(d) {
  return format(moment(d).toDate(), "yyyy-MM-dd");
}

function ReportsLibrary() {
  usePermissionBasedDocumentTitleForSettingsPage();
  const API = useAPI();

  const { range, setRangeEnd } = useDateRange(7, null, dateFormatter);

  const [srMap, setSrMap] = useState();
  const [specRepNames, setNames] = useState();

  const [out, setOut] = useState();
  /**
   * Since these files live on the server, there's this weird logic that needs to happen
   */
  const base = useBaseUrl();
  const baseUrl = getAPIUrl() + base;

  useEffect(() => {
    /**
     * We only run this for the initial mount, but this use effect runs initOrRefresh on other conditions as well
     */
    const onlyForInitialLoad = () =>
      API.getSpecialtyReports().then((specialtyReports) => {
        const specialtyReportsMap = _.keyBy(specialtyReports, (o) => o._id);
        const srNames = specialtyReports.map((sr) => sr.name);
        return [specialtyReportsMap, srNames];
      });

    const initOrRefresh = async () => {
      let initialRequest = undefined;
      if (!srMap || !specRepNames) {
        // the initial page load data hasn't loaded yet
        initialRequest = onlyForInitialLoad();
      }

      const promises = range.map((rd_key) => API.getReports({ rd_key }));

      if (initialRequest) {
        const [srMap_, srNames] = await initialRequest;
        setSrMap(srMap_);
        setNames(srNames);
      }

      const data = await Promise.all(promises);

      setOut(
        _.reduce(
          data,
          (o, arrReports, idx) => {
            const date = range[idx];
            o[date] = _.keyBy(arrReports, (r) => r.source);
            return o;
          },
          {}
        )
      );
    };

    initOrRefresh();
  }, [range]);

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6 mb-3">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>
        </div>
      </div>
      {out && srMap && specRepNames ? (
        <>
          <SingleDaySelectWithArrows
            value={moment(range[range.length - 1]).toDate()}
            onChange={setRangeEnd}
            close={{
              className: "btn-outline float-right",
              size: "xs",
            }}
            closeOnChange
          />
          <div className="bg-white whitespace-nowrap overflow-x-auto md:mt-2 border border-bordgrey2 rounded-xl mb-6 shadow-md">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-2 text-center text-[1rem]">Date</th>
                  {range.map((day, idx) => (
                    <th
                      key={day}
                      className={classNames("px-2 text-center text-[1rem]", {
                        "border-r border-bordgrey2": idx !== range.length - 1,
                      })}
                    >
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_.map(srMap, (srObject, srId) => {
                  return (
                    <tr
                      className="hover:bg-indigo-100"
                      height="30px"
                      key={srId}
                    >
                      <td className="px-2 border-t border-r border-bordgrey2 text-left text-[0.8rem] font-semibold">
                        {srObject.name}
                      </td>
                      {_.map(range, (date, idx) => {
                        // does this date have this SR?

                        const dataForDate = out[date];

                        if (!dataForDate) {
                          return null;
                        }

                        const sr = dataForDate[srId];

                        const btn = sr ? (
                          <a
                            href={`${baseUrl}/reports/${sr._id}/contents`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Tooltip label="Open Report" withArrow>
                              <Button
                                icon="download"
                                size="xs"
                                className="btn-ghost"
                              />
                            </Tooltip>
                          </a>
                        ) : null;
                        return (
                          <td
                            key={date}
                            className={classNames(
                              "hover:bg-pillyellow px-2 border-t border-bordgrey2 text-center",
                              { "border-r": idx !== range.length - 1 }
                            )}
                          >
                            {btn}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ReportsLibrary;
