import { format } from "date-fns";
import { DateTime } from "luxon";

export type TimeFormat = "dd-LLL-yyyy hh:mm a" | "dd-LLL-yyyy";

const createPlantFormatter = (
  tz: string,
  fmt: TimeFormat = "dd-LLL-yyyy hh:mm a"
) => {
  const testing = DateTime.now().setZone(tz);
  if (!testing.isValid) {
    throw new Error(`Invalid timezone: ${tz}`);
  }

  return (t: number) => {
    return DateTime.fromMillis(t, { zone: tz }).toFormat(fmt);
  };
};

const ONE_HOUR_AS_MILLISECONDS = 1000 * 60 * 60;
const ONE_DAY_AS_MILLISECONDS = ONE_HOUR_AS_MILLISECONDS * 24;

const getDurationObject = (
  first: Date | number,
  last: Date | number | null
) => {
  const diff = last
    ? new Date(last).valueOf() - new Date(first).valueOf()
    : first.valueOf();

  const days = Math.floor(diff / ONE_DAY_AS_MILLISECONDS);

  const hours = Math.floor(
    (diff % ONE_DAY_AS_MILLISECONDS) / ONE_HOUR_AS_MILLISECONDS
  );

  const minutes = Math.floor((diff % ONE_HOUR_AS_MILLISECONDS) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const formatDuration = (first: Date | number, last: Date | number) => {
  const { days, hours, minutes } = getDurationObject(first, last);

  return `${days > 0 ? days.toString() + "d " : ""}${
    hours > 0 ? hours.toString() + "h " : ""
  }${minutes > 0 ? minutes.toString() + "m " : ""}`;
};

export function formatDuration4(diff: number) {
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  return `${days > 0 ? days.toString() + "d " : ""}${
    hours > 0 ? hours.toString() + "h " : ""
  }${minutes > 0 ? minutes.toString() + "m " : ""}${
    seconds > 0 ? seconds.toString() + "s" : ""
  }`;
}

export function formatPPP(d: Parameters<typeof format>[0]) {
  return format(d, "PPP");
}

export function lastUpdateFormat(d: string | Date) {
  const date = new Date(d);
  return format(date, "M/d/yyyy h:mm a");
}

export { createPlantFormatter };
