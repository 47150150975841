const FontSizes = {
  xs: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  sm: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  base: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  lg: {
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
  },
};

export { FontSizes };
