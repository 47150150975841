import React, { ComponentProps, useEffect, useState } from "react";

import {
  BarData,
  DensityData,
  RidgelineLimits,
} from "../../../../types/api/RidgelineResponse";
import { RidgelineStats } from "../../../../frameworks/fetcher/api-routes-experimental";
import { useCapabilityQuery } from "../../use-variability-query";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../shared-ui/frontend/popover";
import { Separator } from "../../tooltip/tooltip";
import { NUMBER_FORMATTERS } from "../../../charts/constants/dynamicTrendChartConstants";
import { ClickableVariable } from "../../types";
import { useCapabilityStore } from "../../capability-store";
import { FaUndo } from "react-icons/fa";
import { cn } from "../../../../lib/utils";
import { Button } from "../../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../shared-ui/frontend/tooltip";

export default function CapabilityCalculator({
  variable,
  start,
  end,
  variableName,
  withoutPortal,
}: {
  variable: {
    variableId: string;
    values: BarData[];
    stats: (RidgelineStats & RidgelineLimits) | null;
    densityData: DensityData[];
    ref: string | null;
  } & ClickableVariable;
  start: number;
  end: number;
  variableName: string;
  withoutPortal: boolean;
} & Pick<ComponentProps<typeof PopoverContent>, "withoutPortal">) {
  // need to specify type as number | undefined in order to allow setHandler(undefined) below
  const [usl, setUsl] = useState<number | undefined>(undefined);
  const [lsl, setLsl] = useState<number | undefined>(undefined);
  const [target, setTarget] = useState<number | undefined>(undefined);
  const [tolerance, setTolerance] = useState<number | undefined>(6);
  const addToCapabilityStore = useCapabilityStore((state) => state.addToData);
  const capabilityStats = useCapabilityStore((state) =>
    variable.ref ? state.getData(variable.ref) : undefined
  );

  const queryResults = useCapabilityQuery(
    variable.variableId,
    start,
    end,
    variable.excludedModesMap
  );
  const data = queryResults?.data;

  useEffect(() => {
    const uslValue =
      data && data.lambda && usl ? transformValue(usl, data.lambda) : usl;

    const lslValue =
      data && data.lambda && lsl ? transformValue(lsl, data.lambda) : lsl;

    const targetValue =
      data && data.lambda && target
        ? transformValue(target, data.lambda)
        : target;

    const capabilityResults =
      tolerance && data
        ? calculateCapabilityValues(
            data.mean,
            data.std,
            tolerance,
            uslValue,
            lslValue,
            targetValue
          )
        : undefined;
    if (variable.ref) {
      addToCapabilityStore({
        ref: variable.ref,
        usl,
        lsl,
        target,
        ...capabilityResults,
      });
    }
  }, [usl, lsl, target, tolerance, data, variable, addToCapabilityStore]);

  const valueInputs = [
    {
      label: "usl",
      value: usl,
      setHandler: setUsl,
    },
    {
      label: "lsl",
      value: lsl,
      setHandler: setLsl,
    },
    {
      label: "target",
      value: target,
      setHandler: setTarget,
    },
    {
      label: "tolerance",
      value: tolerance,
      setHandler: setTolerance,
    },
  ];

  // TODO: withoutPortal seems to always be true, do we need to pass it in?
  return (
    <Tooltip>
      <TooltipContent>Capability Analysis</TooltipContent>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              variant={"ghost"}
              size={"icon-sm"}
              className="text-sm hover:text-xindigo-11 text-xslate-11"
              onClick={(e) => e.stopPropagation()}
            >
              Cp
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-max text-sm")}
          withoutPortal={withoutPortal}
        >
          <div id="variable-info" className="flex flex-col justify-between">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-1 right-1"
              onClick={() => {
                setUsl(undefined);
                setLsl(undefined);
                setTarget(undefined);
                setTolerance(6);
              }}
            >
              <FaUndo className="h-3 w-3" />
            </Button>
            <div className="mb-1">
              <label className="text-xslate-11">TAG</label>
              <p className="text-xslate-12 ml-2" id="variable-name">
                {variableName}
              </p>
            </div>

            <div className="my-1">
              <label className="text-xslate-11">POWER TRANSFORMATION</label>
              <p className="text-xslate-12 ml-2">Enabled</p>
            </div>
          </div>

          <div className="flex flex-col" id="valuesInputs">
            {valueInputs.map(({ label, value, setHandler }) => {
              return (
                <div
                  key={label}
                  id={label}
                  className="flex flex-col items-start my-1"
                >
                  <label className="text-xslate-11 my-1" id={`${label}-label`}>
                    {label.toLocaleUpperCase()}
                  </label>
                  <input
                    type="number"
                    id={`${label}-handler`}
                    className={cn(
                      "w-full h-full rounded-md border-2 py-0 px-1 text-x-slate-12"
                    )}
                    value={value !== undefined ? value : ""}
                    // prevent any symbols in number input to avoid messy input for function
                    onKeyDown={(e) => {
                      if (
                        (e.key === "Backspace" || e.key === "Delete") &&
                        /\b\d\b/.test(`${value}`)
                      ) {
                        setHandler(undefined);
                      }
                      ["e", "E"].includes(e.key) && e.preventDefault();
                    }}
                    onChange={(e) => setHandler(+e.target.value)}
                  />
                </div>
              );
            })}
          </div>
          <Separator className="bg-xslate-8 mt-4 mb-3" />
          <div className="flex flex-col justify-start" id="capabilityResults">
            <div className="w-full flex justify-between">
              <span className="text-xslate-11">Cp:</span>{" "}
              <span>
                {capabilityStats && tolerance && capabilityStats.Cp
                  ? format(capabilityStats.Cp)
                  : "--"}
              </span>
            </div>
            <div className="w-full flex justify-between">
              <span className="text-xslate-11">Cpk:</span>{" "}
              <span>
                {capabilityStats && tolerance && capabilityStats.Cpk
                  ? format(capabilityStats.Cpk)
                  : "--"}
              </span>
            </div>
            <div className="w-full flex justify-between">
              <span className="text-xslate-11">CCpk:</span>{" "}
              <span>
                {capabilityStats && tolerance && capabilityStats.CCpk
                  ? format(capabilityStats.CCpk)
                  : "--"}
              </span>
            </div>
            <div className="w-full flex justify-between">
              <span className="text-xslate-11">Cpm:</span>{" "}
              <span>
                {capabilityStats && tolerance && capabilityStats.Cpm
                  ? format(capabilityStats.Cpm)
                  : "--"}
              </span>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}

/**
 * Cp = (USL - LSL)/(tolerance*sigma);
 * CPL=(mean-LSL)/(sigma*tolerance/2),
 * CPU=(USL-mean)/(sigma*tolerance/2,
 * Cpk =min(CPL, CPU)
 * CCpk = (USL-Target)/(sigma*tolerance/2) when only USL is present,
 * CCpk = (LSL-Target)/(sigma*tolerance/2) when only LSL is present,
 * CCpk = (min(USL-Target, LSL-Target))/(sigma*tolerance/2) when both USL and LSL are present
 * Cpm = (usl - lsl) / (tolerance * Math.sqrt(std ** 2 + (mean - target) ** 2))
 * @returns capability values
 */
export function calculateCapabilityValues(
  mean: number,
  std: number,
  tolerance: number,
  usl?: number,
  lsl?: number,
  target?: number
) {
  const Cp =
    usl !== undefined && lsl !== undefined
      ? (usl - lsl) / (tolerance * std)
      : undefined;
  const CPL =
    lsl !== undefined ? (mean - lsl) / ((std * tolerance) / 2) : undefined;
  const CPU =
    usl !== undefined ? (usl - mean) / ((std * tolerance) / 2) : undefined;
  const Cpk =
    CPL !== undefined && CPU !== undefined
      ? Math.min(CPL, CPU)
      : CPL !== undefined
        ? CPL
        : CPU !== undefined
          ? CPU
          : undefined;
  const CCpkNumerator =
    usl !== undefined && lsl !== undefined && target !== undefined
      ? Math.min(usl - target, target - lsl)
      : lsl !== undefined && target !== undefined
        ? target - lsl
        : usl !== undefined && target !== undefined
          ? usl - target
          : undefined;
  const CCpk =
    CCpkNumerator !== undefined
      ? CCpkNumerator / ((std * tolerance) / 2)
      : undefined;
  const Cpm =
    usl !== undefined && lsl !== undefined && target !== undefined
      ? (usl - lsl) / (tolerance * Math.sqrt(std ** 2 + (mean - target) ** 2))
      : undefined;

  return { Cp, Cpk, CCpk, Cpm };
}

const format = (val: number) => NUMBER_FORMATTERS.base(val, 4);

// yeo johnson transformations
// y = ((x + 1)**lambda - 1) / lambda                    for x >= 0, lambda != 0
// y = log(x + 1)                                        for x >= 0, lambda = 0
// y = -((-x + 1) **(2 - lambda) - 1) / (2 - lambda)     for x < 0, lambda != 2
// y = -log(-x + 1)                                      for x < 0, lambda = 2
function transformValue(x: number, lambda: number) {
  // x is positive
  if (x >= 0) {
    // lambda is 0
    if (lambda === 0) return Math.log(x + 1);
    // lambda is not 0
    else return ((x + 1) ** lambda - 1) / lambda;
  }
  // x is negative
  else {
    // lambda is 2
    if (lambda === 2) return -Math.log(-x + 1);
    // lambda is not 2
    else return -((-x + 1) ** (2 - lambda) - 1) / (2 - lambda);
  }
}
