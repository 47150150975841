import { Input } from "../../frontend/input";
import { cn } from "../../frontend/cn";
import { Check } from "lucide-react";
import { Button } from "../../frontend/button";

function ClampInputToggle({
  isAuto,
  setIsAuto,
  setValue,
  value,
  isError,
}: {
  isAuto: boolean;
  setIsAuto: (
    isAuto:
      | { auto: true }
      | {
          auto: false;
          startingValue?: {
            asInputted: string;
            parsed: number;
          };
        }
  ) => void;
  value: string;
  setValue?: (value: string) => void;
  isError: boolean;
}) {
  return (
    <>
      <Input
        type="number"
        // during auto mode, we take the value from the "in view" data
        value={value}
        onChange={(e) => {
          setValue?.(e.target.value);

          const v = parseFloat(e.target.value);

          setIsAuto({
            auto: false,
            startingValue: isNaN(v)
              ? undefined
              : {
                  asInputted: e.target.value,
                  parsed: v,
                },
          });
        }}
        className={cn(
          "h-8 w-32",
          isError &&
            "ring-2 ring-red-500 ring-offset-1 focus-visible:ring-red-500"
        )}
      />
      <Button
        variant={isAuto ? "default" : "outline"}
        className={cn("h-8 grow", isAuto && "pointer-events-none select-none")}
        size={"xxs"}
        onClick={
          isAuto
            ? undefined
            : () => {
                // currently in manual mode, so we switch to auto
                setIsAuto({ auto: true });
              }
        }
      >
        {isAuto && <Check className="size-4 mr-1" />}
        AUTO
      </Button>
    </>
  );
}
export { ClampInputToggle };
