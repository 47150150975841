import { createStore } from "jotai";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from "react";
import { create } from "zustand";
import { useVariablesArrayQuery } from "../../hooks/tanstack-query";
import { createJotaiStoreForChartSync } from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import { assertMinLen1, minLen1 } from "../../shared-ui/lib/utils";
import { subscribeWithSelector } from "zustand/middleware";
import { Atoms } from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { useTimezone } from "../../zustand/config/useConfigStore";

type ChartStore = { store: ReturnType<typeof createStore>; id: string };

type DisplayedChartsStore = {
  charts: ChartStore[] | undefined;
  overlayChart: ChartStore | undefined;
  addChart: (chart: ChartStore) => void;
  removeChart: (id: string) => void;
  replaceCharts: (charts: ChartStore[]) => void;
};

function createUseDisplayedChartsStore() {
  return create<DisplayedChartsStore>()(
    subscribeWithSelector((set, get) => {
      return {
        charts: undefined,
        overlayChart: undefined,
        addChart: (chart) => {
          const charts = get().charts;
          if (!charts) throw new Error("shouldn't be calling this");
          set({
            charts: [chart, ...charts],
          });
        },
        replaceCharts: (charts) => {
          set({ charts });
        },
        removeChart: (id) => {
          const charts = get().charts;
          if (!charts) throw new Error("shouldn't be calling this");
          set({
            charts: charts.filter((x) => x.id !== id),
          });
        },
      };
    })
  );
}

type UseDisplayedChartsStore = ReturnType<typeof createUseDisplayedChartsStore>;

const UseDisplayedChartsContext = createContext<
  UseDisplayedChartsStore | undefined
>(undefined);

function useGetUseDisplayedChartsStores() {
  const s = useContext(UseDisplayedChartsContext);

  if (!s) {
    throw new Error(
      "useGetUseDisplayedChartsStores must be used within UseDisplayedChartsStoreProvider"
    );
  }

  return s;
}

function UseDisplayedChartsStoreProvider({ children }: PropsWithChildren) {
  const variables = useVariablesArrayQuery().data;
  const [useStore] = useState(() => createUseDisplayedChartsStore());

  const zone = useTimezone();

  useEffect(() => {
    if (!variables) return;
    if (useStore.getState().charts) return; // already initialized
    const sp = new URLSearchParams(window.location.search);
    const dv = sp.get(DRAParamsMap.dv)?.trim() ?? "";

    const vidsArr = dv
      .split(",")
      .map((x) => {
        const vids = x
          .split("-")
          .map((x) => parseInt(x))
          .filter((x) => !isNaN(x))
          .map((x) => variables.find((v) => v.short_id === x)?._id)
          .filter((x) => x !== undefined);

        if (!minLen1(vids)) return undefined;
        return vids;
      })
      .filter((x) => x !== undefined);

    /**
     * Only initialize once !!!!!!!!
     */
    useStore.setState({
      charts: vidsArr.map((vids, _, arr) => {
        return {
          store: createJotaiStoreForChartSync({
            initialBatchVariables: assertMinLen1(
              vids.map((vid) => ({
                type: "variable",
                bv: vid.padStart(48, "0"),
              }))
            ),
            initialExpanded: arr.length === 1,
            zone,
          }),
          id: v4(),
        };
      }),
    });
  }, [variables, useStore, zone]);

  const charts = useStore((s) => s.charts);

  const [, setSp] = useSearchParams();

  useEffect(() => {
    if (!charts || !variables) return;

    const reconcileSearchParams = () => {
      const newDv = charts
        .map(({ store }) => {
          const shorts = store
            .get(Atoms.onlyVariableTrendLinesAtom)
            .map((x) => {
              const vid = x.bv.slice(24);
              const short = variables.find((v) => v._id === vid)?.short_id;
              return short;
            })
            .filter((x) => x !== undefined);

          if (!minLen1(shorts)) return undefined;
          return shorts.join("-");
        })
        .filter((x) => x !== undefined)
        .join(",");

      setSp((curr) => {
        const currentDv = curr.get(DRAParamsMap.dv);
        if (currentDv === newDv) return curr;
        const copy = new URLSearchParams(curr);
        copy.delete(DRAParamsMap.dv);
        copy.set(DRAParamsMap.dv, newDv);
        return copy;
      });
    };

    reconcileSearchParams();

    const cleanups = charts.map(({ store }) =>
      store.sub(Atoms.onlyVariableTrendLinesAtom, reconcileSearchParams)
    );

    return () => {
      for (const fn of cleanups) {
        fn();
      }
    };
  }, [charts, variables, setSp]);

  return (
    <UseDisplayedChartsContext.Provider value={useStore}>
      {children}
    </UseDisplayedChartsContext.Provider>
  );
}
export { useGetUseDisplayedChartsStores, UseDisplayedChartsStoreProvider };
