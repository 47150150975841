import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtCamel, unit } from "./common";
import {
  ALL_LIMIT_LEVELS_ORDERED,
  limitLevelSchema,
} from "../../components/of/constants";

export const sortedLevels = ALL_LIMIT_LEVELS_ORDERED;

export const operatingLimitSchema = MongoIdUpdatedAtCreatedAtCamel.merge(
  unit
).extend({
  level: limitLevelSchema,
  type: z.string(),
  value: z.number(),
  variableId: z.string(),
  data: z.array(
    z.object({
      value: z.number(),
      start: z.string().datetime(),
      // The last one in the array has a null end, the rest should be defined
      end: z.string().datetime().nullable(),
    })
  ),
});
export type OperatingLimit = z.infer<typeof operatingLimitSchema>;

export type operatingLimitSchema = OperatingLimit;
