import React, { forwardRef } from "react";
import { type BaseSelectProps } from "./BaseSelectProps";
import { cn } from "../../../lib/utils";

type Option = {
  value: string;
  label?: string;
};

type CustomSelectProps = {
  bordered?: boolean;
  options: Option[];
  size?: "sm" | "md" | "lg" | "xl";
} & Omit<BaseSelectProps, "size">;

const Select = forwardRef<HTMLSelectElement, CustomSelectProps>(
  ({ options, size = "sm", bordered = false, className, ...rest }, ref) => {
    const selectClasses = cn(
      "select font-normal",
      `select-${size}`,
      bordered && "select-bordered",
      className
    );

    return (
      <select ref={ref} className={selectClasses} {...rest}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label ?? o.value}
          </option>
        ))}
      </select>
    );
  }
);

export const DaisySelect = Select;
export default Select;
