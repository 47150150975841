import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import { cn } from "../../../shared-ui/frontend/cn";
import moment from "moment";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { Badge } from "../../../shared-ui/frontend/badge";
import { iife } from "../../../lib/utils";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { useMutation } from "@tanstack/react-query";
import { deleteTag } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { useModal } from "../../common/useModal";
import { useTagCardContext } from "./tag-card-context";
import { useGetUseTagsManagerStore } from "./tags-manager-store-provider";

function TagCardHeader({
  onDelete: onDeleteSuccess,
}: {
  onDelete?: () => void;
}) {
  const showOpc = useGetUseTagsManagerStore()((s) => s.isStaleTagsPage);
  const { editing, open, setEditing, setOpen, tag } = useTagCardContext();

  const baseUrl = useBaseUrlExperimental();

  const MODAL = useModal();

  const deleteTagMut = useMutation({
    mutationFn: () => deleteTag(baseUrl, tag._id),
    onSuccess: () => {
      addSuccessToast("Succesfully deleted");
      MODAL.closeModal();
      onDeleteSuccess?.();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
      MODAL.closeModal();
    },
  });

  const currentUnit = useCurrentUnitObject();
  if (!currentUnit) throw new Error("No current unit");
  const hasMultipleHistorians = currentUnit.alternateHistorians.length > 0;
  const hasEditPermission = useHasEditPermission();
  const isCalculatedTag = !!tag.expression?.trim?.().length;

  return (
    <div className="collapse-title font-medium flex justify-between items-center p-[0.3rem]">
      <div className="inline-flex items-center w-full">
        <Tooltip label={`${open ? "Close" : "Show"} Tag Details`} withArrow>
          <Button
            className="btn-ghost"
            icon="chevron-right"
            iconClasses={cn("transition-transform", {
              "rotate-90": open,
            })}
            onClick={() => {
              if (editing && open) setEditing(false);
              setOpen((b) => !b);
            }}
          />
        </Tooltip>
        <span className="font-semibold break-all">{`${tag.trimmedName}${
          tag.description ? ` | ${tag.description}` : ""
        }`}</span>
        {showOpc && tag.opc_time && (
          <span className="font-normal text-titlegrey ml-auto shrink-0 mx-2 text-sm">
            Last Value Received: {moment.utc(tag.opc_time).format("LLL")}
          </span>
        )}
        {isCalculatedTag && (
          <div className="shrink-0 hidden sm:inline-flex badge ml-3 py-3 px-6 border-[#cacaca] bg-pillyellow text-black">
            CALCULATED TAG
          </div>
        )}
      </div>
      {!isCalculatedTag &&
        iife(() => {
          if (!hasMultipleHistorians) return undefined; // there's only 1 historian for the unit, so we don't need to show anything

          let historianLabel;

          if (!tag.historian) historianLabel = currentUnit.historian.key;
          else {
            const options = [
              currentUnit.historian.key,
              ...currentUnit.alternateHistorians.map((h) => h.key),
            ];
            historianLabel =
              options.find((o) => o === tag.historian) ?? "Unknown";
          }

          return (
            <Badge variant={"primary"} className="text-[12px] ml-auto mr-10">
              {historianLabel}
            </Badge>
          );
        })}
      {hasEditPermission && !showOpc && (
        <div className="inline-flex items-center">
          <Tooltip label="Delete" withArrow>
            <Button
              className="btn-ghost"
              icon="trash-o"
              onClick={() => {
                MODAL.openModal({
                  title: `Are you sure you want to delete ${tag.trimmedName} ${tag.description}?`,
                  buttons: [
                    {
                      type: "button",
                      label: "DELETE",
                      className: "btn-error",
                      onClick: () => deleteTagMut.mutate(),
                    },
                  ],
                  closeLabel: "CANCEL",
                });
              }}
            />
          </Tooltip>
          {editing ? (
            <Tooltip label="Cancel">
              <Button
                className="btn-ghost"
                icon="window-close"
                onClick={() => {
                  setEditing(false);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Edit">
              <Button
                className="btn-ghost"
                icon="pencil"
                onClick={() => {
                  setOpen(true);
                  setEditing(true);
                }}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}
export { TagCardHeader };
