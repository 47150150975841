import { useState } from "react";
import { LuPlus } from "react-icons/lu";
import DaisyButton from "../../common/Button/Button";
import useNotificationsQuery from "../tanstack/useNotificationsQuery";
import { NotificationType } from "../../../types/api/Notification";
import * as R from "remeda";
import { Tooltip as MantineTooltip } from "@mantine/core";
import { NotificationCard2 } from "./notif-card";
import { CustomNotificationCreateForm2 } from "./CustomNotificationCreateForm";
import { StandardNotificationForm2 } from "./StandardNotificationForm";
import { PatternNotificationCreateForm2 } from "./PatternNotificationCreateForm";
import { cn } from "../../../lib/utils";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { X } from "lucide-react";
import { Button } from "../../../shared-ui/frontend/button";
import { useIsRenderedAsFullscreen } from "../../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";

type CreateModes = "Standard" | "Custom" | "Pattern";

const closeAtom = atom(null, (get, s) => {
  s(Atoms.brushStoreAtom, undefined);
  const a = get(Atoms.showNotificationsAtomAtom);
  if (!a) return;
  s(a, false);
});

const allowedNotificationTypesAtom = atom<NotificationType[]>((get) => {
  const isOl = get(Atoms.chartVariantAtom) === "ol";

  // Copied this logic from old DTC
  if (isOl)
    return [
      NotificationType.PATTERN,
      NotificationType.OPERATING_LIMIT,
      NotificationType.CUSTOM,
    ];

  return [
    NotificationType.DRA,
    NotificationType.PATTERN,
    NotificationType.SLOPE,
    NotificationType.CUSTOM,
  ];
});

const showNotificationsPanelAtom = atom((get) => {
  const a = get(Atoms.showNotificationsAtomAtom);
  if (a) return get(a);
  return false;
});

function BottomNotificationsArea() {
  const thisChartIsFullscreen = useIsRenderedAsFullscreen();
  const onlyRenderBottomPanelsInFullscreen = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom
  );
  const showNotificationsPanel = useAtomValue(showNotificationsPanelAtom);

  if (thisChartIsFullscreen) return null;
  if (onlyRenderBottomPanelsInFullscreen) return null;

  return showNotificationsPanel && <NotificationsArea />;
}

function NotificationsArea() {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  const close = useSetAtom(closeAtom);

  const types = useAtomValue(allowedNotificationTypesAtom);

  const { data, status, error } = useNotificationsQuery();
  const [creating, setCreating] = useState<CreateModes>();

  const currentUnit = useCurrentUnitObject();

  if (!currentUnit) throw new Error("No current unit");

  const isRealtime = currentUnit.addons?.realTime;

  /* We check to see whether or not an existing edit is happening. If it is, you can't edit another card at the same time. */
  const brushState = useAtomValue(Atoms.brushStoreAtom);
  const patternEditing =
    brushState &&
    (brushState.for === "pattern-notif-create" ||
      brushState.for === "pattern-notif-edit");

  const setBrushStore = useSetAtom(Atoms.brushStoreAtom);

  /* Show loading spinner */
  if (status === "loading") {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }
  if (!data) {
    return <div>An unknown error has occurred</div>;
  }

  if (primaryTrendLine.type === "expression") {
    return;
  }

  const variableId = primaryTrendLine.bv.slice(24);

  const sortedNotifications = R.filter(data, (notification) => {
    if (!types.includes(notification.type)) {
      return false;
    }

    return (
      notification.variableId === variableId ||
      R.reduce(
        notification.calculatedVariableList || [],
        (result, variable) => {
          return result || variable._id === variableId;
        },
        false
      )
    );
  }).sort((a, b) => {
    return (
      Date.parse(b.updatedAt || b.createdAt) -
      Date.parse(a.updatedAt || a.createdAt)
    );
  });

  const getFormOrNull = () => {
    switch (creating) {
      case "Standard":
        return (
          <StandardNotificationForm2
            notificationTypes={types}
            variableId={variableId}
            close={() => {
              setCreating(undefined);
            }}
          />
        );
      case "Custom":
        return (
          <CustomNotificationCreateForm2
            className="border border-zinc-500 bg-zinc-50 rounded-md mb-6"
            close={() => {
              setCreating(undefined);
            }}
          />
        );
      case "Pattern":
        return (
          <PatternNotificationCreateForm2
            className="border border-zinc-500 bg-zinc-50 rounded-md mb-6"
            variableId={variableId}
            close={() => {
              setCreating(undefined);
            }}
          />
        );
      case undefined:
        return null;
      default:
        const _: never = creating;
        throw new Error("Unreachable");
    }
  };

  const patternButton = isRealtime && (
    <DaisyButton
      size="xs"
      className={cn(patternEditing ? "btn-primary" : "btn-outline")}
      onClick={
        patternEditing
          ? undefined
          : () => {
              setCreating("Pattern");
              setBrushStore({
                range: undefined,
                mode: "pattern-notif-create",
              });
            }
      }
    >
      <LuPlus className="h-4 w-4 mr-1" />
      Pattern
    </DaisyButton>
  );

  const creatingStandard = creating === "Standard";
  const standardButton = (
    <DaisyButton
      size="xs"
      className={cn(creatingStandard ? "btn-primary" : "btn-outline")}
      onClick={
        creatingStandard
          ? undefined
          : () => {
              setCreating("Standard");
            }
      }
    >
      <LuPlus className="h-4 w-4 mr-1" />
      Standard
    </DaisyButton>
  );

  const creatingCustom = creating === "Custom";
  const customButton = (
    <DaisyButton
      size="xs"
      className={cn(creatingCustom ? "btn-primary" : "btn-outline")}
      onClick={
        creatingCustom
          ? undefined
          : () => {
              setCreating("Custom");
            }
      }
    >
      <LuPlus className="h-4 w-4 mr-1" />
      Custom
    </DaisyButton>
  );

  return (
    <div className="mt-2 p-4 border-t border-zinc-400 rounded-b-lg">
      <div className="flex items-center mb-6">
        <span className="text-[1.2rem] ml-1 mr-4 tracking-tight text-slate-800">
          Notifications
        </span>
        <div className="inline-flex gap-2">
          {isRealtime &&
            (patternEditing ? (
              patternButton
            ) : (
              <MantineTooltip label="Create new Pattern Notification" withArrow>
                {patternButton}
              </MantineTooltip>
            ))}
          {creatingStandard ? (
            standardButton
          ) : (
            <MantineTooltip
              label="Create or remove Standard Notification"
              withArrow
            >
              {standardButton}
            </MantineTooltip>
          )}
          {creatingCustom ? (
            customButton
          ) : (
            <MantineTooltip label="Create new Custom Notification" withArrow>
              {customButton}
            </MantineTooltip>
          )}
        </div>
        <div className="ml-auto">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={close} size="icon" variant={"ghost"}>
                <X className="size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <span>Close</span>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      {getFormOrNull()}
      {sortedNotifications.length === 0 ? (
        !creating && (
          <div className="flex text-zinc-500 justify-center mb-2 text-[1rem] mt-5 tracking-tight uppercase">
            No notifications to display
          </div>
        )
      ) : (
        <ul>
          {sortedNotifications.map((notification) => {
            return (
              <NotificationCard2
                key={notification._id}
                notificationId={notification._id}
                onEditing={(isEditing) => {
                  if (isEditing && creating === "Pattern") {
                    setCreating(undefined);
                  }
                }}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}

export {
  BottomNotificationsArea,
  NotificationsArea,
  showNotificationsPanelAtom,
};
