import {
  useEffect,
  useState,
  type PropsWithChildren,
  createContext,
  useContext,
} from "react";
import { atom, useAtom } from "jotai";
import { z } from "zod";

const LS_KEY = "dra-theme-mode";

const themeSchema = z.enum(["light", "dark", "system"]);
type themeSchema = z.infer<typeof themeSchema>;

const DEFAULT_MODE = "system" satisfies themeSchema;

function setLs(theme: themeSchema) {
  localStorage.setItem(LS_KEY, theme);
}

function getLs(): themeSchema {
  const theme = localStorage.getItem(LS_KEY);
  const parsed = themeSchema.safeParse(theme);
  if (parsed.success) return parsed.data;
  return DEFAULT_MODE;
}

function getThemeAtom() {
  const themeAtom = atom<themeSchema>(getLs());
  return themeAtom;
}

function setClassOnBody(isDark: boolean) {
  isDark
    ? document.body.classList.add("dark")
    : document.body.classList.remove("dark");
}

function getMediaQuery() {
  const mq = window.matchMedia("(prefers-color-scheme: dark)");
  return mq;
}

function subscribeMediaQuery(
  mq: ReturnType<typeof getMediaQuery>,
  cb: (e: MediaQueryListEvent) => void
): () => void {
  mq.addEventListener("change", cb);
  return () => mq.removeEventListener("change", cb);
}

const ThemeAtomContext = createContext<
  ReturnType<typeof getThemeAtom> | undefined
>(undefined);

function ThemeProvider({ children }: PropsWithChildren) {
  const [themeAtom] = useState(getThemeAtom);
  const [theme] = useAtom(themeAtom);

  // commenting out until we enable it in .3
  // useEffect(
  //   function handleThemeChange() {
  //     setLs(theme);

  //     switch (theme) {
  //       case "system":
  //         const mq = getMediaQuery();
  //         setClassOnBody(mq.matches);

  //         return subscribeMediaQuery(mq, (e) => setClassOnBody(e.matches));
  //       case "light":
  //         setClassOnBody(false);
  //         break;
  //       case "dark":
  //         setClassOnBody(true);
  //         break;
  //       default:
  //         const _: never = theme;
  //         throw new Error("unreachable");
  //     }
  //   },
  //   [theme]
  // );

  return (
    <ThemeAtomContext.Provider value={themeAtom}>
      {children}
    </ThemeAtomContext.Provider>
  );
}

function useTheme() {
  const atom = useContext(ThemeAtomContext);

  if (!atom)
    throw new Error("useThemeAtom must be used within a ThemeProvider");

  return useAtom(atom);
}

function useResolvedTheme(): Extract<themeSchema, "light" | "dark"> {
  const [theme] = useTheme();

  const [resolvedTheme, setResolvedTheme] = useState<"light" | "dark">(() => {
    switch (theme) {
      case "system":
        return getMediaQuery().matches ? "dark" : "light";
      default:
        return theme;
    }
  });

  useEffect(() => {
    if (theme !== "system") return setResolvedTheme(theme);

    const mq = getMediaQuery();
    setResolvedTheme(mq.matches ? "dark" : "light");

    return subscribeMediaQuery(mq, (e) =>
      setResolvedTheme(e.matches ? "dark" : "light")
    );
  }, [theme]);

  return resolvedTheme;
}

export { ThemeProvider, useTheme, useResolvedTheme };
