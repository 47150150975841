import { ComponentProps, useEffect, useState } from "react";
import Select from "../../common/Select/Select";
import * as ManagerComponents from "../../manager-components";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { z } from "zod";
import { useGroupsQuery } from "../../../hooks/tanstack-query";
import { OVERALL_GROUP_NAME } from "../../../lib/api-schema/misc";
import { addToast } from "../../toast/use-toast-store";
import { createTag } from "../../../frameworks/fetcher/api-routes-experimental";
import { PropsWithCn } from "../../types/component.types";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import { Label } from "../../../shared-ui/frontend/label";
import { Button } from "../../../shared-ui/frontend/button";
import { ChevronRight } from "lucide-react";
import { cn } from "../../../shared-ui/frontend/cn";

type TagType = "Historian" | "Calculated";

const TagTypeMap: { [T in TagType]: T } = {
  Historian: "Historian",
  Calculated: "Calculated",
};

const toggleKeyToLabel = {
  highSide: "High Side Anomaly",
  lowSide: "Low Side Anomaly",
  hide_positive_slopes: "Sloping Trend: Positive Slope",
  hide_negative_slopes: "Sloping Trend: Negative Slope",
};

type Payload = {
  st_enabled: boolean;
  highSide: boolean;
  lowSide: boolean;
  hide_positive_slopes: boolean;
  hide_negative_slopes: boolean;
  unitsOfMeasurement: string | null;
  name: string;
  description: string;
  groupIds: string[];
  familyIds: string[];
  inherit_sd: boolean;

  expression?: string; // only send if calculated
  historian?: string; // only send if they selected a historian that's an alternateHistorian
};

export function BaseTagForm({
  close,
  onSubmit,
  className,
  defaultValues,
  disabled,
}: PropsWithCn<{
  close: () => void;
  onSubmit: (payload: Payload) => void;
  defaultValues?: Omit<Parameters<typeof createTag>[1], "st_enabled">;
  disabled?: boolean;
}>) {
  const isEditing = !!defaultValues;
  const groupsQuery = useGroupsQuery();
  const currentUnit = useCurrentUnitObject();
  if (!currentUnit) throw new Error("No current unit");

  const hasMultipleHistorians = currentUnit.alternateHistorians.length > 0;

  const isEditingACalculatedTag = !!defaultValues?.expression?.trim().length;

  const [type, setType] = useState<TagType>(
    isEditingACalculatedTag ? "Calculated" : "Historian"
  );

  const [toggleState, setToggleState] = useState<{
    [T in keyof typeof toggleKeyToLabel]: boolean;
  }>({
    hide_negative_slopes: defaultValues?.hide_negative_slopes ?? true,
    hide_positive_slopes: defaultValues?.hide_positive_slopes ?? true,
    highSide: defaultValues?.highSide ?? true,
    lowSide: defaultValues?.lowSide ?? true,
  });

  const [selectedHistorian, setSelectedHistorian] = useState(() => {
    if (defaultValues)
      return defaultValues.historian ?? currentUnit.historian.key;

    return currentUnit.historian.key;
  });

  const [name, setName] = useState(defaultValues?.name ?? "");
  const [description, setDescription] = useState(
    defaultValues?.description ?? ""
  );
  const [expression, setExpression] = useState(defaultValues?.expression ?? "");
  const [unit, setUnit] = useState(defaultValues?.unitsOfMeasurement ?? "");
  const [groupIds, setGroupIds] = useState<string[] | undefined>(
    defaultValues ? defaultValues.groupIds : undefined
  );
  const [clusterIds, setClusterIds] = useState(
    defaultValues ? defaultValues.familyIds : []
  );

  const [inheritSd, setInheritSd] = useState(defaultValues?.inherit_sd ?? true);

  const disableForm = groupsQuery.isLoading || !!disabled;

  useEffect(() => {
    if (groupIds) return;
    if (!groupsQuery.data) return;

    const overallGroup = groupsQuery.data.find(
      (g) => g.name === OVERALL_GROUP_NAME
    );
    if (!overallGroup) throw new Error("No overall group");

    setGroupIds([overallGroup._id]);
  }, [groupIds, groupsQuery.data]);

  const onFormSubmit: ComponentProps<"form">["onSubmit"] = (e) => {
    e.preventDefault();

    const modifiedToggleState = { ...toggleState };

    // these two must be swapped because of "hide"
    modifiedToggleState.hide_negative_slopes =
      !modifiedToggleState.hide_negative_slopes;
    modifiedToggleState.hide_positive_slopes =
      !modifiedToggleState.hide_positive_slopes;

    const booleanFields = {
      ...modifiedToggleState,
      st_enabled:
        !modifiedToggleState.hide_negative_slopes ||
        !modifiedToggleState.hide_positive_slopes,
    };

    if (!groupIds) throw new Error("group ids not initialized");

    const payload: Payload = {
      ...booleanFields,
      unitsOfMeasurement: z.string().trim().parse(unit) || null, // can be string or null
      name,
      description: z.string().trim().parse(description), // must be a string
      groupIds,
      familyIds: clusterIds,
      inherit_sd: inheritSd,
    };

    if (type === TagTypeMap.Calculated) {
      if (expression.trim().length === 0) {
        return addToast({
          title: "Expression required",
          description: "Expression must be provided for calculated tags",
          variant: "danger",
        });
      } else {
        payload.expression = expression;
      }
    } else if (hasMultipleHistorians) {
      const initialHistorian =
        defaultValues?.historian ?? currentUnit.historian.key;
      if (selectedHistorian !== initialHistorian) {
        payload.historian = selectedHistorian; // when it's not the default
      }
    }

    onSubmit(payload);
  };

  return (
    <ManagerComponents.FormContainer
      onSubmit={onFormSubmit}
      className={className}
    >
      <ManagerComponents.InputWithLabel
        value={name}
        onChange={(e) => setName(e.target.value)}
        id="id"
        label="ID"
        className="w-full"
        required
        minLength={1}
        disabled={disableForm}
      />
      <ManagerComponents.InputWithLabel
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        id="description"
        label="Description"
        className="w-full"
        disabled={disableForm}
      />

      <Label htmlFor="type">Type</Label>
      <Select
        value={type}
        onChange={(e) => {
          const newType = e.target.value as TagType;

          setInheritSd(defaultValues?.inherit_sd ?? true);
          setType(newType);
        }}
        className="w-full"
        size="md"
        bordered
        id="type"
        options={Object.values(TagTypeMap).map((v) => ({
          value: v,
          label: v + " Tag",
        }))}
        disabled={isEditing || disableForm}
      />
      {type === TagTypeMap.Calculated ? (
        <>
          <Label htmlFor="expression">Expression</Label>
          <InstantCalculator
            onInput={(expr) => {
              setExpression(expr);
            }}
            defaultValue={expression}
          />
        </>
      ) : (
        hasMultipleHistorians && (
          <>
            <Label htmlFor="historian">Historian</Label>
            <Select
              disabled={disableForm}
              value={selectedHistorian}
              onChange={(e) => {
                /**
                 * If the user selects the default unit, we want to set the selected historian to undefined
                 * because we don't want to send anything to the backend in that case.
                 */
                setSelectedHistorian(e.target.value);
              }}
              className="w-full"
              size="md"
              bordered
              id="historian"
              options={[
                {
                  value: currentUnit.historian.key,
                  label: currentUnit.historian.key,
                },
                ...currentUnit.alternateHistorians.map(({ key }) => {
                  return {
                    value: key,
                    label: key,
                  };
                }),
              ]}
            />
          </>
        )
      )}

      <ManagerComponents.InputWithLabel
        disabled={disableForm}
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
        id="unit"
        label="Unit of Measurement"
        className="w-full"
      />
      <div className="flex flex-col m-5 gap-1">
        {Object.entries(toggleKeyToLabel).map(([key, label]) => (
          <ManagerComponents.TogglerWithLabel
            label={label}
            key={key}
            disabled={disableForm}
            checked={toggleState[key as keyof typeof toggleKeyToLabel]}
            onChange={(e) =>
              setToggleState((oldState) => ({
                ...oldState,
                [key]: e.target.checked,
              }))
            }
          />
        ))}
      </div>
      <ManagerComponents.GroupMultiSelect
        disabled={disableForm}
        value={groupIds}
        onChange={setGroupIds}
      />
      <ManagerComponents.ClusterMultiSelect
        disabled={disableForm}
        value={clusterIds}
        onChange={setClusterIds}
      />

      {type === TagTypeMap.Calculated && (
        <AdvancedSettings inheritSd={inheritSd} setInheritSd={setInheritSd} />
      )}
      <ManagerComponents.CancelSaveFooter
        className="mt-4"
        close={{
          onClick: close,
          disabled: disableForm,
        }}
        submit={{
          disabled: disableForm,
        }}
      />
    </ManagerComponents.FormContainer>
  );
}

function AdvancedSettings({
  inheritSd,
  setInheritSd,
}: {
  inheritSd: boolean;
  setInheritSd: (value: boolean) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex items-center my-4">
        <Button
          onClick={() => setOpen((old) => !old)}
          className={cn("text-xslate-11", open && "rotate-90")}
          variant={"ghost"}
          size={"icon"}
          type="button"
        >
          <ChevronRight className="h-5 w-5" />
        </Button>
        <Label className="text-md">Advanced</Label>
      </div>
      {open && (
        <ManagerComponents.TogglerWithLabel
          className="animate-in slide-in-from-top-2"
          label="Inherit Shutdown Rules from Base Tags"
          checked={inheritSd}
          onChange={(e) => setInheritSd(e.target.checked)}
        />
      )}
    </>
  );
}
