import "./FaultTreeEditor.scss";
import React from "react";
import MainLoader from "../../common/MainLoader";
import Error from "../../common/PageError";
import InputOld from "../../common/InputOld";
import First from "../../common/First";
import Form from "../../common/Form";
import Fragment from "../../common/Fragment";
import _ from "lodash";
import FaultTreeError from "./FaultTreeError";
import flags from "../../../lib/flags";
import formatErrors from "../../../lib/formatErrors";
import { useModal } from "../../common/useModal";
import { Link, useLocation, useParams } from "react-router-dom";
import useAPI from "../../../lib/useAPI";
import moment from "moment";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import MainLayout from "../../layouts/MainLayout";
import Button from "../../common/Button/Button";
import { Tooltip } from "@mantine/core";
import classNames from "classnames";
import useBaseUrl, {
  useBaseUrlExperimental,
} from "../../../zustand/useBaseUrl";
import {
  getFaultTree,
  getFaultTreeChart,
  getFaultTreeNodes,
} from "../../../frameworks/fetcher/api-routes-experimental";
import {
  FaultTreeChart2,
  useRefetchFaultTreeChartQuery,
} from "../../d3ft/FaultTreeChart2";
import useMaxDate from "../../common/hooks/useMaxDate";
import { FileUploader } from "./file-uploader";
import { FilesPills } from "./files-pills";
import { POST_FILE } from "../../../frameworks/fetcher/fetcher-experimental";
import { FaultTreeNodeFiles } from "./use-ft-node-files-query";
import useHasEditPermission from "../../../zustand/useHasEditPermission";

class FaultTreeEditor extends React.Component {
  STATES = flags({
    network: [
      { network_nodes: ["node_updating", "node_creating", "node_deleting"] },

      "tree_loading",
      "tree_updating",
    ],

    errors: ["node_network_error", "tree_network_error", "success_error"],

    editing: ["form_node_creation", "form_tree_name"],
  });

  constructor(props) {
    super(props);
    const that = this;
    that.prefix = _.uniqueId("FaultTreeEditor");

    that.appState = {
      state: that.STATES.tree_loading,
      errors: [] /* Actual messages (incl. type) */,

      /* I guarantee to index at least all associated nodes for a tree, BUT
       * I may also index more than that. If it turns out to be performant,
       * I may cache deleted nodes, or pre-fetch nodes, or whatever. So
       * don't assume that you can just iterate over the nodes list on its own.
       * Take your time and traverse `tree.root` if you need a list of IDs. */
      nodes: {},
      tree: null /* Basic tree info (name, etc...) */,
    };

    this.state = {
      editState: 0 /* Interface specific: which forms are currently active */,
      errorMessage: "",

      selectedNode: null,
      newNodeName: "",

      files: [],

      //To remove
      creating: false,
      editingTreeName: false,
    };
  }

  request(state, method) {
    this.setAppState("state", flags.set(this.appState.state, state, true));

    return method
      .apply(this, arguments)
      .then(async () => {
        /* successful request */

        const error = {
          type: "success",
          msg: "Successfully saved!",
        };

        this.setAppState("errors", [error]);
      })
      .then(
        /* Yes, I do know about `promise.catch`->`promise.finally`, but
         * `promise.finally` is hard to polyfill completely, so we don't do
         * it. */
        (val) => {
          this.setAppState(
            "state",
            flags.set(this.appState.state, state, false)
          );
          return Promise.resolve(val);
        },
        (val) => {
          this.setAppState(
            "state",
            flags.set(this.appState.state, state, false)
          );
          return Promise.reject(formatErrors(val));
        }
      );
  }

  setAppState(pathString, value) {
    const that = this;

    const path = pathString.split(".");
    const key = path[path.length - 1];

    const prev = (function set() {
      let host = that.appState;
      for (let i = 0; i < path.length - 1; i++) {
        host = host[path[i]];
      }

      const prev = host[key];
      host[key] = value;

      return prev;
    })();

    /* This clear and recalculate might be a bit hacky. */
    function errorCheck(node) {
      node.errors = (function (errors) {
        errors = node.errors.filter((error) => {
          return error.type !== "warning";
        });

        let emptyWarning = {
          type: "warning",
          msg: "Both Tag and Node References are empty.",
        };

        let childWarning = {
          type: "warning",
          msg: "At least 2 child nodes are recommended.",
        };

        let emptyIndex = errors.findIndex(
          (error) => error.msg === emptyWarning.msg
        );
        let nodeExpression =
          node.edits.nodeExpression == null
            ? node.nodeExpression
            : node.edits.nodeExpression;
        let tagExpression =
          node.edits.tagExpression == null
            ? node.tagExpression
            : node.edits.tagExpression;

        if (
          nodeExpression === "" &&
          tagExpression === "" &&
          emptyIndex === -1
        ) {
          errors.push(emptyWarning);
        } else if (
          (nodeExpression !== "" || tagExpression !== "") &&
          emptyIndex !== -1
        ) {
          errors.splice(emptyIndex, 1);
        }

        return errors;
      })(node.errors);
    }

    //Run error checking for new nodes.
    if (pathString === "nodes") {
      let oldNodes = prev || {};
      Object.keys(value).forEach(function (key) {
        let node = value[key];
        if (node != null) {
          errorCheck(node);
        }
      });
    }

    /* Clear errors and refresh warnings if a node is being edited.
     * - ignore this if the node *errors* are the ones being edited. */
    if (path[0] === "nodes" && path[1] != null && path[2] !== "errors") {
      let id = path[1];
      let node = that.appState.nodes[id];

      node.errors = [];
      errorCheck(node);
    }

    if (
      (pathString === "tree.errors" || pathString === "errors") &&
      value.length
    ) {
      /* We use this to avoid having old timeouts clear new errors */
      let treeError = this.appState.tree
        ? this.appState.tree.errors[0]
        : undefined;
      let baseError = this.appState.errors[0];

      /* Auto-fade */
      setTimeout(() => {
        if (
          this.appState.errors[0] === baseError &&
          this.appState.tree.errors[0] === treeError
        ) {
          this.setAppState("errors", []);
          this.setAppState("tree.errors", []);
        }
      }, 4000);
    }

    that.setState((state) => ({})); //Force re-render
  }

  setState(stateFn, callback) {
    const that = this;

    if (typeof stateFn !== "function") {
      throw new Error("function must be used for setState");
    }

    super.setState((state, props) => {
      const base = stateFn(state, props);

      if (base.hasOwnProperty("creating") && base.creating !== state.creating) {
        Object.assign(base, {
          newNodeName: "",
        });
      }

      if (
        base.hasOwnProperty("editingTreeName") &&
        base.editingTreeName !== state.editingTreeName
      ) {
        Object.assign(base, {
          treeName: that.appState.tree.name,
        });
      }

      return base;
    }, callback);
  }

  async componentDidMount() {
    const that = this;
    const { treeId } = that.props.match;

    if (!treeId) {
      // they accessed the page without the tree Id, redirect to all trees page
      const ALL_FAULT_TREES_LINK = this.props.baseUrl + "/ft";
      window.location.href = ALL_FAULT_TREES_LINK;
    }

    that.setAppState(
      "state",
      flags.set(that.appState.state, that.STATES.tree_loading, true)
    );

    try {
      await Promise.all([
        that.loadTree(treeId),
        that.setTagsForExpressionBuilder(),
      ]);
      const root = that.appState.tree.rootNodeId;

      /* Should be impossible, but allow display when no root node */
      if (root) {
        that.selectNode(root);
      }

      that.setAppState(
        "state",
        flags.set(that.appState.state, that.STATES.tree_loading, false)
      );
    } catch (errors) {
      that.setAppState("errors", errors);
    }
  }

  async setTagsForExpressionBuilder() {
    const tags = (await this.props.API.getTags()).reduce((arr, t) => {
      if (!t.deleted) {
        arr.push({
          label: t.nameWithDescription,
          value: t.name,
        });
      }
      return arr;
    }, []);
    this.setState((state) => ({ ...state, tags }));
  }

  async loadTree(treeId) {
    this.props.refetchFtChart(treeId);

    const props = this.props;
    const treePromise = Promise.all([
      getFaultTree(props.baseUrlSlash, treeId),
      getFaultTreeChart(props.baseUrlSlash, treeId, this.props.maxDateString),
    ]).then(([tree, root]) => {
      const out = Object.assign({}, tree, {
        root: root,
        edits: { name: tree.name || "" },

        errors: [],
      });

      return out;
    });
    /* We do still need to load nodes in a separate call,
     * since faultTreeChart nodes don't have all of the values
     * attached to them */
    const nodes = await getFaultTreeNodes(props.baseUrlSlash, treeId);
    const tree = await treePromise;
    const index = nodes.reduce(
      (index, node) => {
        const indexedNode = (index[node._id] = index[node._id] || {
          _id: "",
          name: "",
          expression: "",
          tagExpression: "",
          nodeExpression: "",
          expressionConnector: "|",
          recommendation: "",

          edits: {
            name: "",
            tagExpression: "",
            nodeExpression: "",
            recommendation: "",
            expressionConnector: "|",
          },

          errors: [],
        });

        /* We assume that if edits exist, they shouldn't be overwritten by new loads.
         * In practice, spec is to discard edits when the node moves, but it's better
         * to do this correctly from the start. */
        [
          "name",
          "nodeExpression",
          "tagExpression",
          "recommendation",
          "expressionConnector",
        ].forEach(function (key) {
          if (indexedNode[key] === indexedNode.edits[key]) {
            indexedNode.edits[key] = node[key];
          }
        });

        // Copy remaining values.
        Object.assign(index[node._id], node);
        return index;
      },
      Object.assign({}, this.appState.nodes)
    );

    this.setAppState("nodes", index);
    this.setAppState("tree", tree);
  }

  async saveTree() {
    return this.request(this.STATES.tree_updating, async () => {
      const id = (this.appState.tree || {})._id;
      const name = this.appState.tree.edits.name.trim();
      const patch = { name: name };
      const tree = await this.props.API.patchFaultTree(id, patch);

      this.setAppState("tree.edits.name", tree.name);
      await this.loadTree(this.appState.tree._id);
    }).catch((errors) => {
      this.setAppState("tree.errors", errors);
      throw errors;
    });
  }

  async saveNode() {
    const id = this.state.selectedNode;
    const node = this.appState.nodes[id];

    return this.request(this.STATES.node_updating, async () => {
      const files = this.state.files;

      let uploadMaybe;
      if (this.state.files.length > 0) {
        const formData = new FormData();
        for (const f of files) {
          formData.append("file", f);
        }

        uploadMaybe = POST_FILE(
          `${this.props.baseUrlSlash}/fault-tree-nodes/${this.state.selectedNode}/files`,
          formData
        );
      }

      const response = await this.props.API.patchFaultTreeNode(id, {
        name: node.edits.name,
        tagExpression: node.edits.tagExpression,
        nodeExpression: node.edits.nodeExpression,
        expressionConnector: node.edits.expressionConnector,
        recommendation: node.edits.recommendation,
      });

      uploadMaybe &&
        (await uploadMaybe.then(() => this.props.refetchFilesQuery()));

      /* On successful save, update the node with the new properties.
       * We're assuming none of the 'edit' properties will need to be set. */
      Object.assign(node, response);

      this.setAppState(`nodes.${id}`, node);
      this.loadTree(this.appState.tree._id);
      this.setState(() => ({ files: [] }));

      return node;
    }).catch((errors) => {
      this.setAppState(`nodes.${id}.errors`, errors);
      throw errors;
    });
  }

  async createNode() {
    const that = this;

    const treeId = that.appState.tree._id;
    const parentId = that.state.selectedNode;
    const name = that.state.newNodeName;

    this.request(that.STATES.node_creating, async () => {
      await that.props.API.postFaultTreeNode({
        name: name,
        parentId: parentId,
        faultTreeId: treeId,
      });

      that.setState(() => ({ newNodeName: "" }));
      that.loadTree(this.appState.tree._id);
    }).catch((errors) => {
      that.setAppState("errors", errors);

      /* For now, add node creation errors to the main queue. It's possible
       * we'll want to move them later. */
      throw errors;
    });
  }

  async deleteNode() {
    const that = this;
    const id = that.state.selectedNode;
    const parentId = that.appState.nodes[id].parentId;

    /*@TODO Build a better modal */
    var confirmation = window.confirm(
      `Are you sure you want to delete this node (${that.appState.nodes[id].name})?`
    );
    if (!confirmation) {
      return null;
    }

    that
      .request(that.STATES.node_deleting, async () => {
        await that.props.API.deleteFaultTreeNode(id);
        const nodes = Object.assign({}, that.appState.nodes);
        nodes[id] = null;

        that.setAppState("nodes", nodes);
        that.loadTree(this.appState.tree._id);

        return that.selectNode(parentId);
      })
      .catch((errors) => {
        that.setAppState(`nodes.${id}.errors`, errors);
        throw errors;
      });
  }

  async togglePublished() {
    const that = this;
    const id = (this.appState.tree || {})._id;
    const patch = { published: !this.appState.tree.published };

    that
      .request(that.STATES.tree_updating, async () => {
        const tree = await that.props.API.patchFaultTree(id, patch);
        this.setAppState("tree.published", tree.published);
      })
      .catch((errors) => {
        console.log("error patching fault tree", errors);
        const nodes = that.appState.nodes;

        errors.forEach((err) => {
          if (!err.meta.nodeId) {
            return;
          }

          const errorsForNode = nodes[err.meta.nodeId].errors;
          errorsForNode.push(err);
          this.setAppState(`nodes.${err.meta.nodeId}.errors`, errorsForNode);
        });

        this.setAppState("tree.errors", [
          {
            type: "error",
            msg: "Publishing error. Look at tree for issue nodes.",
          },
        ]);

        throw errors;
      });
  }

  async selectNode(nodeId) {
    this.setState(() => ({ selectedNode: nodeId }));
    return Promise.resolve();
  }

  setFiles(files) {
    this.setState(() => ({ files }));
  }

  render() {
    const that = this;
    document.title = "Fault Trees > Advanced Settings > DRA";

    /* Used for save-in-progress animations */
    const state = that.appState.state;

    const tree = that.appState.tree;
    const selectedNode = that.state.selectedNode;
    const node = that.appState.nodes[selectedNode];

    const nodeName = node ? node.edits.name : "";
    const nodeExpression = node ? node.edits.nodeExpression : "";
    const tagExpression = node ? node.edits.tagExpression : "";
    const expressionConnector = node ? node.edits.expressionConnector : "|";
    const recommendation = node ? node.edits.recommendation : "";

    const treeName = tree ? tree.edits.name : "";
    const nodeCount = tree ? tree.nodeCount : 0;

    const treeEditRef = React.createRef();
    const nodeCreateRef = React.createRef();

    const error = (function () {
      let errors = that.appState.errors || [];
      if (tree) {
        errors = errors.concat(tree.errors);
      }
      if (node) {
        errors = errors.concat(node.errors);
      }
      return errors[0];
    })();

    const expression = (function () {
      if (!tagExpression) {
        return nodeExpression;
      }
      if (!nodeExpression) {
        return tagExpression;
      }
      return `(${tagExpression}) ${
        expressionConnector || "|"
      } (${nodeExpression})`;
    })();

    // const highlightedExpressionComponent = (() => {
    //   const regg1 = /(["'])(?:\\.|[^\\])*?\1/
    //   const regg2 = /(["'])(?:\\.|[^\\])*?\1/g

    //   const arr = expression.split(regg1)

    //   const tags = [...expression.matchAll(regg2)]

    //   const out = []
    //   arr.forEach((str) => {
    //     if (str === '"') {
    //       out.push(<span style={{ color: "red" }}>{tags.shift()[0]}</span>)
    //     } else {
    //       out.push(<span>{str}</span>)
    //     }
    //   })
    //   return <div className="mt-12">{out}</div>
    // })()

    /* Used for wiring inputs back to state */
    function setter(binding) {
      return (value) => that.setAppState(binding, value);
    }

    /* - Nothing can be edited while a save/load is happening.
     * - Nothing can be edited if the tree is published. */
    function disabled() {
      return (
        selectedNode == null ||
        flags.set(state, that.STATES.network) ||
        tree.published
      );
    }

    const hasEditPermission = this.props.user.hasEditPermission;
    return (
      <MainLayout showDateNav={false}>
        <div className="MIN_H_SCREEN flex flex-col">
          <div className="grid grid-cols-3 py-2 items-center">
            <div className="col-span-1">
              <Link to={"../../control/ft"}>
                <Button
                  icon="chevron-left"
                  iconClasses="mr-1"
                  className="btn-ghost"
                >
                  All Fault Trees
                </Button>
              </Link>
            </div>
            <div className="col-span-1 flex justify-center">
              <First>
                {/* Tree edit form toggle*/}
                <Fragment match={!that.state.editingTreeName}>
                  <span>
                    {hasEditPermission && (
                      <Tooltip label="Edit Name" withArrow position="bottom">
                        <Button
                          className="btn-ghost"
                          id={`${that.prefix}-TreeEditButton`}
                          icon="pencil"
                          onClick={() =>
                            that.setState(() => ({
                              editingTreeName: true,
                            }))
                          }
                        />
                      </Tooltip>
                    )}

                    <span>{treeName}</span>
                    {/* <span> ({nodeCount} nodes)</span> */}
                  </span>
                </Fragment>
                <Form
                  classes={{ Form: "FaultTreeEditor__treeEditForm" }}
                  ref={treeEditRef}
                  selectOnMount={`${that.prefix}-TreeEditName`}
                  selectOnExit={`${that.prefix}-TreeEditButton`}
                  exitOnFocusLost
                  onSubmit={() =>
                    that
                      .saveTree()
                      .then(() =>
                        that.setState(() => ({ editingTreeName: false }))
                      )
                  }
                  onExit={() => {
                    that.setAppState(
                      "tree.edits.name",
                      that.appState.tree.name
                    );

                    return new Promise((resolve) => {
                      that.setState(
                        (state) => ({ editingTreeName: false }),
                        resolve()
                      );
                    });
                  }}
                >
                  <InputOld
                    size="input-sm"
                    id={`${that.prefix}-TreeEditName`}
                    value={treeName}
                    action={setter(`tree.edits.name`)}
                    classes={{ Input: "mr-2" }}
                  />

                  <Button
                    loading={!!flags.set(state, that.STATES.tree_updating)}
                    onClick={() => treeEditRef.current.submit()}
                    disabled={!that.state.treeName}
                    size="xs"
                    className="btn-primary mr-1"
                  >
                    Save
                  </Button>
                  <Button
                    size="xs"
                    className="btn-outline btn-error"
                    onClick={() => treeEditRef.current.exit()}
                  >
                    Cancel
                  </Button>
                </Form>
              </First>
            </div>
            <div className="flex justify-end pr-2">
              {tree && (
                <Tooltip
                  label={
                    hasEditPermission
                      ? `Click to ${tree.published ? "unpublish" : "publish"}`
                      : undefined
                  }
                  withArrow
                  position="left"
                >
                  <Button
                    size="xs"
                    className={classNames({
                      "btn-outline btn-error": !tree.published,
                      "btn-success": tree.published,
                    })}
                    disabled={
                      flags.set(state, that.STATES.tree_updating) ||
                      flags.set(state, that.STATES.tree_loading)
                    }
                    onClick={() => {
                      if (tree.published) {
                        hasEditPermission && that.togglePublished();
                      } else {
                        hasEditPermission &&
                          that.props.MODAL.openModal({
                            title: `Are you sure you want to publish ${tree.name}? Processing will take some time.`,
                            buttons: [
                              {
                                type: "button",
                                label: "PUBLISH",
                                className: "btn-primary",
                                onClick: () => {
                                  that.togglePublished();
                                  that.props.MODAL.closeModal();
                                },
                              },
                            ],
                            closeLabel: "CANCEL",
                          });
                        // modal is used as confirmation for publishing
                      }
                    }}
                  >
                    {tree.published ? "Published" : "Unpublished"}
                  </Button>
                </Tooltip>
              )}
            </div>

            {/* <FaultTreeBadge
            published={tree && tree.published}
            loading={
              flags.set(state, that.STATES.tree_updating) ||
              flags.set(state, that.STATES.tree_loading)
            }
            action={() => that.togglePublished()}
          /> */}
          </div>

          <div className="px-4 mb-6 flex flex-grow">
            <First>
              {/* Tree is loading, only show loading indicator */}
              <MainLoader match={flags.set(state, that.STATES.tree_loading)} />
              <Error
                match={that.appState.tree && !that.appState.tree.root}
                message="No Root Node: this tree was likely meant to be deleted."
              />
              <Fragment>
                {/* Left hand panel, node selector */}
                <div className="FaultTreeEditor__panel FaultTreeEditor__panel--half w-3/4 rounded-xl flex items-center justify-center">
                  {that.appState.tree !== null && (
                    <FaultTreeChart2
                      _key={JSON.stringify(that.appState.nodes)}
                      height={window.innerHeight}
                      width={window.innerWidth * 0.7}
                      treeId={that.appState.tree._id}
                      selectedDate={this.props.maxDateString}
                      handleTreeNodeChange={(id) => that.selectNode(id)}
                      highlightNode={that.state.selectedNode}
                      // zoomEnabled={that.props.zoomEnabled}
                      zoomEnabled={false}
                    />
                  )}
                  {/* <Button
                    icon={!that.props.zoomEnabled ? "lock" : "lock-open"}
                    iconClasses="mr-2"
                    className="btn-ghost absolute bottom-10 left-10 z-50 normal-case"
                    onClick={() => {
                      that.props.setZoomEnabled((curr) => !curr);
                    }}
                  >
                    Zoom
                  </Button> */}
                </div>

                {/* Right hand panel, node editor */}
                <div className="FaultTreeEditor__panel FaultTreeEditor__panel--half w-1/4 rounded-xl">
                  <Form
                    classes={{ Form: "FaultTreeEditor__nodeEditor" }}
                    onSubmit={() => {
                      that.saveNode();
                    }}
                  >
                    <InputOld
                      label="Node Name"
                      classes={{
                        Input: "FaultTreeEditor__nodeInput",
                      }}
                      action={setter(`nodes.${selectedNode}.edits.name`)}
                      value={nodeName}
                      disabled={disabled}
                    />

                    {/* Node Expression group */}
                    <InputOld label="Node Expression" type="group">
                      <InputOld
                        label="Tag References"
                        type="textarea"
                        classes={{
                          Input:
                            "FaultTreeEditor__nodeInput FaultTreeEditor__nodeInput--narrow",
                        }}
                        action={setter(
                          `nodes.${selectedNode}.edits.tagExpression`
                        )}
                        value={tagExpression}
                        disabled={disabled}
                      />
                      {/* <ExpressionBuildingInput
                      defaultValue={tagExpression ? tagExpression : ""}
                      label="Tag References"
                      action={setter(
                        `nodes.${selectedNode}.edits.tagExpression`
                      )}
                      disabled={disabled}
                      options={this.state.tags}
                      textarea={true}
                    /> */}

                      <InputOld
                        label="Node References"
                        type="textarea"
                        classes={{
                          Input:
                            "FaultTreeEditor__nodeInput FaultTreeEditor__nodeInput--narrow",
                        }}
                        action={setter(
                          `nodes.${selectedNode}.edits.nodeExpression`
                        )}
                        value={nodeExpression}
                        disabled={disabled}
                      />

                      <div className="FaultTreeEditor__nodeDetail Input__label Input__label--header">
                        Full Expression (Tag References
                        {/* Preceeding and trailing spaces matter here. The text has been
                         * split between lines specifically to make it harder to accidentally
                         * delete. In other words, yes it looks ugly, but don't reformat it. */}{" "}
                        <InputOld
                          type="select"
                          bordered={true}
                          classes={{ Input: "FaultTreeEditor__select" }}
                          action={setter(
                            `nodes.${selectedNode}.edits.expressionConnector`
                          )}
                          value={expressionConnector}
                          disabled={disabled}
                          options={[
                            { value: "|", label: "|(OR)" },
                            { value: "&", label: "&(AND)" },
                          ]}
                        />{" "}
                        Node References)
                      </div>
                      <div className="border border-blue-800 italic mono faultTree-nodeDetails-info-expression">
                        {expression}
                      </div>
                      {/* <div>{highlightedExpressionComponent}</div> */}
                    </InputOld>

                    <InputOld
                      print={true}
                      label="Recommendation"
                      type="textarea"
                      classes={{ Input: "FaultTreeEditor__nodeInput" }}
                      action={setter(
                        `nodes.${selectedNode}.edits.recommendation`
                      )}
                      value={recommendation}
                      disabled={disabled}
                    />

                    {this.state.selectedNode && (
                      <FilesPillsSection
                        nodeId={this.state.selectedNode}
                        isPublished={tree.published}
                      />
                    )}
                    <FileUploader
                      disabled={disabled()}
                      nodeId={this.state.selectedNode}
                      files={this.state.files}
                      setFiles={(files) => this.setFiles(files)}
                    />
                  </Form>

                  <div className="FaultTreeEditor__nodeControls">
                    <Button
                      loading={!!flags.set(state, that.STATES.node_deleting)}
                      className="btn-outline btn-error"
                      disabled={
                        disabled() ||
                        !node ||
                        !node.parentId ||
                        !hasEditPermission
                      }
                      onClick={() => that.deleteNode()}
                    >
                      Delete Node
                    </Button>

                    <First>
                      {/* Node creation form */}
                      <Fragment match={!that.state.creating}>
                        <Button
                          id={`${that.prefix}-NodeCreateButton`}
                          loading={
                            !!flags.set(state, that.STATES.node_creating)
                          }
                          className="btn-primary btn-outline"
                          disabled={disabled() || !hasEditPermission}
                          onClick={() =>
                            that.setState(() => ({ creating: true }))
                          }
                        >
                          Add Child Node
                        </Button>
                      </Fragment>
                      <Form
                        className="FaultTreeEditor__nodeCreateForm"
                        ref={nodeCreateRef}
                        disabled={disabled()}
                        selectOnExit={`${that.prefix}-NodeCreateButton`}
                        selectOnMount={`${that.prefix}-NodeCreateName`}
                        exitOnFocusLost
                        onSubmit={() =>
                          that
                            .createNode()
                            .then(() =>
                              that.setState(() => ({ creating: false }))
                            )
                        }
                        onExit={() =>
                          new Promise((resolve) => {
                            that.setState(
                              () => ({ creating: false }),
                              () => resolve()
                            );
                          })
                        }
                      >
                        <div className="form-control">
                          <div className="input-group">
                            <InputOld
                              hint="Type child node name"
                              id={`${that.prefix}-NodeCreateName`}
                              action={(value) =>
                                that.setState((state) => ({
                                  newNodeName: value,
                                }))
                              }
                              value={that.state.newNodeName}
                              classes={{ Input: "input-sm" }}
                            />

                            <Button
                              className="btn-primary"
                              onClick={() => nodeCreateRef.current.submit()}
                              disabled={disabled()}
                            >
                              Create
                            </Button>
                            {/* <button className="btn btn-outline btn-sm">
                              Cancel
                            </button> */}
                            <Button
                              className="btn-outline"
                              onClick={() => nodeCreateRef.current.exit()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </First>

                    <Button
                      className="btn-primary"
                      loading={!!flags.set(state, that.STATES.node_updating)}
                      disabled={disabled() || !nodeName || !hasEditPermission}
                      onClick={() => that.saveNode()}
                    >
                      Save Node
                    </Button>
                  </div>

                  <div className="px-12">
                    {error && (
                      <FaultTreeError type={error.type}>
                        {error.msg}
                      </FaultTreeError>
                    )}
                  </div>
                </div>
              </Fragment>
            </First>
          </div>
        </div>
      </MainLayout>
    );
  }
}

function FilesPillsSection({ nodeId, isPublished }) {
  const filesQuery = FaultTreeNodeFiles.useQuery(nodeId);
  const canDelete = useHasEditPermission();

  if (filesQuery.isError) throw filesQuery.error;

  const files = filesQuery.data;

  if (!files || files.length === 0) return null;

  return (
    <>
      <label className="Input__label Input__label--header">Files</label>
      <FilesPills
        allowDelete={!isPublished && canDelete}
        files={files}
        className=" border border-zinc-400 rounded-lg p-3 mb-4"
      />
    </>
  );
}

function PageWithHooks(props) {
  const baseUrl = useBaseUrl();
  const user = useAuthStore((s) => s.user);
  const MODAL = useModal();
  const API = useAPI();
  const Location = useLocation();
  const match = useParams();
  const baseUrlSlash = useBaseUrlExperimental();
  const maxDate = useMaxDate(); // since we dont' use dates on this page, we'll just use this as the default for components that need it
  const maxDateString = moment(maxDate).format("YYYY-MM-DD");
  const refetchFilesQuery = FaultTreeNodeFiles.useInvalidateQueryForUnit();
  const refetchFtChart = useRefetchFaultTreeChartQuery();
  const [zoomEnabled, setZoomEnabled] = React.useState(false);

  const refetchFtChartWrapped = (treeId) =>
    refetchFtChart(treeId, maxDateString);

  return (
    <FaultTreeEditor
      zoomEnabled={zoomEnabled}
      setZoomEnabled={setZoomEnabled}
      maxDateString={maxDateString}
      match={match}
      baseUrlSlash={baseUrlSlash}
      baseUrl={baseUrl}
      user={user}
      refetchFilesQuery={refetchFilesQuery}
      {...props}
      MODAL={MODAL}
      Location={Location}
      API={API}
      refetchFtChart={refetchFtChartWrapped}
    />
  );
}

export default PageWithHooks;
