import React, { useState, useCallback } from "react";

// a simple boolean hook, useful for things like a toggle or checkbox
// helps with not repeating yourself and increases readability in components
export default function useBoolean(defaultValue: boolean) {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x) => !x), []);

  return [value, setTrue, setFalse, toggle] as const;
}
