/**
 * This component is a little weird because we get the colors from the API
 */
import * as React from "react";
import { cn } from "../../lib/utils";
import { badgeVariants } from "../ui/badge";

export default function LabelsBadge({
  children,
  backgroundColor,
}: React.PropsWithChildren<{
  backgroundColor: string;
}>) {
  return (
    <li
      style={
        backgroundColor
          ? { backgroundColor, color: "white", borderColor: backgroundColor }
          : {}
      }
      className={cn(badgeVariants({ variant: "outline" }))}
    >
      {children}
    </li>
  );
}
