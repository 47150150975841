import { ComponentProps, useEffect } from "react";
import { useNotificationPostMutation } from "../tanstack/useNotificationsQuery";
import { NotificationScheduleType } from "../../../types/api/Notification";
import { useGetUseDateSelectorsStore } from "../../charts/DTC/useDateSelectorStore";
import PatternNotificationForm, {
  PatternNotificationForm2,
} from "./PatternNotificationForm";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";

export default function PatternNotificationCreateForm({
  variableId,
  close,
  className,
}: Pick<
  ComponentProps<typeof PatternNotificationForm>,
  "variableId" | "close" | "className"
>) {
  const mutation = useNotificationPostMutation();
  const useDateSelectorsStore = useGetUseDateSelectorsStore();
  const dateSelectors = useDateSelectorsStore();

  useEffect(() => {
    dateSelectors.setDates(null);
    dateSelectors.setEditing(true);
    return () => {
      dateSelectors.setDates(null);
      dateSelectors.setEditing(false);
    };
  }, []);

  return (
    <PatternNotificationForm
      className={className}
      variableId={variableId}
      mutation={mutation}
      defaultData={{
        schedule: NotificationScheduleType.ONCE,
        criteria: "IDENTICAL_MATCH",
        parameters: {
          start: new Date().toISOString(),
          end: new Date().toISOString(),
        },
      }}
      close={close}
    />
  );
}
export function PatternNotificationCreateForm2({
  variableId,
  close,
  className,
}: Pick<
  ComponentProps<typeof PatternNotificationForm2>,
  "variableId" | "close" | "className"
>) {
  const mutation = useNotificationPostMutation();
  const brushState = useAtomValue(Atoms.brushStoreAtom);
  if (!brushState || brushState.for !== "pattern-notif-create") return;

  return (
    <PatternNotificationForm2
      className={className}
      variableId={variableId}
      mutation={mutation}
      defaultData={{
        schedule: NotificationScheduleType.ONCE,
        criteria: "IDENTICAL_MATCH",
        parameters: {
          start: new Date().toISOString(),
          end: new Date().toISOString(),
        },
      }}
      close={close}
    />
  );
}
