import React, { useState, useEffect } from "react";
import _ from "lodash";
import useCurrentUnitObject from "./hooks/useCurrentUnitObject";
import useAPI from "../../lib/useAPI";
import classNames from "classnames";
import { Menu, Tooltip } from "@mantine/core";
import Button from "./Button/Button";

function BellNotificationButton({
  variableId,
  operatingLimitId,
  groupId,
  notificationTypes,
  position,
}) {
  const API = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState();
  const [availableNotifications, setAvailableNotifications] = useState([]);

  const currentUnit = useCurrentUnitObject();

  // init page
  useEffect(() => {
    async function init() {
      const { notifications, availableNotifications } =
        await API.getNotifications(
          notificationTypes,
          variableId,
          operatingLimitId,
          groupId
        );

      setIsLoading(false);
      setNotifications(notifications);
      setAvailableNotifications(availableNotifications);
    }

    init();
  }, []);

  const unitHasRealtime = currentUnit.addons.realTime;
  if (!unitHasRealtime || isLoading) {
    return null;
  }

  // const active = notifications && notifications.length;
  // const direction = menuDirection || "left";

  // const notificationCount = active ? notifications.length : 0;
  // const notificationList = buildNotificationList();
  // const headerText = notificationList.length <= 2 ? getHeaderText() : `${getHeaderText()} (${notificationCount})`;

  async function subscribe(value, operatingLimitId, notificationType) {
    /* Show loading icon. */

    API.subscribeNotification(
      notificationType,
      value,
      variableId,
      operatingLimitId,
      groupId
    ).then((newNotification) => {
      setNotifications((curr) => [...curr, newNotification]);
    });
  }

  async function unsubscribe(value, operatingLimitId) {
    if (window.confirm("Do you want to disable this notification?")) {
      const notification = notifications.find(
        (notification) =>
          notification.criteria === value &&
          notification.operatingLimitId === operatingLimitId
      );
      await API.toggleNotification(notification._id);
      const notificationState = _.filter(
        notifications,
        (notification) =>
          !(
            notification.criteria === value &&
            notification.operatingLimitId === operatingLimitId
          )
      );

      setNotifications(notificationState);
    }
  }
  function getKey(value, operatingLimitId, type) {
    const id = variableId || operatingLimitId || groupId;
    return `${type}-${value}-${id}-${operatingLimitId}`;
  }

  async function notifOnClick(value, operatingLimitId, notificationType) {
    if (isNotificationActive(value, operatingLimitId)) {
      return unsubscribe(value, operatingLimitId);
    } else {
      return subscribe(value, operatingLimitId, notificationType);
    }
  }

  function isNotificationActive(value, operatingLimitId) {
    return (
      notifications &&
      notifications.some(
        (notification) =>
          notification.criteria === value &&
          (!notification.operatingLimitId ||
            (notification.operatingLimitId &&
              notification.operatingLimitId === operatingLimitId))
      )
    );
  }

  // rendering helpers below
  const groups = _.uniq(
    _.reduce(
      availableNotifications,
      (result, notification) => {
        if (notification.group) {
          result.push(notification.group);
        }
        return result;
      },
      []
    )
  );

  const renderData = groups.length
    ? _.reduce(
        groups,
        (arr, g) => {
          const itemsInGroup = availableNotifications.filter(
            (availNot) => availNot.group === g
          );
          return [...arr, ...itemsInGroup];
        },
        []
      )
    : availableNotifications;

  // console.log("render data", renderData);
  // console.log("available", availableNotifications);

  const buttonIsActive = renderData.some((notification) =>
    isNotificationActive(notification.value, notification.operatingLimitId)
  );

  // return (
  //   <div className="flex flex-col">
  //     {renderData.map((n) => (
  //       <div key={n.label}>{n.label}</div>
  //     ))}
  //   </div>
  // );

  return (
    <Menu
      shadow="md"
      position={position ?? "top-end"}
      width={400}
      classNames={{
        dropdown: "border-2 border-bordgrey2 shadow-xl rounded-lg",
      }}
    >
      <Menu.Target>
        <Tooltip label="Notify Me" withArrow>
          <Button
            icon="bell"
            tabIndex={0}
            className={buttonIsActive ? "btn-primary" : "btn-ghost"}
          />
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        {renderData.map((notification) => {
          const isActive = isNotificationActive(
            notification.value,
            notification.operatingLimitId
          );

          return (
            <Menu.Item
              onClick={() =>
                notifOnClick(
                  notification.value,
                  notification.operatingLimitId,
                  notification.type
                )
              }
              key={getKey(
                notification.value,
                notification.operatingLimitId,
                notification.type
              )}
              icon={
                <i
                  className={classNames("fa fa-check", {
                    "text-indigo-800": isActive,
                    "text-transparent": !isActive,
                  })}
                />
              }
            >
              {notification.label}
            </Menu.Item>
          );
        })}
        {/* <Menu.Divider /> */}
      </Menu.Dropdown>
    </Menu>
  );
}

export default BellNotificationButton;
