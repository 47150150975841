import React, { useState } from "react";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import * as R from "remeda";
import { TeamCardProps } from "./types";
import { EditTeamForm } from "./edit-team-form";
import { Badge } from "../../ui/badge";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

/* TODO: we're planning to bring this in to be visible to ordinary users, so we need to make sure that permissions actually do work. */
function TeamCard({
  team,
  users,
  deleteTeam,
  saveTeam,
  creating,
  doLogs,
}: TeamCardProps) {
  const [editMode, setEditMode] =
    useState(creating); /* Start in edit mode if we're creating */

  const userMap = R.mapToObj(users, (user) => [user._id, user]);

  /* TODO We're moving this page to be accessible to users, so this does
   * need to get wired up. */
  const hasEditAccess = useHasEditPermission();
  /* TODO: do we do loading indicators on requests still? */
  return (
    <div className="mt-6 border bordepr-solid border-[#dbdbdb] bg-white rounded-lg">
      {/* name and description  */}
      {!creating && (
        <div className="border-b border-solid border-bordgrey text-[#000000] flex sm:items-center justify-between text-[1.05em] sm:py-[0.15em] py-[0.35em] px-[0.5em] rounded-t-xl">
          <div className="py-[0.4em] flex flex-col sm:flex-row sm:items-center ">
            <span className="font-semibold px-0 sm:my-[0.2em] mx-0">
              {team?.name}
            </span>

            <Badge variant={"amber"} className="ml-3">
              {team?.users?.length || 0} member
              {team?.users?.length === 1 ? "" : "s"}
            </Badge>
          </div>

          {/* Controls */}
          <div className="mt-1 sm:mt-0 flex flex-col sm:flex-row">
            {team && hasEditAccess && (
              <Tooltip label="Delete" withArrow>
                <Button
                  className="btn-ghost"
                  icon="trash-o"
                  onClick={() => {
                    if (
                      !window.confirm(
                        "Are you sure you want to delete this team?"
                      )
                    ) {
                      return;
                    }
                    deleteTeam(team._id)
                      .then(() => addSuccessToast("Successfully deleted"))
                      .catch((e) => addUnknownErrorToast(e));
                  }}
                />
              </Tooltip>
            )}
            {!editMode && hasEditAccess && (
              <Tooltip label="edit" withArrow>
                <Button
                  className="btn-ghost"
                  disabled={false}
                  icon="pencil"
                  onClick={() => setEditMode(true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      )}

      {!editMode ? (
        <>
          <div className="px-2">
            {!!team?.users?.length && (
              <PillRow
                label="Members"
                items={team?.users?.reduce((result: string[], user) => {
                  const userObj = userMap[user];
                  userObj && result.push(`${userObj.first} ${userObj.last}`);
                  return result;
                }, [])}
              />
            )}
          </div>
          <UpdatedAt timestamp={team?.updated_at} />
        </>
      ) : (
        <EditTeamForm
          users={users}
          usersMap={userMap}
          team={team}
          saveTeam={saveTeam}
          closeForm={() => setEditMode(false)}
        />
      )}
    </div>
  );
}

export default TeamCard;
