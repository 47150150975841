import { type Paragraph as ParagraphType } from "../builder/types/BuilderTypes";
import {
  boldAndItalicizeHTMLify,
  generateStyleObjectFromMarginOptions,
} from "../utils";

export default function Paragraph(props: ParagraphType) {
  /**
   * replace things wrapped in ** with <strong> to make them bold
   *
   * replace things wrapped in $$ with <em> to italicize
   *
   * replace things wrapped in %% with <code className="code-expression"> to render as a math expression
   *
   * They can be nested
   *
   * Ex. **Hi, its $$me$$**
   *        the whole thing is bolded, but "me" is also italicized
   *
   * Ex. Hi, its $$me$$
   *        only me is italicized
   *
   * Ex. **Hi, its** $$me$$
   *        First two words are bolded, last word is italicized
   */

  const formatted = boldAndItalicizeHTMLify(props.content);

  return (
    <div
      style={generateStyleObjectFromMarginOptions(
        props.marginTop,
        props.marginBottom
      )}
    >
      {formatted === props.content ? (
        <span>{props.content}</span>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: formatted }} />
      )}
    </div>
  );
}
