import { cn } from "../../../lib/utils";
import { Popover, PopoverTrigger } from "../../ui/popover";
import { ComponentProps, PropsWithChildren, ReactNode, useState } from "react";
import * as CalendarComponents from "../../common/calendar";
import {
  addDays,
  endOfDay,
  format,
  isAfter,
  isSameMinute,
  startOfDay,
  subDays,
} from "date-fns";
import "./antd-styles.scss";
import { CustomRangeButtonPopoverContent } from "./popover-content";
import { TimePicker } from "../time-picker";
import EditableDateTimeRange from "../editable/EditableDateTimeRange";
import { Button } from "../../ui/button";
import { DateTime } from "luxon";
import { LONG } from "../../../shared-ui/lib/luxon-format-tokens";

type CustomRangeButtonContentProps = ComponentProps<
  typeof CustomRangeButtonContent
>;

type PropsWeAreProvidingInternally = keyof Pick<
  CustomRangeButtonContentProps,
  "setStartSelected" | "isStartSelected"
>;

type AvailableForUseProps = Omit<
  CustomRangeButtonContentProps,
  PropsWeAreProvidingInternally
>;

export function CustomRangePopover({
  children,
  ...props
}: PropsWithChildren<AvailableForUseProps>) {
  const [startCalendarSelected, setStartSelected] = useState(true);
  const [editMode, setEditMode] = useState(false);

  return (
    <Popover
      onOpenChange={(isOpen) => {
        setEditMode(isOpen);
        if (!isOpen) {
          setStartSelected(true);
        }
      }}
    >
      <PopoverTrigger>
        {editMode ? (
          <EditableDateTimeRange
            start={props.value.start.getTime()}
            end={props.value.end.getTime()}
            onChangeHandler={(newRange) => {
              const newStart = newRange.start
                ? new Date(newRange.start)
                : props.value.start;
              const newEnd = newRange.end
                ? new Date(newRange.end)
                : props.value.end;
              props.onChange({ start: newStart, end: newEnd });
            }}
            options={{ showTime: true, fontSize: "base" }}
          />
        ) : (
          children
        )}
      </PopoverTrigger>
      <CustomRangeButtonContent
        {...props}
        isStartSelected={startCalendarSelected}
        setStartSelected={setStartSelected}
      />
    </Popover>
  );
}

type CustomRangeButtonPopoverContentProps = ComponentProps<
  typeof CustomRangeButtonPopoverContent
>;

type Expect<T extends true> = T;

export function CustomRangeButtonContent({
  value: { end, start },
  onChange,
  isStartSelected,
  setStartSelected,
  side,
  calendarPosition,
  className,
  sideOffset,
  onInteractOutside,
  withoutPortal,
  ...rest
}: {
  value: {
    start: Date;
    end: Date;
  };
  onChange: (range: { start: Date; end: Date }) => void;
  isStartSelected: boolean;
  setStartSelected: (isStart: boolean) => void;
} & Omit<
  CustomRangeButtonPopoverContentProps,
  keyof Pick<
    CustomRangeButtonPopoverContentProps,
    "children" | "endButton" | "startButton"
  >
> & {
    calendarPosition: "top" | "bottom";
  }) {
  // ensures we are not forgetting to pass along props
  type _CheckingWork = Expect<keyof typeof rest extends never ? true : false>;

  const [showTimepicker, setShowTimepicker] = useState(false);

  const timeTuple = [start, end] as const;

  const getLabel = (d: Date, time: Date) => {
    const copy = new Date(d);
    copy.setHours(time.getHours(), time.getMinutes());

    return format(copy, "PPP h:mm a");
  };

  const getTimePicker2 = () => {
    return (
      <TimePicker
        withoutPortal
        open={showTimepicker}
        onOpenChange={setShowTimepicker}
        value={timeTuple[+!isStartSelected as 0 | 1]}
        onSubmit={
          isStartSelected
            ? (v) => {
                if (!v) throw new Error("unhandled");

                // restore the date since this is a time picker
                const newStart = new Date(start);
                const onlyTimeIsImportantInThis = new Date(v);
                // restore
                newStart.setHours(
                  onlyTimeIsImportantInThis.getHours(),
                  onlyTimeIsImportantInThis.getMinutes(),
                  0,
                  0
                );

                const getEnd = () => {
                  if (isAfter(newStart, end) || isSameMinute(newStart, end)) {
                    const endOfNextDay = endOfDay(addDays(newStart, 1));
                    endOfNextDay.setSeconds(0);
                    endOfNextDay.setMilliseconds(0);
                    return endOfNextDay;
                  }
                  return end;
                };

                onChange({
                  start: newStart, // set the start to what the user selected
                  end: getEnd(),
                });
              }
            : (v) => {
                if (!v) throw new Error("unhandled");

                // restore the date since this is a time picker
                const newEnd = new Date(end);
                const onlyTimeIsImportantInThis = new Date(v);
                // restore
                newEnd.setHours(
                  onlyTimeIsImportantInThis.getHours(),
                  onlyTimeIsImportantInThis.getMinutes(),
                  0,
                  0
                );

                const getStart = () => {
                  if (isAfter(start, newEnd) || isSameMinute(start, newEnd)) {
                    const startOfPreviousDay = startOfDay(subDays(newEnd, 1));
                    startOfPreviousDay.setSeconds(0);
                    startOfPreviousDay.setMilliseconds(0);
                    return startOfPreviousDay;
                  }
                  return start;
                };

                onChange({
                  start: getStart(),
                  end: newEnd, // set the end to what the user selected
                });
              }
        }
      />
    );
  };

  // const getTimePicker = () => {
  //   return (
  //     <AntdTimePicker
  //       open={showTimepicker}
  //       onOpenChange={setShowTimepicker}
  //       changeOnBlur
  //       placement="topRight"
  //       allowClear={false}
  //       showNow={false}
  //       format={"hh:mm A"}
  //       /**
  //        * The Popover that shows up when you click "custom" has a z-index of 3000.
  //        * And This was 1000 something by default. We need to make sure that the time picker
  //        * is above everything since it's the deepest popover in our UI.
  //        */
  //       rootClassName="border-zinc-400 !z-[4000]"
  //       use12Hours
  //       value={dayjs(timeTuple[+!isStartSelected])}
  //       onChange={
  //         isStartSelected
  //           ? (v) => {
  //               if (!v) throw new Error("unhandled");

  //               // restore the date since this is a time picker
  //               const newStart = new Date(start);
  //               const onlyTimeIsImportantInThis = v.toDate();
  //               // restore
  //               newStart.setHours(
  //                 onlyTimeIsImportantInThis.getHours(),
  //                 onlyTimeIsImportantInThis.getMinutes(),
  //                 0,
  //                 0
  //               );

  //               const getEnd = () => {
  //                 if (isAfter(newStart, end) || isSameMinute(newStart, end)) {
  //                   const endOfNextDay = endOfDay(addDays(newStart, 1));
  //                   endOfNextDay.setSeconds(0);
  //                   endOfNextDay.setMilliseconds(0);
  //                   return endOfNextDay;
  //                 }
  //                 return end;
  //               };

  //               onChange({
  //                 start: newStart, // set the start to what the user selected
  //                 end: getEnd(),
  //               });
  //             }
  //           : (v) => {
  //               if (!v) throw new Error("unhandled");

  //               // restore the date since this is a time picker
  //               const newEnd = new Date(end);
  //               const onlyTimeIsImportantInThis = v.toDate();
  //               // restore
  //               newEnd.setHours(
  //                 onlyTimeIsImportantInThis.getHours(),
  //                 onlyTimeIsImportantInThis.getMinutes(),
  //                 0,
  //                 0
  //               );

  //               const getStart = () => {
  //                 if (isAfter(start, newEnd) || isSameMinute(start, newEnd)) {
  //                   const startOfPreviousDay = startOfDay(subDays(newEnd, 1));
  //                   startOfPreviousDay.setSeconds(0);
  //                   startOfPreviousDay.setMilliseconds(0);
  //                   return startOfPreviousDay;
  //                 }
  //                 return start;
  //               };

  //               onChange({
  //                 start: getStart(),
  //                 end: newEnd, // set the end to what the user selected
  //               });
  //             }
  //       }
  //     />
  //   );
  // };

  const getCalendarRenderer = (): ((timePicker: ReactNode) => ReactNode) => {
    return (tp) => (
      <div
        className={cn(
          "absolute transition-all animate-in",
          isStartSelected ? "left-0" : "left-full -translate-x-full",
          calendarPosition === "top" && "bottom-[120%]",
          calendarPosition === "bottom" && "top-[120%]"
        )}
      >
        <CalendarComponents.SingleDay
          disabled={showTimepicker}
          key={isStartSelected.toString()}
          footer={tp}
          className={cn(
            "border border-xslate-8 rounded-md p-4 bg-white dark:bg-xslate-1 !m-0"
          )}
          value={isStartSelected ? start : end}
          onChange={
            isStartSelected
              ? (d) => {
                  const newValue = new Date(d);
                  const prev = start;
                  // restore time since only date matters
                  newValue.setHours(prev.getHours(), prev.getMinutes(), 0, 0);

                  const getEnd = () => {
                    if (isAfter(newValue, end) || isSameMinute(newValue, end)) {
                      const endOfNextDay = endOfDay(addDays(newValue, 1));
                      endOfNextDay.setSeconds(0);
                      endOfNextDay.setMilliseconds(0);
                      return endOfNextDay;
                    }
                    return end;
                  };

                  onChange({
                    start: newValue,
                    end: getEnd(),
                  });
                }
              : (d) => {
                  const newValue = new Date(d);
                  const prev = end;
                  // restore time since only date matters
                  newValue.setHours(prev.getHours(), prev.getMinutes(), 0, 0);

                  const getStart = () => {
                    if (
                      isAfter(start, newValue) ||
                      isSameMinute(start, newValue)
                    ) {
                      const startOfPreviousDay = startOfDay(
                        subDays(newValue, 1)
                      );
                      startOfPreviousDay.setSeconds(0);
                      startOfPreviousDay.setMilliseconds(0);
                      return startOfPreviousDay;
                    }
                    return start;
                  };

                  onChange({
                    start: getStart(),
                    end: newValue,
                  });
                }
          }
        />
      </div>
    );
  };

  const getCalendarWithTimePickerInside = () => {
    const calendarRenderer = getCalendarRenderer();
    const timePicker = getTimePicker2();

    return (
      timePicker &&
      calendarRenderer(
        <div className="flex justify-center mt-2">{timePicker}</div>
      )
    );
  };

  const calendarWithTimePickerInside = getCalendarWithTimePickerInside();

  const ensureTimepickerClosed = () => setShowTimepicker(false);

  return (
    <CustomRangeButtonPopoverContent
      withoutPortal={withoutPortal}
      onInteractOutside={onInteractOutside}
      side={side}
      endButton={{
        label: getLabel(end, timeTuple[1]),
        onClick: () => {
          ensureTimepickerClosed();
          setStartSelected(false);
        },
        selected: !isStartSelected,
      }}
      startButton={{
        label: getLabel(start, timeTuple[0]),
        onClick: () => {
          ensureTimepickerClosed();
          setStartSelected(true);
        },
        selected: isStartSelected,
      }}
      className={className}
      sideOffset={sideOffset}
    >
      {calendarWithTimePickerInside}
    </CustomRangeButtonPopoverContent>
  );
}
