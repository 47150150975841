import * as d3 from "d3";
import * as R from "remeda";
import {
  DEFAULT_FILL_OPACITY,
  DEFAULT_STROKE_OPACITY,
  LIGHT_FILL_OPACITY,
  LIGHT_STROKE_OPACITY,
  drawCapabilityLine,
  drawLimitLines,
  drawXScale,
} from "../utils/drawUtils";
import { OperatingLimit } from "../../../lib/api-schema/operating-limit";
import {
  ChartDimensions,
  DrawDataAtLeast1,
  Scale,
  SingleDrawData,
  SvgSelector,
} from "../types";
import {
  BoxPlotClass,
  LSLLine,
  TargetLine,
  USLLine,
  createLinearScale,
  getConsolidatedXScale,
  getDomainsFromData,
} from "../utils/chartUtils";
import { cn } from "../../../lib/utils";

// TODO: reduce complexity by factoring out common code in here and variability chart draw
export function drawBoxPlot({
  svgSelector,
  dimensions,
  data,
  limits,
  options,
  hover,
  axesFontScale,
}: {
  svgSelector: SvgSelector;
  dimensions: ChartDimensions;
  data: DrawDataAtLeast1;
  limits?: Pick<OperatingLimit, "value">[];
  options: { relative: boolean };
  hover?: {
    onHover: (svgX: number, windowX: number, variableId: string) => void;
    reset: () => void;
  };
  axesFontScale?: number;
}) {
  svgSelector.selectAll("*").remove(); // clear previously drawn stuff

  svgSelector
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("preserveAspectRatio", "none");

  const maxBoxHeight = 50;
  drawBuckets(
    svgSelector,
    dimensions,
    data,
    maxBoxHeight,
    options,
    limits,
    hover,
    axesFontScale
  );
}

function drawBuckets(
  svgSelector: SvgSelector,
  dimensions: ChartDimensions,
  data: DrawDataAtLeast1,
  maxBoxHeight: number,
  { relative }: { relative: boolean },
  limits?: Pick<OperatingLimit, "value">[],
  hover?: {
    onHover: (svgX: number, windowX: number, variableId: string) => void;
    reset: () => void;
  },
  axesFontScale?: number
) {
  // get highlighted variable if exists
  const highlightedVariable = data.find((v) =>
    v.selected ? true : v.hovered ? true : false
  );

  // don't allow variables with no values to affect chart
  const filteredData = data.filter((d) => d.values.length > 0);

  // get consolidated domain for all data
  const domains = getDomainsFromData(filteredData);
  const allXDomains = R.filter(
    domains.map((d) => d?.xDomain),
    R.isTruthy
  );

  // make consolidated x scale for all x domains
  const consolidatedXScale = getConsolidatedXScale(
    allXDomains,
    dimensions,
    limits
  );

  const numBoxes = filteredData.length;

  // make y scale as percentages to map histogram with density chart
  const yScale = createLinearScale(
    [-1, numBoxes],
    [dimensions.height - dimensions.margin, 0]
  );

  // if not relative, draw consolidated x scale
  if (!relative) {
    drawXScale(svgSelector, {
      scale: consolidatedXScale,
      dims: dimensions,
      highlight: true,
      axesFontScale,
    });
  }

  // for all data make a box
  // if relative, make domain for each variable
  filteredData.forEach((variable: SingleDrawData, index) => {
    if (!variable || !variable.stats || variable.values.length < 1) return;

    const boxHeight =
      (dimensions.height - dimensions.margin * 6) / numBoxes < maxBoxHeight
        ? (dimensions.height - dimensions.margin * 6) / numBoxes
        : maxBoxHeight;

    const highlight: boolean = highlightedVariable
      ? variable.ref === highlightedVariable.ref
      : true;

    // if in relative view, make domain and axis for each variable
    if (relative) {
      const variableDomain = domains[index];
      if (!variableDomain) return;

      const myXScale = d3
        .scaleLinear()
        .domain(variableDomain.xDomain)
        .range([0, dimensions.width]);

      if (highlight && highlightedVariable !== undefined) {
        const drawnXScale = drawXScale(svgSelector, {
          scale: myXScale,
          dims: dimensions,
          highlight,
          identifier: variable.id,
          axesFontScale,
        });
        drawnXScale.style("fill", variable.color);

        if (limits) drawLimitLines(svgSelector, limits, myXScale, dimensions);
        if (variable.usl !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.usl,
            USLLine,
            variable.color,
            myXScale
          );
        if (variable.lsl !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.lsl,
            LSLLine,
            variable.color,
            myXScale
          );
        if (variable.target !== undefined)
          drawCapabilityLine(
            svgSelector,
            dimensions,
            variable.target,
            TargetLine,
            variable.color,
            myXScale
          );
      }

      // TODO: draw usl and lsl lines

      if (variable)
        drawBox(
          svgSelector,
          variable,
          index,
          boxHeight,
          myXScale,
          yScale,
          highlight,
          hover
        ); // draw box plots in relative view
    }
    // else in absolute view
    else {
      // TODO: draw usl and lsl lines
      if (variable)
        drawBox(
          svgSelector,
          variable,
          index,
          boxHeight,
          consolidatedXScale,
          yScale,
          highlight,
          hover
        ); // draw box plots for absolute view
      if (limits)
        drawLimitLines(svgSelector, limits, consolidatedXScale, dimensions);
      if (variable.usl !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.usl,
          USLLine,
          variable.color,
          consolidatedXScale
        );
      if (variable.lsl !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.lsl,
          LSLLine,
          variable.color,
          consolidatedXScale
        );
      if (variable.target !== undefined)
        drawCapabilityLine(
          svgSelector,
          dimensions,
          variable.target,
          TargetLine,
          variable.color,
          consolidatedXScale
        );
    }
  });
}

function drawBox(
  svgSelector: SvgSelector,
  data: SingleDrawData,
  index: number,
  boxHeight: number,
  xScale: Scale,
  yScale: Scale,
  highlight: boolean,
  hover?: {
    onHover: (svgX: number, windowX: number, variableId: string) => void;
    reset: () => void;
  }
) {
  const stats = data.stats;
  if (!stats) throw new Error("No stats available");

  const boxPlot = svgSelector
    .append("g")
    .attr("class", cn("chart", BoxPlotClass, data.ref))
    .attr("id", `${BoxPlotClass}-${data.ref}`);

  // min line
  const minLine = boxPlot.append("line");
  minLine
    .attr("x1", xScale(stats.min))
    .attr("x2", xScale(stats.q1))
    .attr("y1", yScale(index))
    .attr("y2", yScale(index))
    .attr("vector-effect", "non-scaling-stroke")
    .attr("stroke", "#222")
    .attr("stroke-width", 1.2)
    .attr(
      "stroke-opacity",
      highlight ? DEFAULT_STROKE_OPACITY : LIGHT_STROKE_OPACITY
    )
    .attr("class", "minLine");

  // max line
  const maxLine = boxPlot.append("line");
  maxLine
    .attr("x1", xScale(stats.q3))
    .attr("x2", xScale(stats.max))
    .attr("y1", yScale(index))
    .attr("y2", yScale(index))
    .attr("vector-effect", "non-scaling-stroke")
    .attr("stroke", "#222")
    .attr("stroke-width", 1.2)
    .attr(
      "stroke-opacity",
      highlight ? DEFAULT_STROKE_OPACITY : LIGHT_STROKE_OPACITY
    )
    .attr("class", "maxLine");

  // generate box
  // could use iqr and q1 or could use q3 to get width
  const box = boxPlot.append("rect");
  box
    .attr("x", xScale(stats.q1))
    .attr("y", yScale(index) - boxHeight / 2)
    .attr("width", xScale(stats.q3) - xScale(stats.q1))
    .attr("height", boxHeight)
    .attr("class", "box")
    .attr("stroke", data.color)
    .attr("stroke-width", 3)
    .attr(
      "stroke-opacity",
      highlight ? DEFAULT_STROKE_OPACITY : LIGHT_STROKE_OPACITY
    )
    .style("fill", data.color)
    .style(
      "fill-opacity",
      highlight ? DEFAULT_FILL_OPACITY : LIGHT_FILL_OPACITY
    );

  // make median line
  const medianLine = boxPlot.append("line");
  medianLine
    .attr("x1", xScale(stats.median))
    .attr("x2", xScale(stats.median))
    .attr("y1", yScale(index) - boxHeight / 2)
    .attr("y2", yScale(index) + boxHeight / 2)
    .attr("stroke", data.color)
    .attr("stroke-width", 3)
    .attr(
      "stroke-opacity",
      highlight ? DEFAULT_STROKE_OPACITY : LIGHT_STROKE_OPACITY
    )
    .attr("class", "medianLine");

  // container to handle mouse events
  boxPlot
    .append("rect")
    .attr("x", xScale(stats.min))
    .attr("y", yScale(index) - boxHeight / 2)
    .attr("width", xScale(stats.max) - xScale(stats.min) + "px")
    .attr("height", boxHeight + "px")
    .attr("id", `box-container-${data.ref}`)
    .attr("visibility", "hidden")
    .attr("pointer-events", "all")
    // onHover here should only pass back variable
    .on("mousemove", (event: MouseEvent) => {
      const x = d3.pointer(event)[0];
      const graph = boxPlot.node();
      if (data && data.ref && graph) hover?.onHover(x, event.clientX, data.ref);
    })
    .on("mouseleave", () => {
      hover?.reset();
    });
}
