import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { Button } from "../../../shared-ui/frontend/button";
import { Plus } from "lucide-react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";

function CreateNewEntityButton({
  onClick,
  className,
}: { onClick?: () => void } & PropsWithCn) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={onClick}
            variant={"default"}
            size={"sm"}
            className={cn(
              "h-7 w-8 p-0 bg-indigo-700 text-white hover:bg-indigo-800 hover:text-white border-0 rounded-lg",
              className
            )}
          >
            <Plus className="h-5 w-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Create New</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default CreateNewEntityButton;
