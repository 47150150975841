import { useAtomValue } from "jotai";
import { GenericCommentForm, OnCommentCreate } from "./generic-comment-form";
import {
  Atoms,
  isVariableTrendLine,
} from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useMutation } from "@tanstack/react-query";
import {
  createReply,
  type CreateCommentReplyPayload,
} from "../../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../../toast/use-toast-store";
import { Comments } from "../../../../hooks/tanstack-query";
import { assertMinLen1 } from "../../../../shared-ui/lib/utils";
import { useMemo } from "use-memo-one";
import { useState } from "react";
import { v4 } from "uuid";
import { useCloseThread } from "./comment-thread";

function CreateCommentReplyForm({
  parentCommentId,
  noPortalMultiSelects,
}: {
  parentCommentId: string;
  noPortalMultiSelects?: true;
}) {
  const primaryVid = useAtomValue(Atoms.primaryBatchVariableAtom);
  const primaryIsVariableTrendLine = isVariableTrendLine(primaryVid);
  const b = useBaseUrlExperimental();

  const invalidateCommentsList = Comments.list.useInvalidate();
  const invalidateSingleCommentQueries = Comments.single.useInvalidate();
  const commentQuery = Comments.single.useQuery(parentCommentId);

  const parentComment = commentQuery.data;

  const [key, setKey] = useState(v4());

  const m = useMutation({
    mutationFn: async (p: CreateCommentReplyPayload) => {
      return await createReply(b, parentCommentId, p);
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
    onSuccess: () => {
      invalidateCommentsList();
      invalidateSingleCommentQueries();
      setKey(v4());
    },
  });

  const variableIdsForParentComment = useMemo(() => {
    if (!parentComment) return undefined;

    return assertMinLen1(parentComment.variables.map((x) => x._id));
  }, [parentComment]);

  const closeThread = useCloseThread();

  if (!parentComment || !primaryIsVariableTrendLine) return null;

  const canCloseOrReopenIssue = parentComment.type === "Issue";

  const onSubmit: OnCommentCreate = (d) => {
    m.mutate({
      labels: d.taggedLabels,
      link: window.location.href,
      tagged_teams: d.taggedTeams,
      tagged_users: d.taggedUsers,
      text: d.comment,
      variables: d.taggedVariables,
      issue_resolution: canCloseOrReopenIssue ? d.issueResolution : undefined,
    });
  };

  if (!variableIdsForParentComment) throw new Error("impossible");

  return (
    <GenericCommentForm
      noPortalMultiSelects={noPortalMultiSelects}
      key={key} // re-mount on successful submission
      showIssueCloseOrReopenButton={canCloseOrReopenIssue}
      className="border-t border-xslate-6 mt-2"
      isLoading={false}
      close={closeThread}
      closeLabel="Close" // Say Close instead of Cancel when we're inside a comment thread
      onSubmit={onSubmit}
      defaults={{
        taggedLabels: [],
        taggedTeams: [],
        taggedUsers: [],
        taggedVariables: variableIdsForParentComment,
        comment: "",
        issueResolution: parentComment.issue_resolved,
      }}
      isPrivate={parentComment.private}
    />
  );
}

export { CreateCommentReplyForm };
