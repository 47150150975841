import moment from "moment-timezone";
import "./VariableInfo.scss";
import {
  useClustersQuery,
  useGroupsMappedByIdQuery,
  useOperatingLimitsQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { reverse } from "remeda";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { ComponentProps } from "react";
import React from "react";
import { Info } from "lucide-react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { Button } from "../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";

type Props = { variableId: string } & Pick<
  ComponentProps<typeof PopoverContent>,
  "withoutPortal"
> & {
    icon?: React.ReactNode;
  } & PropsWithCn;

export function VariableInfoTooltip({ className, ...props }: Props) {
  if (!props.variableId)
    throw new Error("should not render without variableId");

  return (
    <Tooltip>
      <TooltipContent>Information</TooltipContent>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              onClick={(e) => e.stopPropagation()}
              variant={"ghost"}
              size={"icon-sm"}
              className={cn("hover:text-xindigo-11", className)}
            >
              <Info className="size-3.5" />
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <PopoverContent
          withoutPortal={props.withoutPortal}
          className="flex flex-col gap-3 min-w-[300px] max-w-[50vw] text-sm rounded-lg border-xslate-7 cursor-default"
          onClick={(e) => e.stopPropagation()}
        >
          <TooltipPopoverContent {...props} />
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}

function TooltipPopoverContent(props: Pick<Props, "variableId">) {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const limitsQuery = useOperatingLimitsQuery(props.variableId);
  const groupsQuery = useGroupsMappedByIdQuery();
  const clustersQuery = useClustersQuery();

  const variable = variablesMapQuery.data?.[props.variableId];

  const variableLimits = limitsQuery.data ?? [];

  const groups__ = groupsQuery.data ?? {};

  if (variablesMapQuery.isLoading || variablesMapQuery.isError) return null;

  const groupObjects =
    variable?.groups.reduce(
      (arr, id) => {
        const group = groups__[id];
        group && arr.push(group);
        return arr;
      },
      [] as (typeof groups__)[string][]
    ) ?? [];
  const groupNamesArr = groupObjects.map((g) => g.name);

  [] as (typeof groups__)[string][];

  // we might have duplicates since we are checking from 2 sources
  const shutdownNamesSet = new Set<string>();

  if (variable?.type === "ShutdownRule")
    // itself
    shutdownNamesSet.add(variable.trimmedName);

  const addShutdownName = (sdid: string) => {
    const name = variablesMapQuery.data[sdid]?.trimmedName;
    name != null && shutdownNamesSet.add(name);
  };

  groupObjects.forEach(({ shutdownRuleIds }) => {
    shutdownRuleIds.forEach(addShutdownName);
  }); // source 1

  variable?.sd_rule_ids.forEach(addShutdownName); // source 2

  const shutdownRules = [...shutdownNamesSet]; // convert to array

  const operatingModes =
    variable?.om_rule_ids.reduce((arr, id) => {
      const mode = variablesMapQuery.data[id];

      /* Double-bindings fail sometimes, we have to accomodate it. */
      if (!mode) {
        console.log("Missing Operating Rule: ", id);
        return arr;
      }
      arr.push(mode.trimmedName);
      return arr;
    }, [] as string[]) ?? [];

  const limits = variableLimits
    .map((limit) => {
      const level = limit.level.toUpperCase();
      const sortedDates = reverse(limit.data.slice(-3)); /* max 3 values */

      let value;

      if (sortedDates.length === 1) {
        value = `${sortedDates[0]!.value}`;
      } else
        value = sortedDates
          .map(
            (data) =>
              `${data.value} (since ${moment
                .utc(data.start)
                .format("MMM DD, YYYY")})`
          )
          .join(", ");

      return `${level}: ${value}`;
    })
    .filter(Boolean);

  const data: {
    name: string;
    data: string[];
  }[] = [
    {
      name: "Groups",
      data: groupNamesArr,
    },
    {
      name: "Clusters",
      data:
        variable && clustersQuery.data
          ? variable.families.reduce((arr, _id) => {
              const clusterObj = clustersQuery.data.find((c) => c._id === _id);
              clusterObj && arr.push(clusterObj.name);
              return arr;
            }, [] as string[])
          : [],
    },
    {
      name: "Unit of Measurement",
      data: variable?.units_of_measurement
        ? [variable.units_of_measurement]
        : [],
    },
    {
      name: "Shutdown Rules",
      data: shutdownRules,
    },
    {
      name: "Operating Modes",
      data: operatingModes,
    },
    {
      name: "Limits (Operating Fitness)",
      data: limits,
    },
  ].filter((o) => !!o.data.length);

  if (!variable) return;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <span className="text-xslate-11 leading-none mb-0.5 uppercase font-normal">
          Variable
        </span>
        <span className="ml-2 font-normal">{variable.trimmedName}</span>
        <span className="ml-2 font-normal">{variable.description}</span>
      </div>
      {variable.expression && (
        <div className="flex flex-col">
          <span className="text-xslate-11 leading-none mb-0.5 uppercase font-normal">
            Calculated Expression
          </span>
          <span className="ml-2 leading-none font-normal">
            {variable.expression}
          </span>
        </div>
      )}
      {data.map((o) => (
        <div className="flex flex-col" key={o.name}>
          <span className="leading-none mb-0.5 text-xslate-11 uppercase font-normal">
            {o.name}
          </span>
          {o.data.map((x) => (
            <span key={variable._id + o.name + x} className="ml-2 font-normal ">
              {x}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}

export { TooltipPopoverContent };
