import {
  FaultTreeDetailsStore,
  useGetUseFaultTreeDetailsStore,
} from "../details/ft-details-store";
import { useGetOnTreeNodeClick } from "../details/use-get-tree-node-data";
import { FaultTreeNodeFiles } from "../editor/use-ft-node-files-query";
import { FilesPills } from "../editor/files-pills";

type SetSelectedNode = (nodeId: string) => void | Promise<void>;

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function splitStringWithDelimiters(str: string, delimiters: string[]) {
  // Sort delimiters array in descending order of length
  const escapedDels = [...delimiters]
    .sort((a, b) => b.length - a.length)
    .map((s) => escapeRegExp(s));

  // Build regex pattern from delimiters array
  const pattern = new RegExp(`(${escapedDels.join("|")})`);

  // Split string using regex pattern
  return str.split(pattern);
}

const linkNodes = (
  expression: string,
  nodes: NonNullable<FaultTreeDetailsStore["tree"]>["nodes"],
  setSelectedNode: SetSelectedNode
) => {
  const nodeNames = nodes.map((node) => node.name);
  const parts = splitStringWithDelimiters(expression, nodeNames);
  return parts.map((part, i) => {
    const includes = nodeNames.includes(part);

    return (
      <span
        key={part + i.toString()}
        onClick={
          includes
            ? () => {
                const node = nodes.find((n) => n.name === part);
                node && setSelectedNode(node._id);
              }
            : undefined
        }
        className={
          includes ? "text-blue-600 cursor-pointer underline" : undefined
        }
      >
        {part}
      </span>
    );
  });
};

export const FaultTreeNodeDetailsInfo2 = () => {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const nodes = useFaultTreeDetailsStore((s) => s.tree?.nodes);
  const setSelectedNode = useGetOnTreeNodeClick();

  if (!selectedNode) throw new Error("dont render this component if no node");
  const { expression, recommendation } = selectedNode;

  return (
    <div
      className={
        "faultTree-nodeDetails-info bg-white border border-bordgrey2 mt-3 rounded-lg"
      }
    >
      <div className={"faultTree-nodeDetails-info-label"}>Node expression</div>
      <div className={"mono faultTree-nodeDetails-info-expression"}>
        {nodes && linkNodes(expression, nodes, setSelectedNode)}
      </div>
      {recommendation && (
        <>
          <br />
          <div className={"faultTree-nodeDetails-info-label"}>
            Recommendation
          </div>
          <div className={"faultTree-nodeDetails-info-recommendation"}>
            {recommendation}
          </div>
        </>
      )}
      <FilesSection />
    </div>
  );
};

function FilesSection() {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const filesQuery = FaultTreeNodeFiles.useQuery(selectedNode?._id);

  if (filesQuery.isError) throw filesQuery.error;

  if (!selectedNode || !filesQuery.data) return null;

  if (filesQuery.data.length === 0) return null;

  return (
    <>
      <br />
      <div className={"faultTree-nodeDetails-info-label"}>Files</div>
      <div className={"faultTree-nodeDetails-info-selectedNode"}>
        <FilesPills files={filesQuery.data} className="mt-1" />
      </div>
    </>
  );
}
