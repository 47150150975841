import React, { useState } from "react";
import * as R from "remeda";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import ExpressionRow from "../../common/manager/ExpressionRow";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import { OperatingModeForm } from "./operating-mode-form";
import { useEditOperatingModeMutation } from "./mutations";
import { cn } from "../../../lib/utils";
import { addToast } from "../../toast/use-toast-store";
import {
  CreateOperatingModePayload,
  deleteOperatingMode,
  generateMessageFromError,
} from "../../../frameworks/fetcher/api-routes-experimental";
import {
  useClustersQuery,
  useGroupsQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useUpdateOperatingModesQuery } from "./queries";
import { OperatingMode } from "../../../lib/api-schema/om";

function OperatingModesCard({ mode }: { mode: OperatingMode }) {
  const groupsQuery = useGroupsQuery();
  const tagsQuery = useVariablesMappedByIdQuery();
  const clustersQuery = useClustersQuery();

  const hasEditAccess = useHasEditPermission();
  const [editMode, setEditMode] = useState(false);
  const editMutation = useEditOperatingModeMutation();

  const calcOn = mode.om_calc ?? true;

  const baseUrlSlash = useBaseUrlExperimental();
  const updateOmQuery = useUpdateOperatingModesQuery();

  return (
    <div
      className={cn(
        "mt-6 border border-solid border-[#dbdbdb] bg-white rounded-lg",
        "data-[justcreated='true']:animate-new-item-fade"
      )}
      data-justcreated={(
        Date.now() - new Date(mode.createdAt).valueOf() <
        4000
      ).toString()}
    >
      <div className="text-[#000000] flex items-center justify-between text-[1.05em] py-[0.15em] px-[0.5em] border-b border-solid border-bordgrey rounded-t-xl">
        <div className="py-[0.4em] w-1/2 sm:w-auto sm:block flex flex-col">
          <span className="font-semibold px-0 my-[0.2em] mx-0">
            {`${mode.name} | ${mode.description}`}
          </span>
        </div>

        {/* edit and delete buttons  */}
        {hasEditAccess && (
          <div>
            <Tooltip label="Delete" withArrow>
              <Button
                className="btn-ghost"
                icon="trash-o"
                onClick={() => {
                  window.confirm(
                    `Are you sure you want to delete operating mode ${mode.name} | ${mode.description}?`
                  ) &&
                    deleteOperatingMode(baseUrlSlash, mode._id)
                      .then(() => {
                        updateOmQuery((curr) =>
                          curr.filter((om) => om._id !== mode._id)
                        );
                        addToast({
                          title: `Operating mode: ${mode.name} deleted`,
                          variant: "success",
                        });
                      })
                      .catch((e) => {
                        addToast({
                          title: generateMessageFromError(
                            e,
                            "An error occurred during deletion"
                          ),
                          variant: "danger",
                        });
                      });
                }}
              />
            </Tooltip>

            {!editMode && (
              <Tooltip label="Edit" withArrow>
                <Button
                  icon="pencil"
                  onClick={() => setEditMode(true)}
                  className="btn-ghost"
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {editMode ? (
        <OperatingModeForm
          disabled={editMutation.isLoading}
          defaultValues={{ ...mode, clusters: mode.clusters }}
          className="m-0 border-0"
          close={() => setEditMode(false)}
          onSubmit={(payloadFromForm) => {
            const {
              bindingGroupIds,
              bindingVariableIds,
              description,
              expression,
              name,
              om_calc,
              clusters,
              ...rest
            } = payloadFromForm;

            const _shouldNotError: keyof typeof rest extends never
              ? true
              : false = true;

            const payload: {
              _id: string;
            } & Partial<CreateOperatingModePayload> = {
              _id: mode._id,
            };

            if (
              !R.equals(
                bindingGroupIds.slice().sort(),
                mode.bindingGroupIds.slice().sort()
              )
            )
              payload.bindingGroupIds = bindingGroupIds;

            if (
              !R.equals(clusters.slice().sort(), mode.clusters.slice().sort())
            )
              payload.clusters = clusters;

            if (
              !R.equals(
                bindingVariableIds.slice().sort(),
                mode.bindingVariableIds.slice().sort()
              )
            )
              payload.bindingVariableIds = bindingVariableIds;
            if (description !== mode.description)
              payload.description = description;
            if (expression !== mode.expression) payload.expression = expression;
            if (name !== mode.name) payload.name = name;
            if (om_calc !== mode.om_calc) payload.om_calc = om_calc;
            if (!Object.keys(payload).length) return setEditMode(false);

            editMutation.mutateAsync(payload).then((res) => {
              setEditMode(false);

              updateOmQuery((curr) =>
                curr.map((someMode) => {
                  if (someMode._id === res._id) {
                    return res;
                  }
                  return someMode;
                })
              );
            });
          }}
        />
      ) : (
        <>
          <div className="px-2">
            <ExpressionRow expression={mode.expression} />
            <div className="py-[0.4rem]">
              <span className="italic text-textgrey">Anomaly Calculation</span>
              <div className="pl-[1.3rem]">
                <span
                  className={cn(
                    "text-[1.3rem] mono",
                    calcOn ? "text-xgreen-9" : "text-xred-9"
                  )}
                >
                  {calcOn ? "ON" : "OFF"}
                </span>
              </div>
            </div>
            {mode.bindingGroupIds.length > 0 && groupsQuery.data && (
              <PillRow
                items={mode.bindingGroupIds.reduce((names, id) => {
                  const g = groupsQuery.data.find((g) => g._id === id);
                  g && names.push(g.name);
                  return names;
                }, [] as string[])}
                label="Groups"
              />
            )}
            {mode.bindingVariableIds.length > 0 && tagsQuery.data && (
              <PillRow
                items={mode.bindingVariableIds.reduce((names, id) => {
                  const v = tagsQuery.data[id];
                  v && names.push(v.trimmedName);
                  return names;
                }, [] as string[])}
                label="Tags"
              />
            )}

            {mode.clusters.length > 0 && clustersQuery.data && (
              <PillRow
                items={mode.clusters.reduce((names, id) => {
                  const g = clustersQuery.data.find((g) => g._id === id);
                  g && names.push(g.name);
                  return names;
                }, [] as string[])}
                label="Clusters"
              />
            )}
          </div>
          <UpdatedAt timestamp={mode.updatedAt} />
        </>
      )}
    </div>
  );
}

export default React.memo(OperatingModesCard);
