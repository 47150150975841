import { Tag } from "../../../lib/api-schema/tag";
import { variableSchema } from "../../../lib/api-validators";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { BaseTagForm } from "./base-tag-form";
import { useEditTagMutation } from "./mutations";

function EditTagForm({
  onClose,
  tag,
  onSuccess,
}: {
  onClose: () => void;
  tag: variableSchema;
  onSuccess?: (t: Tag) => void;
}) {
  const editTagMutation = useEditTagMutation(onSuccess);
  const hasEditPermission = useHasEditPermission();

  return (
    <BaseTagForm
      disabled={!hasEditPermission || editTagMutation.isLoading}
      className="border-0"
      close={onClose}
      onSubmit={(payload) => {
        const onlyWhatHasChanged: Partial<typeof payload> = {};
        const {
          description,
          groupIds,
          hide_negative_slopes,
          hide_positive_slopes,
          highSide,
          lowSide,
          name,
          st_enabled,
          unitsOfMeasurement,
          expression,
          historian,
          familyIds,
          inherit_sd,
          ...rest
        } = payload;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _mustCompile: keyof typeof rest extends never ? true : false =
          true;

        if (historian) onlyWhatHasChanged.historian = historian;
        if (tag.expression && expression !== tag.expression) {
          onlyWhatHasChanged.expression = expression;
        }
        if (tag.units_of_measurement !== unitsOfMeasurement)
          onlyWhatHasChanged.unitsOfMeasurement = unitsOfMeasurement;
        if (tag.description !== description)
          onlyWhatHasChanged.description = description;
        if (tag.trimmedName !== name) onlyWhatHasChanged.name = name;
        if (
          groupIds.length !== tag.groups.length ||
          // @ts-ignore
          groupIds.some((g) => !tag.groups.some((t) => t.value === g))
        )
          onlyWhatHasChanged.groupIds = groupIds;
        if (
          familyIds.length !== tag.families.length ||
          familyIds.some((clusterId) => !tag.families.includes(clusterId))
        )
          onlyWhatHasChanged.familyIds = familyIds;
        if (tag.high_side !== highSide) onlyWhatHasChanged.highSide = highSide;
        if (tag.low_side !== lowSide) onlyWhatHasChanged.lowSide = lowSide;

        if (tag.hide_negative_slopes !== hide_negative_slopes)
          onlyWhatHasChanged.hide_negative_slopes = hide_negative_slopes;
        if (tag.hide_positive_slopes !== hide_positive_slopes)
          onlyWhatHasChanged.hide_positive_slopes = hide_positive_slopes;
        const prev_st_enabled =
          !tag.hide_negative_slopes || !tag.hide_positive_slopes;
        if (prev_st_enabled !== st_enabled)
          onlyWhatHasChanged.st_enabled = st_enabled;
        if (inherit_sd !== tag.inherit_sd) {
          onlyWhatHasChanged.inherit_sd = inherit_sd;
        }

        editTagMutation.mutate({
          _id: tag._id,
          ...onlyWhatHasChanged,
        });
      }}
      defaultValues={{
        familyIds: tag.families,
        groupIds: tag.groups,
        name: tag.trimmedName,
        hide_negative_slopes: !tag.hide_negative_slopes,
        hide_positive_slopes: !tag.hide_positive_slopes,
        highSide: tag.high_side,
        lowSide: tag.low_side,
        unitsOfMeasurement: tag.units_of_measurement || null,
        expression: tag.expression ?? undefined,
        historian: tag.historian ?? undefined,
        description: tag.description,
        inherit_sd: tag.inherit_sd,
      }}
    />
  );
}

export { EditTagForm };
