import _ from "lodash";
import "./FaultTreeNodeDetailsChart.scss";
import { useGetUseFaultTreeDetailsStore } from "../details/ft-details-store";
import { operators } from "../../d3ft/operators";
import {
  createJotaiStoreForChartSync,
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
} from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useShowClickedLine } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { FullscreenProvider } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { ProfileBookFullscreenChart } from "../../pb/profile-book";
import {
  Atoms,
  ChartVariant,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useMemo } from "use-memo-one";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { Provider } from "jotai";
import { getBvOrId } from "../../../shared-ui/time-series-2/draw/draw";
import { CorrelatedTagsDrawerStoreProvider } from "../../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { ClusterDrawerStoreProvider } from "../../clusters/cluster-drawer/use-cluster-drawer-store";
import { ClusterDrawer } from "../../clusters/cluster-drawer/cluster-drawer";
import { CorrelatedTagsDrawer } from "../../clusters/cluster-drawer/correlated-tags-drawer";

const TAG_VAR_TYPE = 0;

const chartDims = chartDimensionsConfig({ isGridView: false });

function FaultTreeNodeDetailsChart2() {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);

  if (!selectedNode) throw new Error("should not render if so");

  const showClickedLine = useShowClickedLine();
  const zone = useTimezone();

  const chartStores = useMemo(() => {
    const showAnomalyColoration = operators.some((op) =>
      selectedNode.tagExpression.includes(op + "(")
    );

    const decideAnomBooleanForNewlyAddedVariableTrendLines = () =>
      showAnomalyColoration;

    return selectedNode?.expressionVariableIds
      .filter((varIdTypePair) => varIdTypePair.type === TAG_VAR_TYPE)
      .reverse()
      .map((x, _, arr) => {
        return createJotaiStoreForChartSync({
          zone,
          initialBatchVariables: [
            { type: "variable", bv: x._id.padStart(48, "0") },
          ],
          initialExpanded: arr.length === 1,
          decideAnomBooleanForNewlyAddedVariableTrendLines,
          variant: ChartVariant.FaultTrees,
        });
      }); // I have no clue why it's reversed, but legacy code does it
  }, [selectedNode, zone]);

  return (
    <FullscreenProvider>
      <CorrelatedTagsDrawerStoreProvider>
        <ClusterDrawerStoreProvider>
          <ProfileBookFullscreenChart />
          <div className="flex flex-col gap-4 py-5">
            {chartStores.map((jotaiStore) => {
              return (
                <Provider
                  key={getBvOrId(
                    jotaiStore.get(Atoms.primaryBatchVariableAtom)
                  )}
                  store={jotaiStore}
                >
                  <DRASecondaryVariableViewNeedsProvider
                    noPortalMultiSelects // this is rendered in a dialog so the multi selects within the chart cannot also be portal'ed
                    onLineClick={({ bvOrId, clientX, clientY, jotaiStore }) => {
                      showClickedLine({
                        clientX,
                        clientY,
                        jotaiStore,
                        bvOrId,
                      });
                    }}
                    ableToGetTaller
                    {...chartDims}
                    padding={{
                      left: 45,
                    }}
                    expandable
                    respondToSelectedCommentId
                  />
                </Provider>
              );
            })}
          </div>
          <ClusterDrawer />
          <CorrelatedTagsDrawer />
        </ClusterDrawerStoreProvider>
      </CorrelatedTagsDrawerStoreProvider>
    </FullscreenProvider>
  );
}

export { FaultTreeNodeDetailsChart2 };
