import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import First from "../../common/First";
import MainLoader from "../../common/MainLoader";
import PageError from "../../common/PageError";
import Fragment from "../../common/Fragment";
import { subDays } from "date-fns";

import Button from "../../common/Button/Button";
import UserPermissionRoute from "../../login/UserPermissionRoute";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { SingleDaySelectWithArrows } from "../../common/calendar";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { MultiSelectWithLabel } from "../../manager-components";
import {
  useAriaClustersQuery,
  useGroupsQuery,
  usePublishedFaultTreesQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import * as R from "remeda";
import { useMutation } from "@tanstack/react-query";
import {
  deleteRisks,
  queueBackprocessTask,
  queueClusterBackprocessing,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { YYYY_MM_DD } from "../../../lib/validators";
import { cn } from "../../../lib/utils";

const PAGE_NAME = "Back-Processing";
const TABS = {
  RISKS: "Reset Risks",
  BACKPROC: PAGE_NAME, // arbitrary choices
} as const;
const DATE_FORMAT = "YYYY-MM-DD";

function useDeleteRisksMutation() {
  const baseUrl = useBaseUrlExperimental();
  return useMutation({
    mutationFn: (groupIds: string[]) => {
      return deleteRisks(baseUrl, groupIds);
    },
    onSuccess: () => addSuccessToast("Reset task has been added to the queue"),
    onError: (err) => addUnknownErrorToast(err),
  });
}

function useQueueBackprocessTaskMutation() {
  const baseUrl = useBaseUrlExperimental();
  return useMutation({
    mutationFn: (payload: Parameters<typeof queueBackprocessTask>[1]) => {
      return queueBackprocessTask(baseUrl, payload);
    },
    onSuccess: () =>
      addSuccessToast("Back processing request has been added to the queue"),
    onError: (err) => addUnknownErrorToast(err),
  });
}

function useQueueClusterBackprocessingMutation() {
  const baseUrl = useBaseUrlExperimental();
  return useMutation({
    mutationFn: (clusters: { name: string; _id: string }[]) => {
      return queueClusterBackprocessing(baseUrl, clusters);
    },
    onSuccess: () =>
      addSuccessToast("Back processing request has been added to the queue"),
    onError: (err) => addUnknownErrorToast(err),
  });
}

function BackProcessManager() {
  const deleteRisksMutation = useDeleteRisksMutation();
  const backprocessMutation = useQueueBackprocessTaskMutation();
  const clusterBackprocessMutation = useQueueClusterBackprocessingMutation();
  usePermissionBasedDocumentTitleForSettingsPage();
  /**
   * Some things copied over from OperatingLimitsManager to obtain the appropriate default
   * date
   *
   * Some things to note about the date formats: So the API seems to take DATE_FORMAT, but
   * DISPLAY_DATE_FORMAT seems more user friendly. Under the hood the date is DISPLAY_DATE_FORMAT,
   * for the calendar select component and for state. Before hitting the API, it will be converted
   * to DATE_FORMAT
   */
  const yesterdayDate = subDays(new Date(), 1);

  const [selectedState, setSelectedState] = useState<{
    selectedVars: string[];
    selectedGroupIds: string[];
    selectedFts: string[];
    selectedClusters: string[];
  }>({
    selectedVars: [],
    selectedGroupIds: [],
    selectedFts: [],
    selectedClusters: [],
  });

  const clusters = useAriaClustersQuery({
    filterType: "dynamic",
  });
  const groupsQuery = useGroupsQuery();
  const variablesQuery = useVariablesArrayQuery();
  const faultTreesQuery = usePublishedFaultTreesQuery();

  const initialLoading =
    clusters.isLoading ||
    groupsQuery.isLoading ||
    variablesQuery.isLoading ||
    faultTreesQuery.isLoading;
  const pageError =
    clusters.isError ||
    groupsQuery.isError ||
    variablesQuery.isError ||
    faultTreesQuery.isError;

  const [currentTab, setTab] = useState<(typeof TABS)[keyof typeof TABS]>(
    TABS.BACKPROC
  );

  const [selectedDate, setSelectedDate] = useState(yesterdayDate);

  /**
   * before submitting we need to create the final payload, which is kinda confusing.
   * the form allows selection of variables, groups, and fault trees. each of the
   * three require different methods to obtain the variable Ids we need to pass
   * into the request. ultimately, this function does said 3 things to compose
   * one large list of variable Ids that are affected by this back processing
   * change
   */
  const submitBackprocess = async () => {
    /**
     * The group Obj has a list of variables attached to it. For all of the
     * groups selected, flatten their variables into one array.
     */

    const variableIdsFromGroups = R.pipe(
      selectedState.selectedGroupIds,
      R.map((groupId) => {
        const groupObj = groupsQuery.data?.find((g) => g._id === groupId);
        if (!groupObj) throw new Error("No group or no query data");
        return groupObj.variables;
      }),
      R.flatten(),
      R.uniq()
    );

    if (!variablesQuery.data) throw new Error("No variables query data");

    const variableIdsFromTrees = R.uniq(
      variablesQuery.data
        .filter((v) => {
          const thisVariableIsPartOfSomeSelectedTree =
            selectedState.selectedFts.some(
              (ftId) => ftId === v.ft_node_fault_tree_id
            );
          return thisVariableIsPartOfSomeSelectedTree; // include this variable to backprocessing if it is part of some selected tree
        })
        .map((v) => v._id)
    );

    // we need to get the variables associated with the selected clusters
    const variableIdsToBackProcessFromClusters =
      selectedState.selectedClusters.flatMap((clusterId) => {
        const data = clusters.data;
        if (!data) throw new Error("No data");

        const clusterObj = data.find((cluster) => cluster._id === clusterId);

        if (!clusterObj) throw new Error("No cluster");

        // const { variables } = clusterObj; // add the variables associated with the cluster to backprocessing
        // return variables; // return an array, flat map will flatten it

        return { _id: clusterObj._id, name: clusterObj.name };
      });

    // the user selected these variable ids directly

    const uniqueIdsToBackProcess = [
      ...new Set([
        ...variableIdsFromGroups,
        ...variableIdsFromTrees,
        ...selectedState.selectedVars,
        // ...variableIdsToBackProcessFromClusters,
      ]),
    ];

    if (
      uniqueIdsToBackProcess.length === 0 &&
      variableIdsToBackProcessFromClusters.length === 0
    ) {
      addToast({
        title: "There is nothing to submit",
        variant: "danger",
      });
      return;
    }

    if (variableIdsToBackProcessFromClusters.length === 0) {
      const payload = {
        variableIds: uniqueIdsToBackProcess,
        start: YYYY_MM_DD.parse(moment(selectedDate).format(DATE_FORMAT)),
        end: YYYY_MM_DD.parse(moment(yesterdayDate).format(DATE_FORMAT)),
      };

      backprocessMutation.mutate(payload);
    } else {
      clusterBackprocessMutation.mutate(variableIdsToBackProcessFromClusters);
    }
  };

  const resetDRI = async () => {
    if (!selectedState.selectedGroupIds.length) {
      addToast({
        title: "No groups are selected",
        variant: "danger",
      });
      return;
    }

    deleteRisksMutation.mutate(selectedState.selectedGroupIds);
  };

  return (
    <UserPermissionRoute rolenum={3}>
      <First>
        <MainLoader match={initialLoading && !pageError} />
        <PageError
          match={!!pageError}
          message="An error has occured. Please refresh the page."
        />

        <Fragment match={!initialLoading && !pageError}>
          <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
            <div className="flex items-center mb-2 sm:mb-0">
              <span className="text-[2rem] sm:text-[1.75rem] mr-2">
                {PAGE_NAME}
              </span>
            </div>
          </div>
          <div className="border-1 border border-bordgrey bg-white rounded-md my-6 shadow-md">
            <div className="flex w-full">
              {_.map(TABS, (name) => {
                const active = name === currentTab;
                return (
                  <div
                    key={name}
                    className={cn(
                      `grow text-[13px] text-center py-1 cursor-pointer`,
                      {
                        "border-indigo-800 text-indigo-800 border-b-[3px]":
                          active,
                        "border-slate-200 border-b-[1.2px]": !active,
                      }
                    )}
                    onClick={() => setTab(name)}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
            <div className="p-3">
              {groupsQuery.data && (
                <MultiSelectWithLabel
                  disabled={
                    deleteRisksMutation.isLoading ||
                    backprocessMutation.isLoading
                  }
                  data={groupsQuery.data.map((c) => ({
                    value: c._id,
                    label: c.name,
                  }))}
                  onChange={(allIds) =>
                    setSelectedState((curr) => ({
                      ...curr,
                      selectedGroupIds: allIds,
                    }))
                  }
                  value={selectedState.selectedGroupIds}
                  searchable
                  label="Groups"
                />
              )}
              {currentTab === TABS.BACKPROC && (
                <>
                  {clusters.data && (
                    <MultiSelectWithLabel
                      disabled={backprocessMutation.isLoading}
                      data={clusters.data.map((c) => ({
                        value: c._id,
                        label: c.name,
                      }))}
                      onChange={(allIds) =>
                        setSelectedState((curr) => ({
                          ...curr,
                          selectedClusters: allIds,
                        }))
                      }
                      value={selectedState.selectedClusters}
                      searchable
                      label="Clusters"
                    />
                  )}
                  {variablesQuery.data && (
                    <MultiSelectWithLabel
                      disabled={backprocessMutation.isLoading}
                      data={variablesQuery.data
                        .filter((c) => !c.deleted)
                        .map((c) => ({
                          value: c._id,
                          label: c.nameWithDescription,
                        }))}
                      onChange={(allIds) =>
                        setSelectedState((curr) => ({
                          ...curr,
                          selectedVars: allIds,
                        }))
                      }
                      value={selectedState.selectedVars}
                      searchable
                      label="Variables"
                    />
                  )}
                  {faultTreesQuery.data && (
                    <MultiSelectWithLabel
                      disabled={backprocessMutation.isLoading}
                      data={faultTreesQuery.data.map((ft) => ({
                        value: ft._id,
                        label: ft.name,
                      }))}
                      onChange={(allIds) =>
                        setSelectedState((curr) => ({
                          ...curr,
                          selectedFts: allIds,
                        }))
                      }
                      value={selectedState.selectedFts}
                      searchable
                      label="Fault Trees"
                    />
                  )}
                  <SingleDaySelectWithArrows
                    disabled={backprocessMutation.isLoading}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    close={{
                      className: "btn-outline float-right",
                      size: "xs",
                    }}
                    closeOnChange
                  />
                </>
              )}

              <div className="flex justify-end mt-4">
                {currentTab === TABS.RISKS && (
                  <Button
                    size="xs"
                    className="btn-error"
                    onClick={resetDRI}
                    disabled={deleteRisksMutation.isLoading}
                  >
                    Reset DRI
                  </Button>
                )}
                {currentTab === TABS.BACKPROC && (
                  <Button
                    size="xs"
                    className="btn-outline btn-success"
                    onClick={submitBackprocess}
                    disabled={backprocessMutation.isLoading}
                  >
                    submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      </First>
    </UserPermissionRoute>
  );
}

export default BackProcessManager;
