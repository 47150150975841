import { z } from "zod";

export enum AnomalyLevelEnum {
  "No Risk",
  "Low Risk",
  "Medium Risk",
  "High Risk",
}

export const AnomalyValidator = z.nativeEnum(AnomalyLevelEnum);

export const ANOMALY_LEVELS_DESC = Object.values(AnomalyLevelEnum).filter(
  (v) => typeof v !== "string"
) as AnomalyLevelEnum[]; // we just want the numbers [0, ..., 3]

ANOMALY_LEVELS_DESC.sort((a, b) => b - a); // sort in desc order

export const ANOMALY_LEVELS_ASC = [...ANOMALY_LEVELS_DESC].reverse(); // sort in asc order

export const MIN_ANOMALY_LEVEL = AnomalyValidator.parse(
  ANOMALY_LEVELS_DESC.at(-1)
);
export const MAX_ANOMALY_LEVEL = AnomalyValidator.parse(ANOMALY_LEVELS_DESC[0]);

export const BRIValidator = z
  .number()
  .min(MIN_ANOMALY_LEVEL)
  .max(MAX_ANOMALY_LEVEL);
