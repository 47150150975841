import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../frontend/popover";
import { Label } from "../../frontend/label";
import { Atoms, type SelectedVariable } from "../svv-store/use-svv-store";
import { Button } from "../../frontend/button";
import { FaCut } from "react-icons/fa";
import { ComponentProps, type ReactNode, useEffect, useState } from "react";
import {
  useGetUseMinMaxClampsStores,
  UseMinMaxClampsStoresContext,
} from "./clamp-popover-store";
import { ClampInputToggle } from "./clamp-input-toggle";
import { PRECISION } from "./utils";
import { YAxisMode } from "../../time-series/types";
import { cn, PropsWithCn } from "../../frontend/cn";
import { atom, useAtomValue, useSetAtom, useStore } from "jotai";
import { iife } from "../../lib/utils";

type Props = {
  batchVariable: SelectedVariable;
} & PropsWithCn<Pick<ComponentProps<typeof Button>, "size">>;

const globalRangeForBvAtom = atom((get) => {
  return (bv: string) => get(Atoms.globalRangeAtom)?.perBv[bv];
});

const minAutoValueAtom = atom((get) => {
  return (bv: string) => {
    return get(globalRangeForBvAtom)(bv)?.[0].toFixed(PRECISION) ?? "";
  };
});

const maxAutoValueAtom = atom((get) => {
  return (bv: string) => {
    return get(globalRangeForBvAtom)(bv)?.[1].toFixed(PRECISION) ?? "";
  };
});

const disabledAtom = atom((get) => {
  const globalRange = get(Atoms.globalRangeAtom);
  if (!globalRange) return () => false;
  const disabled = (bvString: string) => !(bvString in globalRange.perBv);
  return disabled;
});

function Internal({
  batchVariable: bvObject,
  className,
  size,
}: Props): ReactNode {
  const [open, setOpen] = useState(false);

  const batchVariableOrExpressionId = iife(() => {
    switch (bvObject.type) {
      case "expression":
        return bvObject.id;
      case "variable":
        return bvObject.bv;
      default:
        const _: never = bvObject;
        throw new Error("impossible");
    }
  });

  const disabled = useAtomValue(disabledAtom)(batchVariableOrExpressionId);
  const minAutoValue = useAtomValue(minAutoValueAtom)(
    batchVariableOrExpressionId
  );
  const maxAutoValue = useAtomValue(maxAutoValueAtom)(
    batchVariableOrExpressionId
  );

  const minIsError = minAutoValue === "";
  const maxIsError = maxAutoValue === "";
  const someError = minIsError || maxIsError;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={someError && !disabled ? "destructive" : "ghost"}
          size={size ?? "icon"}
          className={cn("disabled:cursor-not-allowed", className)}
          onClick={(e) => e.stopPropagation()}
        >
          <FaCut className="size-3" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto bg-white p-3 dark:bg-xslate-1"
        onClick={(e) => e.stopPropagation()}
      >
        <HasStoresInput
          shouldBeSyncing={open}
          batchVariable={bvObject}
          className={className}
          size={size}
        />
      </PopoverContent>
    </Popover>
  );
}

const HasStoresInput = ({
  batchVariable,
  shouldBeSyncing,
}: Props & {
  shouldBeSyncing: boolean;
}) => {
  const stores = useGetUseMinMaxClampsStores();
  if (!stores) throw new Error("should not be possible");

  const batchVariableOrExpressionId = iife(() => {
    switch (batchVariable.type) {
      case "expression":
        return batchVariable.id;
      case "variable":
        return batchVariable.bv;
      default:
        const _: never = batchVariable;
        throw new Error("impossible");
    }
  });
  const [minStore, maxStore] = stores;
  const minIsAuto = minStore((s) => s.auto);
  const maxIsAuto = maxStore((s) => s.auto);
  const minValue = minStore((s) => s.inputValue);
  const maxValue = maxStore((s) => s.inputValue);

  const minAutoValue = useAtomValue(minAutoValueAtom)(
    batchVariableOrExpressionId
  );
  const maxAutoValue = useAtomValue(maxAutoValueAtom)(
    batchVariableOrExpressionId
  );

  const s = useStore();

  const setYClampMin = useSetAtom(Atoms.setYClampMinAtom);
  const setYClampMax = useSetAtom(Atoms.setYClampMaxAtom);

  useEffect(
    function handleDataSync() {
      if (!shouldBeSyncing) return;

      if (minIsAuto) {
        /**
         * set an event listener to update the input value when the chart's
         * ranges update. Since we're in auto mode, we should do this.
         * */
        return s.sub(minAutoValueAtom, () => {
          const stringDisplayed = s.get(minAutoValueAtom)(
            batchVariableOrExpressionId
          );
          const { inputValue: value, setInputValue } = minStore.getState();
          if (value !== stringDisplayed) {
            setInputValue(stringDisplayed);
          }
        });
      }

      /**
       * Set an event listener on the input, so that when
       * the user changes the input, we send these values
       * to the SVV Store which will cause the chart to update
       * with new extrema.
       */
      return minStore.subscribe(
        (s) => s.inputValue,
        (inputValue) => {
          if (!inputValue.trim()) {
            setYClampMin(batchVariable);
            return;
          }

          const num = parseFloat(inputValue.trim());

          if (isNaN(num)) throw new Error("should not be possible??");

          setYClampMin(batchVariable, num);
        }
      );
    },
    [
      batchVariable,
      batchVariableOrExpressionId,
      minStore,
      minIsAuto,
      shouldBeSyncing,
      s,
      setYClampMin,
    ]
  );

  useEffect(
    function handleDataSync() {
      if (!shouldBeSyncing) return;

      if (maxIsAuto)
        return s.sub(maxAutoValueAtom, () => {
          const stringDisplayed = s.get(maxAutoValueAtom)(
            batchVariableOrExpressionId
          );
          const { inputValue: value, setInputValue } = maxStore.getState();
          if (value !== stringDisplayed) {
            setInputValue(stringDisplayed);
          }
        });

      return maxStore.subscribe(
        (s) => s.inputValue,
        (inputValue) => {
          if (!inputValue.trim()) {
            setYClampMax(batchVariable);
            return;
          }

          const num = parseFloat(inputValue.trim());

          if (isNaN(num)) throw new Error("should not be possible??");

          setYClampMax(batchVariable, num);
        }
      );
    },
    [
      batchVariable,
      batchVariableOrExpressionId,
      maxStore,
      maxIsAuto,
      shouldBeSyncing,
      s,
      setYClampMax,
    ]
  );

  return (
    <div className="flex gap-7">
      <div className="inline-flex flex-col justify-around">
        <Label htmlFor="height">Y-MAX</Label>
        <Label htmlFor="height">Y-MIN</Label>
      </div>
      <div className="inline-flex flex-col gap-2">
        <div className="inline-flex gap-2.5">
          <ClampInputToggle
            value={maxIsAuto ? maxAutoValue : maxValue}
            setValue={maxStore.getState().setInputValue}
            isAuto={maxIsAuto}
            setIsAuto={(newAutoValue) => {
              maxStore.getState().setAuto(newAutoValue.auto);

              if (newAutoValue.auto) {
                // immediately remove any user input
                setYClampMax(batchVariable, undefined);
                return;
              }

              if (!maxIsAuto) return; // very important

              const { startingValue } = newAutoValue;

              if (startingValue !== undefined) {
                setYClampMax(batchVariable, startingValue.parsed);
                maxStore.getState().setInputValue(startingValue.asInputted);
                return;
              }

              // no starting value provided, we need to get it from the chart
              // Immediately get the extrema from the chart
              const v = s.get(Atoms.globalRangeAtom)?.perBv[
                batchVariableOrExpressionId
              ]?.[1];
              // Then make it seem like the user inputted this value
              v !== undefined && setYClampMax(batchVariable, v);
              // Then apply this value to the input
              maxStore.getState().setInputValue(v?.toFixed(PRECISION) ?? "");
            }}
            isError={maxAutoValue === ""}
          />
        </div>
        <div className="inline-flex gap-2.5">
          <ClampInputToggle
            value={minIsAuto ? minAutoValue : minValue}
            setValue={minStore.getState().setInputValue}
            isAuto={minIsAuto}
            setIsAuto={(newAutoValue) => {
              // tell the minStore the new auto value because the checkbox was clicked/changed
              minStore.getState().setAuto(newAutoValue.auto);

              if (newAutoValue.auto) {
                // immediately remove any user input because it is auto
                setYClampMin(batchVariable, undefined);
                return;
              }

              // we are not auto

              /**
               * Only run the below code if `!newAutoValue.auto && minIsAuto`
               *
               * That is, this actions is "switching from auto to manual mode"
               */
              if (!minIsAuto) return; // very important

              const { startingValue } = newAutoValue; // the user may have initiated manual mode by changing the input, so set this value as the new clamp value

              if (startingValue !== undefined) {
                // valid user value
                setYClampMin(batchVariable, startingValue.parsed); // tell the chart to clamp with this value
                minStore.getState().setInputValue(startingValue.asInputted); // tell the input's store to display this value
                return;
              }

              // invalid user value, get the value from the chart instead
              const v = s.get(Atoms.globalRangeAtom)?.perBv[
                batchVariableOrExpressionId // ask the chart for the min value visible in the chart
              ]?.[0];
              // Then make it seem like the user inputted this value
              v !== undefined && setYClampMin(batchVariable, v);
              minStore.getState().setInputValue(v?.toFixed(PRECISION) ?? ""); // tell the input's store to display this value
            }}
            isError={minAutoValue === ""}
          />
        </div>
      </div>
    </div>
  );
};

// const initRelativeYClampStoresForBvAtom = atom(
//   null,
//   (
//     get,
//     set,
//     bv: SelectedVariable,
//     initialAutos: [boolean | undefined, boolean | undefined],
//     initialValues: [number | undefined, number | undefined],
//     globalRange: GlobalRange | undefined
//   ) => {
//     const selectedVariables = get(Atoms.selectedVariablesAtom);
//     const single = get(Atoms.singleVariableViewAtom);
//     if (single) throw new Error("impossible");

//     const out = selectedVariables.map((x) =>
//       x === bv
//         ? {
//             ...x,
//             clampStores: initClampStoresForNewBatchVariable(
//               iife(() => {
//                 switch (x.type) {
//                   case "variable":
//                     return x.bv;
//                   case "expression":
//                     return x.id;
//                   default:
//                     const _: never = x;
//                     throw new Error("impossible");
//                 }
//               }),
//               initialAutos,
//               initialValues,
//               globalRange
//             ),
//           }
//         : x
//     ) as typeof selectedVariables;

//     set(Atoms.selectedVariablesAtom, out);
//   }
// );

// const NoStoresYet: typeof Internal = (props) => {
//   const { batchVariable } = props;

//   if (batchVariable.clampStores)
//     throw new Error("only render when not init yet");

//   const batchVariableOrExpressionId = iife(() => {
//     switch (batchVariable.type) {
//       case "expression":
//         return batchVariable.id;
//       case "variable":
//         return batchVariable.bv;
//       default:
//         const _: never = batchVariable;
//         throw new Error("impossible");
//     }
//   });

//   const globalRange = useAtomValue(Atoms.globalRangeAtom);
//   const range = useAtomValue(globalRangeForBvAtom)(batchVariableOrExpressionId);
//   const min = range?.[0].toFixed(PRECISION) ?? "";
//   const max = range?.[1].toFixed(PRECISION) ?? "";

//   const single = useAtomValue(Atoms.singleVariableViewAtom);
//   const initRelativeYClampStoresForBv = useSetAtom(
//     initRelativeYClampStoresForBvAtom
//   );

//   if (single) throw new Error("should not render");

//   return (
//     <div className="flex gap-7">
//       <div className="inline-flex flex-col justify-around">
//         <Label htmlFor="height">Y-MAX</Label>
//         <Label htmlFor="height">Y-MIN</Label>
//       </div>
//       <div className="inline-flex flex-col gap-2">
//         <div className="inline-flex gap-2.5">
//           <ClampInputToggle
//             value={max}
//             isAuto
//             setIsAuto={(auto) => {
//               if (auto.auto) throw new Error("impossible");

//               const { startingValue } = auto;
//               initRelativeYClampStoresForBv(
//                 batchVariable,
//                 [true, false],
//                 [undefined, startingValue],
//                 globalRange
//               );
//             }}
//             isError={false}
//           />
//         </div>
//         <div className="inline-flex gap-2.5">
//           <ClampInputToggle
//             value={min}
//             isAuto
//             setIsAuto={(auto) => {
//               if (auto.auto) throw new Error("impossible");

//               const { startingValue } = auto;

//               initRelativeYClampStoresForBv(
//                 batchVariable,
//                 [false, true],
//                 [startingValue, undefined],
//                 globalRange
//               );
//             }}
//             isError={false}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const RelativeClampsPopover: typeof Internal = (props) => {
  const single = useAtomValue(Atoms.singleVariableViewAtom);
  const yAxisMode = useAtomValue(Atoms.yAxisModeAtom);
  if (single) return;
  const show =
    YAxisMode.Relative === yAxisMode || YAxisMode.Swimlane === yAxisMode;

  if (!show) return;

  return (
    <UseMinMaxClampsStoresContext.Provider
      value={props.batchVariable.clampStores}
    >
      <Internal {...props} />
    </UseMinMaxClampsStoresContext.Provider>
  );
};

export { RelativeClampsPopover };
