import { useParams } from "react-router";
import { useConfigRequired } from "./config/useConfigStore";
import { z } from "zod";

export default function useBaseUrl(withSlash = true): string | undefined {
  const Config = useConfigRequired()!;

  const { unit } = useParams();

  if (!unit) {
    return undefined;
  }

  const units: { url: string }[] = Config.units;

  const configObject = units.find(
    (u) => u.url.replace("/", "").toLowerCase() === unit.toLowerCase()
  );

  const out = configObject?.url;
  return out ? (withSlash ? out : out.replace("/", "")) : undefined;
}

export function useBaseUrlExperimental() {
  const Config = useConfigRequired()!;

  if (!Config) throw new Error("config not initialized");

  const params = useParams();

  const parsed = z.string().safeParse(params.unit);
  if (!parsed.success)
    throw new Error(
      "unit not found in URL. only use this hook on pages that have a unit in the URL"
    );

  const unit = parsed.data;

  if (unit.length === 0) throw new Error("how is this possible");

  const configObject = Config.units.find(
    (u) => u.url.replace("/", "").toLowerCase() === unit.toLowerCase()
  );

  if (!configObject)
    throw new Error(`no unit found in config with the url /${unit}`);

  const urlOut = configObject.url;
  if (!z.string().startsWith("/").safeParse(urlOut).success)
    throw new Error("url in config should start with /");
  return urlOut as `/${string}`;
}

export type BaseUrl = ReturnType<typeof useBaseUrlExperimental>;

export function validateBaseUrl(v: string) {
  const parsed = z.string().startsWith("/").safeParse(v);

  if (parsed.success) {
    return parsed.data as BaseUrl;
  }
  throw new Error(v + " is not a valid base url");
}
