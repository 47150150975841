/**
 * cssLabels match those in FaultTreeOverviewCard.scss
 */
const LEGEND_ITEMS = [
  {
    label: "Active",
    cssLabel: "Active",
  },
  {
    label: "Inactive",
    cssLabel: "Inactive",
  },
  {
    label: "Active (Shutdown)",
    cssLabel: "ActiveShutdown",
  },
  {
    label: "Inactive (Shutdown)",
    cssLabel: "InactiveShutdown",
  },
  { label: "Processing", cssLabel: "Processing" },
];

export default function FaultTreeLegend() {
  return (
    <div className="grid grid-cols-3 gap-x-2">
      {LEGEND_ITEMS.map(({ label, cssLabel }) => (
        <div className="flex gap-2" key={label}>
          <div className={`rounded-md h-4 w-10 SeriesContainer_${cssLabel}`} />
          <span className="text-[0.8rem]">{label}</span>
        </div>
      ))}
    </div>
  );
}
