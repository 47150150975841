import React, { useRef, useState } from "react";
import { useModal } from "../../common/useModal";
import { Link } from "react-router-dom";
import useAPI from "../../../lib/useAPI";
import { useLocation } from "react-router-dom";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import { Modal, Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import classNames from "classnames";
import { Badge } from "../../ui/badge";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

function FaultTreeManagerCard({
  addTreeToState,
  setAPILoadingMessage,
  tree,
  deleteTree,
  togglePublished,
}) {
  const [showCopyModal, setshowCopyModal] = useState(false);
  const [showPublishingModal, setShowPublishingModal] = useState(false);

  const api = useAPI();
  const MODAL = useModal();
  const user = useAuthStore((s) => s.user);
  const textInput = useRef(null);

  const numberOfNodes = tree.nodeCount || 0;
  const nodeCountString = `${numberOfNodes} node${
    numberOfNodes !== 1 ? "s" : ""
  }`;

  const loc = useLocation();
  const pathname = loc.pathname;

  return (
    <div className="p-1 pl-3 mt-2.5 border border-solid border-[#dbdbdb] bg-white rounded-xl drop-shadow-sm z-0">
      {/* name and description  */}
      <div className="text-[#000000] flex items-center justify-between text-[1.05em] py-[0.15em] px-[0.5em] rounded-t-xl">
        <div className="py-[0.4em] w-1/2 sm:w-auto sm:block flex flex-col">
          <span className="font-semibold px-0 my-[0.2em] mx-0">
            {tree.name}
          </span>
          <Badge variant={"amber"} className="ml-3">
            {nodeCountString}
          </Badge>
        </div>

        {/* edit and delete buttons  */}
        <div>
          {tree.published == null ? null : (
            <>
              <Tooltip
                label={`Click to ${tree.published ? "un" : ""}publish`}
                disabled={!user.hasEditPermission}
              >
                <Button
                  size="xs"
                  className={classNames({ "btn-success": tree.published })}
                  onClick={() =>
                    user.hasEditPermission &&
                    (tree.published
                      ? togglePublished()
                      : setShowPublishingModal(true))
                  }
                >
                  {tree.published ? "Published" : "Unpublished"}
                </Button>
              </Tooltip>
              <Modal
                classNames={{ modal: "rounded-xl" }}
                size="35%"
                opened={showPublishingModal}
                centered={true}
                onClose={() => {
                  setShowPublishingModal(false);
                }}
                title={`Are you sure you want to publish ${tree.name}? Processing will take some time.`}
              >
                <form
                  className="flex"
                  onSubmit={(e) => {
                    e.preventDefault();

                    setAPILoadingMessage(`Publishing ${tree.name}`);
                    setShowPublishingModal(false);

                    togglePublished()
                      .then(() => addSuccessToast(`${tree.name} published`))
                      .catch((e) => addUnknownErrorToast(e))
                      .finally(() => setAPILoadingMessage(null));
                  }}
                >
                  <Button type="submit" className="ml-auto">
                    Publish
                  </Button>
                </form>
              </Modal>
            </>
          )}

          {/* this modal won't be rendered until showCopyModal is true */}
          {user.hasEditPermission ? (
            <>
              <Modal
                classNames={{ modal: "rounded-xl" }}
                size="35%"
                opened={showCopyModal}
                centered={true}
                onClose={() => {
                  setshowCopyModal(false);
                  textInput.current.value = ""; // reset the form
                }}
                title={`Enter a new name for the copy of ${tree.name}`}
              >
                <form
                  className="input-group"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setAPILoadingMessage("Copying fault tree...");
                    api
                      .copyFaultTree(tree._id, {
                        name: textInput.current.value,
                      })
                      .then((res) => {
                        addTreeToState(res); // update the UI to show another card
                        addSuccessToast("Successfully copied fault tree");
                        setshowCopyModal(false);
                      })
                      .catch((e) => addUnknownErrorToast(e))
                      .finally(() => setAPILoadingMessage(null));
                  }}
                >
                  <input
                    type="text"
                    ref={textInput}
                    required={true}
                    placeholder="New fault tree name"
                    className="input input-bordered w-full input-sm"
                  />
                  <Button type="submit">Create</Button>
                </form>
              </Modal>
              <Tooltip label="Copy" withArrow>
                <Button
                  className="ml-auto btn-ghost"
                  icon="copy"
                  onClick={() => setshowCopyModal(true)}
                />
              </Tooltip>
            </>
          ) : null}

          {!tree.published && user.hasEditPermission && (
            <Tooltip label="Delete" withArrow>
              <Button
                className="ml-auto btn-ghost"
                icon="trash-o"
                onClick={() => {
                  MODAL.openModal({
                    title: `Are you sure you want to delete Fault Tree: ${tree.name}?`,
                    buttons: [
                      {
                        type: "button",
                        label: "DELETE",
                        className: "btn-error",
                        onClick: () => {
                          deleteTree();
                          MODAL.closeModal();
                        },
                      },
                    ],
                    closeLabel: "CANCEL",
                  });
                }}
              />
            </Tooltip>
          )}
          {/* mark where we come from because another page links to the edit page as well */}
          {user.hasEditPermission ? (
            <Link to={`../../ft/edit/${tree._id}`} state={{ from: pathname }}>
              <Tooltip label="Edit" withArrow>
                <Button icon="pencil" className="btn-ghost" />
              </Tooltip>
            </Link>
          ) : (
            <Link to={`../../ft/edit/${tree._id}`} state={{ from: pathname }}>
              <Tooltip label="View Tree" withArrow>
                <Button icon="arrow-circle-right" className="btn-ghost" />
              </Tooltip>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default FaultTreeManagerCard;
