import { clsx, type ClassValue } from "clsx";
import { type PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithCn<T = {}> = {
  className?: string;
} & T;

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithCnAndChildren<T = {}> = PropsWithCn & PropsWithChildren<T>;
