import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { useConfigRequired } from "../../zustand/config/useConfigStore";
import NavigateToLogin from "./NavigateToLogin";

/**
 * if the user accesses a link with a
 */
function useNavigateUponInvalidUnitPrefixInUrl() {
  const Config = useConfigRequired();
  const { unit } = useParams();
  const Nav = useNavigate();

  useEffect(() => {
    if (!unit) {
      return;
    }

    if (
      !Config.units.find(
        (u) => u.url.replace("/", "").toLowerCase() === unit.toLowerCase()
      )
    ) {
      Nav("/", { replace: true });
    }
  }, [unit, Config]);
}

/**
 * This private route wraps the entire /:unit route
 *
 * so we can assume whenever this component is rendered,
 * a logged in page is shown.
 */
const LoggedInRoute = () => {
  const isAuthed = useAuthStore((s) => s.authed);
  useNavigateUponInvalidUnitPrefixInUrl();

  return isAuthed ? <Outlet /> : <NavigateToLogin />;
};

export default LoggedInRoute;
