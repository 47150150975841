import * as React from "react";
import * as Toast from "@radix-ui/react-toast";
import { toastVariant } from "./toast-variants";
import { removeToast, useToastStore } from "./use-toast-store";
import { FaCheck } from "react-icons/fa";
import { GrClose } from "react-icons/gr";

export const AllToasts: React.FC = () => {
  const toasts = useToastStore((s) => s.toasts);

  const getIcon = (t: (typeof toasts)[number]) => {
    if (t.variant === "success") return FaCheck;
    if (t.variant === "danger") return GrClose;

    return null;
  };

  return (
    <>
      {toasts.map((t) => {
        const Icon = getIcon(t);
        return (
          <Toast.Root
            key={t.id}
            className={toastVariant({ variant: t.variant })}
            onOpenChange={(bool) => {
              if (!bool) removeToast(t);
            }}
          >
            <Toast.Title className="text-lg font-bold flex items-center">
              {Icon && <Icon className="mr-2 h-4 w-4" />}
              {t.title}
            </Toast.Title>
            {t.description && (
              <Toast.Description>{t.description}</Toast.Description>
            )}
          </Toast.Root>
        );
      })}
    </>
  );
};
