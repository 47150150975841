import { useVariablesMappedByIdQuery } from "../../../hooks/tanstack-query";
import { cn } from "../../../lib/utils";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "../variability-drawer";
import { PropsWithCn } from "../../types/component.types";
import { ellipsify } from "../../utils/stylable";
import { NUMBER_FORMATTERS } from "../../charts/constants/dynamicTrendChartConstants";
import { useVariabilityDataQuery } from "../use-variability-query";
import { useVariabilityTooltipStore } from "./use-variability-tooltip-store";
import { uniq } from "remeda";
import { Loader2 } from "lucide-react";
import { useCapabilityStore } from "../capability-store";
import { DateTime } from "luxon";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import {
  ABBREVIATED_MONTH_DATE,
  LONG,
  TWELVE_HR_TIME,
} from "../../../shared-ui/lib/luxon-format-tokens";
import { createPortal } from "react-dom";

function VariabilityTooltip() {
  const groups = useMin1Groups();
  const hoverData = useVariabilityTooltipStore((s) => s.data);
  const useStore = useGetUseVariabilityDrawerStore();

  const groupFound = groups.find((g) =>
    g.variables.find((v) => v.id === hoverData?.variableId)
  );

  const variableFound = groupFound?.variables.find(
    (v) => v.id === hoverData?.variableId
  );

  const showLimitStats = useStore((s) => {
    const gs = s.groups;
    if (!gs) throw new Error("No groups");

    return (
      uniq(gs.flatMap((g) => g.variables.map((v) => v._id))).length === 1 &&
      s.showLimitLines
    );
  });

  if (!hoverData || !groupFound || !variableFound) return null;

  const graphRect = hoverData.graph.getBoundingClientRect();

  const estimatedStartingHeight = 250;
  const yOffset = 30; // initial height without capability or limits stats, will need to revisit later

  // TODO: positioning will have to change when we put back capability data and limits into the tooltip
  const out = (
    <div
      style={{
        left: `${hoverData.windowX}px`,
        top: `${Math.max(0, graphRect.top - (estimatedStartingHeight - yOffset))}px`,
        transform: "translateX(-50%)",
      }}
      className={`max-w-[500px] min-w-[260px] min-h-[${estimatedStartingHeight}px] fixed flex flex-col rounded-md border p-3 text-sm border-xslate-7 bg-popover text-gray-900 shadow-lg tooltip-container pointer-events-none z-50`}
      id={`tooltip-${groupFound.id}`}
    >
      <VariabilityStats
        showLimitStats={showLimitStats}
        start={groupFound.start}
        end={groupFound.end}
        variableId={variableFound._id}
        excludedModes={variableFound.excludedModesMap}
        color={variableFound.color}
        includeHeader
      />
    </div>
  );

  // return out;

  return createPortal(out, document.body);
}

const formatNumber = (val: number) => NUMBER_FORMATTERS.base(val, 4);

export function VariabilityStats({
  excludedModes,
  variableId,
  end,
  start,
  color,
  showLimitStats,
  includeHeader,
}: {
  includeHeader?: true;
  start: number;
  end: number;
  variableId: string;
  excludedModes: Record<string, boolean>;
  color?: string;
  showLimitStats?: boolean;
}) {
  const variablesMapQuery = useVariablesMappedByIdQuery();

  const variabilityQuery = useVariabilityDataQuery(
    variableId,
    new Date(start),
    new Date(end),
    Object.fromEntries(
      Object.entries(excludedModes).filter(([k, bool]) => !!bool) as [
        string,
        true,
      ][]
    )
  );
  const stats = variabilityQuery.data?.stats;
  const variable = variablesMapQuery.data?.[variableId];

  const capabilityStats = useCapabilityStore((state) =>
    state.getData(variableId)
  );

  const zone = useTimezone();

  const getDateLabel = (range: {
    end: number;
    start: number;
  }): {
    start: string;
    end: string;
    label: string;
    full?: string;
  } => {
    const { end, start } = range;
    const startDate = DateTime.fromMillis(start, { zone });
    const endDate = DateTime.fromMillis(end, { zone });

    const isDefaultTime = () => {
      const startTime = startDate.toFormat(TWELVE_HR_TIME);
      const endTime = endDate.toFormat(TWELVE_HR_TIME);

      return startTime === "12:00 AM" && endTime === "11:59 PM";
    };

    const shortFormat = ABBREVIATED_MONTH_DATE;
    const fullFormat = LONG;

    const fullStartLabel = startDate.toFormat(fullFormat);
    const fullEndLabel = endDate.toFormat(fullFormat);

    const full = `${fullStartLabel}  —  ${fullEndLabel}`;

    if (isDefaultTime()) {
      const startLabel = startDate.toFormat(shortFormat);
      const endLabel = endDate.toFormat(shortFormat);
      return {
        start: startLabel,
        end: endLabel,
        label: `${startLabel}  —  ${endLabel}`,
        full,
      };
    }

    return {
      start: fullStartLabel,
      end: fullEndLabel,
      label: full,
    };
  };

  const dateLabel = getDateLabel({ start, end });

  return (
    <>
      {includeHeader && (
        <>
          <div className="flex items-center pb-2">
            {variable && (
              <>
                {/* tooltip color needs to be dynamically set when hover variable changes */}
                {color && (
                  <span
                    id="var-tooltip-color"
                    className={`rounded-full h-4 w-4`}
                    style={{ background: color }}
                  />
                )}
                <span className="ml-2 text-md leading-2 mr-5 font-semibold">
                  {ellipsify(variable.trimmedName, 40)}
                </span>
              </>
            )}

            {/* <div className="ml-auto h-[10px] w-[10px] rounded-full bg-xgreen-6" /> */}
            {/* <span className="ml-2 font-medium">0.15</span> */}
          </div>
          <span className="mx-auto whitespace-nowrap text-center">
            {dateLabel.label}
          </span>
          <Separator className="my-1.5" />
        </>
      )}
      {stats ? (
        <>
          <TooltipDataLine value={formatNumber(stats.mean)} label="Mean" />
          <TooltipDataLine
            value={formatNumber(stats.std)}
            label="Standard Deviation"
          />
          <TooltipDataLine value={formatNumber(stats.max)} label="Maximum" />
          <TooltipDataLine value={formatNumber(stats.min)} label="Minimum" />
          <TooltipDataLine
            value={formatNumber(stats.q1)}
            label="Q1 (25th %ile)"
          />
          <TooltipDataLine
            value={formatNumber(stats.median)}
            label="Median (50th %ile)"
          />
          <TooltipDataLine
            value={formatNumber(stats.q3)}
            label="Q3 (75th %ile)"
          />
          <TooltipDataLine
            value={formatNumber(stats.gradient.m)}
            label="Gradient (Best Fit Line)"
          />

          {showLimitStats && stats.limits && stats.limits.length > 0 && (
            <>
              <Separator className="my-2" />
              {stats.limits.map((l) => {
                const symbol = l.level.startsWith("high") ? ">=" : "<=";

                const label = `Data ${symbol} ${l.level.toUpperCase()}`;

                return (
                  <TooltipDataLine
                    key={l.level}
                    value={`${l.percent}%`}
                    label={label}
                  />
                );
              })}
            </>
          )}

          {capabilityStats &&
            (capabilityStats.Cp ||
              capabilityStats.Cpk ||
              capabilityStats.CCpk) && (
              <>
                <Separator className="my-2" />
                {capabilityStats.Cp && (
                  <TooltipDataLine
                    label="Cp"
                    value={formatNumber(capabilityStats.Cp)}
                  />
                )}
                {capabilityStats.Cpk && (
                  <TooltipDataLine
                    label="Cpk"
                    value={formatNumber(capabilityStats.Cpk)}
                  />
                )}
                {capabilityStats.CCpk && (
                  <TooltipDataLine
                    label="CCpk"
                    value={formatNumber(capabilityStats.CCpk)}
                  />
                )}
                {capabilityStats.Cpm && (
                  <TooltipDataLine
                    label="Cpm"
                    value={formatNumber(capabilityStats.Cpm)}
                  />
                )}
              </>
            )}
        </>
      ) : (
        <div className="flex justify-center py-3">
          <Loader2 className="h-7 w-7 animate-spin-slow" />
        </div>
      )}
    </>
  );
}

export function Separator({ className }: PropsWithCn) {
  return <div className={cn("w-full h-[0.5px] bg-gray-900", className)}></div>;
}

function TooltipDataLine({
  label,
  className,
  value,
}: PropsWithCn & { label: string; value: string }) {
  return (
    <div className={cn("flex justify-between text-sm gap-8", className)}>
      <span className="whitespace-nowrap text-xslate-11">{label}</span>
      <span className="whitespace-nowrap text-xslate-12">{value}</span>
    </div>
  );
}

export { VariabilityTooltip };
