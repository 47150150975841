import * as React from "react";
import { create } from "zustand";
import { AnomalyLevelEnum } from "../../../../types/api/Anomaly";

type CheckedMap = Partial<{ [TKey in AnomalyLevelEnum]: boolean }>;

type AckManStore = {
  checkedMap: CheckedMap;
  setChecked: (level: keyof CheckedMap, checked: boolean) => void;

  onlyActiveFaultTreeNodes: boolean;
  setOnlyActiveFaultTreeNodes: (type: boolean) => void;

  selectedGroupId: string | undefined;
  setGroup: (id: string) => void;

  fresh: boolean;
  setFresh: (type: boolean) => void;

  date: Date | undefined;
  setDate: (date: Date) => void;
};

function createUseAckManStore() {
  const useAckManStore = create<AckManStore>()((set) => {
    return {
      checkedMap: {
        "3": true, // default only show level 3s
        "2": false,
        "1": false,
      },

      setChecked: (k, checked) =>
        set((s) => ({
          checkedMap: {
            ...s.checkedMap,
            [k]: checked,
          },
          onlyActiveFaultTreeNodes: false, // reset this when toggling a level
        })),

      selectedGroupId: undefined,
      setGroup: (id) => set({ selectedGroupId: id }),

      fresh: false,
      setFresh: (isOn) => {
        set((curr) => ({
          fresh: isOn,
          onlyActiveFaultTreeNodes: isOn
            ? false
            : curr.onlyActiveFaultTreeNodes,
        }));
      },

      date: undefined,
      setDate: (date) => set({ date }),

      onlyActiveFaultTreeNodes: false,
      setOnlyActiveFaultTreeNodes: (isOn) =>
        set((curr) => ({
          onlyActiveFaultTreeNodes: isOn,
          checkedMap: isOn ? {} : curr.checkedMap,
          fresh: isOn ? false : curr.fresh,
        })),
    };
  });
  return useAckManStore;
}

type UseAckManStore = ReturnType<typeof createUseAckManStore>;

const AckManStoreContext = React.createContext<UseAckManStore | undefined>(
  undefined
);

export function useGetUseAckManStore() {
  const ctx = React.useContext(AckManStoreContext);
  if (!ctx) {
    throw new Error("useAckManStore must be used within a AckManStoreContext");
  }
  return ctx;
}

export function AckManStoreProvider({ children }: React.PropsWithChildren) {
  const storeRef = React.useRef<UseAckManStore>();
  const store = storeRef.current ?? (storeRef.current = createUseAckManStore());

  return (
    <AckManStoreContext.Provider value={store}>
      {children}
    </AckManStoreContext.Provider>
  );
}
