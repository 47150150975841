import { variableSchema } from "../../../lib/api-validators";
import { PropsWithChildren, useRef, useState } from "react";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { Tag } from "../../../lib/api-schema/tag";
import { TagCardContext, useTagCardContext } from "./tag-card-context";
import { TagCardHeader } from "./tag-card-header";
import { EditTagForm } from "./edit-tag-form";
import { TagData } from "./tag-data";

type Props = {
  tag: variableSchema;
  onUpdate?: (tag: Tag) => void;
  onDelete?: () => void;
};

function TagCardInternal({ tag, onUpdate, onDelete }: Props) {
  const currentUnit = useCurrentUnitObject();
  if (!currentUnit) throw new Error("No current unit");

  const { editing, setEditing, open } = useTagCardContext();

  return (
    <div className="border border-bordgrey2 bg-base-100 mt-6 overflow-visible pb-0 rounded-xl">
      <TagCardHeader onDelete={onDelete} />
      {/* the overflow below is to make the groups selection visible  */}
      {open && (
        <div className="animate-in slide-in-from-bottom-5">
          {editing ? (
            <EditTagForm
              onClose={() => setEditing(false)}
              onSuccess={(_newTag) => {
                setEditing(false);
                onUpdate?.(_newTag);
              }}
              tag={tag}
            />
          ) : (
            <TagData tag={tag} />
          )}
        </div>
      )}
    </div>
  );
}

function TagCard({ children, ...rest }: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const hasOpenedOnce = useRef(false);

  if (open) {
    hasOpenedOnce.current = true;
  }

  return (
    <TagCardContext.Provider
      value={{
        editing,
        setEditing,
        open,
        setOpen,
        tag: rest.tag,
        hasOpenedOnce: hasOpenedOnce.current,
      }}
    >
      <TagCardInternal {...rest} />
    </TagCardContext.Provider>
  );
}

export { TagCard };
