import { z } from "zod";
import * as R from "remeda";
import {
  GET,
  PATCH,
  POST,
  DELETE,
  DEFAULT_HEADERS_PICK,
} from "./fetcher-experimental";
import moment from "moment";
import {
  groupSchema,
  riskSchema,
  variableSchema,
} from "../../lib/api-validators";
import { YYYY_MM_DD } from "../../lib/validators";
import {
  acknowledgementSchema,
  acknowledgementType,
} from "../../lib/api-schema/acknowledgment";
import { anomalySummarySchema } from "../../lib/api-schema/anomaly-summary";
import { faultTreeResolutionDateSchema } from "../../lib/api-schema/ft/fault-tree-resolution-date";
import { faultTreeNodeStatusSeriesSchema } from "../../lib/api-schema/ft/fault-tree-node-status-series";
import { faultTreeSchema } from "../../lib/api-schema/ft/fault-tree";
import { faultTreeNodeSchema } from "../../lib/api-schema/ft/fault-tree-node";
import { faultTreeNodeStatusSchema } from "../../lib/api-schema/ft/fault-tree-node-status";
import { ftStatusSchema } from "../../lib/api-schema/ft/fault-tree-status";
import { faultTreeChartSchema } from "../../lib/api-schema/ft/fault-tree-chart";
import { folderSchema } from "../../lib/api-schema/folder";
import { useBaseUrlExperimental } from "../../zustand/useBaseUrl";
import { labelSchema } from "../../lib/api-schema/label";
import { userSchema } from "../../lib/api-schema/user";
import { operatingLimitSchema } from "../../lib/api-schema/operating-limit";
import getFaultTreeTemplate, {
  TreeTemplateEnum,
} from "../../components/d3ft/FaultTreeTemplates";
import { faultTreeNodeFileSchema } from "../../lib/api-schema/ft/fault-tree-node-file";
import { OperatingMode } from "../../lib/api-schema/om";
import { Group } from "../../lib/api-schema/group";
import { Tag } from "../../lib/api-schema/tag";
import Variable from "../../types/api/Variable";
import { OlDetailsStore } from "../../components/of/details/use-ol-details-store";
import {
  LEVEL_MAGNITUDES,
  QueryMode,
  limitLevelNumSchema,
} from "../../components/of/constants";
import { exceedanceCountSchema } from "../../lib/api-schema/ol/exceedance-count";
import { probabilityOrExceedanceVariablesSchema } from "../../lib/api-schema/ol/probability-or-exceedance-variables";
import Comment from "../../types/api/Comment";
import {
  CreateShutdownRulePayload,
  DTO_ShutdownRule,
} from "../../lib/api-schema/shutdown-rule";
import { RidgelineResponse } from "../../types/api/RidgelineResponse";
// import type { Timeseries } from "../../shared-ui/time-series/types";
import { UserRoleString } from "../../zustand/auth/types/UserRole";
import { CapabilityQueryResults } from "../../types/api/Capability";
import { TimeseriesForBv } from "../../shared-ui/time-series-2/types";
import { minLen1 } from "../../shared-ui/lib/utils";
import { slopeSchema } from "../../lib/api-schema/slope";
import { cdsSchema } from "../../lib/api-schema/cds";
import { clusterSchema } from "../../lib/api-schema/cluster";
import { chartFormat } from "./utils";
import {
  SavedExpressionVariable,
  type SavedExpression,
} from "../../components/instant-calculator/types";
import { teamSchema } from "../../lib/api-schema/team";
import { Sections } from "../../lib/api-schema/sections";

type BaseUrl = ReturnType<typeof useBaseUrlExperimental>;

type ConstructorSignature<T> = T extends {
  new (...args: infer Args): unknown;
}
  ? Args
  : never;

type URLSearchParamsConstructorParameter = ConstructorSignature<
  typeof URLSearchParams
>[0];

function getSearchString(sp: URLSearchParamsConstructorParameter) {
  const params = new URLSearchParams(sp);
  return `?${params.toString()}`;
}

export function generateMessageFromError(
  err: unknown,
  defaultMsg?: string
): string {
  if (err instanceof Error) {
    return err.message;
  }
  if (typeof err === "string") {
    return err;
  }
  if (Array.isArray(err)) {
    for (const e of err) {
      const parsed = z.object({ msg: z.string() }).safeParse(e);
      if (parsed.success) return parsed.data.msg; // Seems like active directory returns errors in this format
    }
  }
  return defaultMsg ?? "An unknown error ocurred";
}

export function getVariables(
  baseUrl: BaseUrl,
  sp?: URLSearchParamsConstructorParameter
) {
  const params = new URLSearchParams(sp);

  return GET(`${baseUrl}/variables?${params.toString()}`) as Promise<
    Variable[]
  >;
}

export function getClusters(baseUrl: BaseUrl) {
  // todo naming
  return getAriaClusters(baseUrl);
}

export function getGroups(
  baseUrl: BaseUrl,
  sp?: Partial<{
    hidden: boolean;
  }>
) {
  const sp_ = sp || {};

  const params = new URLSearchParams({
    hidden: sp_.hidden === true ? "true" : "false",
  });

  return GET(`${baseUrl}/groups?${params.toString()}`) as Promise<Group[]>;
}

export const editGroup = withBaseUrl(
  (b) =>
    async (gid: string, payload: { variables: string[]; name: string }) => {
      return await PATCH(`${b}/groups/${gid}`, payload).then((g) =>
        groupSchema.parse(g)
      );
    }
);

export const createGroup = withBaseUrl(
  (b) => async (payload: { variables: string[]; name: string }) => {
    return await POST(`${b}/groups`, payload).then((g) => groupSchema.parse(g));
  }
);

export const deleteGroup = withBaseUrl((b) => async (gid: string) => {
  await DELETE(`${b}/groups/${gid}`);
});

export const reorderAllGroupsForAUnit = withBaseUrl(
  (b) => async (groupIds: string[]) => {
    return await PATCH(`${b}/groups/reorder`, groupIds);
  }
);

export async function getRisks(
  baseUrl: BaseUrl,
  query: {
    groupId: string;
    start: Date | moment.Moment;
    end: Date | moment.Moment;
  }
) {
  const query_: Record<keyof typeof query, string> = {
    groupId: query.groupId,
    start: moment(query.start).format("YYYY-MM-DD"),
    end: moment(query.end).format("YYYY-MM-DD"),
  };

  const sp = new URLSearchParams(query_);

  const risks = (await GET(`${baseUrl}/risks?${sp.toString()}`)) as unknown;
  return riskSchema.array().parse(risks);
}

export async function getAnomaly(
  baseUrl: BaseUrl,
  payload: { varIds?: string[]; groupId?: string; groupShortId?: string },
  tzAgnosticDateString: string | moment.Moment,
  sp?: URLSearchParamsConstructorParameter
) {
  if (payload.groupId !== undefined && payload.varIds) {
    throw new Error("Cannot specify both groupId and varIds");
  }
  const md = moment(tzAgnosticDateString);
  const y = md.format("YYYY");
  const m = md.format("MM");
  const d = md.format("DD");

  const dateUrl = [y, m, d].map((s) => `/${s}`).join("");

  const params = new URLSearchParams(sp);

  const data = await POST(
    `${baseUrl}/anomalies${dateUrl}?${params.toString()}`,
    payload
  );

  // return anomalySchema.array().parse(data);
  return data; // do not parse the data here because the return type can change depending on "include" query param
}

const commentsSummaryPayloadSchema = z.object({
  start_date: z.string().datetime(),
  end_date: z.string().datetime(),
  variableIds: z.string().array(),
  private: z.boolean().optional(),
  limit: z.number().int().min(0).optional(),
  page: z.number().int().min(1).optional(),
});
type CommentsSummaryPayloadSchema = z.infer<
  typeof commentsSummaryPayloadSchema
>;

export function getCommentsSummary(
  baseUrl: BaseUrl,
  payload: CommentsSummaryPayloadSchema,
  sp?: URLSearchParamsConstructorParameter
) {
  const payload_ = commentsSummaryPayloadSchema.parse(payload);

  const modifiedPayload = {
    ...payload_,
    private: !!payload_.private,
    limit: payload_.limit ?? 10,
    page: payload_.page ?? 1,
  };

  const url = `${baseUrl}/comments/summary${getSearchString(sp)}`;

  return POST(url, modifiedPayload) as Promise<unknown>;
}

export async function getAcknowledgements(
  baseUrl: BaseUrl,
  params: ({ varId: string } | { varIds: string[] }) & {
    start: string;
    end: string;
  }
) {
  const { end, start } = params;
  const params_ = new URLSearchParams({
    start: moment.utc(YYYY_MM_DD.parse(start)).startOf("day").toISOString(),
    end: moment.utc(YYYY_MM_DD.parse(end)).endOf("day").toISOString(),
  });
  if ("varId" in params) {
    params_.set("varId", params.varId);
  } else {
    params_.set("varIds", params.varIds.join(","));
  }

  const acks = (await GET(
    `${baseUrl}/acknowledgments?${params_.toString()}`
  )) as unknown;
  return acknowledgementSchema
    .array()
    .parse(acks)
    .sort(
      (a, b) =>
        new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf()
    );
}

export async function getAcknowledgedVariables(
  b: BaseUrl,
  start: number,
  end: number
) {
  const sp = new URLSearchParams({
    start: start.toString(),
    end: end.toString(),
  });

  return GET(`${b}/acknowledgments/variables?${sp.toString()}`).then(
    (res) => res as string[]
  );
}

const postAckSchema = acknowledgementSchema.pick({ type: true }).extend({
  variable: z.string(),
  date: YYYY_MM_DD,
  unacknowledgment: z.boolean(),
});

export type PostAckSchema = z.infer<typeof postAckSchema>;

export async function postAcknowledgement(
  baseUrl: BaseUrl,
  payload: PostAckSchema
) {
  const { date, type, ...rest } = postAckSchema.parse(payload);

  const d = new Date(date);
  const modifiedPayload = {
    type: type ?? acknowledgementType.Enum.normal,
    start: moment.utc(d).startOf("day").toISOString(),
    end: moment.utc(d).endOf("day").toISOString(),
    ...rest,
  };

  const ack = (await POST(
    `${baseUrl}/acknowledgments`,
    modifiedPayload
  )) as unknown;
  return acknowledgementSchema.parse(ack);
}

export async function getAnomalySummariesForDay(
  baseUrl: BaseUrl,
  payload: { start: YYYY_MM_DD; end: YYYY_MM_DD; varIds: string[] }
) {
  const payload_ = {
    start: YYYY_MM_DD.parse(payload.start),
    end: YYYY_MM_DD.parse(payload.end),
    varIds: payload.varIds,
  };

  // const summaries = await POST(`${baseUrl}/timeseries/anomalies`, payload_);
  const summaries = await POST(`${baseUrl}/anomalies/summaries`, payload_);
  return anomalySummarySchema.array().parse(summaries);
}

export async function getClusterDriftScore(
  baseUrl: BaseUrl,
  payload: { start: YYYY_MM_DD; end: YYYY_MM_DD }
) {
  const payload_ = {
    start: YYYY_MM_DD.parse(payload.start),
    end: YYYY_MM_DD.parse(payload.end),
  };
  const q = new URLSearchParams(payload_);

  const summaries = await GET(`${baseUrl}/aria/cds?${q.toString()}`);
  return cdsSchema.parse(summaries);
}

export const postFolder = withBaseUrl(
  (b) =>
    async (payload: { name: string; variables: [string, ...string[]][] }) => {
      return await POST(`${b}/folders`, payload).then((f) =>
        folderSchema.parse(f)
      );
    }
);

export async function getAriaClusters(
  baseUrl: BaseUrl,
  at?: number,
  filterType?: clusterSchema["type"],
  enabled?: clusterSchema["aria_enabled"]
) {
  const sp = new URLSearchParams({
    at: at ? at.toString() : "",
    filterType: filterType ?? "",
    enabled: enabled?.toString() ?? "",
  });
  const clusters = await GET(`${baseUrl}/aria/clusters?${sp.toString()}`);
  return clusterSchema.array().parse(clusters);
}

export async function toggleAria(baseUrl: BaseUrl, clusterId: string) {
  return await PATCH(`${baseUrl}/aria/clusters/${clusterId}/toggle`);
}

export async function saveCluster(
  baseUrl: BaseUrl,
  payload: Partial<clusterSchema>,
  clusterId?: string
) {
  if (clusterId) {
    return await PATCH(`${baseUrl}/aria/clusters/${clusterId}`, payload);
  }
  return await POST(`${baseUrl}/aria/clusters`, payload);
}

export async function deleteCluster(baseUrl: BaseUrl, clusterId: string) {
  return await DELETE(`${baseUrl}/aria/clusters/${clusterId}`);
}

export async function getFaultTreeNodeResolutionDate(
  baseUrl: BaseUrl,
  nodeId: string
) {
  const date = await GET(
    `${baseUrl}/fault-tree-nodes/${nodeId}/resolution-date`
  );
  return faultTreeResolutionDateSchema.parse(date);
}

export async function getFaultTreeNodeStatusSeries(
  baseUrl: BaseUrl,
  nodeId: string,
  start: YYYY_MM_DD,
  end: YYYY_MM_DD
) {
  const params = new URLSearchParams({
    start: YYYY_MM_DD.parse(start),
    end: YYYY_MM_DD.parse(end),
  });

  const data_1 = await GET(
    `${baseUrl}/variables/${nodeId}/statusSeries?${params.toString()}`
  );
  return z
    .object({
      series: faultTreeNodeStatusSeriesSchema.array(),
    })
    .parse(data_1);
}

export async function getFaultTreeNodeStatus(
  baseUrl: BaseUrl,
  nodeId: string,
  date: YYYY_MM_DD
) {
  const parsedDate = YYYY_MM_DD.parse(date);
  const data_1 = await GET(
    `${baseUrl}/fault-tree-nodes/${nodeId}/status?start=${parsedDate}&end=${parsedDate}`
  );
  return faultTreeNodeStatusSchema.parse(data_1);
}

export async function getFaultTrees(baseUrl: BaseUrl) {
  const trees = await GET(`${baseUrl}/fault-trees`);
  return faultTreeSchema.array().parse(trees);
}

export async function getFaultTree(baseUrl: BaseUrl, treeId: string) {
  const tree = await GET(`${baseUrl}/fault-trees/${treeId}`);
  return faultTreeSchema.parse(tree);
}

export function createFaultTree(b: BaseUrl, name: string) {
  return POST(`${b}/fault-trees`, { name }).then((tree) =>
    faultTreeSchema.parse(tree)
  );
}

export function createFaultTreeNode(
  b: BaseUrl,
  payload: {
    faultTreeId: string;
    name?: string;
    recommendation?: string;
    nodeExpression?: string;
    tagExpression?: string;
    expressionConnector?: string;
    parentId: string | null;
  }
) {
  return POST(`${b}/fault-tree-nodes/`, payload).then((node) =>
    faultTreeNodeSchema.parse(node)
  );
}

export async function createFaultTreeFromTemplate(
  b: BaseUrl,
  treeNum?: TreeTemplateEnum
) {
  const templateTreeStruct = getFaultTreeTemplate(treeNum);

  // create a new tree
  const newTree = await createFaultTree(
    b,
    `fault-tree-${new Date().getTime()}` // random name
  );

  const createChildrenRecursively = async (
    myChildren: (typeof templateTreeStruct)[] | undefined,
    parentId: string
  ) => {
    if (!myChildren) return;

    // do this in series because doing it in parallel
    // will throw a 500 error due to mongoose duplicate keys
    // on the auto-incrementing shortId thingy :(
    for (const child of myChildren) {
      const newNode = await createFaultTreeNode(b, {
        name: `${new Date().getTime()}.${child.name}`,
        faultTreeId: newTree._id,
        parentId: parentId,
      });
      await createChildrenRecursively(child.children, newNode._id);
    }
  };

  // create the root node of the tree
  await createFaultTreeNode(b, {
    name: `root-${new Date().getTime()}`,
    faultTreeId: newTree._id,
    parentId: null,
  }).then((rootNode) => {
    // create the rest of the tree
    return createChildrenRecursively(templateTreeStruct.children, rootNode._id);
  });

  // get the fresh tree
  return getFaultTree(b, newTree._id);
}

export async function getFaultTreeNode(baseUrl: BaseUrl, nodeId: string) {
  const nodes = await GET(`${baseUrl}/fault-tree-nodes/${nodeId}`);
  return faultTreeNodeSchema.parse(nodes);
}

export async function getFaultTreeNodes(baseUrl: BaseUrl, treeId: string) {
  const nodes = await GET(`${baseUrl}/fault-trees/${treeId}/fault-tree-nodes/`);
  return faultTreeNodeSchema.array().parse(nodes);
}

export async function getFaultTreeStatus(
  baseUrl: BaseUrl,
  payload: {
    date: YYYY_MM_DD;
    treeId: string;
  }
) {
  const status = await GET(
    `${baseUrl}/fault-trees/${payload.treeId}/status?${new URLSearchParams({
      date: payload.date,
    }).toString()}`
  );
  return ftStatusSchema.parse(status);
}

export async function getFaultTreeChart(
  baseUrl: BaseUrl,
  treeId: string,
  date: YYYY_MM_DD
) {
  const params = new URLSearchParams({
    date: YYYY_MM_DD.parse(date),
  });
  const response = await GET(
    `${baseUrl}/fault-trees/treeChartData/${treeId}?${params.toString()}`
  );

  const { data } = z
    .object({
      data: faultTreeChartSchema,
    })
    .parse(response);

  return data;
}

export async function getFreshAnomalies(
  baseUrl: BaseUrl,
  payload: Partial<{
    deg1array: string[];
    deg2array: string[];
    deg3array: string[];
  }> & {
    date: YYYY_MM_DD;
  }
) {
  const modifiedPayload = {
    date: YYYY_MM_DD.parse(payload.date),
    deg1array: payload.deg1array ?? [],
    deg2array: payload.deg2array ?? [],
    deg3array: payload.deg3array ?? [],
  };

  const data = await POST(
    `${baseUrl}/variables/freshAnomalies`,
    modifiedPayload
  );

  return z
    .object({
      freshFirst: z.string().array(),
      freshSecond: z.string().array(),
      freshThird: z.string().array(),
    })
    .parse(data);
}

export const deleteFolder = withBaseUrl((b) => async (id: string) => {
  return await DELETE(`${b}/folders/${id}`).then((f) =>
    console.log(f, "delete folder response")
  );
});

export const editFolder = withBaseUrl(
  (b) =>
    async (
      id: string,
      name: string,
      variables: (string | [string, ...string[]])[]
    ) => {
      return await PATCH(`${b}/folders/${id}`, { name, variables }).then((f) =>
        folderSchema.parse(f)
      );
    }
);

export async function getFolders(baseUrl: BaseUrl) {
  const folders = await GET(`${baseUrl}/folders`).then((folders) =>
    folderSchema.array().parse(folders)
  );

  return { folder: folders };
}

export async function getVariablesSlopes(
  baseUrl: BaseUrl,
  payload: { date: YYYY_MM_DD; groupId: string }
) {
  const params = new URLSearchParams({
    date: moment(YYYY_MM_DD.parse(payload.date)).format("YYYY/MM/DD"),
    groupId: payload.groupId,
  });
  const data = await GET(`${baseUrl}/variables/slopes?${params.toString()}`);

  return z
    .object({
      longSlopes: z.string().array(),
      shortSlopes: z.string().array(),
      mediumSlopes: z.string().array(),
    })
    .parse(data);
}

export const getSlopeById = withBaseUrl((b) => async (id: string) => {
  const res = await GET(`${b}/slopes/${id}`);
  return slopeSchema.parse(res);
});

export async function getSlopes(
  baseUrl: BaseUrl,
  variableId: string,
  start: YYYY_MM_DD,
  end: YYYY_MM_DD
) {
  const params = new URLSearchParams({ start, end, variableId });
  const res = await GET(
    `${baseUrl}/slopes/forAnalysisPeriod?${params.toString()}`
  );

  return slopeSchema.array().parse(res);
}

export type AckEmailDownloadType = "summary" | "list" | "top";
export function triggerAcknowledgementsEmail(
  baseUrl: BaseUrl,
  payload: {
    date: YYYY_MM_DD;
    groupId: string;
    levels: number[];
    downloadType: AckEmailDownloadType;
    onlyFresh: boolean;
    onlyActiveFaultTreeNodes: boolean;
    filterVariableIds: string[];
  }
) {
  const { levels, ...rest } = payload;
  return POST(`${baseUrl}/acknowledgments/download/`, {
    ...rest,
    levels: levels.map((n) => n.toString()).join(","),
  });
}

export function getLabels() {
  return GET("/labels").then((labels) => labelSchema.array().parse(labels));
}

export async function getUsers(b: BaseUrl) {
  const users = await GET(`${b}/users/`);
  return userSchema
    .array()
    .parse(users)
    .sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );
}

export const createUser = async (payload: {
  email: string;
  first: string;
  last: string;
  reports: string[];
  units: string[];
  role: UserRoleString;
}) => {
  const res = await POST(`/users`, payload);
  return userSchema.parse(res);
};

export const updateUser = withBaseUrl(
  (b) =>
    (
      id: string,
      payload: {
        email: string;
        first: string;
        last: string;
        reports: string[];
        role: UserRoleString;
      }
    ) => {
      return PATCH(`${b}/users/${id}`, payload);
    }
);

export async function getTeams(b: BaseUrl) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query = await GET(`${b}/teams/`); // TODO:schema
  return teamSchema.array().parse(query);
}

export function getActiveFTNodeIds(b: BaseUrl, date: YYYY_MM_DD) {
  const sp = new URLSearchParams({
    date: YYYY_MM_DD.parse(date),
  });
  return GET(`${b}/fault-tree-nodes/active?${sp.toString()}`).then(
    (nodeIdsToFaultTreeName) =>
      z.record(z.string()).parse(nodeIdsToFaultTreeName)
  );
}

export function getLimitsForVariable(b: BaseUrl, variableId: string) {
  const sp = new URLSearchParams({
    variableId,
  }).toString();

  return GET(`${b}/operating-limits?${sp}`).then((limits) =>
    operatingLimitSchema.array().parse(limits)
  );
}

export function patchNotification_TODO_TYPE_THIS(
  b: BaseUrl,
  _id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notif: any
) {
  return PATCH(`${b}/notifications/${_id}`, notif);
}

export async function getFaultTreeNodeFiles(baseUrl: BaseUrl, nodeId: string) {
  const data = await GET(`${baseUrl}/fault-tree-nodes/${nodeId}/files`);
  return faultTreeNodeFileSchema.array().parse(data);
}

export async function deleteFtNodeFile(baseUrl: BaseUrl, fileId: string) {
  await DELETE(`${baseUrl}/fault-tree-nodes/file/${fileId}`);
}

export const deleteUser = withBaseUrl((b) => (id: string) => {
  return PATCH(`${b}/users/${id}/remove-unit?unit=${b.replace("/", "")}`);
});

export async function deleteRisks(baseUrl: BaseUrl, groupIds: string[]) {
  await DELETE(`${baseUrl}/risks`, { groupIds });
}

export async function queueBackprocessTask(
  b: BaseUrl,
  payload: {
    variableIds: string[];
    start: YYYY_MM_DD;
    end: YYYY_MM_DD;
  }
) {
  return await POST(`${b}/tasks/jobs`, payload);
}

export async function queueClusterBackprocessing(
  b: BaseUrl,
  clusters: { _id: string; name: string }[]
) {
  const promises = clusters.map((cluster) => {
    return POST(`${b}/proc-jobs/ariaBP`, {
      id: cluster._id,
      key: cluster.name,
    });
  });
  return await Promise.all(promises);
}

type CreateTagPayload = {
  st_enabled: boolean;
  highSide: boolean;
  lowSide: boolean;
  hide_positive_slopes: boolean;
  hide_negative_slopes: boolean;
  unitsOfMeasurement: string | null;
  name: string;
  description: string;
  groupIds: string[];
  familyIds: string[];
  inherit_sd: boolean;
  expression?: string; // only send if calculated tag
  historian?: string; // only send if they selected a historian that's an alternateHistorian
};

export async function createTag(baseUrl: BaseUrl, payload: CreateTagPayload) {
  return await POST(`${baseUrl}/tags`, payload).then((res) => res as Tag);
}

export const deleteTag = withBaseUrl((b) => async (id: string) => {
  await DELETE(`${b}/tags/${id}`);
});

export const getReferencingVariables = withBaseUrl(
  (b) => async (id: string) => {
    const res = await GET(`${b}/variables/${id}/referencing-variables`);

    const { data } = z
      .object({
        data: z.array(variableSchema),
      })
      .parse(res);
    return data;
  }
);

export async function patchTag(
  baseUrl: BaseUrl,
  tagId: string,
  payload: Partial<CreateTagPayload>
) {
  return await PATCH(`${baseUrl}/tags/${tagId}`, payload).then(
    (res) => res as Tag
  );
}

export async function getTags(baseUrl: BaseUrl) {
  return await GET(`${baseUrl}/tags`).then((res) => res as Tag[]);
}

export type CreateOperatingModePayload = Pick<
  OperatingMode,
  | "name"
  | "description"
  | "expression"
  | "bindingGroupIds"
  | "bindingVariableIds"
  | "clusters"
  | "om_calc"
>;
export async function createOperatingMode(
  baseUrl: BaseUrl,
  payload: CreateOperatingModePayload
) {
  return await POST(`${baseUrl}/operating-modes`, payload).then(
    (res) => res as OperatingMode
  );
}

export async function editOperatingMode(
  baseUrl: BaseUrl,
  _id: string,
  payload: Partial<CreateOperatingModePayload>
) {
  return await PATCH(`${baseUrl}/operating-modes/${_id}`, payload).then(
    (res) => res as OperatingMode
  );
}

export async function getOperatingModes(b: BaseUrl) {
  return await GET(`${b}/operating-modes`).then((res) =>
    (res as OperatingMode[]).sort(
      (a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf() // descending
    )
  );
}

export async function deleteOperatingMode(b: BaseUrl, _id: string) {
  return await DELETE(`${b}/operating-modes/${_id}`);
}

export type RidgelineStats = {
  min: number;
  max: number;
  mean: number;
  std: number;
  median: number;
  iqr: number;
  q1: number;
  q3: number;
  gradient: { m: number; b: number };
};

export async function getRidgelineData(
  b: BaseUrl,
  variableId: string,
  start: Date | number,
  end: Date | number,
  modes?: string[],
  ref?: string
) {
  const startDate = moment(start).toISOString();
  const endDate = moment(end).toISOString();

  const params = new URLSearchParams();
  params.set("start", startDate);
  params.set("end", endDate);
  params.set("varId", variableId);
  params.set("excludedModes", (modes || []).join(","));
  ref && params.set("ref", ref);

  const res = await GET(`${b}/variability/ridgeline?${params.toString()}`);
  return res as RidgelineResponse;
}

export async function getCapabilityResults(
  b: BaseUrl,
  variableId: string,
  start: Date | number,
  end: Date | number,
  modes?: string[]
) {
  const startDate = moment(start).toISOString();
  const endDate = moment(end).toISOString();

  const params = new URLSearchParams();
  params.set("start", startDate);
  params.set("end", endDate);
  params.set("varId", variableId);
  params.set("excludedModes", (modes || []).join(","));

  const res = await GET(`${b}/variability/capability?${params.toString()}`);
  return res as CapabilityQueryResults;
}

export async function setWatchlist(
  b: BaseUrl,
  variableId: string,
  status: boolean
) {
  return PATCH(`${b}/variables/${variableId}/watchlist`, {
    watchlist: status,
  });
}

// lookup map
const ProbabilityVariablesOptions = {
  highProb: {
    percent: "0.75",
  },
  medProb: {
    percent: "0.5",
  },

  highProbNoExceedances: {
    percent: "0.75",
    noExceedances: "true",
  },
  medProbNoExceedances: {
    percent: "0.5",
    noExceedances: "true",
  },
  /**
   * Deprecated as of release/2024.1a but keeping them here just in case
   */
  // increaseProb: {
  //   increase: "0.25",
  // },
  // increaseProbNoExceedances: {
  //   increase: "0.25",
  //   noExceedances: "true",
  // },
  exceedance: {},
} as const satisfies {
  [K in QueryMode]: Partial<{
    percent: `${number}`;
    increase: `${number}`;
    noExceedances: "true";
  }>;
};

// utility to use on any route that has a base url as the first arg
export type APIRouteParamsWithoutBaseUrl<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (b: BaseUrl, ...args: any[]) => Promise<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = T extends (b: BaseUrl, ...args: infer Args) => Promise<any> ? Args : never;

type SomeOperatingLimitsRelatedQueryParams = {
  groupId: string;
  start: YYYY_MM_DD;
  end: YYYY_MM_DD;
  mode: QueryMode;
} & Pick<OlDetailsStore, "excludeLevels" | "excludeTypes">;

// a helper function used in 1 or more routes
const getSearchParamsForSomeOperatingLimitsRelatedRequests = (
  query: SomeOperatingLimitsRelatedQueryParams
) => {
  const { excludeLevels, excludeTypes, mode, ...rest } = query;

  const excludeTypeArr = Object.entries(excludeTypes)
    .filter(([, isExcluded]) => isExcluded)
    .map(([highOrLow]) => highOrLow as keyof typeof excludeTypes);

  if (excludeTypeArr.length > 1) throw new Error("Can only exclude one type");

  const excludeLevelArr = Object.entries(excludeLevels)
    .filter(([, isExcluded]) => isExcluded)
    .flatMap(([level]) => {
      const num = limitLevelNumSchema.parse(parseInt(level));

      const toExcludePair = LEVEL_MAGNITUDES[num];
      return toExcludePair;
    });

  const [first] = excludeTypeArr;

  type OnlyExcludeType = {
    excludeType: keyof typeof excludeTypes;
  };
  type OnlyExcludeLevels = {
    excludeLevels: string;
  };
  type ExcludeTypeAndLevels = OnlyExcludeLevels & OnlyExcludeType;

  let optionals:
    | OnlyExcludeLevels
    | OnlyExcludeType
    | ExcludeTypeAndLevels
    | undefined = undefined;

  const hasExcludeLevels = excludeLevelArr.length > 0;

  // do it this way because only include key if needed
  if (first && hasExcludeLevels) {
    optionals = {
      excludeType: first,
      excludeLevels: excludeLevelArr.join(","),
    };
  } else if (first) {
    optionals = {
      excludeType: first,
    };
  } else if (hasExcludeLevels) {
    optionals = {
      excludeLevels: excludeLevelArr.join(","),
    };
  }

  const sp = new URLSearchParams({
    ...rest,
    ...ProbabilityVariablesOptions[mode],
    ...(optionals ?? {}), // only include key if needed
  });

  return sp;
};

export async function getProbabilityOrExceedanceVariables(
  b: BaseUrl,
  query: SomeOperatingLimitsRelatedQueryParams
) {
  const sp = getSearchParamsForSomeOperatingLimitsRelatedRequests(query);

  // This function hits a different route depending on the mode
  const route = query.mode === "exceedance" ? "exceeded" : "ol-probability";

  return GET(`${b}/variables/${route}?${sp.toString()}`).then((res) => {
    return probabilityOrExceedanceVariablesSchema.parse(res);
  });
}

export const getExceedanceGroupCounts = async (
  ...args: Parameters<typeof getProbabilityOrExceedanceVariables>
) => {
  const [b, query] = args;
  const sp = getSearchParamsForSomeOperatingLimitsRelatedRequests(query);
  const res = await GET(`${b}/limit-exceedances/count?${sp.toString()}`);
  return exceedanceCountSchema.array().parse(res);
};

type CommonOperatingLimitRoutesPayload = {
  start: YYYY_MM_DD;
  end: YYYY_MM_DD;
  id: string;
};

export function getOperatingLimitStatusSeries(
  b: BaseUrl,
  payload: CommonOperatingLimitRoutesPayload
) {
  const sp = new URLSearchParams({
    start: YYYY_MM_DD.parse(payload.start),
    end: YYYY_MM_DD.parse(payload.end),
  });

  return GET(
    `${b}/operating-limits/status-series/${payload.id}?${sp.toString()}`
  ).then((res) =>
    z
      .object({
        data: z
          .object({
            date: z.string().datetime(),
            value: z.number(),
          })
          .array(),
      })

      .parse(res)
  );
}

export type _7_30_90 = 7 | 30 | 90;

export async function getOperatingLimitProbabilities(
  b: BaseUrl,
  payload: CommonOperatingLimitRoutesPayload
) {
  const sp = new URLSearchParams({
    start: YYYY_MM_DD.parse(payload.start),
    end: YYYY_MM_DD.parse(payload.end),
  });

  type _7_30_90_Counts = { [N in _7_30_90]: number };

  return GET(`${b}/operating-limit-stats/${payload.id}?${sp.toString()}`).then(
    (res) =>
      res as {
        date: string;
        operatingLimitId: string;
        probability: _7_30_90_Counts;
        smoothedProbability: _7_30_90_Counts;
        maxIncreaseOfProbability: _7_30_90_Counts;
      }[]
  );
}

export type CreateCommentPayload = {
  context: NonNullable<Comment["context"]>;
  variables: [string, ...string[]]; // must have at least 1
  tagged_teams: string[];
  tagged_users: string[];
  labels: string[];
  link: string;
  start_date: number;
  end_date: number;
  text: string;
  private: boolean;
  // openIssue: boolean;
  type: "Comment" | "Issue";
};

export const createComment = withBaseUrl(
  (b) => async (p: CreateCommentPayload) => {
    if (p.context.variableId !== p.variables[0]) {
      throw new Error("Variable id must be the first in the variables array");
    }
    await POST(`${b}/comments`, {
      ...p,
      /**
       * This function takes numbers because it's easier to think about
       * things without timezones. It also makes things more explicit, as
       * in you have to know what youre doing with fake dates.
       *
       * But the API expects strings.
       */
      start_date: new Date(p.start_date).toISOString(),
      end_date: new Date(p.end_date).toISOString(),
    });
  }
);
export const createReply = withBaseUrl(
  (b) => async (parentId: string, payload: CreateCommentReplyPayload) => {
    await POST(`${b}/comments/${parentId}/reply`, payload);
    // Not returning the result here because for some reason it looks different
    // than the Comment type we have defined. TODO: investigate this if you
    // need to return the result
  }
);

export const CommentEndpoints = {
  create: createComment,
  createReply: createReply,
  edit: withBaseUrl(
    (b) => async (id: string, payload: Partial<CreateCommentPayload>) => {
      await PATCH(`${b}/comments/${id}`, {
        ...payload,

        // this route has validations on it that expects a string
        // but I want to use numbers on the frontend to be explicit
        start_date:
          payload.start_date === undefined
            ? undefined
            : new Date(payload.start_date).toISOString(),
        end_date:
          payload.end_date === undefined
            ? undefined
            : new Date(payload.end_date).toISOString(),
      });
    }
  ),
  delete: withBaseUrl((b) => async (_id: string) => {
    await DELETE(`${b}/comments/${_id}`);
  }),
};

export type CreateCommentReplyPayload = Pick<
  CreateCommentPayload,
  "labels" | "link" | "tagged_teams" | "tagged_users" | "text" | "variables"
> & {
  issue_resolution?: boolean;
};

export type EditCommentPayload = CreateCommentReplyPayload & {
  [K in keyof Pick<
    CreateCommentPayload,
    "end_date" | "start_date"
  >]?: CreateCommentPayload[K];
};

export type GetCommentsListOpts = {
  page?: number;
  limit?: number;
  private?: boolean;
  variableId: string;
  start_date: number;
  end_date: number;
};
export const getCommentsList = withBaseUrl(
  (b) =>
    async ({
      page,
      limit,
      private: p,
      variableId,
      end_date,
      start_date,
    }: GetCommentsListOpts) => {
      const res = await GET(
        `${b}/comments${getSearchString({
          limit: (limit ?? 300).toString(),
          page: (page ?? 1).toString(),
          private: (p ?? false).toString(),
          variableIds: variableId, // is this supposed to be an array?

          // API expects strings, we take in numbers
          start_date: new Date(start_date).toISOString(),
          end_date: new Date(end_date).toISOString(),
        })}`
      );

      return res as {
        docs: Comment[];
        has_more: boolean;
        quota_max: number;
        quota_remaining: number;
      };
    }
);

export async function getCommentedVariables(
  b: BaseUrl,
  start: number,
  end: number
) {
  const sp = new URLSearchParams({
    start: start.toString(),
    end: end.toString(),
  });

  return GET(`${b}/comments/variables?${sp.toString()}`).then(
    (res) => res as string[]
  );
}

export async function getComment_TODO_VALIDATE(b: BaseUrl, _id: string) {
  return GET(`${b}/comments/${_id}`).then((res) => res as Comment);
}

export const createSection = withBaseUrl(
  (b) => async (payload: { groups: [string, ...string[]]; name: string }) => {
    return POST(`${b}/sections`, payload);
  }
);

export const editSection = withBaseUrl(
  (b) => async (sid: string, payload: { groups: string[]; name: string }) => {
    return PATCH(`${b}/sections/${sid}`, payload).then(
      (res) => res as Sections
    );
  }
);

export const getSectionForUnit = withBaseUrl((b) => async () => {
  const res = await GET(`${b}/sections`);
  return res as Sections | null;
});

export const addGroupToSection = withBaseUrl(
  (b) =>
    async ({ groupId, sectionId }: { groupId: string; sectionId: string }) => {
      const out = await PATCH(`${b}/sections/add`, {
        groupId,
        sectionId,
      });
      return out as Sections;
    }
);

export async function getShutdownRules(b: BaseUrl) {
  return GET(`${b}/shutdown-rules`).then((res) => res as DTO_ShutdownRule[]);
}

export async function createShutdownRule(
  b: BaseUrl,
  payload: CreateShutdownRulePayload
) {
  return POST(`${b}/shutdown-rules`, payload).then(
    (res) => res as DTO_ShutdownRule
  );
}

export async function editShutdownRule(
  b: BaseUrl,
  _id: string,
  payload: Partial<CreateShutdownRulePayload>
) {
  return PATCH(`${b}/shutdown-rules/${_id}`, payload).then(
    (res) => res as DTO_ShutdownRule
  );
}

export async function deleteShutdownRule(b: BaseUrl, _id: string) {
  return DELETE(`${b}/shutdown-rules/${_id}`);
}

export async function getAriaTimeseries(
  b: BaseUrl,
  variables: string[],
  cluster: string,
  start: string,
  end: string
): Promise<(TimeseriesForBv & { driftTime: number })[]> {
  const sp = new URLSearchParams({
    variables: variables.join(","),
    cluster,
    start,
    end,
  }).toString();

  const data = (await GET(`${b}/aria/timeseries?${sp}`)) as {
    v: number;
    var: string;
    t: string;
    l: number | null;
    m: string | null;
  }[];
  return chartFormat(
    data.map((x) => {
      return {
        da: x.l,
        timestamp: new Date(x.t).getTime(),
        value: x.v,
        variable: x.var,
        mode: x.m,
      };
    }),
    cluster
  );
}

export async function getInstantCalculation(
  b: BaseUrl,
  expressions: { expression: string; id: string }[],
  start: number,
  end: number
): Promise<TimeseriesForBv[]> {
  const payload = {
    expressions,
    start: new Date(start).toISOString(),
    end: new Date(end).toISOString(),
  };
  const json_arr = await POST(`${b}/instant-calculator`, payload).then(
    (res) =>
      res as {
        data: Record<string, number>;
        expression: string;
        id: string;
      }[]
  );

  const out = json_arr.map((json) => {
    const points = Object.entries(json.data)
      .filter(([t]) => !isNaN(parseInt(t)))
      .map(([t, v]) => {
        return {
          v,
          t: parseInt(t),
        };
      })
      .sort((a, b) => a.t - b.t);

    if (!minLen1(points)) throw new Error("what to do?");

    const d: [number, number] = [Infinity, -Infinity];
    const r: [number, number] = [Infinity, -Infinity];

    for (const x of points) {
      if (x.t < d[0]) d[0] = x.t;
      if (x.t > d[1]) d[1] = x.t;
      if (x.v < r[0]) r[0] = x.v;
      if (x.v > r[1]) r[1] = x.v;
    }

    const out: TimeseriesForBv = {
      type: "expression",
      id: json.id,
      stages: [
        {
          _id: "Remainder",
          d,
          r,
          ptsPartitioned: [
            {
              pts: points,
              r: [
                R.minBy(points, (x) => x.v)!.v,
                R.maxBy(points, (x) => x.v)!.v,
              ],
              d: null,
            },
          ],
        },
      ],
      d,
      r,
    };
    return out;
  });

  return out;
}

export async function saveExpression(
  b: BaseUrl,
  name: string,
  variables: SavedExpressionVariable[],
  _id?: string
) {
  if (_id) {
    return PATCH(`${b}/saved-expressions/${_id}`, {
      name,
      variables,
    }).then(
      (res) => res as { _id: string; variables: SavedExpressionVariable[] }
    );
  }
  return POST(`${b}/saved-expressions`, {
    name,
    variables,
  }).then(
    (res) => res as { _id: string; variables: SavedExpressionVariable[] }
  );
}

export async function deleteSavedExpression(b: BaseUrl, _id: string) {
  return DELETE(`${b}/saved-expressions/${_id}`);
}

export async function getSavedExpressions(b: BaseUrl) {
  return GET(`${b}/saved-expressions`).then((res) => res as SavedExpression[]);
}
export async function getMsalUrl() {
  return GET("/authenticate/msal")
    .then((res) => res as { url: string })
    .then((u) => u.url);
}

export async function getEntraUsersMap() {
  return GET("/users/entra-users").then(
    (res) => res as Record<string, { first: string; last: string }>
  );
}

export interface Timeseries {
  timestamp: number;
  value: number;
}

export interface Changepoints {
  timestamp: number;
  da: number;
  mode: string | null;
  value: number;
}

type Resolution = 1 | 60 | 1440;

export const getTimeseries = withBaseUrl(
  (b) =>
    async (
      payload: {
        variables: string[];
        start: number;
        end: number;
        resolution: Resolution;
      },
      signal?: AbortSignal
    ) => {
      const sp = new URLSearchParams({
        variables: payload.variables.join(","),
        start: payload.start.toString(),
        end: payload.end.toString(),
        resolution: payload.resolution.toString(),
      });

      const out = (await GET(
        `${b}/timeseries/?${sp.toString()}`,
        DEFAULT_HEADERS_PICK,
        signal
      )) as {
        data: { [variableId: string]: Timeseries[] };
        changepoints: { [variableId: string]: Changepoints[] };
      };

      return out;
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withBaseUrl<MF extends (...args: any) => any>(
  fn: (b: BaseUrl) => MF
): MF extends (...args: infer U) => infer T
  ? (b: BaseUrl, ...args: U) => T
  : never {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((b: BaseUrl, ...args: any) => fn(b)(...args)) as any;
}

export const getSpecialtyReports = withBaseUrl((b) => async () => {
  const res = await GET(b + "/specialty-reports");
  return res as { name: string; _id: string }[];
});
