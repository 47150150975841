import * as React from "react";
import { LoadingOverlay } from "@mantine/core";
import { FooterLogo } from "../nmm-branding/footer-logo";
import { DynamicRiskAnalyzerLogo } from "../nmm-branding/dynamic-risk-analyzer-logo";

export function LoginContainer({
  isLoading,
  children,
}: {
  isLoading?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div className="flex justify-center h-screen pt-[25vh] animate-in slide-in-from-bottom-10 duration-700 fade-in-30">
      <LoadingOverlay color="dark" visible={!!isLoading} />
      <div className="mx-4 sm:mx-auto w-full sm:w-4/5 md:w-3/5 lg:w-1/ xl:w-1/3 2xl:w-1/4">
        <FooterLogo className="w-1/2 mx-auto" />
        <DynamicRiskAnalyzerLogo className="w-full mt-8" />
        {children}
      </div>
    </div>
  );
}
