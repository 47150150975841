import {
  Atoms,
  isExpressionTrendLine,
  isVariableTrendLine,
  type SelectedVariable,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { RelativeClampsPopover } from "../../../shared-ui/time-series-2/clamps/relative-clamps-popover";
import * as Controls from "../../../shared-ui/time-series-2/secondary-variable-view/control-buttons";
import { TooltipPopoverContent } from "../../charts/DTC/variable-info-tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { Button } from "../../../shared-ui/frontend/button";
import { Info } from "lucide-react";
import { Provider, useAtomValue } from "jotai";
import { GroupStatistics } from "../../variability-view/header/components/GroupStatistics";
import { mapToObj } from "remeda";
import { type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { iife } from "../../../lib/utils";
import { GlobalControlButtonsForClickedLine } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/global-clicked-line-controls";

function ControlButtons({
  batchVariable,
  onClose,
}: {
  batchVariable: SelectedVariable | string;
  // we use this component 2 diff ways
  onClose?: () => void;
}) {
  const vs = useAtomValue(Atoms.selectedVariablesAtom);

  const batchVariableObj = iife((): SelectedVariable | undefined => {
    if (typeof batchVariable === "string") {
      return vs.find((x) =>
        isExpressionTrendLine(x)
          ? x.id === batchVariable
          : x.bv === batchVariable
      );
    }
    return batchVariable;
  });

  if (!batchVariableObj) throw new Error("impossible");

  const canShowVariableInfo = isVariableTrendLine(batchVariableObj);

  return (
    <>
      <RelativeClampsPopover
        batchVariable={batchVariableObj}
        className="text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
        size={"icon-sm"}
      />

      {/* <Controls.EyeButton
        size={"icon-sm"}
        batchVariable={batchVariableObj}
        className="text-xslate-11"
      /> */}
      {canShowVariableInfo && (
        <>
          <Popover>
            <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
              <Button
                variant={"ghost"}
                size="icon-sm"
                className={
                  "text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
                }
              >
                <Info className="size-3.5" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="text-xs p-3.5">
              <TooltipPopoverContent
                variableId={batchVariableObj.bv.slice(24)}
              />
            </PopoverContent>
          </Popover>
          <VariabilityStatisticsPopover
            variableId={batchVariableObj.bv.slice(24)}
            color={batchVariableObj.color}
            className={
              "text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
            }
          />
        </>
      )}

      <Controls.DeleteButton
        size={"icon-sm"}
        className="text-xslate-11"
        onClose={onClose}
        batchVariable={batchVariableObj}
      />
    </>
  );
}

/**
 * Default implementation of what to show when a line is
 * clicked in the chart. Right now, we show the control
 * buttons for the clicked line, but you can take what
 * this component returns and make something new as
 * requirements change.
 */
function ClickedLineControlsNeedsTimeseriesTooltipStoreProvider() {
  return (
    <GlobalControlButtonsForClickedLine>
      {(onClosePopupDialog, clickedLineData) => {
        return (
          <Provider store={clickedLineData.jotaiStore}>
            <ControlButtons
              batchVariable={clickedLineData.bvOrId}
              onClose={onClosePopupDialog}
            />
          </Provider>
        );
      }}
    </GlobalControlButtonsForClickedLine>
  );
}

function VariabilityStatisticsPopover({
  className,
  variableId,
  color,
}: PropsWithCn<{ variableId: string; color: string | undefined }>) {
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const excludedModes = useAtomValue(Atoms.excludedModesAtom);

  return (
    <GroupStatistics
      className={className}
      showLimitStats={false}
      variableId={variableId}
      color={color}
      start={start}
      end={end}
      excludedModes={mapToObj([...excludedModes], (excludedMode) => [
        excludedMode,
        true,
      ])}
    />
  );
}

export {
  ControlButtons,
  VariabilityStatisticsPopover,
  ClickedLineControlsNeedsTimeseriesTooltipStoreProvider,
};
