import React, { useEffect, useState } from "react";

function isSpacebar(code) {
  return code === "Space";
}

export default function useOnSpacebar() {
  const [upCb, setUpCb] = useState();
  const [downCb, setDownCb] = useState();

  function downHandler(e) {
    if (isSpacebar(e.code)) {
      downCb?.();
    }
  }

  function upHandler(e) {
    if (isSpacebar(e.code)) {
      upCb?.();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downCb, upCb]);

  return {
    onShiftUp: (f) => setUpCb(() => f),
    onShiftDown: (f) => setDownCb(() => f),
  };
}
