import { useEffect, useRef } from "react";
import { drawVariabilityChart } from "./draw/drawVariabilityChart";
import { useVariabilityDataQueries } from "./use-variability-query";
import * as R from "remeda";
import { useOperatingLimitsQuery } from "../../hooks/tanstack-query";
import { Loader2 } from "lucide-react";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "./variability-drawer";
import { useVariabilityTooltipStore } from "./tooltip/use-variability-tooltip-store";
import { setUpData } from "./utils/chartUtils";
import { select } from "d3";
import { getHoverElementInHistogram } from "./utils/hoverUtils";
import { useCapabilityStore } from "./capability-store";
import { cn } from "../../lib/utils";

function VariabilityChart({
  className,
  initialDims,
  aspect,
  axesFontScale,
  onClick,
}: {
  // must be defined together so they should be in one object
  aspect?: {
    height: number;
    width: number;
  };
  initialDims: {
    width: number;
    height: number;
    margin: number;
  };
  className: string;
  axesFontScale?: number;
  onClick?: () => void;
}) {
  const useStore = useGetUseVariabilityDrawerStore();
  const isRelative = useStore((s) => s.view === "relative");
  const showBars = useStore((s) => s.showBarsInHistogram);
  const showDensityGraph = useStore((s) => s.showLineGraphInHistogram);
  const showLimitLines = useStore((s) => s.showLimitLines);
  const showBoxPlot = useStore((s) => s.showBoxPlot);

  // TODO: refactor this since it's also used in box plot, maybe put in drawer?
  const groups = useMin1Groups();
  const variabilityQueries = useVariabilityDataQueries(groups);
  const limitsQuery = useOperatingLimitsQuery(groups[0].variables[0]._id);
  const capabilityData = useCapabilityStore((state) => state.data);

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const shouldShowLoading = () => {
    return (
      limitsQuery.isLoading || variabilityQueries.some((item) => item.isLoading)
    );
  };

  const loading = shouldShowLoading();

  useEffect(() => {
    if (!svgRef.current || loading) return;

    const data = variabilityQueries.map((query) => {
      if (!query.data || query.isLoading || query.isLoadingError)
        throw new Error("no data");
      return query.data;
    });

    const varChartSelector = select(svgRef.current);

    const formattedData = setUpData(data, groups, capabilityData);
    if (formattedData === undefined) throw new Error("Error formatting data");

    const selectedVariableRef = formattedData.find((v) => v.selected)?.ref;
    const hoveredVariableRef = formattedData.find((v) => v.hovered)?.ref;

    // the user has to have it toggled on and it must be a single variable
    const shouldDrawLimits =
      showLimitLines &&
      R.pipe(
        groups,
        R.flatMap((g) => g.variables.map((v) => v._id)),
        R.uniq(),
        (arr) => arr.length === 1
      );

    // hover callbacks
    const hoverFns = {
      // function to call when hovering over chart
      onHover(svgX: number, windowX: number) {
        // TODO: find a way to get highest value on density path
        const element = getHoverElementInHistogram(svgX, varChartSelector); // get the highest Y value from rects in line with the mouse
        const elementClasses = element?.getAttribute("class");

        // if over an element, render tooltip with variable
        if (element && elementClasses && containerRef.current) {
          /* if variable is selected, show the selected variable in tooltip 
            when hovering over a graph */
          if (selectedVariableRef) {
            useVariabilityTooltipStore.getState().setData({
              svgX:
                (svgX / dimensions.width) * containerRef.current.offsetWidth,
              windowX,
              variableId: selectedVariableRef,
              graph: containerRef.current,
            });
            return;
          }
          // get variable id from element class list
          // TODO: this can break easily if variable id is not in a specific position of class list, make more flexible
          const highlightedVariableId = elementClasses.split(" ")[2];

          // if variable chart has identifier
          if (highlightedVariableId && containerRef.current) {
            // show highlighted variable in tooltip
            useVariabilityTooltipStore.getState().setData({
              svgX:
                (svgX / dimensions.width) * containerRef.current.offsetWidth,
              windowX,
              variableId: highlightedVariableId,
              graph: containerRef.current,
            });
            // only change hover variable if necessary
            if (hoveredVariableRef !== highlightedVariableId)
              useStore.getState().toggleHoverVariable(highlightedVariableId);
          } else {
            throw new Error(
              "Chart needs variable id in class name to highlight it."
            );
          }
        }
        // reset tooltip and hover variable
        else {
          this.reset();
        }
      },
      reset() {
        useVariabilityTooltipStore.getState().reset();
        useStore.getState().toggleHoverVariable(undefined);
      },
    };

    const dimensions = {
      height:
        aspect?.height ??
        (containerRef.current
          ? containerRef.current.offsetHeight
          : initialDims.height),
      width:
        aspect?.width ??
        (containerRef.current
          ? containerRef.current.offsetWidth
          : initialDims.width),
      margin: initialDims.margin,
    };

    drawVariabilityChart({
      // use container height and width defined by tailwind to define svg scales
      svgSelector: varChartSelector,
      dimensions,
      data: formattedData as [
        (typeof formattedData)[number],
        ...typeof formattedData,
      ],
      limits: shouldDrawLimits ? limitsQuery.data : undefined,
      options: {
        showBars,
        showLines: showDensityGraph,
        relative: isRelative,
      },
      hoverFns,
      axesFontScale,
    });
  }, [
    containerRef,
    svgRef,
    aspect?.height,
    aspect?.width,
    showLimitLines,
    useStore,
    limitsQuery.data,
    showBars,
    showDensityGraph,
    showBoxPlot,
    isRelative,
    groups,
    axesFontScale,
    capabilityData,
    loading,
    variabilityQueries,
    initialDims.height,
    initialDims.width,
    initialDims.margin,
  ]);

  return (
    <div
      ref={containerRef}
      id="var-chart"
      className={cn("relative", className)}
    >
      {loading && (
        <Loader2 className="h-10 w-10 animate-spin-slow absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      )}
      <svg
        onClick={onClick}
        className={cn(onClick && "cursor-pointer")}
        ref={svgRef}
        id="var-chart-svg"
        viewBox={aspect && `0 0 ${aspect.width} ${aspect.height}`}
      />
    </div>
  );
}

export { VariabilityChart };
