import { operatingLimitSchema } from "../../../lib/api-schema/operating-limit";
import { last } from "remeda";
import { YYYY_MM_DD } from "../../../lib/validators";
import { useOperatingLimitsProbabilityQuery } from "../../../hooks/tanstack-query";
import { Tooltip } from "@mantine/core";
import { BsCaretRight } from "react-icons/bs";
import { minutesToMilliseconds } from "date-fns";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";

export function ProbabilityToCrossPercentage(props: {
  limit: Pick<operatingLimitSchema, "level" | "_id">;
  start: YYYY_MM_DD;
  end: YYYY_MM_DD;
}) {
  const enabled = useAtomValue(Atoms.chartInViewportAtom);

  const probabilityQuery = useOperatingLimitsProbabilityQuery(
    {
      start: props.start,
      end: props.end,
      id: props.limit._id,
    },
    {
      enabled,
      staleTime: minutesToMilliseconds(15),
    }
  );

  const getLabel = () => {
    if (!probabilityQuery.data) return "-";
    const value = last(probabilityQuery.data)?.smoothedProbability[7] ?? 0;

    return `${Math.round(value * 100 + Number.EPSILON)}%`;
  };

  const label = getLabel();

  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-1 min-w-[3em] inline-flex items-center gap-1 justify-start">
      <BsCaretRight className="h-3 w-3" />
      <Tooltip
        label={`Probability to cross ${props.limit.level.toUpperCase()} in the next 7
          days`}
      >
        {/* key triggers animation  */}
        <span
          className="animate-in duration-300 fade-in-10 text-sm font-medium"
          key={label}
        >
          {label}
        </span>
      </Tooltip>
    </div>
  );
}
