import Select from "react-select";
import { type TeamForm } from "../../../types/api/Team";
import { Controller, useForm } from "react-hook-form";
import User from "../../../types/api/User";
import Button from "../../common/Button/Button";

type UseFormData = ReturnType<typeof useForm<TeamForm>>;

type PartialUseForm = Pick<UseFormData, "control" | "register"> & {
  onSubmit: ReturnType<UseFormData["handleSubmit"]>;
};

export function BaseForm({
  register,
  control,
  loading,
  onCancel,
  users,
  onSubmit,
  className,
}: PartialUseForm & {
  loading: boolean;
  onCancel: () => void;
  users: User[];
  className?: string;
}) {
  return (
    <form className={className} onSubmit={onSubmit}>
      <div className="form-control w-full">
        <label className="label uppercase pb-0 text-slate-700">
          <span className="">Team Name</span>
        </label>
        <input
          className="input-bordered input input-md w-full"
          disabled={loading}
          {...register("name", { required: true, minLength: 1 })}
        />
      </div>

      <Controller
        control={control}
        name="users"
        render={({ field: { onChange, value } }) => (
          <div className="form-control w-full">
            <label className="label uppercase pb-0 text-slate-700">
              <span className="">Users</span>
            </label>
            <Select
              isMulti={true}
              value={value}
              isClearable={false}
              placeholder="Select"
              isDisabled={loading}
              backspaceRemovesValue={false}
              options={users.map((user) => ({
                value: user._id,
                label: user.first + " " + user.last,
              }))}
              onChange={(_value) => {
                onChange(_value);
              }}
            />
          </div>
        )}
      />
      <div className="flex justify-between mt-6">
        <Button
          type="reset"
          size="sm"
          className="btn-outline btn-error"
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={loading}
          size="sm"
          className="btn-primary"
        >
          Save
        </Button>
      </div>
    </form>
  );
}
