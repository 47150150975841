import { useState, useEffect } from "react";
import { faultTreeNodeStatusSeriesSchema } from "../../../lib/api-schema/ft/fault-tree-node-status-series";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { getFaultTreeNodeStatusSeries } from "../../../frameworks/fetcher/api-routes-experimental";
import { useDateState } from "../../../zustand/useDateState";
import { produce } from "immer";
import { useGetUseFaultTreeDetailsStore } from "./ft-details-store";

export function useFTStatusSeriesCache() {
  const selectedNode = useGetUseFaultTreeDetailsStore()((s) => s.selectedNode);

  const $ds = useDateState();

  const baseUrl = useBaseUrlExperimental();

  const axisFrom = $ds.axisRangeFrom.dateString;
  const axisTo = $ds.axisRangeTo.dateString;

  const [selectedNodesSeriesMap, setSeriesMap] = useState<
    Record<string, faultTreeNodeStatusSeriesSchema[]>
  >({}); // when the user clicks on nodes, we fetch the series and cache it here

  const getRequestKey = (nodeId: string) => nodeId + axisFrom + axisTo;

  function getSeries(nodeId: string) {
    return selectedNodesSeriesMap[getRequestKey(nodeId)];
  }

  useEffect(() => {
    if (!selectedNode) return;

    const run = async () => {
      const requestKey = getRequestKey(selectedNode._id);

      if (!selectedNodesSeriesMap[requestKey]) {
        // fetch it
        getFaultTreeNodeStatusSeries(
          baseUrl,
          selectedNode._id,
          axisFrom,
          axisTo
        ).then(({ series }) => {
          setSeriesMap(
            produce((curr) => {
              curr[requestKey] = series;
            })
          );
        });
      }
    };

    void run();
  }, [selectedNode, axisFrom, axisTo, baseUrl, selectedNodesSeriesMap]);

  return getSeries;
}
