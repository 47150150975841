import { produce } from "immer";
import { useStore } from "jotai";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { create } from "zustand";

type JotaiStore = ReturnType<typeof useStore>;

type Chart = {
  primaryVariableId: string;
  chartJotaiStore: JotaiStore;
};

type CreateOrEditFolderStore = {
  data:
    | {
        charts: Chart[];
        editingFolderId: string | undefined; // undefined when creating a folder
      }
    | undefined;
  addChart: (primaryVariableId: string, jotaiStore: JotaiStore) => void;
  removeChart: (primaryVariableId: string) => void;
  enterEditMode: (initialCharts: Chart[], folderId: string) => void;
  enterCreateMode: (initialCharts: Chart[]) => void;
  exit: () => void;
};

function createUseCreateOrEditFolderStore() {
  return create<CreateOrEditFolderStore>()((set, get) => {
    return {
      removeChart: (variableId) => {
        const { data } = get();

        if (!data) throw new Error("not currently editing or creating");

        set({
          data: produce(data, (d) => {
            d.charts = d.charts.filter(
              (c) => c.primaryVariableId !== variableId
            );
          }),
        });
      },
      exit: () => {
        set({ data: undefined });
      },
      enterEditMode: (initialCharts, folderId) => {
        set({
          data: {
            editingFolderId: folderId,
            charts: initialCharts,
          },
        });
      },
      enterCreateMode: (initialCharts) => {
        set({
          data: {
            editingFolderId: undefined,
            charts: initialCharts,
          },
        });
      },
      data: undefined,
      addChart: (variableId, chartJotaiStore) => {
        const { data } = get();

        if (!data) throw new Error("not currently editing or creating");

        set({
          data: {
            ...data,
            charts: [
              // add to beginning of array
              {
                primaryVariableId: variableId,
                chartJotaiStore,
              },
              ...data.charts,
            ],
          },
        });
      },
    };
  });
}

type UseCreateOrEditFolderStore = ReturnType<
  typeof createUseCreateOrEditFolderStore
>;

const UseCreateOrEditFolderStoreContext = createContext<
  UseCreateOrEditFolderStore | undefined
>(undefined);

function UseCreateOrEditFolderStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(() => createUseCreateOrEditFolderStore());

  return (
    <UseCreateOrEditFolderStoreContext.Provider value={store}>
      {children}
    </UseCreateOrEditFolderStoreContext.Provider>
  );
}

function useGetUseCreateOrEditFolderStore() {
  const store = useContext(UseCreateOrEditFolderStoreContext);

  if (!store)
    throw new Error(
      "useGetUseCreateOrEditFolderStore must be used within a UseCreateOrEditFolderStoreProvider"
    );
  return store;
}

export { useGetUseCreateOrEditFolderStore, UseCreateOrEditFolderStoreProvider };
