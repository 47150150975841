const routes = {
  blob(filename, blob) {
    const objUrl = window.URL.createObjectURL(blob);

    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    let link = document.createElement("a");
    link.href = objUrl;
    link.download = filename;
    link.click();

    // FF hack
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  },
  csvFromString(filename, csvContent) {
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const objUrl = window.URL.createObjectURL(blob);

    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    let link = document.createElement("a");
    link.href = objUrl;
    link.download = filename;
    link.click();

    // FF hack
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  },
};

export default routes;
