import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtDeletedCamel, unit } from "../common";

export const faultTreeSchema = MongoIdUpdatedAtCreatedAtDeletedCamel.merge(
  unit
).extend({
  displayId: z.number().int(),
  name: z.string(),
  nodeCount: z.number().int(),
  published: z.boolean(),
  rootNodeId: z.string(),
  shortId: z.number().int(),
});

export type faultTreeSchema = z.infer<typeof faultTreeSchema>;
