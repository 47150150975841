import React from "react";
import _ from "lodash";
import BarChart from "../../charts/BarChart";
import useAPI from "../../../lib/useAPI";
import moment from "moment";
import { getRangeLabel } from "./utils";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minutesToMilliseconds } from "date-fns";

export default function OperatingLimitsExtremumCard({ id, instanceref }) {
  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();

  const api = useAPI();

  const b = useBaseUrlExperimental();

  const data = useQuery({
    enabled: useAtomValue(Atoms.chartInViewportAtom),
    staleTime: minutesToMilliseconds(5),
    queryKey: [b, "ol-hist", "extremum", id, start, end],
    queryFn: async () => {
      let time = await api.getOperatingLimitHistogram(
        id,
        start,
        end,
        "extremum"
      );

      return _.map(time.data, (day) => ({
        key: DateTime.fromISO(day.date, { zone: "utc" }).toISODate(),
        value: [day.value],
      }));
    },
  }).data;

  return <ExtremumCard data={data} instanceref={instanceref} />;
}

const ExtremumCard = React.memo(({ data, instanceref }) => {
  if (!data) {
    return null;
  }
  const filteredValues = data
    .map((d) => d.value[0])
    .filter((d) => d !== null && d !== undefined && d !== 0);
  const maxOrMinExtremum =
    filteredValues.length === 0
      ? 0
      : instanceref.type === "low"
        ? Math.min(...filteredValues)
        : Math.max(...filteredValues);
  const start = moment.utc(data[0].key);
  const end = moment.utc(data[data.length - 1].key);
  return (
    <div>
      <p className="absolute top-3 right-[29rem] text-[13px] font-bold">
        {instanceref.type === "low" ? "Minimum: " : "Maximum: "}
        {Math.round(filteredValues.at(-1) * 100) / 100} (
        {moment.utc(data[data.length - 1].key).format("MMM D")}),{" "}
        {Math.round(maxOrMinExtremum * 100) / 100} (
        {getRangeLabel(start, end, true)})
      </p>
      <BarChart
        classes={{
          BarChart: "OperatingLimitsDetails__BarChart",
          BarChart__xAxis: "OperatingLimitsDetails__BarChart__xAxis",
        }}
        chartTitle={`Extremums`}
        showTitle
        chartKeys={["Count"]}
        data={data}
        yAxisLabel={"Exceedances"}
        xAxisLabel={"Date"}
      />
    </div>
  );
});
