import React from "react";
import { cn } from "../../../lib/utils";

export function InputLabel({
  children,
  className,
}: React.ComponentProps<"label">) {
  return (
    <label
      className={cn(
        "label pb-0 text-slate-700 justify-start text-[1rem] tracking-tight",
        className
      )}
    >
      {children}
    </label>
  );
}
