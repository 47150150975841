import React, { useEffect, useState } from "react";
import useAPI from "../../../lib/useAPI";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import AddonRoute from "../../common/AddonRoute";
import MainLayout from "../../layouts/MainLayout";
import { Loader, LoadingOverlay, Tooltip } from "@mantine/core";
import { cn } from "../../../lib/utils";
import * as NotificationCard from "../dropdown/notif-card";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import Button from "../../common/Button/Button";
import download from "../../../lib/download";
import "./NotificationsManager.scss";
import { addToast } from "../../toast/use-toast-store";
import { patchNotification_TODO_TYPE_THIS } from "../../../frameworks/fetcher/api-routes-experimental";
import { InstantCalculator } from "../../instant-calculator/InstantCalculator";
import validateExpression from "../../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../../shared-ui/expression-building-input/constants";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import Notification from "../../../types/api/Notification";
import Variable from "../../../types/api/Variable";

const PAGE_NAME = "Notifications";

export default function Notifications() {
  const api = useAPI();
  const baseUrl = useBaseUrlExperimental();
  const [loaded, setLoaded] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  // empty string means create new, otherwise it's the id of the notification (or null if not editing)
  const [editing, setEditing] = useState<string | null>(null);
  const [filter, setFilter] = useState("");
  const allVariables = useVariablesArrayQuery();
  const symbols = allVariables.data?.map((o) => o.name) || [];

  useEffect(() => {
    api.getUserNotifications().then((notifs) => {
      setNotifications(notifs as unknown as Notification[]);
      setLoaded(true);
    });
  }, [api]);
  const count = notifications.length;
  const [expression, setExpression] = useState<string>("");

  return (
    <AddonRoute addonKey="realTime">
      <MainLayout showDateNav={false}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 px-4 2xl:px-[10rem]">
            <LoadingOverlay visible={!loaded} overlayBlur={2} />
            {loaded ? (
              <>
                <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
                  <div className="flex items-center mb-2 sm:mb-0">
                    <span className="text-[2rem] sm:text-[1.75rem] mr-2">
                      {PAGE_NAME}
                    </span>
                    <CreateNewEntityButton onClick={() => setEditing("")} />
                  </div>

                  <div>
                    {notifications?.length != null && (
                      <span className="mr-5 italic text-[1.25rem] text-titlegrey">
                        Displaying {notifications.length} notifications
                      </span>
                    )}
                    <Button
                      className="btn-ghost"
                      icon="download"
                      iconClasses="mr-1"
                      onClick={async () => {
                        const csvString = await api.downloadNotifications();
                        download.csvFromString("Notifications.csv", csvString);
                      }}
                    >
                      download
                    </Button>
                  </div>
                </div>
                <div className="w-full">
                  {/* Empty message */}
                  {count ? null : (
                    <div className="EmptyMessage Manager__EmptyMessage">
                      <p>No notifications to display.</p>
                      <br />
                      <p>
                        <i
                          className="EmptyMessage__icon fa fa-plus"
                          aria-hidden="true"
                        ></i>
                        Create a custom notification or{" "}
                        <i
                          className="EmptyMessage__icon fa fa-bell-o"
                          aria-hidden="true"
                        ></i>
                        subscribe to <strong>DRA</strong> notifications.
                      </p>
                    </div>
                  )}

                  {/* Search */}
                  {count ? (
                    <input
                      type="text"
                      className="input w-full mb-4 mt-2"
                      placeholder="Search by Notification Type, Rule, Date, or Frequency"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onInput={(e) => setFilter((e.target as any).value)}
                      value={filter}
                    />
                  ) : null}

                  <ul className="pl-0 list-none NotificationsManager__list mb-12">
                    {(
                      [
                        ...(editing === ""
                          ? [
                              {
                                _id: "",
                                schedule: { type: "Once" },
                                userId: "",
                                description: "",
                                type: "Custom",
                                pending: false,
                                enabled: true,
                                criteria: 10,
                                updatedAt: "",
                                createdAt: "",
                              },
                            ]
                          : []),
                        ...notifications,
                      ] as Notification[]
                    )
                      .filter((notification) => {
                        const search = filter.toLowerCase();
                        const strings = [
                          notification.description,
                          formattedType(notification),
                          notification.schedule.type,
                          (notification.lastSend || "").toString(),
                        ].map((string) => string.toLowerCase());
                        return strings.some((string) =>
                          string.includes(search)
                        );
                      })
                      .sort((a, b) => {
                        return (
                          new Date(b.updatedAt || b.createdAt).valueOf() -
                          new Date(a.updatedAt || a.createdAt).valueOf()
                        );
                      })
                      .map((notification) => {
                        const id = notification._id;
                        const destination = ((type) => {
                          if (!notification.variable) {
                            return null;
                          }

                          switch (type) {
                            case "DRA":
                              return `./pb?dv=${notification.variable.short_id}`;
                            case "Slope":
                              return `./pb?dv=${notification.variable.short_id}`;
                            case "FaultTreeNode":
                              return `./ft/details/${notification.variable.ft_node_fault_tree_id}?selectednode=${notification.variable._id}`;
                            case "OperatingLimit":
                              return `./of/details/${notification.variable.groups[0]}?shortIds=${notification.variable.short_id}`;
                            case "Custom":
                              return `./pb?dv=${
                                (notification.calculatedVariableList
                                  ?.map((variable) => variable.short_id)
                                  .join(",") ?? "") satisfies string
                              }`;
                            case "Pattern":
                              return `./pb?dv=${notification.variable.short_id}`;
                            default:
                              return null;
                          }
                        })(notification.type);

                        const isEditMode = editing === id;

                        return (
                          <NotificationCard.Container
                            key={id}
                            notification={notification}
                            className={cn(
                              isEditMode && "bg-white hover:bg-white"
                            )}
                          >
                            <div
                              className={`flex flex-col gap-3 ${isEditMode ? "hidden" : ""}`}
                            >
                              <div className="justify-between flex items-center px-3 pt-2">
                                <div className="NotificationsManager__badges">
                                  <span
                                    className={cn(
                                      "rounded-full tracking-tight px-3 text-[0.9rem] border",
                                      notification.enabled
                                        ? `NotificationsManager__badge--${notification.type}`
                                        : "bg-zinc-50"
                                    )}
                                  >
                                    {formattedType(notification)}
                                  </span>
                                </div>

                                {!loaded ? (
                                  <Loader color="dark" size={25} />
                                ) : (
                                  <div className="items-center flex">
                                    {notification.type === "Custom" && (
                                      <Tooltip label="Edit" withArrow>
                                        <Button
                                          className="btn-ghost"
                                          onClick={() => {
                                            setEditing(id);
                                            setExpression(
                                              notification.description
                                            );
                                          }}
                                          icon="pencil"
                                        />
                                      </Tooltip>
                                    )}

                                    <Tooltip label="Delete" withArrow>
                                      <Button
                                        className="btn-ghost"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Are you sure you want to delete this Notification?"
                                            )
                                          ) {
                                            api
                                              .deleteNotification(id)
                                              .then(() => {
                                                setNotifications((prev) =>
                                                  prev.filter(
                                                    (n) => n._id !== id
                                                  )
                                                );
                                                addToast({
                                                  variant: "success",
                                                  title: "Notification deleted",
                                                });
                                              });
                                          }
                                        }}
                                        icon="trash-o"
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      label={
                                        notification.enabled
                                          ? "Disable Notification"
                                          : "Enable Notification"
                                      }
                                      withArrow
                                    >
                                      <input
                                        checked={notification.enabled}
                                        className="toggle toggle-sm checked:bg-primary checked:border-primary ml-2"
                                        type="checkbox"
                                        onChange={() =>
                                          api
                                            .toggleNotification(
                                              notification._id
                                            )
                                            .then(() => {
                                              setNotifications((prev) =>
                                                prev.map((n) =>
                                                  n._id === notification._id
                                                    ? {
                                                        ...n,
                                                        enabled: !n.enabled,
                                                      }
                                                    : n
                                                )
                                              );
                                              addToast({
                                                variant: "success",
                                                title: `Notification ${
                                                  notification.enabled
                                                    ? "disabled"
                                                    : "enabled"
                                                }`,
                                              });
                                            })
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                              </div>

                              <div className="flex">
                                <NotificationCard.RuleDisplayOptionalMiniChart
                                  notification={notification}
                                  variableId={notification.variableId}
                                />

                                {destination ? (
                                  <Tooltip
                                    label="Navigate to Dashboard"
                                    withArrow
                                  >
                                    <a
                                      href={destination}
                                      className="btn btn-sm btn-ghost ml-auto mt-auto mr-2"
                                    >
                                      <i className="fa fa-arrow-circle-o-right" />
                                    </a>
                                  </Tooltip>
                                ) : null}
                              </div>

                              <NotificationCard.Footer
                                notification={notification}
                              />
                            </div>

                            {/* Edit Mode */}
                            {isEditMode ? (
                              <form
                                className="p-4"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  try {
                                    validateExpression(
                                      expression,
                                      symbols,
                                      FUNCTIONS,
                                      true
                                    );
                                    const formData = new FormData(
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      e.target as any
                                    );
                                    const data = {
                                      customExpression: expression,
                                      type: "Custom",
                                      schedule: {
                                        type:
                                          formData.get("scheduleType") ||
                                          "Once",
                                      },
                                      // todo what's this?
                                      criteria: 10,
                                    };

                                    const createOrEdit =
                                      id === ""
                                        ? api.createNotification(data)
                                        : patchNotification_TODO_TYPE_THIS(
                                            baseUrl,
                                            id,
                                            data
                                          );
                                    createOrEdit.then(() => {
                                      api
                                        .getUserNotifications()
                                        .then((notifs) => {
                                          setNotifications(
                                            notifs as unknown as Notification[]
                                          );
                                        });
                                      setEditing(null);
                                      addToast({
                                        variant: "success",
                                        title: "Notification saved",
                                      });
                                    });
                                  } catch (e) {
                                    if (e instanceof Error) {
                                      addToast({
                                        variant: "danger",
                                        title: "Error saving notification",
                                        description: e.message,
                                      });
                                    }
                                  }
                                }}
                              >
                                {editing === "" && (
                                  <div className="tracking-tight mt-1 mb-4">
                                    Create a custom notification
                                  </div>
                                )}

                                <label>
                                  <span className="tracking-tight">Rule:</span>
                                  {/* {notification.type === "Custom" && (
                                  <Tooltip
                                    label={`'Notification Rule is a Boolean expression (True/False), built using Tag IDs and DRA operators, e.g. rolling_mean("TI100.PV", 1) > 100. Note that Tag IDs are case-sensitive and must be referenced in double quotes.'`}
                                  >
                                    <i className="fa fa-info" />
                                  </Tooltip>
                                )} */}
                                </label>

                                <InstantCalculator
                                  onInput={setExpression}
                                  booleanExpression={true}
                                  defaultValue={notification.description}
                                />

                                <label className="mt-6 tracking-tight">
                                  Frequency:
                                </label>
                                <select
                                  defaultValue={notification.schedule.type}
                                  name="scheduleType"
                                  className="select select-bordered font-normal w-full"
                                >
                                  {[
                                    {
                                      value: "Once",
                                      label:
                                        "Notify immediately for first occurrence of the day | Once a day notification",
                                    },
                                    {
                                      value: "Every_Occurance",
                                      label:
                                        "Notify immediately | Multiple notifications a day",
                                    },
                                    /* {value: 'Summary', label: 'Summary, Daily at 9:00AM'} */
                                  ].map(({ label, value }) => (
                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ))}
                                </select>

                                <div className="Manager__entityEdit__controls">
                                  <Button
                                    className="btn-outline btn-error"
                                    onClick={() => setEditing(null)}
                                  >
                                    Cancel
                                  </Button>

                                  <Button type="submit" className="btn-primary">
                                    Save
                                  </Button>
                                </div>
                              </form>
                            ) : null}
                          </NotificationCard.Container>
                        );
                      })}
                  </ul>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </MainLayout>
    </AddonRoute>
  );
}

function formattedType(notification: Notification) {
  switch (notification.type) {
    case "DRA":
      return "DRA";
    case "Slope":
      return "Sloping Trend";
    case "FaultTreeNode":
      return "Fault Trees";
    case "OperatingLimit":
      return "Operating Fitness";
    case "Custom":
      return "Custom";
    case "Pattern":
      return "Pattern";
    default:
      return "";
  }
}
