function LineBreak({ color = "#a3d875" }: { color: string }) {
  return (
    <hr
      style={{
        height: "0.25px",
        background: color,
        border: `0.5px solid ${color}`,
      }}
    />
  );
}

export default LineBreak;
