import moment from "moment";
import * as R from "remeda";
import { variableSchema } from "../../../lib/api-validators";
import useCurrentUnitObject, {
  useHasMultipleHistorians,
} from "../../common/hooks/useCurrentUnitObject";
import ExpressionRow from "../../common/manager/ExpressionRow";
import { DoubleBadge } from "../../ui/double-badge";
import PillRow from "../../common/manager/PillRow";
import {
  useClustersQuery,
  useGroupsQuery,
} from "../../../hooks/tanstack-query";
import { cn } from "../../../shared-ui/frontend/cn";
import { minutesToMilliseconds } from "date-fns";
import { useMemo } from "react";
import { useTagCardContext } from "./tag-card-context";
import { useQuery } from "@tanstack/react-query";
import { getReferencingVariables } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";

export function useTagReferences(tagId: string) {
  const { hasOpenedOnce, open } = useTagCardContext();

  const baseUrl = useBaseUrlExperimental();

  const referencesQuery = useQuery({
    queryKey: [baseUrl, "referencingVariables", tagId],
    queryFn: () => getReferencingVariables(baseUrl, tagId),
    enabled: open && hasOpenedOnce,
    staleTime: minutesToMilliseconds(1),
  });

  return useMemo(() => {
    if (!referencesQuery.data) return undefined;
    if (!open) return undefined;

    const refs = {
      "Fault Tree Nodes": [] as string[],
      "Calculated Tags": [] as string[],
      "Notifications (for all Users)": [] as string[],
      "Shutdown Rules": [] as string[],
      "Operating Modes": [] as string[],
    };

    for (const v of referencesQuery.data) {
      switch (v.type) {
        case "Tag":
          v.expression && refs["Calculated Tags"].push(v.expression);
          continue;
        case "FaultTreeNode":
          v.trimmedName && refs["Fault Tree Nodes"].push(v.trimmedName);
          continue;
        case "Notification":
          v.trimmedName &&
            refs["Notifications (for all Users)"].push(v.trimmedName);
          continue;
        case "ShutdownRule":
          v.trimmedName && refs["Shutdown Rules"].push(v.trimmedName);
          continue;
        case "OperatingMode":
          v.trimmedName && refs["Operating Modes"].push(v.trimmedName);
          continue;
      }
    }

    if (!Object.values(refs).some((v) => v.length > 0)) return undefined;

    return refs;
  }, [referencesQuery.data, open]);
}

function Separator() {
  return <hr className="border-2" />;
}

function TagData({ tag }: { tag: variableSchema }) {
  const isCalculatedTag = !!tag.expression?.trim?.().length;

  const currentUnit = useCurrentUnitObject();

  if (!currentUnit) throw new Error("No current unit");

  const hasMultipleHistorians = useHasMultipleHistorians();

  const groupsQuery = useGroupsQuery();
  const clustersQuery = useClustersQuery();

  const refs = useTagReferences(tag._id);

  const groupNames = useMemo(() => {
    return R.filter(
      tag.groups.map((g) => groupsQuery.data?.find((gg) => gg._id === g)?.name),
      R.isTruthy
    );
  }, [tag.groups, groupsQuery.data]);

  const timestamp = (
    <div className="Manager__entity__label mb-1 flex justify-end">
      <span className="mr-2">
        {`Last updated: ${moment(tag.updated_at).format("LLL")}`}
      </span>
    </div>
  );
  return (
    <>
      {isCalculatedTag && tag.expression && (
        <>
          <Separator />
          <div className="px-[1rem]">
            <ExpressionRow expression={tag.expression} />
          </div>
        </>
      )}
      <Separator />
      <div className="text-[1rem] px-[1rem] pt-[0.4rem] pb-[0.9rem]">
        <ul className="text-[1rem]">
          <LeftAndRightPill
            label="Units of Measurement"
            value={tag.units_of_measurement || "NONE"}
            on={!!tag.units_of_measurement}
          />
          <LeftAndRightPill
            label="High Side"
            value={tag.high_side ? "ON" : "OFF"}
            on={tag.high_side}
          />
          <LeftAndRightPill
            label="Low Side"
            value={tag.low_side ? "ON" : "OFF"}
            on={tag.low_side}
          />
          <LeftAndRightPill
            label="Sloping Trend: Negative Slope"
            value={
              tag.st_enabled ? (tag.hide_negative_slopes ? "OFF" : "ON") : "OFF"
            }
            on={!!tag.st_enabled && !tag.hide_negative_slopes}
          />
          <LeftAndRightPill
            label="Sloping Trend: Positive Slope"
            value={
              tag.st_enabled ? (tag.hide_positive_slopes ? "OFF" : "ON") : "OFF"
            }
            on={!!tag.st_enabled && !tag.hide_positive_slopes}
          />
          {!!tag.expression && (
            <LeftAndRightPill
              label="Inherit Shutdown Rules from Base Tags"
              value={tag.inherit_sd ? "ON" : "OFF"}
              on={tag.inherit_sd}
            />
          )}
          {hasMultipleHistorians && (
            <DoubleBadge
              left={{
                children: "Historian",
                variant: "slate",
              }}
              right={{
                variant: "outline",
                children: tag.historian ?? currentUnit.historian.key,
              }}
            />
          )}
        </ul>
      </div>
      {groupNames.length > 0 && (
        <>
          <Separator />
          <div className="px-[1rem] py-[0.4rem]">
            <PillRow keyPrefix={tag._id} items={groupNames} label="Groups" />
          </div>
        </>
      )}
      {clustersQuery.data && tag.families.length > 0 && (
        <>
          <Separator />
          <div className="px-[1rem] py-[0.4rem]">
            <PillRow
              keyPrefix={tag._id}
              items={R.filter(
                tag.families.map((_id) => {
                  return clustersQuery.data.find((c) => c._id === _id)?.name;
                }),
                R.isTruthy
              )}
              label="Clusters"
            />
          </div>
        </>
      )}
      {refs ? (
        <>
          <Separator />
          <div className="bg-refbluebg px-[1rem] py-3">
            {Object.entries(refs || []).map(([refName, refValues]) => {
              if (!refValues.length) return undefined;
              return (
                <PillRow
                  key={tag._id + refName}
                  keyPrefix={tag._id + refName}
                  items={refValues}
                  label={refName}
                />
              );
            })}
            {timestamp}
          </div>
        </>
      ) : (
        timestamp
      )}
    </>
  );
}

function LeftAndRightPill({
  label,
  value,
  on,
}: {
  label: string;
  value: string;
  on: boolean;
}) {
  return (
    <li className="inline-block mt-2 mr-2">
      <DoubleBadge
        left={{
          children: label,
          variant: "slate",
        }}
        right={{
          variant: "outline",
          className: cn(on ? "text-emerald-500" : "text-red-500"),
          children: value,
        }}
      />
    </li>
  );
}

export { TagData };
