import {
  FaComment as Comment,
  FaCheck,
  FaExclamationTriangle as ExclamationTriangle,
  FaFilter,
} from "react-icons/fa";
import { Check, X } from "lucide-react";
import { BsFillLightningChargeFill, BsClock as Clock } from "react-icons/bs";
import { useId } from "react";
import { ANOMALY_LEVELS_DESC } from "../../../types/api/Anomaly";
import { useGetUseDriStore } from "../hooks/create-use-dri-store";
import { produce } from "immer";
import { BsChevronRight, BsChevronDoubleRight } from "react-icons/bs";
import { Collapse, LoadingOverlay, Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import useSearchParamsEnhanced from "../../boundaries/hooks/useSearchParamsEnhanced";
import { ellipsify } from "../../utils/stylable";
import type { FormattedDataInner } from "../hooks/types";
import { getAcknowledgements } from "../../../frameworks/fetcher/api-routes-experimental";
import { useQuery } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useDateState } from "../../../zustand/useDateState";
import { cn } from "../../../lib/utils";
import {
  useClustersQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { Badge } from "../../ui/badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { Button as NewButton } from "../../../shared-ui/frontend/button";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Input } from "../../../shared-ui/frontend/input";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import { useTagsFilterStoreContext } from "../../tags/use-tags-filter-store";
import { useTagsFilter } from "../../tags/use-tags-filter";

const DEGREE_SYMBOL_UNICODE = "\u00B0";

export function Sidebar() {
  const useDriStore = useGetUseDriStore();
  const loading = useDriStore((s) => s.loading);
  const sidebarOpen = useDriStore((s) => s.sidebarOpen);
  const setSidebarOpen = useDriStore((s) => s.setSidebarOpen);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  // filter out variables based on tag filters
  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomalyTimeFilter = tagsFilterStore((s) => s.anomalyTimeFilter);
  const canShowVariable = useTagsFilter(tagFilters, anomalyTimeFilter);

  return (
    <>
      <div
        className={cn(
          "w-[24rem] SIDEBAR SIDEBAR-SINGLE min-w-[24rem] z-50 border-r border-zinc-300 overflow-visible bg-base-100 mt-3",
          sidebarOpen ? "expand-sidebar" : "collapse-sidebar"
        )}
        onAnimationEnd={(e) => {
          const el = e.target as HTMLElement;
          el.classList.contains("collapse-sidebar")
            ? el.classList.add("hidden")
            : el.classList.remove("hidden");
        }}
      >
        <LoadingOverlay visible={loading} overlayBlur={1} />
        <ViewSelector />
        <SingleGroupInSidebar
          dataKey={"watch"}
          canShowVariable={canShowVariable}
        />
        {ANOMALY_LEVELS_DESC.map((anomInt, idx, arr) => {
          return (
            <SingleGroupInSidebar
              dataKey={anomInt}
              key={anomInt.toString()}
              isLast={idx === arr.length - 1}
              canShowVariable={canShowVariable}
            />
          );
        })}
      </div>
      {sidebarOpen ? (
        <button
          onClick={toggleSidebar}
          className="expand-sidebar-handle bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4 border-xindigo-7 border hover:border-xindigo-8 h-28 w-4 rounded-r-lg fixed cursor-pointer top-[60%] -translate-y-[60%] flex items-center transition-all hover:scale-110 z-40"
        >
          <IoChevronBackOutline className="size-4" />
        </button>
      ) : (
        <button
          onClick={toggleSidebar}
          className="collapse-sidebar-handle bg-xindigo-3 text-xindigo-11 hover:bg-xindigo-4 border-xindigo-7 border hover:border-xindigo-8 h-28 w-4 rounded-r-lg fixed cursor-pointer top-[60%] -translate-y-[60%] flex items-center transition-all hover:scale-110 z-40"
        >
          <IoChevronForwardOutline className="size-4" />
        </button>
      )}
    </>
  );
}

function ViewSelector() {
  const useDriStore = useGetUseDriStore();
  const isPlainView = useDriStore((s) => s.viewMode === "plain");
  const clustersQuery = useClustersQuery();

  return (
    <div className="flex justify-between items-center pl-4 pr-2 py-2 sticky top-0 bg-base-100 border-b-[3px] border-zinc-600 z-50">
      <div className="flex flex-row items-center">
        <h3 className="text-[1.1rem] font-bold">Tags</h3>
      </div>
      {clustersQuery.data && clustersQuery.data.length > 0 && (
        <div className="btn-group text-neutral">
          <Button
            size="xs"
            className={cn(isPlainView ? "btn-outline" : "btn-neutral")}
            onClick={() => useDriStore.getState().setViewMode("cluster")}
          >
            Cluster View
          </Button>
          <Button
            size="xs"
            className={cn(isPlainView ? "btn-neutral" : "btn-outline")}
            onClick={() => useDriStore.getState().setViewMode("plain")}
          >
            Plain View
          </Button>
        </div>
      )}
    </div>
  );
}

function SingleGroupInSidebar({
  dataKey,
  isLast,
  canShowVariable,
}: {
  dataKey: keyof FormattedDataInner;
  isLast?: boolean;
  canShowVariable: (vid: string) => boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParamsEnhanced();
  const useDriStore = useGetUseDriStore();
  const isOpen = useDriStore((s) => s.listOpen[dataKey]);
  const clusters = useDriStore((s) => s.clusters);
  const data = useDriStore(
    ({ anomalies, viewMode }) => anomalies?.[viewMode][dataKey] ?? []
  );
  const viewMode = useDriStore((s) => s.viewMode);
  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const active = isOpen;
  const isWatchlist = dataKey === "watch";
  const title = isWatchlist
    ? "Watchlist"
    : `${dataKey}${DEGREE_SYMBOL_UNICODE} Anomaly`;
  const id = useId();

  const handleOpenListClick = () => {
    useDriStore.setState((s) => {
      return {
        listOpen: produce(s.listOpen, (curr) => {
          curr[dataKey] = !curr[dataKey];
        }),
      };
    });
  };

  const variablesMapQuery = useVariablesMappedByIdQuery();

  const isPlainView = viewMode === "plain";
  const showCluster = !isWatchlist;

  const baseUrlSlash = useBaseUrlExperimental();

  const {
    axisRangeTo: { dateString: axisTo },
  } = useDateState();
  const ids = data.map((a) => a._id);
  const acksQuery = useQuery({
    queryKey: ["acks", ids, axisTo, axisTo, baseUrlSlash],
    queryFn: () =>
      getAcknowledgements(baseUrlSlash, {
        end: axisTo,
        start: axisTo,
        varIds: ids,
      }),
    enabled: ids.length > 0,
  });

  const hasData = data.length > 0;

  if (variablesMapQuery.isError || variablesMapQuery.isLoading) return null;

  return (
    <div key={title}>
      <label
        className={cn(
          "tab-label flex items-center gap-2 p-2 pl-5 tracking-tight group",
          hasData
            ? "cursor-pointer hover:font-medium hover:bg-xslate-3"
            : "cursor-not-allowed",
          active && "text-indigo-800 bg-zinc-100"
        )}
        // watchlist label receives no color on the bottom
        style={{
          borderBottomWidth: "3px",
          borderBottomColor: isWatchlist
            ? "#3730a3"
            : `rgb(var(--anom${dataKey})`,
        }}
        htmlFor={id}
        onClick={data.length === 0 ? undefined : handleOpenListClick}
      >
        <div className={cn("transition-transform", isOpen && "rotate-90")}>
          <BsChevronRight />
        </div>
        <span className="group-hover:ml-1 transition-all">{title}</span>
        {viewMode === "plain" && (
          <Badge variant={"outline"}>
            {ids.filter((id) => canShowVariable(id)).length}
          </Badge>
        )}
        <Tooltip label="Show All" position="left" withArrow>
          <Button
            disabled={!hasData}
            className="ml-auto text-[18px] btn-ghost"
            icon={BsChevronDoubleRight}
            onClick={(e) => {
              e.stopPropagation(); // prevent the list from expand/collapse

              if (hasData) {
                setSearchParams({
                  v: "",
                  dv: data.map(({ short_id }) => short_id.toString()),
                });
                useDriStore.setState((s) => {
                  return {
                    showAllMode: true,
                  };
                });
              }
            }}
          />
        </Tooltip>
      </label>
      <Collapse in={isOpen}>
        <div className={cn("tab-content", isLast && "mb-16")}>
          <div className="flex flex-col text-[13px] bg-white">
            {data.map((a) => {
              if (!canShowVariable(a._id)) return null;

              if (!clusters) throw new Error("");

              let urlVariableString = a.short_id.toString();

              if (a.associatedFamilyId && !isWatchlist) {
                const family = clusters[a.associatedFamilyId];
                if (!family) throw new Error("");

                urlVariableString += `-${family._id}`;
              }

              const isActive = searchParams.get("v") === urlVariableString;

              const shouldShowDegIndicators =
                !isPlainView &&
                showCluster &&
                a.associatedFamilyId &&
                a.hasIndicators;

              const indicatorLevels =
                shouldShowDegIndicators && a.indicators
                  ? Object.entries(a.indicators)
                      .filter(([_, on]) => on)
                      .sort(([a], [b]) => parseInt(b) - parseInt(a))
                      .map(([deg]) => {
                        return parseInt(deg);
                      })
                  : undefined;

              /**
               * If this list should show cluster data, AND its currently cluster view mode, AND this specific variable does have cluster data associated
               */
              const getClusterPillWithCountAndColorsMaybe = () => {
                if (
                  clusters &&
                  showCluster !== false &&
                  !isPlainView &&
                  a.associatedFamilyId
                ) {
                  const family = clusters[a.associatedFamilyId];
                  if (!family) throw new Error("");
                  return (
                    <div className="flex items-stretch max-w-full mt-1">
                      <div className="inline-flex max-w-max rounded-l-md px-2 border-zinc-300 border-y border-l items-center gap-2 bg-white h-5 grow">
                        <span className="text-xs text-ellipsis whitespace-nowrap grow tracking-tight">
                          {family.type === "dynamic" && (
                            <BsFillLightningChargeFill className="h-3 w-3 mb-[1px] mr-1 inline" />
                          )}
                          {ellipsify(family.name, 30)}
                        </span>
                        <span className="leading-[0.72rem] h-3.5 bg-zinc-200 rounded-full px-1 text-[0.7rem] text-zinc-500 border border-zinc-300">
                          {family.variables.length}
                        </span>
                      </div>
                      {indicatorLevels && (
                        <div className="inline-flex items-center">
                          {indicatorLevels.map((lvl) => {
                            return (
                              <div
                                key={`indc-${lvl}`}
                                className="w-4 h-full last:rounded-r-md"
                                style={{
                                  backgroundColor: `rgb(var(--anom${lvl}))`,
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }

                return null;
              };
              const clusterPillWithCountAndColorsMaybe =
                getClusterPillWithCountAndColorsMaybe();

              const ack = acksQuery.data?.find((ack) => ack.variable === a._id);
              const ackType = ack ? ack.type : null;
              const ackTypeIcon =
                ackType === "normal" ? <FaCheck /> : <ExclamationTriangle />;
              const leftSideCommentOrAckIcons = (
                <div className="flex flex-col gap-1 absolute top-1 left-[-13px] text-[11px] text-zinc-400">
                  {ack && ack.unacknowledgment === false ? ackTypeIcon : null}
                  {a.comment ? <Comment /> : null}
                </div>
              );

              return (
                <div
                  /**
                   * This is extremely important. Duplicating keys for this components creates a world of a headache
                   * for list items duplicating/missing randomly.
                   */
                  key={`${title}-${a._id}${a.associatedFamilyId ?? ""}`}
                  onClick={() => {
                    const base = { v: urlVariableString };
                    if (a.associatedFamilyId && !isWatchlist) {
                      const family = a.cluster;
                      if (!family) throw new Error("");

                      const shorts = family.variables.reduce((arr, vid) => {
                        const variable = variablesMapQuery.data[vid];
                        variable && arr.push(variable.short_id.toString());

                        return arr;
                      }, [] as string[]);

                      setSearchParams({
                        ...base,
                        dv: shorts,
                      });

                      setChartViewMode("grid");
                    } else {
                      setSearchParams({
                        ...base,
                        dv: base.v,
                      });
                      setChartViewMode("list");
                    }
                    useDriStore.setState({
                      showAllMode: false,
                    });
                  }}
                  className={cn(
                    "min-h-[3.5rem] py-2 px-5 flex gap-3 group border-slate-300 not-first:border-t last:border-b active:border-transparent items-center transition-all",
                    isActive
                      ? "bg-xindigo-4"
                      : "hover:bg-xslate-3 hover:text-indigo-800 active:scale-95 active:rounded-lg hover:cursor-pointer"
                  )}
                >
                  <div
                    className={cn(
                      "inline-flex flex-col lowercase relative",
                      isActive && "font-medium"
                    )}
                  >
                    <span className="text-md break-all">{a.trimmedName}</span>
                    <span className="text-xslate-11 tracking-tight break-all">
                      {ellipsify(a.description, 80)}
                    </span>
                    {leftSideCommentOrAckIcons}
                    {clusterPillWithCountAndColorsMaybe}
                  </div>
                  {a.time ? (
                    <span className="inline-flex items-center gap-1 text-[0.7rem] ml-auto">
                      <Clock />
                      {a.time_formatted}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
