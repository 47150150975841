import { useState } from "react";
import Team, { type TeamForm } from "../../../types/api/Team";
import { useForm } from "react-hook-form";
import { TeamCardProps } from "./types";
import User from "../../../types/api/User";
import { z } from "zod";
import { BaseForm } from "./base-form";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

export function EditTeamForm({
  team,
  users,
  usersMap,
  saveTeam,
  closeForm,
}: Pick<TeamCardProps, "users" | "saveTeam"> & {
  team: Team;
  usersMap: Record<string, User>;
  closeForm: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const getDefaultValues = () => {
    const out: TeamForm = {
      name: team.name,
      users: team.users.map((user) => {
        const userObj = usersMap[user];
        if (!userObj) throw new Error("User not found");

        return {
          value: user,
          label: userObj.first + " " + userObj.last,
        };
      }),
    };

    return out;
  };

  const { control, register, reset, handleSubmit } = useForm<TeamForm>({
    defaultValues: getDefaultValues(),
  });

  const onSubmit = handleSubmit(async (data: TeamForm) => {
    /* Format users from select to actual backend data-type. */
    const formattedUsers = data.users.map((user) => user.value);

    const payload: Team = {
      ...team,
      name: data.name,
      users: formattedUsers,
    };

    /* Pre-save check. We only want this to be clientside; it's not desirable
     * for the user to create a team with no members, but it can happen and it's
     * not a big deal if a team ends up with zero users. If we start adding more
     * validation in the future, this should be separated out into another file. */
    const preCheck = z
      .object({
        name: z.string({
          required_error: "Team name is required",
        }),
        users: z.string().array().nonempty({
          message: "Include at least one team member",
        }),
      })
      .safeParse(payload);

    if (!preCheck.success) {
      addToast({
        title: preCheck.error.issues.map((data) => data.message).join(" "),
        variant: "danger",
      });
      return;
    }

    /* Both `create` and `update` just take a payload. If updating, pull the _id
     * off of the payload. */
    try {
      setLoading(true);
      const result = await saveTeam(payload);
      reset(); /* Clear form on creation. */
      addSuccessToast("Team saved successfully");
      closeForm();
      return result;
    } catch (err) {
      addUnknownErrorToast(err);
    } finally {
      setLoading(false);
    }
  });

  return (
    <BaseForm
      onSubmit={onSubmit}
      className="p-4 form-control w-full"
      control={control}
      loading={loading}
      onCancel={closeForm}
      register={register}
      users={users}
    />
  );
}
