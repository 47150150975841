import React from "react";
import _ from "lodash";
import BarChart from "../../charts/BarChart";
import useAPI from "../../../lib/useAPI";
import moment from "moment";
import { getRangeLabel } from "./utils";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { minutesToMilliseconds } from "date-fns";

export default function OperatingLimitsFrequencyCard({ id }) {
  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();
  const API = useAPI();

  const b = useBaseUrlExperimental();

  const data = useQuery({
    enabled: useAtomValue(Atoms.chartInViewportAtom),
    staleTime: minutesToMilliseconds(5),
    queryKey: [b, "ol-hist-freq", id, start, end],
    queryFn: async () => {
      const dataProm = API.getOperatingLimitHistogram(
        id,
        start,
        end,
        "frequency"
      ).then((time) =>
        _.map(time.data, (day) => ({
          key: DateTime.fromISO(day.date, { zone: "utc" }).toISODate(),
          value: [day.value],
        }))
      );
      const total = await API.getOperatingLimitTotalExceedances(id, start, end);

      return {
        total,
        data: await dataProm,
      };
    },
  }).data;

  if (!data) return;

  return (
    <FrequencyCard
      data={data.data}
      total={data.total}
      last={data[data.length - 1]?.value[0]}
    />
  );
}

const FrequencyCard = React.memo(({ data, total, last }) => {
  if (!data.length) {
    return null;
  }
  const start = moment.utc(data[0].key);
  const end = moment.utc(data[data.length - 1].key);
  return (
    <div>
      <p className="absolute top-3 right-[29rem] text-[13px] font-bold">
        Exceedances: {last} ({end.format("MMM D")}), {total} (
        {getRangeLabel(start, end, true)})
      </p>
      <BarChart
        classes={{
          BarChart: "OperatingLimitsDetails__BarChart",
          BarChart__xAxis: "OperatingLimitsDetails__BarChart__xAxis",
        }}
        chartTitle={`Number of Exceedances`}
        showTitle={true}
        chartKeys={["Count"]}
        data={data}
        yAxisLabel={"Exceedances"}
        xAxisLabel={"Date"}
      />
    </div>
  );
});
