import { ComponentProps } from "react";
import {
  useNotificationQuery,
  useNotificationPatchMutation,
} from "../tanstack/useNotificationsQuery";
import CustomNotificationForm from "./CustomNotificationForm";

export default function CustomNotificationEditForm({
  notificationId,
  ...passAlong
}: {
  notificationId: string;
} & Pick<
  ComponentProps<typeof CustomNotificationForm>,
  "close" | "className"
>) {
  const defaultData = useNotificationQuery(notificationId).data;
  const mutation = useNotificationPatchMutation(notificationId);

  if (!defaultData) {
    return <div>Loading...</div>;
  }

  return (
    <CustomNotificationForm
      mutation={mutation}
      defaultData={defaultData}
      {...passAlong}
    />
  );
}
