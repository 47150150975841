import { ComponentProps } from "react";
import { BsClipboardPulse } from "react-icons/bs";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { VariabilityStats } from "../../tooltip/tooltip";
import { cn, type PropsWithCn } from "../../../../shared-ui/frontend/cn";
import { Button } from "../../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../shared-ui/frontend/tooltip";

export function GroupStatistics({
  withoutPortal,
  className,
  ...rest
}: {
  showLimitStats: boolean;
} & Pick<ComponentProps<typeof PopoverContent>, "withoutPortal"> &
  ComponentProps<typeof VariabilityStats> &
  PropsWithCn) {
  return (
    <Tooltip>
      <TooltipContent>Statistics</TooltipContent>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              variant={"ghost"}
              size={"icon-sm"}
              className={cn(className)}
              onClick={(e) => e.stopPropagation()}
            >
              <BsClipboardPulse className="size-3.5" />
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <PopoverContent withoutPortal={withoutPortal}>
          <VariabilityStats {...rest} />
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}
