import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { Loader2 } from "lucide-react";
import { Button } from "./button";
import type { ComponentProps, PropsWithChildren } from "react";

export function GenericDeleteTriggerWithConfirmationPopup({
  onConfirm,
  confirmDisabled,
  children,
  description,
  title,
  asChild,
  variant,
  open,
  onOpenChange,
}: PropsWithChildren<{
  confirmDisabled: boolean;
  onConfirm: () => void;
  description?: string;
  title: string;
}> &
  Pick<ComponentProps<typeof Dialog>, "open" | "onOpenChange"> &
  Pick<ComponentProps<typeof DialogTrigger>, "asChild"> &
  Pick<ComponentProps<typeof Button>, "variant">) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children && <DialogTrigger asChild={asChild}>{children}</DialogTrigger>}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>
        <div className="flex justify-end">
          <Button
            variant={variant ?? "destructive"}
            disabled={confirmDisabled}
            onClick={onConfirm}
          >
            {confirmDisabled ? <Loader2 className="animate-spin" /> : "Delete"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
