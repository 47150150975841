import Button from "../../common/Button/Button";
import { Tooltip } from "@mantine/core";
import {
  useNotificationDeleteMutation,
  useNotificationsAvailableQuery,
  useNotificationSubscribeMutation,
} from "../tanstack/useNotificationsQuery";
import Notification, {
  NotificationsAvailable,
  NotificationType,
} from "../../../types/api/Notification";
import * as R from "remeda";
import { FaCheck } from "react-icons/fa";
import { cn } from "../../../lib/utils";
import { Loader2, X } from "lucide-react";
import { addToast } from "../../toast/use-toast-store";

export default function StandardNotificationForm({
  variableId,
  notificationTypes = [NotificationType.DRA, NotificationType.SLOPE],
  close,
}: {
  variableId: string;
  notificationTypes?: NotificationType[];
  close: () => void;
}) {
  const { data } = useNotificationsAvailableQuery({
    variableId: variableId,
    notificationTypes: notificationTypes,
  });

  const availableNotifications = data?.availableNotifications || [];
  const notifications = data?.notifications || [];
  const active = R.map(availableNotifications, (available) => {
    return notifications.find((notification) => {
      return (
        notification.criteria === available.value &&
        (!notification.operatingLimitId ||
          (notification.operatingLimitId &&
            notification.operatingLimitId === available.operatingLimitId))
      );
    });
  });

  const onError = (error: { msg: string }[]) => {
    addToast({
      title: error.at(0)?.msg || "An unknown error has occurred",
      variant: "danger",
    });
  };

  return (
    <div className="mb-6 border border-zinc-500 bg-zinc-50 rounded-lg overflow-clip animate-in slide-in-from-bottom-4 fade-in-20">
      <div className="flex sm:items-center justify-between sm:py-[0.15em] py-[0.35em] px-[0.5em] border-b border-b-zinc-500">
        <span className="tracking-tight text-[1rem]">
          Create a Notification
        </span>
        <div className="ml-auto">
          <Button
            className="btn-ghost"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <ul>
        {availableNotifications.length > 0 ? (
          availableNotifications.map((option, index) => {
            return (
              <li key={`${option.type}-${option.value}-${index}`}>
                <StandardNotificationFormOption
                  variableId={variableId}
                  active={active[index]}
                  onError={onError}
                  {...option}
                />
              </li>
            );
          })
        ) : (
          <div className="flex justify-center m-4">
            <span className="text-sm font-medium tracking-tight">
              None available
            </span>
          </div>
        )}
      </ul>
    </div>
  );
}
export function StandardNotificationForm2({
  variableId,
  notificationTypes = [NotificationType.DRA, NotificationType.SLOPE],
  close,
}: {
  variableId: string;
  notificationTypes?: NotificationType[];
  close: () => void;
}) {
  const { data } = useNotificationsAvailableQuery({
    variableId: variableId,
    notificationTypes: notificationTypes,
  });

  const availableNotifications = data?.availableNotifications || [];
  const notifications = data?.notifications || [];
  const active = R.map(availableNotifications, (available) => {
    return notifications.find((notification) => {
      return (
        notification.criteria === available.value &&
        (!notification.operatingLimitId ||
          (notification.operatingLimitId &&
            notification.operatingLimitId === available.operatingLimitId))
      );
    });
  });

  const onError = (error: { msg: string }[]) => {
    addToast({
      title: error.at(0)?.msg || "An unknown error has occurred",
      variant: "danger",
    });
  };

  return (
    <div className="mb-6 border border-zinc-500 bg-zinc-50 rounded-lg overflow-clip animate-in slide-in-from-bottom-4 fade-in-20">
      <div className="flex sm:items-center justify-between sm:py-[0.15em] py-[0.35em] px-[0.5em] border-b border-b-zinc-500">
        <span className="tracking-tight text-[1rem]">
          Create a Notification
        </span>
        <div className="ml-auto">
          <Button
            className="btn-ghost"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <ul>
        {availableNotifications.length > 0 ? (
          availableNotifications.map((option, index) => {
            return (
              <li key={`${option.type}-${option.value}-${index}`}>
                <StandardNotificationFormOption
                  variableId={variableId}
                  active={active[index]}
                  onError={onError}
                  {...option}
                />
              </li>
            );
          })
        ) : (
          <div className="flex justify-center m-4">
            <span className="text-sm font-medium tracking-tight">
              None available
            </span>
          </div>
        )}
      </ul>
    </div>
  );
}
function StandardNotificationFormOption({
  active,
  variableId,
  label,
  type,
  value,
  onError,
  operatingLimitId,
}: {
  active: Notification | undefined;
  variableId: string;
  onError: (error: { msg: string }[]) => void;
} & NotificationsAvailable["availableNotifications"][number]) {
  /* Add mutation for subscribing. */
  const { mutate: subscribe, status: subscribeStatus } =
    useNotificationSubscribeMutation({
      notificationType: type,
      variableId: variableId,
      value: value,
      operatingLimitId,
    });
  const { mutate: unsubscribe, status: unsubscribeStatus } =
    useNotificationDeleteMutation(active?._id || "");

  const loading =
    subscribeStatus === "loading" || unsubscribeStatus === "loading";
  const icon = (() => {
    if (loading) {
      return (
        <Tooltip label="Loading...">
          <Loader2 className="w-4 h-4 animate-spin-slow" />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Active">
        <FaCheck
          className={cn(
            active ? "text-primary" : "text-transparent",
            "w-4 h-4 inline"
          )}
        />
      </Tooltip>
    );
  })();

  const handleClick = () => {
    if (active && !window.confirm("Do you want to remove this notification?")) {
      return;
    }

    active
      ? unsubscribe(undefined, { onError })
      : subscribe(undefined, { onError });
  };

  return (
    <button
      type="submit"
      disabled={loading}
      onClick={handleClick}
      className={cn(
        "w-full text-left py-1.5 px-3 transition-all group flex items-center",
        !loading && !active && "hover:pl-5",
        active ? "hover:bg-xred-3" : "hover:bg-zinc-200"
      )}
    >
      {icon}
      <span className="ml-2 text-[0.9rem] text-zinc-600 group-hover:text-zinc-800">
        {label}
      </span>
      {active && (
        <X className="w-5 h-5 text-transparent group-hover:text-xred-11 ml-auto" />
      )}
    </button>
  );
}
