import { Alert } from "@mantine/core";
import React from "react";
import Input from "../../../common/Input/Input";
import { type PDF as PDFType } from "../types/BuilderTypes";
import {
  CODENAME_INPUT_PLACEHOLDER,
  FILENAME_INPUT_PLACEHOLDER,
} from "./constants";
import type EditorProps from "./EditorProps";
import MarginInputs from "./MarginInputs";
import { validateCodename, validateFilename } from "./validators";

function PDF({ content, editContent }: EditorProps<PDFType>) {
  function validateDisplayName(name: string) {
    if (!name || !name.trim()) {
      return "Display name is required";
    }
  }

  const err =
    validateFilename(content.filename) ||
    validateCodename(content.codename) ||
    validateDisplayName(content.displayname);

  const errComp = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  return (
    <div className="flex flex-col gap-2">
      {errComp}
      <MarginInputs editContent={editContent} content={content} />
      <div className="form-control">
        <label className="input-group input-group-sm flex">
          <span>Filename</span>
          <Input
            className="grow"
            placeholder={FILENAME_INPUT_PLACEHOLDER}
            bordered
            size="sm"
            required
            value={content.filename}
            onChange={(e) => {
              editContent((c) => {
                c.filename = e.target.value;
              });
            }}
          />
        </label>
      </div>
      <div className="form-control">
        <label className="input-group input-group-sm flex">
          <span>Codename</span>
          <Input
            className="grow"
            placeholder={CODENAME_INPUT_PLACEHOLDER}
            bordered
            size="sm"
            required
            value={content.codename}
            onChange={(e) => {
              editContent((c) => {
                c.codename = e.target.value;
              });
            }}
          />
        </label>
      </div>
      <div className="form-control">
        <label className="input-group input-group-sm">
          <span>Display Name</span>
          <Input
            className="grow"
            bordered={true}
            placeholder="Displayed to the user in the download button"
            size="sm"
            required={true}
            value={content.displayname}
            onChange={(e) => {
              editContent((c) => {
                c.displayname = e.target.value;
              });
            }}
          />
        </label>
      </div>
    </div>
  );
}

export default PDF;
