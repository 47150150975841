/**
 * Ex: 12-Jun-2024
 */
export const ABBREVIATED_MONTH_DATE = "LLL d yyyy";
/**
 * Ex: 4:02 PM
 */
export const TWELVE_HR_TIME = "h:mm a";
/**
 * Ex: 12-Jun-2024, 12:00 AM
 */
export const LONG = `${ABBREVIATED_MONTH_DATE}, ${TWELVE_HR_TIME}`;
