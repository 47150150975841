import moment from "moment";

export function getRangeLabel(
  start: Date | string,
  end: Date | string,
  short = false
) {
  const first = moment.utc(start);
  const second = moment.utc(end);
  const monthFormat = short ? "MMM" : "MMMM";

  if (first.isSame(second, "month")) {
    return `${first.format(monthFormat)} ${first.format("D")}-${second.format(
      "D"
    )}${short ? "" : ", " + first.format("YYYY")}`;
  } else if (first.isSame(second, "year")) {
    return `${first.format(`${monthFormat} D`)} - ${second.format(
      `${monthFormat} D`
    )}${short ? "" : ", " + first.format("YYYY")}`;
  } else {
    return `${first.format(`${monthFormat} D, YYYY`)} - ${second.format(
      short ? `${monthFormat} D` : `${monthFormat} D, YYYY`
    )}`;
  }
}

// todo - move this to shared-ui when we've finally merged ft2ts branch
export function formatDuration4(diff: number) {
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  return `${days > 0 ? days.toString() + "d " : ""}${
    hours > 0 ? hours.toString() + "h " : ""
  }${minutes > 0 ? minutes.toString() + "m " : ""}`;
}
