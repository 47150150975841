import classNames from "classnames";

function Title({ title }: { title: string }) {
  return (
    <div className={classNames("flex text-[2rem] pb-3")}>
      <span>{title}</span>
    </div>
  );
}

export default Title;
