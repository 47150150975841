import { cn } from "../../frontend/cn";
import { Input } from "../../frontend/input";
import { useMemo, useRef, useState } from "react";
import { Button } from "../../frontend/button";
import { X } from "lucide-react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  Atoms,
  initClampStoresForNewBatchVariable,
} from "../svv-store/use-svv-store";
import { minLen1 } from "../../lib/utils";
import { colorGen } from "../../lib/getColors";

const addVariableAtom = atom(null, (get, set, varId: string) => {
  const selectedVariables = get(Atoms.selectedVariablesAtom);

  const variableTrendLines = selectedVariables.filter(
    (x) => x.type === "variable"
  );

  const existingBatchIdsSet = new Set(
    variableTrendLines.map((x) => x.bv.slice(0, 24))
  );

  const first = selectedVariables[0];

  if (first.type === "variable") {
  } else {
    // throw new Error(
    //   "You are trying to add a variable to an expression first chart. What does this mean????"
    // );
    /**
     * This will be something we need to address in Batch, but for now, I know DRA uses fake MongoIds for the batchId
     */

    existingBatchIdsSet.add("0".repeat(24));
  }

  const currentBvs = variableTrendLines.map((v) => v.bv);

  const newSelectedVariables = Array.from(existingBatchIdsSet)
    .map((bid) => {
      return bid + varId;
    })
    .filter((bv) => !currentBvs.includes(bv));

  const newColors = colorGen.getN(
    selectedVariables.map((x) => x.color),
    newSelectedVariables.length
  );

  const initialAnom = get(Atoms.initialAnomBoolForNewlyAddedVariableTrendLines);

  const newTrendLines = newSelectedVariables.map(
    (bv, i): (typeof selectedVariables)[number] => {
      const color = newColors[i];

      if (!color) throw new Error("no color");
      return {
        type: "variable",
        bv,
        anom: initialAnom,
        color,
        clampStores: initClampStoresForNewBatchVariable(bv),
      };
    }
  );
  const combined = [...selectedVariables, ...newTrendLines];
  if (!minLen1(combined)) throw new Error("BUG");

  const shouldOpenSidebar = combined.length > 1;
  shouldOpenSidebar && set(get(Atoms.cardsSidebarOpenAtomAtom), true);
  set(Atoms.selectedVariablesAtom, combined);
});

function VariableSelectOverlay<
  TOption extends {
    _id: string;
    name: string;
    trimmedName: string;
    description?: string | null;
  },
>({ variables }: { variables: TOption[] }) {
  const [query, setQuery] = useState("");
  const selectedVariables = useAtomValue(Atoms.selectedVariablesAtom);

  const [show, setShow] = useAtom(Atoms.variableSelectDrawerOpenAtom);

  /**
   * These methods should never change so we can get them
   * synchronously without subscribing to the store
   */
  const addVariable = useSetAtom(addVariableAtom);

  const filteredVariables = useMemo(() => {
    if (!show) return;

    const cleaned = query.trim().toLowerCase();
    if (cleaned === "") return variables;

    return variables.filter((v) => {
      return (
        v.name.toLowerCase().includes(cleaned) ||
        (v.description ? v.description.toLowerCase().includes(cleaned) : false)
      );
    });
  }, [variables, query, show]);

  const drawer = filteredVariables && (
    <ul className="z-20 w-80">
      {filteredVariables.map((variable) => {
        const disabled = selectedVariables.some(
          (x) => x.type === "variable" && x.bv.slice(24) === variable._id
        );
        return (
          <li
            data-disabled={disabled.toString()}
            key={variable._id}
            className="cursor-default select-none rounded-md px-2 py-1.5 text-sm text-popover-foreground transition-all duration-75 hover:bg-xslate-4 hover:pl-2.5 hover:text-xslate-12 active:scale-95 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50"
            onClick={() => addVariable(variable._id)}
          >
            <span className="line-clamp-1">{variable.trimmedName}</span>
            {variable.description && (
              <span className="break-all text-xslate-11 text-xs">
                {variable.description}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );

  const inputRef = useRef<HTMLInputElement>(null);

  if (!show) return null;

  return (
    <div
      className={cn(
        "left-0 top-0 z-20 h-full w-full overflow-hidden rounded-lg absolute bg-black/40"
      )}
    >
      <div className="flex h-full">
        <div className="h-full min-w-min overflow-scroll bg-white animate-in slide-in-from-left-3 dark:bg-xslate-1 p-2">
          <div className="relative">
            <Input
              ref={inputRef}
              className="h-8"
              placeholder="Search..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {query.trim() !== "" && (
              <Button
                variant={"ghost"}
                size={"icon"}
                className="size-5 hover:text-red-600 absolute right-2 top-1/2 -translate-y-1/2"
                onClick={() => {
                  setQuery("");
                  inputRef.current?.focus();
                }}
              >
                <X className="size-3" />
              </Button>
            )}
          </div>
          <div className="flex mt-1 text-xslate-11">
            {query.trim() !== "" && filteredVariables && (
              <span className="text-xs">
                {filteredVariables.length} result
                {filteredVariables.length === 1 ? "" : "s"}
              </span>
            )}
            <span className="ml-auto text-xs">{variables.length} tags</span>
          </div>
          {drawer}
        </div>
        <div
          className="grow relative cursor-pointer"
          onMouseDown={() => setShow(false)}
        >
          <span className="text-xslate-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap uppercase font-medium text-lg z-[-1]">
            Click to close
          </span>
        </div>
      </div>
    </div>
  );
}

export { VariableSelectOverlay };
