import assets from "../assets";
import { type HelpPage } from "./types";

const AdminGuide: HelpPage = {
  color: "#ffd43b",
  sectiontitle: "Admin Guide",
  url: "adminguide",
  subtitle:
    "Step-by-step instructions for Admins to make administrative changes in DRA",
  subsections: [
    {
      id: "subsection-4898",
      title: "Advanced Settings",
      content: [
        {
          type: "paragraph",
          content:
            "The **Advanced Settings page is only visible to DRA Admins** and allows them to configure administrative changes in DRA. Click the **Double Gear** icon in the upper right corner to access this page. The options are indicated as follows: ",
          id: "content-4899",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "Click to create/edit/delete **Tags**.",
              id: "content-list-4901",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Groups**.",
              id: "content-list-4902",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Shutdown Rules**.",
              id: "content-list-4903",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Operating Modes**.",
              id: "content-list-4904",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Clusters**.",
              id: "content-list-4905",
              marginLeft: "0",
            },
            {
              value:
                "Click to view a summary of Acknowledgments. See **Settings – Acknowledgement Summary** section of User Guide for details. This page is the same for Admins and Users.",
              id: "content-list-4906",
              marginLeft: "0",
            },
            {
              value:
                "Click to view and download reports. See **Settings – Reports Library** section of User Guide for details. This page is the same for Admins and Users.",
              id: "content-list-4907",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Fault Trees**.",
              id: "content-list-4908",
              marginLeft: "0",
            },
            {
              value:
                "Click to create/edit/delete **Operating Fitness Limits**.",
              id: "content-list-4909",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Specialty Reports**.",
              id: "content-list-4910",
              marginLeft: "0",
            },
            {
              value: "Click to create/edit/delete **Users**.",
              id: "content-list-4911",
              marginLeft: "0",
            },
          ],
          id: "content-4900",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "advancedsettings.png",
          codename: "advancedsettings",
          id: "content-4912",
          content: assets.advancedsettings,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-4913",
      title: "Tags",
      content: [
        {
          type: "paragraph",
          content:
            "The **Tags** page allows Admins to add a Tag, modify specifics of an existing Tag, or delete a Tag.",
          id: "content-4914",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content: "**Adding a Tag**",
          id: "content-4915",
          marginTop: "1",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To add a new Tag in DRA, click **plus (+)** button to launch a form.",
              id: "content-list-4917",
              marginLeft: "0",
            },
            {
              value:
                "If you are adding a regular (historian based) Tag, enter a **Tag ID** that matches the name of the Tag in the Historian. If you are creating a **Calculated Tag**, any unique ID can be chosen. We recommend adding a ‘.calc’ suffix for clarity.",
              id: "content-list-4918",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description** for the Tag.",
              id: "content-list-4919",
              marginLeft: "0",
            },
            {
              value:
                "Select **Tag type**. Two options are available: **Historian Tag** and **Calculated Tag**. ",
              id: "content-list-4920",
              marginLeft: "0",
            },
          ],
          id: "content-4916",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value:
                'To create a calculated tag in DRA, first select **Calculated Tag** in the dropdown and then enter its Expression. Use double quotes to refer to existing Tags in the expression, e.g. "TI100.PV + "TI200.PV". Note that the Tag IDs are case-sensitive, refer to the Tags page for exact casing.',
              id: "content-list-4922",
              marginLeft: "0",
            },
            {
              value:
                "NOTE: DRA supports 50+ powerful functions that can be used in Calculated Tag expressions. Please see the ‘List of Supported Functions’ section for a full list of supported functions.",
              id: "content-list-4923",
              marginLeft: "0",
            },
          ],
          id: "content-4921",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "Enter **Unit of Measurement for the Tag**.",
              id: "content-list-4925",
              customIndex: "5",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) anomaly calculations on the High Side.",
              id: "content-list-4926",
              customIndex: "6",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) anomaly calculations on the Low Side.",
              id: "content-list-4927",
              customIndex: "7",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) sloping trend calculations for Positive Slopes.",
              id: "content-list-4928",
              customIndex: "8",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) sloping trend calculations for Negative Slopes.",
              id: "content-list-4929",
              customIndex: "9",
              marginLeft: "0",
            },
            {
              value:
                "Click within **Groups** dropdown and select a list of associated Groups for the Tag.",
              id: "content-list-4930",
              customIndex: "10",
              marginLeft: "0",
            },
            {
              value: "Click to **Save**.",
              id: "content-list-4931",
              customIndex: "11",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Tag has been successfully created.",
              id: "content-list-4932",
              customIndex: "12",
              marginLeft: "0",
            },
          ],
          id: "content-4924",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "tags1.png",
          codename: "tags1",
          id: "content-4933",
          content: assets.tags1,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Editing a Tag**",
          id: "content-4934",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click to **edit a Tag** from the full list. Once a Tag is selected, the displayed fields are auto-populated with its associated information.",
              id: "content-list-4936",
              marginLeft: "0",
            },
            {
              value:
                "Displays **Tag ID** that is used to match the Tag’s name in the Historian.",
              id: "content-list-4937",
              marginLeft: "0",
            },
            {
              value:
                "Displays **Description** for the Tag. The Description is not used to match the Tag in the Historian and can be different in DRA.",
              id: "content-list-4938",
              marginLeft: "0",
            },
            {
              value: "Displays **Units of Measurement** for the Tag.",
              id: "content-list-4939",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) anomaly calculations on the Low Side.",
              id: "content-list-4940",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) sloping trend calculations for Positive Slopes.",
              id: "content-list-4941",
              marginLeft: "0",
            },
            {
              value:
                "Click to enable (blue) or disable (grey) sloping trend calculations for Negative Slopes.",
              id: "content-list-4942",
              marginLeft: "0",
            },
            {
              value:
                "The **Groups** field displays all Groups that the Tag is associated with. The default Group is Overall.",
              id: "content-list-4943",
              marginLeft: "0",
            },
            {
              value: "Click **Save** to confirm changes made to this Tag.",
              id: "content-list-4944",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Tag has been successfully updated.",
              id: "content-list-4945",
              marginLeft: "0",
            },
          ],
          id: "content-4935",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "tags2.png",
          codename: "tags2",
          id: "content-4946",
          content: assets.tags2,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Deleting a Tag**",
          id: "content-4947",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "To permanently delete a Tag, click **Delete** button.",
              id: "content-list-4949",
              marginLeft: "0",
            },
            {
              value:
                "A prompt appears confirming the Tag deletion request. Click **DELETE** button to proceed with the Tag deletion. Note that this step cannot be undone.",
              id: "content-list-4950",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Tag has been successfully deleted.",
              id: "content-list-4951",
              marginLeft: "0",
            },
          ],
          id: "content-4948",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "**$$NOTE: Make sure that you remove the Tag from any dependent shutdown rules, operating modes, or calculated tags that reference that Tag, otherwise you will be blocked by the system from deleting the Tag.$$**",
          id: "content-4952",
          marginTop: "-1",
          marginBottom: "1",
        },
        {
          type: "image",
          filename: "tags3.png",
          codename: "tags3",
          id: "content-4953",
          content: assets.tags3,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-4954",
      title: "Groups",
      content: [
        {
          type: "paragraph",
          content:
            "The **Groups** page allows Admins to create a Group, modify specifics of an existing Group, or delete a Group.",
          id: "content-4955",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content: "**Creating a Group**",
          id: "content-4956",
          marginTop: "1",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To create a new Group in DRA, click **plus (+)** button to launch a form.",
              id: "content-list-4958",
              marginLeft: "0",
            },
            {
              value: "Enter a name for the new Group.",
              id: "content-list-4959",
              marginLeft: "0",
            },
            {
              value:
                "Search for a specific Tag by Tag ID or description. The list of Tags is divided in two boxes. The Tags that are not in this Group are displayed in the right box. The Tags that are part of this Group are displayed in the left box.",
              id: "content-list-4960",
              marginLeft: "0",
            },
            {
              value:
                "Click **plus (+) symbol** (in the right box) to add Tags to the Group individually. Click **Add all** to perform a batch addition of Tags to the Group.",
              id: "content-list-4961",
              marginLeft: "0",
            },
            {
              value:
                "Click **minus (-) symbol** (in the left box) to remove Tags from the Group individually. Click **Remove all** to perform a batch removal of Tags from the Group.",
              id: "content-list-4962",
              marginLeft: "0",
            },
            {
              value: "Click **Save** to confirm changes made to the Group.",
              id: "content-list-4963",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Group has been successfully created.",
              id: "content-list-4964",
              marginLeft: "0",
            },
          ],
          id: "content-4957",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "groups1.png",
          codename: "groups1",
          id: "content-4965",
          content: assets.groups1,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Updating a Group**",
          id: "content-4966",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click to **modify a Group** from the full list of existing Groups. Once a Group is selected, its associated information is auto-populated underneath.",
              id: "content-list-4968",
              marginLeft: "0",
            },
            {
              value:
                "Displays the name of the selected Group. The Group name can be edited here.",
              id: "content-list-4969",
              marginLeft: "0",
            },
            {
              value:
                "Search for a specific Tag by Tag ID or description. The list of Tags is divided in two boxes. The Tags that are not in this Group are displayed in the right box. The Tags that are part of this Group are displayed in the left box.",
              id: "content-list-4970",
              marginLeft: "0",
            },
            {
              value:
                "Click **plus (+) symbol** (in the right box) to add Tags to the Group individually. Click Add all to perform a batch addition of Tags to the Group.",
              id: "content-list-4971",
              marginLeft: "0",
            },
            {
              value:
                "Click **minus (-) symbol** (in the left box) to remove Tags from the Group individually. Click Remove all to perform a batch removal of Tags from the Group.",
              id: "content-list-4972",
              marginLeft: "0",
            },
            {
              value: "Click **Save** to confirm changes to the group. ",
              id: "content-list-4973",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Group has been successfully updated.",
              id: "content-list-4974",
              marginLeft: "0",
            },
          ],
          id: "content-4967",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "groups2.png",
          codename: "groups2",
          id: "content-4975",
          content: assets.groups2,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Deleting a Group**",
          id: "content-4976",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "To permanently delete a Group, click **Delete** button.",
              id: "content-list-4978",
              marginLeft: "0",
            },
            {
              value:
                "A prompt appears confirming the Group deletion request. Click **DELETE** to proceed with Group deletion. **$$Note that this step cannot be undone.$$**",
              id: "content-list-4979",
              marginLeft: "0",
            },
            {
              value:
                "A confirmation message appears once the Group has been successfully deleted.",
              id: "content-list-4980",
              marginLeft: "0",
            },
          ],
          id: "content-4977",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "groups3.png",
          codename: "groups3",
          id: "content-4981",
          content: assets.groups3,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-4982",
      title: "Shutdown Rules",
      content: [
        {
          type: "paragraph",
          content:
            "The **Shutdown Rules** page allows Admins to add shutdown rules for a Group or set of Tags (the latter is generally used for creation of $$Equipment Shutdowns$$). A **Shutdown Rule** consists of an ID, short description, expression, and associated Groups or Tags.",
          id: "content-4983",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To create a new Shutdown Rule, click **plus (+)** button to launch a form.",
              id: "content-list-4985",
              marginLeft: "0",
            },
            {
              value:
                "Enter a **unique ID** for the shutdown rule. We recommend adding a ‘.sd’ suffix for clarity.",
              id: "content-list-4986",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-4987",
              marginLeft: "0",
            },
            {
              value:
                "Enter an **Expression** (a Boolean condition that is either True or False). All Tags must be contained in quotation marks, and all independent expressions must be within parentheses.",
              id: "content-list-4988",
              marginLeft: "0",
            },
            {
              value:
                "Select **Groups** that should be bound with the shutdown rule (if any).",
              id: "content-list-4989",
              marginLeft: "0",
            },
            {
              value:
                "Select **Tags** that should be bound with the shutdown rule (if any).",
              id: "content-list-4990",
              marginLeft: "0",
            },
            {
              value:
                "Select **Category**: Shutdown, Slowdown, Equipment Shutdown, Calibration, or Transition.",
              id: "content-list-4991",
              marginLeft: "0",
            },
            {
              value:
                "Enter **Buffer time** (in hours). Shutdown Buffer allows you to extend shutdown periods automatically to account for post-shutdown transition periods (or startups) as shutdowns. The default value is set to 3 hours. ",
              id: "content-list-4992",
              marginLeft: "0",
            },
            {
              value:
                "Click **Save**. A confirmation message appears once the shutdown rule has successfully been created.",
              id: "content-list-4993",
              marginLeft: "0",
            },
            {
              value:
                "Click to **Delete** or **Edit** an existing shutdown rule.",
              id: "content-list-4994",
              marginLeft: "0",
            },
            {
              value: "Displays the time the shutdown rule was last updated.",
              id: "content-list-4995",
              marginLeft: "0",
            },
          ],
          id: "content-4984",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "shutdownrules.png",
          codename: "shutdownrules",
          id: "content-4996",
          content: assets.shutdownrules,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-4997",
      title: "Operating Modes",
      content: [
        {
          type: "paragraph",
          content:
            "The **Operating Modes** page allows Admins to add Operating Modes for a Group or set of Tags. This can be used for differentiating and analyzing different operating conditions in a process, such as high vs. low production rates, distinct product grades, etc. Like a Shutdown Rule, an Operating Mode consists of an ID, short description, expression, and associated Groups or Tags.",
          id: "content-4998",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To create a new Operating Mode, click **plus (+)** button to launch a form.",
              id: "content-list-5000",
              marginLeft: "0",
            },
            {
              value:
                "Enter a **unique ID** for the Operating Mode. We recommend adding a ‘.mode suffix for clarity.",
              id: "content-list-5001",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-5002",
              marginLeft: "0",
            },
            {
              value:
                "Enter an **Expression** (a Boolean condition that is either True or False).",
              id: "content-list-5003",
              marginLeft: "0",
            },
            {
              value:
                "Select **Groups** that should be bound with the operating mode (if any).",
              id: "content-list-5004",
              marginLeft: "0",
            },
            {
              value:
                "Select **Tags** that should be bound with the operating mode (if any).",
              id: "content-list-5005",
              marginLeft: "0",
            },
            {
              value:
                "Click **Save**. A confirmation message appears once the operating mode has successfully been created.",
              id: "content-list-5006",
              marginLeft: "0",
            },
            {
              value:
                "Click to **Delete** or **Edit** an existing Operating Mode.",
              id: "content-list-5007",
              marginLeft: "0",
            },
            {
              value: "Displays the time the Operating Mode was last updated.",
              id: "content-list-5008",
              marginLeft: "0",
            },
          ],
          id: "content-4999",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "**$$Note that for a given variable, the associated Operating Modes should be mutually exclusive.$$**",
          id: "content-5009",
          marginBottom: "1",
          marginTop: "0",
        },
        {
          type: "image",
          filename: "operatingmodes.png",
          codename: "operatingmodes",
          id: "content-5010",
          content: assets.operatingmodes,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-5011",
      title: "Custom Clusters",
      content: [
        {
          type: "paragraph",
          content:
            "The **Custom Clusters** page allows Admins to add new Custom Clusters in DRA. A **Custom Cluster** consists of a name, short description, and associated Tags. Note that the Dynamic Clusters are created automatically by the system.",
          id: "content-5012",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To create a new Custom Cluster, click **plus (+)** button to launch a form.",
              id: "content-list-5014",
              marginLeft: "0",
              customIndex: "1",
            },
            {
              value: "Enter **Cluster Name**.",
              id: "content-list-5015",
              marginLeft: "0",
              customIndex: "2",
            },
            {
              value: "Enter **Cluster Description**.",
              id: "content-list-5016",
              marginLeft: "0",
              customIndex: "3",
            },
            {
              value:
                "Select two or more Tags from the **Associated Variables** drop-down menu.",
              id: "content-list-5017",
              marginLeft: "0",
              customIndex: "4",
            },
            {
              value:
                "Click **Save** to confirm the creation of Custom Cluster. A confirmation message appears once the Custom Cluster has been configured successfully.",
              id: "content-list-5018",
              marginLeft: "0",
              customIndex: "5",
            },
            {
              value:
                "Click to **Delete** or **Edit** an existing Custom Cluster.",
              id: "content-list-5019",
              marginLeft: "0",
              customIndex: "6",
            },
          ],
          id: "content-5013",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "clusters.png",
          codename: "clusters",
          id: "content-5020",
          content: assets.clusters,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-5021",
      title: "Teams",
      content: [
        {
          type: "paragraph",
          content:
            "The **Teams** page allows Admins to add new Teams in DRA. These are teams of users, which can easily be tagged in a comment or subscribed to a report.",
          id: "content-5022",
          marginTop: "0",
          marginBottom: "1",
        },
        {
          type: "paragraph",
          content: "A **Team** consists of a name and associated members. ",
          id: "content-5023",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To create a new Team, click **plus (+)** button to launch a form.",
              id: "content-list-5025",
              marginLeft: "0",
            },
            {
              value: "Enter **Team Name.**",
              id: "content-list-5026",
              marginLeft: "0",
            },
            {
              value:
                "Select two or more Members from the **Users** drop-down menu.",
              id: "content-list-5027",
              marginLeft: "0",
            },
            {
              value:
                "Click **Save** to confirm the creation of Team. A confirmation message appears once the Team has been configured successfully.",
              id: "content-list-5028",
              marginLeft: "0",
            },
            {
              value: "Click to **Delete** or **Edit** an existing Team.",
              id: "content-list-5029",
              marginLeft: "0",
            },
          ],
          id: "content-5024",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "Teams2.png",
          codename: "teams2",
          id: "content-5030",
          marginTop: "0",
          marginBottom: "0",
          content: assets.teams2,
        },
      ],
    },
    {
      id: "subsection-5031",
      title: "Fault Trees",
      content: [
        {
          type: "paragraph",
          content:
            "The **Fault Trees** page allows Admins to create new Fault Trees, modify or delete Existing Fault Trees. Refer to Fault Trees Administration guide for details or contact NMM Support.",
          id: "content-5032",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "Click **plus (+)** symbol to create a new Fault Tree. ",
              id: "content-list-5034",
              marginLeft: "0",
            },
            {
              value: "Displays **Fault Tree Name**.",
              id: "content-list-5035",
              marginLeft: "0",
            },
            {
              value:
                "Displays the status: **Published/Unpublished**. Click to **Publish/Unpublish** the Fault Tree (**$$NOTE: Fault trees must be unpublished before they can be edited)$$**.",
              id: "content-list-5036",
              marginLeft: "0",
            },
            {
              value: "Click to **View/Edit** the Fault Tree.",
              id: "content-list-5037",
              marginLeft: "0",
            },
          ],
          id: "content-5033",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "faulttrees.png",
          codename: "faulttrees",
          id: "content-5038",
          content: assets.faulttrees,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-5039",
      title: "Operating Fitness",
      content: [
        {
          type: "paragraph",
          content:
            "The **Operating Fitness** page allows Admins to add/modify/delete Operating Fitness Limits.",
          id: "content-5040",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To add a new Limit, click **plus (+)** symbol to launch a form.",
              id: "content-list-5042",
              marginLeft: "0",
            },
            {
              value:
                "Select **Tag** for the Limit. Select from the dropdown or search by Tag ID or description.",
              id: "content-list-5043",
              marginLeft: "0",
            },
            {
              value: "Select **Limit level**.",
              id: "content-list-5044",
              marginLeft: "0",
            },
            {
              value: "Click to select **Effective Date**.",
              id: "content-list-5045",
              marginLeft: "0",
            },
            {
              value: "Enter a **Limit Value**.",
              id: "content-list-5046",
              marginLeft: "0",
            },
            {
              value:
                "Click **Add History** to add more historical Limits for this Limit level.",
              id: "content-list-5047",
              marginLeft: "0",
            },
            {
              value:
                "Click **Save** to create Limit. A confirmation message appears once the Operating Fitness Limit has successfully been created.",
              id: "content-list-5048",
              marginLeft: "0",
            },
            {
              value: "Click to expand Limit information for that Tag.",
              id: "content-list-5049",
              marginLeft: "0",
            },
            {
              value: "Click to **Delete** or **Edit** the Limit.",
              id: "content-list-5050",
              marginLeft: "0",
            },
          ],
          id: "content-5041",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "operatingfitness2.png",
          codename: "operatingfitness2",
          id: "content-5051",
          content: assets.operatingfitness2,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-5052",
      title: "Specialty Reports",
      content: [
        {
          type: "paragraph",
          content:
            "The **Specialty Reports** page allows Admins to create customized reports in DRA. These reports can be tailored for different cross-functional teams as Rotary, Maintenance, Instrumentation, etc. When creating a Specialty report, there are several customization options. For example, you can:",
          id: "content-5053",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: true,
          content: [
            {
              value:
                "Select a date range: 7d, 30d, 60d, 120d or 180d (or longer in some cases). ",
              id: "content-list-5055",
              marginLeft: "0",
            },
            {
              value:
                "Track specific groups only, such as Rotating Equipment or Instrumentation. ",
              id: "content-list-5056",
              marginLeft: "0",
            },
            {
              value:
                "Automate delivery of reports on a specified frequency: daily, weekly, biweekly, monthly and shift. ",
              id: "content-list-5057",
              marginLeft: "0",
            },
            {
              value:
                "Subscribe users to specific reports depending upon their role and expertise. ",
              id: "content-list-5058",
              marginLeft: "0",
            },
          ],
          id: "content-5054",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content:
            "Furthermore, there are 4 different types of Reports: **DRA Reports, Sloping Trend Reports, Fault Trees Reports,** and **Operating Fitness Reports**. They are described as follows: ",
          id: "content-5059",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "paragraph",
          content: "**Creating a DRA Report**",
          id: "content-5060",
          marginTop: "1",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click **plus (+)** button to launch a form to create a new Specialty Report.",
              id: "content-list-5062",
              marginLeft: "0",
            },
            {
              value:
                "Select **DRA Report** as **Report Type** using the dropdown menu.",
              id: "content-list-5063",
              marginLeft: "0",
            },
            {
              value: "Enter a **Name** for the Specialty Report.",
              id: "content-list-5064",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-5065",
              marginLeft: "0",
            },
            {
              value: "Select a **Summary Type** from the dropdown menu. ",
              id: "content-list-5066",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Delivery Time** from the dropdown menu. This dictates when the reports are sent to users.",
              id: "content-list-5067",
              marginLeft: "0",
            },
            {
              value: "Select **Groups** to be included in the report.",
              id: "content-list-5068",
              marginLeft: "0",
            },
            {
              value:
                "Select whether **Watchlist Tags** should appear on the report or not.",
              id: "content-list-5069",
              marginLeft: "0",
            },
            {
              value:
                "Select whether **Remaining Variables** (outside of the specified Groups) should appear on the report.",
              id: "content-list-5070",
              marginLeft: "0",
            },
            {
              value:
                "Specify if DRI petal charts should be included for All Groups or Select Groups (as specified by the Group Order). You can also choose the third option: Do Not Include DRI.",
              id: "content-list-5071",
              marginLeft: "0",
            },
            {
              value: "Add **Subscribers** to this Specialty Report.",
              id: "content-list-5072",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Date Range** of the charts (X-axis range) appearing on the Specialty Report.",
              id: "content-list-5073",
              marginLeft: "0",
            },
            {
              value:
                "Specify the Order by which anomalies should be organized in the report (by group or degree of anomaly).",
              id: "content-list-5074",
              marginLeft: "0",
            },
            {
              value:
                "Choose which **Degree of Anomalies** should appear on the report.",
              id: "content-list-5075",
              marginLeft: "0",
            },
            {
              value:
                "Choose **Anomaly Type** from the dropdown. Three options are available: All Anomalies, Fresh Anomalies Only, Fresh Anomalies first and followed by Other Anomalies. ",
              id: "content-list-5076",
              marginLeft: "0",
            },
            {
              value:
                "Specify a **Resolution** for reports. **$$Note: Low resolution will result in a smaller file size but decreased resolution of the charts.$$**",
              id: "content-list-5077",
              marginLeft: "0",
            },
            {
              value: "Click to **Save** your Specialty Report.",
              id: "content-list-5078",
              marginLeft: "0",
            },
            {
              value:
                "From left to right: $$Delete Report, Edit, Generate$$ and $$Send Report to Yourself, Generate$$ and $$Send Report to All Subscribers$$.",
              id: "content-list-5079",
              marginLeft: "0",
            },
            {
              value: "Displays the number of subscribers.",
              id: "content-list-5080",
              marginLeft: "0",
            },
            {
              value: "Displays the last modified date. ",
              id: "content-list-5081",
              marginLeft: "0",
            },
          ],
          id: "content-5061",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "specialtyreport.png",
          codename: "specialtyreport",
          id: "content-5082",
          content: assets.specialtyreport,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Creating a Sloping Trend Report**",
          id: "content-5083",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click **plus (+)** button to launch a form to create a new Specialty Report.",
              id: "content-list-5085",
              marginLeft: "0",
            },
            {
              value:
                "Select **Sloping Trend Report** as **Report Type** using the dropdown menu.",
              id: "content-list-5086",
              marginLeft: "0",
            },
            {
              value: "Enter a **Name** for the Specialty Report.",
              id: "content-list-5087",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-5088",
              marginLeft: "0",
            },
            {
              value: "Select a **Summary Type** from the dropdown menu. ",
              id: "content-list-5089",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Delivery Time** from the dropdown menu. This dictates when the reports are sent to users.",
              id: "content-list-5090",
              marginLeft: "0",
            },
            {
              value: "Select **Groups** to be included in the report.",
              id: "content-list-5091",
              marginLeft: "0",
            },
            {
              value: "Add **Subscribers** to this Specialty Report.",
              id: "content-list-5092",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Date Range** of the charts (X-axis range) appearing on the Specialty Report.",
              id: "content-list-5093",
              marginLeft: "0",
            },
            {
              value:
                "Specify the Order by which Trends should be organized in the report (by trends or by group).",
              id: "content-list-5094",
              marginLeft: "0",
            },
            {
              value: "Choose which **Trends** should appear on the report.",
              id: "content-list-5095",
              marginLeft: "0",
            },
            {
              value: "Click to **Save** your Specialty Report.",
              id: "content-list-5096",
              marginLeft: "0",
            },
            {
              value:
                "From left to right: $$Delete Report, Edit, Generate$$ and $$Send Report to Yourself, Generate$$ and $$Send Report to All Subscribers.$$",
              id: "content-list-5097",
              marginLeft: "0",
            },
            {
              value: "Displays the number of subscribers.",
              id: "content-list-5098",
              marginLeft: "0",
            },
            {
              value: "Displays the last modified date.",
              id: "content-list-5099",
              marginLeft: "0",
            },
          ],
          id: "content-5084",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "specialtyreport2.png",
          codename: "specialtyreport2",
          id: "content-5100",
          content: assets.specialtyreport2,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Creating a Fault Tree Report**",
          id: "content-5101",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click **plus (+)** button to launch a form to create a new Specialty Report.",
              id: "content-list-5103",
              marginLeft: "0",
            },
            {
              value:
                "Select **Fault Tree Report** as **Report Type** using the dropdown menu.",
              id: "content-list-5104",
              marginLeft: "0",
            },
            {
              value: "Enter a **Name** for the Specialty Report.",
              id: "content-list-5105",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-5106",
              marginLeft: "0",
            },
            {
              value: "Select a **Summary Type** from the dropdown menu. ",
              id: "content-list-5107",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Delivery Time** from the dropdown menu. This dictates when the reports are sent to users.",
              id: "content-list-5108",
              marginLeft: "0",
            },
            {
              value: "Specify **Fault Trees** to be included in the report.",
              id: "content-list-5109",
              marginLeft: "0",
            },
            {
              value: "Select whether to include Fault Tree Summary or not.",
              id: "content-list-5110",
              marginLeft: "0",
            },
            {
              value:
                "Select whether to show a Fault Tree when any Node is Active or only when Main Node is Active.",
              id: "content-list-5111",
              marginLeft: "0",
            },
            {
              value: "Select whether to send the Report when empty or not.",
              id: "content-list-5112",
              marginLeft: "0",
            },
            {
              value: "Add **Subscribers** to this Specialty Report.",
              id: "content-list-5113",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Date Range** of the charts (X-axis range) appearing on the Specialty Report.",
              id: "content-list-5114",
              marginLeft: "0",
            },
            {
              value: "Click to **Save** your Specialty Report.",
              id: "content-list-5115",
              marginLeft: "0",
            },
            {
              value:
                "From left to right: $$Delete Report, Edit, Generate$$ and $$Send Report to Yourself, Generate$$ and $$Send Report to All Subscribers.$$",
              id: "content-list-5116",
              marginLeft: "0",
            },
            {
              value: "Displays the number of subscribers.",
              id: "content-list-5117",
              marginLeft: "0",
            },
            {
              value: "Displays the last modified date.",
              id: "content-list-5118",
              marginLeft: "0",
            },
          ],
          id: "content-5102",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "specialtyreport3.png",
          codename: "specialtyreport3",
          id: "content-5119",
          content: assets.specialtyreport3,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content: "**Creating an Operating Fitness Report**",
          id: "content-5120",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "Click **plus (+)** button to launch a form to create a new Specialty Report.",
              id: "content-list-5122",
              marginLeft: "0",
            },
            {
              value:
                "Select **Operating Fitness Report** as **Report Type** using the dropdown menu.",
              id: "content-list-5123",
              marginLeft: "0",
            },
            {
              value: "Enter a **Name** for the Specialty Report.",
              id: "content-list-5124",
              marginLeft: "0",
            },
            {
              value: "Enter a **Description**.",
              id: "content-list-5125",
              marginLeft: "0",
            },
            {
              value: "Select a **Summary Type** from the dropdown menu. ",
              id: "content-list-5126",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Delivery Time** from the dropdown menu. This dictates when the reports are sent to users.",
              id: "content-list-5127",
              marginLeft: "0",
            },
            {
              value: "Select **Groups** to be included in the report.",
              id: "content-list-5128",
              marginLeft: "0",
            },
            {
              value: "Select whether to send the Report when empty or not.",
              id: "content-list-5129",
              marginLeft: "0",
            },
            {
              value:
                "Select whether to show all Limits or select Limits on Trendcharts (shown in table).",
              id: "content-list-5130",
              marginLeft: "0",
            },
            {
              value: "Add **Subscribers** to this Specialty Report.",
              id: "content-list-5131",
              marginLeft: "0",
            },
            {
              value:
                "Select a **Date Range** of the charts (X-axis range) appearing on the Specialty Report.",
              id: "content-list-5132",
              marginLeft: "0",
            },
            {
              value:
                "Specify the Order by which Limits should be organized in the report (by levels or groups).",
              id: "content-list-5133",
              marginLeft: "0",
            },
            {
              value: "Choose **Analysis Type** from the dropdown.",
              id: "content-list-5134",
              marginLeft: "0",
            },
            {
              value: "Select **Levels** to be included in the report.",
              id: "content-list-5135",
              marginLeft: "0",
            },
            {
              value: "Click to **Save** your Specialty Report.",
              id: "content-list-5136",
              marginLeft: "0",
            },
            {
              value:
                "From left to right: $$Delete Report, Edit, Generate$$ and $$Send Report to Yourself, Generate$$ and $$Send Report to All Subscribers$$.",
              id: "content-list-5137",
              marginLeft: "0",
            },
            {
              value: "Displays the number of subscribers.",
              id: "content-list-5138",
              marginLeft: "0",
            },
            {
              value: "Displays the last modified date.",
              id: "content-list-5139",
              marginLeft: "0",
            },
          ],
          id: "content-5121",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "specialtyreport4.png",
          codename: "specialtyreport4",
          id: "content-5140",
          content: assets.specialtyreport4,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
    {
      id: "subsection-5141",
      title: "Users",
      content: [
        {
          type: "paragraph",
          content:
            "The **Users** page allows Admins to create individual user accounts in DRA and define their roles.",
          id: "content-5142",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value:
                "To add a new user account, click the **plus (+)** button to launch a form.",
              id: "content-list-5144",
              marginLeft: "0",
            },
            {
              value:
                "Enter the **email address** for the new user. **$$NOTE: For installs with Active Directory enabled, use the company Single Sign On Username instead of the email address.$$**",
              id: "content-list-5145",
              marginLeft: "0",
            },
            {
              value: "Enter the new user’s **First Name.**",
              id: "content-list-5146",
              marginLeft: "0",
            },
            {
              value: "Enter the new user’s **Last Name.**",
              id: "content-list-5147",
              marginLeft: "0",
            },
            {
              value: "Select which **Reports** the user should receive.",
              id: "content-list-5148",
              marginLeft: "0",
            },
            {
              value: "Click **Save** to confirm user creation in DRA.",
              id: "content-list-5149",
              marginLeft: "0",
            },
            {
              value: "Click to **Delete** or **Edit** the user profile.",
              id: "content-list-5150",
              marginLeft: "0",
            },
          ],
          id: "content-5143",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "users.png",
          codename: "users",
          id: "content-5151",
          content: assets.users,
          marginBottom: "2",
          marginTop: "0",
        },
        {
          type: "paragraph",
          content:
            "To change a **User Role** to an **Admin role**, edit the User once it is created and change the **User Role**. As discussed earlier, unlike the Users, the Admins have access to the Advanced Settings page and can make administrative changes in DRA. The Users have access to all other dashboards and capabilities. ",
          id: "content-5152",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "list",
          bullets: false,
          content: [
            {
              value: "Click the **Edit User** button on the user profile.",
              id: "content-list-5154",
              marginLeft: "0",
            },
            {
              value: "Select **Admin** under User Role.",
              id: "content-list-5155",
              marginLeft: "0",
            },
            {
              value: "Click **Save** to confirm User Role change.",
              id: "content-list-5156",
              marginLeft: "0",
            },
          ],
          id: "content-5153",
          marginTop: "0",
          marginBottom: "0",
        },
        {
          type: "image",
          filename: "users2.png",
          codename: "users2",
          id: "content-5157",
          content: assets.users2,
          marginTop: "0",
          marginBottom: "0",
        },
      ],
    },
  ],
};

export default AdminGuide;
