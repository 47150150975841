import moment from "moment";
import * as React from "react";
import { useConfigRequired } from "../../zustand/config/useConfigStore";

export function PlantTimeClock() {
  const cfg = useConfigRequired();
  const [t, causeRerender] = React.useState(0);

  React.useEffect(() => {
    // cause an automatic update every second
    const interval = setInterval(() => {
      causeRerender((t) => t + 1);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span>{moment.tz(new Date(), cfg.timezone).format("LLL")}</span>;
}
