import { createStore, type StoreApi } from "zustand";
import type * as d3 from "d3";
import { YAxisMode, BatchViewMode } from "../../time-series/types";
import { colorGen, type UniqueColorGenerator } from "../../lib/getColors";
import { assertMinLen1, iife, minLen1 } from "../../lib/utils";
import * as R from "remeda";
import {
  createUseClampStore,
  type UseClampStore,
} from "../clamps/clamp-popover-store";
import { getInitialClampStoreInputValueForBv } from "../clamps/utils";
import { atom, type ExtractAtomValue } from "jotai";
import { atomWithDefault, atomWithReset, RESET, splitAtom } from "jotai/utils";
import { TimeseriesForBv } from "../types";
import { getBvOrId, trendDataMatches, TrendLineVariant } from "../draw/draw";

export const initClampStoresForNewBatchVariable = (
  bvOrExpressionId: string,
  globalRange?: GlobalRange
): SelectedVariable["clampStores"] => {
  const getClampStoreWithInitialValue = (): SelectedVariable["clampStores"] => {
    return [
      createUseClampStore({
        auto: true,
        inputValue: getInitialClampStoreInputValueForBv(
          globalRange,
          bvOrExpressionId,
          0
        ),
      }),
      createUseClampStore({
        auto: true,
        inputValue: getInitialClampStoreInputValueForBv(
          globalRange,
          bvOrExpressionId,
          1
        ),
      }),
    ];
  };

  return getClampStoreWithInitialValue();
};

const canvasesContainerAtom = atom<HTMLElement | null>(null);

const variableSelectDrawerOpenAtom = atom(false);

/**
 * Global range tells us what is currently being
 * shown in the chart. That is, every time the chart
 * re-draws, it will provide the min/max Ys for each
 * batch variable in a callback. We store it here and
 * reference it in clamping calculations. For example,
 * if a user enters a clamp that is out of bounds of
 * the data, we'll know to show some visual error
 * state.
 *
 * When this value is undefined, that means we're probably
 * in between a draw cycle and it hasn't been updated yet.
 * Or, it means that the user set a clamp such that nothing
 * drawn, so globalRange is undefined because there is no
 * trend line drawn.
 */
const globalRangeAtom = atom<GlobalRange | undefined>(undefined);

/**
 * The draw function can update subscribers with what
 * batch variables the hover line is intersecting. This
 * state keeps track of that and is very fast-changing.
 */
type InterpolatedOrRealPoint = {
  v: number;
  t: number; // if there doesn't exist a real time t in the data, then you can consider this as an interpolated point (v was calculated from interpolation)
  stageId: string;
};
const hoverIntersectionMapAtom = atom<
  | {
      [bv: string]: InterpolatedOrRealPoint;
    }
  | undefined
>(undefined);

const hoveredLineAtom = atom<
  | (TrendLineVariant & { stage: TimeseriesForBv["stages"][number]["_id"] })
  | undefined
>(undefined);

type TrendLineFor = "variable" | "expression";

type SelectedVariable = (
  | { type: "variable"; anom: boolean; bv: string }
  | { type: "expression"; id: string; expression: string }
) & {
  color: string;
  // checked: boolean;
  yClampMin?: number;
  yClampMax?: number;
  clampStores?: [UseClampStore, UseClampStore];
};

export function isVariableTrendLine(
  v: SelectedVariable
): v is Extract<SelectedVariable, { type: "variable" }> {
  return v.type === "variable";
}

export function isExpressionTrendLine(
  v: SelectedVariable
): v is Extract<SelectedVariable, { type: "expression" }> {
  return v.type === "expression";
}

export type InitialTrendLine =
  | {
      type: "expression";
      id: string;
      expression: string;
    }
  | {
      type: "variable";
      bv: string;
    };

export enum ChartVariant {
  OperatingLimits = "ol",
  SlopingTrends = "st",
  FaultTrees = "ft",
  Aria = "aria",
  InstantCalculator = "ic",
}

/**
 * This atom must be initialized with a provider or else
 * you'll probably get a crash. It's used to initialize
 * the state of the store.
 */
const _initialStateAtom = atom<
  | {
      expanded: boolean;
      batchVariablesOrExpressions: [InitialTrendLine, ...InitialTrendLine[]];
      decideAnomBooleanForNewlyAddedVariableTrendLines:
        | ((initialSt: boolean, isOperatingLimitsVariant: boolean) => boolean)
        | undefined;
      variant?: ChartVariant; // operating limits variant, this is not settable after initial because it shouldn't be turned off if we're rendering it on limits pages
    }
  | undefined
>(undefined);

const _domainAtom = atom<[number, number] | undefined>(undefined);

const getDomainAtom = atom((get) => {
  const x = get(_domainAtom);
  if (!x) throw new Error("must be initialized");
  return x;
});

const setDomainAtom = atom(null, (_, set, domain: [number, number]) => {
  set(_domainAtom, domain);
});

const zoomAtomAtom = atomWithDefault((get) => {
  get(getDomainAtom); // recompute when domain changes
  get(singleVariableViewAtom); // recompute when going from single to multi or other way around
  return atom<
    d3.D3ZoomEvent<SVGSVGElement, readonly [number, number]> | undefined
  >(undefined);
});

/**
 * On every draw/zoom event, the draw function will
 * update the drawnDomain (left/right) bounds of the
 * chart.
 */
const drawnDomainAtomAtom = atom((get) => {
  const ogDomain = get(getDomainAtom); // when this changes we need a new config
  return atom(ogDomain);
});

/**
 * If you know that a user action (like clicking a button)
 * will require the chart to show some range, you can use
 * this atom to ensure that the domain is set to include
 * that range.
 */
const ensureDomainAtom = atom(null, (get, set, ensure: [number, number]) => {
  const [s, e] = ensure;

  const curr = get(getDomainAtom);

  if (s >= curr[0] && e <= curr[1]) return; // noop

  set(setDomainAtom, [Math.min(curr[0], s), Math.max(curr[1], e)]);
});

const getInitialStateAtom = atom((g) => {
  const x = g(_initialStateAtom);
  if (!x) throw new Error("must be initialized");
  return x;
});

const getInitialStateOnlyVariableTrendLines = atom((g) => {
  const variableIds = g(getInitialStateAtom)
    .batchVariablesOrExpressions.filter((x) => x.type === "variable")
    .map((x) => x.bv.slice(24));

  return variableIds;
});

const chartVariantAtom = atom((g) => g(getInitialStateAtom).variant);

const showLimitLinesAtom = atomWithDefault(
  (get) => get(chartVariantAtom) === "ol"
);

const setInitialStateAtom = atom(
  null,
  (_, set, initialState: ExtractAtomValue<typeof _initialStateAtom>) => {
    set(_initialStateAtom, initialState);
  }
);

const excludedModesAtom = atomWithDefault((get) => {
  const isStVariant = get(getInitialStateAtom).variant === "st";
  const out: Set<TimeseriesForBv["stages"][number]["_id"]> = new Set();
  isStVariant && out.add("000000000000000000000000");
  return out;
});

const atLeastOneExcludedMaskAtom = atom((get) => {
  return get(excludedModesAtom).size > 0;
});

const xAxisContinuityAtom = atom((get) => {
  const hasExcludedMask = get(atLeastOneExcludedMaskAtom);

  if (!hasExcludedMask) return undefined;

  // only allow discontinuity if there is at least one excluded mask

  return atomWithDefault((get) => {
    const isSlopingTrends = get(slopingTrendsAtom);
    const isContinuous = !isSlopingTrends;
    return isContinuous;
  });
});

const expandedAtom = atomWithDefault<boolean>(
  (get) => get(getInitialStateAtom).expanded
);

const initialAnomBoolForNewlyAddedVariableTrendLines = atom((get) => {
  const initialState = get(getInitialStateAtom);

  const customDecider =
    initialState.decideAnomBooleanForNewlyAddedVariableTrendLines;

  // default behavior
  const startedOffSlopingTrends = initialState.variant === "st";
  const isOperatingLimitVariant = initialState.variant === "ol";

  if (customDecider) {
    return customDecider(startedOffSlopingTrends, isOperatingLimitVariant);
  }
  const anomaliesStartOff = startedOffSlopingTrends || isOperatingLimitVariant;

  const anomaliesStartOn = !anomaliesStartOff;

  return anomaliesStartOn;
});

const isModeTransparencyAtom = atomWithReset<boolean | undefined>(undefined);

const _selectedVariablesAtom = atomWithDefault<
  [SelectedVariable, ...SelectedVariable[]]
>(function initialize(get) {
  const initialState = get(getInitialStateAtom);
  const bvs = initialState.batchVariablesOrExpressions;

  /**
   * If the chart is in sloping trends view when state gets inited,
   * or the variant of the chart is operating limits, then we start
   * with the anomalies turned off.
   */
  const initialAnomalyOn = get(initialAnomBoolForNewlyAddedVariableTrendLines);
  const colors = colorGen.getN([], bvs.length);
  const out = bvs.map((bv, i): SelectedVariable => {
    const color = colors[i];
    if (!color) throw new Error("no color");

    switch (bv.type) {
      case "variable":
        return {
          type: "variable",
          bv: bv.bv,
          color,
          anom: initialAnomalyOn,
          clampStores: initClampStoresForNewBatchVariable(bv.bv),
        };
      case "expression":
        return {
          type: "expression",
          expression: bv.expression,
          id: bv.id,
          clampStores: initClampStoresForNewBatchVariable(bv.id),
          color,
        };
      default:
        const _: never = bv;
        throw new Error("unhandled");
    }
  });
  if (!minLen1(out)) throw new Error("BUG");
  return out;
});

const selectedCommentIdAtom = atomWithReset<string | undefined>(undefined);

// reset the selected variables to the initial state
const resetAtom = atom(null, (_, set) => {
  set(_selectedVariablesAtom, RESET);
  set(expandedAtom, RESET);
  set(zoomAtomAtom, RESET);
  set(_brushStoreAtom, RESET);
  set(showLimitLinesAtom, RESET);
  set(selectedCommentIdAtom, RESET);
  set(isModeTransparencyAtom, RESET);
  set(_slopingTrendsAtom, RESET);
});

/**
 * Side effects when the number of selected variables changes.
 *
 * We have to do things like open/close the sidebar, reset the
 * pinned batch variable, etc.
 *
 * Not fired on initial state set, only on user-interaction changes
 */
const onNumSelectedVariablesChange = atom(
  null,
  (get, set, currLen: number, newLen: number) => {
    const isOl = get(chartVariantAtom) === "ol";
    if (!isOl) return;

    const isSingleVariableView = currLen === 1;
    const goingFromSingleToMulti = isSingleVariableView && newLen > 1;

    /**
     * In an operating limits chart, turn off the limit lines
     * when we go from single to multi-variable view.
     *
     * But in all other cases, keep it as is.
     */
    if (goingFromSingleToMulti) {
      set(showLimitLinesAtom, false);
    }
  }
);

// read/write selected variables
const selectedVariablesAtom = atom(
  (get) => get(_selectedVariablesAtom),
  (
    get,
    set,
    selectedVariablesOrFn:
      | [SelectedVariable, ...SelectedVariable[]]
      | ((
          curr: [SelectedVariable, ...SelectedVariable[]],
          colorGen: UniqueColorGenerator
        ) => [SelectedVariable, ...SelectedVariable[]])
  ) => {
    const currentSelectedVariables = get(_selectedVariablesAtom);

    let newSelectedVariables: typeof currentSelectedVariables;

    if (typeof selectedVariablesOrFn === "function") {
      newSelectedVariables = selectedVariablesOrFn(
        currentSelectedVariables,
        colorGen
      );
    } else {
      newSelectedVariables = selectedVariablesOrFn;
    }

    if (!minLen1(newSelectedVariables))
      throw new Error("selectedVariables must have at least one variable");

    set(
      onNumSelectedVariablesChange,
      currentSelectedVariables.length,
      newSelectedVariables.length
    ); // side effects
    set(_selectedVariablesAtom, newSelectedVariables);
  }
);

const daBarsAtomAtom = atom((get) => {
  const isSingle = get(singleVariableViewAtom);

  if (isSingle) return undefined;

  /**
   * When entering multi-view. Start all da bars off.
   */
  return atom(false);
});

const singleVariableViewAtom = atom(
  (get) => get(selectedVariablesAtom).length === 1
);

const showNotificationsAtomAtom = atom((get) => {
  const isSingle = get(singleVariableViewAtom);
  if (isSingle) return atom(false);
  return undefined;
});

const excludedBatchVariablesSetAtomAtom = atom(
  (
    get
  ): {
    [K in TrendLineFor]: ReturnType<typeof atom<Set<string>>>;
  } => {
    get(singleVariableViewAtom); // re-compute when singleVariableViewAtom changes
    return {
      expression: atom(new Set<string>()),
      variable: atom(new Set<string>()),
    };
  }
);

const cardsSidebarOpenAtomAtom = atom((get) => {
  const isSingle = get(singleVariableViewAtom); // re-compute when singleVariableViewAtom changes
  return atom(!isSingle);
});

const yAxisModeAtomAtom = atom((get) => {
  const isSingle = get(singleVariableViewAtom); // re-compute when singleVariableViewAtom changes

  if (isSingle) return undefined;
  return atom(YAxisMode.Absolute);
});

const yAxisModeAtom = atom(
  (get) => {
    const a = get(yAxisModeAtomAtom);
    if (a) return get(a);
    return undefined;
  },
  (get, set, mode: YAxisMode) => {
    const a = get(yAxisModeAtomAtom);
    if (!a) throw new Error("should not be calling");

    // remove any clamping performed on the trend lines when switching modes
    set(selectedVariablesAtom, (curr) => {
      return assertMinLen1(
        curr.map((x) => {
          return {
            ...x,
            yClampMax: undefined,
            yClampMin: undefined,
            clampStores: initClampStoresForNewBatchVariable(
              getBvOrId(x),
              get(globalRangeAtom)
            ),
          };
        })
      );
    });
    set(a, mode);
  }
);

const xAxisModeAtomAtom = atom((get) => {
  get(singleVariableViewAtom);
  return atom(BatchViewMode.series); // re-compute when singleVariableViewAtom changes
});

const spotlightAtomAtom = atom((get) => {
  const yAxisMode = get(yAxisModeAtom); // re-compute when yAxisModeAtomAtom changes
  return atom(yAxisMode === YAxisMode.Relative);
});

const onlyShowCommentsInFullscreenAtom = atom(false);

const _slopingTrendsAtom = atomWithDefault(
  (get) => get(getInitialStateAtom).variant === "st"
);
const slopingTrendsAtom = atom((get) => get(_slopingTrendsAtom));
const setSlopingTrendsAtom = atom(
  null,
  (get, set, opts: { on: boolean; newChartDomain?: [number, number] }) => {
    const isSlopingTrendsView = opts.on;
    const newAnomalyMode = !isSlopingTrendsView;

    const newSv = get(selectedVariablesAtom).map((x) => ({
      ...x,
      anom: newAnomalyMode,
    }));

    const excludedModes = new Set(get(excludedModesAtom));

    /**
     * When entering sloping trends view, exclude shutdowns.
     * When exiting sloping trends view, turn shutdowns back on.
     */
    opts.on
      ? excludedModes.add("000000000000000000000000")
      : excludedModes.delete("000000000000000000000000");

    set(_slopingTrendsAtom, opts.on);
    set(selectedVariablesAtom, assertMinLen1(newSv));
    opts.newChartDomain && set(setDomainAtom, opts.newChartDomain);
    set(excludedModesAtom, excludedModes);
  }
);

const chartInViewportAtom = atom(false);

const removeBatchIdAtom = atom(null, (get, set, bid: string) => {
  const selectedVariables = get(selectedVariablesAtom);
  const newSelected = selectedVariables.filter((selectedVariable, i) => {
    if (selectedVariable.type === "expression") return true;
    const isFirst = i === 0;
    return isFirst || selectedVariable.bv.slice(0, 24) !== bid;
  }) as typeof selectedVariables;

  if (!minLen1(newSelected)) throw new Error("BUG");

  set(selectedVariablesAtom, newSelected);
});

const addSelectedBatchIdsAtom = atom(
  null,
  (get, set, batchIdsToAdd: string[]) => {
    const currentBatchVariables = get(selectedVariablesAtom);

    const existingBvs = currentBatchVariables
      .map((v) => (v.type === "variable" ? v.bv : undefined))
      .filter((x) => x !== undefined);

    const currentVariableIds = Array.from(
      new Set(existingBvs.map((bvar) => bvar.slice(24)))
    );

    const newBatchVariablesToAdd = currentVariableIds
      .flatMap((uniqueVariableId) =>
        batchIdsToAdd.map((b) => `${b}${uniqueVariableId}`)
      )
      .filter((bv) => !existingBvs.includes(bv)); // dont add if already exists

    if (!newBatchVariablesToAdd.length) return currentBatchVariables.length; // noop

    const newColors = colorGen.getN(
      currentBatchVariables.map((x) => x.color),
      newBatchVariablesToAdd.length
    );

    const asObjs = newBatchVariablesToAdd.map(
      (bv, i): (typeof currentBatchVariables)[number] => {
        const color = newColors[i];

        if (!color) throw new Error("no color");
        return {
          type: "variable",
          bv,
          anom: false, // new batches are not colored by default
          color,
          clampStores: initClampStoresForNewBatchVariable(
            bv,
            get(globalRangeAtom)
          ),
        };
      }
    );

    const newSelectedVariables = currentBatchVariables.concat(
      asObjs
    ) as typeof currentBatchVariables; // safe cast as we're adding new variables

    set(selectedVariablesAtom, newSelectedVariables);
  }
);

// const multipleVariableViewAtom = atom((get) => !get(singleVariableViewAtom));

export type GlobalRange = {
  max: number;
  min: number;
  perBv: Record<string, [number, number] | undefined>;
};

type Dimensions = {
  h: number;
  w: number;
};

const containerDimensionsAtom = atom<Dimensions | undefined>(undefined);
const chartAreaDimensionsAtom = atom<Dimensions | undefined>(undefined);

type BrushRange = [number, number | undefined];
type BrushStore = {
  unorderedRange: BrushRange; // unordered because the user can drag the brush in either direction
  setRange: (range: BrushRange | ((curr: BrushRange) => BrushRange)) => void;
  handleData: HandleData | undefined;
  setHandleData: (handleData: HandleData) => void;
  redrawer: ((r: BrushRange) => void) | undefined;
  setRedrawer: (r: ((r: BrushRange) => void) | undefined) => void;

  setRightFixed: (isFixed: boolean) => void;
};

type BrushFor =
  | "comment-create"
  | "comment-edit"
  | "pattern-notif-edit"
  | "pattern-notif-create";
const _brushStoreAtom = atomWithReset<
  | {
      brushStore: StoreApi<BrushStore>;
      for: BrushFor;
    }
  | undefined
>(undefined);

type SavedXScale = {
  (t: number): number | undefined;
  invert: (t: number) => number | undefined;
  domain: () => [number, number];
  range: () => [number, number];
}; // this looks like an object but is a function, so be careful when putting in useState. atom is built on top of this

const _xScaleAtom = atomWithReset<SavedXScale | undefined>(undefined);

const setXScaleAtom = atom(null, (_, set, xScale: SavedXScale | undefined) => {
  // we must use a function here because the xScale is a function
  // See https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react-hooks
  set(_xScaleAtom, () => xScale);
});

const getXScaleAtom = atom((get) => get(_xScaleAtom));

const brushStoreAtom = atom(
  (get) => get(_brushStoreAtom),
  (
    get,
    set,
    o:
      | { range: [number, number | undefined] | undefined; mode: BrushFor }
      | undefined
  ) => {
    if (!o) {
      set(_brushStoreAtom, RESET);
      return;
    }
    const { range, mode } = o;

    set(_brushStoreAtom, {
      for: mode,
      brushStore: createStore<BrushStore>((set2) => {
        return {
          unorderedRange:
            range ??
            iife(() => {
              const range = get(get(drawnDomainAtomAtom));

              if (!range) throw new Error("BUG");
              return [range[1] - (range[1] - range[0]) / 2, range[1]] as [
                number,
                number,
              ];
            }),
          setRange: (range) =>
            set2((curr) => {
              const out =
                typeof range === "function"
                  ? range(curr.unorderedRange)
                  : range;
              curr.redrawer?.(out); // side effect?
              return {
                unorderedRange: out,
              };
            }),
          handleData: undefined,
          setHandleData: (handleData) => set2({ handleData }),
          redrawer: undefined,
          setRedrawer: (redrawer) => set2({ redrawer }),

          setRightFixed: (rightIsFixed) => {
            set2((curr) => {
              const [l, r] = curr.unorderedRange;

              const out: BrushRange = rightIsFixed
                ? [r === undefined ? l : Math.min(l, r), undefined]
                : iife(() => {
                    const range = get(get(drawnDomainAtomAtom));
                    if (!range) throw new Error("BUG");
                    return [l, range[1]].sort((a, b) => a - b) as [
                      number,
                      number,
                    ];
                  });

              curr.redrawer?.(out); // side effect?
              return {
                unorderedRange: out,
              };
            });
          },
        };
      }),
    });
  }
);

const isCreatingCommentAtom = atom((get) => {
  const b = get(brushStoreAtom);
  return b ? b.for === "comment-create" : false;
});

const redrawCanvasFnAtom = atom<
  | ((
      highlightedBv:
        | (TrendLineVariant & {
            stage: TimeseriesForBv["stages"][number]["_id"];
          })
        | undefined
    ) => void)
  | undefined
>(undefined);

const setYClampMinAtom = atom(
  null,
  (get, set, target?: TrendLineVariant, d?: number) => {
    const s = get(selectedVariablesAtom);
    if (target === undefined) {
      // set all
      const out = s.map((x): typeof x => {
        /**
         * Choosing to make this a mutable change for
         * now, but I feel kinda weird about it because
         * it requires you to really understand how it's
         * being used especially in a useEffect. Might
         * change...
         */
        x.yClampMin = d;
        return x;
      }) as typeof s;

      set(selectedVariablesAtom, out);
      return;
    }

    // set a specific one
    const out = s.map((x): typeof x => {
      if (trendDataMatches(x, target)) {
        /**
         * Choosing to make this a mutable change for
         * now, but I feel kinda weird about it because
         * it requires you to really understand how it's
         * being used especially in a useEffect. Might
         * change...
         */
        x.yClampMin = d;
      }
      return x;
    }) as typeof s;

    set(selectedVariablesAtom, out);
  }
);

const setYClampMaxAtom = atom(
  null,
  (
    get,
    set,
    target?:
      | { type: "variable"; bv: string }
      | { type: "expression"; id: string },
    d?: number
  ) => {
    const s = get(selectedVariablesAtom);
    if (target === undefined) {
      // set all
      const out = s.map((x): typeof x => {
        /**
         * Choosing to make this a mutable change for
         * now, but I feel kinda weird about it because
         * it requires you to really understand how it's
         * being used especially in a useEffect. Might
         * change...
         */
        x.yClampMax = d;
        return x;
      }) as typeof s;
      set(selectedVariablesAtom, out);
      return;
    }

    // set a specific one
    const out = s.map((x): typeof x => {
      if (trendDataMatches(x, target)) {
        /**
         * Choosing to make this a mutable change for
         * now, but I feel kinda weird about it because
         * it requires you to really understand how it's
         * being used especially in a useEffect. Might
         * change...
         */
        x.yClampMax = d;
      }
      return x;
    }) as typeof s;

    set(selectedVariablesAtom, out);
  }
);

type HandleData = {
  /**
   * Derivatives of padding
   */
  leftPercent: number;
  topPercent: number;
  heightPercent: number;
};

const checkedBatchVariablesAtom = atom<{
  [K in TrendLineFor]: Set<string>;
}>((g) => {
  const selectedVariables = g(selectedVariablesAtom);
  const excludedBatchVariablesAtom = g(excludedBatchVariablesSetAtomAtom);

  const excludedBatchVariables = g(excludedBatchVariablesAtom.variable);
  const excludedExpressionIds = g(excludedBatchVariablesAtom.expression);

  // if none are pinned, look to excluded to figure out whats included
  const included = selectedVariables.filter((x) => {
    switch (x.type) {
      case "expression":
        return !excludedExpressionIds.has(x.id);
      case "variable":
        return !excludedBatchVariables.has(x.bv);
      default:
        const _: never = x;
        throw new Error("unhandled");
    }
  });

  const includedBatchVariables = included
    .filter((x) => x.type === "variable")
    .map((x) => x.bv);

  const includedExpressionIds = included
    .filter((x) => x.type === "expression")
    .map((x) => x.id);

  return {
    variable: new Set(includedBatchVariables),
    expression: new Set(includedExpressionIds),
  };
});

const resetBrushAtom = atom(null, (_, set) => {
  set(brushStoreAtom, undefined);
});

const toggleExcludedVariableTrendLineAtom = atom(
  null,
  (get, set, bv: string) => {
    const excluded = get(excludedBatchVariablesSetAtomAtom);
    const prev = get(excluded.variable);

    const copy = new Set(prev);
    copy.delete(bv) || copy.add(bv); // a way of saying delete if it exists, otherwise add
    set(excluded.variable, copy);
  }
);

const toggleExcludedExpressionTrendLineAtom = atom(
  null,
  (get, set, bv: string) => {
    const excluded = get(excludedBatchVariablesSetAtomAtom);
    const prev = get(excluded.expression);

    const copy = new Set(prev);
    copy.delete(bv) || copy.add(bv); // a way of saying delete if it exists, otherwise add
    set(excluded.expression, copy);
  }
);

const excludeAllButOneAtom = atom(
  null,
  (get, set, bvOrIdToKeep: string, type: TrendLineFor) => {
    const excludedBatchVariablesAtom = get(excludedBatchVariablesSetAtomAtom);
    const selectedVariables = get(selectedVariablesAtom);

    const excludeTheseExpressionIds = new Set(
      selectedVariables.filter((x) => x.type === "expression").map((x) => x.id)
    );
    const excludeTheseBatchVariables = new Set(
      selectedVariables.filter((x) => x.type === "variable").map((x) => x.bv)
    );

    switch (type) {
      case "expression":
        excludeTheseExpressionIds.delete(bvOrIdToKeep);
        break;
      case "variable":
        excludeTheseBatchVariables.delete(bvOrIdToKeep);
        break;
      default:
        const _: never = type;
        throw new Error("unhandled");
    }

    set(excludedBatchVariablesAtom.expression, excludeTheseExpressionIds);
    set(excludedBatchVariablesAtom.variable, excludeTheseBatchVariables);
  }
);

const setAllAnomalyColorationAtom = atom(null, (get, set, on: boolean) => {
  const newSelectedVariables = get(Atoms.selectedVariablesAtom).map((x) => {
    return { ...x, anom: on };
  });
  set(Atoms.selectedVariablesAtom, assertMinLen1(newSelectedVariables));
});
const setAllShutdownMaskAtom = atom(null, (get, set, on: boolean) => {
  set(excludedModesAtom, (prev) => {
    const out = new Set(prev);
    if (on) {
      out.add("000000000000000000000000");
    } else {
      out.delete("000000000000000000000000");
    }
    return out;
  });
});
const setAllModeTransparencyAtom = atom(null, (get, set, on: boolean) => {
  set(isModeTransparencyAtom, on);
});

const onlyVariableTrendLinesAtom = atom((get) => {
  const selectedVariables = get(selectedVariablesAtom);
  return selectedVariables.filter((x) => x.type === "variable");
});

const onlyExpressionTrendLinesAtom = atom((get) => {
  const selectedVariables = get(selectedVariablesAtom);
  return selectedVariables.filter((x) => x.type === "expression");
});

const onlyVariableTrendLinesVariableIdsAtom = atom((get) => {
  return get(onlyVariableTrendLinesAtom).map((x) => x.bv.slice(24));
});

const atLeastOneVariableTrendLineAtom = atom(
  (get) => get(onlyVariableTrendLinesAtom).length > 0
);

const selectedVariableAtomsAtom = splitAtom(selectedVariablesAtom);

const primaryBatchVariableAtom = atom((get) => {
  const firstAtom = get(selectedVariableAtomsAtom)[0];

  if (!firstAtom) throw new Error("BUG");

  return get(firstAtom);
});

const Atoms = {
  selectedVariableAtomsAtom,
  atLeastOneVariableTrendLineAtom,
  onlyExpressionTrendLinesAtom,
  onlyVariableTrendLinesVariableIdsAtom,
  onlyVariableTrendLinesAtom,
  expandedAtom,
  drawnDomainAtomAtom,
  variableSelectDrawerOpenAtom,
  globalRangeAtom,
  hoverIntersectionMapAtom,
  removeBatchIdAtom,
  addSelectedBatchIdsAtom,
  hoveredLineAtom,
  zoomAtomAtom,
  selectedVariablesAtom,
  singleVariableViewAtom,
  yAxisModeAtomAtom,
  xAxisModeAtomAtom,
  spotlightAtomAtom,
  cardsSidebarOpenAtomAtom,
  setYClampMinAtom,
  setYClampMaxAtom,
  setSlopingTrendsAtom,
  slopingTrendsAtom,
  primaryBatchVariableAtom,
  chartInViewportAtom,
  chartAreaDimensionsAtom,
  containerDimensionsAtom,
  brushStoreAtom,
  redrawCanvasFnAtom,
  resetAtom,
  setInitialStateAtom,
  excludeAllButOneAtom,
  excludedBatchVariablesSetAtomAtom,
  checkedBatchVariablesAtom,
  getXScaleAtom,
  setXScaleAtom,
  chartVariantAtom,
  showLimitLinesAtom,
  resetBrushAtom,
  ensureDomainAtom,
  canvasesContainerAtom,
  showNotificationsAtomAtom,
  excludedModesAtom,
  xAxisContinuityAtom,
  selectedCommentIdAtom,
  isCreatingCommentAtom,
  getDomainAtom,
  setDomainAtom,
  yAxisModeAtom,
  isModeTransparencyAtom,
  setAllAnomalyColorationAtom,
  setAllShutdownMaskAtom,
  setAllModeTransparencyAtom,
  onlyShowCommentsInFullscreenAtom,
  toggleExcludedExpressionTrendLineAtom,
  toggleExcludedVariableTrendLineAtom,
  getInitialStateOnlyVariableTrendLines,
  initialAnomBoolForNewlyAddedVariableTrendLines,
  daBarsAtomAtom,
} as const;

export { type SelectedVariable, Atoms };
