import { getAPIUrl } from "../../api-url";
import {
  DEFAULT_HEADERS,
  type Method,
  type MyResponse,
} from "./constantsAndTypes";

const APIServer = getAPIUrl();

function getFullPath(path: string) {
  return `${APIServer}${path}`;
}

function getFetchOptions(
  method: Method,
  headers: Record<string, string>
): RequestInit {
  return {
    method: method,
    cache: "reload",
    headers: new Headers(headers),
    credentials: "include",
  };
}

async function handleRes(res: Response) {
  const newRes: MyResponse = {
    headers: res.headers,
    ok: res.ok,
    redirected: res.redirected,
    status: res.status,
    statusText: res.statusText,
    type: res.type,
    url: res.url,
    body: null,
    data: null,
  };

  const resContentType = res.headers.get("Content-Type");

  if (resContentType == null) newRes.body = "";
  else if (resContentType.includes("application/json"))
    newRes.body = await res.json();
  else if (resContentType.includes("text")) newRes.body = await res.text();
  else if (resContentType.includes("multipart/form-data"))
    newRes.body = await res.formData();
  else newRes.body = await res.blob();
  if (!newRes.ok) throw newRes;

  newRes.data = newRes.body;
  return newRes;
}

/**
 * GET has no params because shouldn't
 * ever need to pass a body to GET req
 */
export async function GET(path: string) {
  const res = await fetch(
    getFullPath(path),
    getFetchOptions("GET", DEFAULT_HEADERS)
  );
  return handleRes(res);
}

function getOtherMethods(method: Method) {
  return async <T>(
    path: string,
    options?: {
      body: T;
    }
  ) => {
    const optionsOut = getFetchOptions(method, DEFAULT_HEADERS);

    if (options?.body) {
      optionsOut.body = JSON.stringify(options.body);
    }

    const res = await fetch(getFullPath(path), optionsOut);
    return handleRes(res);
  };
}

/**
 * These 3 are identical
 */
export const PATCH = getOtherMethods("PATCH");
export const POST = getOtherMethods("POST");
export const DELETE = getOtherMethods("DELETE");
