/* Max requests for the same origin on a few browsers:
 *
 * - Firefox: 6
 * - Chrome: ???
 * - IE 11: 13
 *
 * The goal here is to guarantee that no matter what, we'll have a (limited)
 * open pool of available requests that we can use. The same-origin is
 * important, because all of our requests go to the same origin. */
const BACKGROUND_REQUEST_LIMIT = 4; /* TODO: should this be set based on browser detection? */
const backgroundRequestsQueue = [];
let availableBackgroundRequests = BACKGROUND_REQUEST_LIMIT;

function enqueue(call) {
  function next() {
    const nextCall = backgroundRequestsQueue.shift();
    if (!nextCall) {
      return;
    }
    nextCall();
  }

  const result = new Promise(function (res, rej) {
    backgroundRequestsQueue.push(function () {
      availableBackgroundRequests--;

      call()
        .then((result) => {
          res(result);
          availableBackgroundRequests++;
          next();
        })
        .catch((result) => {
          rej(result);
          availableBackgroundRequests++;
          next();
        });
    });
  });

  if (availableBackgroundRequests) {
    next();
  }

  return result;
}

export default enqueue;
