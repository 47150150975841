import {
  createContext,
  memo,
  useContext,
  useEffect,
  type ComponentProps,
} from "react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import {
  Comments,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import * as SVV from "../../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { Header } from "./header";
import { BottomControls } from "./bottom-controls";
import { VariableSelectOverlay } from "../../../shared-ui/time-series-2/secondary-variable-view/variable-select-overlay";
import { VariableCardsSidebar } from "./variable-cards-sidebar";
import { TooltipProvider } from "../../../shared-ui/frontend/tooltip";
import {
  heightFactorAtom,
  TimeseriesChart,
} from "../../../shared-ui/time-series-2/timeseries-chart";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { BottomNotificationsArea } from "../../notifications/dropdown/notifications-area";
import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../../boundaries/hooks/useDRAParams2";
import { useAtomValue, useSetAtom, useStore } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { iife } from "../../../lib/utils";
import { BottomCommentArea } from "./comments/comment-area";
import { YAxisMode } from "../../../shared-ui/time-series/types";
import {
  useGetUseVariabilityDrawerStore,
  VariabilityDrawerStoreProvider,
} from "../../variability-view/variability-drawer";
import { assertMinLen1, minLen1 } from "../../../shared-ui/lib/utils";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import { VariabilityChart } from "../../variability-view/variability-chart";
import WhiskerBoxPlot from "../../variability-view/WhiskerBoxPlot";
import { VariabilityTooltip } from "../../variability-view/tooltip/tooltip";
import { useOperatingModesQuery } from "../../om/manager/queries";
import { FullscreenCommentsNotificationsSidebar } from "./comments/fullscreen-comments-sidebar";
import { LimitsForChart } from "../../of/details/list-of-charts/list-of-charts";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import {
  isVariableVariant,
  TALLER_CHART_SCALE,
} from "../../../shared-ui/time-series-2/draw/draw";
import { useMemo } from "use-memo-one";

type ChartRenderOptions = {
  noPortalMultiSelects: boolean;
  hideCommentPills: boolean;
};

const defaultRenderOptions: ChartRenderOptions = {
  noPortalMultiSelects: false,
  hideCommentPills: false,
};

const ChartRenderOptionsContext =
  createContext<ChartRenderOptions>(defaultRenderOptions);

export function useChartRenderOptions() {
  return useContext(ChartRenderOptionsContext);
}

const initialContainerDims = {
  height: 350,
  width: 1200,
  margin: 60,
};

/**
 * Can be used in DRA or Aria context
 */
function SecondaryVariableViewNeedsProvider({
  onClose,
  expandable,
  loading,
  respondToSelectedCommentId,
  allowModeTransparency,
  closeAndDisableLimitStatusSeries,
  noDaBars,
  onVariabilityClick,
  noPortalMultiSelects,
  hideCommentPills,
  ...props
}: Omit<
  ComponentProps<typeof TimeseriesChart>,
  keyof Pick<
    ComponentProps<typeof TimeseriesChart>,
    "stages" | "theme" | "timezone" | "app" | "inViewOverride"
  >
> &
  PropsWithCn<
    ComponentProps<typeof Header> & {
      loading?: boolean;
      /**
       * Depending on what page we're on, we may or may not want
       * this chart to respond to selected comment id in the
       * search params.
       */
      respondToSelectedCommentId?: boolean;
      allowModeTransparency?: boolean; // can this chart use the mode transparency feature?
      closeAndDisableLimitStatusSeries?: boolean;

      noPortalMultiSelects?: boolean;
      hideCommentPills?: boolean;
      onVariabilityClick?: () => void;
    }
  >) {
  const variables = useVariablesArrayQuery().data ?? [];
  const timezone = useTimezone();

  const [sp] = useSearchParams();

  const commentId = sp.get(DRAParamsMap.selectedCommentId)?.trim() || undefined;
  const comment = Comments.single.useQuery(commentId).data;

  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const primaryIsVariableVariant = isVariableVariant(primaryTrendLine);

  const candidateComment = iife(() => {
    if (!primaryIsVariableVariant) return undefined;
    if (!comment) return undefined;
    const belongsToMe =
      comment.context?.variableId === primaryTrendLine.bv.slice(24);
    if (!belongsToMe) return undefined;

    return comment;
  });

  const setSelectedCommentId = useSetAtom(Atoms.selectedCommentIdAtom);
  const setAllAnomalyColoration = useSetAtom(Atoms.setAllAnomalyColorationAtom);
  const setSlopingTrends = useSetAtom(Atoms.setSlopingTrendsAtom);

  useEffect(
    function handleSetSelectedCommentWhenParamsChange() {
      if (!respondToSelectedCommentId) return;
      if (!candidateComment) return;

      setSelectedCommentId(candidateComment._id);

      const showAnomalyColoration =
        candidateComment.context?.chartContext.showAnomalyColoration ?? true;

      const showSlopingTrends =
        candidateComment.context?.chartContext.showSlopingTrends ?? false;

      setSlopingTrends({ on: showSlopingTrends });

      // must be done after sloping trends, not sure why right now..
      setAllAnomalyColoration(showAnomalyColoration);
    },
    [
      respondToSelectedCommentId,
      setSelectedCommentId,
      candidateComment,
      setAllAnomalyColoration,
      setSlopingTrends,
    ]
  );

  const modesQuery = useOperatingModesQuery();
  const modes = modesQuery.data;
  const jot = useStore();

  useEffect(
    /**
     * The value of isModeTransparency is decided by the data associated with
     * the primary variable, so it must be set after we fetch some data. Otherwise,
     * would've done it in the store synchronously.
     */
    function initializeTransparencyModeStartingValue() {
      if (!allowModeTransparency) return;

      const handle = () => {
        const shouldSetItToABool =
          jot.get(Atoms.isModeTransparencyAtom) === undefined;

        if (!shouldSetItToABool) return;
        if (!modes) return;

        const primaryTrendLine = jot.get(Atoms.primaryBatchVariableAtom);
        if (!isVariableVariant(primaryTrendLine)) return;
        const variableId = primaryTrendLine.bv.slice(24);
        const thisVariableHasAtLeastOneMode = modes.some((m) =>
          m.bindingVariableIdsSet.includes(variableId)
        );
        jot.set(Atoms.isModeTransparencyAtom, thisVariableHasAtLeastOneMode);
      };

      // immediately try to set the value
      handle();

      /**
       * set up a listener for when isModeTransparency becomes
       * undefined again (perhaps through the reset action)
       * */
      const cleanup = jot.sub(Atoms.isModeTransparencyAtom, handle);
      return cleanup;
    },
    [jot, modes, allowModeTransparency]
  );

  return (
    <ChartRenderOptionsContext.Provider
      value={useMemo(
        () => ({
          hideCommentPills:
            hideCommentPills ?? defaultRenderOptions.hideCommentPills,
          noPortalMultiSelects:
            noPortalMultiSelects ?? defaultRenderOptions.noPortalMultiSelects,
        }),
        [noPortalMultiSelects, hideCommentPills]
      )}
    >
      <div>
        <TooltipProvider delayDuration={50}>
          <SVV.InViewDetectContainer
            loading={loading}
            className={cn(
              "rounded-md border border-xslate-6 bg-white dark:bg-xslate-1 @container",
              props.className
            )}
          >
            <div className="hidden @5xl:inline-flex">
              <VariableCardsSidebar />
            </div>
            <SVV.ChartAreaContainer>
              <VariableSelectOverlay variables={variables} />
              <Header onClose={onClose} expandable={expandable} />
              <InternalVariabilityView
                onClick={onVariabilityClick}
                height={props.height}
                width={props.width}
                axesFontScale={props.axesFontScale}
                ableToGetTaller={props.ableToGetTaller}
              />
              <TimeseriesChart
                stages={undefined}
                theme="light"
                timezone={timezone}
                app="DRA"
                /**
                 * If this prop was passed in, use this value, else use the internal state
                 * value.
                 */
                noDaBars={noDaBars}
                hideCommentPills={hideCommentPills}
                {...props}
              />
              <BottomControls />
              <BottomCommentArea />
              <BottomNotificationsArea />
            </SVV.ChartAreaContainer>
            <FullscreenCommentsNotificationsSidebar />
          </SVV.InViewDetectContainer>
          <OperatingLimitsStatusSeries
            closedAndDisabled={!!closeAndDisableLimitStatusSeries}
          />
        </TooltipProvider>
      </div>
    </ChartRenderOptionsContext.Provider>
  );
}

const OperatingLimitsStatusSeries = memo(function ({
  closedAndDisabled,
}: {
  closedAndDisabled: boolean;
}) {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const primaryIsVariableVariant = isVariableVariant(primaryTrendLine);
  const isOperatingLimitsVariant = useAtomValue(Atoms.showLimitLinesAtom);
  const thisChartIsFullscreen = SVV.useIsRenderedAsFullscreen();
  const jotaiStore = useStore();

  const doesSomethingDifferentIfNotFullscreen = useAtomValue(
    Atoms.onlyShowCommentsInFullscreenAtom // rename this since it has diff puposes now
  );

  const toggleFullscreen = useToggleFullscreen();

  if (!primaryIsVariableVariant) return;
  if (!isOperatingLimitsVariant) return;

  const shouldOpenFullscreenWhenClicked =
    !thisChartIsFullscreen && doesSomethingDifferentIfNotFullscreen;

  return (
    <LimitsForChart
      closedAndDisabled={closedAndDisabled}
      variable={primaryTrendLine.bv.slice(24)}
      onlyOneOpenAtATime={thisChartIsFullscreen}
      overrideOnClick={
        shouldOpenFullscreenWhenClicked
          ? () => toggleFullscreen?.(jotaiStore)
          : undefined
      }
    />
  );
});

function InternalVariabilityView(props: {
  height: number;
  width: number;
  axesFontScale?: number;
  ableToGetTaller?: boolean;
  onClick: (() => void) | undefined;
}) {
  const yAxisMode = useAtomValue(Atoms.yAxisModeAtom);
  const selectedVariables = useAtomValue(Atoms.onlyVariableTrendLinesAtom);
  const [start, end] = useTzAwareDateStateDomain();

  if (yAxisMode !== YAxisMode.Variability) return null;

  return (
    <VariabilityDrawerStoreProvider
      init={{
        isForOFPage: false,
        initiallyOpen: iife(() => {
          const initialVariables = selectedVariables.map((x) => {
            return { color: x.color, _id: x.bv.slice(24) };
          });

          if (!minLen1(initialVariables)) throw new Error("impossible");

          return {
            variables: initialVariables,
            defaultStart: start,
            defaultEnd: end,
          };
        }),
      }}
    >
      <InternalVariabilityViewNeedProvider {...props} />
    </VariabilityDrawerStoreProvider>
  );
}

function InternalVariabilityViewNeedProvider({
  height,
  width,
  axesFontScale,
  ableToGetTaller,
  onClick,
}: {
  height: number;
  width: number;
  axesFontScale?: number;
  ableToGetTaller?: boolean;
  onClick: (() => void) | undefined;
}) {
  const expanded = useAtomValue(Atoms.expandedAtom);
  const selectedVariables = useAtomValue(Atoms.onlyVariableTrendLinesAtom);
  const heightFactor = useAtomValue(heightFactorAtom);

  const useVariabilityStore = useGetUseVariabilityDrawerStore();
  const jotaiStore = useStore();

  useEffect(() => {
    const cleanup1 = jotaiStore.sub(Atoms.getDomainAtom, () => {
      const domain = jotaiStore.get(Atoms.getDomainAtom);
      const currGroups = useVariabilityStore.getState().groups;

      if (!currGroups) return;

      const updatedGroups = currGroups.map((group) => {
        return {
          ...group,
          start: domain[0],
          end: domain[1],
        };
      });

      useVariabilityStore.setState({ groups: assertMinLen1(updatedGroups) });
    });

    const cleanup2 = jotaiStore.sub(Atoms.onlyVariableTrendLinesAtom, () => {
      const variableTrendLines = jotaiStore.get(
        Atoms.onlyVariableTrendLinesAtom
      );
      const currGroups = useVariabilityStore.getState().groups;

      if (!currGroups) return;

      const groupsToRemove = currGroups.filter((x) => {
        return !variableTrendLines.some((y) =>
          x.variables.map((z) => z._id).includes(y.bv.slice(24))
        );
      });

      for (const x of groupsToRemove) {
        useVariabilityStore.getState().removeGroup(x.id);
      }

      const groupsToAdd = variableTrendLines.filter((x) => {
        return !currGroups.some((y) =>
          y.variables.map((z) => z._id).includes(x.bv.slice(24))
        );
      });

      useVariabilityStore.getState().addVariables(
        groupsToAdd.map((x) => x.bv.slice(24)),
        jotaiStore.get(Atoms.getDomainAtom)
      );
    });

    return () => {
      cleanup1();
      cleanup2();
    };
  }, [jotaiStore, useVariabilityStore]);

  const taller = ableToGetTaller
    ? expanded || selectedVariables.length > 1
    : false;

  const heightToUse = taller
    ? (height / heightFactor / 2) * TALLER_CHART_SCALE
    : height / heightFactor / 2;

  const aspect = {
    height: heightToUse,
    width: width,
  };

  return (
    <>
      <VariabilityTooltip />
      <VariabilityChart
        onClick={onClick}
        aspect={aspect}
        className=""
        axesFontScale={axesFontScale}
        initialDims={initialContainerDims}
      />
      <WhiskerBoxPlot
        className=""
        onClick={onClick}
        aspect={aspect}
        axesFontScale={axesFontScale}
        initialDims={initialContainerDims}
      />
    </>
  );
}

export { SecondaryVariableViewNeedsProvider };
