import { useState } from "react";
import UpdatedAt from "../common/manager/UpdatedAt";
import CommentCreateOrEditForm from "./CommentCreateOrEditForm";
import iconMapper from "../../lib/iconMapper";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { HiReply } from "react-icons/hi";
import CommentReplyCard from "./CommentReplyCard";
import { Badge, Tooltip } from "@mantine/core";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import moment from "moment";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import { Link } from "react-router-dom";
import _ from "lodash";
import Button from "../common/Button/Button";
import CommentBadgeList from "./CommentBadgeList";
import { cn, iife } from "../../lib/utils";
import { RANGES } from "../../constants/dateState";
import { DATESTATE_SP_KEYS } from "../../zustand/useDateState";
import { LightweightDTCForCommentCard } from "./lightweight-dtc";
import { Badge as RadixBadge } from "../../shared-ui/frontend/badge";

// CommentCard renders itself as well so the logic is a little weird
export default function CommentCard({
  comment,
  editData,
  updateState,
  insertNewReply,
  deleteComment,
}) {
  const USER_ID = useAuthStore((s) => s.user._id);
  const editable = USER_ID === comment.author._id;
  const isPrivateComment = comment.private;
  /**
   * It seems like regular comments have type = "Comment" or 0
   *
   * And Issues have comment type = "Issue" or 1
   */
  const isIssue = comment.type === "Issue" || comment.type === 1;
  const isResolved = comment.issue_resolved;

  const firstName = comment.author.first;
  const lastName = comment.author.last;

  const [showReplies, setShowReplies] = useState(!!comment.replies.length);
  const [editMode, setEditMode] = useState(false);

  const [createReplyMode, setCreateReplyMode] = useState(false);

  const currentUnit = useCurrentUnitObject();

  const urlLink = (() => {
    const context = comment.context;

    const diffMs =
      new Date(comment.end_date).valueOf() -
      new Date(comment.start_date).valueOf();

    const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

    const getAxisRangeIndexAndCustomDays = () => {
      const idx = RANGES.findIndex((r) => r.days >= diffDays);

      if (idx === -1)
        return {
          [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: RANGES.length, // enter custom mode
          [DATESTATE_SP_KEYS.CUSTOM_NUM_DAYS]:
            diffDays + Math.ceil(diffDays * 0.1), // add some extra days so the range isn't exactly the same
        };

      return {
        [DATESTATE_SP_KEYS.AXIS_RANGE_INDEX]: idx,
      };
    };

    const subtraction = currentUnit.addons.realTime ? 0 : 1;
    let maxDate = moment()
      .subtract(subtraction, "days")
      .endOf("day")
      .format("YYYY-MM-DD");
    /* The server sends back local dates as if they're in UTC. We have to
     * account for that. But it also means we need local dates to generated the
     * same way, so they don't break when we account for the server. Sorry, I
     * know this is confusing. We need 11:59 today, and then to act like it's
     * 11:59 UTC. So to do that, we get the Y/M/D and then pass that into UTC. */
    maxDate = moment.utc(maxDate).endOf("day").valueOf();
    const boundedEndDate = Math.min(
      maxDate,
      new Date(comment.end_date).valueOf()
    );
    const activeIssue = isIssue && !isResolved;

    // if active, make the end date today (minus real time of course)
    const endDate = activeIssue ? new Date(maxDate) : new Date(boundedEndDate);

    const endDay = moment.utc(endDate).format("DD");
    const endMonth = moment.utc(endDate).format("MM");
    const endYear = moment.utc(endDate).format("YYYY");

    const axisRangeIndexAndCustomDaysQuery = getAxisRangeIndexAndCustomDays();

    const endDateQuery = {
      mo: endMonth,
      d: endDay,
      y: endYear,
    };

    const urlData = ((ctxType) => {
      switch (ctxType) {
        case "FaultTreeNode":
          return {
            to: "../ft/details/" + context.treeId,
            query: {
              selectednode: context.nodeId,
              selectedCommentId: comment._id,
            },
          };

        case "OperatingLimit":
          return {
            to: "../of/details/" + context.groupId,
            query: {
              shortIds: context.shortId,
              selectedCommentId: comment._id,
              ...endDateQuery,
              ...axisRangeIndexAndCustomDaysQuery,
            },
          };

        default:
          // Profile book

          const getAllAssociatedVariableShortIdsAsCsv = () => {
            const out = [];

            const parentShortId = context
              ? context.shortId
              : comment.variables[0].short_id;
            // so that the parent comes
            out.push(parentShortId);

            function addIfNotPresent(v) {
              if (!out.includes(v.short_id)) {
                out.push(v.short_id);
              }
            }

            _.forEach(comment.variables, addIfNotPresent);

            _.forEach(comment.replies, (r) => {
              _.forEach(r.variables, addIfNotPresent);
            });

            return out.join("-"); // "-" means they'll all be in the same chart
          };

          return {
            to: "../pb",
            query: {
              ...endDateQuery,
              selectedCommentId: comment._id,
              dv: getAllAssociatedVariableShortIdsAsCsv(),
              ...axisRangeIndexAndCustomDaysQuery,
            },
          };
      }
    })(context?.type);

    return (
      urlData.to + "?" + _.map(urlData.query, (v, k) => `${k}=${v}`).join("&")
    );
  })();

  const formateDate = (d) => moment.utc(d).format("lll");
  const { end_date, start_date } = comment;
  const dateString =
    comment.type === "Issue" && !comment.issue_resolved
      ? `${formateDate(start_date)} - Ongoing`
      : end_date && start_date
        ? `${formateDate(start_date)} - ${formateDate(end_date)}`
        : null;

  const showRed = isIssue && !isResolved;

  return (
    <li>
      <div
        className={cn(
          "flex p-5 gap-3 rounded-md overflow-visible w-full border-zinc-300 mb-2 border relative hover:border-xindigo-8 group",
          showRed ? "bg-xred-2" : isPrivateComment ? "bg-xamber-2" : "bg-white"
        )}
      >
        {urlLink ? (
          <Link
            to={urlLink}
            className="btn btn-ghost absolute right-0 bottom-0 text-[1.5rem] text-zinc-400 group-hover:text-indigo-800"
          >
            <FaRegArrowAltCircleRight />
          </Link>
        ) : null}
        {/* body of card  */}
        <div className="overflow-visible">
          {/* in body we have 2 columns, one for the commment and its replies,  */}
          {/* one for the extra metadata  */}
          <div className="grid grid-cols-12">
            <div className="col-span-4">
              <div className="mr-10">
                <div className="rounded-md border border-xslate-4 p-2">
                  <LightweightDTCForCommentCard comment={comment} />
                </div>

                <div className="mono mt-2 lowercase text-[1rem]">
                  {comment.variables[0].name}
                </div>
                <div className="text-gray-500 text-[0.8rem] mt-2">
                  {dateString}
                </div>
              </div>
              <div className="mt-4 flex gap-2">
                {isIssue ? (
                  <Badge color={isResolved ? "gray" : "red"} variant="outline">
                    <i
                      className={iconMapper("fa fa-info-circle")}
                      aria-hidden="true"
                    />
                    {` ${isResolved ? "Closed" : "Open"} Issue`}
                  </Badge>
                ) : isPrivateComment ? (
                  <RadixBadge variant={"amber"}>My Note</RadixBadge>
                ) : null}
                {iife(() => {
                  if (!comment.context) return null;

                  switch (comment.context.type) {
                    case "FaultTreeNode":
                      return (
                        <RadixBadge variant={"violet"}>Fault Trees</RadixBadge>
                      );
                    case "OperatingLimit":
                      return (
                        <RadixBadge variant={"blue"}>
                          Operating Fitness
                        </RadixBadge>
                      );
                    default:
                      const isSt =
                        comment.context.chartContext.showSlopingTrends;

                      if (isSt)
                        return (
                          <RadixBadge variant={"success"}>
                            Sloping Trends
                          </RadixBadge>
                        );

                      return null;
                  }
                })}
              </div>
            </div>
            <div className="col-span-8 gap-3">
              <div
                className={cn(
                  "flex transition-colors p-3 rounded-md",
                  showRed
                    ? "hover:bg-xred-3"
                    : cn(
                        isPrivateComment
                          ? "hover:bg-xamber-2"
                          : "hover:bg-xslate-2"
                      )
                )}
              >
                <div className="w-3/4 flex flex-col">
                  <div className="flex gap-2">
                    <div className="avatar placeholder">
                      <div
                        className={`bg-zinc-300 text-base-100 rounded-full w-7 h-7`}
                      >
                        <span className="text-[0.7rem]">
                          {firstName[0].toUpperCase() +
                            lastName[0].toUpperCase()}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h2 style={{ marginBottom: 0 }} className="card-title">
                        {firstName} {lastName}
                      </h2>
                      <div className="italic text-[0.7rem] text-textgrey">
                        <UpdatedAt
                          noContainer={true}
                          timestamp={comment.edited_at}
                        />
                      </div>
                    </div>
                    <div className="ml-auto">
                      {editable ? (
                        <div>
                          <Tooltip label="Delete" withArrow>
                            <Button
                              className="btn-ghost"
                              icon="trash-o"
                              onClick={() => deleteComment(comment)}
                            />
                          </Tooltip>
                          {editMode ? (
                            <Tooltip label="Cancel" withArrow>
                              <Button
                                className="btn-ghost"
                                icon="window-close"
                                onClick={() => setEditMode(false)}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip label="Edit" withArrow>
                              <Button
                                className="btn-ghost"
                                icon="pencil"
                                onClick={() => {
                                  setEditMode(true);
                                  setShowReplies(false);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {editMode ? (
                    <div className="my-6 px-12">
                      <CommentCreateOrEditForm
                        closeForm={() => {
                          setEditMode(false);
                          setShowReplies(true);
                        }}
                        comment={comment}
                        {...editData}
                        updateState={(payload) => updateState(comment, payload)}
                      />
                    </div>
                  ) : (
                    <span className="text-[1rem] block my-4 px-12">
                      {comment.text}
                    </span>
                  )}
                </div>
                <CommentBadgeList comment={comment} variant="side" />
              </div>

              <div className="my-2">
                {showReplies &&
                  comment.replies.map((r) => (
                    <CommentReplyCard
                      editData={editData}
                      comment={r}
                      key={r._id}
                      updateState={updateState}
                      deleteComment={deleteComment}
                    />
                  ))}
                {!isPrivateComment &&
                  (!createReplyMode ? (
                    <div className="flex gap-2 mt-4">
                      <Button
                        icon={HiReply}
                        iconClasses="mr-1"
                        size="xs"
                        className="btn-outline normal-case"
                        onClick={() => {
                          setCreateReplyMode((b) => !b);
                          setShowReplies(true);
                        }}
                      >
                        Reply
                      </Button>
                    </div>
                  ) : (
                    <div className="border-l-2 border-zinc-300 w-3/4 px-12 ml-4">
                      <CommentCreateOrEditForm
                        closeForm={() => setCreateReplyMode(false)}
                        {...editData}
                        updateState={(payload, toggleIssueResolution) =>
                          insertNewReply(
                            comment,
                            payload,
                            toggleIssueResolution
                          )
                        }
                        parentData={{
                          variableId: comment.variables[0]._id,
                          _id: comment._id,
                          isIssue,
                          isResolved,
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
