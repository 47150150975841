import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { create } from "zustand";

type DataStates<T> =
  | {
      data: T[];
      slice: T[];
    }
  | { data: undefined; slice: undefined };

type PaginationStore<Data> = DataStates<Data> & {
  page: number;
  hasNext: boolean;
  hasPrev: boolean;
  next: () => void;
  prev: () => void;
  reset: () => void;
  onDataChange: (newData: Data[]) => void;

  replaceData: (toReplace: Data, replaceWith: Data) => void;
  deleteData: (toDelete: Data) => void;
  pageSize: number;
};

function createUsePaginationStore<Data>(
  initialData: Data[] | undefined,
  pageSize: number
) {
  const getStateForPageNum = (allData: Data[], pageNum: number) => {
    return {
      page: pageNum,
      data: allData,
      slice: allData.slice(pageNum * pageSize, (pageNum + 1) * pageSize),
      hasNext: allData.length > (pageNum + 1) * pageSize,
      hasPrev: pageNum > 0,
    } as const satisfies Pick<
      PaginationStore<Data>,
      "slice" | "page" | "hasNext" | "hasPrev" | "data"
    >;
  };

  function getStateFromNewData(newData: Data[]) {
    const partialState = getStateForPageNum(newData, 0);

    const out = {
      ...partialState,
      data: newData,
    } as const satisfies Pick<PaginationStore<Data>, "data"> &
      typeof partialState;

    return out;
  }

  return create<PaginationStore<Data>>((set, get) => {
    const initialPartial = initialData
      ? getStateFromNewData(initialData)
      : {
          data: undefined,
          page: -1,
          slice: undefined,
          hasNext: false,
          hasPrev: false,
        };

    return {
      ...initialPartial,
      pageSize,
      replaceData: (toReplace, replaceWith) => {
        const { data, slice } = get();
        if (!data) throw new Error("data is undefined");

        const idx = data.findIndex((d) => d === toReplace);
        if (idx === -1) throw new Error("data not found");

        const newData = [...data];
        newData[idx] = replaceWith;

        const idx2 = slice.findIndex((d) => d === toReplace);
        const newSlice = [...slice];
        newSlice[idx2] = replaceWith;

        set({
          data: newData,
          slice: newSlice,
        });
      },
      deleteData: (toDelete) => {
        const { data, page } = get();
        if (!data) throw new Error("data is undefined");
        const newData = data.filter((d) => d !== toDelete);
        const maxPage = Math.floor(newData.length / pageSize) - 1;

        set(getStateForPageNum(newData, Math.min(page, maxPage)));
      },
      onDataChange: (d) => set(getStateFromNewData(d)),
      next: () => {
        const { hasNext } = get();
        if (!hasNext) return;
        set(({ data, page }) => {
          if (!data) throw new Error("data is undefined");
          return getStateForPageNum(data, page + 1);
        });
      },
      prev: () => {
        const { hasPrev } = get();
        if (!hasPrev) return;
        set(({ data, page }) => {
          if (!data) throw new Error("data is undefined");
          return getStateForPageNum(data, page - 1);
        });
      },
      reset: () =>
        set(({ data }) => {
          if (!data) throw new Error("data is undefined");
          return getStateForPageNum(data, 0);
        }),
    };
  });
}

type UsePaginationStore<T> = ReturnType<typeof createUsePaginationStore<T>>;

const PaginationStoreContext = createContext<
  UsePaginationStore<unknown> | undefined
>(undefined);

function useGetUsePaginationStore<T>() {
  const useStore = useContext(PaginationStoreContext);
  if (useStore === undefined) {
    throw new Error(
      "useGetUsePaginationStore must be used within a PaginationStoreProvider"
    );
  }
  return useStore as UsePaginationStore<T>;
}

function PaginationProvider<T>({
  children,
  data,
  pageSize,
}: PropsWithChildren<{ data: T[] | undefined; pageSize: number }>) {
  const mounted = useRef(false);
  const [s] = useState(() => createUsePaginationStore<T>(data, pageSize));

  useEffect(() => {
    if (!data) return;
    mounted.current && s.getState().onDataChange(data);
  }, [data, s]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    // as any is a necessary hack to get the generic type to work, this is fine
    // because the consumer will still have type safety. We cast it so they don't
    // have to.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <PaginationStoreContext.Provider value={s as any}>
      {children}
    </PaginationStoreContext.Provider>
  );
}

function usePagination<T>() {
  const useStore = useGetUsePaginationStore<T>();
  return useStore();
}

export { usePagination, PaginationProvider };
