export enum TreeTemplateEnum {
  Small,
  Medium,
  Large,
}

export const TreeTemplateNumbers = Object.values(TreeTemplateEnum).filter(
  (v) => typeof v === "number"
) as TreeTemplateEnum[];
TreeTemplateNumbers.sort((a, b) => a - b); // sort ascending

type TemplateTree = {
  name: string;
  children?: TemplateTree[];
};

export default function getFaultTreeTemplate(
  templateNum?: TreeTemplateEnum
): TemplateTree {
  if (templateNum === 0) {
    return {
      name: "root",
      children: [
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
      ],
    };
  }

  if (templateNum === 1) {
    return {
      name: "root",
      children: [
        {
          name: "1",
          children: [{ name: "1.1" }, { name: "1.2" }, { name: "1.3" }],
        },
        {
          name: "2",
          children: [{ name: "2.1" }, { name: "2.2" }, { name: "2.3" }],
        },
        {
          name: "3",
          children: [{ name: "3.1" }, { name: "3.2" }, { name: "3.3" }],
        },
        {
          name: "4",
          children: [{ name: "4.1" }, { name: "4.2" }, { name: "4.3" }],
        },
        {
          name: "5",
          children: [{ name: "5.1" }, { name: "5.2" }, { name: "5.3" }],
        },
      ],
    };
  }

  if (templateNum === 2) {
    return {
      name: "root",
      children: [
        {
          name: "1",
          children: [
            { name: "1.1", children: [{ name: "1.1.1" }, { name: "1.1.2" }] },
            { name: "1.2", children: [{ name: "1.2.1" }, { name: "1.2.2" }] },
            { name: "1.3", children: [{ name: "1.3.1" }, { name: "1.3.2" }] },
          ],
        },
        {
          name: "2",
          children: [
            { name: "2.1", children: [{ name: "2.1.1" }, { name: "2.1.2" }] },
            { name: "2.2", children: [{ name: "2.2.1" }, { name: "2.2.2" }] },
            { name: "2.3", children: [{ name: "2.3.1" }, { name: "2.3.2" }] },
          ],
        },
        {
          name: "3",
          children: [
            { name: "3.1", children: [{ name: "3.1.1" }, { name: "3.1.2" }] },
            { name: "3.2", children: [{ name: "3.2.1" }, { name: "3.2.2" }] },
            { name: "3.3", children: [{ name: "3.3.1" }, { name: "3.3.2" }] },
          ],
        },
        {
          name: "4",
          children: [
            { name: "4.1", children: [{ name: "4.1.1" }, { name: "4.1.2" }] },
            { name: "4.2", children: [{ name: "4.2.1" }, { name: "4.2.2" }] },
            { name: "4.3", children: [{ name: "4.3.1" }, { name: "4.3.2" }] },
          ],
        },
        {
          name: "5",
          children: [
            { name: "5.1", children: [{ name: "5.1.1" }, { name: "5.1.2" }] },
            { name: "5.2", children: [{ name: "5.2.1" }, { name: "5.2.2" }] },
            { name: "5.3", children: [{ name: "5.3.1" }, { name: "5.3.2" }] },
          ],
        },
      ],
    };
  }
  return {
    name: "root",
    children: [],
  };
}
