import _ from "lodash";

export function swapTwoItemsInArray<T>(
  idx1: number,
  idx2: number,
  arr: T[],
  returnCopy?: boolean
): T[] | void {
  if (idx1 > arr.length - 1 || idx2 > arr.length - 1) {
    throw new Error("out of bounds");
  }

  if (returnCopy) {
    const copy = arr.slice();
    const first = copy[idx1];
    const second = copy[idx2];

    if (!second || !first) throw new Error();
    copy[idx1] = second;
    copy[idx2] = first;
    return copy;
  } else {
    const first = arr[idx1];
    const second = arr[idx2];
    if (!second || !first) throw new Error();
    arr[idx1] = second;
    arr[idx2] = first;
  }
}

export function removeFromArrayByIndex<T>(arr: T[], index: number): T[] {
  if (index > arr.length - 1) {
    throw new Error("out of bounds");
  }

  return _.remove(arr, (_, idx) => idx === index);
}
