/*
 * A wrapper around binary flags to make them more convenient
 * to work with and grok for people who are unfamiliar with binary.
 *
 * Even if you are using raw binary operators yourself, you still
 * might want to be able to group flags.
 */
function flags(scheme) {
  const flags = {};
  let index = 1;

  function parse(scheme) {
    /* Create new flag, ignoring duplicates */
    if (typeof scheme === "string") {
      if (!flags[scheme]) {
        flags[scheme] = index;
        index = index * 2;
      }

      return flags[scheme];
    }

    /* Arrays contain multiple tags */
    if (scheme instanceof Array) {
      return scheme.reduce((index, scheme) => index + parse(scheme), 0);
    }

    //Groups
    if (typeof scheme === "object" && scheme != null) {
      return Object.keys(scheme).reduce((result, group) => {
        //Add any children to the map.
        let indices = parse(scheme[group]);

        /* This group is the combination of all of them. Note that index is not
         * incremented, since groups don't take up their own slot. Unlike tags,
         * groups *cannot* be used multiple times. It's an interesting idea, but
         * I'm not convinced it would be particularly valuable. And it opens the
         * door to potentially confusing behavior later. For example, adding a
         * group to another group, or circular dependencies, ala crud like
         * {'foo':'foo'} */
        if (flags[group]) {
          throw new Error("Groups can not be re-used: flags.js");
        }
        flags[group] = indices;

        return result + indices;
      }, 0);
    }

    return 0; //Unparsable, do nothing.
  }

  parse(scheme);
  return flags;
}

// Static methods
Object.assign(flags, {
  set: function (state, flag, assign) {
    if (assign != null) {
      state = assign ? state | flag : state & ~flag;

      return state;
    }

    return state & flag;
  },

  any: function (state, flags) {
    return state | flags;
  },
});

export default flags;
