export function boldAndItalicizeHTMLify(str: string) {
  return str
    .replaceAll(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    .replaceAll(/\$\$(.*?)\$\$/g, "<em>$1</em>")
    .replaceAll(
      /%%(.*?)%%/g,
      '<code style="color: #0071bc; font-family: monospace, monospace"className="code-expression">$1</code>'
    );
}

export function generateStyleObjectFromMarginOptions(
  marginTop?: string,
  marginBottom?: string
) {
  return {
    marginTop: `${marginTop ?? 0}rem`,
    marginBottom: `${marginBottom ?? 0}rem`,
  };
}
