import { type BaseUrl, useBaseUrlExperimental } from "../../zustand/useBaseUrl";
import { useQuery, useQueries, UseQueryOptions } from "@tanstack/react-query";
import {
  getCapabilityResults,
  getRidgelineData,
} from "../../frameworks/fetcher/api-routes-experimental";
import { VariabilityGroup } from "./types";

/* Generic query builder used internally */
function buildVariabilityDataQueryOptions(
  base: BaseUrl,
  id: string,
  start: Date | number,
  end: Date | number,
  modes?: Record<string, true>,
  ref?: string
) {
  return {
    queryKey: [
      base,
      "variabilityData",
      id,
      start.valueOf(),
      end.valueOf(),
      modes, // should be an object because in arrays, ordering matters
      ref,
    ],
    queryFn: async () => {
      const data = await getRidgelineData(
        base,
        id,
        start,
        end,
        modes && Object.keys(modes),
        ref
      );

      return {
        ...data,
        variableId: id, // I probably need this for the chart hovering stuff
      };
    },
    staleTime: Infinity,
    keepPreviousData: true,
  } as const satisfies UseQueryOptions;
}

/* Used to fetch information for the primary variable */
export function useVariabilityDataQuery(
  id: string,
  start: Date,
  end: Date,
  modes?: Record<string, true>
) {
  const url = useBaseUrlExperimental();
  const queryOption = buildVariabilityDataQueryOptions(
    url,
    id,
    start,
    end,
    modes
  );
  return useQuery(queryOption);
}

/* Used to fetch collective chart infomration for the entire bucket */
export function useVariabilityDataQueries(buckets: VariabilityGroup[]) {
  const url = useBaseUrlExperimental();
  const queryOptions = buckets.flatMap(({ start, end, variables }) =>
    variables.map(({ _id, excludedModesMap: modeMap, id }) =>
      buildVariabilityDataQueryOptions(
        url,
        _id,
        start,
        end,
        Object.fromEntries(
          Object.entries(modeMap).filter(([_, bool]) => !!bool) as [
            string,
            true,
          ][]
        ),
        id
      )
    )
  );

  return useQueries({ queries: queryOptions });
}

/* Used to fetch collective chart infomration for the entire bucket */
export function useCapabilityQuery(
  variableId: string,
  start: number,
  end: number,
  excludedModesMap: Record<string, boolean>
) {
  const url = useBaseUrlExperimental();

  const modes = Object.entries(excludedModesMap)
    .filter(([_, value]) => value)
    .map((mode) => mode[0]);

  const queryOptions = {
    queryKey: [
      url,
      "variabilityCapability",
      variableId,
      start.valueOf(),
      end.valueOf(),
      modes,
    ],
    queryFn: async () => {
      return await getCapabilityResults(url, variableId, start, end, modes);
    },
  } as const satisfies UseQueryOptions;

  return useQuery(queryOptions);
}
