import { Loader } from "@mantine/core";
import classNames from "classnames";

function PetalChartSkeleton() {
  return (
    <div className="2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 bg-white rounded-md min-h-[28vh] flex justify-center items-center relative">
      <div className="absolute w-full h-full flex justify-center items-center top-0 left-0">
        <Loader color="dark" size="xs"/>
      </div>
      <div
        className={classNames(
          "bg-gray-300 animate-pulse",
          `w-3/4`,
          `aspect-[2/1]`,
          "rounded-t-[40rem] rounded-b-[1rem]"
        )}
      />
    </div>
  );
}

export default PetalChartSkeleton;
