import React, { useState, useEffect, useRef } from "react";

export default function useResizeObserver(eltRef, handleResize) {
  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      handleResize(entries[0].target);
    })
  );

  useEffect(() => {
    if (eltRef.current) {
      observer.current.observe(eltRef.current);
      return () => {
        observer.current.unobserve(eltRef.current);
      };
    }
  }, [eltRef.current, observer]);

  return observer;
}
