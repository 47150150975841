import { useState, useMemo, useEffect } from "react";
import CommentCard from "./CommentCard";
import _ from "lodash";
import { useModal } from "../common/useModal";
import First from "../common/First";
import moment from "moment";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import useInitializeCommentsPage from "./hooks/useInitializeCommentsPage";
import classNames from "classnames";
import { Loader, LoadingOverlay, Tooltip } from "@mantine/core";
import CommentFilterSelect from "./CommentFilterSelect";
import CommentViewTypeSelect from "./CommentViewTypeSelect";
import usePaginatedComments from "./hooks/usePaginatedComments";
import { produce } from "immer";
import useIntersectionObserver from "../common/hooks/useIntersectionObserver";
import MainLayout from "../layouts/MainLayout";
import ErrorScreen from "../common/error-screen";
import Button from "../common/Button/Button";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../toast/use-toast-store";
import { FullscreenProvider } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import { FullscreenChartForCommentsPage } from "./fullscreen-chart-for-comments-page";
import { CorrelatedTagsDrawerStoreProvider } from "../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { CorrelatedTagsDrawer } from "../clusters/cluster-drawer/correlated-tags-drawer";
import { ClusterDrawerStoreProvider } from "../clusters/cluster-drawer/use-cluster-drawer-store";
import { ClusterDrawer } from "../clusters/cluster-drawer/cluster-drawer";

const viewOptions = [
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Yearly",
    value: "Yearly",
  },
];

const TABS = [
  { label: "comments", isPrivate: false },
  { label: "my notes", isPrivate: true },
];

function Comments() {
  const { openModal, closeModal } = useModal();
  // const [scroll, scrollTo] = useWindowScroll();

  useDocumentTitle("Comments > DRA");

  // what tab are we on?

  const { initialLoading, pageError, labels, users, teams, variables } =
    useInitializeCommentsPage(); // a big useEffect to fetch data, handle loading state

  const [currTabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState(viewOptions[0].value); // monthly

  const Paginator = usePaginatedComments();

  const deleteComment = (obj) => {
    const actuallyDelete = async () => {
      try {
        await Paginator.delete(obj);
        addSuccessToast("Comment deleted");
      } catch (e) {
        addUnknownErrorToast(e);
      }
    };

    openModal({
      body: {
        text: obj.text,
      },
      title: "Are you sure you want to delete your comment?",
      buttons: [
        {
          type: "button",
          label: "Delete",
          className: "btn-error",
          onClick: () => {
            // upon confirming
            actuallyDelete();
            closeModal();
          },
        },
      ],
      closeLabel: "CANCEL",
    });
  };

  /**
   * comments = [{month: "July", comments: [...commentObject]}, ...]
   *
   * Sorting is handled below. It is safe to render comments variable directly
   */
  const comments = useMemo(() => {
    const out = [];

    const filteredComments = Paginator.comments;
    // format/group the comments depending on monthly/yearly view
    if (view === "Monthly") {
      const groupedYearly = _.groupBy(filteredComments, (c) =>
        moment(c.updated_at).year()
      );

      const yearKeys = Object.keys(groupedYearly);
      yearKeys.sort((a, b) => parseInt(b) - parseInt(a)); // show the latest comments first

      for (const year of yearKeys) {
        // we are iterating from the latest year to the earliest year
        const groupedMonthly = _.groupBy(
          groupedYearly[year],
          (c) => moment(c.updated_at).month() + 1 // months are 0-indexed
        );

        const monthKeys = Object.keys(groupedMonthly);
        monthKeys.sort((a, b) => parseInt(b) - parseInt(a)); // latest comments first

        // console.log("monthkeys", monthKeys)
        for (const monthNumber of monthKeys) {
          out.push({
            label: moment(monthNumber, "M").format("MMMM"),
            comments: groupedMonthly[monthNumber],
          });
        }
      }
    }

    if (view === "Yearly") {
      const groupedYearly = _.groupBy(filteredComments, (c) =>
        moment(c.updated_at).year()
      );

      const yearKeys = Object.keys(groupedYearly);
      yearKeys.sort((a, b) => parseInt(b) - parseInt(a)); // show the latest comments first

      for (const year of yearKeys) {
        // year is now a string since it is a key of an object
        out.push({ label: year, comments: groupedYearly[year] });
      }
    }

    return out;
  }, [Paginator.comments, view]);

  const { enteredTheScreen, subscribe } = useIntersectionObserver();

  useEffect(() => {
    // console.log("trigger", enteredTheScreen);
    enteredTheScreen && Paginator.loadMore();
  }, [enteredTheScreen]);

  const searchComponent = (
    <div className="form-control">
      <label className="input-group input-group-sm">
        <span>Search</span>
        <input
          type="text"
          className="input input-bordered input-sm"
          onChange={(e) => {
            const text = e.target.value.trim();

            return Paginator.setQueryObjectDebounced(
              produce((s) => {
                if (!text) {
                  delete s.search;
                } else {
                  s.search = text;
                }
              })
            );
          }}
        />
      </label>
    </div>
  );

  // other things we can filter on besides Labels like "critical" "cost avoidance" etc...
  const otherFilterOptions = [
    {
      label: "All Comments",
      onFilter: produce((state) => {
        delete state["labelIds[]"];
        delete state["activeIssue"];
        delete state["inactiveIssue"];
      }),
    },
    {
      label: "Open Issues",
      onFilter: produce((state) => {
        state.activeIssue = true;
        delete state["labelIds[]"];
        delete state["inactiveIssue"];
      }),
    },
    {
      label: "Closed Issues",
      onFilter: produce((state) => {
        state.inactiveIssue = true;
        delete state["labelIds[]"];
        delete state["activeIssue"];
      }),
    },
  ].map(
    produce((s) => {
      s.value = s.label;
    })
  );

  const filterComponent = (
    <CommentFilterSelect
      onChange={Paginator.setQueryObject}
      filterOptions={_.concat(otherFilterOptions, labels)}
      initialLoading={initialLoading}
    />
  );

  const viewComponent = (
    <CommentViewTypeSelect
      initialLoading={initialLoading}
      value={view}
      onChange={setView}
      options={viewOptions}
    />
  );

  return (
    <MainLayout>
      {/* <Affix position={{ bottom: 5, right: 5 }}>
        {scroll.y > 0 && (
          <Button
            className="btn btn-primary btn-ghost hover:text-primary"
            onClick={() => scrollTo({ y: 0 })}
          >
            <FiArrowUpCircle className="text-[27px]" />
          </Button>
        )}
      </Affix> */}
      <First>
        <LoadingOverlay
          match={(initialLoading || Paginator.initialLoading) && !pageError}
          visible={true}
          overlayBlur={0}
        />
        <ErrorScreen visible={true} match={!!pageError} />
        <ClusterDrawerStoreProvider>
          <CorrelatedTagsDrawerStoreProvider>
            <TimeseriesChartTooltipStoreProvider>
              {() => (
                <>
                  <GlobalTooltip />
                  <ClusterDrawer />
                  <CorrelatedTagsDrawer />

                  <div className="w-full px-[5rem] pb-24">
                    <div className="flex items-end gap-2 mt-6">
                      <div className="flex gap-2 mr-auto">
                        <div>{searchComponent}</div>
                        <div>{filterComponent}</div>
                        <div>{viewComponent}</div>
                      </div>

                      {Paginator.commentsCount ? (
                        <Tooltip
                          position="bottom"
                          label="Click to download all comments"
                        >
                          {Paginator.downloadLoading ? (
                            <Loader color="dark" size={20} />
                          ) : (
                            <div
                              onClick={() =>
                                Paginator.downloadComments()
                                  .then(() =>
                                    addSuccessToast("Download complete", false)
                                  )
                                  .catch((e) => addUnknownErrorToast(e))
                              }
                              className="hover:underline cursor-pointer border-gray-500 text-[0.95rem] text-gray-500"
                            >{`${Paginator.commentsCount} comments`}</div>
                          )}
                        </Tooltip>
                      ) : null}
                      <div className="btn-group text-neutral">
                        {TABS.map(({ label: tabName, isPrivate }, idx) => {
                          const isActive = currTabIndex === idx;

                          return (
                            <Button
                              key={tabName}
                              onClick={() => {
                                if (currTabIndex !== idx) {
                                  setTabIndex(idx);
                                  Paginator.setQueryObject(
                                    produce((state) => {
                                      state.private = isPrivate;
                                    })
                                  );
                                }
                              }} // dont change anything if they click on the tab they're currently in
                              className={classNames({
                                "btn-neutral": isActive,
                                "btn-outline": !isActive,
                              })}
                            >
                              {tabName}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                    <FullscreenProvider>
                      {comments.length ? (
                        <ul className="list-none mt-8">
                          {comments.map((section, idx) => {
                            return (
                              <li
                                key={`${section.label}-${idx}`}
                                className="mb-12 last-of-type:mb-0"
                              >
                                <div className="items-baseline gap-2 border-b-[2px] border-zinc-500 inline pb-2 px-2">
                                  <span className="inline text-[1.9rem]">
                                    {section.label}{" "}
                                    {/* the number of comments and replies total  */}
                                  </span>
                                  {/* <span className="text-textgrey text-[0.8rem] inline">
                              {`${section.comments.length} comments${
                                isLastSection ? " shown" : ""
                              }`}
                            </span> */}
                                </div>
                                <ul className="mt-4">
                                  {section.comments.map((c, idx) => (
                                    <CommentCard
                                      key={c._id}
                                      updateState={Paginator.update}
                                      comment={c}
                                      editData={{
                                        labels,
                                        variables,
                                        users,
                                        teams,
                                      }}
                                      insertNewReply={Paginator.insertReply}
                                      deleteComment={deleteComment}
                                    />
                                  ))}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div className="flex flex-col justify-center h-full gap-3 mt-[30vh]">
                          <p className="text-[1.15rem] flex justify-center">
                            No comments found
                          </p>
                        </div>
                      )}
                      <FullscreenChartForCommentsPage />
                    </FullscreenProvider>
                    {Paginator.hasMore ? (
                      <div className="flex justify-center">
                        <Loader color="dark" size={25} />
                      </div>
                    ) : null}
                    {Paginator.loading ? null : <div ref={subscribe} />}
                  </div>
                </>
              )}
            </TimeseriesChartTooltipStoreProvider>
          </CorrelatedTagsDrawerStoreProvider>
        </ClusterDrawerStoreProvider>
      </First>
    </MainLayout>
  );
}

export default Comments;
