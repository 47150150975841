import { z } from "zod";

export const faultTreeNodeFileSchema = z.object({
  chunkSize: z.number().int(),
  contentType: z.string(),
  filename: z.string(),
  length: z.number().int(),
  metadata: z.object({
    user: z.string(),
    faultTreeNodeId: z.string(),
  }),
  uploadDate: z.string().datetime(),
  _id: z.string(),
});

export type faultTreeNodeFileSchema = z.infer<typeof faultTreeNodeFileSchema>;
