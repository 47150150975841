import React from "react";

/*
 * ``First`` component: for conditionally rendering one of a subset of options.
 *
 * At most one child of this component will be rendered. When instantiated,
 * children are checked in the order they are passed in to see if they match or
 * are contained in a data set.
 *
 * - The first child to match in the dataset is rendered, and the rest are
 *   discarded.
 *
 * - If no children are matched in the dataset, then nothing is rendered.
 *
 *
 * The API is is fairly permissive.
 *
 * ``props.data`` can be either an array or a single value. Each child can
 * contain a ``match`` property, which must be present in the data array for the
 * child to match. If ``match`` is null, then the child is assumed to always
 * match. This allows for default fallbacks.
 *
 * If ``props.data`` is not included, we assume that each ``match`` property will
 * contain a boolean indicating whether or not it should be displayed.
 *
 * Finally, data can be a function instead of an array. If it is a function,
 * it will be called for each matching (and non-null) value and the first
 * child to return true will be used.
 *
 * It is (rarely) beneficial to have first work in the opposite direction. For
 * example, when including a loading element in front of an overall page. In
 * that case, include the ``reverse`` property to make first work from the
 * bottom up rather than from the top down.
 */
function First(props) {
  const data = props.data;
  function show(match) {
    if (data == null) {
      return !!match;
    }

    if (typeof data === "function") {
      return !!data(match);
    }

    if (Array.isArray(data)) {
      return data.includes(match);
    }

    return data == match;
  }

  const children = props.reverse
    ? React.Children.toArray(props.children).reverse()
    : React.Children.toArray(props.children);

  const first = children.find(function (child) {
    if (child.props == null) {
      return false;
    } /* Explicitly reject whitespace or text elements as children */
    return child.props.match == null || show(child.props.match);
  });

  return <>{first}</> || null;
}

export default First;
