import { useNavigate } from "react-router-dom";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { useConfigRequired } from "../../zustand/config/useConfigStore";
import { Tooltip } from "@mantine/core";
import Button from "../common/Button/Button";

export function LogoutButton() {
  const config = useConfigRequired();
  const user = useAuthStore((s) => s.user);
  const nav = useNavigate();

  if (!user) return null;

  const { first, last } = user;
  const logoutTooltipLabel = `Logout ${first} ${last}`;

  return (
    <Tooltip
      classNames={{
        tooltip: "bg-error text-error-content",
        arrow: "bg-error",
      }}
      position="left"
      label={logoutTooltipLabel}
    >
      <Button
        className="btn-ghost w-[3.5rem]"
        onClick={() => {
          if (config.entra) {
            const logoutUrl = config.entra.logoutUrl;
            useAuthStore
              .getState()
              .removeToken()
              .then(() => {
                window.location.href = logoutUrl;
              });
            return;
          }

          void useAuthStore.getState().unAuthenticate();
          nav("/");
        }}
        icon="sign-out"
      />
    </Tooltip>
  );
}
