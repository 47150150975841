import _ from "lodash";

export default function (errors) {
  /* Errors must be an array. */
  errors = ((errors) => {
    /* Server responses can often come embedded in response objects */
    if (errors && errors.body && errors.body.errors) {
      return errors.body.errors;
    }
    return errors instanceof Array ? errors : [errors];
  })(errors);

  /* - If a string is valid JSON, compile it.
   * - If a string is not valid JSON, build it into a new object.
   * - If an error is an actual error object, attach its trace to meta. */
  errors = errors.map((error) => {
    if (typeof error === "string") {
      try {
        return JSON.parse(error);
      } catch (err) {
        return { msg: error };
      }
    }

    if (error instanceof Error) {
      return {
        meta: _.pick(error, ["message", "stack"]),
      };
    }

    return error;
  });

  /* General formatting */

  errors = errors.map((error) => {
    if (error?.meta?.message === "Failed to fetch") {
      console.log("Failed to fetch. Is the server on?");
    }
    return Object.assign(
      {
        msg: "An unknown error has occured.",
        type: "error",
        meta: {},
      },
      error
    );
  });

  return errors;
}
