import { PropsWithChildren, useEffect, useState } from "react";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import { useAriaQuery } from "../../../hooks/tanstack-query";
import { MinLen1 } from "../../../shared-ui/lib/types";
import { createStore, Provider, useAtomValue } from "jotai";
import {
  Atoms,
  ChartVariant,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  GenericSecondaryVariableViewNeedsProvider,
  type AvailableProps,
  useDebouncedEffectiveRangeForTimeseriesQuery,
} from "./dra-and-aria-secondary-variable.view";
import { cn } from "../../../lib/utils";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";

/**
 * Use this if you want to provide your own store provider. See
 * the component below for an example of how to provide your
 * own store
 *
 * If you don't want to provide your own store provider, use the
 * component below.
 */
function AriaSecondaryVariableViewNeedsProvider({
  className,
  ...props
}: Omit<
  AvailableProps,
  keyof Pick<
    AvailableProps,
    | "data"
    | "noDaBars"
    | "anomalyRectangles"
    | "anomalyPatchesThicknessScale"
    | "hackForDemo"
    | "hideCommentPills"
  >
>) {
  const [firstAtom] = useAtomValue(Atoms.selectedVariableAtomsAtom);
  if (!firstAtom) throw new Error("must be defined");

  const first = useAtomValue(firstAtom);

  if (first.type !== "variable")
    throw new Error("This is an expression chart. Bug");
  const cluster = first.bv.slice(0, 24);

  const variables = useAtomValue(
    Atoms.onlyVariableTrendLinesVariableIdsAtom
  ).sort() as MinLen1<string>;
  const [startRequest, endRequest] =
    useDebouncedEffectiveRangeForTimeseriesQuery();

  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);

  const ariaQuery = useAriaQuery(
    variables,
    cluster,
    new Date(startRequest).toISOString(),
    new Date(endRequest).toISOString(),
    canRequestData && variables.length > 0
  );

  return (
    <GenericSecondaryVariableViewNeedsProvider
      hackForDemo // remove later
      noDaBars
      data={ariaQuery.data}
      anomalyRectangles
      hideCommentPills // disabled on ARIA for now
      className={cn("border-0", className)}
      // anomalyPatchesThicknessScale={2}
      {...props}
    />
  );
}

function AriaSecondaryVariableViewProvider({
  initialVariables,
  clusterId,
  initialExpanded,
  children,
}: {
  initialExpanded: boolean;
  clusterId: string;
  initialVariables: [string, ...string[]];
} & PropsWithChildren) {
  const [start, end] = useTzAwareDateStateDomain(); // a derivate of datestate, will change when datestate does

  const [s] = useState(() => {
    const s = createStore();
    s.set(Atoms.setInitialStateAtom, {
      decideAnomBooleanForNewlyAddedVariableTrendLines: undefined,
      batchVariablesOrExpressions: assertMinLen1(
        initialVariables.map((v) => ({
          type: "variable" as const,
          bv: `${clusterId}${v}`,
        }))
      ),
      expanded: initialExpanded,
      variant: ChartVariant.Aria,
    });
    s.set(Atoms.setDomainAtom, [start, end]);

    /**
     * The default behavior is to show the sidebar open depending on the #
     * of batch variables provided above, and is baked into the store logic.
     *
     * This is a special case in ARIA: show the sidebar closed by default,
     * regardless of the # of batch variables provided.
     */
    s.set(s.get(Atoms.cardsSidebarOpenAtomAtom), false);

    return s;
  });

  return <Provider store={s}>{children}</Provider>;
}

export {
  AriaSecondaryVariableViewNeedsProvider,
  AriaSecondaryVariableViewProvider,
};
