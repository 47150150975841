import { LoadingOverlay } from "@mantine/core";
import { MdError } from "react-icons/md";

function ErrorScreen({
  visible,
  message,
}: {
  visible: boolean;
  message?: string;
}) {
  return (
    <LoadingOverlay
      visible={visible}
      overlayBlur={2}
      loader={
        <div className="flex w-full h-full justify-center items-center flex-col gap-4">
          <MdError className="text-[5rem] text-red-700" />
          <span className="text-[1.6rem] text-red-700">
            {message ?? "An error has ocurred. Please reload the page."}
          </span>
        </div>
      }
    />
  );
}

export default ErrorScreen;
