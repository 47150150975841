import { z } from "zod";
import { limitLevelSchema } from "../../../components/of/constants";

export const exceedanceCountSchema = z.object({
  date: z.string().datetime(),
  variables: z.number().int().min(0),
  levels: z.record(limitLevelSchema, z.number().int().min(0)),
});

export type exceedanceCountSchema = z.infer<typeof exceedanceCountSchema>;
