import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DateStateSearchParamKeys } from "../../zustand/useDateState";

type PickSearchParams = {
  [TParam in DateStateSearchParamKeys]?: boolean;
} & Record<string, boolean>; // this looks weird but its just a Record<string, boolean> in the end. writing it this way allows for autocomplete/intellisense to work on DateStateSearchParamKeys

type SetSearchParams = {
  [TParam in DateStateSearchParamKeys]?: string;
} & Record<string, string>; // see above

export function LinkWithQuery(
  props: React.ComponentProps<typeof Link> & {
    pick: PickSearchParams; // pick what search params that currently exist to keep
    set?: SetSearchParams; // set what search params to add
  }
) {
  const { pick, to, set, ...rest } = props;

  const [searchParams] = useSearchParams();

  const copy = new URLSearchParams({});

  Object.entries(pick).forEach(([k, shouldKeep]) => {
    if (!shouldKeep) return;

    const v = searchParams.get(k);
    if (v !== null && v.trim() !== "") {
      copy.set(k, v);
    }
  });

  // set will override pick
  set &&
    Object.entries(set).forEach(([k, v]) => {
      if (pick[k] !== undefined) throw new Error("pick and set cannot overlap");
      copy.set(k, v);
    });

  return <Link {...rest} to={to + "?" + copy.toString()} />;
}
