import React from "react";
import { Navigate } from "react-router";
import { Outlet } from "react-router-dom";
import useCurrentUnitObject from "./hooks/useCurrentUnitObject";

type Props = {
  children?: React.ReactNode;
  addonKey:
    | "specialtyReports"
    | "faultTrees"
    | "slopingTrends"
    | "operatingLimits"
    | "realTime";
  asOutlet?: boolean;
};

function AddonRoute({ children, addonKey, asOutlet = false }: Props) {
  const currentUnit = useCurrentUnitObject();
  const allowed = currentUnit && currentUnit.addons?.[addonKey];

  const ifAllowedComponent = asOutlet ? <Outlet /> : children;

  return <>{allowed ? ifAllowedComponent : <Navigate to="/" replace />}</>;
}

export default AddonRoute;
