import { LoadingOverlay } from "@mantine/core";
import React from "react";

function LoadingScreen({ message, visible, overlayBlur }) {
  return (
    <LoadingOverlay
      visible={visible}
      overlayBlur={overlayBlur || 2}
      loader={
        <div className="flex flex-col justify-center gap-2">
          {message ? <span className="text-center">{message}</span> : null}
          <progress className="progress w-80"></progress>
        </div>
      }
    />
  );
}

export default LoadingScreen;
