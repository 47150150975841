import * as React from "react";
import { useGroupsQuery } from "../../../hooks/tanstack-query";
import Select from "../../common/Select/Select";
import { EmailMe } from "./email-me";
import { LevelTogglers } from "./level-togglers";
import { useGetUseAckManStore } from "./store/ack-man-store";
import useMaxDate from "../../common/hooks/useMaxDate";
import { SingleDaySelectWithArrows } from "../../common/calendar";

function useInitGroupSelection() {
  const useAckManStore = useGetUseAckManStore();

  const groupsQuery = useGroupsQuery();

  const groups = groupsQuery.data;

  const groupOptions: Array<{
    label: string;
    value: string;
  }> =
    groups?.map((g) => ({
      label: g.name,
      value: g._id,
    })) ?? [];

  React.useEffect(() => {
    if (!groups) return;
    if (useAckManStore.getState().selectedGroupId) return;

    const group = groups.find((g) => g.name === "Overall") ?? groups[0];

    if (!group) return;

    useAckManStore.getState().setGroup(group._id);
  }, [groups, useAckManStore]);

  return groupOptions;
}

export function Header() {
  const maxDate = useMaxDate();
  const useAckManStore = useGetUseAckManStore();

  const selectedGroupId = useAckManStore((s) => s.selectedGroupId);

  const allGroups = useInitGroupSelection();

  const date = useAckManStore((s) => s.date) ?? maxDate;

  return (
    <div className="flex items-start gap-3 mt-4">
      <LevelTogglers />
      <SingleDaySelectWithArrows
        value={date}
        onChange={useAckManStore.getState().setDate}
        close={{
          className: "btn-outline float-right",
          size: "xs",
        }}
        closeOnChange
      />
      <div className="input-group max-w-min">
        <span className="bg-neutral text-neutral-content text-[0.9rem]">
          Group
        </span>
        <Select
          className="max-w-[200px] border-bdgrey font-normal"
          bordered={true}
          options={allGroups}
          value={selectedGroupId}
          onChange={({ target: { value } }) =>
            useAckManStore.getState().setGroup(value)
          }
        />
      </div>
      <EmailMe />
    </div>
  );
}
