import React from "react";
import _ from "lodash";

/* A normal React component, just extended to have slightly better defaults and
 * to enable some better dev patterns.
 *
 * Features are:
 *
 * - Synchronous app state:
 *
 *     This distinguishes from `setState` because `setState` is asynchronous,
 *     which is a bit more expensive and can result in some strange bugs occasionally.
 *     `setAppState` is designed to be used with non-interface data. It turns out that
 *     even in React, not all data is relevant to rendering directly. It is useful
 *     to occasionally pull that out and manage it in more precise ways.
 *
 *     Setting app state will always trigger a rerender, but it merely trigger's React's
 *     internal rerender. So it's not guaranteed to happen immediately or do anything
 *     special. The calls may even be deduped.
 *
 *     Two methods: `setAppState` works the same way as `setState`, and
 *     `mergeAppState` is a recursive version based on lodash `_.merge`.
 *
 * - Async React state:
 *
 *     `setAsyncState` returns a promise that you can await on. Note that this is
 *     discouraged, if you find yourself regularly needing to await on a React
 *     state change, this is often a sign that you should be using `appState` for
 *     that property instead.
 *
 *     `setAsyncState` requires a function rather than an object becuase asynchronous
 *     state management using objects is error-prone. See
 *     https://nearmiss.atlassian.net/wiki/spaces/~312472286/blog/2019/01/02/581894181/React+Gotcha+Immutable+asynchronous+setState.
 *
 * - (optional) Prefix for building IDs
 *
 *     Just a minor convenient property to get a unique name for the current instance.
 *     Can be used to build IDs in HTML. Note that if you're just adding a component
 *     in a render method then this ID is going to be constantly changing. This isn't
 *     necessarily good to use in that case, but if you want to get around that behavior,
 *     you can also pass in a `prefix` prop to override the value.
 *
 * - (optional) Linking system for sharing state updates across multiple components.
 *
 *     Occasionally there are components that are tightly coupled but that can
 *     be spread across a page such that it doesn't make sense to try and merge them
 *     into a single component or push state into a parent. Often, the solution in this
 *     situation will be to leverage Redux. But for small components that want individualized
 *     states instead of a singleton, Redux may not be suitable. In those rare instances, passing
 *     in a `linker` object to the `linker` property of both components will allow them to mirror
 *     some state updates between each linked component.
 */
class ReactEnhanced extends React.Component {
  constructor(props) {
    super(props);

    this.prefix = props.prefix || _.uniqueId(this.__proto__.constructor.name);
    this.appState = {}; /* use define property to avoid overwriting. */

    /* Handle linker wiring and updates: */
    const update = (prevProps, prevState) => {
      /* If a prefix was being provided through props, and it's not
       * being provided anymore, generate a new prefix. This is just
       * a bit cleaner and means you don't need to think about component
       * lifecycles as much. We do force a rerender in this situation. */
      if (prevProps && prevProps.prefix && !this.props.prefix) {
        this.prefix = _.uniqueId(this.__proto__.constructor.name);
        this.forceUpdate(); /* probably not necessary? */
      }

      /* Pair down the state to only the changed values so we avoid
       * overwriting state when a component is linked. */
      const updatingState = prevState
        ? _.reduce(
            this.state,
            (result, value, key) => {
              if (value !== prevState[key]) {
                result[key] = value;
              }
              return result;
            },
            {}
          )
        : {};

      /* Linker wiring/unwiring */
      const previousLinker = prevProps ? prevProps.linker : null;
      const linkRemoved =
        previousLinker && previousLinker !== this.props.linker;
      const linkAdded =
        this.props.linker && this.props.linker !== previousLinker;

      if (linkRemoved) {
        this.unlink();
      }

      if (linkAdded) {
        this.unlink = this.props.linker.subscribe((state) => {
          /* Skip our own update events. */
          const hasChanged = _.reduce(
            state,
            (result, value, field) => {
              return result || value !== this.state[field];
            },
            false
          );

          if (hasChanged) {
            this.setState(state);
          }
        });

        /* And send/intake the state depending on the linker status. */
        this.props.linker.initalized
          ? this.setState(this.props.linker.state)
          : this.props.linker.update(this.state);
      }

      /* Send any changed state. */
      if (this.props.linker) {
        this.props.linker.update(updatingState);
      }

      /* If a linker was just added, recieve any state that still needs to be
       * updated. The above code may handle this in many situations, but it
       * won't handle it in all of them. Note that we run this check after
       * sending any newly updated properties above. */
      if (linkAdded) {
        const needsUpdate = _.reduce(
          this.props.linker.state,
          (result, value, key) => {
            return result || value !== this.state[key];
          },
          false
        );

        if (needsUpdate) {
          this.setState(this.props.linker.state);
        }
      }
    };

    this.mounted = false; /* Make it safe to set appState without relying on render having finished. */
    this.componentDidMount = (() => {
      let original = this.componentDidMount || function () {};
      return function () {
        this.mounted = true;
        _.each(this.toSubscribe, (store) => {
          this.subscriptions.push(store.subscribe(() => this.forceUpdate()));
        });
        this.toSubscribe = [];

        update();
        return original.apply(this, arguments);
      };
    })();

    this.componentDidUpdate = (() => {
      let original = this.componentDidUpdate || function () {};
      return function (prevProps, prevState) {
        update(prevProps, prevState);
        return original.apply(this, arguments);
      };
    })();

    /* Cleanup for some of the internal state we're tracking. */
    this.componentWillUnount = (() => {
      let original = this.componentWillUnmount || function () {};
      return function () {
        _.each(this.subscriptions, (unsubscribe) => unsubscribe());
        this.subscriptions = [];

        this.mounted = false;
        this.unlink && this.unlink();

        return original.apply(this, arguments);
      };
    })();

    this.subscriptions = [];
    this.toSubscribe = [];
    this.subscribeStores = function (...rest) {
      /* Unsubscribe anything that already exists */
      _.forEach(this.subscriptions, (unsubscribe) => unsubscribe());

      _.forEach(rest, (store) => {
        if (this.mounted) {
          this.subscriptions.push(store.subscribe(() => this.forceUpdate()));
          return;
        }

        this.toSubscribe.push(store);
      });
    };

    /* Handle `prefix` changes without forcing multiple renders. We would have
     * used `componentWillRecieveProps` for this, but it's being deprecated, so
     * I don't see a reason to go down that route. */
    this.render = (() => {
      let original = this.render || function () {};
      return function () {
        if (this.props.prefix && this.props.prefix !== this.prefix) {
          this.prefix = this.props.prefix;
        }

        return original.apply(this, arguments);
      };
    })();
  }

  /* Allows using "pure" components. See the following:
   * - https://blog.isquaredsoftware.com/2020/05/blogged-answers-a-mostly-complete-guide-to-react-rendering-behavior/
   * - https://reactjs.org/docs/react-api.html#reactpurecomponent
   *
   * This reduces rendering overhead in some situations. The big difference between
   * this and a pure component is that we do deep comparisons of state/props instead
   * of shallow ones. In general, my assumption is that if you accidentally pass an object
   * in, you probably wanted to do a deep comparison. And if not, hey, this method can
   * be overridden.
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (!this.pure) {
      return true;
    }
    return (
      !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    );
  }

  /* Return promises when setting react state */
  setAsyncState(method) {
    if (!_.isFunction(method)) {
      throw new Error("`setAsyncState` requires a function");
    }

    return new Promise((resolve) =>
      this.setState.call(this, method, () => resolve())
    );
  }

  /* Synchronous state assigment. This turns out to matter a lot. */
  setAppState(state) {
    _.assign(this.appState, state);

    if (this.onAppStateChange) {
      this.onAppStateChange(state);
    }

    /* Trigger re-render. React will de-dup these for us. */
    /* `setAppState` is safe to use before the component has mounted,
     * since it's not tied to rendering. */
    if (this.mounted || this.mounted == null) {
      this.forceUpdate();
    }
  }

  mergeAppState(state) {
    _.merge(this.appState, state);

    if (this.onAppStateChange) {
      this.onAppStateChange(state);
    }

    /* Trigger re-render. React will de-dup these for us. */
    /* `mergeAppState` is safe to use before the component has mounted */
    if (this.mounted || this.mounted == null) {
      this.forceUpdate();
    }
  }
}

export default {
  Component: ReactEnhanced,
};
