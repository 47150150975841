import * as React from "react";
import { MultiSelect } from "@mantine/core";
import { cn } from "../../shared-ui/frontend/cn";
import type { z } from "zod";
import { allKeyValidator } from "../../shared-ui/lib/validators";

export type AllKey = z.infer<typeof allKeyValidator>;

/**
 * Prestyled stages multi select component using Mantine's MultiSelect
 */
export const PrestyledMultiSelect = React.forwardRef<
  React.ElementRef<typeof MultiSelect>,
  React.ComponentPropsWithoutRef<typeof MultiSelect>
>((props, ref) => {
  return (
    <MultiSelect
      ref={ref}
      {...props}
      classNames={{
        input: cn(
          props.icon && "pl-8",
          "bg-white dark:bg-xslate-1 border min-h-0 h-full rounded-md border-xslate-7 focus-within:border-xindigo-8 pr-8"
        ),
        values: "inline-flex items-center h-full gap-y-1 py-1",
        value: "my-0 bg-xslate-4 text-xslate-11 border border-xslate-7",
        dropdown: "border-xslate-7 overflow-hidden",
        itemsWrapper: "bg-white dark:bg-xslate-1 text-xslate-11",
        item: "text-xslate-11 data-[hovered=true]:bg-xslate-5 hover:bg-xslate-5 data-[disabled=true]:bg-xslate-4 data-[disabled=true]:cursor-not-allowed",
        searchInput: "text-xslate-11",
        label: "text-xslate-11",
      }}
    />
  );
});

/**
 * Pass this into the onChange prop of the above component if you are using the allKeyValidator,
 * to have a default All option. This is tricky so I extracted the logic into one place.
 */
export function handleAllOption(
  selected: string[],
  options: {
    value: string;
  }[]
) {
  const last = selected.at(-1);
  if (!last || last === allKeyValidator.value) {
    return [allKeyValidator.value];
  }

  const filterOutAll = selected.filter((id) => id !== allKeyValidator.value);

  const everyItemIsSelected = options.every(
    (p) => p.value === allKeyValidator.value || filterOutAll.includes(p.value)
  );

  return everyItemIsSelected ? [allKeyValidator.value] : filterOutAll;
}

PrestyledMultiSelect.displayName = "PrestyledMultiSelect";
