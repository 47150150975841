import { utils, writeFileXLSX } from "xlsx";

export default function downloadXLSX<T>(
  arr: T[],
  filename: string,
  headerOrderArr: string[] = []
) {
  // headerOrderArr is an array with the columns that you want to appear first, in that order. it does not have to include all of them
  const wb = utils.book_new();
  utils.book_append_sheet(
    wb,
    utils.json_to_sheet(arr, { header: headerOrderArr }),
    "Data"
  );
  return writeFileXLSX(wb, filename);
}
