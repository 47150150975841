import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { create } from "zustand";
import { userSchema as User } from "../../../lib/api-schema/user";

/**
 * Define what the user can search by in the search bar
 */
type QueryBy = "email" | "first name" | "last name";

type UsersManagerState = {
  createMode: boolean;
  setCreateMode: (createMode: boolean) => void;
  sort: PropertyKey;
  setSort: (sort: string) => void;
  filter: PropertyKey;
  setFilter: (filter: string) => void;
  query: string;
  setQuery: (query: string) => void;

  queryBy: QueryBy;
  setQueryBy: (queryBy: QueryBy) => void;

  enabledFilters: Record<PropertyKey, (t: User) => boolean>;
  enabledSorts: Record<PropertyKey, (a: User, b: User) => number>;
};

type InitialDataForStore = {
  initialSort: UsersManagerState["sort"];
  initialFilter: UsersManagerState["filter"];
  enabledFilters: UsersManagerState["enabledFilters"];
  enabledSorts: UsersManagerState["enabledSorts"];
};

function createUseUsersManagerStore({
  initialFilter,
  initialSort,
  enabledFilters,
  enabledSorts,
}: InitialDataForStore) {
  return create<UsersManagerState>((set, get) => ({
    createMode: false,
    setCreateMode: (createMode) => set({ createMode }),
    sort: initialSort,
    setSort: (sort) => {
      if (!get().enabledSorts[sort])
        throw new Error(
          "Cannot set sort key if corresponding function is not defined"
        );
      set({ sort });
    },
    filter: initialFilter,
    setFilter: (filter) => {
      if (!get().enabledFilters[filter])
        throw new Error(
          "Cannot set sort key if corresponding function is not defined"
        );
      set({ filter });
    },
    query: "",
    setQuery: (query) => set({ query }),
    queryBy: "email",
    setQueryBy: (queryBy) => set({ queryBy }),
    enabledFilters,
    enabledSorts,
  }));
}

type UseUsersManagerStore = ReturnType<typeof createUseUsersManagerStore>;

const UsersManagerStoreContext = createContext<
  UseUsersManagerStore | undefined
>(undefined);

function useGetUseUsersManagerStore() {
  const useStore = useContext(UsersManagerStoreContext);
  if (useStore === undefined) {
    throw new Error(
      "useGetUseUsersManagerStore must be used within a UseUsersManagerStoreProvider"
    );
  }
  return useStore;
}

function UseUsersManagerStoreProvider<
  T extends Record<string, (t: User) => boolean>,
  S extends Record<string, (a: User, b: User) => number>,
>({
  children,
  ...rest
}: PropsWithChildren<{
  enabledFilters: T;
  initialFilter: keyof T;
  enabledSorts: S;
  initialSort: keyof S;
}>) {
  const [s] = useState(() => createUseUsersManagerStore(rest));

  return (
    <UsersManagerStoreContext.Provider value={s}>
      {children}
    </UsersManagerStoreContext.Provider>
  );
}

export {
  useGetUseUsersManagerStore,
  UseUsersManagerStoreProvider,
  type QueryBy,
};
