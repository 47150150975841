export function formatTime(time: number) {
  if (time >= 3600) {
    const tmp = time / 3600;
    return (tmp % 1 !== 0 ? Math.round(tmp * 10) / 10 : tmp) + "h";
  } else if (time >= 60) {
    const tmp = time / 60;
    return (tmp % 1 !== 0 ? Math.round(tmp) : tmp) + "m";
  } else {
    return time + "s";
  }
}
