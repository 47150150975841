import React, { useState, useEffect, useMemo, useCallback } from "react";
import First from "../../common/First";
import MainLoader from "../../common/MainLoader";
import PageError from "../../common/PageError";
import Fragment from "../../common/Fragment";
import useAPI from "../../../lib/useAPI";
import SearchBar from "../../common/SearchBar";
import useSearch from "../../common/useSearch";
import usePagination from "../../common/usePagination";
import useAuthStore from "../../../zustand/auth/useAuthStore";

import TeamCard from "./TeamCard";
import _ from "lodash";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { CreateTeamForm } from "./create-team-form";

function numericRole(role) {
  switch (role) {
    case "User":
      return 1;
    case "Admin":
      return 2;
    case "Root":
      return 3;
    default:
      return 0;
  }
}

function TeamsManagerDeprecated() {
  usePermissionBasedDocumentTitleForSettingsPage();
  const api = useAPI();
  const USER = useAuthStore((s) => s.user);
  const hasEditAccess = USER.hasEditPermission;

  const mode = "Teams";

  const [initialLoading, setInitialLoading] = useState(true); // display loading screen
  const [pageError, setPageError] = useState(null); // page-level error

  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const [createMode, setCreateMode] = useState(false);

  const labels = {
    title: mode,
    overview: `Displaying ${teams.length} Teams`,
    empty: "No teams to show",
  };

  /* We need to split the logic slightly depending on whether users or teams are visible.
   * But we don't want to end up with a bunch of nested if statements. */

  /* ------- USER CONTROLS ------- */
  /* ----------------------------- */

  const userMap = useMemo(() => {
    return users.reduce((result, user) => {
      result[user._id] = user;
      return result;
    }, {});
  }, [users]);

  const teamFilterOptions = {
    includeAll: true,
    options: {},
  };

  const teamSortOptions = {
    options: {
      "last updated": (a, b) =>
        Date.parse(b.updated_at) - Date.parse(a.updated_at),
      name: (a, b) => a.name.localeCompare(b.name),
    },
  };

  const teamSearchOptions = {
    options: {
      name: { keys: ["name"] },
      users: {
        keys: [
          (item) => {
            return item.users.map(
              (id) => `${userMap[id]?.first} ${userMap[id]?.last}`
            );
          },
        ],
      },
    },
  };

  /* These also need to be encapsulated a bit more (?) */
  const { matches, sort, search } = useSearch(teams, {
    filter: teamFilterOptions,
    sort: teamSortOptions,
    search: teamSearchOptions,
  });

  const Pagination = usePagination(matches, 20, true);

  const formatUser = useCallback(
    (u, reportsFormatted) => {
      const [reportsAssociated, reportsNotAssociated] = _.partition(
        reportsFormatted,
        (r) => u.reports.includes(r.value)
      );
      u.reports = reportsAssociated;

      // can we edit or delete this person?
      const isLesserThanMe = numericRole(u.role) < numericRole(USER.role);
      const isMe = u._id === USER._id;
      u.editable =
        (isLesserThanMe || isMe) && u.email && u.first && u.last && u.role; // TODO replace 1 with whatever else I need
      u.deletable = isLesserThanMe && !isMe;

      // you can't edit your own role though
      if (u.editable) {
        u.reportsNotAssociated = reportsNotAssociated; // they only need this field for the form

        if (isMe) {
          u.roleOptions = {
            disabled: true,
            // this option is present just for display, although we disable the select input
            options: [
              {
                label: u.role,
                value: u.role,
              },
            ],
          };
        } else {
          // Somebody else
          u.roleOptions = {
            options: [
              { value: "User", label: "User" },
              { value: "Admin", label: "Admin" },
            ],
          };
        }
      }

      return { ...u };
    },
    [USER._id, USER.role]
  );

  useEffect(() => {
    const initialize = async () => {
      /* We could set this up as two separate tabs, but it seems like a wild overcomplication
       * over what is a much simpler implementation:
       *
       *  - fetch everything (both stores)
       *  - filter the list based on which tab is selected
       *
       * Note that because the page is now treated like two separate pages, we want to
       * move back to a "loading" state whenever the page is re-mounted in the app.
       */
      setInitialLoading(true);

      try {
        const reportsPromise = api.getSpecialtyReports();
        const usersPromise = api.getUsers();
        const teams = await api.getTeams();

        const reports = await reportsPromise;
        const reportsFormatted = reports.map((r) => ({
          value: r._id,
          label: r.name,
        }));

        const users = (await usersPromise).map((u) =>
          formatUser(u, reportsFormatted)
        );

        setUsers(users);
        setTeams(teams); /* Do we need to format the team? */
        setInitialLoading(false);
      } catch (e) {
        console.log(e);
        setPageError(e);
      }
    };

    initialize();
  }, [api, formatUser]);

  const createTeam = useCallback(
    async (payload) => {
      const result = await api.createTeam(payload);
      setTeams((teams) => [result, ...teams]);
      setCreateMode(false);
      return result;
    },
    [api] /* Probably not necessary */
  );

  const deleteTeam = useCallback(
    async (id) => {
      await api.deleteTeam(id);
      setTeams((teams) => {
        return teams.filter((team) => team._id !== id);
      });
    },
    [api]
  );

  const updateTeam = useCallback(
    async (payload) => {
      const result = await api.updateTeam(payload._id, payload);
      setTeams((teams) => {
        const index = _.findIndex(teams, (team) => team._id === payload._id);
        const updatedTeams = [...teams];
        updatedTeams[index] = result;
        return updatedTeams;
      });
    },
    [api]
  );

  /**
   * TODO this needs some refactoring, it makes no sense
   */
  function buildCard(item) {
    const close = null;

    return (
      <TeamCard
        key={item?._id}
        creating={false}
        team={item}
        users={users}
        cancel={close}
        deleteTeam={deleteTeam}
        saveTeam={updateTeam}
      />
    );
  }

  const exitCreateMode = () => setCreateMode(false);

  return (
    <First>
      <MainLoader match={initialLoading && !pageError} />
      <PageError
        match={!!pageError}
        message="An error has occured. Please refresh the page."
      />

      <Fragment match={!initialLoading && !pageError}>
        <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
          <div className="flex items-center mb-2 sm:mb-0">
            <span className="text-[2rem] sm:text-[1.75rem] mr-2">
              {labels.title}
            </span>

            {hasEditAccess && (
              <CreateNewEntityButton onClick={() => setCreateMode(true)} />
            )}
          </div>
          <div>
            <span className="mr-5 italic text-[1.25rem] text-titlegrey">
              {labels.overview}
            </span>
          </div>
        </div>
        <SearchBar search={search} sort={sort} filter={false} />
        {createMode && (
          <CreateTeamForm
            closeForm={exitCreateMode}
            createTeam={createTeam}
            users={users}
          />
        )}

        <div className="drop-shadow-md my-5">
          {Pagination.items.length ? (
            Pagination.items.map((item) => buildCard(item))
          ) : (
            <div className="flex text-textgrey justify-center text-[1.5rem] mt-5">
              {labels.empty}
            </div>
          )}
        </div>
        {Pagination.buttons}
      </Fragment>
    </First>
  );
}

export { TeamsManagerDeprecated };
