import { BarData } from "../../../types/api/RidgelineResponse";
import {
  HIST_DEFAULT_FILL_OPACITY,
  LIGHT_FILL_OPACITY,
} from "../utils/drawUtils";
import { ChartDimensions, Scale, SvgSelector } from "../types";
import { cn } from "../../../lib/utils";
import { HistogramClass } from "../utils/chartUtils";

export const HistRectPadding = 1;

export const drawHistogram = (
  svgSelector: SvgSelector,
  data: BarData[],
  dataRef: string,
  dataColor: string,
  xScale: Scale,
  yScale: Scale,
  highlight: boolean,
  { height, margin }: ChartDimensions,
  options?: { showBars: boolean }
) => {
  // pass in options show bars to make visible or not
  svgSelector
    .append("g")
    .attr("transform", `translate(0, 0)`)
    .attr("class", cn("chart", HistogramClass, dataRef))
    .attr("id", `${HistogramClass}-${dataRef}`)
    .selectAll("rect")
    .exit()
    .data(data)
    .enter()
    .append("rect")
    .attr("vector-effect", "non-scaling-stroke")
    .attr("x", (d) => xScale(d.x0))
    .attr("y", (d) => yScale(d.value))
    .attr("width", (d) =>
      Math.max(xScale(d.x1) - xScale(d.x0) - HistRectPadding, 5)
    ) // clamp if negative width
    .attr("height", (d) => height - margin - yScale(d.value))
    .attr("pointer-events", "all")
    .attr("visibility", options?.showBars ? "visible" : "hidden")
    .style("fill", dataColor)
    .style(
      "fill-opacity",
      highlight ? HIST_DEFAULT_FILL_OPACITY : LIGHT_FILL_OPACITY
    );
};
