import { useState, useRef, useEffect, ComponentProps } from "react";
import { TimeseriesProps } from "../../shared-ui/time-series/types";
import { useVariablesArrayQuery } from "../../hooks/tanstack-query";
import ExpressionBuildingInput from "../../shared-ui/expression-building-input/ExpressionBuildingInput";
import { parseExprRef } from "../../shared-ui/expression-building-input/utils";
import validateExpression from "../../shared-ui/expression-building-input/validate";
import { FUNCTIONS } from "../../shared-ui/expression-building-input/constants";
import { Button } from "../ui/button";
import { FaCalculator, FaSave } from "react-icons/fa";
import {
  DrawerContent,
  DrawerClose,
  Drawer,
} from "../../shared-ui/frontend/drawer";
import { cn } from "../../lib/utils";
import { X } from "lucide-react";
import { v4 } from "uuid";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "./global-tooltip";
import {
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "./secondary-variable-view/dra-secondary-variable.view";
import {
  Atoms,
  ChartVariant,
} from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useAtomValue } from "jotai";

const alwaysFalse = () => false;

export default function TimeSeriesDrawer(
  props: Omit<
    TimeseriesProps & {
      expressionChange?: (expr: string) => void;
      save?: (expr: string) => void;
    },
    "start" | "end"
  > &
    Partial<{ start: Date; end: Date }> &
    Required<Pick<ComponentProps<typeof Drawer>, "open" | "onOpenChange">>
) {
  const [expression, setExpression] = useState<{
    expression: string;
    id: string;
  }>({
    expression: props.expression || "",
    id: v4(),
  });
  const [isValidExpression, setIsValidExpression] = useState(true);
  const [validationError, setValidationError] = useState<string>("");
  const inputRef = useRef<HTMLDivElement>(null);
  const allVariables = useVariablesArrayQuery();
  const options =
    allVariables.data
      ?.filter((v) => v.type === "Tag")
      .map((v) => ({
        label: v.trimmedName,
        value: v.name,
        description: v.description,
      })) || [];
  const symbols = allVariables.data?.map((o) => o.name) || [];

  useEffect(() => {
    setExpression({
      expression: props.expression || "",
      id: v4(),
    });
  }, [props.expression]);

  return (
    <Drawer
      dismissible={false}
      open={props.open}
      onOpenChange={props.onOpenChange}
      /**
       * If modal is off, the custom date selector
       * breaks because the drawer doesn't allow you to
       * focus on other things outside the drawer.
       *
       * We render the date selectors outside of the drawer,
       * so it can't be focused without modal=false.
       */
      modal={false}
    >
      <DrawerContent className="h-[98dvh] bg-xslate-3">
        {/* Because we have modal off, Ankur wants this little
        hack to show a darkened background at the top. If modal=true this would be handled for us already like
        in the variability drawer. */}
        {props.open && (
          <div className="-z-10 -translate-y-[90%] absolute top-0 h-10 w-screen bg-black/80" />
        )}
        <div className="flex flex-col grow">
          <div className="items-center flex border-b border-xslate-5 py-2 px-4 bg-white shadow-sm rounded-t-lg">
            <div className="font-semibold tracking-tight text-lg text-xslate-12 mr-auto">
              Instant Calculator
            </div>
            {props.save && (
              <Button
                variant="outline"
                className="mr-2"
                onClick={() => {
                  const expr = parseExprRef(inputRef.current?.innerHTML || "");
                  props.save?.(expr);
                }}
                size="sm"
              >
                <FaSave className="h-3 w-3" />
                <span className="ml-1 uppercase">Save</span>
              </Button>
            )}
            <DrawerClose asChild>
              <Button variant="outline" size="sm">
                <X className="size-4" />
                <span className="ml-1 uppercase">Close</span>
              </Button>
            </DrawerClose>
          </div>
          <div className="flex p-3 items-stretch">
            <ExpressionBuildingInput
              inputRef={inputRef}
              options={options}
              defaultValue={expression.expression}
              className={cn(
                "bg-white border-r-0 rounded rounded-r-none",
                !isValidExpression && "border-red-500 border-r"
              )}
              onInput={(e) => {
                const html = e.currentTarget.innerHTML;
                if (html === "") {
                  setExpression({ expression: "", id: v4() });
                }
              }}
            />
            <Button
              className="rounded-l-none text-sm min-h-10 h-auto self-stretch"
              variant="default"
              onClick={() => {
                const expr = parseExprRef(inputRef.current?.innerHTML || "");
                try {
                  if (validateExpression(expr, symbols, FUNCTIONS)) {
                    setIsValidExpression(true);
                    // setExpression(expr);
                    if (props.expressionChange) {
                      props.expressionChange(expr);
                    }
                  }
                } catch (e) {
                  if (e instanceof Error) {
                    setIsValidExpression(false);
                    setTimeout(() => setIsValidExpression(true), 4000);
                    setValidationError(e.message);
                  }
                }
              }}
            >
              <FaCalculator className="h-4 w-4 mr-2" />
              Calculate
            </Button>
          </div>
          {!isValidExpression && (
            <div className="bg-red-500 text-white p-2 -mt-2 w-fit">
              {validationError}
            </div>
          )}
          {expression.expression.trim().length > 0 ? (
            <TimeseriesChartTooltipStoreProvider>
              {() => (
                <div className="px-3">
                  <GlobalTooltip />
                  <DRASecondaryVariableViewProvider
                    key={expression.expression}
                    initialBatchVariables={[
                      {
                        type: "expression",
                        ...expression,
                      },
                    ]}
                    initialExpanded={false}
                    variant={ChartVariant.InstantCalculator}
                    decideAnomBooleanForNewlyAddedVariableTrendLines={
                      alwaysFalse
                    }
                  >
                    <Chart />
                  </DRASecondaryVariableViewProvider>
                </div>
              )}
            </TimeseriesChartTooltipStoreProvider>
          ) : (
            <div className="flex justify-center items-center h-screen">
              <h3 className="animate-in slide-in-from-top-5 text-xl tracking-tight text-xslate-11 relative bottom-16">
                Type an expression and click &nbsp;
                <i className="fa fa-calculator" /> Calculate
              </h3>
            </div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function Chart() {
  const selectedVariables = useAtomValue(Atoms.selectedVariablesAtom);
  const multiVariableView = selectedVariables.length > 1;

  return (
    <DRASecondaryVariableViewNeedsProvider
      ableToGetTaller={false}
      height={window.innerHeight * 0.81 * 1.6 - (multiVariableView ? 50 : 0)}
      width={window.innerWidth * 1.6}
      lineWidthScale={0.3}
      axesFontScale={0.7}
    />
  );
}
