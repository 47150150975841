import { type PropsWithChildren } from "react";
import { useGetUseTimeseriesChartsTooltipStore } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { cn } from "../../shared-ui/frontend/cn";
import { createPortal } from "react-dom";
import { usePlantTimeFormatter } from "../../hooks/use-plant-time-formatter";
import { useAtomValue } from "jotai";
import { Atoms } from "../../shared-ui/time-series-2/svv-store/use-svv-store";

function InstantCalculatorTooltip() {
  const useStore = useGetUseTimeseriesChartsTooltipStore();
  const container = useAtomValue(Atoms.canvasesContainerAtom);

  if (!useStore)
    throw new Error(
      "useStore is undefined. Only render this component inside a TimeseriesChartTooltipStoreProvider."
    );

  const tooltipData = useStore((s) => s.hoverData);

  const fmtTime = usePlantTimeFormatter();

  if (!tooltipData || !container) return null;

  const tooltip = (
    <div
      className={cn(
        // pointer-events-none is to allow the mouse to go through this element
        // so we can continue hovering over the chart even if mouse is over this tooltip
        "pointer-events-none absolute left-0 top-0 z-[500] flex -translate-x-1/2 flex-col rounded-md border border-xslate-6 p-2 text-xs bg-zinc-950/70 text-white"
      )}
      style={{
        top: `${tooltipData.percentageTop + 5}%`,
        left: `${Math.max(Math.min(tooltipData.percentageLeft, 93), 7)}%`,
      }}
    >
      <div className="mb-2 flex items-center border-b border-inherit pb-2">
        <span className="text-md leading-2 mr-5 whitespace-nowrap font-semibold">
          {tooltipData.v.toFixed(2)}
        </span>
      </div>

      <div className="grid grid-cols-[40px_auto] justify-items-start gap-x-8">
        <TooltipLabel>Time</TooltipLabel>
        <span className="col-auto whitespace-nowrap">
          {fmtTime(tooltipData.t)}
        </span>
      </div>
    </div>
  );

  return createPortal(tooltip, container);
}

function TooltipLabel({ children }: PropsWithChildren) {
  return (
    <span className="col-start-1 justify-self-start whitespace-nowrap uppercase text-amber-500">
      {children}
    </span>
  );
}

export { InstantCalculatorTooltip };
