import { getFullName } from "../../../../shared-ui/lib/user-utils";

function UserName({ user }: { user: { first: string; last: string } }) {
  return <span className="text-sm font-medium">{getFullName(user)}</span>;
}

function Avatar({ user }: { user: { first: string; last: string } }) {
  const { first, last } = user;
  return (
    <div
      className={`bg-zinc-300 text-base-100 rounded-full size-10 grid place-content-center shrink-0`}
    >
      <span className="text-lg">
        {first[0]?.toUpperCase()}
        {last[0]?.toUpperCase()}
      </span>
    </div>
  );
}

export { UserName, Avatar };
