import { useSearchParams } from "react-router-dom";
import * as R from "remeda";
import { z } from "zod";

type DRAParam = "selectedCommentId" | "dv" | "g" | "v";

type SetOptions = {
  replaceHistory?: boolean;
};

export const DRAParamsMap: Record<DRAParam, DRAParam> = {
  v: "v",
  dv: "dv",
  g: "g",
  selectedCommentId: "selectedCommentId",
};

export function useDRAParams2() {
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteDRAParams = (
    deleteBools: Partial<Record<DRAParam, boolean>>,
    options?: Pick<SetOptions, "replaceHistory">
  ) => {
    setSearchParams(
      (curr) => {
        R.forEachObj.indexed(deleteBools, (shouldDelete, key) => {
          shouldDelete && curr.delete(key);
        });
        return curr;
      },
      {
        replace: options?.replaceHistory,
      }
    );
  };

  const getGroupShortId = () => {
    const raw = searchParams.get(DRAParamsMap.g);
    const parsed = z.coerce.number().int().safeParse(raw);
    return parsed.success ? parsed.data : undefined;
  };

  const setGroupShortId = (shortId: number, options?: SetOptions) => {
    const short = z.number().int().parse(shortId);

    setSearchParams(
      (curr) => {
        curr.set(DRAParamsMap.g, short.toString());
        return curr;
      },
      { replace: options?.replaceHistory }
    );
  };

  const deleteDisplayVariableIds = (vidsToDelete?: number[]) => {
    if (!vidsToDelete) {
      deleteDRAParams({ dv: true }); // delete all
      return;
    }

    // delete specific ones
    const dvStrings = searchParams.get(DRAParamsMap.dv)?.split(",") ?? [];
    const asNumbers = z.coerce.number().int().array().parse(dvStrings);

    const filtered = asNumbers.filter((n) => !vidsToDelete.includes(n));

    if (filtered.length === asNumbers.length) {
      return;
    }

    setSearchParams((curr) => {
      curr.delete(DRAParamsMap.dv);
      curr.set(DRAParamsMap.dv, filtered.map((n) => n.toString()).join(","));
      return curr;
    });
  };

  const getDisplayVariableIds = () => {
    const raw = searchParams.get(DRAParamsMap.dv);
    if (!raw) {
      return [];
    }
    return z.coerce.number().int().array().parse(raw.split(","));
  };

  const setDisplayVariableIds = (
    vids: number[],
    options?: SetOptions & {
      end?: boolean; // do you want the new vids to be at the end of the list?
      append?: boolean; // do you want to merge with existing?
      atIndex?: number; // insert at this index
    }
  ) => {
    if (options?.atIndex !== undefined) {
      setSearchParams(
        (curr) => {
          const current = getDisplayVariableIds();
          const before = current.slice(0, options.atIndex);
          const after = current.slice(options.atIndex);
          const newParams = [
            ...before.filter((id) => !vids.includes(id)),
            ...vids,
            ...after.filter((id) => !vids.includes(id)),
          ];
          curr.delete(DRAParamsMap.dv);
          curr.set(
            DRAParamsMap.dv,
            newParams.map((n) => n.toString()).join(",")
          );
          return curr;
        },
        { replace: options?.replaceHistory }
      );
      return;
    }
    if (!options?.append) {
      setSearchParams(
        (curr) => {
          curr.delete(DRAParamsMap.dv);
          curr.set(DRAParamsMap.dv, vids.map((n) => n.toString()).join(","));
          return curr;
        },
        { replace: options?.replaceHistory }
      );
      return;
    }

    const current = getDisplayVariableIds();
    const oldsRemovedDups = current.filter((n) => !vids.includes(n));

    const withNew = options?.end
      ? [...oldsRemovedDups, ...vids]
      : [...vids, ...oldsRemovedDups];

    const asStrings = withNew.map((n) => n.toString());

    const newParamString = asStrings.join(",");

    setSearchParams(
      (curr) => {
        curr.delete(DRAParamsMap.dv);
        curr.set(DRAParamsMap.dv, newParamString);
        return curr;
      },
      { replace: options?.replaceHistory }
    );
  };

  const getVariableId = () => {
    const raw = searchParams.get(DRAParamsMap.v);
    if (!raw) {
      return undefined;
    }

    if (raw.includes("-")) {
      const out = z.coerce.number().int().array().parse(raw.split("-"));

      const [first, second] = out;
      if (first === undefined || second === undefined) {
        throw new Error("invalid variable id");
      }

      return [first, second] as const;
    } else {
      return z.coerce.number().int().parse(raw);
    }
  };

  return {
    deleteDRAParams,
    getGroupShortId,
    setGroupShortId,
    deleteDisplayVariableIds,
    setDisplayVariableIds,
    getDisplayVariableIds,
    getVariableId,
    setSearchParams,
  };
}

export default useDRAParams2;
