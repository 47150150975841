import type { AnomalyLevelEnum } from "../lib/anomaly-levels";
import * as d3 from "d3";

type VariableId = string;

type SegmentData = { value: number; datetime: string };
type SelectedStages = Record<VariableId, string[]>;
type HoverState = Record<VariableId, SegmentData>;
type StringToNumberTuple = Record<VariableId, [number, number]>;
type HoverStateValue = HoverState[VariableId] | undefined;
type HighlightModeState = Record<VariableId, number>;

type Segment = {
  level: number;
  mode: string;
  variable: string;
  data: SegmentData[];
  batchVariable: string;
};

type Timeseries = {
  value: number;
  variable: string;
  stage: string;
  timestamp: string;
  da: AnomalyLevelEnum | -2 | null;
  batchVariable: string;
  // stageId: string;
}[];

type TimeseriesProps = {
  variables: string[];
  aria?: boolean;
  width: number;
  height: number;
  expression?: string;
  start: Date;
  end: Date;
  coloredLines?: string[];
  fixedRanges?: StringToNumberTuple;
  // style?: React.CSSProperties;
};

type Slope = {
  start: { date: string; value: number };
  end: { date: string; value: number };
};

type SlopeInfo = {
  slope: number;
  change: number;
  anom: number;
  first: string;
  duration: number;
};

type AnomalyBar = {
  level: number;
  start: Date;
  end: Date;
  batchVariable: string | null;
  isShutdown?: boolean;
};

type ModeSegment = {
  mode: string;
  start: Date;
  end: Date;
};

type ScaleMap = {
  // the key here is a batchVariable
  // unless we're aligning stages, then it's a batchVariable-stage
  // unless we have hiddenModes, then it's a batchVariable-start
  [key: string]: {
    x: d3.ScaleLinear<number, number>;
    x2: d3.ScaleLinear<number, number>;
    y: d3.ScaleLinear<number, number>;
    y2: d3.ScaleLinear<number, number>;
    xTime: d3.ScaleTime<number, number>;
    xTime2: d3.ScaleTime<number, number>;
  };
};

enum BatchViewMode {
  series = "series",
  ParallelStage = "parallel-stage",
  ParallelStart = "parallel-start",
}

enum YAxisMode {
  Relative = "relative",
  Absolute = "absolute",
  Swimlane = "swimlane",
  Variability = "variability",
}

export {
  type SelectedStages,
  type HoverState,
  type StringToNumberTuple,
  type HoverStateValue,
  type HighlightModeState,
  type TimeseriesProps,
  type Slope,
  type SlopeInfo,
  type AnomalyBar,
  type ModeSegment,
  type ScaleMap,
  BatchViewMode,
  YAxisMode,
  type SegmentData,
  type Segment,
  type Timeseries,
};
