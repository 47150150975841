import { Alert } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { type Paragraph as ParagraphType } from "../types/BuilderTypes";
import type EditorProps from "./EditorProps";
import MarginInputs from "./MarginInputs";

function Paragraph({ content, editContent }: EditorProps<ParagraphType>) {
  function generateError(): string | null {
    if (!content.content.trim()) {
      return "This paragraph is empty";
    }
    return null;
  }

  const err = generateError();

  const errComponent = err && (
    <Alert title="Error" color="red" variant="filled">
      Paragraph text is required.
    </Alert>
  );

  return (
    <div className="flex flex-gap flex-col gap-2">
      {errComponent}
      <MarginInputs editContent={editContent} content={content} />
      <textarea
        className={classNames("textarea textarea-bordered grow", {
          "border-red-500": !!err,
        })}
        required
        placeholder="Paragraph text here"
        value={content.content}
        onChange={(e) => {
          editContent((c) => {
            c.content = e.target.value;
          });
        }}
      />
    </div>
  );
}

export default Paragraph;
