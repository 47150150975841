import {
  useOperatingLimitsProbabilityOrExceedanceVariablesQuery,
  useOperatingLimitsQuery,
  Comments,
  useVariablesArrayQuery,
} from "../../../../hooks/tanstack-query";
import { variableSchema } from "../../../../lib/api-validators";
import { cn, iife } from "../../../../lib/utils";
import { useDateState } from "../../../../zustand/useDateState";
import Button from "../../../common/Button/Button";
import { useSelectedGroupOnOperatingLimitsPages } from "../group-select";
import { useGetUseOlDetailsStore } from "../use-ol-details-store";
import { DEFAULT_VIEW, VIEW_LABELS, VIEW_ORDER } from "../../constants";
import { type operatingLimitSchema } from "../../../../lib/api-schema/operating-limit";
import {
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import OperatingLimitsInsightCard from "../OperatingLimitsInsightCard";
import OperatingLimitsTimeCard from "../OperatingLimitsTimeCard";
import OperatingLimitsFrequencyCard from "../OperatingLimitsFrequencyCard";
import OperatingLimitsExtremumCard from "../OperatingLimitsExtremumCard";
import { useSearchedVariablesOnOperatingLimitsPages } from "../sidebar";
import { useSelectedCommentIdParam } from "../use-selected-comment-id-param";
import { differenceWith } from "remeda";
import { ProbabilityToCrossPercentage } from "../probability-to-cross-percentage";
import { PredictiveProbabilityCard } from "../predictive-probability-card";
import { StatusSeries2 } from "./status-series";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useMemoOne } from "use-memo-one";
import { useChartDomainAsYYYY_MM_DD_ForFakeDates } from "../../../time-series/secondary-variable-view/use-chart-domain-as-yyyy-mm-dd";
import { useToggleFullscreen } from "../../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { useGetUseViewModeStore } from "../../../../shared-ui/time-series-2/grid-view-store";
import { ViewModeSelectors } from "../../../common/view-mode-selectors";
import {
  Atoms,
  ChartVariant,
  InitialTrendLine,
} from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useAtomValue } from "jotai";
import { minutesToMilliseconds } from "date-fns";

export function useOperatingLimitsProbabilityOrExceedanceVariablesQueryOnDetailsPage() {
  const useStore = useGetUseOlDetailsStore();
  const excludeLevels = useStore((s) => s.excludeLevels);
  const excludeTypes = useStore((s) => s.excludeTypes);
  const probabilityMode = useStore((s) => s.queryMode);

  const ds = useDateState();

  const group = useSelectedGroupOnOperatingLimitsPages();

  return useOperatingLimitsProbabilityOrExceedanceVariablesQuery(!!group, {
    excludeLevels,
    excludeTypes,
    mode: probabilityMode,
    groupId: group?._id ?? "query is disabled when no group is selected",
    start: ds.selectedDateStart,
    end: ds.axisRangeTo.dateString,
  });
}

// the charts we have to show that are not the searched one (manually added)
// this is dictated by the OL stuff
export function useNonSearchedVariables() {
  const useStore = useGetUseOlDetailsStore();

  const probabilityOrExceedanceVariablesQuery =
    useOperatingLimitsProbabilityOrExceedanceVariablesQueryOnDetailsPage();

  const data = probabilityOrExceedanceVariablesQuery.data?.variables;

  useEffect(() => {
    /**
     * if the user's actions causes the query to update, we
     * want to make sure to remove the searched variables if
     * that variable ends up being included because of limits.
     *
     * On this page, we show variables' charts due to limits,
     * or due to it being manually added.
     * */
    if (!data) return;

    useStore.setState((s) => {
      return {
        manuallySearchedVariableIds: differenceWith(
          // remove the searched variables that are now included because of limits
          s.manuallySearchedVariableIds,
          data,
          (searchedVariableId, variableFromQuery) => {
            return searchedVariableId === variableFromQuery._id;
          }
        ),
      };
    });
  }, [data, useStore]);

  return data;
}

export function ListOfCharts() {
  const useViewModeStore = useGetUseViewModeStore();
  const viewMode = useViewModeStore((s) => s.viewMode);
  const setViewMode = useViewModeStore((s) => s.setViewMode);
  const numCols = useViewModeStore((s) => s.numCols);
  const setNumCols = useViewModeStore((s) => s.setNumCols);
  const useStore = useGetUseOlDetailsStore();
  const singleCommentQuery = Comments.single.useQuery(
    useSelectedCommentIdParam()
  );

  const variablesWithLimits = useNonSearchedVariables();

  const searchedVariableIds = useStore((s) => s.manuallySearchedVariableIds);
  const searchedVariablesOrManuallyAddedVariables =
    useSearchedVariablesOnOperatingLimitsPages();

  /**
   * When there is a selected comment, ensure that the variable belonging
   * to that comment is shown on the page.
   *
   * This can happen if a user adds a chart to the page by searching for it,
   * then adding a comment. This means that the variable is not part of the
   * data returned by the Limits query, so we have to essentially replicate
   * the flow of adding a variable to the page by searching for it.
   */
  useEffect(() => {
    if (!singleCommentQuery.data || !variablesWithLimits) return; // wait for variablesWithLimits to load before we try to add a variable for not being present

    // @ts-ignore
    const forVariableId = singleCommentQuery.data.context?.variableId;

    if (!forVariableId) return; // no context, nothing we can do really

    const variableHasAChartOnPage =
      variablesWithLimits?.some((v) => v._id === forVariableId) ||
      searchedVariableIds.some((vid) => vid === forVariableId);

    if (variableHasAChartOnPage) return; // nothing to do, the DTC is shown

    // add the variable to the manually searched list because there is a selected comment for which there is no DTC
    useStore.getState().addSearchVariableId(forVariableId);
  }, [
    singleCommentQuery.data,
    useStore,
    searchedVariableIds,
    variablesWithLimits,
  ]);

  const dataLoadedAndNoVariables =
    variablesWithLimits &&
    variablesWithLimits.length === 0 &&
    searchedVariablesOrManuallyAddedVariables.length === 0;

  const allChartsToDisplay = useMemo(() => {
    return [
      ...searchedVariablesOrManuallyAddedVariables,
      ...(variablesWithLimits ?? []),
    ];
  }, [variablesWithLimits, searchedVariablesOrManuallyAddedVariables]);

  const numCharts = allChartsToDisplay.length;
  useEffect(() => {
    setNumCols(numCharts > 9 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts, setNumCols]);

  const isGridView = viewMode === "grid";

  const toggleFullscreen = useToggleFullscreen();

  const chartDims = chartDimensionsConfig(
    isGridView ? { isGridView: true, numCols } : { isGridView: false }
  );

  const currentView = useSlice(allChartsToDisplay);

  return (
    <div className="grow">
      {/* Header statistics */}
      <div className="px-8 flex flex-col max-w-full box-border pb-12">
        {/* Empty message */}
        {dataLoadedAndNoVariables && (
          <div className="flex flex-col justify-center items-center mt-24 w-full gap-4">
            <p className="flex mx-auto">No variables to display.</p>
            <p className="flex mx-auto">
              Select a different&nbsp;&nbsp;
              <strong>
                <i className="fa fa-calendar" aria-hidden="true"></i>
                &nbsp; Analysis Period
              </strong>
              &nbsp; or&nbsp;
              <strong>
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </strong>
              &nbsp; to add variables.
            </p>
          </div>
        )}

        {!dataLoadedAndNoVariables && (
          <ViewModeSelectors
            withLabels
            className="ml-auto"
            variant={"default"}
            enabledModes={["grid", "list"] as const}
            viewMode={viewMode}
            setViewMode={setViewMode}
            numCols={numCols}
            setNumCols={setNumCols}
          />
        )}

        {currentView.slice.length > 0 && (
          <ul
            className={cn(
              viewMode === "grid" && `grid grid-cols-${numCols || 3} gap-4`,
              "list-none py-2"
            )}
          >
            {viewMode !== "chart" || allChartsToDisplay.length === 1 ? (
              currentView.slice.map((variable) => (
                <li
                  className="OperatingLimitsDetails__variable bg-base-100 border border-bordgrey rounded-md overflow-visible"
                  key={variable._id}
                >
                  {/* Bar Chart(s), Static Chart(s), and Status Series */}
                  <DRASecondaryVariableViewProvider
                    initialBatchVariables={[
                      {
                        type: "variable",
                        bv: "0".repeat(24) + variable._id,
                      },
                    ]}
                    initialExpanded={false}
                    variant={ChartVariant.OperatingLimits}
                  >
                    <DRASecondaryVariableViewNeedsProvider
                      /**
                       * If you're in grid view, opening a comment will open the fullscreen
                       * view with the comment open.
                       */
                      commentsOnlyInFullscreen={isGridView}
                      respondToSelectedCommentId
                      ableToGetTaller
                      {...chartDims}
                      padding={{
                        left: 45,
                      }}
                      expandable
                      numTicks={undefined}
                      onlyMinMaxYAxes={isGridView}
                      onLineClick={iife(() => {
                        if (isGridView) {
                          return (
                            toggleFullscreen &&
                            (({ jotaiStore }) => toggleFullscreen(jotaiStore))
                          );
                        }
                        return undefined;
                      })}
                      closeAndDisableLimitStatusSeries={isGridView}
                    />
                  </DRASecondaryVariableViewProvider>
                </li>
              ))
            ) : (
              <DRASecondaryVariableViewProvider
                initialBatchVariables={
                  allChartsToDisplay.map((variable) => ({
                    type: "variable",
                    bv: "0".repeat(24) + variable._id,
                  })) as [InitialTrendLine, ...InitialTrendLine[]]
                }
                initialExpanded={false}
              >
                <DRASecondaryVariableViewNeedsProvider
                  commentsOnlyInFullscreen={false}
                  respondToSelectedCommentId
                  ableToGetTaller={false}
                  {...chartDims}
                  padding={{
                    left: 45,
                  }}
                  expandable
                  numTicks={undefined}
                  closeAndDisableLimitStatusSeries={false}
                />
              </DRASecondaryVariableViewProvider>
            )}
            <Dummy nextRef={currentView.next} />
          </ul>
        )}
      </div>
    </div>
  );
}

type NextFn = (() => void) | undefined;

function Dummy({ nextRef }: { nextRef: Readonly<MutableRefObject<NextFn>> }) {
  const inViewRef = useRef(false);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const elt = ref.current;
    if (!elt) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry) throw new Error("impossible");

        const inView = entry.isIntersecting;

        inViewRef.current = inView;

        inView && nextRef.current?.();
      },
      { threshold: 0.01 } // Adjust the threshold as needed
    );

    observer.observe(elt);

    return () => observer.disconnect();
  }, [nextRef]);

  /**
   * This takes care of when the div comes in view,
   * next() is called, but the next slice of elements isn't enough
   * to bring the div back out of view.
   *
   * Pretty much: call .next() until the div goes out of view
   */
  useEffect(() => {
    inViewRef.current && nextRef.current?.();
  });

  return <div ref={ref} className="h-[15px] w-full"></div>;
}

const SLICE_COUNT = 5;
function useSlice<T>(data: T[]) {
  const [n, setN] = useState(SLICE_COUNT);

  const slice = useMemo(
    () => (n >= data.length ? data : data.slice(0, n)),
    [n, data]
  );

  useEffect(() => {
    setN(SLICE_COUNT);
  }, [data]);

  const hasMore = n < data.length;

  const next: NextFn = hasMore
    ? () => setN((curr) => curr + SLICE_COUNT)
    : undefined;

  const updateRef = useRef<NextFn>(next);
  updateRef.current = next;

  return { slice, next: updateRef };
}

export const LimitsForChart = memo(function LimitsForChart({
  variable,
  onlyOneOpenAtATime,
  overrideOnClick,
  closedAndDisabled,
}: {
  variable: string;
  onlyOneOpenAtATime: boolean;
  overrideOnClick?: () => void;
  closedAndDisabled?: boolean;
}) {
  const variablesQuery = useVariablesArrayQuery();

  const variableObj = useMemoOne(() => {
    return variablesQuery.data?.find((x) => x._id === variable);
  }, [variablesQuery.data, variable]);

  const inViewport = useAtomValue(Atoms.chartInViewportAtom);

  const limitsQuery = useOperatingLimitsQuery(variable, {
    enabled: inViewport,
    staleTime: minutesToMilliseconds(5),
  });
  const data = limitsQuery.data;

  const [expanded, setExpanded] = useState(new Set<string>());

  if (!data || !variableObj || !data.length) return null;

  return (
    <ul className="list-none bg-white">
      {data.map((limit_) => {
        return (
          <SingleLimitForChart
            overrideOnClick={overrideOnClick}
            expanded={closedAndDisabled ? false : expanded.has(limit_._id)}
            setExpanded={
              closedAndDisabled
                ? undefined
                : (newExpanded) => {
                    if (newExpanded) {
                      if (onlyOneOpenAtATime) {
                        setExpanded(new Set([limit_._id]));
                        return;
                      } else {
                        setExpanded((prev) => {
                          const copy = new Set(prev);
                          copy.add(limit_._id);
                          return copy;
                        });
                        return;
                      }
                    }
                    setExpanded((prev) => {
                      const copy = new Set(prev);
                      copy.delete(limit_._id);
                      return copy;
                    });
                  }
            }
            limit={limit_}
            key={limit_._id}
            variable={variableObj}
          />
        );
      })}
    </ul>
  );
});

function SingleLimitForChart({
  limit,
  variable,
  expanded,
  setExpanded,
  overrideOnClick,
}: {
  limit: Pick<operatingLimitSchema, "_id" | "level" | "type">;
  variable: variableSchema;
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
  overrideOnClick?: () => void;
}) {
  const [start, end] = useChartDomainAsYYYY_MM_DD_ForFakeDates();
  const [selectedView, setSelectedView] =
    useState<(typeof VIEW_ORDER)[number]>(DEFAULT_VIEW);

  return (
    <li className={"OperatingLimitsDetails__li OperatingLimitsDetails__level"}>
      <button
        className={cn(
          "OperatingLimitsDetails__clickable",
          "OperatingLimitsDetails__StatusSeries__item",
          expanded && "OperatingLimitsDetails__StatusSeries__item--active"
        )}
        onClick={() =>
          overrideOnClick ? overrideOnClick() : setExpanded?.(!expanded)
        }
      >
        <div className="OperatingLimitsDetails__StatusSeries__label">
          {limit.level.toUpperCase()}
        </div>
        <StatusSeries2
          limit={limit}
          drawStyle={"square"}
          start={start}
          end={end}
        />
        <ProbabilityToCrossPercentage limit={limit} start={start} end={end} />
      </button>

      {expanded && (
        <div
          className={cn(
            "OperatingLimitsDetails__variable__details OperatingLimitsDetails__variable__details--animated OperatingLimitsDetails__variable__details--expanded"
          )}
        >
          <div className="btn-group btn-group-sm float-right mr-4 mt-2">
            {VIEW_ORDER.map((view) => {
              let labelOrFn = VIEW_LABELS[view] as
                | (typeof VIEW_LABELS)[keyof typeof VIEW_LABELS]
                | string;

              if (typeof labelOrFn === "function") {
                labelOrFn = labelOrFn(limit);
              }

              const isSelected = selectedView === view;

              return (
                <Button
                  key={view}
                  onClick={isSelected ? undefined : () => setSelectedView(view)}
                  size="xs"
                  className={cn(
                    isSelected
                      ? "bg-neutral text-neutral-content"
                      : "bg-base-100 text-neutral hover:bg-neutral hover:text-neutral-content"
                  )}
                >
                  {labelOrFn}
                </Button>
              );
            })}
          </div>
          {iife(() => {
            switch (selectedView) {
              case "insights":
                return (
                  <OperatingLimitsInsightCard
                    variable={variable}
                    limit={limit}
                  />
                );
              case "time":
                return <OperatingLimitsTimeCard id={limit._id} />;
              case "frequency":
                return <OperatingLimitsFrequencyCard id={limit._id} />;
              case "extremum":
                return (
                  <OperatingLimitsExtremumCard
                    id={limit._id}
                    instanceref={limit}
                  />
                );
              case "probability":
                return <PredictiveProbabilityCard limit={limit} />;
              default:
                const _: never = selectedView;
                throw new Error("unhandled");
            }
          })}
        </div>
      )}
    </li>
  );
}
