import React from "react";
import type EditorProps from "./EditorProps";
import { type List as ListType } from "../types/BuilderTypes";
import { Alert, Tooltip } from "@mantine/core";
import Button from "../../../common/Button/Button";
import { removeFromArrayByIndex, swapTwoItemsInArray } from "../utils";
import { listContentTemplate } from "../templates";
import _ from "lodash";
import classNames from "classnames";
import Input from "../../../common/Input/Input";
import MarginInputs from "./MarginInputs";

function List({ content, editContent }: EditorProps<ListType>) {
  const { bullets, content: listContent } = content;

  function generateListItemError(
    value: string,
    marginLeft?: string,
    customIndex?: string
  ) {
    if (!value.trim()) {
      return " List element text is required";
    }
    if (!marginLeft) {
      return "Margin left indentation is required";
    }

    const p = parseInt(marginLeft);

    if (isNaN(p)) {
      return "Margin left is not a number";
    }

    if (!bullets) {
      if (!customIndex || !customIndex.trim()) {
        return "Set custom index to 0 if not used";
      }

      const i = parseInt(customIndex);

      if (isNaN(i)) {
        return "Custom index is not a number";
      }
    }
  }

  return (
    <div>
      <div className="flex items-center gap-2 opacity-80 m-2">
        Numbered list
        <input
          checked={!!bullets}
          className="toggle toggle-sm toggle-primary"
          type="checkbox"
          onChange={(e) => {
            const isBulleted = e.target.checked;
            editContent((c) => {
              c.bullets = isBulleted;
              _.forEach(c.content, (cont) => {
                if (isBulleted) {
                  delete cont.customIndex;
                } else {
                  cont.customIndex = "0";
                }
              });
            });
          }}
        />
        Bulleted list
      </div>
      <MarginInputs editContent={editContent} content={content} />
      <div className="flex flex-col gap-2">
        {listContent.map(({ value, id, marginLeft, customIndex }, idx, arr) => {
          const isFirst = idx === 0;
          const isLast = idx == arr.length - 1;

          const err = generateListItemError(value, marginLeft, customIndex);
          const errorComponent = err && (
            <Alert title="Error" color="red" variant="filled">
              {err}
            </Alert>
          );

          const canDelete = listContent.length !== 1;

          return (
            <React.Fragment key={id}>
              {errorComponent ? <div>{errorComponent}</div> : null}
              <div className="flex gap-2">
                <textarea
                  required
                  className={classNames("textarea textarea-bordered grow", {
                    "border border-red-400": !!errorComponent,
                  })}
                  placeholder="Bio"
                  value={value}
                  onChange={(e) => {
                    editContent((c) => {
                      const c_ = c.content[idx];
                      if (!c_) throw new Error("shouldnt happen");
                      c_.value = e.target.value;
                    });
                  }}
                />
                <label>
                  Margin Left
                  <Input
                    size="sm"
                    bordered
                    value={marginLeft}
                    onChange={(e) => {
                      editContent((c) => {
                        const c_ = c.content[idx];
                        if (!c_) throw new Error("shouldnt happen");
                        c_.marginLeft = e.target.value;
                      });
                    }}
                  />
                </label>
                {!bullets ? (
                  <label>
                    Custom Index
                    <Input
                      size="sm"
                      bordered
                      value={customIndex}
                      onChange={(e) => {
                        editContent((c) => {
                          const c_ = c.content[idx];
                          if (!c_) throw new Error("shouldnt happen");
                          c_.customIndex = e.target.value;
                        });
                      }}
                    />
                  </label>
                ) : null}

                {canDelete ? (
                  <>
                    <Tooltip withArrow label="Delete this list item">
                      <Button
                        icon="trash-o"
                        className="btn-ghost"
                        size="xs"
                        onClick={() => {
                          editContent((c) => {
                            removeFromArrayByIndex(c.content, idx);
                          });
                        }}
                      />
                    </Tooltip>
                    {!isFirst ? (
                      <Tooltip withArrow label="Move this list up">
                        <Button
                          icon="arrow-up"
                          className="btn-ghost"
                          size="xs"
                          onClick={() => {
                            editContent((c) => {
                              swapTwoItemsInArray(idx, idx - 1, c.content);
                            });
                          }}
                        />
                      </Tooltip>
                    ) : null}
                    {!isLast ? (
                      <Tooltip withArrow label="Move this list down">
                        <Button
                          icon="arrow-down"
                          className="btn-ghost"
                          size="xs"
                          onClick={() => {
                            editContent((c) => {
                              swapTwoItemsInArray(idx, idx + 1, c.content);
                            });
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </>
                ) : null}
              </div>
            </React.Fragment>
          );
        })}
        <div className="flex">
          <Button
            className="btn-outline ml-auto"
            onClick={() => {
              editContent((c) => {
                c.content.push(listContentTemplate());
              });
            }}
            size="xs"
          >
            Add List Item
          </Button>
        </div>
      </div>
    </div>
  );
}

export default List;
