import Select from "../../common/Select/Select";
import Button from "../../common/Button/Button";
import { Tooltip } from "@mantine/core";
import { useState } from "react";
import { useGetUseAckManStore } from "./store/ack-man-store";
import {
  triggerAcknowledgementsEmail,
  type AckEmailDownloadType,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useMutation } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import useMaxDate from "../../common/hooks/useMaxDate";
import moment from "moment";
import { YYYY_MM_DD } from "../../../lib/validators";
import { AnomalyValidator } from "../../../types/api/Anomaly";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { BiLoaderAlt } from "react-icons/bi";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";

type DEFAULT_DOWNLOAD_TYPE = "";
type SelectableValue = AckEmailDownloadType | DEFAULT_DOWNLOAD_TYPE;

const downloadTypeToLabel: Record<
  AckEmailDownloadType | DEFAULT_DOWNLOAD_TYPE,
  string
> = {
  summary: "Acknowledgment Summary",
  list: "List of Anomaly Tags",
  top: "Top Acknowledgers",
  "": "Email Me:",
};

export function EmailMe() {
  const variablesQuery = useVariablesArrayQuery();
  const baseUrlSlash = useBaseUrlExperimental();
  const maxDate = useMaxDate();
  const useAckManStore = useGetUseAckManStore();
  const date = useAckManStore((s) => s.date) ?? maxDate;
  const selectedGroupId = useAckManStore((s) => s.selectedGroupId);
  const onlyActiveFaultTreeNodes = useAckManStore(
    (s) => s.onlyActiveFaultTreeNodes
  );
  const onlyFresh = useAckManStore((s) => s.fresh);

  const mutation = useMutation({
    mutationFn: (t: AckEmailDownloadType) =>
      triggerAcknowledgementsEmail(baseUrlSlash, {
        downloadType: t,
        date: YYYY_MM_DD.parse(moment(date).format("YYYY-MM-DD")),
        groupId: selectedGroupId ?? "",
        onlyFresh,
        onlyActiveFaultTreeNodes,
        levels: Object.entries(useAckManStore.getState().checkedMap)
          .filter(
            ([level, checked]) =>
              checked && AnomalyValidator.safeParse(parseInt(level)).success
          )
          .map(([level]) => AnomalyValidator.parse(parseInt(level))),
        filterVariableIds: variablesQuery.data?.map((v) => v._id) ?? [],
      }),
  });

  const [downloadTypeSelectValue, setDownloadTypeSelectValue] =
    useState<SelectableValue>("");

  const options: (AckEmailDownloadType | DEFAULT_DOWNLOAD_TYPE)[] = [
    "",
    "summary",
    "list",
    "top",
  ];

  const defaultValueIsSelected = downloadTypeSelectValue === "";

  return (
    <>
      <Select
        className="max-w-[17%] border-bdgrey font-normal"
        bordered={true}
        options={options.map((value) => ({
          value,
          label: downloadTypeToLabel[value],
        }))}
        value={downloadTypeSelectValue}
        onChange={({ target: { value } }) =>
          setDownloadTypeSelectValue(value as SelectableValue)
        }
      />
      {mutation.isLoading ? (
        <BiLoaderAlt className="animate-spin-slow w-40" />
      ) : (
        <Tooltip label="Send email" withArrow>
          <Button
            className="btn-ghost"
            icon={"envelope"}
            disabled={
              !selectedGroupId || !variablesQuery.data || defaultValueIsSelected
            }
            onClick={() => {
              if (defaultValueIsSelected) return;
              mutation
                .mutateAsync(downloadTypeSelectValue)
                .then(() =>
                  addSuccessToast("An email with your download has been sent.")
                )
                .catch((e) => addUnknownErrorToast(e))
                .finally(() => mutation.reset());
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
