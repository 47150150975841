import { cn } from "../lib/utils";

import * as React from "react";
import { PropsWithCn } from "./types/component.types";
import { MultiSelect as MantineMultiSelect } from "@mantine/core";
import {
  useClustersQuery,
  useGroupsQuery,
  useVariablesArrayQuery,
} from "../hooks/tanstack-query";
import { OVERALL_GROUP_NAME } from "../lib/api-schema/misc";
import Select from "./common/Select/Select";
import { Label } from "../shared-ui/frontend/label";
import { Button } from "../shared-ui/frontend/button";
import { Input } from "../shared-ui/frontend/input";
import { MultiSelect } from "../shared-ui/frontend/multi-select";

export function TogglerWithLabel({
  className,
  label,
  ...rest
}: { label: string } & React.ComponentProps<"input">) {
  const { type, ...rest2 } = rest;
  return (
    <div className={cn("items-center flex", className)}>
      <input
        {...rest2}
        type="checkbox"
        className="toggle checked:border-primary checked:bg-primary"
      />
      <span className="ml-4">{label}</span>
    </div>
  );
}

export function InputWithLabel({
  label,
  id,
  className,
  ...rest
}: React.ComponentProps<typeof Input> & { label: string }) {
  if (!id) throw new Error("id is required");

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        className={cn("hover:border-zinc-400 mb-1", className)}
        {...rest}
      />
    </>
  );
}

export function SelectWithLabel({
  id,
  label,
  ...rest
}: React.ComponentProps<typeof Select> & {
  label: string;
  id: string;
}) {
  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <Select id={id} size="md" className="mb-1" {...rest} />
    </>
  );
}

export function FormContainer({
  className,
  ...rest
}: React.ComponentProps<"form">) {
  return (
    <form
      className={cn(
        "bg-white rounded-md p-4 border border-slate-300 hover:border-zinc-400 flex flex-col gap-1 relative animate-in slide-in-from-bottom-4 fade-in-20 my-3",
        className
      )}
      {...rest}
    />
  );
}

export function CancelSaveFooter({
  close,
  submit,
  className,
}: PropsWithCn<{
  close?: React.ComponentProps<typeof Button>;
  submit?: React.ComponentProps<typeof Button>;
}>) {
  return (
    <div className={cn("flex justify-between", className)}>
      {close && (
        <Button
          type="button"
          {...close}
          size={"sm"}
          variant={"destructive"}
          className={cn(close.className)}
        >
          Cancel
        </Button>
      )}

      {submit && (
        <Button
          {...submit}
          size={"sm"}
          variant="default"
          type="submit"
          className={cn(submit.className)}
        >
          Save
        </Button>
      )}
    </div>
  );
}

export function ClusterMultiSelect({
  id = "clusters",
  placeholder = "Select clusters",
  disabled,
  ...rest
}: Pick<
  React.ComponentProps<typeof MultiSelectWithLabel>,
  | "id"
  | "disabled"
  | "clearable"
  | "creatable"
  | "searchable"
  | "value"
  | "onChange"
  | "maxDropdownHeight"
  | "placeholder"
>) {
  const clustersQuery = useClustersQuery();

  return (
    <MultiSelectWithLabel
      {...rest}
      label="Clusters"
      placeholder={placeholder}
      disabled={clustersQuery.isLoading || disabled}
      data={
        clustersQuery.data
          ?.filter((c) => c.type === "static")
          .map((g) => ({
            value: g._id,
            label: g.name,
          }))
          .sort((a, b) => {
            if (a.label === OVERALL_GROUP_NAME) return -1;
            if (b.label === OVERALL_GROUP_NAME) return 1;
            return 0; // as is
          }) || []
      }
    />
  );
}

export function GroupMultiSelect({
  id = "groups",
  placeholder = "Select groups",
  disabled,
  ...rest
}: Pick<
  React.ComponentProps<typeof MultiSelectWithLabel>,
  | "id"
  | "disabled"
  | "clearable"
  | "creatable"
  | "searchable"
  | "value"
  | "onChange"
  | "maxDropdownHeight"
  | "placeholder"
>) {
  const groupsQuery = useGroupsQuery();

  return (
    <div className="mb-1">
      <MultiSelectWithLabel
        {...rest}
        label="Groups"
        placeholder={placeholder}
        disabled={groupsQuery.isLoading || disabled}
        data={
          groupsQuery.data
            ?.map((g) => ({
              value: g._id,
              label: g.name,
            }))
            .sort((a, b) => {
              if (a.label === OVERALL_GROUP_NAME) return -1;
              if (b.label === OVERALL_GROUP_NAME) return 1;
              return 0; // as is
            }) || []
        }
      />
    </div>
  );
}

export function TagsMultiSelect({
  id = "tags",
  placeholder = "Select tags",
  disabled,
  onChange,
  value,
  noPortal,
}: React.ComponentProps<typeof GroupMultiSelect> & {
  noPortal?: true;
}) {
  const variablesQuery = useVariablesArrayQuery();
  const myId = id + "-multi-select";

  return (
    <>
      <Label htmlFor={myId}>Tags</Label>
      <MultiSelect
        noPortal={noPortal}
        id={myId}
        placeholder={placeholder}
        isDisabled={variablesQuery.isLoading || disabled}
        value={variablesQuery.data
          ?.filter((x) => value?.includes(x._id))
          .map((x) => {
            return {
              value: x._id,
              label: x.trimmedName,
            };
          })}
        onChange={(values) => onChange?.(values.map((x) => x.value))}
        options={
          variablesQuery.data
            ?.filter((v) => v.type === "Tag")
            .map((variable) => ({
              value: variable._id,
              label:
                variable.trimmedName +
                (variable.description ? ` | ${variable.description}` : ""),
            })) || []
        }
      />
    </>
  );
}

export function MultiSelectWithLabel({
  id,
  clearable = false,
  creatable = false,
  searchable = true,
  maxDropdownHeight = 370,
  label,
  ...rest
}: { label: string } & Pick<
  React.ComponentProps<typeof MantineMultiSelect>,
  | "id"
  | "disabled"
  | "clearable"
  | "creatable"
  | "searchable"
  | "value"
  | "onChange"
  | "maxDropdownHeight"
  | "placeholder"
  | "data"
>) {
  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <MantineMultiSelect
        {...rest}
        maxDropdownHeight={maxDropdownHeight}
        dropdownPosition="flip"
        clearSearchOnChange={false}
        id={id}
        classNames={{
          input: "rounded-md p-[0.2em]",
          value: "bg-zinc-300",
          dropdown: "border-2 border-bordgrey2",
        }}
        clearable={clearable}
        creatable={creatable}
        searchable={searchable}
      />
    </>
  );
}
