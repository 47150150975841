import React, { useEffect, useRef } from "react";
import * as R from "remeda";
import {
  useGetUseVariabilityDrawerStore,
  useMin1Groups,
} from "./variability-drawer";
import { useVariabilityDataQueries } from "./use-variability-query";

import { useOperatingLimitsQuery } from "../../hooks/tanstack-query";
import { useVariabilityTooltipStore } from "./tooltip/use-variability-tooltip-store";
import { setUpData } from "./utils/chartUtils";
import { select } from "d3";
import { drawHoverLineAndApplyHoverCallbacks } from "./utils/hoverUtils";
import { drawBoxPlot } from "./draw/drawBoxPlot";
import { createTooltipLine } from "./utils/drawUtils";
import { useCapabilityStore } from "./capability-store";
import { cn } from "../../lib/utils";

export default function WhiskerBoxPlot({
  className,
  initialDims,
  aspect,
  axesFontScale,
  onClick,
}: {
  className: string;
  initialDims: { width: number; height: number; margin: number };
  aspect?: {
    height: number;
    width: number;
  };
  axesFontScale?: number;
  onClick?: () => void;
}) {
  const useStore = useGetUseVariabilityDrawerStore();
  const isRelative = useStore((s) => s.view === "relative");
  const showLimitLines = useStore((s) => s.showLimitLines);

  const groups = useMin1Groups();
  const variabilityQueries = useVariabilityDataQueries(groups);
  const limitsQuery = useOperatingLimitsQuery(groups[0].variables[0]._id);

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const capabilityData = useCapabilityStore((state) => state.data);

  const shouldShowLoading = () => {
    return (
      limitsQuery.isLoading || variabilityQueries.some((item) => item.isLoading)
    );
  };

  const loading = shouldShowLoading();

  useEffect(() => {
    if (!svgRef.current || loading) return;

    const data = variabilityQueries.map((query) => {
      if (!query.data || query.isLoading || query.isLoadingError)
        throw new Error("no data");
      return query.data;
    });

    const formattedData = setUpData(data, groups, capabilityData);

    if (formattedData === undefined) throw new Error("Error formatting data");

    const selectedVariableRef = formattedData.find((v) => v.selected)?.ref;
    const hoveredVariableRef = formattedData.find((v) => v.hovered)?.ref;

    // the user has to have it toggled on and it must be a single variable
    const shouldDrawLimits =
      showLimitLines &&
      R.pipe(
        groups,
        R.flatMap((g) => g.variables.map((v) => v._id)),
        R.uniq(),
        (arr) => arr.length === 1
      );

    const boxSvgSelector = select(svgRef.current);

    const dimensions = {
      height:
        aspect?.height ??
        (containerRef.current
          ? containerRef.current.offsetHeight
          : initialDims.height),
      width:
        aspect?.width ??
        (containerRef.current
          ? containerRef.current.offsetWidth
          : initialDims.width),
      margin: initialDims.margin,
    };

    const hoverFns = {
      onHover(svgX: number, windowX: number, variableId: string) {
        if (containerRef.current) {
          if (selectedVariableRef) {
            useVariabilityTooltipStore.getState().setData({
              svgX:
                (svgX / dimensions.width) * containerRef.current.offsetWidth,
              windowX,
              variableId: selectedVariableRef,
              graph: containerRef.current,
            });
          } else {
            useVariabilityTooltipStore.getState().setData({
              svgX:
                (svgX / dimensions.width) * containerRef.current.offsetWidth,
              windowX,
              variableId: variableId,
              graph: containerRef.current,
            });

            if (hoveredVariableRef !== variableId)
              useStore.getState().toggleHoverVariable(variableId);
          }
        }
      },
      reset() {
        useVariabilityTooltipStore.getState().reset();
        useStore.getState().toggleHoverVariable(undefined);
      },
    };

    // now draw whisker boxplot
    drawBoxPlot({
      svgSelector: boxSvgSelector,
      dimensions,
      data: formattedData as [
        (typeof formattedData)[number],
        ...typeof formattedData,
      ],
      limits: shouldDrawLimits ? limitsQuery.data : undefined,
      hover: hoverFns,
      options: { relative: isRelative },
      axesFontScale,
    });

    createTooltipLine(boxSvgSelector, dimensions);
    drawHoverLineAndApplyHoverCallbacks(boxSvgSelector, dimensions);
  }, [
    aspect?.height,
    aspect?.width,
    svgRef,
    isRelative,
    useStore,
    variabilityQueries,
    limitsQuery.data,
    showLimitLines,
    groups,
    capabilityData,
    axesFontScale,
    initialDims.height,
    initialDims.width,
    initialDims.margin,
    loading,
  ]);

  return (
    <div ref={containerRef} id="box-plot" className={className}>
      <svg
        onClick={onClick}
        ref={svgRef}
        className={cn(onClick && "cursor-pointer")}
        id="box-plot-svg"
      />
    </div>
  );
}
