import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Notification, {
  NotificationsAvailable,
  NotificationType,
  CustomNotification,
} from "../../../types/api/Notification";
import useAPI from "../../../lib/useAPI";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { patchNotification_TODO_TYPE_THIS } from "../../../frameworks/fetcher/api-routes-experimental";

/* Fetch */
export default function useNotificationsQuery() {
  const API = useAPI();
  return useQuery<Notification[]>(["notifications"], async () => {
    const notificationsCall = API.getUserNotifications() as unknown as Promise<
      Notification[]
    >;
    const notifications = await notificationsCall;

    return notifications.map((notification) => {
      if (
        notification.type === NotificationType.CUSTOM &&
        notification.variable
      ) {
        return {
          ...notification,
          customExpression: notification.variable.expression,
        };
      }
      return notification;
    });
  });
}

export function useNotificationQuery(id: string) {
  const query = useNotificationsQuery();

  return {
    ...query,
    data: query.data?.find((notification) => notification._id === id),
  };
}

export function useNotificationsAvailableQuery({
  notificationTypes,
  variableId,
  operatingLimitId,
  groupId,
}: {
  notificationTypes: string[];
  variableId?: string;
  operatingLimitId?: string;
  groupId?: string;
}) {
  const API = useAPI();

  return useQuery({
    queryKey: ["notifications", "available", variableId, notificationTypes],
    queryFn: () => {
      return API.getNotifications(
        notificationTypes,
        variableId,
        operatingLimitId,
        groupId
      ) as unknown as Promise<NotificationsAvailable>;
    },
  });
}

export function useNotificationPostMutation() {
  const API = useAPI();
  const queryClient = useQueryClient();

  /* Should this have a unique key? */
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (notification: any) => {
      const response = (await API.createNotification(
        notification
      )) as unknown as Notification;

      if (response.type === NotificationType.CUSTOM && response.variable) {
        (response as CustomNotification).customExpression =
          response.variable.expression || "";
      }

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });
}

export function useNotificationPatchMutation(
  id: string,
  o?: { onSuccess?: () => void; onError?: (error: unknown) => void }
) {
  const queryClient = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: (notification: any) => {
      return patchNotification_TODO_TYPE_THIS(
        baseUrl,
        id,
        notification
      ) as Promise<Notification>;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      o?.onSuccess?.();
    },
    onError: (e) => {
      o?.onError?.(e);
    },
  });
}

export function useNotificationDeleteMutation(id: string) {
  const API = useAPI();
  const queryClient = useQueryClient();

  return useMutation<void, { msg: string }[]>({
    mutationKey: ["notifications", "remove", id],
    mutationFn: () => API.deleteNotification(id) as unknown as Promise<void>,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });
}

export function useNotificationSubscribeMutation({
  notificationType,
  variableId,
  operatingLimitId,
  value,
}: {
  notificationType: NotificationType;
  variableId: string;
  operatingLimitId?: string;
  value: number;
}) {
  const API = useAPI();
  const queryClient = useQueryClient();

  return useMutation<Notification, { msg: string }[]>({
    mutationKey: [
      "notifications",
      "subscribe",
      notificationType,
      variableId,
      operatingLimitId,
      value,
    ],
    mutationFn: () =>
      API.subscribeNotification(
        notificationType,
        value,
        variableId,
        operatingLimitId
      ) as unknown as Promise<Notification>,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });
}

export function useNotificationToggleMutation(id: string) {
  const API = useAPI();
  const queryClient = useQueryClient();

  return useMutation<void, { msg: string }[]>({
    mutationKey: ["notifications", "toggle", id],

    /* TODO: jump ship from useAPI to get rid of these errors */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: () => API.toggleNotification(id) as any,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });
}
