import { z } from "zod";
import { AnomalyValidator } from "../../../types/api/Anomaly";

export const faultTreeNodeStatusSchema = z.object({
  anomalyLevel: AnomalyValidator,
  error: z.any(),
  processing: z.boolean(),
  status: z.number().int(),
});

export type faultTreeNodeStatusSchema = z.infer<
  typeof faultTreeNodeStatusSchema
>;
