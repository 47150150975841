import React, { type PropsWithChildren } from "react";

function MainWrapper({ children }: PropsWithChildren) {
  return (
    <div id="main-wrapper" className="drawer drawer-end bg-base-100 DRAWER">
      {children}
    </div>
  );
}

export default MainWrapper;
