export const DEFAULT_HEADERS = {
  "Cache-Control": "no-cache, must-revalidate",
  Pragma: "no-cache",
  "content-type": "application/json; charset=utf-8",
  accept: "application/json",
};

export type MyResponse = Omit<
  Response,
  | "body" // we don't need a few of the properties
  | "arrayBuffer"
  | "clone"
  | "bodyUsed"
  | "blob"
  | "formData"
  | "json"
  | "text"
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export type Method = "GET" | "PATCH" | "POST" | "DELETE";

export type Header = {
  Authorization?: string;
} & typeof DEFAULT_HEADERS;
