import React from "react";
import { createRoot } from "react-dom/client";
import { ModalProvider } from "./components/common/useModal";
import "./styles/index.css";
import "./styles/font-awesome.css";
import "react-dates/lib/css/_datepicker.css";
import "./styles/charts.css";
import "./styles/chart-grid.css";
import "react-day-picker/dist/style.css";
import "./styles/styling.scss";
import "./lib/polyfills";
import "./styles/sidebar.scss";

import "@fontsource/open-sans/variable.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/roboto-mono";

import App from "./App";
import { IconContext } from "react-icons";
import AppDataBoundary from "./components/boundaries/AppDataBoundary";
import { MantineProvider } from "@mantine/core";
import { TanstackProvider } from "./tanstack-provider";
import { ToastProvider, ToastViewport } from "./components/ui/toast";
import { AllToasts } from "./components/toast/all-toasts";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "./hooks/use-theme";
import { TooltipProvider } from "./shared-ui/frontend/tooltip";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <MantineProvider theme={{ fontFamily: "Open Sans" }}>
        <ModalProvider>
          {/* use this classname and CSS to apply custom styles to all icons in react-icons package  */}
          <IconContext.Provider value={{ className: "REACT_ICON" }}>
            <TanstackProvider>
              <ReactQueryDevtools />
              <AppDataBoundary>
                <ToastProvider duration={3000}>
                  <TooltipProvider delayDuration={50}>
                    <App />
                  </TooltipProvider>
                  <ToastViewport className="flex flex-col gap-2 px-4" />
                  <AllToasts />
                </ToastProvider>
              </AppDataBoundary>
            </TanstackProvider>
          </IconContext.Provider>
        </ModalProvider>
      </MantineProvider>
    </ThemeProvider>
  </React.StrictMode>
);
