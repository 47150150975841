import { FullscreenDrawer } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { Provider } from "jotai";
import { AriaSecondaryVariableViewNeedsProvider } from "../time-series/secondary-variable-view/aria-secondary-variable.view";
import { FullscreenChartContainer } from "../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { useShowClickedLine } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { FULLSCREEN_PROPS } from "../pb/profile-book";

export default function Fullscreen() {
  const showClickedLine = useShowClickedLine();
  return (
    <FullscreenDrawer>
      {(store) => (
        <FullscreenChartContainer>
          <Provider store={store}>
            <AriaSecondaryVariableViewNeedsProvider
              onLineClick={({ bvOrId, clientX, clientY, jotaiStore }) => {
                showClickedLine({
                  clientX,
                  clientY,
                  jotaiStore,
                  bvOrId,
                });
              }}
              ableToGetTaller={false}
              {...FULLSCREEN_PROPS()}
            />
          </Provider>
        </FullscreenChartContainer>
      )}
    </FullscreenDrawer>
  );
}
