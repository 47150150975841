import { Badge } from "../../shared-ui/frontend/badge";
import Comment from "../../types/api/Comment";

export function CommentTypeBadge({ comment }: { comment: Comment }) {
  if (comment.private) {
    return (
      <Badge size={"xs"} variant={"amber"}>
        My Note
      </Badge>
    );
  }

  const isIssue = comment.type === "Issue";

  if (isIssue && !comment.issue_resolved) {
    return (
      <Badge size={"xs"} variant={"danger"}>
        Open Issue
      </Badge>
    );
  }

  if (!comment.context) return null;

  switch (comment.context.type) {
    case "FaultTreeNode":
      return (
        <Badge size={"xs"} variant={"violet"}>
          Fault Trees
        </Badge>
      );
    case "OperatingLimit":
      return (
        <Badge size={"xs"} variant={"blue"}>
          Operating Fitness
        </Badge>
      );
    default:
      const _: undefined = comment.context.type; // Ensure all cases are handled

      const isSt = comment.context.chartContext.showSlopingTrends;

      if (isSt)
        return (
          <Badge size={"xs"} variant={"success"}>
            Sloping Trends
          </Badge>
        );

      return null;
  }
}
