import {
  type PropsWithChildren,
  createContext,
  useRef,
  useContext,
  useEffect,
} from "react";
import { create } from "zustand";
import { CacheData } from "./types";
import { variableSchema } from "../../../lib/api-validators";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import * as R from "remeda";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  getClusters,
  getVariables,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { AnomalyLevelEnum } from "../../../types/api/Anomaly";
import { DriView, views } from "../constants";
import { produce } from "immer";
import useDRAParams2 from "../../boundaries/hooks/useDRAParams2";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { subscribeWithSelector } from "zustand/middleware";

export type ClustersState = Record<string, clusterSchema>;
export type VariablesState = Record<string, variableSchema>;

export type DriStore = {
  clusters: ClustersState | undefined;
  variables: VariablesState | undefined;
  anomalies: CacheData | undefined;
  viewMode: DriView;
  setViewMode: (view: DriView) => void;

  showAnomalies: boolean;
  setShowAnomalies: (show: boolean) => void;
  globalShutdownToggle: boolean;
  setGlobalShutdownToggle: (_: boolean) => void;
  globalModeTransparencyToggle: boolean;
  setGlobalModeTransparencyToggle: (_: boolean) => void;

  showAllMode: boolean;

  cache: Record<string, Record<string, CacheData>>;
  clearCache: (dateString: string | null, gid: string) => void;
  getCache: (dateString: string, gid: string) => CacheData | undefined;
  setCache: (dateString: string, gid: string, data: CacheData) => void;

  loading: boolean;

  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;

  listOpen: {
    watch: boolean;
  } & Record<AnomalyLevelEnum, boolean>;

  reset: () => void;
};

function getViewPreference(): DriView {
  const view = localStorage.getItem("dri-view");

  const parsed = DriView.safeParse(view);

  if (parsed.success) return parsed.data;
  return views.plain;
}

function setViewPreference(view: (typeof views)[keyof typeof views]) {
  localStorage.setItem("dri-view", view);
}

export function createUseDriStore() {
  const useDriStore = create<DriStore>()(
    subscribeWithSelector((set, get) => {
      const reset = (): Omit<DriStore, "reset"> => {
        return {
          showAnomalies: true,
          setShowAnomalies: (show) => {
            set({ showAnomalies: show });
          },
          globalShutdownToggle: false,
          setGlobalShutdownToggle: (globalShutdownToggle) => {
            set({ globalShutdownToggle });
          },
          globalModeTransparencyToggle: true,
          setGlobalModeTransparencyToggle: (globalModeTransparencyToggle) => {
            set({ globalModeTransparencyToggle });
          },
          loading: true,
          sidebarOpen: true,
          setSidebarOpen: (open) => {
            set({ sidebarOpen: open });
          },
          clusters: undefined,
          variables: undefined,
          anomalies: undefined,
          viewMode: getViewPreference(),
          setViewMode(view) {
            setViewPreference(view);
            set({ viewMode: view });
          },
          showAllMode: false,
          listOpen: {
            watch: true,
            0: true,
            1: true,
            2: true,
            3: true,
          },

          cache: {},
          clearCache: (dateString, gid) => {
            const cache = get().cache;

            if (dateString) {
              if (gid) {
                const daysData = cache[dateString];

                if (daysData) {
                  set((curr) => {
                    return {
                      cache: produce(curr.cache, (cache) => {
                        const dataForThisDate = cache[dateString];
                        if (!dataForThisDate) return;

                        delete dataForThisDate[gid];
                      }),
                    };
                  });
                }
              } else {
                // clear for this date
                set({
                  cache: produce(cache, (curr) => {
                    delete curr[dateString];
                  }),
                });
              }
            } else {
              // clear for all dates
              if (!gid) throw new Error("unhandled");

              set({
                cache: produce(cache, (curr) => {
                  R.forEachObj(curr, (groupIdsToDataObject) => {
                    delete groupIdsToDataObject[gid];
                  });
                }),
              });
            }
          },
          getCache: (dateString, gid) => get().cache[dateString]?.[gid],
          setCache: (dateString, gid, data) => {
            set((curr) => {
              return {
                cache: produce(curr.cache, (cache) => {
                  const dataForThisDate = cache[dateString];
                  if (!dataForThisDate) {
                    cache[dateString] = {
                      [gid]: data,
                    };
                  } else {
                    dataForThisDate[gid] = data;
                  }
                }),
              };
            });
          },
        };
      };

      return {
        ...reset(),
        reset: () => {
          set(reset());
        },
      };
    })
  );

  return useDriStore;
}

export type UseDriStore = ReturnType<typeof createUseDriStore>;

const DriStoreContext = createContext<UseDriStore | undefined>(undefined);

/**
 * Wrap the whole DRI page in this provider.
 */
export const DriStoreProvider = ({ children }: PropsWithChildren) => {
  const useDriStoreRef = useRef<UseDriStore>();

  if (!useDriStoreRef.current) useDriStoreRef.current = createUseDriStore();

  const useDriStore = useDriStoreRef.current;

  const currentUnit = useCurrentUnitObject();

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    useDriStore.getState().reset();

    mounted.current = true;
  }, [currentUnit, useDriStore]);

  return (
    <DriStoreContext.Provider value={useDriStore}>
      {children}
    </DriStoreContext.Provider>
  );
};

export function useGetUseDriStore() {
  const useDriStore = useContext(DriStoreContext);

  if (!useDriStore) throw new Error("Have you wrapped with DriStoreProvider?");

  return useDriStore;
}

export function useGetUseDriStoreNotRequired() {
  return useContext(DriStoreContext);
}

/**
 * Use this function on the DRI page to initialize the store with data from the API.
 */
export function useInitDriStoreData() {
  const useDriStore = useGetUseDriStore();
  const DRAParams2 = useDRAParams2();
  const baseUrlSlash = useBaseUrlExperimental();

  useEffect(() => {
    const init = async () => {
      const varTask = getVariables(baseUrlSlash)
        .then((v) => variableSchema.array().parse(v))
        .then((variables) =>
          variables.map((v) => {
            return {
              ...v,
              name: v.trimmedName.toLowerCase(),
              description: v.description.toLowerCase(),
            };
          })
        );

      const clusterTask = getClusters(baseUrlSlash).then((c) =>
        clusterSchema.array().parse(c)
      );

      const [variables_, clusters_] = await Promise.all([varTask, clusterTask]);

      /**
       * In each cluster object we want a parallel array of shortIds along side the mongo id
       */

      const cidToCluster: ClustersState = R.mapToObj(clusters_, (c) => {
        return [c._id, c];
      });

      useDriStore.setState({
        clusters: R.mapKeys(cidToCluster, (_, cluster) => cluster._id),
        variables: R.mapToObj(variables_, (v) => [v._id, v]),
      });
    };
    void init();
  }, [baseUrlSlash, useDriStore]);
}
