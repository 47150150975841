import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { isSameDay, isAfter } from "date-fns";
import { RANGES } from "../../constants/dateState";
import useMaxDate from "../common/hooks/useMaxDate";
import { Button } from "../../shared-ui/frontend/button";
import { useState } from "react";
import { cn, type PropsWithCn } from "../../shared-ui/frontend/cn";
import EditableDateTimeRange from "../dateSelector/editable/EditableDateTimeRange";

type Range = {
  start: number;
  end: number;
};

function LocalDateSelector({
  end,
  onChange,
  start,
  className,
  todoRefactorThisPropLabel: label,
}: Range & {
  onChange: (newRange: Partial<Range>) => void;
  todoRefactorThisPropLabel?: string;
} & PropsWithCn) {
  const [open, setOpen] = useState(false);

  const maxDate = useMaxDate();

  return (
    <div className={cn("items-center hidden @4xl:flex", className)}>
      <Button
        className="rounded-r-none text-xslate-11"
        size="xxs"
        variant={"outline"}
        onClick={() =>
          onChange({
            start: subDays(start, 1).getTime(),
            end: subDays(end, 1).getTime(),
          })
        }
      >
        <FaChevronLeft className="w-3 h-3" />
      </Button>
      <Popover open={open} onOpenChange={() => setOpen(!open)}>
        <PopoverTrigger>
          <div className="min-w-[340px]">
            {open ? (
              <EditableDateTimeRange
                className="h-6 border border-x-0 border-xslate-7"
                start={start}
                end={end}
                options={{
                  showTime: true,
                  fontSize: "sm",
                }}
                onChangeHandler={onChange}
                close={() => setOpen(false)}
              />
            ) : (
              <Button
                className="w-full rounded-none border-x-0 whitespace-nowrap hover:bg-xslate-3 font-medium mb-0.5"
                size="xxs"
                variant={"outline"}
              >
                {label}
              </Button>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          withoutPortal
          side="top"
          style={{
            zIndex: 3000,
          }}
          onOpenAutoFocus={(e) => e.preventDefault()} // allow auto focus into editable date time selector to show cursor
        >
          <div className="btn-group min-w-max bg-transparent">
            {RANGES.map(({ days, label }) => {
              const isActive =
                endOfDay(addDays(start, days - 1)).getTime() === end;

              return (
                <Button
                  key={label}
                  className="[&:not(:last-child)]:rounded-r-none [&:not(:first-child)]:rounded-l-none"
                  variant={isActive ? "default" : "outline"}
                  size="xxs"
                  onClick={() => {
                    const newEnd = endOfDay(end).setSeconds(0, 0);
                    onChange({
                      end: newEnd,
                      start: startOfDay(subDays(newEnd, days - 1)).getTime(),
                    });
                    setOpen(false);
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
      <Button
        className="rounded-l-none text-xslate-11"
        size="xxs"
        variant={"outline"}
        onClick={() => {
          onChange({
            start: addDays(start, 1).getTime(),
            end: addDays(end, 1).getTime(),
          });
        }}
        disabled={isSameDay(end, maxDate) || isAfter(end, maxDate)}
      >
        <FaChevronRight className="w-3 h-3" />
      </Button>
    </div>
  );
}

export { LocalDateSelector };
