import { createContext, useContext } from "react";
import { variableSchema } from "../../../lib/api-validators";

const TagCardContext = createContext<
  | {
      open: boolean;
      setOpen: (open: boolean | ((o: boolean) => boolean)) => void;
      editing: boolean;
      setEditing: (editing: boolean) => void;
      tag: variableSchema;
      hasOpenedOnce: boolean;
    }
  | undefined
>(undefined);

function useTagCardContext() {
  const context = useContext(TagCardContext);
  if (!context) {
    throw new Error("useTagsCardContext must be used within a TagsCardContext");
  }
  return context;
}

export { useTagCardContext, TagCardContext };
