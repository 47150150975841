import React, { useEffect, useState } from "react";
import * as R from "remeda";
import "./HelpLayoutTemplate.scss";

import MainLayout from "../../layouts/MainLayout";
import Button from "../../common/Button/Button";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import Title from "./Title";
import LineBreak from "./LineBreak";
import SideBar from "./SideBar";
import type { HelpPage } from "../pages/types";
import Section from "./Section";
import { useLocation } from "react-router";
import { produce } from "immer";

export default function HelpPageTemplate({
  color,
  sectiontitle,
  subsections,
  url,
}: HelpPage) {
  useDocumentTitle("Help > DRA");
  return (
    <MainLayout showDateNav={false} goBackToDRAButton helpPage>
      <div className="flex bg-base-100">
        <div className="min-w-[24rem] max-w-[24rem] overflow-scroll sticky top-0 SIDEBAR SIDEBAR-SINGLE-ZERO border-r border-zinc-300">
          <SideBar />
        </div>
        <div className="sm:px-10 md:px-16 2xl:px-40 mt-12 grow">
          <Title title={sectiontitle} />
          <LineBreak color={color} />

          {/* The ActualContent has local state and I need it to reset when the url
          changes so I put a key below. React defaults to re-render components
          (if they're re-used) * instead of re-mounting them. In this case, 
          we want fresh state because that state is used for keeping track of 
          when images load, which is different across different help pages. */}
          <ActualContent color={color} subsections={subsections} key={url} />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <Button
              className="btn-ghost"
              icon="chevron-up"
              iconClasses="mr-2"
              onClick={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}
            >
              Scroll To Top
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

function ActualContent({
  subsections,
  color,
}: Pick<HelpPage, "subsections" | "color">) {
  const hashId = useHashId();

  const [assetsLoaded, setLoaded] = useState(() =>
    R.pipe(
      subsections,
      R.filter((s) => {
        const hasImageInSubsection = s.content.some((c) => c.type === "image");
        return hasImageInSubsection;
      }),
      R.mapToObj((s) => {
        const loadedMap: Record<string, boolean> = {};

        for (const c of s.content) {
          if (c.type === "image") {
            loadedMap[c.id] = false;
          }
        }

        return [s.id, loadedMap];
      })
    )
  );

  const setSubsectionLoaded = (subsectionId: string, imageId: string) => {
    setLoaded(
      produce((draft) => {
        const subsection = draft[subsectionId];
        if (!subsection) throw new Error("subsection not found. bug");

        const bool = subsection[imageId];
        if (bool === undefined) throw new Error("imageId not found. bug");
        subsection[imageId] = true;
      })
    );
  };

  const allAssetsLoaded = Object.values(assetsLoaded)
    .flatMap((boolMap) => {
      return Object.values(boolMap);
    })
    .every((loaded) => loaded);

  useEffect(() => {
    if (!hashId || !allAssetsLoaded) return;
    const elt = document.getElementById(hashId);
    if (elt) {
      elt.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      throw new Error(
        "hashId not found. bug" +
          JSON.stringify(
            {
              hashId,
              allAssetsLoaded,
            },
            undefined,
            2
          )
      );
    }
  }, [hashId, allAssetsLoaded]);

  return (
    <>
      {subsections.map((s) => {
        const setImageLoaded = (imageId: string) => {
          setSubsectionLoaded(s.id, imageId);
        };

        return (
          <React.Fragment key={s.title}>
            <Section
              {...s}
              setImageLoaded={setImageLoaded}
              allAssetsLoaded={allAssetsLoaded}
            />
            <LineBreak color={color} />
          </React.Fragment>
        );
      })}
    </>
  );
}

function useHashId() {
  const loc = useLocation();
  if (!loc.hash) return undefined;

  const urlHash = decodeURIComponent(loc.hash.slice(1, loc.hash.length)).trim();

  if (!urlHash) return undefined;

  return urlHash;
}
