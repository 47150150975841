import { useMutation } from "@tanstack/react-query";
import {
  createTag,
  generateMessageFromError,
  patchTag,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { addToast } from "../../toast/use-toast-store";
import { type Tag } from "../../../lib/api-schema/tag";

export function useCreateTagMutation(onSuccess?: (tag: Tag) => void) {
  const baseUrlSlash = useBaseUrlExperimental();

  return useMutation({
    mutationFn: (payload: Parameters<typeof createTag>[1]) =>
      createTag(baseUrlSlash, payload),
    onSuccess: (newTag) => {
      addToast({
        title: `Tag ${newTag.name} created`,
        variant: "success",
      });
      onSuccess?.(newTag);
    },
    onError: (err) => {
      addToast({
        title: "Error",
        description: generateMessageFromError(err),
        variant: "danger",
      });
    },
  });
}

export function useEditTagMutation(onSuccess?: (tag: Tag) => void) {
  const baseUrlSlash = useBaseUrlExperimental();

  return useMutation({
    mutationFn: (payload: { _id: string } & Parameters<typeof patchTag>[2]) => {
      const { _id, ...rest } = payload;
      return patchTag(baseUrlSlash, _id, rest);
    },
    onSuccess: (newTag) => {
      addToast({
        title: `Tag: ${newTag.name} updated`,
        variant: "success",
      });
      onSuccess && onSuccess(newTag);
    },
    onError: (err) => {
      addToast({
        title: "Error",
        description: generateMessageFromError(err),
        variant: "danger",
      });
    },
  });
}
