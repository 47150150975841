import React from "react";
import type EditorProps from "./editors/EditorProps";
import Image from "./editors/Image";
import List from "./editors/List";
import Paragraph from "./editors/Paragraph";
import PDF from "./editors/PDF";
import Video from "./editors/Video";
import {
  type Image as ImageType,
  type Paragraph as ParagraphType,
  type PDF as PDFType,
  type Video as VideoType,
  type Content,
  type List as ListType,
} from "./types/BuilderTypes";

function EditorWrapper(props: EditorProps<Content>) {
  const {
    content: { type },
  } = props;
  if (type === "list") {
    return <List {...(props as EditorProps<ListType>)} />;
  } else if (type === "paragraph") {
    return <Paragraph {...(props as EditorProps<ParagraphType>)} />;
  } else if (type === "pdf") {
    return <PDF {...(props as EditorProps<PDFType>)} />;
  } else if (type === "image") {
    return <Image {...(props as EditorProps<ImageType>)} />;
  } else if (type === "video") {
    return <Video {...(props as EditorProps<VideoType>)} />;
  }
  return null;
}

export default EditorWrapper;
