import React, { useState } from "react";
import Input from "../../common/Input/Input";
import {
  type Content,
  type Image,
  type List,
  type Paragraph,
  type PDF,
  type SubSection,
  type Video,
} from "./types/BuilderTypes";
import { Draft as WritableDraft } from "immer";
import Button from "../../common/Button/Button";
import {
  imageTemplate,
  listTemplate,
  paragraphTemplate,
  pdfTemplate,
  videoTemplate,
} from "./templates";
import { Alert, Badge, Collapse, Tooltip } from "@mantine/core";
import classNames from "classnames";
import { removeFromArrayByIndex, swapTwoItemsInArray } from "./utils";
import EditorWrapper from "./EditorWrapper";

type Props = {
  subsection: SubSection;
  isFirst: boolean;
  isLast: boolean;
  canDelete: boolean;
  moveUp: () => void;
  moveDown: () => void;
  delete_: () => void;
  editSubsection: (
    mutator: (subsection: WritableDraft<SubSection>) => void // immer produce 'mutator'
  ) => void;
  createCopy: () => void;
};

function Subsection({
  createCopy,
  subsection,
  editSubsection,
  canDelete,
  delete_,
  isFirst,
  isLast,
  moveUp,
  moveDown,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { content, title } = subsection;

  /**
   * Generate some defaults and add it to the subsection
   */
  function addContentToSubsection(
    content_: Paragraph | List | Image | PDF | Video
  ) {
    // TODO add more
    editSubsection((s) => {
      s.content.push(content_);
    });
  }

  function editContentInSubsection(
    contentIdx: number,
    mutator: (c: WritableDraft<Content>) => void
  ) {
    editSubsection((s) => {
      const a = s.content[contentIdx];
      if (!a) throw new Error();
      mutator(a);
    });
  }

  function generateError(): string | null {
    if (!title.trim()) {
      return "Subsection title is required";
    }
    // recursively identify problems with children
    return null;
  }

  const err = generateError();

  const errComponent = err && (
    <Alert title="Error" color="red" variant="filled">
      {err}
    </Alert>
  );

  const errBadge = err && (
    <Badge color="red" variant="filled">
      {err}
    </Badge>
  );

  const addMoreDataButtonsData = [
    {
      label: "Add Paragraph",
      onClick: () => addContentToSubsection(paragraphTemplate()),
    },
    {
      label: "Add List",
      onClick: () => addContentToSubsection(listTemplate()),
    },
    {
      label: "Add Image",
      onClick: () => addContentToSubsection(imageTemplate()),
    },
    {
      label: "Add Video",
      onClick: () => addContentToSubsection(videoTemplate()),
    },
    {
      label: "Add PDF",
      onClick: () => addContentToSubsection(pdfTemplate()),
    },
  ];

  {
    /* buttons to add more content */
  }
  const addMoreButtons = addMoreDataButtonsData.map(
    ({ label, onClick }, idx) => {
      const isFirst = idx === 0;
      return (
        <Button
          key={label}
          onClick={onClick}
          className={classNames("btn-outline", { "ml-auto": isFirst })}
        >
          {label}
        </Button>
      );
    }
  );

  return (
    <div className="border border-bordgrey2 rounded-xl p-4 bg-white shadow-md flex-col flex gap-3">
      <div className="flex gap-2 items-center">
        <Button
          icon={"chevron-down"}
          onClick={() => setIsOpen((s) => !s)}
          size="xs"
          iconClasses={classNames("transition-transform", {
            "rotate-180": isOpen,
          })}
          className="btn-ghost max-w-min"
        />
        <div>{title}</div>
        {errBadge}
        {canDelete ? (
          <div className="ml-auto">
            {isFirst ? null : (
              <Tooltip withArrow label={`Move this subsection up`}>
                <Button
                  onClick={moveUp}
                  icon={"arrow-up"}
                  size="xs"
                  className="btn-ghost"
                />
              </Tooltip>
            )}
            {isLast ? null : (
              <Tooltip withArrow label={`Move this subsection down`}>
                <Button
                  onClick={moveDown}
                  icon={"arrow-down"}
                  size="xs"
                  className="btn-ghost"
                />
              </Tooltip>
            )}
            <Button
              icon={"trash-o"}
              onClick={delete_}
              size="xs"
              className="btn-ghost max-w-min"
            />
            <Tooltip withArrow label="Create a copy of this subsection">
              <Button
                onClick={createCopy}
                icon="copy"
                size="xs"
                className="btn-ghost"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>

      <Collapse in={isOpen}>
        <div className="flex flex-col gap-3">
          {errComponent}
          <div className="form-control">
            <label className="input-group input-group-sm">
              <span>Title</span>
              <Input
                bordered={true}
                size="sm"
                required={true}
                value={title}
                onChange={(e) =>
                  editSubsection((s) => {
                    s.title = e.target.value;
                  })
                }
              />
            </label>
          </div>
          <div className="flex flex-col gap-4">
            {content.map((c, idx) => {
              const canDelete = content.length !== 1;
              const isFirst = idx === 0;
              const isLast = idx === content.length - 1;

              return (
                <div
                  className="flex flex-col shadow-md border border-zinc-400 rounded-md p-2"
                  key={c.id}
                >
                  <div className="flex">
                    <div className="grow mb-4">
                      <Badge size="lg" color="indigo">
                        {c.type.toUpperCase()}
                      </Badge>
                    </div>
                    <div>
                      {isFirst ? null : (
                        <Tooltip withArrow label={`Move this ${c.type} up`}>
                          <Button
                            onClick={() => {
                              editSubsection((s) => {
                                swapTwoItemsInArray(idx, idx - 1, s.content);
                              });
                            }}
                            icon={"arrow-up"}
                            size="xs"
                            className="btn-ghost"
                          />
                        </Tooltip>
                      )}
                      {isLast ? null : (
                        <Tooltip withArrow label={`Move this ${c.type} down`}>
                          <Button
                            onClick={() => {
                              editSubsection((s) => {
                                swapTwoItemsInArray(idx, idx + 1, s.content);
                              });
                            }}
                            icon={"arrow-down"}
                            size="xs"
                            className="btn-ghost"
                          />
                        </Tooltip>
                      )}
                      {canDelete ? (
                        <Tooltip withArrow label={`Delete this ${c.type}`}>
                          <Button
                            onClick={() => {
                              editSubsection((s) => {
                                removeFromArrayByIndex(s.content, idx);
                              });
                            }}
                            icon={"trash-o"}
                            size="xs"
                            className="btn-ghost"
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <EditorWrapper
                      content={c}
                      editContent={(mutator) => {
                        editContentInSubsection(idx, mutator);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex mt-4 gap-2">{addMoreButtons}</div>
        </div>
      </Collapse>
    </div>
  );
}

export default Subsection;
