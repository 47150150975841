import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import daisyTheme from "daisyui/src/colors/themes";
import Color from "color";
import _ from "lodash";
import "./PetalChart.scss";
import getColors from "../common/colors";
import { useQuery } from "@tanstack/react-query";
import { addDays, endOfDay, minutesToMilliseconds, startOfDay } from "date-fns";
import { getRisks } from "../../frameworks/fetcher/api-routes-experimental";
import { useDateState } from "../../zustand/useDateState";
import moment from "moment";

const colors = getColors();

/**
 * If the index is not === 0, it cannot be green, so we need to shift the colors down and add red to the end
 */
const petalColors = ["yellow", "orange", "red", "red"].map(
  (c) => _.find(colors, (o) => o.color === c).rgb
);

function PetalChart({
  groupId,
  width,
  height,
  style,
  unitName: unitToFetchRisksFor,
  scaleText,
  className,
}) {
  const [clickedPetal, setClickedPetal] = useState(null);
  const [hoveredPetal, setHoveredPetal] = useState(null);
  const $date = useDateState();
  const prefix = _.uniqueId("petal-chart-");
  const ref = useRef();
  const [drawData, setDrawData] = useState([]);

  const risksQuery = useQuery({
    queryKey: [
      "risks",
      unitToFetchRisksFor,
      {
        end: $date.axisRangeTo.dateString,
        start: $date.axisRangeFrom.dateString,
        groupId: groupId,
      },
    ],
    queryFn: () =>
      getRisks("/" + unitToFetchRisksFor, {
        end: $date.axisRangeTo.dateString,
        start: $date.axisRangeFrom.dateString,
        groupId: groupId,
      }),
    refetchOnWindowFocus: false,
    staleTime: minutesToMilliseconds(1), // this probably never changes once calculated
  });

  const defaultStyles = {
    svg: { margin: "0 auto", overflow: "visible" },
    background: "#fff",
    petalBackground: daisyTheme["[data-theme=light]"]["base-100"],
    petalBackgroundHover: daisyTheme["[data-theme=light]"]["base-200"],
    petalBackgroundClick: daisyTheme["[data-theme=light]"]["base-300"],
    petal: petalColors,
    petalHover: petalColors.map((c) => Color(c).darken(0.1)),
    petalShutdown: Array(petalColors.length)
      .fill(0)
      .map((d, i) => `url(#gradient${i})`),
    petalShutdownHover: Array(petalColors.length)
      .fill(0)
      .map((d, i) => `url(#gradient${i}Hover)`),
    slim: false, // if true, the tooltip will be drawn closer to the center on the ends to avoid overflow
  };
  const styles = { ...defaultStyles, ...style };

  useEffect(() => {
    d3.select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .style("background", styles.background);
  }, [width, height, styles.background]);

  useEffect(() => {
    if (!risksQuery.data) return;
    // const endDate = new Date(end).getTime()
    // if (endDate > new Date(axisFrom).getTime() && endDate < new Date(previousDate.axisTo).getTime()) {
    //   // get active petal from DS
    //   const petalIndex = new moment.duration(endDate - new Date(previousDate.axisFrom).getTime()).asDays()
    //   setClickedPetal(petalIndex)
    //   setHoveredPetal(petalIndex)
    //   return
    // }
    setClickedPetal(null);
    setHoveredPetal(null);

    setDrawData(
      risksQuery.data.map((d) => {
        return {
          index: d.index,
          shutdown: d.shutdownMilliseconds > 0,
        };
      })
    );
  }, [risksQuery.data]);

  // redraw upon size changes
  useEffect(() => {
    // if (clickedPetal === null || clickedPetal < 0) {
    //   setClickedPetal(drawData.length - 1)
    //   return
    // }
    // if (hoveredPetal === null || hoveredPetal < 0) {
    //   setHoveredPetal(drawData.length - 1)
    //   return
    // }
    draw(drawData, height, width);
  }, [height, width, drawData, hoveredPetal, scaleText]);

  function draw(data, heightLocal, widthLocal) {
    if (data.length === 0) {
      return;
    }
    d3.select(ref.current).selectAll("*").remove();
    d3.select(ref.current)
      .attr("width", widthLocal)
      .attr("height", heightLocal)
      .style("background", styles.background);
    const innerRadius = Math.min(widthLocal, heightLocal) / 5,
      outerRadius = Math.min(widthLocal, heightLocal) / 1.25, // the outerRadius goes from the middle of the SVG area to the border
      paddingBottom = 20;
    const svg = d3
      .select(ref.current)
      .append("g")
      .attr(
        "transform",
        `translate(${widthLocal / 2},${
          heightLocal - paddingBottom
        }) rotate(-90)`
      )
      .on("mouseout", () => setHoveredPetal(null));

    // X scale
    const x = d3
      .scaleBand()
      .range([0, Math.PI])
      .domain(data.map((d, i) => i));
    const endDate = new Date($date.axisRangeTo.local);
    endDate.setDate(endDate.getDate() + 1);
    const t = d3
      .scaleTime()
      .domain([
        startOfDay($date.axisRangeFrom.local),
        endOfDay($date.axisRangeTo.local),
      ])
      .range([0, Math.PI]);

    // Y scale
    const y = scaleLinear().range([innerRadius, outerRadius]).domain([0, 3]);

    // Add stripe gradient for shutdown
    const defs = svg.append("defs");
    ["", "Hover"].forEach((type) => {
      styles.petal.forEach((c, i) => {
        const pattern = defs
          .append("pattern")
          .attr("id", `gradient${i}${type}`)
          .attr("width", 8)
          .attr("height", 10)
          .attr("patternUnits", "userSpaceOnUse")
          .attr("patternTransform", "rotate(45 50 50)");
        pattern
          .append("rect")
          .attr("width", 8)
          .attr("height", 10)
          .attr("fill", type === "Hover" ? Color(c).darken(0.25) : c);
        pattern
          .append("line")
          .attr("stroke", "black")
          .attr("style", "opacity: 0.4")
          .attr("stroke-width", "7px")
          .attr("y2", 10);
      });
    });

    // Create full height background petals
    const backgroundPetals = svg
      .append("g")
      .selectAll("path")
      .data(Array($date.axisRange.days).fill(3))
      .enter()
      .append("path")
      .attr("fill", (d, i) => {
        if (i === (clickedPetal === null ? data.length - 1 : clickedPetal)) {
          return styles.petalBackgroundClick;
        }
        if (i === hoveredPetal) {
          return styles.petalBackgroundHover;
        }
        return styles.petalBackground;
      })
      .style("cursor", "pointer");

    backgroundPetals
      // .on('mouseover', (d, i) => setHoveredPetal(i))
      // see https://observablehq.com/@d3/d3v6-migration-guide#events
      .on("mouseover", (event, d) => {
        const e = backgroundPetals.nodes();
        const i = e.indexOf(event.target);
        setHoveredPetal(i);
      })
      // .on('mouseout', (d, i) => setHoveredPetal(null))
      .on("click", (event) => {
        const e = backgroundPetals.nodes();
        const i = e.indexOf(event.target);
        $date.setEnd(addDays($date.axisRangeFrom.local, i));
        setClickedPetal(i);
      })
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(function (d) {
            return y(d);
          })
          .startAngle(function (d, i) {
            return x(i);
          })
          .endAngle(function (d, i) {
            return x(i) + x.bandwidth();
          })
          .padAngle(0)
          .padRadius(innerRadius)
      );

    // Y axis (semicircles + degree of anom tick mark)
    const circlesGroup = svg.append("g").attr("dy", "-50");
    const paddingBottomGroup = svg.append("g");
    const yAxis = svg
      .append("g")
      .attr("text-anchor", "middle")
      .attr("dy", "-50");

    const yTick = yAxis
      .selectAll("g")
      .data(y.ticks(3))
      .enter()
      .append("g")
      .attr("class", "fill-[#808080]");
    const yCircle = circlesGroup
      .selectAll("g")
      .data(y.ticks(3))
      .enter()
      .append("g")
      .attr("class", "fill-[#808080]");

    yCircle
      .append("circle")
      .attr("fill", "none")
      .attr("class", "stroke-[#808080]")
      .attr("r", y);

    // padding bottom block
    paddingBottomGroup
      .append("rect")
      // reversed because it's rotated
      .attr("width", paddingBottom)
      .attr("height", widthLocal)
      .attr("x", -paddingBottom)
      .attr("y", -(outerRadius + innerRadius))
      .attr("fill", styles.background);

    yTick
      .append("text")
      .attr("y", function (d) {
        return -y(d);
      })
      .attr("dx", "-1em")
      .attr("rotate", "90")
      .text(y.tickFormat(1, "d"));

    yTick
      .append("text")
      .attr("y", function (d) {
        return y(d);
      })
      .attr("dx", "-1em")
      .attr("rotate", "90")
      .text(y.tickFormat(1, "d"));

    // X axis (dates at top of semicircle)
    const xAxis = svg.append("g").attr("text-anchor", "middle");
    const nTicks = parseInt($date.axisRange.days);
    const radians = d3.scaleLinear().domain([0, 1]).range([-Math.PI, Math.PI]);

    const evenlySpacedElements = (arr, count) => {
      if (count <= 0 || arr.length === 0) {
        return [];
      }

      // Calculate the step size to evenly space the elements
      const step = (arr.length - 1) / (count - 1);

      // Initialize an array to store the selected elements
      const result = [];

      for (let i = 0; i < count; i++) {
        const index = Math.round(i * step); // Round to the nearest whole number
        result.push(arr[index]);
      }

      return result;
    };

    const chosenIndices = evenlySpacedElements(
      Array.from({ length: nTicks }).map((_, i) => i),
      7
    );

    const chosenBounds = chosenIndices.map((i) => {
      const pizzaSliceAngle = Math.PI / nTicks;

      const start = i * pizzaSliceAngle;
      const end = (i + 1) * pizzaSliceAngle;

      const d = addDays($date.axisRangeFrom.local, i);

      return {
        start,
        end,
        middle: (start + end) / 2,
        date: d,
        formatted: moment(d).format("MM/D"),
      };
    });

    for (const bound of chosenBounds) {
      const middleRadians = bound.middle - Math.PI / 2;

      // const parabolicPadding = 18 - (12.25 + -1 * Math.pow(i - 7 / 2, 2));
      const cx = Math.cos(middleRadians) * (outerRadius + 17);
      const cy = Math.sin(middleRadians) * (outerRadius + 17);

      xAxis
        .append("g")
        .attr("transform", `rotate(0)`)
        .append("text")
        .attr("transform", `rotate(90, ${cx}, ${cy})`)
        .attr("x", cx)
        .attr("y", cy)
        .attr("font-family", "'Open Sans', sans-serif")
        .attr("class", "fill-[#808080]")
        .attr("opacity", hoveredPetal === null ? 1 : 0.8)
        .text(() => {
          return bound.formatted;
        });
    }

    // Add the petals
    const petals = svg
      .append("g")
      .selectAll("path")
      .data(data.map((x) => (x.index > 0 ? x.index : -1))) // we are marking this -1 so we can use it later
      .enter()
      .append("path")
      .attr("fill", function (d, i) {
        const isShutdown = data[i].shutdown;

        if (d === -1) {
          return isShutdown ? "#555" : colors[0].rgb;
        }
        if (i === hoveredPetal) {
          return isShutdown
            ? styles.petalShutdownHover[Math.floor(d)]
            : styles.petalHover[Math.floor(d)];
        }
        return isShutdown
          ? styles.petalShutdown[Math.floor(d)]
          : styles.petal[Math.floor(d)];
      })
      .attr("id", (d, i) => `${prefix}-${i}`)
      .attr("class", "petal") // only used to un-click other petals
      .attr(
        "style",
        (d, i) =>
          `cursor: pointer; ${
            (clickedPetal === null ? data.length - 1 : clickedPetal) === i
              ? "stroke: #333; stroke-width: 2.5px;"
              : ""
          }`
      );

    petals
      // .on('mouseover', (d, i) => setHoveredPetal(i))
      .on("mouseover", (event, d) => {
        const e = petals.nodes();
        const i = e.indexOf(event.target);
        setHoveredPetal(i);
      })
      // .on('mouseout', (d, i) => setHoveredPetal(null))
      .on("click", (event) => {
        const e = petals.nodes();
        const i = e.indexOf(event.target);
        $date.setEnd(addDays($date.axisRangeFrom.local, i));
        setClickedPetal(i);
      })
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(function (d) {
            return y(d < 0 ? 0.075 : d); // we manipulated the index above to be -1 to indicate that it was in fact 0. arbitrarily choose it to be 0.075 so that it shows a little line
          })
          .startAngle(function (d, i) {
            return x(i);
          })
          .endAngle(function (d, i) {
            return x(i) + x.bandwidth();
          })
          .padAngle(1 / $date.axisRange.days / 3)
          .padRadius(innerRadius)
      );

    // add tooltip
    if (hoveredPetal !== null) {
      const i = hoveredPetal;
      // const halfTick = 0.5 / (nTicks - 1)
      const halfTick = 0.5;
      const deg = radians((i + halfTick) / nTicks) / 2;
      // want tooltip to be closer to center at start / end of petal, and further away in the middle
      const centered = (i + halfTick) / (nTicks / 2);
      const centeredAt1 = centered < 1 ? centered : 2 - centered;
      const spacingReduction = (1 - centeredAt1) * (outerRadius / 4);
      const cx =
        Math.cos(deg) *
        (outerRadius + 15 - (styles.slim ? spacingReduction : 0));
      const cy =
        Math.sin(deg) *
        (outerRadius + 15 - (styles.slim ? spacingReduction : 0));
      // const ct = t.invert((i / (i === (nTicks - 1) ? nTicks - 0.00001 : nTicks)) * Math.PI)
      const tooltip = svg.append("g").attr("text-anchor", "middle");
      tooltip
        .append("rect")
        .attr("x", cx - (nTicks >= 180 ? 120 : 100) / 2)
        .attr("y", cy - 25 / 2)
        .attr("rx", 3)
        .attr("width", nTicks >= 180 ? 120 : 100)
        .attr("height", 25)
        .attr("fill", "#333")
        .attr("transform", `rotate(${90}, ${cx}, ${cy})`);
      tooltip
        .append("text")
        .attr("transform", `rotate(90, ${cx}, ${cy})`)
        .attr("x", cx)
        .attr("y", cy)
        .attr("alignment-baseline", "middle")
        .attr("font-family", "'Open Sans', sans-serif")
        .attr("fill", "#eee")
        .style("font-size", "14px")
        .text(
          data[hoveredPetal].index === null
            ? "-.--"
            : data[hoveredPetal].index === undefined
              ? data[data.length - 1].index === null
                ? "-.--"
                : data[data.length - 1].index.toFixed(2)
              : data[hoveredPetal].index.toFixed(2) +
                ` (${moment
                  .utc($date.axisRangeFrom.dateString)
                  .add(i, "days")
                  .format("MMM D")})`
        );
    }

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", -outerRadius)
      .attr("x2", 0)
      .attr("y2", outerRadius)
      .attr("fill", "none")
      .attr("stroke-width", 1)
      .attr("class", "stroke-gray-400");

    const numberText = scaleText ?? 0.0023;

    // the dri value text
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", -heightLocal / 12)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(90)")
      .style("font-size", `${numberText * widthLocal}em`)
      .attr("class", "fill-gray-600")
      .text(
        data[data.length - 1].index === undefined ||
          data[data.length - 1].index === null
          ? "-.--"
          : data[data.length - 1].index.toFixed(2)
      );
    // the dri value text
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", -heightLocal / 50)
      .attr("text-anchor", "middle")
      .style("font-size", `${numberText * 0.65 * widthLocal}em`)
      .attr("transform", "rotate(90)")
      .attr("class", "fill-slate-600")
      .text("DRI");
  }

  return <svg className={className} ref={ref} style={styles.svg}></svg>;
}

export default PetalChart;
