import { endOfDay, subDays } from "date-fns";
import useCurrentUnitObject from "./useCurrentUnitObject";

export default function useMaxDate(): Date {
  const currentUnit = useCurrentUnitObject(true);

  const IS_REAL_TIME = currentUnit!.addons && currentUnit!.addons.realTime;
  const maxDate = endOfDay(IS_REAL_TIME ? new Date() : subDays(new Date(), 1));

  return maxDate;
}
