import { type PDF as PDFType } from "./types/ComponentTypes";
import { generateStyleObjectFromMarginOptions } from "../utils";
import Button from "../../common/Button/Button";

/**
 * Default is numbered, pass in bullets=true for a bulleted list
 */
export default function PDF({
  marginTop,
  marginBottom,
  content,
  displayname,
}: PDFType) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem 0",
        ...generateStyleObjectFromMarginOptions(marginTop, marginBottom),
      }}
    >
      <Button className="btn-ghost" icon="download">
        <a
          href={content}
          download={displayname.replaceAll(".pdf", "") + ".pdf"}
        >
          {displayname}
        </a>
      </Button>
    </div>
  );
}
