import React from "react";
import _ from "lodash";
import "./Form.scss";

/* We do need to polyfill this since Babel probably won't know to. */
function closest(el, target) {
  const targets = _.isArray(target) ? target : [target];
  const mappedTargets = _.reduce(
    targets,
    (result, target) => {
      if (_.isString(target)) {
        target = document.querySelector(`#${target}`);
      }

      if (target instanceof HTMLElement) {
        result.push(target);
      }

      return result;
    },
    []
  );

  if (el == null) {
    return false;
  }
  if (el === document.body) {
    return false;
  }
  if (_.includes(mappedTargets, el)) {
    return true;
  }

  return closest(el.parentElement, mappedTargets);
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    const that = this;

    that.prefix = _.uniqueId("Form");
    that.id = props.id || `${that.prefix}-autogenerated`;
  }

  componentDidMount() {
    this.onMount();
    this.formRef.addEventListener("nmm-form-cancel", this.exit);
  }

  componentWillUnmount() {
    this.formRef.removeEventListener("nmm-form-cancel", this.exit);
  }

  render() {
    const that = this;
    const props = that.props;
    const classes = props.classes || {};

    /* These need to be handled on render so they'll be recomputed. Otherwise,
     * you'll be forced to use persistent state to get around closures. */
    that.selectOnMount = props.selectOnMount;
    that.selectOnExit = props.selectOnExit;

    that.exitOnSubmit = !!props.exitOnSubmit;
    that.exitOnFocusLost = !!props.exitOnFocusLost;

    that.disabled = _.isFunction(props.disabled)
      ? props.disabled
      : () => !!props.disabled;

    that.onExit = (function () {
      const onExit = props.onExit || function () {};
      return async function () {
        const self = document.querySelector(`#${that.id}`);
        const targets = (props.includeInFocusRing || []).concat(self);
        const focused = closest(document.activeElement, targets);

        await onExit();

        /* Only refocus the `selectOnExit` element if the user hasn't already
         * clicked away. */
        if (that.selectOnExit && focused) {
          /* There's no equivalent to `component*Did*Unmount` in React.
           * We need to wait for the render to actually happen, which won't
           * be until the next tick.
           *
           * There's a danger here that React might start doing weird things
           * with when it renders. I'm going to take that risk since it'll be a
           * minor bug if it does happen. But I'm a little bit annoyed about it,
           * and a better solution would be preferable if possible. */
          setTimeout(() => {
            const target = document.querySelector(`#${that.selectOnExit}`);
            target && target.focus();
          }, 0);
        }
      };
    })();

    that.onMount = (function () {
      const onMount = props.onMount || function () {};
      return async function () {
        await onMount();

        if (that.selectOnMount) {
          setTimeout(() => {
            const target = document.querySelector(`#${that.selectOnMount}`);
            target && target.focus();
          }, 0);
        }
      };
    })();

    /* Make sure that submit will only go through if the form isn't disabled. */
    that.submit = (function () {
      const submit = props.onSubmit || function () {};
      return async function () {
        if (that.disabled()) {
          return;
        }

        try {
          await submit();

          if (that.exitOnSubmit) {
            that.exit();
          }

          /* don't exit forms if there's a submission error, that signifies that
           * something has interupted the process. */
        } catch (err) {
          console.log("Submission error: ", err);
        }
      };
    })();

    that.exit = () => that.onExit();

    this.props.exitHandlerRef &&
      (this.props.exitHandlerRef.current = that.onExit);

    return (
      <form
        className={`Form ${classes.Form}`}
        tabIndex={props.focusable ? 0 : -1}
        ref={(element) => {
          this.formRef = element;
        }}
        style={that.props.styles || {}}
        id={this.id}
        onKeyDown={(e) => {
          /* React will not trigger key *presses* inside of inputs.
           * Thus, keydown. */

          if (e.keyCode === 27) {
            //escape
            that.onExit();
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          that.submit();
        }}
        onBlur={() => {
          /* Wait for the browser to give the new element focus.
           * Annoying, but necessary. */
          setTimeout(() => {
            const self = document.querySelector(`#${this.id}`);
            const selfFocused = self === document.activeElement;

            const targets = (props.includeInFocusRing || []).concat(self);
            const childOrFocusRing = closest(document.activeElement, targets);

            const isFocused = !!childOrFocusRing || selfFocused;
            if (!isFocused && this.exitOnFocusLost) {
              that.exit();
            }
          }, 0);
        }}
      >
        {props.children}

        {/* We want the vast majority of our forms to submit on "enter". In order
         * for that to happen, they either need to contain only one text field,
         * or they need to explicitly specify that there is a submit button. */}
        <div className="Form__submit">
          <input type="submit" aria-hidden="true" value="Submit" />
        </div>
      </form>
    );
  }
}

export default Form;
