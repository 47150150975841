export type Operator = "da" | "da_hi" | "da_lo" | "da_sf";

const OperatorMap: { [TOp in Operator]: TOp } = {
  da: "da",
  da_hi: "da_hi",
  da_lo: "da_lo",
  da_sf: "da_sf",
};

export const operators = Object.keys(OperatorMap) as Operator[];
