import { cn } from "../../../lib/utils";
import * as React from "react";
import {
  ANOMALY_LEVELS_DESC,
  AnomalyLevelEnum,
} from "../../../types/api/Anomaly";
import { useGetUseAckManStore } from "./store/ack-man-store";

const nonZeroAnomalyValues = ANOMALY_LEVELS_DESC.filter((l) => l > 0);

const toggleStyles: Partial<{
  [TKey in AnomalyLevelEnum]: { checkbox: string; text: string };
}> = {
  "3": {
    checkbox: "checked:bg-lvl3 checked:border-lvl3",
    text: "text-lvl3",
  },
  "2": {
    checkbox: "checked:bg-lvl2 checked:border-lvl2",
    text: "text-lvl2",
  },
  "1": {
    checkbox: "checked:bg-lvl1 checked:border-lvl1",
    text: "text-lvl1",
  },
};

export function LevelTogglers() {
  const useAckManStore = useGetUseAckManStore();
  const checkedMap = useAckManStore((s) => s.checkedMap);
  const onlyActiveFaultTreeNodes = useAckManStore(
    (s) => s.onlyActiveFaultTreeNodes
  );
  return (
    <div className="inline-flex flex-col gap-1 mr-auto border border-xslate-4 hover:border-xslate-7 py-1.5 rounded-lg bg-white">
      <div className="flex gap-1 px-2">
        {nonZeroAnomalyValues.map((level) => {
          const stylesObj = toggleStyles[level];

          if (!stylesObj) throw new Error("No style string for level");
          return (
            <Toggle
              key={level}
              checked={checkedMap[level] ?? false}
              onChange={(checked) =>
                useAckManStore.getState().setChecked(level, checked)
              }
              className={stylesObj.checkbox}
              label={
                <span className={cn(stylesObj.text, "text-sm font-semibold")}>
                  {level}°
                </span>
              }
            />
          );
        })}
      </div>
      <div className="w-full h-[1px] bg-xslate-5 my-0.5" />
      <Toggle
        checked={onlyActiveFaultTreeNodes}
        onChange={(checked) =>
          useAckManStore.getState().setOnlyActiveFaultTreeNodes(checked)
        }
        className="checked:bg-ft-active checked:border-ft-active ml-2"
        label={
          <span
            className={cn(
              onlyActiveFaultTreeNodes ? "text-ft-active" : "text-xslate-11",
              "whitespace-nowrap text-sm tracking-tight mr-2"
            )}
          >
            Active fault tree nodes
          </span>
        }
      />
    </div>
  );
}

function Toggle({
  checked,
  className,
  onChange,
  label,
  disabled,
}: {
  checked: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
  label: React.ReactNode;
  disabled?: boolean;
}) {
  return (
    <div className="flex items-center gap-2">
      <input
        disabled={disabled}
        checked={checked}
        className={cn(`toggle toggle-sm`, className)}
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
      />
      {label}
    </div>
  );
}
