import * as R from "remeda";
import { type StageFromAPI, type TimeseriesForBv } from "./types";
import { minutesToMilliseconds } from "date-fns";
import { minLen1 } from "../lib/utils";

/**
 * Stage separators in other views can be longer or
 * shorter than this variable depending on what the data
 * actually is, but in align by stage mode we need to
 * standardize it so that we have some sort of logic
 * behind where to align them at.
 */
const NUM_MS_FOR_STAGE_SEPARATORS = minutesToMilliseconds(1);

/**
 * This function handles calculating offsets required to
 * draw the chart in parallel align by stages mode.
 *
 * It strips out default stages, and compares stages
 * between other batch variables present to figure out
 * the discontinuous scales pretty much.
 *
 * We're going to store the offsets in extra properties
 * instead of changing the actual time values of any points
 * so that we can reference the real data still (for
 * tooltip purposes for example).
 */
function manipulateStageOffsetsForAlignByStageView2(
  dataForAllBvsShown: [TimeseriesForBv, ...TimeseriesForBv[]],
  stageMap: Record<string, { isDefault: boolean }>
  // _groupOrderMap: Record<string, string[]>
) {
  const KEY = "manipulateStageOffsetsForAlignByStageView2";
  console.time(KEY);
  /**
   * In parallel align by stage view, we have to ensure the
   * stages that exist in the Timeseries data exists because
   * we need to check the ordering of stages to know how to
   * order the alignment of stages.
   *
   * In other views, it is possible for a chart to still draw
   * the data, even if the stage no longer exists, but it is
   * still referenced in the timeseries data.
   *
   * So, we will simply filter out stages if they don't exist
   * anymore.
   *
   * We also filter out any default stages
   */

  const dataWithStagesRemoved = _removeDefaultStagesFromData(
    dataForAllBvsShown,
    R.mapValues(stageMap, ({ isDefault }) => isDefault)
  );

  /**
   * There are no stages left, hence there is nothing to draw
   */
  if (!dataWithStagesRemoved) {
    console.timeEnd(KEY);
    return undefined;
  }

  const withOffsets = _calculateStageOffsets(dataWithStagesRemoved);

  const stageIdToDataIncludingIt =
    _getStageIdToDataWithStagePresent(withOffsets);

  const uniqueStageDeps = _getUniqueStageDependencies(stageIdToDataIncludingIt);

  const stageStarts: Record<string, number> = {};
  let longestSequenceLen = 0;

  for (const stageIdsOnSameScale of uniqueStageDeps) {
    let stageStart = 0;
    for (const stageId of stageIdsOnSameScale) {
      stageStarts[stageId] = stageStart;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const d = stageIdToDataIncludingIt[stageId]!;
      stageStart += d.longest + NUM_MS_FOR_STAGE_SEPARATORS;
    }

    longestSequenceLen = Math.max(
      longestSequenceLen,
      stageStart - NUM_MS_FOR_STAGE_SEPARATORS
    );
  }

  const out = { uniqueStageDeps, longestSequenceLen, stageStarts, withOffsets };

  console.timeEnd(KEY);

  return out;
}

export type DataForAlignByStageView = NonNullable<
  ReturnType<typeof manipulateStageOffsetsForAlignByStageView2>
>;

const _removeDefaultStagesFromData = (
  data: [TimeseriesForBv, ...TimeseriesForBv[]],
  isDefaultMap: Record<string, boolean>
): [TimeseriesForBv, ...TimeseriesForBv[]] | undefined => {
  const out = data
    .map((x): TimeseriesForBv | undefined => {
      const existingStages = x.stages.filter((s) => {
        const isDefault = isDefaultMap[s._id];
        return !isDefault;
      });

      if (!minLen1(existingStages)) return undefined;

      return {
        ...x,
        stages: existingStages,
      };
    })
    .filter((x) => x !== undefined);

  if (!minLen1(out)) return undefined;

  return out;
};

/**
 * After removing stages, there can be contiguous stages that are
 * the same. So we need to move them backwards.
 */
const _calculateStageOffsets = (
  dataWithStagesRemoved: [TimeseriesForBv, ...TimeseriesForBv[]]
) => {
  const out = dataWithStagesRemoved.map((dataForBvWithStagesRemoved) => {
    type StageFromAPIWithOffset = StageFromAPI & {
      distanceFromStageStart: number;
    };
    const stagesWithOffsets = dataForBvWithStagesRemoved.stages.map(
      (thisStage, i, arr): StageFromAPIWithOffset => {
        let distanceFromStageStart = 0;
        let j = i - 1;
        while (j >= 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const somePreviousStage = arr[j]!;
          if (somePreviousStage._id !== thisStage._id) break;
          distanceFromStageStart +=
            somePreviousStage.d[1] - somePreviousStage.d[0];
          j--;
        }

        return {
          ...thisStage,
          distanceFromStageStart,
        };
      }
    );

    if (!minLen1(stagesWithOffsets)) throw new Error("bug");

    const out = {
      ...dataForBvWithStagesRemoved,
      ["stages" satisfies keyof typeof dataForBvWithStagesRemoved]:
        stagesWithOffsets,
    };

    return out;
  });

  if (!minLen1(out)) throw new Error("bug");

  return out;
};

const _getStageIdToDataWithStagePresent = (
  data: ReturnType<typeof _calculateStageOffsets>
) => {
  const stageIdToDataWithStagePresent: Record<
    string,
    {
      data: typeof data;
      longest: number;
    }
  > = {};

  for (const x of data) {
    let i = 0;

    while (i < x.stages.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const thisStage = x.stages[i]!;

      let len = thisStage.d[1] - thisStage.d[0];

      // go up until we find a different stage
      let j = i + 1;

      while (j < x.stages.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const nextStage = x.stages[j]!;
        if (nextStage._id !== thisStage._id) break;
        len += nextStage.d[1] - nextStage.d[0];
        j++;
      }

      const d = stageIdToDataWithStagePresent[thisStage._id];
      if (d) {
        d.data.push(x);
        d.longest = Math.max(d.longest, len);
      } else {
        stageIdToDataWithStagePresent[thisStage._id] = {
          data: [x],
          longest: len,
        };
      }

      // now j is the index of the next unique stage, or .length + 1
      i = j;
    }
  }

  return stageIdToDataWithStagePresent;
};

// const t: {
//   stageId: string;
//   stageStart: number;
//   longest: number;
// }[] = [];

// let stageStart = 0;
// for (const stageId of stageIdsOnSameScale) {
//   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//   const d = data[stageId]!;
//   t.push({
//     stageId,
//     stageStart,
//     longest: d.longest,
//   });
//   stageStart += d.longest + NUM_MS_FOR_STAGE_SEPARATORS;
// }

/**
 * Calculate which stages should be drawn along the same axis
 */
const _getUniqueStageDependencies = (
  data: ReturnType<typeof _getStageIdToDataWithStagePresent>
): [string, ...string[]][] => {
  const stageIdToAlignSequence: Record<string, [string, ...string[]]> = {};

  const addDependencyStagesToMap = (
    stageId: string,
    dependentStages: Set<string>,
    seen: Set<(typeof data)[string]["data"][number]>
  ) => {
    const cached = stageIdToAlignSequence[stageId];
    if (cached) return; // already encountered

    const batchVariablesThatHaveThisStage = data[stageId];

    if (!batchVariablesThatHaveThisStage) throw new Error("impossible");

    for (const d of batchVariablesThatHaveThisStage.data) {
      if (seen.has(d)) continue;
      seen.add(d);

      for (const s of d.stages) {
        dependentStages.add(s._id);
        addDependencyStagesToMap(s._id, dependentStages, seen);
      }
    }

    const stageIdsOnSameScale = Array.from(dependentStages);

    // now sort them to make it a real "sequence"
    stageIdsOnSameScale.sort((s1, s2) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dataWithStage1 = data[s1]!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dataWithStage2 = data[s2]!;

      for (const d of dataWithStage1.data) {
        const one = d.stages.findIndex((x) => x._id === s1);
        const two = d.stages.findIndex((x) => x._id === s2);

        if (one !== -1 && two !== -1) {
          // both stages are in this data
          return one - two;
        }
      }

      // try in the other one
      for (const d of dataWithStage2.data) {
        const one = d.stages.findIndex((x) => x._id === s1);
        const two = d.stages.findIndex((x) => x._id === s2);

        if (one !== -1 && two !== -1) {
          // both stages are in this data
          return one - two;
        }
      }

      return 0;
    });

    if (!minLen1(stageIdsOnSameScale)) throw new Error("bug");

    for (const stageId of stageIdsOnSameScale) {
      stageIdToAlignSequence[stageId] = stageIdsOnSameScale;
    }
  };

  // this should take care of all the data
  for (const stageId in data) {
    addDependencyStagesToMap(stageId, new Set(), new Set());
  }

  // now we have a map of stageIds of the data, and the values tell us which sequence they should be aligned in

  const uniqueSequences = new Set(Object.values(stageIdToAlignSequence));
  return Array.from(uniqueSequences);
};

export {
  manipulateStageOffsetsForAlignByStageView2,
  NUM_MS_FOR_STAGE_SEPARATORS,
};
