import React from "react";
import { type LimitLevelNum } from "../constants";

type Props = {
  levels: ({
    level: number;
    label: string;
    selected: boolean;
  } & Required<Pick<React.ComponentProps<"input">, "onChange">>)[];
};

/**
 * @deprecated
 */
export function OperatingFitnessLevelsToggler({ levels }: Props) {
  return (
    <>
      {levels.map(({ selected, level, onChange, label }) => {
        return (
          <div className="flex items-center gap-1" key={`${level}`}>
            <input
              checked={selected}
              className="toggle toggle-sm level-toggler"
              data-level={level}
              type="checkbox"
              onChange={onChange}
            />
            <span data-level={level} className="level-toggler-text">
              {label}
            </span>
          </div>
        );
      })}
    </>
  );
}

export function OperatingFitnessToggle({
  checked,
  onChange,
  levelOrType,
  children,
}: Required<Pick<React.ComponentProps<"input">, "onChange" | "checked">> & {
  levelOrType?: LimitLevelNum;
} & React.PropsWithChildren) {
  return (
    <div className="flex items-center gap-1">
      <input
        checked={checked}
        className="toggle toggle-sm data-[levelortype='1']:checked:bg-of1 data-[levelortype='1']:checked:border-of1 data-[levelortype='2']:checked:bg-of2 data-[levelortype='2']:checked:border-of2 data-[levelortype='3']:checked:bg-of3 data-[levelortype='3']:checked:border-of3 data-[levelortype='4']:checked:bg-of4 data-[levelortype='4']:checked:border-of4 data-[levelortype='5']:checked:bg-of5 data-[levelortype='5']:checked:border-of5"
        data-levelortype={levelOrType?.toString()}
        type="checkbox"
        onChange={onChange}
      />
      <span
        data-levelortype={levelOrType?.toString()}
        className="data-[levelortype='1']:text-of1 data-[levelortype='2']:text-of2 data-[levelortype='3']:text-of3 data-[levelortype='4']:text-of4 data-[levelortype='5']:text-of5"
      >
        {children}
      </span>
    </div>
  );
}
